import { opacity } from '../../utils';
import { colors } from '../colors';

const table = {
  header: {
    background: opacity(colors.gray[500], 10),
    border_color: colors.gray[400],
    cellHighlight: opacity(colors.blue[100], 40),
  },
  body: {
    background: 'transparent',
    border_color: colors.gray[400],
    row: {
      hover_background: opacity(colors.gray[100], 50),
      datatable_hover_background: colors.royalBlue[100],
      focusedRow: {
        border: `1px solid ${colors.gray[900]}`,
        shadow: `0 0 13px 1px ${opacity(colors.blue[200], 40)}`,
      },
      selected: {
        background: colors.blue[100],
        hover: {
          background: colors.blue[100],
        },
      },
    },
    cellHighlight: colors.blue[100],
  },
};

export default table;
