import { DateRange, isRelativeDate, relativeToMoment, RelativeValue } from '@logz-pkg/utils';
import { capitalize, isNil } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { QuickOption } from './types';

const dateFormat = 'DD.MM.YYYY, HH:mm:ss.SSS';

export const getReactDatePickerValues = (range?: DateRange): [Date | RelativeValue, Date | RelativeValue] => {
  if (!range) return [moment().startOf('day').toDate(), moment().endOf('day').toDate()];

  const { start, end } = range;

  return [
    start.type === 'absolute' ? moment(start.value).toDate() : start.value,
    end.type === 'absolute' ? moment(end.value).toDate() : end.value,
  ];
};

export const formatInputValue = (date: Date | RelativeValue, timezone?: string) => {
  if (!date) return '';

  // When relative date is other than 'now', convert it to absolute
  if (isRelativeDate(date) && date.includes('now')) {
    if (date === 'now') return capitalize(date);

    return relativeToMoment(date as RelativeValue, timezone).format(dateFormat);
  }

  return moment(date).format(dateFormat);
};

export const getTimestamps = (start: Date, end: Date): { start: number; end: number } => {
  return {
    start: start && start.valueOf(),
    end: end && end.valueOf(),
  };
};

export const normalizeValue = ({ type, value }) => {
  if (type === 'absolute') {
    const date = moment(value);
    const isCurrentYear = date.year() === moment().year();

    const baseFormat = isCurrentYear ? 'MMM DD, HH:mm:ss' : 'MMM DD, YYYY HH:mm:ss';

    const formattedDate = date.format(baseFormat);

    return formattedDate;
  }

  return capitalize(value);
};

export const getReadableValue = (dateRange: DateRange, quickOptions: QuickOption[]) => {
  if (!dateRange) return '';

  const startDate = normalizeValue(dateRange.start);
  const endDate = normalizeValue(dateRange.end);

  if (dateRange.start.type === 'absolute' || dateRange.end.type === 'absolute') {
    return `${startDate} - ${endDate}`;
  }

  return quickOptions.reduce((acc, { label, extendedLabel, start, end }) => {
    if (start === dateRange.start.value && end === dateRange.end.value) return extendedLabel || label;

    return acc;
  }, '');
};

export const convertToTimezone = (value: number | Moment | Date, fromTimezone: string, toTimezone: string) => {
  const dateInOriginalTz = moment.tz(value, fromTimezone);

  const dateInTargetTz = moment.tz(
    {
      year: dateInOriginalTz.year(),
      month: dateInOriginalTz.month(),
      day: dateInOriginalTz.date(),
      hour: dateInOriginalTz.hour(),
      minute: dateInOriginalTz.minute(),
      second: dateInOriginalTz.second(),
      millisecond: dateInOriginalTz.millisecond(),
    },
    toTimezone,
  );

  return dateInTargetTz;
};

export const getInputAbsoluteDate = (
  value: number | Moment | Date,
  fromTimezone?: string,
): { type: 'absolute'; value: number } => {
  if (isNil(fromTimezone)) return { type: 'absolute', value: moment(value).valueOf() };

  const toTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formattedTimestamp = convertToTimezone(value, fromTimezone, toTimezone).valueOf();

  return { type: 'absolute', value: formattedTimestamp };
};

export const getInternalValue = (value: DateRange, fromTimezone?: string): DateRange => {
  if (!value) return null;

  const { start, end } = value;

  return {
    start: start.type === 'absolute' ? getInputAbsoluteDate(start.value, fromTimezone) : start,
    end: end.type === 'absolute' ? getInputAbsoluteDate(end.value, fromTimezone) : end,
  };
};

export const getOutputAbsoluteDate = (
  value: number | Moment | Date,
  toTimezone?: string,
): { type: 'absolute'; value: number } => {
  if (isNil(toTimezone)) return { type: 'absolute', value: moment(value).valueOf() };

  const fromTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formattedTimestamp = convertToTimezone(value, fromTimezone, toTimezone).valueOf();

  return { type: 'absolute', value: formattedTimestamp };
};

export const getExternalValue = (value: DateRange, toTimezone?: string): DateRange => {
  if (!value) return null;

  const { start, end } = value;

  return {
    start: start.type === 'absolute' ? getOutputAbsoluteDate(start.value, toTimezone) : start,
    end: end.type === 'absolute' ? getOutputAbsoluteDate(end.value, toTimezone) : end,
  };
};

export const calculateWidth = (readableValue: string, minWidth: number, placeholder?: string) => {
  const AVG_LETTER_SIZE = 6.8;

  const displayedValue = readableValue ? readableValue : placeholder;
  const MARGINS_WIDTH = !readableValue || displayedValue.includes('Now') ? 30 : 15;

  const minimumSize = Math.max(displayedValue.length * AVG_LETTER_SIZE + MARGINS_WIDTH, minWidth);

  return `${minimumSize}px`;
};
