import { FunctionComponent, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { get } from 'lodash';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Group } from '../../containers/Group/Group.component';
import { Text } from '../../typography';
import { Flex } from '../../containers/Flex/Flex.component';
import { ISpinnerProps } from './Spinner.types';
import { SpinnerContainer, SpinnerSvg } from './Spinner.styles';

export const Spinner: FunctionComponent<ISpinnerProps> = ({
  size = 'l',
  subject,
  color,
  fullscreen = false,
  title = null,
  className = '',
  ...props
}) => {
  const theme = useContext(ThemeContext);

  const spinnerColor = color ? get(theme.colors, color) ?? color : theme.spinner.color;

  if (!fullscreen)
    return (
      <SpinnerContainer
        role={'progressbar'}
        size={size}
        {...generateLogzTestAttributes({ context: 'loading', subject })}
        {...props}
      >
        <SpinnerSvg color={spinnerColor} />
      </SpinnerContainer>
    );

  return (
    <Flex
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      subject={subject}
      className={className}
      context={'stretched-loader'}
    >
      <Group vertical alignItems={'center'} gap={5}>
        {title && <Text>{title}</Text>}
        <Spinner size={size} />
      </Group>
    </Flex>
  );
};

Spinner.displayName = 'Spinner';
