import { Radio as PrimaryRadio } from './Radio.component';
import { RadioGroup } from './RadioGroup.component';

type RadioProps = typeof PrimaryRadio & {
  Group: React.ElementType;
};

Object.defineProperty(PrimaryRadio, 'Group', { value: RadioGroup });

export const Radio = PrimaryRadio as RadioProps;
