import React, { ReactNode } from 'react';
import { Group } from '../../../containers/Group/Group.component';
import { Ellipsis } from '../../../typography';

type SelectDecoratedOptionParams = {
  ellipsis?: boolean;
  label?: ReactNode;
  decoration?: ReactNode | string;
};

export const SelectDecoratedOption: React.FC<SelectDecoratedOptionParams> = ({
  label = null,
  decoration = null,
  ellipsis = false,
}) => (
  <Group alignItems="center">
    {typeof decoration === 'string' ? <span>{decoration}</span> : decoration}
    {ellipsis ? (
      <Ellipsis maxLines={1} wordBreak={'break-all'}>
        <span>{label}</span>
      </Ellipsis>
    ) : (
      <span>{label}</span>
    )}
  </Group>
);
