import styled from 'styled-components';
import { TableRow } from './Row.component';

export const TableHeader = styled(props => <TableRow context={'table-header'} {...props} />)`
  background: ${({ theme, tableOptions }) => tableOptions?.header?.background || theme.table.header.background};
  border: 1px solid ${({ theme }) => theme.table.header.border_color};
  align-items: stretch;
`;

TableHeader.displayName = 'Table.Header';
