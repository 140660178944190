import { bluePalette, grayPalette } from '../../themes/colors';

export const cardLightTheme = {
  backgroundColor: grayPalette[0],
  borderColor: grayPalette[300],
  highlight: {
    backgroundColor: bluePalette[100],
    borderColor: bluePalette[600],
  },
  footer: {
    backgroundColor: grayPalette[100],
    color: grayPalette[800],
    lineHeight: '20px',
  },
};
