import { SortDirection } from '@logz-pkg/enums';
import { Header } from '@tanstack/react-table';
import { isNil } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../Button/Button.component';
import { Group } from '../../../containers/Group/Group.component';
import { Text } from '../../../typography';
import { IDataTableProps } from '../types';
import { ITableCellStyledProps } from './BodyCell';
import { Pin } from './Pin';
import { PinShadow } from './PinShadow';
import { Resizer } from './Resizer';
import { Sort } from './Sort';

export const StyledHeadCell = styled.th<ITableCellStyledProps>`
  display: flex;
  position: relative;
  padding: 0px;
  cursor: ${({ draggable }) => (draggable ? 'grab' : 'auto')};
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};

  :last-child {
    border-right: none;
  }

  background-color: ${({ theme }) => theme.colors.gray[0]};

  button {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[300]};
    button {
      display: block;
    }
  }
`;

interface IHeadCellProps extends React.ComponentProps<'th'> {
  header: Header<any, unknown>;
  customSortDirection: { id: string; direction: SortDirection }[];
  onSort: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPin: () => void;
  onDelete?: IDataTableProps<any>['onDelete'];
  onColumnResize?: IDataTableProps<any>['onColumnResize'];
  isScrolledHorizontally: boolean;
}

export const HeadCell: React.FC<IHeadCellProps> = ({
  children,
  header,
  customSortDirection,
  ref,
  onDelete,
  onSort,
  onPin,
  onColumnResize,
  isScrolledHorizontally,
  draggable,
  ...props
}) => {
  const isResizing = header.column.getIsResizing();
  const columnMeta = header.column.columnDef.meta as any;
  const hasContent = Boolean(children);

  const sortDirection = useMemo(() => {
    if (!header.column.getIsSorted()) {
      return SortDirection.Initial;
    }

    return header.column.getIsSorted() === 'asc' ? SortDirection.Ascending : SortDirection.Descending;
  }, [header.column.getCanSort(), header.column.getIsSorted()]);

  useEffect(() => {
    if (onColumnResize) {
      if (isResizing === false) {
        onColumnResize({ id: header.column.id, size: header.column.getSize() });
      }
    }
  }, [isResizing, header.column.getSize()]);

  const isPinned = header.column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && header.column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && header.column.getIsFirstColumn('right');

  return (
    <StyledHeadCell draggable={isResizing ? false : draggable} hasContent={hasContent} {...props}>
      {isFirstRightPinnedColumn && <PinShadow isVisible={isScrolledHorizontally} pinDirection={'right'} />}
      <Group p={2} alignItems={'center'} width={'100%'}>
        {React.isValidElement(children) ? (
          children
        ) : (
          <Text color={'royalBlue.1000'} ellipsis>
            {children}
          </Text>
        )}
        <Group gap={0} justifyContent="end">
          {header.column.getCanSort() && (
            <Sort
              sortDirection={customSortDirection?.find(item => item.id === header.id)?.direction || sortDirection}
              disabled={columnMeta.disableSorting}
              disabledTooltip={columnMeta.disableSortTooltip}
              onClick={onSort}
            />
          )}
          {header.column.getCanPin() && <Pin onPin={onPin} isPinned={Boolean(header.column.getIsPinned())} />}
          {!isNil(onDelete) && (
            <Button
              icon={'xmark-regular'}
              variant="tertiary"
              round
              size="xs"
              subject="delete-icon-data-table"
              onClick={e => {
                e.stopPropagation();
                onDelete(header.id);
              }}
            />
          )}
        </Group>
      </Group>
      {header.column.getCanResize() && (
        <Resizer
          isResizing={header.column.getIsResizing()}
          {...{
            onMouseDown: header.getResizeHandler(),
            onTouchStart: header.getResizeHandler(),
            onDoubleClick: () => {
              header.column.resetSize();
            },
            onClick: e => {
              e.stopPropagation();
              e.preventDefault();
            },
          }}
        />
      )}
      {isLastLeftPinnedColumn && <PinShadow isVisible={isScrolledHorizontally} pinDirection={'left'} />}
    </StyledHeadCell>
  );
};
