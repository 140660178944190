import styled, { css } from 'styled-components';
import { fontWeight, FontWeightProps, SpaceProps, color, space, fontSize } from 'styled-system';
import { underlineStyle } from '../Text/Text.styles';
import { Color } from '../../themes';

export interface ITitleStyleProps extends SpaceProps, FontWeightProps {
  size?: 14 | 16 | 18 | 20 | 24 | 30;
  uppercase?: boolean;
  underline?: boolean;
  inline?: boolean;
  color?: Color;
  fontSize?: number;
}
export const uppercaseStyle = css`
  text-transform: uppercase;
`;

export const inlineStyle = css`
  display: inline-block;
`;

export const StyledTitle = styled.div<ITitleStyleProps>`
  display: flex;
  align-items: baseline;

  ${({ theme }) => theme.title.styles};
  ${({ underline }) => underline && underlineStyle}
  ${({ uppercase }) => uppercase && uppercaseStyle}
  ${({ inline }) => inline && inlineStyle}

  ${fontSize};
  ${fontWeight};
  ${space};
  ${color};

  color: ${({ color: titleColor, theme }) => !titleColor && theme.title.color};
`;
