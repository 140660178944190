import styled, { css } from 'styled-components';
import { Link } from '../../typography';
import {
  baseInputCss,
  baseInputErrorFocusCss,
  baseInputErrorHoverCss,
  baseInputFocusCss,
  baseInputHoverCss,
} from '../../Input/Input.styles';
import { textSize } from '../../themes';
import { ISelectProps } from './Select.types';

export const classNamePrefix = 'react-select';
const recipientsLineHeight = 25;

export const SelectIconButton = styled<any>(Link).attrs({
  tabIndex: -1,
  alignItems: 'center',
})`
  color: ${({ theme, variant }) => theme.select.variants[variant].indicator.iconColor};
  :hover {
    color: ${({ theme, variant }) => theme.select.variants[variant].indicator.hover.iconColor};
  }
`;

export const reactSelectCssOverrides = props => css<ISelectProps>`
  .${classNamePrefix}__value-container--is-multi {
    ${() => `
      max-height: ${(props.maxLines || 3) * recipientsLineHeight}px;
    `};
  }

  .${classNamePrefix}__value-container--has-value {
    color: ${({ theme, variant }) => theme.select.variants[variant].valueContainer.textColor};
  }

  .${classNamePrefix}__control {
    ${baseInputCss};
    font-size: ${({ theme }) => textSize({ theme, size: 'medium' })};
    padding-right: 0;

    &:hover:not(.${classNamePrefix}__control--is-focused) {
      ${({ error }) => (error ? baseInputErrorHoverCss : baseInputHoverCss)}
    }
  }

  .${classNamePrefix}__control--is-focused {
    ${({ error }) => (error ? baseInputErrorFocusCss : baseInputFocusCss)}
    ${({ theme, variant, borderless, error }) =>
      !error &&
      (!borderless
        ? css`
            border: 1px solid ${theme.select.variants[variant].focused.borderColor};
            box-shadow: 0 0 0 ${theme.select.variants[variant].focused.shadowSpread}
              ${theme.select.variants[variant].focused.shadowColor};
          `
        : css`
            border-bottom: 1px solid ${theme.select.variants[variant].focused.borderColor};
          `)};
  }

  .${classNamePrefix}__placeholder {
    color: ${({ theme, variant }) => theme.select.variants[variant].placeholder.textColor};
  }

  .${classNamePrefix}__value-container {
    padding: 0;
    overflow-y: auto;
  }

  .${classNamePrefix}__multi-value {
    border-radius: 3px;
    border: 1px solid ${({ theme, variant }) => theme.select.variants[variant].multiValue.container.borderColor};
    background-color: ${({ theme, variant }) => theme.select.variants[variant].multiValue.container.backgroundColor};
  }

  .${classNamePrefix}--is-disabled {
    cursor: not-allowed;
    pointer-events: visible;

    .${classNamePrefix}__control {
      pointer-events: none;
    }
  }
`;
