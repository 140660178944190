import React, { useEffect, useState, ImgHTMLAttributes } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isNil } from 'lodash';
import { Spinner } from '../progress/Spinner/Spinner.component';

interface IImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  subject?: string;
  hideProgress?: boolean;
  onFail?(): void;
  fallbackSrc?: string;
}

export const Img: React.FC<IImgProps> = ({
  src,
  subject = 'image',
  onFail,
  hideProgress = false,
  fallbackSrc,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | undefined>(src);

  const handleError = () => {
    if (!hideProgress) setIsLoading(false);

    onFail?.();

    if (fallbackSrc) setImageSrc(fallbackSrc);
  };

  useEffect(() => {
    if (hideProgress) {
      setImageSrc(src ?? fallbackSrc);
    } else {
      setIsLoading(true);

      const preLoader = new Image();

      preLoader.onload = () => {
        setIsLoading(false);
        setImageSrc(src);

        if (isNil(src)) {
          handleError();
        }
      };
      preLoader.onerror = handleError;
      preLoader.src = src;
    }
  }, [src]);

  return (
    <>
      {isLoading ? (
        !hideProgress && <Spinner subject={`loading-${subject}`} />
      ) : (
        <img
          {...generateLogzTestAttributes({ context: 'image', subject })}
          src={imageSrc}
          onError={handleError}
          alt={props.alt || subject}
          {...props}
        />
      )}
    </>
  );
};
