import React from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { StyledOrderedList, StyledNav } from './Breadcrumbs.styles';
import { Crumb, CustomCrumb } from './types';
import { SingleCrumb } from './components/SingleCrumb';
import { CustomSingleCrumb } from './components/CustomSingleCrumb';

interface IBreadcrumbsProps {
  crumbs: Crumb[];
  separator?: boolean;
  firstItemSeparator?: boolean;
  subject?: string;
  customCrumb?: CustomCrumb;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
  crumbs = [],
  customCrumb,
  firstItemSeparator = false,
  separator = true,
  subject = 'Breadcrumbs',
}) => {
  return (
    <StyledNav {...generateLogzTestAttributes({ context: `Breadcrumbs`, subject })} aria-label="Breadcrumb">
      <StyledOrderedList separator={separator}>
        {crumbs.map((crumb, index) => {
          const isFirstItem = index === 0;
          const isDisabled = !customCrumb && index === crumbs.length - 1;

          return (
            <SingleCrumb
              key={`${crumb.label ?? crumb.icon}`}
              {...{ firstItemSeparator, isFirstItem, isDisabled, separator, crumb }}
            />
          );
        })}
        {customCrumb && <CustomSingleCrumb crumb={customCrumb} />}
      </StyledOrderedList>
    </StyledNav>
  );
};
