import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { SearchFiltersClearButtonSubject } from '@logz-pkg/enums';
import { Button } from '../../Button/Button.component';

const StyledButton = styled(Button)`
  &:focus {
    box-shadow: none;
  }
`;

export const ClearFiltersButton: React.FC<{
  disabled?: boolean;
  onClick: () => void;
  values: Record<string, any>;
  defaultValues: Record<string, any>;
}> = ({ onClick, disabled, values, defaultValues }) => {
  const isDirty = useMemo(
    () => !isNil(Object.entries(values).find(([key, value]) => defaultValues[key] !== value)),
    [values, defaultValues],
  );

  return (
    <>
      {isDirty && (
        <StyledButton
          subject={SearchFiltersClearButtonSubject.Clear}
          disabled={disabled}
          ml={1}
          variant={'tertiary'}
          size={'m'}
          onClick={onClick}
        >
          Clear filters
        </StyledButton>
      )}
    </>
  );
};
