export enum BucketSettingsNotificationSubject {
  EditSuccess = 'edit-aws-bucket-success',
  EditFailure = 'edit-aws-bucket-failure',
  CreateSuccess = 'create-aws-bucket-success',
  CreateFailure = 'create-aws-bucket-failure',
  DeleteSuccess = 'delete-aws-bucket-success',
  DeleteFailure = 'delete-aws-bucket-failure',
  RegionGetFailure = 'get-regions-failure',
  LogTypesGetFailure = 'get-logtypes-failure',
  AccountIdGetFailure = 'get-accountid-failure',
  GetBuckets = 'get-buckets',
}

export enum OperatingSystem {
  Windows = 'windows',
  Linux = 'linux',
}

export enum Format {
  Plain = 'plain',
  Json = 'json',
}

export enum BucketEndpoints {
  CloudTrail = 'cloudtrails',
  S3 = 's3-buckets',
}

export enum LogzioSupportedBucketTypes {
  CloudTrail = 'cloudtrail',
  Elb = 'elb',
  CloudFront = 'cloudfront',
  S3Access = 'S3Access',
  VPC = 'vpcflow',
  S3 = 's3-buckets',
}

export enum AuthenticationTypes {
  IAM = 'iam',
  SecretAccess = 'secret',
}

export enum ShipperTypes {
  CUSTOM = 'custom',
  RANDOM = 'random',
  APPLICATION = 'application',
  COGNITIVE = 'cognitive',
}
