import { components } from 'react-select';
import styled from 'styled-components';
import { Icon } from '../../../icons/Icon.component';
import { classNamePrefix, SelectIconButton } from '../Select.styles';

const StyledClearIndicator = styled(components.ClearIndicator)`
  border-right: 1px solid
    ${({ theme, selectProps: { variant } }) => theme.select.variants[variant].indicator.clear.borderColor};
  &.${classNamePrefix}__clear-indicator {
    margin: ${({ theme }) => theme.space[2]}px 0;
    padding: 0 ${({ theme }) => theme.space[2]}px;
  }
`;

export const SelectClearIndicator: typeof components.ClearIndicator = props => (
  <StyledClearIndicator {...props}>
    <SelectIconButton subject="select-clear-button" variant={props.selectProps.variant}>
      <Icon icon={'xmark-regular'} size={12} />
    </SelectIconButton>
  </StyledClearIndicator>
);

SelectClearIndicator.displayName = 'ClearIndicator';
