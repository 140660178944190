import { ElementType } from 'react';
import { ModalBody } from './Body.component';
import { ModalFooter } from './Footer.component';
import { ModalHeader } from './Header.component';
import { Modal as PrimaryModal, ModalContext } from './Modal.component';

export { ConfirmModal } from './ConfirmModal';
export type { IConfirmModalProps } from './ConfirmModal';

export type { IModalProps } from './Modal.component';

type ModalProps = typeof PrimaryModal & {
  Header: ElementType;
  Body: ElementType;
  Footer: ElementType;
};

Object.defineProperty(PrimaryModal, 'Header', { value: ModalHeader });
Object.defineProperty(PrimaryModal, 'Body', { value: ModalBody });
Object.defineProperty(PrimaryModal, 'Footer', { value: ModalFooter });

export const Modal = PrimaryModal as ModalProps;

export { ModalContext };
