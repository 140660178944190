import React from 'react';
import styled, { css } from 'styled-components';
import { CheckboxListSubjects } from '@logz-pkg/enums';
import { isNil } from 'lodash';
import { useRowVirtualizer } from '../../ScrollContainer/ScrollContainer.component';
import { Group } from '../../containers/Group/Group.component';
import { ICheckboxList } from '../CheckboxList.component';
import { Checkbox } from '../Checkbox.component';
import { Flex } from '../../containers/Flex/Flex.component';
import { CHECKBOX_HEIGHT, CHECKBOX_PADDING_Y } from '../constants';

const StyledLabel = styled(Flex)`
  height: ${CHECKBOX_HEIGHT + CHECKBOX_PADDING_Y * 2}px;
  width: 100%;
`;

const StyledGroup = styled(Group)<{ checked?: boolean | null; disabled?: boolean; notAllowed?: boolean }>`
  ${({ checked, theme }) => checked === false && `background-color:${theme.checkbox.list.backgroundColor.inactive}`};
  padding: 0 4px;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      text-decoration: line-through;
    `};
  ${({ notAllowed }) =>
    notAllowed &&
    css`
      * {
        cursor: not-allowed;
      }
    `};
  border-radius: 2px;
  &:hover {
    background-color: ${({ checked, theme }) =>
      checked === false
        ? theme.checkbox.list.backgroundColor.hover.inactive
        : theme.checkbox.list.backgroundColor.hover.default};
  }
`;

interface IOptionsListProps {
  options: ICheckboxList['options'];
  isItemChecked: ICheckboxList['isItemChecked'];
  isItemDisabled: ICheckboxList['isItemDisabled'];
  itemKey: ICheckboxList['getItemKey'];
  toggleOne: ICheckboxList['toggleOne'];
  fullWidth: ICheckboxList['fullWidth'];
  numberOfCheckedItems?: number;
  minNumberOfCheckedItems?: number;
  subject?: string;
}

export const OptionsList: React.FC<IOptionsListProps> = ({
  options,
  isItemChecked,
  isItemDisabled,
  itemKey,
  toggleOne,
  fullWidth,
  numberOfCheckedItems,
  minNumberOfCheckedItems,
  subject,
}) => {
  const { rowVirtualizer } = useRowVirtualizer();

  const items = rowVirtualizer.getVirtualItems();
  const checkedNumber = numberOfCheckedItems ?? options.filter(option => isItemChecked(option)).length;

  return items.map(virtualItem => {
    const item = options[virtualItem.index];
    const checked = isItemChecked(item);

    const disabled = isItemDisabled(item);

    return (
      <div key={virtualItem.key}>
        <StyledGroup
          key={itemKey(item)}
          fullWidth
          checked={checked}
          notAllowed={checked && checkedNumber === minNumberOfCheckedItems}
          disabled={disabled}
        >
          <Checkbox
            subject={`${CheckboxListSubjects.singleItem}${subject ? `-${subject}` : ''}`}
            onChange={toggleOne.bind(null, item)}
            checked={!isNil(checked)}
            label={<StyledLabel alignItems={'center'}>{item.label}</StyledLabel>}
            fullWidth={fullWidth}
            alignCheckbox={'center'}
            disabled={checked && checkedNumber === minNumberOfCheckedItems}
            {...(checked === false && { checkedIcon: 'minus-regular', checkedColor: 'red.600' })}
            {...(disabled && { checkedColor: 'royalBlue.1000' })}
          />
        </StyledGroup>
      </div>
    );
  });
};
