import styled from 'styled-components';
import { Group } from '../containers/Group/Group.component';

export const ModalFooter = styled(({ justifyContent = 'flex-end', ...props }) => (
  <Group width={'100%'} justifyContent={justifyContent} py={4} px={6} gap={4} {...props} />
))`
  background-color: ${({ theme }) => theme.modal.footer.backgroundColor};
`;

ModalFooter.displayName = 'Modal.Footer';
