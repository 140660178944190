import styled from 'styled-components';
import { Drawer } from '../../Drawer/Drawer.component';

export const SecondarySidenavContainer = styled.div`
  position: relative;
`;

export const SecondarySidenavDrawer = styled(props => <Drawer direction={props.direction} {...props} />)`
  background-color: ${({ theme }) => theme.secondarySidenav.backgroundColor};
`;
