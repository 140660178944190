import { colors } from '../../../themes/colors';

export const fieldContainerLightTheme = {
  requiredSignColor: colors.orange[600],
  helper: {
    color: colors.gray[800],
  },
  error: {
    color: colors.red[800],
  },
  label: {
    m: {
      color: colors.gray[800],
    },
    l: {
      color: colors.gray[900],
    },
  },
};
