import { get, isObject } from 'lodash';
import { K5QueryUtils, IK5Query } from './kibana5-query-utils';

export enum QueryLanguage {
  LUCENE = 'lucene',
  KUERY = 'kuery',
}

/**
 * K6 default query can be empty string, thus all is optional.
 */
export interface IK6Query extends IK5Query {
  query?: string | IK5Query;
  language?: QueryLanguage;
}

export class K6QueryUtils {
  static get DefaultQuery(): IK6Query {
    return { query: '', language: QueryLanguage.LUCENE };
  }

  static isK6Query(query: IK6Query): boolean {
    return !K5QueryUtils.isK5Query(query);
  }

  static isDefault(query: IK6Query): boolean {
    return !query || (query as string) === '' || query.query === '' || K5QueryUtils.isDefault(query);
  }

  static compose(value: string | IK5Query = ''): IK6Query {
    return { query: value, language: QueryLanguage.LUCENE };
  }

  static decorate(target: IK6Query, value: string | IK5Query): void {
    target.query = value;

    if (!Boolean(target.language)) {
      target.language = QueryLanguage.LUCENE;
    }
  }

  static getQueryString(query: IK5Query): string {
    if (typeof query === 'string') return query;

    return get(query, 'query') || K5QueryUtils.getQueryString(query);
  }

  /**
   * k6 matches changes by referenced query, modify source, don't override query instance.
   */
  static update(query: IK6Query, value: string): IK6Query {
    let retVal = query;

    if (K5QueryUtils.isK5Query(retVal)) {
      K5QueryUtils.update(retVal, value);
    } else if (isObject(retVal)) {
      K6QueryUtils.decorate(retVal, value);
    } else {
      // override string instance
      retVal = K6QueryUtils.compose(value);
    }

    return retVal;
  }
}
