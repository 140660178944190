import { MutableRefObject, useCallback, useState } from 'react';
import { CHAR_WIDTH } from '../constants';

type UseInputWidth = {
  inputRef: MutableRefObject<HTMLInputElement>;
  value: string;
};

export const useInputWidth = ({ inputRef, value }: UseInputWidth) => {
  const INITIAL_FIELD_WIDTH = Math.max(value.length * CHAR_WIDTH, CHAR_WIDTH);
  const [fieldWidth, setFieldWidth] = useState(INITIAL_FIELD_WIDTH);

  const calculateFieldWidth = useCallback(() => {
    const newWidth = Math.max(inputRef.current.value.length * CHAR_WIDTH, CHAR_WIDTH);

    setFieldWidth(newWidth);
  }, []);

  const resetFieldWidth = () => setFieldWidth(INITIAL_FIELD_WIDTH);

  return { fieldWidth, resetFieldWidth, calculateFieldWidth };
};
