import { DateRange, getTimeRange, isRelativeRange, relativeToAbsoluteRange } from '@logz-pkg/utils';
import { Group } from '@logz-ui/styleguide/components/containers/Group/Group.component';
import React, { useMemo, useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { DateCustomization, DateSection } from '../types';
import { getInternalValue } from '../utils';
import { ActionsBar } from './ActionsBar';
import { AbsoluteInputs } from './components/AbsoluteInputs';
import { AbsoluteDatePicker } from './components/AbsoluteDatePicker';
import { extractValuesFromDateRange } from './utils';

export interface IAbsoluteFilterProps {
  value: DateRange;
  onChange: (range: DateRange) => void;
  onConfirm: (range: DateRange) => void;
  onCancel: () => void;
  showEndDateNowButton: boolean;
  timezone?: string;
  minDate?: Date;
  maxDate?: Date;
  dateCustomizations?: DateCustomization[];
  footerNotification?: React.ReactNode;
  actionsBarNotification?: React.ReactNode;
  updateSelectedRange: (range: DateRange) => void;
}

export const AbsoluteFilter: React.FC<IAbsoluteFilterProps> = ({
  value,
  onChange,
  onConfirm,
  onCancel,
  showEndDateNowButton,
  timezone,
  minDate,
  maxDate,
  dateCustomizations,
  footerNotification,
  actionsBarNotification,
  updateSelectedRange,
}) => {
  const [activeSection, setActiveSection] = useState<DateSection>('start');
  const { datePickerAbsoluteDates, shownDates } = useMemo(() => extractValuesFromDateRange(value), [value]);
  const [start, end] = datePickerAbsoluteDates;

  useEffect(() => {
    if (value && isRelativeRange(value)) {
      const absoluteValue = relativeToAbsoluteRange(value);
      const internalValue = getInternalValue(absoluteValue, timezone);

      updateSelectedRange(internalValue);
    }
  }, [value, updateSelectedRange, timezone]);

  const handleConfirm = () => {
    const [shownStart, shownEnd] = shownDates;
    const dateRange = getTimeRange(shownStart, shownEnd);

    onConfirm(dateRange);
  };

  const handleChange = (newRange: DateRange, shouldSwitchSection = true) => {
    onChange(newRange);

    if (shouldSwitchSection) {
      setActiveSection(prev => (prev === 'start' ? 'end' : 'start'));
    }
  };

  return (
    <Group vertical width={'100%'} gap={0}>
      <Group vertical p={4} alignItems={'stretch'} width={'100%'}>
        <AbsoluteInputs
          activeSection={activeSection}
          onSectionChange={setActiveSection}
          showEndDateNowButton={showEndDateNowButton}
          timezone={timezone}
          shownDates={shownDates}
          onChange={handleChange}
        />
        <AbsoluteDatePicker
          dateCustomizations={dateCustomizations}
          datePickerAbsoluteDates={datePickerAbsoluteDates}
          shownDates={shownDates}
          minDate={minDate}
          maxDate={maxDate}
          activeSection={activeSection}
          onChange={handleChange}
        />
        {footerNotification}
      </Group>
      <ActionsBar
        disabled={!start || !end}
        onCancel={onCancel}
        onConfirm={handleConfirm}
        notification={actionsBarNotification}
      />
    </Group>
  );
};
