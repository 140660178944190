import React, { forwardRef } from 'react';
import {
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  flexWrap,
  flexGrow,
  flexDirection,
  alignItems,
  justifyContent,
  display,
  textAlign,
  flexShrink,
  overflow,
  MinWidthProps,
  MaxWidthProps,
  MinHeightProps,
  MaxHeightProps,
  FlexWrapProps,
  FlexGrowProps,
  FlexShrinkProps,
  FlexDirectionProps,
  AlignItemsProps,
  TextAlignProps,
  JustifyContentProps,
  DisplayProps,
  OverflowProps,
} from 'styled-system';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Box, IBoxProps } from '../Container/Box.component';

const flexPropTypes = {
  sticky: propTypes.bool,
  context: propTypes.string,
  subject: propTypes.string,
  className: propTypes.string,
  children: propTypes.node,
  name: propTypes.string,
  onClick: propTypes.func,
} as const;

type FlexTypes = {
  sticky?: boolean;
  context?: string;
  subject?: string;
  className?: string;
  children?: React.ReactNode;
  name?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

type IBaseFlexBoxProps = FlexTypes & IBoxProps;

export type IFlexProps = IBaseFlexBoxProps &
  MinWidthProps &
  MaxWidthProps &
  MinHeightProps &
  MaxHeightProps &
  FlexWrapProps &
  FlexGrowProps &
  FlexShrinkProps &
  FlexDirectionProps &
  AlignItemsProps &
  TextAlignProps &
  JustifyContentProps &
  DisplayProps &
  OverflowProps;

const FlexBox = forwardRef<HTMLDivElement>((props: IBaseFlexBoxProps, ref) => (
  <Box ref={ref} style={props.style} {...props} />
));

FlexBox.displayName = 'FlexBox';

export const Flex = styled(FlexBox)<IFlexProps>`
  display: flex;
  box-sizing: border-box;
  ${minWidth}
  ${maxWidth}
  ${minHeight}
  ${maxHeight}
  ${flexWrap}
  ${flexGrow}
  ${flexShrink}
  ${flexDirection}
  ${alignItems}
  ${textAlign}
  ${justifyContent}
  ${display}
  ${overflow}
`;

Flex.displayName = 'Flex';

export const FlexColumn = ({ ...props }) => <Flex {...props} {...{ flexDirection: 'column' }} />;

export const InlineFlex = styled(Flex)`
  display: inline-flex;
  gap: 4px;
`;

(Flex as any).propTypes = flexPropTypes;
