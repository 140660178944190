import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { LinearProgress } from '../../progress/LinearProgress/LinearProgress.component';
import { TableContext } from './Table.component';

const stickyHeaderShadowStyle = ({
  stickyHeader,
  hideShadow,
  theme,
}: {
  stickyHeader: boolean;
  hideShadow: boolean;
  theme;
}) =>
  stickyHeader &&
  theme &&
  css`
    overflow-y: scroll;
    box-shadow: 0px -10px 5px -5px ${theme.colors.border} inset;
    ${hideShadow && 'box-shadow: none;'}
  `;

const TableBodyStyled = styled.div<{ stickyHeader?: boolean; hideShadow: boolean }>`
  border-width: 0 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.border};

  ${stickyHeaderShadowStyle}
`;

export const TableBody = ({ children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hideShadow, setHideShadow] = useState(false);

  const handleScroll = () => {
    const node = ref?.current;

    if (node) {
      const { scrollTop, scrollHeight, clientHeight } = node;

      setHideShadow(scrollTop >= scrollHeight - clientHeight - 1);
    }
  };

  return (
    <TableBodyStyled {...props} ref={ref} onScroll={handleScroll} hideShadow={hideShadow}>
      <TableContext.Consumer>{({ loading }: any) => loading && <LinearProgress overlay />}</TableContext.Consumer>
      {children}
    </TableBodyStyled>
  );
};

TableBody.displayName = 'TableBody';
