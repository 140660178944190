import React from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isNil } from 'lodash';
import { SingleTag, TagState } from './types';
import { StepList } from './components/StepList';
import { DeleteButton } from './components/DeleteButton';
import { TagContainer } from './components/TagContainer';
import { useEditMode } from './hooks/edit-mode.hook';

export interface IMultiTagProps {
  tag: SingleTag;
  onChange?: (tag: SingleTag) => void;
  onSelected?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDelete?: () => void;
  state?: TagState;
  highlight?: string;
  subject?: string;
  noTooltip?: boolean;
  invalid?: boolean;
  isEditable?: boolean;
}

export const MultiTag: React.FC<IMultiTagProps> = ({
  tag,
  state,
  onChange,
  onDelete,
  onSelected,
  highlight,
  subject,
  noTooltip,
  invalid,
  isEditable = true,
}) => {
  const { editMode } = useEditMode(tag);

  return (
    <TagContainer
      {...{
        editMode,
        onSelected,
        state,
        invalid,
        onChange,
        tag,
        highlight,
        noTooltip,
        isEditable,
      }}
      {...generateLogzTestAttributes({ context: 'multi-tag', subject })}
    >
      <StepList />
      {state !== 'preview' && !isNil(onDelete) && <DeleteButton {...{ onDelete, editMode, state }} />}
    </TagContainer>
  );
};
