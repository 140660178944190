export enum LogzioTheme {
  Light,
  Dark,
}

export enum KibanaTheme {
  Light,
  Dark,
}

export enum GrafanaTheme {
  Light = 'light',
  Dark = 'dark',
}
