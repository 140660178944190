import React, { ComponentProps, forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import {
  color,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  maxWidth,
  MaxWidthProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Color } from '../../themes';
import { Ellipsis } from '../Ellipsis/Ellipsis.component';
import {
  codeStyle,
  italicsStyle,
  paragraphStyle,
  strikeThroughStyle,
  underlineStyle,
  upperCaseStyle,
} from './Text.styles';

const lineHeights = {
  dense: 1.1,
  normal: 1.3,
  expanded: 1.5,
};

type MouseEvents = Partial<Pick<React.DOMAttributes<HTMLButtonElement>, 'onClick'>>;

interface IBaseTextProps extends MouseEvents, TextAlignProps, SpaceProps, MaxWidthProps {
  children: ReactNode;
  weight?: 300 | 400 | 500 | 700 | FontWeightProps['fontWeight'];
  size?: '8px' | 10 | 11 | 12 | 14 | 16 | 18 | 20 | 22 | 28 | 30 | 48;
  variant?: 'primary' | 'secondary' | 'dark' | 'contrast' | 'faded' | 'description' | 'code';
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'initial' | 'inherit';
  color?: Color | (string & { fromColor?: any });
  italic?: boolean;
  paragraph?: boolean;
  underline?: boolean;
  noTextWrap?: boolean;
  strikethrough?: boolean;
  subject?: string;
  upperCase?: boolean;
  code?: boolean;
  inheritColor?: boolean;
  lineHeight?: keyof typeof lineHeights;
  title?: string;
}

const StyledText = styled.span<IBaseTextProps & FontWeightProps & FontSizeProps & TextAlignProps>`
  line-height: ${({ lineHeight = 'normal' }) => lineHeights[lineHeight]};
  -webkit-font-smoothing: antialiased;
  font-family: ${({ theme, variant }) => theme.text.font?.[variant] ?? theme.fonts.text};
  color: ${({ theme, inheritColor, variant = 'primary' }) => !inheritColor && theme.text.color[variant]};
  ${({ italic }) => italic && italicsStyle}
  ${({ underline }) => underline && underlineStyle}
  ${({ strikethrough }) => strikethrough && strikeThroughStyle}
  ${({ upperCase }) => upperCase && upperCaseStyle}
  ${({ paragraph }) => paragraph && paragraphStyle}
  ${({ code }) => code && codeStyle}
  ${({ textAlign }) =>
    textAlign &&
    css`
      display: inline-block;
    `};
  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
      overflow-wrap: break-word;
    `}
  ${({ noTextWrap }) =>
    noTextWrap &&
    css`
      text-wrap: nowrap;
    `}

  ${space}
  ${fontWeight}
  ${fontSize}
  ${textAlign}
  ${color}
  ${maxWidth}
`;

export const BaseText = forwardRef<HTMLSpanElement, IBaseTextProps>(
  ({ children, weight, size = 14, subject = 'text', whiteSpace, ...props }, ref) => (
    <StyledText
      {...generateLogzTestAttributes({ context: 'text', subject })}
      fontWeight={weight}
      fontSize={size}
      whiteSpace={whiteSpace}
      ref={ref}
      {...props}
    >
      {children}
    </StyledText>
  ),
);

export interface ITextProps extends IBaseTextProps {
  ellipsis?: boolean | Omit<ComponentProps<typeof Ellipsis>, 'children' | 'ref'>;
  noTooltip?: boolean;
}

const defaultEllipsisProps: Omit<ComponentProps<typeof Ellipsis>, 'children'> = { maxLines: 1, wordBreak: 'break-all' };

export const Text = forwardRef<HTMLSpanElement, ITextProps>(
  ({ children, ellipsis, noTooltip = false, whiteSpace, ...props }, ref) => {
    return Boolean(ellipsis) ? (
      <Ellipsis
        whiteSpace={whiteSpace}
        ref={ref}
        {...defaultEllipsisProps}
        noTooltip={noTooltip}
        {...(typeof ellipsis !== 'boolean' ? ellipsis : {})}
      >
        <BaseText whiteSpace={whiteSpace} {...props}>
          {children}
        </BaseText>
      </Ellipsis>
    ) : (
      <BaseText whiteSpace={whiteSpace} ref={ref} {...props}>
        {children}
      </BaseText>
    );
  },
);

Text.displayName = 'Text';
