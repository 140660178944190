import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Flex } from '../../containers/Flex/Flex.component';

export const Container = styled(Flex)`
  margin-right: 5px;
  justify-content: center;
  align-items: center;
`;

export const IconContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>;
};
