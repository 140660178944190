import { CSSMotionProps } from 'rc-motion';
import { useNotification } from 'rc-notification';
import 'rc-notification/assets/index.css';
import { useEffect } from 'react';
import { NotificationWrapper } from './components/NotificationWrapper';
import { notificationApiService } from './notification-api.service';

const motion: CSSMotionProps = {
  motionName: 'slide-motion',

  motionAppear: true,
  motionLeave: true,

  onAppearStart: () => ({ opacity: 0.5, right: '-500px' }),
  onAppearActive: () => ({ opacity: 1 }),
  onLeaveStart: () => ({ opacity: 1 }),
  onLeaveActive: () => ({ opacity: 0.5, right: '-500px', bottom: '-10px' }),
};

export const Notification = () => {
  const [api, holder] = useNotification({
    stack: { threshold: 2, gap: 10 },
    style: () => ({ width: '100%' }),
    motion,
    renderNotifications(node, info) {
      return <NotificationWrapper key={info.key}>{node}</NotificationWrapper>;
    },
  });

  useEffect(() => {
    notificationApiService.notificationApi.set(api);
  }, [api]);

  return <div style={{ width: 'fit-content', height: 'fit-content' }}>{holder}</div>;
};
