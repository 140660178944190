import React, { useContext } from 'react';
import { Field } from 'formik';
import { ThemeContext } from 'styled-components';
import { Group } from '../../../containers/Group/Group.component';
import { IFilterField } from '../../interfaces';
import { Text } from '../../../typography';
import { FilterButton } from '../FilterValue/FilterButton';
import { IIconProps, Icon } from '../../../icons/Icon.component';

export interface IBooleanFilter extends IFilterField {
  label: string;
  icon?: IIconProps['icon'];
  iconColor?: IIconProps['color'];
}

const StyledLabel = ({ label, value }) => {
  const { searchFilters } = useContext(ThemeContext);

  return (
    <Text
      weight={400}
      color={value ? searchFilters.button.active.label.color : searchFilters.button.inactive.label.color}
    >
      {(value && (
        <Text weight={500}>
          {value}
          {label}
        </Text>
      )) || <>{label}</>}
    </Text>
  );
};

export const BooleanFilter: React.FC<IBooleanFilter> = ({ name, label, maxLabelWidth, icon, iconColor }) => {
  return (
    <Field name={name}>
      {({ form, field }) => (
        <FilterButton
          variant={'secondary'}
          isActive={field.value === true}
          maxLabelWidth={maxLabelWidth}
          onClick={() => form.setFieldValue(field.name, !field.value)}
          subject={label}
        >
          <Group alignItems={'center'} gap={1}>
            {icon ? <Icon icon={icon} color={iconColor} size={14} /> : null}
            <StyledLabel label={label} value={field.value} />
          </Group>
        </FilterButton>
      )}
    </Field>
  );
};
