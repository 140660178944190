import { Cell } from '@tanstack/react-table';
import React from 'react';
import { CELL_EXPAND_ACTION_LABEL } from '../../../constants';
import { Tooltip } from '../../../../../tooltip/Tooltip.component';
import { IRowAction } from '../../../types';
import { ActionButton } from './ActionButton';
import { useActionItemPopover } from './hooks/action-item-popover.hook';

interface IActionItemProps<T> {
  action: IRowAction<T>;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipTitle: string | null;
  cell: Cell<any, unknown>;
}

export const ActionItem = React.memo<IActionItemProps<any>>(({ action, onClick, cell, tooltipTitle }) => {
  const { open, close } = useActionItemPopover();

  const handleClickAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    const Item = cell.column.columnDef.cellExpandRender;

    if (action.label === CELL_EXPAND_ACTION_LABEL) {
      const { clientX: x, clientY: y } = event;

      open({ x, y }, <Item {...cell.getContext()} closePopover={close} />);
    } else {
      onClick(event);
    }
  };

  return (
    <Tooltip openDelayInMs={200} title={tooltipTitle} placement="top">
      <ActionButton action={action} onClick={handleClickAction} />
    </Tooltip>
  );
});

ActionItem.displayName = 'ActionItem';
