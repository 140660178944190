import { useMemo, useState } from 'react';
import { get, isEqual, orderBy } from 'lodash';
import { ISearchRequestObject, ISort } from '@logz-build/typescript';
import { matchSorter } from 'match-sorter';

interface IInlineSearchParams<T = object> {
  fetch?: (searchRequestObject: ISearchRequestObject) => void;
  data: T[];
  fieldsToSearchOn?: Array<string | ((item: T) => string)>;
  filterMapper?: Record<string, string>;
  serverSideFilters?: Array<string | ((item: T) => string)>;
  // Use field-extractor when you have a field that is not primitive, and you have to sort by it
  fieldExtractor?: (item: T, field: keyof T) => any;
  customSortFns?: Partial<Record<keyof T, (first: T, second: T) => number>>;
  initialRequestObject?: ISearchRequestObject;
}

const isFiltersEqual = ({
  currFilters,
  lastFilters,
}: {
  currFilters: ISearchRequestObject['filter'];
  lastFilters: ISearchRequestObject['filter'];
}): boolean => {
  const { searchTerm: currSearchTerm, ...restCurrFilters } = currFilters;
  const { searchTerm: lastSearchTerm, ...restLastFilters } = lastFilters;

  return isEqual(restCurrFilters, restLastFilters);
};

export const sortByField = <T = object>({
  data,
  sort,
  fieldExtractor = (item, field) => item[field],
  customSortFns,
}: {
  data: T[];
  sort: ISort;
  fieldExtractor?: IInlineSearchParams<T>['fieldExtractor'];
  customSortFns?: IInlineSearchParams<T>['customSortFns'];
}): T[] => {
  if (!Object.values(sort ?? {}).length) {
    return [...data];
  }

  const { field, descending } = sort;
  const customSortFn = customSortFns?.[field];

  if (customSortFn) {
    const cloneData = [...data];

    cloneData.sort(customSortFn);

    if (descending) cloneData.reverse();

    return cloneData;
  }

  return orderBy(data, item => fieldExtractor(item, field as keyof T), [descending ? 'desc' : 'asc'] || []);
};

export const useInlineSearch = <T = object>({
  fetch,
  data,
  fieldsToSearchOn = [],
  filterMapper = {},
  serverSideFilters = [],
  fieldExtractor,
  customSortFns,
  initialRequestObject = {},
}: IInlineSearchParams<T>) => {
  const [lastSearchRequestObject, setLastSearchRequestObject] = useState<ISearchRequestObject>(initialRequestObject);

  const refreshData = async () => {
    return fetch?.(lastSearchRequestObject);
  };

  const extractServerFilters = (filters: ISearchRequestObject['filter']) => {
    if (!serverSideFilters?.length || !filters) return {};

    return Object.fromEntries(Object.entries(filters)?.filter(([key]) => serverSideFilters?.includes(key)));
  };

  const handleSearch = async (searchRequestObject: ISearchRequestObject) => {
    let shouldMakeRequest = true;

    if (serverSideFilters.length > 0) {
      const currFilters = extractServerFilters(searchRequestObject.filter);

      const lastFilters = extractServerFilters(lastSearchRequestObject.filter);

      shouldMakeRequest = !isFiltersEqual({ currFilters, lastFilters });
    }

    setLastSearchRequestObject(searchRequestObject);

    if (shouldMakeRequest) return fetch?.(searchRequestObject);
  };

  const filteredData = useMemo(() => {
    if (!data?.length) return;

    const { searchTerm, ...filters } = lastSearchRequestObject?.filter || {};

    if (!searchTerm && !Object.values(filters)?.length) {
      return data;
    }

    const stringFieldsToSearchOn = fieldsToSearchOn.filter(field => typeof field === 'string');
    const filterFields = Object.keys(filters).filter(field => stringFieldsToSearchOn.includes(field));

    const filtered = data.filter(item =>
      filterFields.every(filterFieldName => {
        if (!filters[filterFieldName]?.length) return true;

        const dataFieldName = filterMapper[filterFieldName] || filterFieldName;
        const dataFieldValue = get(item, dataFieldName)?.toString();

        return filters[filterFieldName].includes(dataFieldValue);
      }),
    );

    const sorterOptions = fieldsToSearchOn.length > 0 ? { keys: fieldsToSearchOn } : null;

    return matchSorter(filtered, searchTerm ?? '', sorterOptions);
  }, [lastSearchRequestObject.filter, lastSearchRequestObject.filter?.searchTerm, data]);

  const sortedData = useMemo(() => {
    if (!filteredData?.length) {
      return;
    }

    if (!lastSearchRequestObject?.sort) {
      return filteredData;
    }

    return sortByField<T>({ data: filteredData, sort: lastSearchRequestObject.sort[0], fieldExtractor, customSortFns });
  }, [lastSearchRequestObject?.sort?.[0]?.field, lastSearchRequestObject?.sort?.[0]?.descending, filteredData]);

  const pageData = useMemo(() => {
    if (!sortedData?.length) {
      return;
    }

    if (!lastSearchRequestObject?.pagination) {
      return sortedData;
    }

    return sortedData?.slice(
      (lastSearchRequestObject.pagination.pageNumber - 1) * lastSearchRequestObject.pagination.pageSize,
      lastSearchRequestObject.pagination.pageNumber * lastSearchRequestObject.pagination.pageSize,
    );
  }, [sortedData, lastSearchRequestObject?.pagination?.pageNumber, lastSearchRequestObject?.pagination?.pageSize]);

  return {
    pageData,
    pagination: lastSearchRequestObject.pagination,
    handleSearch,
    total: sortedData?.length,
    refreshData,
  };
};
