import React from 'react';
import styled from 'styled-components';
import { Button } from '@logz-ui/styleguide/components/Button/Button.component';
import { Group } from '@logz-ui/styleguide/components/containers/Group/Group.component';
import { DateRangePickerSubject } from '@logz-pkg/enums';

export type ActionsBarProps = {
  disabled?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  notification?: React.ReactNode;
};

const StyledGroup = styled(Group)`
  border-top: 1px solid ${({ theme }) => theme.datePicker.border};
`;

export const ActionsBar: React.FC<ActionsBarProps> = ({ disabled, onConfirm, onCancel, notification }) => {
  return (
    <StyledGroup p={4} width={'100%'} justifyContent={'space-between'}>
      <Group>{notification}</Group>
      <Group>
        <Button size={'s'} variant={'secondary'} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          subject={DateRangePickerSubject.AbsoluteTabApplyButton}
          size={'s'}
          onClick={onConfirm}
          disabled={disabled}
        >
          Apply
        </Button>
      </Group>
    </StyledGroup>
  );
};
