import React, { ComponentProps } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Text } from '../typography';

interface IHighlightedText extends ComponentProps<typeof Text> {
  textToHighlight?: string;
  fuzzy?: boolean;
  method?: 'bold' | 'mark';
  children: string;
}

const fuzzyMatch = (text, value) => {
  let valueIndex = 0;
  const indices = [];

  for (let i = 0; i < text.length; i += 1) {
    if (valueIndex < value.length && text[i].toLowerCase() === value[valueIndex].toLowerCase()) {
      indices.push(i);
      valueIndex += 1;
    }
  }

  return indices;
};

const getHighlightedText = (text: string, value: string, method: IHighlightedText['method']) => {
  const indices = fuzzyMatch(text, value);
  const highlightedParts = [];
  let lastIndex = 0;

  for (let i = 0; i < indices.length; i += 1) {
    const start = indices[i];
    let end = start;

    // Combine consecutive indices
    while (i < indices.length - 1 && indices[i + 1] === end + 1) {
      i += 1;
      end = indices[i];
    }

    // Add text before the match
    if (lastIndex < start) {
      highlightedParts.push(text.slice(lastIndex, start));
    }

    // Add the highlighted match
    highlightedParts.push(
      method === 'bold' ? (
        <b key={start}>{text.slice(start, end + 1)}</b>
      ) : (
        <mark key={start}>{text.slice(start, end + 1)}</mark>
      ),
    );
    lastIndex = end + 1;
  }

  // Add the remaining text after the last match
  if (lastIndex < text.length) {
    highlightedParts.push(text.slice(lastIndex));
  }

  return highlightedParts;
};

export const HighlightedText: React.FC<IHighlightedText> = ({
  children = '',
  textToHighlight,
  fuzzy = true,
  method = 'bold',
  ...options
}) => {
  if (!textToHighlight) return <Text {...options}>{children}</Text>;

  const lowerCaseText = children.toLowerCase();
  const lowerCaseHighlight = textToHighlight.toLowerCase();

  // Check for continuous string match
  const searchIndex = lowerCaseText.indexOf(lowerCaseHighlight);

  if (searchIndex !== -1) {
    // Highlight continuous string match
    const endMatchIndex = searchIndex + textToHighlight.length;
    const beforeMatch = children.slice(0, searchIndex);
    const match = children.slice(searchIndex, endMatchIndex);
    const afterMatch = children.slice(endMatchIndex);

    return (
      <Text {...options}>
        {beforeMatch}
        {method === 'bold' ? <b>{match}</b> : <mark>{match}</mark>}
        {afterMatch}
      </Text>
    );
  }

  if (!fuzzy) return <Text {...options}>{children}</Text>;

  // Highlight fuzzy string match
  const highlightedText = getHighlightedText(children, textToHighlight, method);

  return (
    <Text {...generateLogzTestAttributes({ context: 'highlighted-text' })} {...options}>
      {highlightedText.map((part, index) => (
        <React.Fragment key={index}>{part}</React.Fragment>
      ))}
    </Text>
  );
};
