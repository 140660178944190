export enum SecurityRuleConstants {
  PRECONFIGURED_BY_LOGZIO = 'Preconfigured by Logz.io',
  LOGZIO_RULE = 'Logz.io rule',
}

export enum SecurityRulesStates {
  Edit = 'dashboard.security.rules-v2019-edit',
  New = 'dashboard.security.rules-v2019-new',
  Duplicate = 'dashboard.security.rules-v2019-duplicate',
}

export enum SigmaRulesSubjects {
  SigmaCleanText = 'sigma-clean-text',
  SigmaConvert = 'sigma-convert',
  SigmaErrorNotification = 'sigma-error-notification',
  SigmaConvertorButton = 'sigma-convertor-button',
  SigmaRuleEditor = 'sigma-rule-editor',
}
