import styled from 'styled-components';

export const NotificationWrapper = styled.div`
  .rc-notification-notice-wrapper {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .rc-notification {
    z-index: 10001;
  }

  .rc-notification .rc-notification-notice {
    background-color: ${({ theme }) => theme.notification.backgroundColor};
    border: 1px solid ${({ theme }) => theme.notification.borderColor};
    box-shadow: 0 19px 15px ${({ theme }) => theme.notification.shadowFrom},
      0 15px 12px ${({ theme }) => theme.notification.shadowTo};
    border-radius: unset;
    padding: 15px 15px 15px 5px;
    margin: 0;
    z-index: 9999998;
  }

  .rc-notification .rc-notification-notice-close {
    z-index: 9999999999;
  }

  .rc-notification .rc-notification-notice:not(:first-child) {
    margin-top: 10px;
  }

  .rc-notification-notice.notification-type-success {
    border-left: solid 3px ${({ theme }) => theme.notification.success.accentColor};
  }

  .rc-notification-notice.notification-type-info {
    border-left: solid 3px ${({ theme }) => theme.notification.info.accentColor};
  }

  .rc-notification-notice.notification-type-danger {
    border-left: solid 3px ${({ theme }) => theme.notification.danger.accentColor};
  }

  .rc-notification-notice.notification-type-warning {
    border-left: solid 3px ${({ theme }) => theme.notification.warning.accentColor};
  }

  .rc-notification-notice.notification-type-progress {
    border-left: solid 3px ${({ theme }) => theme.notification.progress.accentColor};
  }

  .rc-notification .rc-notification-notice-close {
    right: 15px;
    top: 20px;
    line-height: 0;
  }

  .rc-notification-stack > .rc-notification-notice-wrapper:nth-last-child(1) {
    right: 10px;
  }
  .rc-notification-stack > .rc-notification-notice-wrapper:nth-last-child(2) {
    right: 9px;
  }
  .rc-notification-stack > .rc-notification-notice-wrapper:nth-last-child(3) {
    right: 8px;
  }
  .rc-notification-stack > .rc-notification-notice-wrapper:not(:nth-last-child(-n + 3)) {
    right: 7px;
  }
`;
