export enum CostManagementSubjects {
  CostManagementPage = 'cost-management-page',
  UsageTable = 'usage-table',
  UsageTableEmptyState = 'usage-table-empty-state',
}

export enum CostManagementTabs {
  Logs = 'logs',
  Metrics = 'metrics',
}
