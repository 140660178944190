import React from 'react';
import styled from 'styled-components';
import { space } from '../utils';
import { useHover } from '@logz-pkg/react-hooks';

const propTypes = {
  ...space.propTypes,
};

const defaultProps = {
  ...{ p: 0, m: 0 },
};

const HeaderDropdownStyled = styled.div`
  display: flex;
  position: relative;
  ${space};
`;

/**
 * @deprecated
 */
export const HeaderDropdown = props => {
  const { ref, isHovering } = useHover();

  return (
    <IsHoveredContext.Provider value={isHovering}>
      <HeaderDropdownStyled {...props} ref={ref} />
    </IsHoveredContext.Provider>
  );
};

export const IsHoveredContext = React.createContext(false);

HeaderDropdown.defaultProps = defaultProps;
HeaderDropdown.propTypes = propTypes;
