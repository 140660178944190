import React from 'react';
import type { IMultiTagProps } from '../MultiTag.component';
import { StepForm } from './StepForm';
import { StepText } from './StepText';
import { useMultiTag } from './TagContainer';

export interface IStepProps {
  step: IMultiTagProps['tag']['steps'][number];
  index: number;
}

export const Step: React.FC<IStepProps> = ({ step, index }) => {
  const { state, isEditable } = useMultiTag();

  return step.selected && state !== 'preview' && isEditable ? (
    <StepForm {...{ index, step }} />
  ) : (
    <StepText {...{ step, index }} />
  );
};
