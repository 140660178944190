export enum ScheduledReportsSuccessNotifications {
  Create = 'Report created',
  Update = 'Report updated',
  Delete = 'Report deleted',
  Test = 'Test Report Sent',
  Activate = 'Enabled report',
  Deactivate = 'Disabled report',
}

export enum ScheduledReportsNotificationSubjects {
  Create = 'create-report',
  Update = 'update-report',
  Delete = 'delete-report',
  Test = 'test-report',
  Deactivate = 'deactivate-report',
  Activate = 'activate-report',
}

export enum ScheduledReportsEmptyState {
  Title = 'Nothing to report, Captain.',
  Subtitle = "You haven't set up any reports yet. Schedule a new report to get a regular view of the system's health.",
}

export enum ScheduledReportsErrors {
  DeactivatedByServer = 'We had to disable this report. Please fix any issues and reactivate.',
}

export enum ScheduledReportTestSubjects {
  EditPage = 'scheduled-reports-edit-page',
}
