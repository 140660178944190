export enum MetricsRollupsSubjects {
  Table = 'metrics-rollups-table',
  MetricsRollupsPage = 'metrics-rollups',
  TableEmptyState = 'metrics-rollups-table-empty-state',
  SearchRollup = 'search-rollup',
  NewRollupButton = 'new-rollup-button',
  CreateRollup = 'create-rollup',
  UpdateRollup = 'update-rollup',
  DeleteRollup = 'delete-rollup',
  ActivateRollup = 'activate-rollup',
  DeactivateRollup = 'deactivate-rollup',
  AccountIdSelect = 'account-id-select',
  MetricNameSelect = 'metric-mame-select',
  ModalCheckbox = 'modal-checkbox',
  Modal = 'metrics-drop-rollup-modal',
  AddRollupButton = 'add-rollup-button',
  MetricFieldNameSelect = 'metric-field-name-select',
  MetricFieldValueInput = 'metric-field-value-input',
  ActivationToggle = 'activation-toggle',
  BulkDeleteRollup = 'bulk-delete-rollups',
  BulkCreateRollup = 'bulk-create-rollups',
}

export enum RollupFunction {
  COUNT = 'COUNT',
  LAST = 'LAST',
  MAX = 'MAX',
  MEAN = 'MEAN',
  MEDIAN = 'MEDIAN',
  MIN = 'MIN',
  P10 = 'P10',
  P20 = 'P20',
  P30 = 'P30',
  P40 = 'P40',
  P50 = 'P50',
  P60 = 'P60',
  P70 = 'P70',
  P80 = 'P80',
  P90 = 'P90',
  P95 = 'P95',
  P99 = 'P99',
  P999 = 'P999',
  P9999 = 'P9999',
  STDEV = 'STDEV',
  SUM = 'SUM',
  SUMSQ = 'SUMSQ',
}
