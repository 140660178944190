import { grayPalette } from '../../themes/colors';

export const popMenuLightTheme = {
  buttonBar: {
    backgroundColor: grayPalette[100],
  },
  panel: {
    dividerColor: grayPalette[300],
  },
};
