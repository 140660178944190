export type PerformanceEventMeta = Record<string, string | number | boolean>;

const getTimestamp = () => new Date().getTime();

export class PerformanceService<T extends string = string> {
  protected events: Partial<Record<T, { start: number; end: number; meta: Record<string, any> }>> = {};

  start(name: T, meta: PerformanceEventMeta = {}) {
    this.events[name] = { start: getTimestamp(), end: null, meta };
  }

  setMeta(name: T, meta: PerformanceEventMeta = {}) {
    if (!this.events[name]) return;

    this.events[name] = { ...this.events[name], meta: { ...this.events[name].meta, ...meta } };
  }

  stop(name: T, endMeta: PerformanceEventMeta = {}) {
    if (!this.events[name]) return;

    this.events[name] = { ...this.events[name], end: getTimestamp(), meta: { ...this.events[name].meta, ...endMeta } };
  }

  deleteMark(name: T) {
    delete this.events[name];
  }

  getMeasurement(name: T, sendMeta: PerformanceEventMeta = {}) {
    if (!this.events[name]) return;

    if (!this.events[name].end) this.stop(name);

    const { start, end, meta } = this.events[name];
    const durationMs = end - start;

    this.deleteMark(name);

    return { ...meta, ...sendMeta, name, durationMs };
  }

  isRunning(name: T) {
    return !!this.events[name];
  }
}

export const performanceService = new PerformanceService();
