export type ColorPalette = {
  25: string;
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

type GradientColorPalette = {
  radiant: string;
};

const gray: ColorPalette = {
  25: '#FCFCFD',
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#EAECF0',
  300: '#D0D5DD',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
};

const error: ColorPalette = {
  25: '#FFFBFA',
  50: '#FEF3F2',
  100: '#FEE4E2',
  200: '#FECDCA',
  300: '#FDA29B',
  400: '#F97066',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
  900: '#7A271A',
};

const seriousWarning: ColorPalette = {
  25: '#FFF9F5',
  50: '#FFF4ED',
  100: '#FFE6D5',
  200: '#FFD6AE',
  300: '#FF9C66',
  400: '#FF692E',
  500: '#FF4405',
  600: '#E62E05',
  700: '#BC1B06',
  800: '#97180C',
  900: '#771A0D',
};

const regularWarning: ColorPalette = {
  25: '#FFFCF5',
  50: '#FFFAEB',
  100: '#FEF0C7',
  200: '#FEDF89',
  300: '#FEC84B',
  400: '#FDB022',
  500: '#F79009',
  600: '#DC6803',
  700: '#B54708',
  800: '#93370D',
  900: '#7A2E0E',
};

const success: ColorPalette = {
  25: '#F6FEF9',
  50: '#ECFDF3',
  100: '#D1FADF',
  200: '#A6F4C5',
  300: '#6CE9A6',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027A48',
  800: '#05603A',
  900: '#054F31',
};

const green: ColorPalette = {
  25: '#F6FEF9',
  50: '#EDFCF2',
  100: '#D3F8DF',
  200: '#AAF0C4',
  300: '#73E2A3',
  400: '#3CCB7F',
  500: '#16B364',
  600: '#099250',
  700: '#087443',
  800: '#095C37',
  900: '#084C2E',
};

const teal: ColorPalette = {
  25: '#F6FEFC',
  50: '#F0FDF9',
  100: '#CCFBEF',
  200: '#99F6E0',
  300: '#5FE9D0',
  400: '#2ED3B7',
  500: '#15B79E',
  600: '#0E9384',
  700: '#107569',
  800: '#125D56',
  900: '#134E48',
};

const blue: ColorPalette = {
  25: '#F5FAFF',
  50: '#EFF8FF',
  100: '#D1E9FF',
  200: '#B2DDFF',
  300: '#84CAFF',
  400: '#53B1FD',
  500: '#2E90FA',
  600: '#1570EF',
  700: '#175CD3',
  800: '#1849A9',
  900: '#194185',
};

const indigo: ColorPalette = {
  25: '#F5F8FF',
  50: '#EEF4FF',
  100: '#E0EAFF',
  200: '#C7D7FE',
  300: '#A4BCFD',
  400: '#8098F9',
  500: '#6172F3',
  600: '#444CE7',
  700: '#3538CD',
  800: '#2D31A6',
  900: '#2D3282',
};

const darkBlue: ColorPalette = {
  25: '#F5F8FF',
  50: '#EFF4FF',
  100: '#D1E0FF',
  200: '#B2CCFF',
  300: '#84ADFF',
  400: '#528BFF',
  500: '#2970FF',
  600: '#155EEF',
  700: '#004EEB',
  800: '#0040C1',
  900: '#00359E',
};

const violet: ColorPalette = {
  25: '#FBFAFF',
  50: '#F5F3FF',
  100: '#ECE9FE',
  200: '#DDD6FE',
  300: '#C3B5FD',
  400: '#A48AFB',
  500: '#875BF7',
  600: '#7839EE',
  700: '#6927DA',
  800: '#5720B7',
  900: '#491C96',
};

const pink: ColorPalette = {
  25: '#FEF6FB',
  50: '#FDF2FA',
  100: '#FCE7F6',
  200: '#FCCEEE',
  300: '#FAA7E0',
  400: '#F670C7',
  500: '#EE46BC',
  600: '#DD2590',
  700: '#C11574',
  800: '#9E165F',
  900: '#851651',
};

const orange: ColorPalette = {
  25: '#FEFAF5',
  50: '#FEF6EE',
  100: '#FDEAD7',
  200: '#F9DBAF',
  300: '#F7B27A',
  400: '#F38744',
  500: '#EF6820',
  600: '#E04F16',
  700: '#B93815',
  800: '#932F19',
  900: '#772917',
};

const yellow: ColorPalette = {
  25: '#FEFDF0',
  50: '#FEFBE8',
  100: '#FEF7C3',
  200: '#FEEE95',
  300: '#FDE272',
  400: '#FAC515',
  500: '#EAAA08',
  600: '#CA8504',
  700: '#A15C07',
  800: '#854A0E',
  900: '#713B12',
};

const gradient: GradientColorPalette = {
  radiant: `linear-gradient(95.34deg, ${blue[600]} 30.62%, ${pink[500]} 79.47%, ${yellow[400]} 112.72%)`,
};

export const colorsV2 = {
  gray,
  error,
  seriousWarning,
  regularWarning,
  success,
  green,
  teal,
  blue,
  indigo,
  darkBlue,
  violet,
  pink,
  orange,
  yellow,
  gradient,
};

type ColorName = keyof typeof colorsV2;

export type ColorV2 = {
  [K in ColorName]: K extends 'gradient' ? `${K}.${keyof GradientColorPalette}` : `${K}.${keyof ColorPalette}`;
}[keyof typeof colorsV2];
