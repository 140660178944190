import { FunctionComponent, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useUpdateEffect } from '@logz-pkg/react-hooks';
import { StatusIcon, autoCompleteOffValue, IInputProps } from '../Input.component';
import { Icon } from '../../icons/Icon.component';
import { Spinner } from '../../progress/Spinner/Spinner.component';
import { extractContainerProps } from '../../utils';
import { ClearIndicator, CompositeInput, CompositeInputContainer } from '../shared/composite.input';

export const InputSearch: FunctionComponent<IInputProps> = ({
  onChange = () => {},
  type = 'search',
  placeholder = 'Search',
  debounceTime = 300,
  subject = 'search',
  context = 'input',
  variant = 'primary',
  loading = false,
  disableAutoComplete = true,
  defaultValue = '',
  disableEnter = false,
  ...props
}) => {
  const [value, setValue] = useState(defaultValue || '');
  const { containerProps, ...rest } = extractContainerProps(props);

  // The purpose of the hook is to allow the parent's competent to update the value from above,
  // mainly intended to allow the value to be updated when going back/forward by the browser buttons
  useUpdateEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue ?? '');
      onChange(defaultValue ?? '');
    }
  }, [defaultValue]);

  const handleClear = () => {
    setValue('');
    onChange?.('');
  };

  const handleKeyPress = event => {
    if (!disableEnter && event.key === 'Enter') {
      const { value } = event.target;

      setValue(value);
      onChange(value);
    }
  };

  const debouncedOnChange = useMemo(() => debounce(onChange, debounceTime), [onChange]);
  const handleChange = searchTerm => {
    setValue(searchTerm);
    debouncedOnChange(searchTerm);
  };

  return (
    <CompositeInputContainer flex={1} {...containerProps} subject={subject} context={context}>
      <CompositeInput
        pr={'25px'}
        value={value}
        autocomplete={disableAutoComplete ? autoCompleteOffValue : null}
        type={type}
        placeholder={placeholder}
        {...rest}
        variant={variant}
        onKeyPress={e => handleKeyPress(e)}
        onChange={e => handleChange(e.target.value)}
      />
      <StatusIcon>
        {loading && <Spinner size="s" />}
        {!loading && value && <ClearIndicator onClick={handleClear} subject={`${subject}-clear-indicator`} />}
        {!loading && !value && <Icon icon={'magnifying-glass-regular'} />}
      </StatusIcon>
    </CompositeInputContainer>
  );
};

InputSearch.displayName = 'Input.search';
