import React from 'react';
import styled from 'styled-components';
import { Resizer } from './Resizer';

const StyledHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};

  th:last-child {
    flex-grow: 1;
    flex-shrink: 0;
    ${Resizer} {
      display: none;
    }
  }
`;

interface IHeadProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const Head: React.FC<IHeadProps> = ({ children, style, ...props }) => {
  return (
    <StyledHead style={style} {...props}>
      {children}
    </StyledHead>
  );
};
