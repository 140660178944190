import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import React, { useCallback, useState } from 'react';
import { Button } from '../Button/Button.component';
import icons from '../icons/font-awesome/metadata/icons.json';
import { Icon } from '../icons/Icon.component';
import { Popover } from '../popover/Popover.component';
import { PopoverPlacement } from '../popover/types';
import { PopMenuPanel } from './components/PopMenuPanel';
import { ButtonsConfig } from './types';

export interface IPopMenuProps {
  title: string;
  subTitle?: string;
  showChevron?: boolean;
  icon?: keyof typeof icons;
  placement?: PopoverPlacement;
  onOpen?: () => void;
  onClose?: () => void;
  onButtonClick?: (isOpen: boolean) => void;
  menuComponent: React.ReactElement;
  buttonsConfig: ButtonsConfig;
  width?: string | number;
  height?: string | number;
  subject?: string;
}

const BasePopMenu = ({ children, subject }) => (
  <div {...generateLogzTestAttributes({ context: 'pop-menu', subject })}>{children}</div>
);

export const PopMenu: React.FC<IPopMenuProps> = ({
  subject = 'pop-menu',
  title,
  subTitle,
  icon,
  placement = 'bottom-start',
  showChevron,
  menuComponent,
  onOpen,
  onClose,
  onButtonClick,
  buttonsConfig,
  width,
  height,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick(isOpen);
    }
  }, [onButtonClick, isOpen]);

  const handleOpen = useCallback(() => {
    setIsOpen(true);

    if (onOpen) {
      onOpen();
    }
  }, [onOpen]);

  const handleClose = useCallback(() => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <BasePopMenu subject={subject}>
      <Popover
        topOffset={6}
        appendToBody
        onOpen={handleOpen}
        onClose={handleClose}
        subject={`${subject}-popover`}
        placement={placement}
        Content={{
          Component: PopMenuPanel,
          props: {
            title: subTitle ? subTitle : title,
            menuComponent,
            buttonsConfig,
            width,
            height,
          } as Omit<React.ComponentProps<typeof PopMenuPanel>, 'closePopover'>,
        }}
      >
        <Button
          variant={'secondary'}
          icon={icon}
          subject={`${subject}-button`}
          endIcon={showChevron ? <Icon flip={isOpen ? 'vertical' : undefined} icon={'chevron-down-regular'} /> : null}
          onClick={handleButtonClick}
        >
          {title}
        </Button>
      </Popover>
    </BasePopMenu>
  );
};
