import React, { FunctionComponent, LazyExoticComponent } from 'react';
import { LogzioThemeProvider } from '../themes';

export const LazyLoader: FunctionComponent<{
  Lazy: LazyExoticComponent<any>;
  fallback: any;
}> = ({ fallback = null, Lazy, ...props }) => {
  return (
    <LogzioThemeProvider>
      <React.Suspense fallback={fallback}>
        <Lazy {...props} />
      </React.Suspense>
    </LogzioThemeProvider>
  );
};
