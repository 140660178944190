import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Cell, Row as RTrow } from '@tanstack/react-table';
import { isNil } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { IIconProps } from '../../../../icons/Icon.component';
import { CELL_EXPAND_ACTION_LABEL } from '../../constants';
import { IDataTableProps, IRowAction } from '../../types';
import { ActionItem } from './components/ActionItem';
import { ActionsContainer, StyledActions } from './components/Actions.styles';

interface IActionsProps<T extends object> {
  actions: ReturnType<IDataTableProps<T>['getActions']>;
  row: RTrow<T>;
  cell: Cell<T, unknown>;
  isFlipped: boolean;
  subject?: string;
}

export const Actions = <T extends object>({ actions, row, cell, subject, isFlipped }: IActionsProps<T>) => {
  const { cellExpandRender } = cell.column.columnDef;
  const modifiedActions = useMemo(
    () =>
      !isNil(cellExpandRender)
        ? [
            ...actions,
            {
              label: CELL_EXPAND_ACTION_LABEL,
              icon: 'arrow-up-right-and-arrow-down-left-from-center-regular' as IIconProps['icon'],
              onClick: null,
              showTooltip: true,
              shouldDisplay: () => !cell.id.includes('@timestamp'),
            },
          ]
        : actions,
    [actions, cellExpandRender],
  );
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, action: IRowAction<T>) => {
      event.stopPropagation();
      event.preventDefault();
      action.onClick(row, cell);
    },
    [row, cell],
  );

  const renderedActions = useMemo(
    () =>
      modifiedActions
        ?.filter(action => !action.shouldDisplay || action.shouldDisplay(row))
        .map(action => (
          <ActionItem
            cell={cell}
            key={`${action.icon}-${action.label}`}
            action={action}
            onClick={event => handleClick(event, action)}
            tooltipTitle={action.showTooltip ? action.label : null}
          />
        )),
    [actions, row, handleClick],
  );

  if (!actions?.length) return null;

  return (
    <ActionsContainer isFlipped={isFlipped} className="actions">
      <StyledActions {...generateLogzTestAttributes({ subject, context: 'table-cell-actions' })}>
        {renderedActions}
      </StyledActions>
    </ActionsContainer>
  );
};

Actions.displayName = 'Actions';
