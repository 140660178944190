import styled from 'styled-components';
import React, { ElementType } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Divider } from '../../Divider/Divider.component';

const PlainTableHead = styled.thead`
  background-color: transparent;
`;

const PlainTableRow = styled.tr`
  border-bottom: transparent;
`;

const PlainTableDataCell = styled.td`
  vertical-align: middle;
`;
const DividerTableDataCell = styled.td`
  vertical-align: middle;
  padding: 0 6px;
`;

const PlainTableBody = ({ children, ...props }) => (
  <tbody {...props}>
    <PlainTableRow>
      <DividerTableDataCell colSpan={30}>
        <Divider color={'gray.700'} />
      </DividerTableDataCell>
    </PlainTableRow>
    {children}
  </tbody>
);

interface IPlainTableProps {
  subject?: string;
  children?: React.ReactNode;
}

interface ExtendedTable extends React.FC<IPlainTableProps> {
  Head: ElementType;
  Row: ElementType;
  Cell: ElementType;
  Divider: ElementType;
  Body: ElementType;
}

const Table: ExtendedTable = ({ subject, ...props }) => (
  <table {...generateLogzTestAttributes({ context: 'plain-table', subject })} width={'100%'} {...props} />
);

Table.Head = PlainTableHead;
Table.Row = PlainTableRow;
Table.Cell = PlainTableDataCell;
Table.Divider = DividerTableDataCell;
Table.Body = PlainTableBody;

export const PlainTable = Table;
