import {
  bluePalette,
  grayPalette,
  greenPalette,
  redPalette,
  royalBluePalette,
  yellowPalette,
} from '../../themes/colors';

export const inlineNotificationLightTheme = {
  variants: {
    success: {
      light: {
        icon: 'circle-check-solid' as const,
        textColor: royalBluePalette[1000],
        backgroundColor: greenPalette[100],
        accentColor: greenPalette[500],
        iconColor: greenPalette[500],
      },
      bold: {
        icon: 'circle-check-solid' as const,
        textColor: royalBluePalette[1000],
        backgroundColor: greenPalette[500],
        accentColor: greenPalette[600],
        iconColor: royalBluePalette[1000],
      },
    },
    warning: {
      light: {
        icon: 'triangle-exclamation-solid' as const,
        textColor: royalBluePalette[1000],
        backgroundColor: yellowPalette[300],
        accentColor: yellowPalette[700],
        iconColor: yellowPalette[700],
      },
      bold: {
        icon: 'triangle-exclamation-solid' as const,
        textColor: royalBluePalette[1000],
        backgroundColor: yellowPalette[600],
        accentColor: yellowPalette[800],
        iconColor: royalBluePalette[1000],
      },
    },
    danger: {
      light: {
        icon: 'diamond-exclamation-solid' as const,
        textColor: royalBluePalette[1000],
        backgroundColor: redPalette[100],
        accentColor: redPalette[600],
        iconColor: redPalette[600],
      },
      bold: {
        icon: 'diamond-exclamation-solid' as const,
        textColor: 'white',
        backgroundColor: redPalette[600],
        accentColor: redPalette[700],
        iconColor: 'white',
      },
    },
    announcement: {
      light: {
        icon: 'bullhorn-solid' as const,
        textColor: royalBluePalette[1000],
        backgroundColor: bluePalette[200],
        accentColor: bluePalette[700],
        iconColor: bluePalette[800],
      },
      bold: {
        icon: 'bullhorn-solid' as const,
        textColor: 'white',
        backgroundColor: bluePalette[700],
        accentColor: bluePalette[800],
        iconColor: 'white',
      },
    },
    info: {
      light: {
        icon: 'circle-info-solid' as const,
        textColor: royalBluePalette[1000],
        backgroundColor: grayPalette[200],
        accentColor: grayPalette[700],
        iconColor: grayPalette[700],
      },
      bold: {
        icon: 'circle-info-solid' as const,
        textColor: 'white',
        accentColor: grayPalette[800],
        backgroundColor: grayPalette[700],
        iconColor: 'white',
      },
    },
  },
};
