import { FunctionComponent } from 'react';
import { Flex } from '../containers/Flex/Flex.component';
import { Card } from '../Card/Card.component';
import { Group } from '../containers/Group/Group.component';
import { Text } from '../typography/Text/Text.component';
import { colors } from '../themes/colors';
import { StarsCollection } from './StarsCollection';

interface IFeedbackProps {
  subject?: string;
  onClick?: (index: number) => void;
  maxRate?: number;
  rate: number;
}

export const Feedback: FunctionComponent<IFeedbackProps> = ({ subject = '', maxRate = 5, rate, onClick }) => (
  <Card p={6} backgroundColor={colors.blue[100]} width={'100%'}>
    <Group vertical fullWidth alignItems="center">
      <Flex mb={3}>
        <Text weight={600} color={colors.royalBlue[1000]} size={14}>
          How helpful was this answer?
        </Text>
      </Flex>
      <StarsCollection onClick={onClick} maxRate={maxRate} rate={rate} />
      <Group justifyContent="space-between" width={'220px'}>
        <Text size={12} color={colors.royalBlue[600]}>
          Not helpful
        </Text>
        <Text size={12} color={colors.royalBlue[600]}>
          Very helpful
        </Text>
      </Group>
    </Group>
  </Card>
);
