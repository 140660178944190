import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from '../../icons/Icon.component';
import { semanticColor } from '../../themes';
import { space } from '../../utils';
import { statusFontStyle } from './Note.styles';

const NoteWrapper = styled.div`
  display: flex;
  gap: 6px;
  ${statusFontStyle}
  ${space}
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.sizes.text.medium};
`;

const StyledIcon = styled(Icon)`
  line-height: inherit;
`;

const TextLabel = styled.span<{ semantic: string }>`
  color: ${semanticColor};
  font-weight: 600;
  font-size: 16px;
  margin-right: 6px;
`;

export const iconByVariant = {
  accent: 'triangle-exclamation-solid',
  announcement: 'bullhorn-solid',
  danger: 'diamond-exclamation-solid',
  info: 'circle-info-solid',
  warning: 'triangle-exclamation-solid',
  success: 'circle-check-solid',

  notice: 'thumbtack-solid',
  help: 'circle-question-solid',
  critical: 'diamond-exclamation-solid',
};

const IconOrText = ({ variant, text, color }) => {
  if (text) {
    return <TextLabel semantic={variant}>{text}</TextLabel>;
  }

  if (variant) return <StyledIcon color={color} icon={iconByVariant[variant]} />;
};

export const Note = props => {
  const { semantic } = useContext(ThemeContext).colors;

  return (
    <NoteWrapper {...props}>
      {IconOrText({ ...props, color: semantic[props.variant] })}
      <span>{props.children}</span>
    </NoteWrapper>
  );
};

Note.propTypes = {
  variant: PropTypes.oneOf(['danger', 'warning', 'success', 'info', 'accent']),
  type: PropTypes.oneOf(['status', 'text']).isRequired,
  inline: PropTypes.bool,
};

Note.displayName = 'Note';
