import { FunctionComponent, useContext, useState } from 'react';
import { PaddingProps, SpaceProps } from 'styled-system';
import styled, { css, ThemeContext } from 'styled-components';
import { Group } from '../containers/Group/Group.component';
import { Card } from '../Card/Card.component';
import { Icon } from '../icons/Icon.component';
import { Text } from '../typography';
import { Flex } from '../containers/Flex/Flex.component';

export interface IInlineNotificationProps extends SpaceProps, PaddingProps {
  variant: 'success' | 'warning' | 'danger' | 'announcement' | 'info';
  title?: string | JSX.Element;
  noIcon?: boolean;
  accent?: boolean;
  children?: any;
  subject?: string;
  bold?: boolean;
  noClose?: boolean;
  onClose?: () => any;
  sharpCorners?: boolean;
  fullWidth?: boolean;
  shouldCenterIcon?: boolean;
}

const StyledIcon = styled(Icon)`
  margin-left: auto;
  padding: 2px 0 2px 2px;
  :hover {
    cursor: pointer;
  }
`;

const StyledCard = styled(Card)<{ sharpCorners?: boolean }>`
  ${({ sharpCorners }) =>
    sharpCorners &&
    css`
      border-radius: 0;
    `}
`;

export const InlineNotification: FunctionComponent<IInlineNotificationProps> = ({
  title,
  noIcon = false,
  children,
  variant,
  subject,
  onClose,
  bold = false,
  noClose = false,
  accent = false,
  fullWidth = false,
  shouldCenterIcon = true,
  ...props
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState(true);

  const {
    inlineNotification: { variants },
  } = useContext(ThemeContext);

  const currentVariant = bold ? variants[variant].bold : variants[variant].light;

  const close = () => {
    if (onClose) {
      onClose();
    }

    setIsOpenInternal(false);
  };

  if (!isOpenInternal) return null;

  return (
    <StyledCard
      borderless
      accentLocation={'left'}
      backgroundColor={currentVariant.backgroundColor}
      accentColor={accent && currentVariant.accentColor}
      p={4}
      pr={5}
      context={`inline-notification-${variant}`}
      subject={subject}
      width={fullWidth ? '100%' : null}
      {...props}
    >
      <Group justifyContent="space-between" fullWidth>
        <Group alignItems={(!children || !title) && shouldCenterIcon ? 'center' : 'flex-start'}>
          {!noIcon && (
            <Flex justifyContent="center" alignItems="center">
              <Icon color={currentVariant.iconColor} icon={currentVariant.icon} size={16} />
            </Flex>
          )}
          <Group vertical>
            {typeof title === 'string' ? (
              <Text weight={700} color={currentVariant.textColor}>
                {title}
              </Text>
            ) : (
              title
            )}
            {children && (
              <Text weight={400} color={currentVariant.textColor}>
                {children}
              </Text>
            )}
          </Group>
        </Group>
        {!noClose && <StyledIcon color={currentVariant.textColor} icon={'xmark-regular'} size={14} onClick={close} />}
      </Group>
    </StyledCard>
  );
};

InlineNotification.displayName = 'InlineNotification';
