import styled from 'styled-components';

export const MenuItemDivider = styled.hr`
  height: 0;
  background: none;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.menu.item_divider_color};
  margin-block: 4px;
  width: 100%;
`;
