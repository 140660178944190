import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../Button/Button.component';
import { ITagProps } from '../Tag.component';

const resetStyles = css`
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray[1000]};

  &:hover {
    ${resetStyles}
  }

  &:focus {
    ${resetStyles}
  }
`;

interface IRemoveButtonProps {
  value: React.ReactNode;
  onRemove: ITagProps['onRemove'];
}

export const RemoveButton: React.FC<IRemoveButtonProps> = ({ value, onRemove }) => {
  const handleRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onRemove(value);
  };

  return (
    <StyledButton
      subject={'tag-remove'}
      round
      size="xs"
      icon={'xmark-regular'}
      variant={'tertiary'}
      onClick={handleRemove}
    />
  );
};
