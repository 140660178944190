export enum AppMode {
  OPERATIONS = 'OPERATIONS',
  SECURITY = 'SECURITY',
  NONE = 'NONE',
}

export interface IAppModeValue {
  value: AppMode;
  normalized: string;
  rootState: string;
  kibanaState: string;
  kibanaHash: string;
}
