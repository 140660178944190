import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import React, { forwardRef, FunctionComponent } from 'react';
import { LinearProgress } from '../../progress/LinearProgress/LinearProgress.component';
import { Text } from '../../typography';
import { ContainerBorderWidth, ContainerSize } from './container.enum';
import { IContainerProps } from './Container.types';
import { StyledContainer } from './Container.styles';

const PrimaryContainer = forwardRef<HTMLDivElement, IContainerProps>(
  (
    {
      isLoading = false,
      subject,
      variant,
      shadow = 'none',
      fontFamily = 'base',
      borderWidth = ContainerBorderWidth.Small,
      children,
      context,
      ...props
    },
    ref,
  ) => (
    <StyledContainer
      boxShadow={shadow}
      isLoading={isLoading}
      {...((subject && generateLogzTestAttributes({ context: context || 'container', subject })) || {})}
      variant={variant}
      fontFamily={fontFamily}
      borderWidth={borderWidth}
      ref={ref}
      {...props}
    >
      {isLoading && <LinearProgress />}
      {children}
    </StyledContainer>
  ),
);

PrimaryContainer.displayName = 'PrimaryContainer';

type ContainerProps = typeof PrimaryContainer & {
  EmptyState: React.ElementType;
  Size: typeof ContainerSize;
};

export const Container = PrimaryContainer as ContainerProps;

Container.displayName = 'Container';

const EmptyStateContainer: FunctionComponent<{ children: React.ReactNode }> = ({ children, ...props }) => (
  <Container mt={12} textAlign="center" {...props}>
    <Text size={18} variant={'faded'} mb={5}>
      {children}
    </Text>
  </Container>
);

Object.defineProperty(PrimaryContainer, 'EmptyState', { value: EmptyStateContainer });
Object.defineProperty(PrimaryContainer, 'Size', { value: ContainerSize });
