import React, { FunctionComponent } from 'react';
import { PaddingProps, MarginProps, FlexProps } from 'styled-system';
import { Flex } from '../../containers/Flex/Flex.component';
import { SecondarySidenavContainer, SecondarySidenavDrawer } from './Secondary-sidenav.styles';

export interface ISecondarySidenavDrawerProps extends PaddingProps, MarginProps, FlexProps {
  isOpen: boolean;
  name: string;
  direction: string;
  width: string;
  subject?: string;
  children: React.ReactNode;
}

export const SecondarySidenav: FunctionComponent<ISecondarySidenavDrawerProps> = ({
  isOpen,
  children,
  name,
  direction,
  width,
  subject,
  ...layoutProps
}) => (
  <SecondarySidenavContainer>
    <SecondarySidenavDrawer open={isOpen} name={name} direction={direction} width={width} hideBackdrop>
      <Flex flexDirection={'column'} subject={subject} {...layoutProps}>
        {children}
      </Flex>
    </SecondarySidenavDrawer>
  </SecondarySidenavContainer>
);

SecondarySidenav.displayName = 'Sidenav.Secondary';
