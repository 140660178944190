export const baseVariants = {
  primary: 'primary',
  danger: 'danger',
};

export const buttonVariants = Object.assign({}, baseVariants, {
  secondary: 'secondary',
  text: 'text',
  symbol: 'symbol',
  symbolSecondary: 'symbolSecondary',
  symbolTernary: 'symbolTernary',
  dangerSecondary: 'dangerSecondary',
  accent: 'accent',
  accentSymbol: 'accentSymbol',
  link: 'link',
});
