export enum FieldFilterOperator {
  Is = 'IS',
  IsNot = 'IS_NOT',
  OneOf = 'ONE_OF',
  NotOneOf = 'NOT_ONE_OF',
  Exists = 'EXISTS',
  NotExists = 'NOT_EXISTS',
  IsBetween = 'IS_BETWEEN',
  IsNotBetween = 'IS_NOT_BETWEEN',
}
