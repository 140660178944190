import React from 'react';
import { components } from 'react-select';
import { Icon } from '../../../icons/Icon.component';
import { Group } from '../../../containers/Group/Group.component';
import { Text } from '../../../typography';

const SelectMenuList = () => (
  <Group fullWidth alignItems={'center'} ml={3} my={2}>
    <Icon icon={'arrow-turn-down-left-regular'} />
    <Text lineHeight={'dense'} size={12}>
      Add new option
    </Text>
  </Group>
);

const ReactSelectNewOptionProp = '__isNew__';

export const CreatableMenuList =
  () =>
  ({ options, ...props }: React.ComponentProps<typeof components.MenuList>) => {
    const newOption = options.find(option => option[ReactSelectNewOptionProp]);

    return (
      <components.MenuList options={options} {...props}>
        {newOption && <SelectMenuList />}
        {props.children}
      </components.MenuList>
    );
  };

CreatableMenuList.displayName = 'CreatableSelectMenuList';
