export enum TelemetryAgentWizardSteps {
  Platform,
  Datasources,
  AgentDetails,
}

export enum TelemetryAgentSubjects {
  AddNewCollector = 'add-new-collector',
  FleetManagementPage = 'telemetry-agent-fleet-management-page',
  FleetManagementEmptyState = 'telemetry-agent-fleet-management-empty-state',
  WizardPage = 'telemetry-agent-wizard-page',
  WizardStepHeader = 'telemetry-agent-wizard-step-header',
  WizardStepContainer = 'telemetry-agent-wizard-step-container',
  PlatformStep = 'telemetry-agent-platform-step',
  DatasourceCardTitle = 'telemetry-agent-datasource-card-title',
  DatasourcesStep = 'telemetry-agent-datasources-step',
  AgentDetailsStep = 'telemetry-agent-agent-details-step',
  InstallationPage = 'telemetry-agent-installation-page',
  InstallationPageInstructionsTitle = 'telemetry-agent-installation-page-instructions-title',
  Platform = 'platform',
  IntegrationSelector = 'integration-selector',
  Subtype = 'subtype',
  ConfigCard = 'ConfigCard',
  TelemetryCard = 'telemetry-card',
  TelemetryTitle = 'telemetry-title',
  DatasourceCard = 'datasource-card',
  TelemetryToggle = 'telemetry-toggle',
  DatasourceToggle = 'datasource-toggle',
  AddDatasource = 'add-datasource',
  RemoveDatasource = 'remove-datasource',
  CollectorNameInput = 'collector-name-input',
  CollectorDescriptionInput = 'collector-description-input',
  StepTitle = 'step-title',
  InformationSection = 'notification-section',
  InstallationNextActionCard = 'installation-next-action-card',
  InstallationNextActions = 'installation-next-actions',
  InstallationWaitingForDataCard = 'installation-waiting-for-data-card',
  InstallationKibanaCard = 'installation-kibana-card',
  InstallationKubernetesCard = 'installation-kubernetes-card',
  InstallationTracingCard = 'installation-tracing-card',
  InstallationSpmCard = 'installation-spm-card',
  InstallationCtaCard = 'installation-Cta-card',
  InstallationMetricsCard = 'installation-metrics-card',
  InstallationConnectionStatus = 'installation-connection-status',
  InstallationBundleNotification = 'installation-bundle-notification',
  AccountsTitle = 'accounts-title',
  AccountSelect = 'account-select',
  BackStep = 'agent-wizard-back',
  NextStep = 'agent-wizard-next',
  CancelWizard = 'agent-wizard-cancel',
  InstallDashboardsButton = 'install-dashboards-button',
  InstallCommand = 'install-command',
  LaunchStackLink = 'launch-stack-link',
  AgentParamPathListRemovePath = 'telemetry-agent-param-remove-path',
  AgentParamStringListRemoveString = 'telemetry-agent-string-list-param-remove-path',
  AgentParamKeyValueRemovePair = 'telemetry-agent-param-remove-key-value',
  AgentParamMultilinePatternRemove = 'telemetry-agent-multiline-pattern-remove',
  AgentFleetTable = 'telemetry-agent-fleet-table',
  SummaryAgentName = 'telemetry-agent-summary-agent-name',
  SummaryAgentDescription = 'telemetry-agent-summary-agent-description',
  SummaryPlatform = 'telemetry-agent-summary-platform',
  SummarySubtype = 'telemetry-agent-summary-subtype',
  SummaryDatasourceTelemetries = 'telemetry-agent-summary-datasource-telemetries',
  SummaryDatasources = 'telemetry-agent-summary-datasources',
  SummaryLogsAccount = 'telemetry-agent-logs-account',
  SummaryMetricsAccount = 'telemetry-agent-metrics-account',
  SummaryTracingAccount = 'telemetry-agent-tracing-account',
}
