import React, { FunctionComponent, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Text } from '../typography';
import { Group } from '../containers/Group/Group.component';
import { IIconProps, Icon } from '../icons/Icon.component';

type Variant = 'success' | 'warning' | 'danger' | 'announcement' | 'info';

const variantsMetadata: Record<Variant, { icon: IIconProps['icon'] }> = {
  success: { icon: 'circle-check-solid' },
  warning: { icon: 'circle-info-solid' },
  danger: { icon: 'diamond-exclamation-solid' },
  announcement: { icon: 'circle-info-solid' },
  info: { icon: 'circle-info-solid' },
};

interface IHighlightContainer {
  variant: Variant;
}

export const HighlightContainer = styled(Group)<IHighlightContainer>`
  background-color: ${({ theme, variant }) => theme.highlight.variants[variant].backgroundColor};
  padding: 6px 8px;
  border-radius: 3px;
  align-items: center;
  width: fit-content;
`;

interface IHighlight {
  variant: Variant;
  icon?: IIconProps['icon'];
  noIcon?: boolean;
  children: React.ReactNode;
}

export const Highlight: FunctionComponent<IHighlight> = ({ variant = 'info', icon, noIcon = false, children }) => {
  const {
    highlight: { variants: highlightVariants },
  } = useContext(ThemeContext);

  const currentVariant = highlightVariants[variant];

  return (
    <HighlightContainer variant={variant}>
      {!noIcon && <Icon icon={icon ?? variantsMetadata[variant].icon} color={currentVariant?.iconColor} />}
      <Text weight={700} color={'royalBlue.1000'}>
        {children}
      </Text>
    </HighlightContainer>
  );
};

Highlight.displayName = 'Highlight';
