export enum RouteRateLimitBucket {
  LOWEST = 'LOWEST',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST',
}

export enum RouteRateLimitScope {
  GLOBAL = 'GLOBAL',
  ACCOUNT = 'ACCOUNT',
  PRINCIPAL = 'PRINCIPAL',
}
