function _sizeFromThemeAsInteger(theme: any, size: string | number) {
  let convertedSize = 0;

  if (typeof size === 'string') {
    const [px] = size.split('px');

    convertedSize = +px;
  } else if (typeof size === 'number') {
    convertedSize = theme.space[size] ? theme.space[size] : size;
  }

  return convertedSize;
}

export function sizeFromTheme(theme: any, size: string | number) {
  return `${_sizeFromThemeAsInteger(theme, size)}px`;
}

export function calculateHalfGap({ theme, gap }: { theme: any; gap: string | number }) {
  return `${_sizeFromThemeAsInteger(theme, gap) / 2}px`;
}
