import { FunctionComponent } from 'react';
import { IPreDefinedAction } from '../Crud.types';
import { CustomBulkAction } from './Actions/Custom';
import { DeleteActionModal } from './modals/DeleteActionModal';
import { GenericConfirmationModal } from './modals/GenericConfirmationModal';

export const actionToComponentsMapping: Record<string, FunctionComponent<IPreDefinedAction>> = {
  ['delete']: action => (
    <CustomBulkAction
      Modal={DeleteActionModal}
      icon={'trash-can-regular'}
      label={'Delete'}
      onConfirm={action.onConfirm}
      notificationTitle={action.notificationTitle}
      confirmationText={action.confirmationText}
    />
  ),
  ['activate']: action => (
    <CustomBulkAction
      Modal={GenericConfirmationModal}
      icon={'toggle-large-on-regular'}
      label={'Activate'}
      onConfirm={action.onConfirm}
    />
  ),
  ['deactivate']: action => (
    <CustomBulkAction
      Modal={GenericConfirmationModal}
      icon={'toggle-large-off-regular'}
      label={'Deactivate'}
      onConfirm={action.onConfirm}
    />
  ),
};
