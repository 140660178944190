import React, { useCallback } from 'react';
import { DefaultTheme, ThemeProvider, useTheme } from 'styled-components';
import { merge } from 'lodash';
import { superDatePickerLightTheme } from '../themes/light-v2.theme';
import { superDatePickerDarkTheme } from '../themes/dark-v2.theme';
import { PopupVariant } from '../types';

type ThemeProviderProps = React.PropsWithChildren & {
  variant?: PopupVariant;
};

export const SuperDateRangePickerThemeProvider: React.FC<ThemeProviderProps> = ({ children, variant }) => {
  const { name } = useTheme();

  const handleTheme = useCallback(
    (theme: DefaultTheme): DefaultTheme => {
      const mainTheme =
        name === 'light' ? superDatePickerLightTheme : merge({}, superDatePickerLightTheme, superDatePickerDarkTheme);

      return {
        ...theme,
        datePicker: merge({}, mainTheme, (mainTheme.variants[variant] ?? {}) as Partial<typeof mainTheme>),
      };
    },
    [name, variant],
  );

  return <ThemeProvider theme={handleTheme}>{children}</ThemeProvider>;
};
