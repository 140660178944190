import React, { useState } from 'react';
import styled from 'styled-components';
import { Skeleton } from '../Skeleton/Skeleton.component';
import { Flex } from '../containers/Flex/Flex.component';
import { Group } from '../containers/Group/Group.component';
import { Spinner } from '../progress/Spinner/Spinner.component';
import { WidthObserver } from './components/WidthObserver';

const StyledIconSlot = styled(Skeleton)`
  margin: 0;
  min-width: ${({ width }) => width};
`;

export const StyledLoadingCard: React.FC = () => {
  return (
    <Flex subject={'loading-card'} width={'100%'} height={'100%'} p={4}>
      <Group vertical width={'100%'} gap={4}>
        <Group width={'100%'} gap={4}>
          <StyledIconSlot width={'45px'} height={'45px'} />
          <Skeleton width={'100%'} height={'45px'} />
        </Group>
        <Skeleton width={'100%'} height={'100%'} />
      </Group>
    </Flex>
  );
};

export const StyledLoadingGraph: React.FC = () => {
  const [width, setWidth] = useState(0);
  const numberOfBars = Math.round(width / 25);

  return (
    <WidthObserver onWidthChange={setWidth}>
      <Flex subject={'loading-card'} width={'100%'} height={'100%'} p={4} flexDirection={'column'} alignItems={'center'}>
        <Flex width={'100%'} style={{ gap: '4px' }} height={'100%'} alignItems={'end'}>
          {Array.from({ length: numberOfBars }).map((_, i) => {
            return <Skeleton width={'100%'} height={`${Math.random() * 70 + 30}%`} key={i} />;
          })}
        </Flex>
      </Flex>
    </WidthObserver>
  );
};

export const StyledLoadingSpinner: React.FC = () => {
  return (
    <Flex subject={'loading-card'} width={'100%'} height={'100%'} p={4} flexDirection={'column'} alignItems={'center'}>
      <Spinner fullscreen />
    </Flex>
  );
};
