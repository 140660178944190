export enum TimeUnit {
  Milliseconds = 'MILLISECONDS',
  Seconds = 'SECONDS',
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS',
}

export enum TimeConstants {
  SecondsInHour = 60 * 60,
  SecondsInDay = TimeConstants.SecondsInHour * 60,
  SecondsInWeek = TimeConstants.SecondsInDay * 7,
  SecondsInYear = TimeConstants.SecondsInDay * 365,
  MillisecondsInMinute = 1000 * 60,
  MillisecondsInAnHour = TimeConstants.SecondsInHour * 1000,
}
