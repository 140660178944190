import { FunctionComponent } from 'react';
import { IActionModalProps } from '../interfaces';
import { ConfirmModal } from '../../../../Modal/ConfirmModal';

export const GenericConfirmationModal: FunctionComponent<IActionModalProps> = ({
  open,
  onClose,
  onConfirm,
  modelName,
  count,
  label,
  ...props
}) => (
  <ConfirmModal
    isOpen={open}
    variant={'danger'}
    title={`${label} ${count} ${count > 1 ? `${modelName}s` : modelName}`}
    confirmText={label}
    onConfirm={onConfirm}
    onClose={onClose}
    {...props}
  />
);
