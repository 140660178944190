import { bluePalette, grayPalette, greenPalette, redPalette, yellowPalette } from '../../themes/colors';

export const badgeDarkTheme = {
  success: { color: greenPalette[200] },
  warning: { color: yellowPalette[200] },
  danger: { color: redPalette[200] },
  announcement: { color: bluePalette[200] },
  info: { color: grayPalette[200] },
  fontColor: 'black',
};
