export enum FinanceConsoleSubject {
  FormContainer = 'form-container',
  ActualPlansTable = 'actual-plans-table',
  ProductPurchasesTable = 'product-purchases-table',
  OnDemandPlansTable = 'on-demand-plans-table',
  UpdateSuccess = 'update-success',
  UpdateFailed = 'update-failed',
}

export enum ConsumptionPurchaseStatus {
  PENDING = 'PENDING',
  DONE = 'DONE',
}

export enum BillingType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  CONSUMPTION = 'CONSUMPTION',
}

export enum ProductUnit {
  UTM = 'UTM',
  GB = 'GB',
}
