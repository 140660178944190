export const getAllPaths = (json = {}, path = '') => {
  if (!Object.keys(json)) return [];

  let paths = [];

  for (const key in json) {
    if (!json.hasOwnProperty(key)) continue;

    if (
      ['number', 'string', 'boolean'].includes(typeof json[key]) ||
      Array.isArray(json[key]) ||
      (typeof json[key] === 'object' && !Object.keys(json[key]).length)
    ) {
      paths.push(path + key);
    } else {
      paths = [...paths, ...getAllPaths(json[key], `${path + key}.`)];
    }
  }

  return paths;
};

function isScope(obj) {
  return obj && obj.$evalAsync && obj.$watch;
}

function isWindow(obj) {
  return obj && obj.window === obj;
}

function toJsonReplacer(key, value) {
  let val = value;

  if (typeof key === 'string' && key.charAt(0) === '$' && key.charAt(1) === '$') {
    val = undefined;
  } else if (isWindow(value)) {
    val = '$WINDOW';
  } else if (value && window.document === value) {
    val = '$DOCUMENT';
  } else if (isScope(value)) {
    val = '$SCOPE';
  }

  return val;
}

function toAngularJson(obj, pretty) {
  if (typeof obj === 'undefined') return undefined;

  if (!(typeof pretty === 'number')) {
    pretty = pretty ? 2 : null;
  }

  return JSON.stringify(obj, toJsonReplacer, pretty);
}

function fromAngularJson(json) {
  return typeof json === 'string' ? JSON.parse(json) : json;
}

export const jsonUtils = { getAllPaths, toAngularJson, fromAngularJson };
