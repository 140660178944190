export enum GuidedWalkthroughSubject {
  HeaderBadge = 'guided-walkthrough-header-badge',
  UnseenTasksBadge = 'guided-walkthrough-unseen-task-badge',
  Drawer = 'guided-walkthrough-drawer',
  DrawerContent = 'guided-walkthrough-drawer-content',
  DrawerCloseButton = 'guided-walkthrough-drawer-close-button',
  PhasesList = 'guided-walkthrough-phases-list',
  Task = 'guided-walkthrough-task',
  TaskAction = 'guided-walkthrough-task-action',
  TodoTaskBadge = 'guided-walkthrough-todo-task-badge',
  LockedTaskBadge = 'guided-walkthrough-locked-task-badge',
  DoneTaskBadge = 'guided-walkthrough-done-task-badge',
  ProgressLabel = 'guided-walkthrough-progress-label',
}
