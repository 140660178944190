import { opacity } from '../../utils';
import { colors } from '../colors';
import { marketingBase } from './marketingBase';

export const input = {
  styles: {
    color: colors.yellow[600],
    background: marketingBase.colors.background,
    border: `1px solid ${opacity(colors.yellow[600], 50)}`,
  },
  placeholder: {
    color: opacity(colors.yellow[600], 50),
  },
  active: {
    color: colors.yellow[300],
    borderColor: colors.yellow[300],
    shadowColor: 'rgba(101, 178, 212, 0.34)',
    '&::placeholder': {
      color: colors.yellow[300],
    },
  },
  hover: {
    color: colors.yellow[400],
    borderColor: colors.yellow[400],
  },
  focus: {
    color: colors.gray[0],
    borderColor: colors.gray[0],
    '&::placeholder': {
      color: opacity(colors.gray[0], 50),
    },
  },
  disabled: {
    color: opacity(colors.gray[500], 40),
    background: marketingBase.colors.background,
    borderColor: opacity(colors.gray[500], 40),
    '&::placeholder': {
      color: opacity(colors.gray[500], 40),
    },
  },
  borderless: {
    borderBottom: `1px solid ${opacity(colors.yellow[600], 50)}`,
  },
  error: {
    borderColor: colors.red[500],
  },
};
