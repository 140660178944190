import React from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { ScrollContainer } from '../ScrollContainer/ScrollContainer.component';
import type { CheckboxListSingle } from './types';
import { CheckboxListActions } from './CheckboxListActions.component';
import { OptionsList } from './components/OptionsList';
import { CHECKBOX_HEIGHT, CHECKBOX_PADDING_Y } from './constants';

const SINGLE_CHECKBOX_HEIGHT = CHECKBOX_HEIGHT + CHECKBOX_PADDING_Y * 2;
export interface ICheckboxList {
  options: CheckboxListSingle[];
  value: any[];
  maxVisibleItems?: number;
  subject?: string;
  fullWidth?: boolean;
  numberOfCheckedItems?: number;
  minNumberOfCheckedItems?: number;

  onChange(value: any[]): void;

  getItemKey?: (item: CheckboxListSingle) => string;
  isItemChecked?: (item: CheckboxListSingle) => boolean;
  isItemDisabled?: (item: CheckboxListSingle) => boolean;
  toggleOne?: (item: CheckboxListSingle, checked: boolean) => void;
}

const ListOfCheckboxes: React.FC<ICheckboxList> = ({
  value,
  options,
  getItemKey,
  fullWidth,
  maxVisibleItems = 10,
  numberOfCheckedItems,
  minNumberOfCheckedItems,
  ...props
}) => {
  const isItemChecked = (item: CheckboxListSingle) => {
    return props.isItemChecked ? props.isItemChecked(item) : value.find(id => id === item.value);
  };

  const isItemDisabled = (item: CheckboxListSingle) => props?.isItemDisabled?.(item);

  function toggleOne(item, checked) {
    props.toggleOne
      ? props.toggleOne(item, checked)
      : props.onChange(checked ? value.concat(item.value) : value.filter(id => id !== item.value));
  }

  const itemKey = (item: CheckboxListSingle) => {
    return getItemKey ? getItemKey(item) : item.value;
  };

  return (
    <>
      <ScrollContainer
        {...{
          count: options.length,
          height: maxVisibleItems * SINGLE_CHECKBOX_HEIGHT,
          estimateSize: () => SINGLE_CHECKBOX_HEIGHT,
        }}
        {...generateLogzTestAttributes({ context: 'checkbox-list', subject: props.subject })}
      >
        <OptionsList
          {...{
            fullWidth,
            isItemChecked,
            isItemDisabled,
            itemKey,
            options,
            toggleOne,
            minNumberOfCheckedItems,
            numberOfCheckedItems,
            subject: props.subject,
          }}
        />
      </ScrollContainer>
    </>
  );
};

const CheckboxList: typeof ListOfCheckboxes & {
  Actions?: typeof CheckboxListActions;
} = ListOfCheckboxes;

CheckboxList.Actions = CheckboxListActions;

export { CheckboxList };
