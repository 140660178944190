import { throttle } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { ResizeCallbackWrapper } from '../ResizeCallbackWrapper/ResizeCallbackWrapper.component';
import { ShadowScrollContainer } from './ShadowScrollContainer';

interface IShadowScrollProps extends React.PropsWithChildren {
  parentRef: React.RefObject<HTMLDivElement>;
  subject?: string;
}

export const ShadowScroll: React.FC<IShadowScrollProps> = ({ children, parentRef, subject }) => {
  const [hideShadow, setHideShadow] = React.useState(true);

  const handleResize = useCallback(
    throttle(() => {
      const node = parentRef?.current;

      if (node) {
        const { scrollTop, scrollHeight, clientHeight } = node;

        setHideShadow(scrollTop >= scrollHeight - clientHeight - 1);
      }
    }, 100),
    [parentRef],
  );

  useEffect(() => {
    handleResize();

    parentRef.current?.addEventListener('scroll', handleResize);

    return () => {
      parentRef.current?.removeEventListener('scroll', handleResize);
    };
  }, [parentRef]);

  return (
    <ResizeCallbackWrapper callback={handleResize}>
      <ShadowScrollContainer hideShadow={hideShadow} context="shadow-scroll" subject={subject}>
        {children}
      </ShadowScrollContainer>
    </ResizeCallbackWrapper>
  );
};
