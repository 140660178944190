import { FilterComponentTestSubjects } from '@logz-pkg/enums';
import { isNil } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { Badge } from '../../Badge/Badge.component';
import { Button } from '../../Button/Button.component';
import { HighlightedText } from '../../HighlightedText/HighlightedText.component';
import { Container } from '../../containers/Container/Container.component';
import { Flex } from '../../containers/Flex/Flex.component';
import { Group } from '../../containers/Group/Group.component';
import { Icon } from '../../icons/Icon.component';
import { Spinner } from '../../progress/Spinner/Spinner.component';
import { lightV2Theme } from '../../themes';
import { Tooltip } from '../../tooltip/Tooltip.component';
import { Text } from '../../typography/Text/Text.component';
import type { ITitleComponentProps, SettingOption } from '../types';

const SettingsContainer = styled(Group)`
  min-height: 20px;
`;

const StyledFlex = styled(Flex)<{ settings: SettingOption[] }>`
  .filter-badge {
    ${({ settings }) => (settings?.some(setting => !isNil(setting.activeIcon)) ? `display: none` : 'display:block')};
  }

  .active-filter-setting {
    display: flex;
  }

  .filter-setting {
    display: none;
  }

  &:hover {
    .filter-setting {
      display: flex;
    }
    .active-filter-setting {
      display: none;
    }

    .filter-badge {
      ${({ settings }) => (settings?.length > 0 ? ` display: none` : 'display:block')};
    }
  }
`;

export const TitleComponent: React.FC<ITitleComponentProps> = ({
  title,
  value,
  options,
  loading,
  titleIcon,
  settings,
  highlight,
  hideBadge,
  disableExpandTooltip,
}) => {
  const appTheme = useTheme() as typeof lightV2Theme;
  const badgeContent =
    value.length > 0 ? `${value.length}/${options.total}` : `${options.total > 999 ? '999+' : options.total}`;
  const isActive = value.length > 0 && !loading;
  const badgeColor = isActive ? appTheme.multiFilter.badgeColorActive : appTheme.multiFilter.badgeColorInactive;
  const badgeOutline = !isActive;

  const disabledTooltip = useMemo(
    () =>
      disableExpandTooltip ? (
        <Container>
          <Text inheritColor>{disableExpandTooltip}</Text>
        </Container>
      ) : null,
    [disableExpandTooltip],
  );

  return (
    <StyledFlex justifyContent={'space-between'} py={1} alignItems={'center'} settings={settings}>
      <Group alignItems="center" gap={1}>
        {titleIcon && (
          <Icon
            icon={titleIcon}
            size={10}
            color={
              Boolean(disableExpandTooltip)
                ? appTheme.multiFilter.disabledTitleIconColor
                : appTheme.multiFilter.titleIconColor
            }
          />
        )}
        <HighlightedText
          ellipsis={{
            openDelayInMs: 300,
            overrideTooltip: disableExpandTooltip ? disabledTooltip : title,
            placement: 'bottom-start',
          }}
          size={12}
          textToHighlight={highlight}
          color={
            Boolean(disableExpandTooltip)
              ? appTheme.multiFilter.disabledTitleIconColor
              : appTheme.multiFilter.titleIconColor
          }
        >
          {title}
        </HighlightedText>
      </Group>
      <SettingsContainer gap={0} alignItems="center" fullWidth>
        <Group className="filter-badge" justifyContent={'center'} alignItems={'center'}>
          {loading ? (
            <Spinner size="s" />
          ) : (
            !isNil(badgeContent) &&
            !hideBadge && <Badge square outline={badgeOutline} size={'xs'} color={badgeColor} value={badgeContent} />
          )}
        </Group>
        {settings?.length > 0 && (
          <Group alignItems="center" subject="filter-context-menu">
            {settings.map(setting => {
              return (
                <Fragment key={setting.id}>
                  <div className="filter-setting">
                    <Tooltip title={setting.tooltipLabel} placement="top" openDelayInMs={500}>
                      <Button
                        subject={`${FilterComponentTestSubjects.ContextMenuOption}-${setting.id}`}
                        variant="tertiary"
                        round
                        size="s"
                        icon={setting.icon}
                        onClick={e => {
                          e.stopPropagation();
                          setting.onClick();
                        }}
                      />
                    </Tooltip>
                  </div>
                  {setting.activeIcon && (
                    <div className="active-filter-setting">
                      <Button
                        subject={`${FilterComponentTestSubjects.ContextMenuOption}-${setting.id}`}
                        variant="tertiary"
                        round
                        size="s"
                        icon={setting.activeIcon}
                        onClick={e => {
                          e.stopPropagation();
                          setting.onClick();
                        }}
                      />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </Group>
        )}
      </SettingsContainer>
    </StyledFlex>
  );
};
