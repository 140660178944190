import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

interface WidthObserverProps {
  children: React.ReactNode;
  onWidthChange: (width: number) => void;
}

export const WidthObserver: React.FC<WidthObserverProps> = ({ children, onWidthChange }) => {
  const [parentWidth, setParentWidth] = useState<number>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const resizeDebounce = useCallback(
    debounce(() => {
      if (!parentWidth) return;

      onWidthChange(parentWidth);
    }, 50),
    [parentWidth, onWidthChange],
  );

  useEffect(() => {
    resizeDebounce();

    return () => resizeDebounce.cancel();
  }, [parentWidth, resizeDebounce]);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      requestAnimationFrame(() => {
        for (const entry of entries) {
          if (entry.target === parentRef.current) {
            setParentWidth(entry.contentRect.width);
          }
        }
      });
    });

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        observer.unobserve(parentRef.current);
      }
    };
  }, []);

  return (
    <div ref={parentRef} style={{ height: '100%' }}>
      {children}
    </div>
  );
};
