import styled from 'styled-components';
import { Flex } from '../containers/Flex/Flex.component';

const propTypes = {
  ...Flex.propTypes,
};

const defaultProps = {
  ...Flex.defaultProps,
  ...{ m: 0, p: 0 },
};

/**
 * @deprecated
 */
export const HeaderSection = styled(Flex)`
  background: transparent;
  align-items: stretch;

  font-family: ${({ theme }) => theme.fonts.base};
  font-size: 15px;
  line-height: normal;
`;

HeaderSection.displayName = 'HeaderSection';
HeaderSection.propTypes = propTypes;
HeaderSection.defaultProps = defaultProps;
