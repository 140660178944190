import styled, { css, keyframes } from 'styled-components';
import { space, width, minWidth as minWidthProps, WidthProps, type MinWidthProps } from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isNil } from 'lodash';
import { IButtonProps } from './Button.types';

const pulseKeyframes = keyframes`
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 6px rgba(0, 0, 0, 0);
    }   
`;

export const fontSize: Record<IButtonProps['size'], 10 | 12 | 14> = {
  l: 14,
  m: 14,
  s: 12,
  xs: 10,
};

const padding: Record<IButtonProps['size'], string> = {
  l: '10px 14px',
  m: '6px 10px',
  s: '4px 8px',
  xs: '4px 8px',
};

const minWidthVariants: Record<IButtonProps['size'], string> = {
  l: '90px',
  m: '70px',
  s: '50px',
  xs: '50px',
};

const buttonHeights: Record<IButtonProps['size'], string> = {
  l: '40px',
  m: '32px',
  s: '28px',
  xs: '20px',
};

const roundSize: Record<IButtonProps['size'], string> = {
  l: '40px',
  m: '32px',
  s: '16px',
  xs: '16px',
};

interface IButtonDimensions {
  width: string;
  height: string;
  minWidth: string;
  padding: string;
}

const getButtonDimensions = (props: Partial<IButtonProps & { iconOnly: boolean }>): IButtonDimensions => {
  const { round, iconOnly, size = 'm' } = props;

  if (iconOnly && isNil(round)) {
    return {
      width: buttonHeights[size],
      height: buttonHeights[size],
      minWidth: buttonHeights[size],
      padding: '0',
    };
  }

  if (round) {
    return {
      width: roundSize[size],
      height: roundSize[size],
      minWidth: roundSize[size],
      padding: '0',
    };
  }

  return {
    width: 'auto',
    height: buttonHeights[size],
    minWidth: minWidthVariants[size],
    padding: padding[size],
  };
};

const baseButtonLayout = css<IButtonProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  isolation: ${({ variant }) => (variant === 'radiant' ? 'isolate' : 'auto')};
  ${props => {
    const dimensions = getButtonDimensions(props);

    return css`
      width: ${dimensions.width};
      height: ${dimensions.height};
      min-width: ${dimensions.minWidth};
      padding: ${dimensions.padding};
    `;
  }}
  font-size: ${({ size }) => fontSize[size]};
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.text};
  -webkit-font-smoothing: antialiased;
  border: none;
  border-radius: ${({ round }) => (round ? '50%' : '4px')};
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-decoration: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

const getVariantStyles = ({ theme, variant, disabled, round }) => {
  const variantTheme = theme.button.variants[variant];
  const defaultBorderColor = variantTheme.borderColor ?? 'transparent';
  const disabledBorderColor = variantTheme.disabled?.borderColor ?? 'transparent';
  const disabledBackground = variantTheme.disabled?.background ?? variantTheme.background;

  if (variant === 'radiant') {
    const radiantBackground = disabled ? variantTheme.disabled?.before?.background : variantTheme.before?.background;

    return css`
      position: relative;
      background: ${variantTheme.before.background};
      box-shadow: ${variantTheme.boxShadow};
      color: ${variantTheme.textColor};
      opacity: ${disabled ? variantTheme.disabled.opacity : 1};

      &::before {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: ${variantTheme.background};
        border-radius: inherit;
        z-index: -2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        background: ${radiantBackground};
        border-radius: ${round ? '50%' : '3px'};
        z-index: -1;
      }

      &:hover:not(:disabled) {
        &::after {
          background: ${variantTheme.hover?.background};
        }
      }
      &:active:not(:disabled) {
        &::after {
          background: ${variantTheme.active?.background};
          color: ${variantTheme.active?.textColor};
        }
      }
    `;
  }

  return css`
    color: ${disabled ? variantTheme.disabled?.textColor : variantTheme.textColor};
    background: ${variantTheme.background};
    box-shadow: 0 0 0 1px ${defaultBorderColor} inset;

    &:hover:not(:disabled) {
      background: ${variantTheme.hover?.background};
      color: ${variantTheme.hover?.textColor ?? variantTheme.textColor};
    }

    &:active:not(:disabled) {
      background: ${variantTheme.active?.background ?? variantTheme.background};
      color: ${variantTheme.active?.textColor ?? variantTheme.textColor};
    }

    &:disabled {
      background: ${disabledBackground};
      box-shadow: 0 0 0 1px ${disabledBorderColor} inset;
    }
  `;
};

const roundStyle = ({ round }) =>
  round &&
  css`
    border-radius: 50%;
    overflow: hidden;
  `;

const pulseAnimationStyle = ({ pulsate }) =>
  pulsate &&
  css`
    animation: ${pulseKeyframes} 2s infinite;
  `;

export const StyledButton = styled.button.attrs(({ subject }: { subject: string; iconOnly: boolean }) =>
  generateLogzTestAttributes({ context: 'button', subject }),
)<IButtonProps & WidthProps & MinWidthProps>`
  ${baseButtonLayout}
  ${props => getVariantStyles(props)}
  ${roundStyle}
  ${pulseAnimationStyle}
  ${space}
  ${width}
  ${minWidthProps}

  &:focus-visible {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.royalBlue[1000]};
    animation: focusAnimation 0.3s forwards;
  }

  &:focus {
    outline: none;
  }

  @keyframes focusAnimation {
    0% {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.royalBlue[1000]};
    }
    100% {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.royalBlue[1000]};
    }
  }
`;

export const TooltipContainer = styled.span`
  display: inline-block;

  :hover {
    cursor: not-allowed;
  }
`;
