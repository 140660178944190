import { get } from 'lodash';
import { ColorV2, colorsV2 } from '../themes';
import { multiTagLightV2Theme } from './themes/light.theme';
import { SingleTag, MultiTagStep, TagState } from './types';

const createNewSteps = (tag: SingleTag, index: number, newStep: MultiTagStep) => {
  const newSteps = [...tag.steps];

  newSteps[index] = newStep;

  return newSteps;
};

type GetBackgroundColorProps = {
  container: typeof multiTagLightV2Theme.container;
  editMode: boolean;
  state: TagState;
  defaultColor?: ColorV2;
};

const getBackgroundColor = ({ container, editMode, state, defaultColor }: GetBackgroundColorProps) => {
  const { backgroundColor } = container;

  if (state === 'disabled' || editMode) {
    return backgroundColor.disabled;
  }

  if (state === 'selected') {
    return backgroundColor.selected;
  }

  if (state === 'hovered') {
    return backgroundColor.hover;
  }

  if (defaultColor) {
    return get(colorsV2, defaultColor);
  }

  return backgroundColor.default;
};

const getBorderColor = (container: typeof multiTagLightV2Theme.container, state: TagState) => {
  const { borderColor } = container;

  if (state === 'selected') {
    return borderColor.selected;
  }

  if (state === 'hovered') {
    return borderColor.hover;
  }

  return borderColor.default;
};

export const multiTagUtils = {
  createNewSteps,
  getBackgroundColor,
  getBorderColor,
};
