import { FunctionComponent } from 'react';
import { Button } from '../../../../Button/Button.component';
import { Tooltip } from '../../../../tooltip/Tooltip.component';

interface ICrudTableRecordEditActionProps {
  onEdit(): void;
  isDense: boolean;
}

/**
 * According to the specs -
 * If there is only 1 action, we need to display an button with both icon and text
 * If there are 2 or more actions, we show only
 */
export const CrudTableRecordEditAction: FunctionComponent<ICrudTableRecordEditActionProps> = ({ onEdit, isDense }) => {
  const buttonSubject = 'edit';
  const buttonText = 'Edit';

  return (
    <Tooltip title={isDense ? buttonText : ''}>
      <Button round={isDense} variant={'secondary'} icon={'pen-regular'} onClick={onEdit} subject={buttonSubject}>
        {!isDense && buttonText}
      </Button>
    </Tooltip>
  );
};
