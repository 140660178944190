export enum ServiceListSubjects {
  ServiceListPage = 'service-list-page',
  ServiceListTable = 'service-list-table',
  ServiceOverviewPage = 'service-overview-page',
  OperationOverviewPage = 'operation-overview-page',
  FeatureEmptyState = 'feature-empty-state',
  CheckMapView = 'check-map-view',
  CheckTableView = 'check-table-view',
  SwitchViewMode = 'switch-view-mode',
  TechnologyIndicator = 'technology-indicator',
}
