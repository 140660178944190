import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '../../typography';
import { colors } from '../../themes';
import { IJsonFormatterProps } from '../JsonFormatter.component';

const activeStyle = css`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue[800]};
  text-decoration: underline;
`;

const hoverStyle = css`
  font-weight: 500;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue[600]};
`;

const StyledText = styled(Text)<{ active?: boolean }>`
  ${({ onClick, active }) =>
    onClick &&
    css`
      cursor: pointer;
      ${!active &&
      css`
        &:hover {
          ${hoverStyle}
        }
      `}
      &:active {
        ${activeStyle}
      }
    `}

  ${({ active }) => active && activeStyle}
  word-break:break-all;
`;

interface IValueProps {
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  value: IJsonFormatterProps['highlight'][string];
  active?: boolean;
}

export const Value: React.FC<IValueProps> = ({ onClick, value, active }) => {
  return (
    <StyledText size={12} variant="code" weight={400} color={colors.royalBlue[1000]} onClick={onClick} active={active}>
      {value}
    </StyledText>
  );
};
