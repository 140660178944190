interface RequestError extends Error {
  statusCode?: number;
  data?: any;
}

export const asyncFilter = (array, filter) =>
  Promise.all(array.map((entry, index) => filter(entry, index).catch(() => false))).then(bits =>
    array.filter(() => bits.shift()),
  );

export const awaitToRequest = (request): Promise<[RequestError | null, any]> =>
  request.then(data => [null, data]).catch(err => [err, undefined]);

export const asyncForEach = async <T>(array: T[], callback: (item: T, index: number, array: T[]) => Promise<void>) => {
  for (let i = 0; i < array.length; i += 1) {
    await callback(array[i], i, array);
  }
};

export const asyncMap = async (array, callback): Promise<any[]> => Promise.all(array.map(callback));

export const findAsyncSequential = async <T>(
  array: T[],
  predicate: (item: T) => Promise<boolean>,
): Promise<T | undefined> => {
  for (const item of array) {
    if (await predicate(item)) {
      return item;
    }
  }

  return undefined;
};

export const findAsyncParallel = async <T>(
  array: T[],
  predicate: (item: T) => Promise<boolean>,
): Promise<T | undefined> => {
  const promises = array.map(predicate);
  const results = await Promise.all(promises);
  const index = results.findIndex(result => result);

  return array[index];
};
