/* eslint-disable max-lines */
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { isNil } from 'lodash';
import { IPagination, ISearchRequestObject } from '@logz-build/typescript';
import { isPromise } from '../../utils';
import { TablePagination } from '../TablePagination';
import { Table } from '../Table';
import { CrudTableRecordDeleteModal } from './CrudTableRecordDeleteModal';
import { CrudTableRecord } from './Record/Record';
import { CrudTableHeader } from './Header';
import { CrudTableEditRecord } from './Record/Edit/Edit';
import { ActionBar } from './ActionBar';
import { paginationSize, ICrudTableProps, CrudTableEvents, CrudTableRef } from './Crud.types';
import { useBulkActions } from './bulkActions.hook';
import { BulkActionsBar, IBulkActionsProps } from './Bulk/BulkActionsBar';

export const BulkActionsStateContext = React.createContext<IBulkActionsProps>({
  totalDataLength: null,
  dataSourceLength: null,
  selectedLength: null,
  selected: { ids: [], isAllPagesSelected: false, isPageSelected: false },
  modelName: null,
  actions: null,
  filter: null,
  onSearch: null,
});

const buildPaginationSearchObject = (pagination: IPagination) => ({
  pageNumber: pagination.pageNumber || 1,
  pageSize: pagination.pageSize || paginationSize.default,
});

function usePrevious<Type>(value): Type {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const defaultDataSource = [];
const defaultPagination = {
  pageNumber: 1,
  pageSize: paginationSize.default,
};

const defaultTableOptions: ICrudTableProps['options'] = {
  actions: { hide: false },
  header: { sticky: false },
  tableStyles: { cellPadding: 15 },
};

export const CrudTable = forwardRef<CrudTableRef, ICrudTableProps>(
  (
    {
      options = defaultTableOptions,
      dataSource = defaultDataSource,
      addButtonText = 'Add record',
      recordIdField = 'id',
      total = 0,
      pagination = defaultPagination,
      hidePagination = false,
      filterObjectBuilder = (filters: ISearchRequestObject['filter']) => filters,
      showSearchInput = false,
      canEdit = () => true,
      canDelete = () => true,
      canDuplicate = () => false,
      createInitialValues = {},
      initialRequestObject = {},
      emptyState = null,
      customActions = [],
      indicatorComponent = () => null,
      fullWidth = true,
      tooltipPlacement = 'bottom',
      forceLoading = false,
      onCancel,
      columns,
      recordContext,
      DeleteModalComponent,
      validationSchema,
      validate,
      onSearch,
      onCreate,
      onUpdate,
      onDelete,
      onDuplicate,
      customAddAction,
      customEditAction,
      customDuplicateAction,
      searchTooltip,
      searchPlaceholder,
      disableAddButton,
      disabledAddButtonTooltip,
      error,
      subject,
      recordSubject,
      DeleteButton,
      EditButton,
      DuplicateButton,
      preventActionCondense,
      renderAddButton,
      attachedAddButton = true,
      searchFilters,
      shouldDisableRow,
      onSearchClick,
    },
    ref,
  ) => {
    const mountedRef = useRef<boolean>(true);
    const [recordToDelete, setRecordToDelete] = useState<object>(null);
    const { selected, toggleSelectAllData, handleRecordSelected, handleHeaderSelected } = useBulkActions({
      dataSource,
      isBulkFeatureEnabled: Boolean(options.bulk),
    });
    const [inCreateMode, setInCreateMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [recordsInEdit, setRecordsInEdit] = useState<(number | string)[]>([]);
    const [searchRequestObject, setSearchRequestObject] = useState<ISearchRequestObject>({
      filter: {},
      pagination: buildPaginationSearchObject(pagination),
      sort: [],
    });
    const prevPagination = usePrevious<ISearchRequestObject['pagination']>(pagination);
    const isTableLoading = isLoading || forceLoading;

    useEffect(() => {
      if (recordsInEdit?.length === 0 && !inCreateMode) {
        document.dispatchEvent(new Event(CrudTableEvents.TableNotInEdit));
      }
    }, [recordsInEdit?.length, inCreateMode]);

    const shouldDisableAddButton = useMemo(() => {
      const shouldDisable = disableAddButton || isTableLoading || inCreateMode;

      return shouldDisable;
    }, [disableAddButton, isTableLoading, inCreateMode]);

    const previousDisableState = usePrevious<boolean>(shouldDisableAddButton);

    const handleSearch = async (searchObject: ISearchRequestObject, isInitial = false) => {
      setSearchRequestObject(searchObject);

      try {
        const searchReturn = onSearch(searchObject, isInitial);

        if (isPromise(searchReturn)) {
          setIsLoading(true);
          await searchReturn;
        }

        if (!mountedRef.current) return;
      } finally {
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      search: handleSearch,
      refresh: () => handleSearch(searchRequestObject),
    }));

    const handleAddClick = () => {
      setInCreateMode(true);
    };
    const handleAddCancel = () => {
      onCancel?.();
      setInCreateMode(false);
    };

    const handleCreate = async values => {
      await onCreate(values);

      if (!mountedRef.current) return;

      setInCreateMode(false);
      // We now fire a search request so we would receive the latest records. We don't care when it finishes
      // On the contrary, we don't want to link the create and the search together here, since it will make the creation
      // record be displayed while the table is searching.
      handleSearch(searchRequestObject);
    };

    const openDeleteModal = (record: object) => setRecordToDelete(record);
    const closeDeleteModal = () => setRecordToDelete(null);

    const handleDelete = async record => {
      await onDelete(record);

      if (!mountedRef.current) return;

      // We now fire a search request so we would receive the latest records. We don't care when it finishes
      handleSearch(searchRequestObject);
    };

    const handleChangePage = (newPage: number) =>
      handleSearch({
        ...searchRequestObject,
        pagination: {
          pageNumber: newPage,
          pageSize: searchRequestObject.pagination.pageSize || pagination.pageSize,
        },
      });

    const handleChangeRowsPerPage = (newPageSize: number) =>
      handleSearch({
        ...searchRequestObject,
        pagination: {
          pageNumber: 1, // NOTE: reset 'page' on pageSize change
          pageSize: newPageSize,
        },
      });

    const handleSort = sort => handleSearch({ ...searchRequestObject, sort });

    const getRecordKey = record => {
      if (typeof recordIdField === 'string') {
        return record[recordIdField];
      }

      return recordIdField(record);
    };

    const handleEdit = record => setRecordsInEdit(prevState => [...prevState, getRecordKey(record)]);

    const handleEditCancel = record => {
      onCancel?.();
      setRecordsInEdit(prevState => {
        const newState = [...prevState];

        newState.splice(prevState.indexOf(getRecordKey(record)), 1);

        return newState;
      });
    };

    const handleUpdate = async (values: object) => {
      try {
        await onUpdate(values);
      } catch {
        return;
      }

      if (!mountedRef.current) return;

      handleEditCancel(values);
      // We now fire a search request so we would receive the latest records. We don't care when it finishes
      handleSearch(searchRequestObject);
    };

    const handleDuplicate = async record => {
      await onDuplicate(record);
      setRecordsInEdit([getRecordKey(record)]);
    };

    const onFilterChange = filters => {
      handleSearch({
        ...searchRequestObject,
        filter: filterObjectBuilder(filters),
        pagination: {
          pageNumber: 1,
          pageSize: searchRequestObject?.pagination.pageSize || pagination.pageSize,
        },
      });
    };

    // Checks whether the table should be dense: whether the buttons should be only icon, or icon + text.
    const isDense = (): boolean => (!!onUpdate || !!customEditAction) && !!onDelete;

    const handleStartLoading = (): void => setIsLoading(true);

    const handleFinishLoading = (): void => isLoading && setIsLoading(false);

    useEffect(() => {
      const onAddClicked = async () => {
        if (customAddAction) {
          await customAddAction(createInitialValues, handleAddClick);
        } else {
          handleAddClick();
        }
      };

      document.addEventListener(CrudTableEvents.AddNewClicked, onAddClicked);

      return () => {
        document.removeEventListener(CrudTableEvents.AddNewClicked, onAddClicked);
      };
    }, [customAddAction]);

    useEffect(() => {
      if (inCreateMode && disableAddButton) {
        setInCreateMode(false);
      }
    }, [inCreateMode, disableAddButton]);

    useEffect(() => {
      if (shouldDisableAddButton) {
        if (previousDisableState) return;

        document.dispatchEvent(new Event(CrudTableEvents.TableIsLoading));
      } else if (previousDisableState) {
        document.dispatchEvent(new Event(CrudTableEvents.TableIsReady));
      }
    }, [shouldDisableAddButton, previousDisableState]);

    useEffect(() => {
      if (pagination.pageSize !== prevPagination?.pageSize || pagination.pageNumber !== prevPagination?.pageNumber) {
        setSearchRequestObject(prevState => {
          return {
            ...prevState,
            pagination: buildPaginationSearchObject(pagination),
          };
        });
      }
    }, [pagination]);

    useEffect(() => {
      handleSearch({ ...searchRequestObject, ...initialRequestObject }, true);
    }, []);

    // Cleanup
    useEffect(
      () => () => {
        mountedRef.current = false;
      },
      [],
    );

    const bodyRecords = dataSource.map(record =>
      recordsInEdit.includes(getRecordKey(record)) ? (
        <CrudTableEditRecord
          columns={columns}
          record={record}
          key={getRecordKey(record)}
          onCancel={() => handleEditCancel(record)}
          validationSchema={validationSchema}
          validate={validate}
          onUpdate={values => handleUpdate(values)}
          context={`table-edit-record-id-${recordContext ? record[recordContext] : getRecordKey(record)}`}
          subject={recordSubject ? recordSubject(record) : null}
          options={options}
        />
      ) : (
        <CrudTableRecord
          {...(options.bulk
            ? {
                bulk: {
                  checked: selected.ids.includes(record.id) || selected.isAllPagesSelected,
                  onChange: handleRecordSelected,
                },
              }
            : {})}
          disable={shouldDisableRow && shouldDisableRow(record)}
          key={getRecordKey(record)}
          record={record}
          columns={columns}
          onDelete={record => (isNil(DeleteModalComponent) ? handleDelete(record) : openDeleteModal(record))}
          canEdit={(!!onUpdate || !!customEditAction) && canEdit(record)}
          canDelete={!!onDelete && canDelete(record)}
          canDuplicate={!!onDuplicate && canDuplicate(record)}
          onEdit={record => (customEditAction ? customEditAction(record) : handleEdit(record))}
          onDuplicate={record => (customDuplicateAction ? customDuplicateAction(record) : handleDuplicate(record))}
          onSearch={() => handleSearch(searchRequestObject)}
          onStartLoading={handleStartLoading}
          onFinishLoading={handleFinishLoading}
          customActions={customActions}
          indicatorComponent={indicatorComponent}
          DeleteButton={DeleteButton}
          EditButton={EditButton}
          DuplicateButton={DuplicateButton}
          isDense={!preventActionCondense && isDense()}
          context={`table-record-id-${recordContext ? record[recordContext] : getRecordKey(record)}`}
          subject={recordSubject ? recordSubject(record) : null}
          tableOptions={options}
          {...(options?.row?.onClick ? { onClick: () => options?.row?.onClick(record) } : {})}
        />
      ),
    );

    return (
      <>
        <ActionBar
          initialRequestObject={initialRequestObject}
          searchFilters={searchFilters}
          subject={subject}
          error={error}
          fullWidth={fullWidth}
          searchTooltip={searchTooltip}
          searchPlaceholder={searchPlaceholder}
          showSearchInput={showSearchInput}
          handleFiltersChange={onFilterChange}
          addButtonText={addButtonText}
          showAddButton={(Boolean(onCreate) || Boolean(customAddAction)) && attachedAddButton}
          disableAddButton={disableAddButton}
          onAddClick={() => (customAddAction ? customAddAction(createInitialValues, handleAddClick) : handleAddClick())}
          onSearchClick={onSearchClick}
          disabledAddButtonTooltip={disabledAddButtonTooltip}
          tooltipPlacement={tooltipPlacement}
          renderAddButton={renderAddButton}
          inCreateMode={inCreateMode}
          isTableLoading={isTableLoading}
          actionBarOptions={options.actionBar}
        />
        {options.bulk && (
          <BulkActionsStateContext.Provider
            value={{
              totalDataLength: total,
              dataSourceLength: dataSource.length,
              selectedLength: selected.isAllPagesSelected ? total : selected.ids.length,
              selected,
              modelName: options.bulk.modelName,
              actions: options.bulk.actions,
              filter: searchRequestObject.filter,
              onSearch: () => handleSearch(searchRequestObject),
            }}
          >
            <BulkActionsBar onSelectAllData={toggleSelectAllData} />
          </BulkActionsStateContext.Provider>
        )}
        <Table
          inheritSize={options?.header?.sticky}
          columnWidths={columns.map(({ width }) => width)}
          columnIsFixedWidths={columns.map(({ isFixedWidth }) => isFixedWidth)}
          loading={isTableLoading}
          subject={subject}
        >
          <CrudTableHeader
            {...(options.bulk
              ? {
                  bulk: {
                    selectedLength: selected.ids.length,
                    dataSourceLength: dataSource.length,
                    isAllPagesSelected: selected.isAllPagesSelected,
                    onChange: (isChecked: boolean) =>
                      handleHeaderSelected(
                        isChecked,
                        dataSource.map(({ id }) => id),
                      ),
                  },
                }
              : {})}
            columns={columns}
            sort={searchRequestObject.sort}
            onSort={sort => handleSort(sort)}
            tableOptions={options}
          />
          <Table.Body stickyHeader={options?.header?.sticky}>
            {inCreateMode && (
              <CrudTableEditRecord
                columns={columns}
                onCancel={() => handleAddCancel()}
                isNew={true}
                record={createInitialValues}
                onCreate={values => handleCreate(values)}
                validationSchema={validationSchema}
                validate={validate}
                context={'table-new-record'}
                options={options}
              />
            )}
            {/* Display empty state if we're done loading and found out we have no results */}
            {(total === 0 && !isTableLoading) || dataSource?.length === 0 ? emptyState : bodyRecords}
          </Table.Body>
        </Table>
        {/* Don't display pagination if there are no results */}
        {total !== 0 && !hidePagination && (
          <TablePagination
            rowsPerPageOptions={paginationSize.options}
            count={total}
            rowsPerPage={searchRequestObject.pagination.pageSize}
            pageNumber={searchRequestObject.pagination.pageNumber}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
        {DeleteModalComponent && (
          <CrudTableRecordDeleteModal
            isOpen={!isNil(recordToDelete)}
            onDelete={record => handleDelete(record)}
            onClose={() => closeDeleteModal()}
            recordToDelete={recordToDelete}
            ModalComponent={DeleteModalComponent}
          />
        )}
      </>
    );
  },
);

CrudTable.displayName = 'CrudTable';
