import React, { ReactNode, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';

const Container = styled.div<IPageProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: ${({ disableOverflow }) => (disableOverflow ? 'hidden' : 'auto')};
  background-color: ${({ theme }) => theme.page.backgroundColor};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  anchor-name: --drawer-wrapper;
`;

interface IPageProps {
  children: ReactNode;
  fullWidth?: boolean;
  subject?: string;
  disableOverflow?: boolean;
  innerRef?: React.Ref<HTMLDivElement>;
}

export const Page: FunctionComponent<IPageProps> = ({ innerRef, children, subject, ...props }) => (
  <Container ref={innerRef} {...props} {...generateLogzTestAttributes({ subject })}>
    {children}
  </Container>
);

Page.displayName = 'Page';
