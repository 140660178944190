export enum FieldMappingsTabs {
  Base = 'dashboard.tools.field-mapping',
  Mappings = 'dashboard.tools.field-mapping.mapping-configuration',
  Errors = 'dashboard.tools.field-mapping.mapping-errors',
}

export enum FieldMappingSubjects {
  Page = 'field-mappings',
  EmptyState = 'field-mapping-empty-state',
  ApplyNotification = 'apply-mapping',
  ConfirmModal = 'confirm-modal',
  ResolveError = 'resolve-error',
}
