/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Table as PrimaryTable } from './Table.component';
import { TableHeader } from './Header.component';
import { TableHeaderCell } from './HeaderCell.component';
import { TableBody } from './Body.component';
import { TableRow } from './Row.component';
import { TableCell } from './Cell.component';
import { TableActionCell } from './ActionCell.component';
import { TableSection } from './Section.component';

export type TableComponents = 'Header' | 'HeaderCell' | 'Body' | 'Row' | 'Cell' | 'ActionCell' | 'Section';

type TableProps = typeof PrimaryTable & {
  Header: React.ElementType;
  HeaderCell: React.ElementType;
  Body: React.ElementType;
  Row: React.ElementType;
  Cell: React.ElementType;
  ActionCell: React.ElementType;
  Section: React.ElementType;
} & Partial<Record<TableComponents, React.ElementType>>;

Object.defineProperty(PrimaryTable, 'Header', { value: TableHeader });
Object.defineProperty(PrimaryTable, 'HeaderCell', { value: TableHeaderCell });
Object.defineProperty(PrimaryTable, 'Body', { value: TableBody });
Object.defineProperty(PrimaryTable, 'Row', { value: TableRow });
Object.defineProperty(PrimaryTable, 'Cell', { value: TableCell });
Object.defineProperty(PrimaryTable, 'ActionCell', { value: TableActionCell });
Object.defineProperty(PrimaryTable, 'Section', { value: TableSection });

export const Table = PrimaryTable as TableProps;
