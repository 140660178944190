export enum MitreSelectTestSubjects {
  ClearAllTagsButton = 'clear-all-tags-button',
  MitreSelectContainer = 'mitre-select-container',
  AddTagButton = 'add-tag-button',
  TacticSelect = 'tactic-select',
  TechniqueSelect = 'technique-select',
  SubTechniqueSelect = 'sub-technique-select',
  SaveTagButton = 'save-tag-button',
  RemoveTagButton = 'remove-tag-button',
  ClearAllButton = 'clear-all-button',
  TagsContainer = 'tags-container',
  InvalidMitreTagAlert = 'invalid-mitre-tag',
}
