import { SeverityLevel } from '@logz-pkg/enums';
import { buttonGroupTheme } from '../../ButtonGroup/themes/light-v1.theme';
import { checkboxLightTheme } from '../../Checkbox/themes/light-v2.theme';
import { collapseLightV2Theme } from '../../Collapse/themes/light-v2.theme';
import { fieldContainerLightTheme } from '../../containers/FieldContainer/themes/light.theme';
import { pageLightTheme } from '../../containers/Page/themes/light.theme';
import { drawerLightThemeV2 } from '../../Drawer/themes/light-v2.theme';
import { filterLightThemeV2 } from '../../Filter/themes/light-v2.theme';
import { infoLightTheme } from '../../Info/themes/light.theme';
import { inlineNotificationLightTheme } from '../../InlineNotification/themes/light-v2.theme';
import { searchLightTheme } from '../../Input/Search/themes/light.theme';
import { selectLightTheme } from '../../inputs/Select/themes/light.theme';
import { multiTagLightV2Theme } from '../../MultiTag/themes/light.theme';
import { highlightLightTheme } from '../../Notifications & Messages/themes/light-v2.theme';
import { linearProgressTheme } from '../../progress/LinearProgress/themes/light-v2.theme';
import { spinnerLightTheme } from '../../progress/Spinner/themes/lightTheme';
import { tagLightV2Theme } from '../../Tag/themes/light-v2.theme';
import { toggleLightV2Theme } from '../../Toggle/themes/light-v2.theme';
import link from '../../typography/Link/themes/light.theme';
import { textLightTheme } from '../../typography/Text/themes/light-v2.theme';
import { titleLightTheme } from '../../typography/Title/themes/light.theme';
import { opacity } from '../../utils';
import { colors } from '../colors';
import { baseColors } from './baseColors';
import { buttons } from './buttons';
import { buttonsGroup } from './buttonsGroup';
import headerButtons from './components/HeaderButton.variants';
import { container } from './container';
import { fillBar } from './fillBar';
import { fonts } from './fonts';
import header from './header';
import { icon } from './icon';
import { input } from './input';
import { label } from './label';
import { menu } from './menu';
import { notification } from './notification';
import { popover } from './popover';
import { sizes } from './sizes';
import table from './table';
import { tabs } from './tabs';
import { tooltip } from './tooltip';

export const BaseTheme = {
  name: 'base',

  // Scales
  breakpoints: ['40em', '52em', '64em'],
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120],
  shadow: ['none', `0 1px 4px 0 ${opacity(colors.gray[900], 40)}`],
  shadows: {
    none: 'none',
    small: `0 1px 4px 0 ${opacity(colors.gray[900], 40)}`,
  },

  // Fonts
  fonts,

  // Sizes
  sizes,

  // Borders
  border: `1px solid ${colors.gray[400]}`,

  colors: {
    ...colors,
    background: colors.gray[0],
    border: colors.gray[400],
    text: {
      ...baseColors.text,
    },
    actions: {
      primary: colors.blue[500],
      secondary: opacity(colors.blue[200], 10),
      accent: colors.orange[500],
    },
    semantic: {
      accent: colors.orange[500],
      announcement: colors.gray[800],
      danger: colors.red[500],
      info: colors.gray[800],
      notice: colors.gray[600],
      success: colors.green[500],
      warning: colors.orange[400],
      critical: colors.gray[0],
    },
    severity: {
      [SeverityLevel.Info]: colors.blue[400],
      [SeverityLevel.Low]: colors.green[500],
      [SeverityLevel.Medium]: colors.orange[500],
      [SeverityLevel.High]: colors.red[600],
      [SeverityLevel.Severe]: colors.magenta[600],
    },
  },

  // Components
  buttons,
  spinnerLightTheme,
  header,
  headerButtons,
  input,
  label,
  link,
  menu,
  notification,
  select: selectLightTheme,
  table,
  tag: tagLightV2Theme,
  tooltip,
  toggle: toggleLightV2Theme,
  drawer: drawerLightThemeV2,
  popover,
  buttonsGroup,
  buttonGroup: buttonGroupTheme,
  info: infoLightTheme,
  container,
  fillBar,
  page: pageLightTheme,
  icon,
  tabs,
  modal_backdrop_color: colors.gray[300],
  backdrop_background_color: opacity(colors.gray[200], 70),
  divider: { color: colors.gray[800] },
  card: {
    backgroundColor: colors.gray[200],
    borderColor: colors.gray[500],
  },
  radio: {
    border: {
      checked: colors.blue[500],
      default: colors.blue[500],
      disabled: colors.gray[800],
    },
    background: {
      checked: colors.gray[0],
      default: colors.gray[0],
      disabled: colors.gray[800],
    },
    innerCircle: {
      checked: colors.blue[500],
      default: colors.gray[0],
      disabled: colors.gray[800],
    },
  },
  badge: {
    accent: { backgroundColor: colors.orange[500] },
    announcement: { backgroundColor: colors.gray[800] },
    danger: { backgroundColor: colors.red[500] },
    info: { backgroundColor: colors.gray[800] },
    success: { backgroundColor: colors.green[500] },
    warning: { backgroundColor: colors.orange[400] },
  },
  inlineNotification: inlineNotificationLightTheme,
  highlight: highlightLightTheme,
  modal: {
    footer: {
      backgroundColor: 'white',
    },
  },
  checkbox: checkboxLightTheme,
  spinner: spinnerLightTheme,
  title: titleLightTheme,
  fieldContainer: fieldContainerLightTheme,
  text: textLightTheme,
  search: searchLightTheme,
  multiFilter: filterLightThemeV2,
  collapse: collapseLightV2Theme,
  multiTag: multiTagLightV2Theme,
  linearProgress: linearProgressTheme,
};
