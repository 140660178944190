export enum SortDirection {
  Descending = 'DESC',
  Ascending = 'ASC',
  Initial = 'Initial',
}

export enum CrudTableTestSubject {
  TableAddButton = 'table-add-button',
  NextPageButton = 'next-page-button',
  PrevPageButton = 'prev-page-button',
  PaginationPerPageSelector = 'PaginationPerPageSelector',
  TableEmptyState = 'table-empty-state',
}
