export enum ContainerSize {
  Small,
  Medium,
}

export enum ContainerBorderWidth {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}
