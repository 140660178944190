import { css } from 'styled-components';

export const italicsStyle = css`
  font-style: italic;
`;
export const underlineStyle = css`
  text-decoration: underline;
`;
export const strikeThroughStyle = css`
  text-decoration: line-through;
`;
export const upperCaseStyle = css`
  text-transform: uppercase;
`;
export const paragraphStyle = css`
  line-height: 1.5;
`;
export const codeStyle = css`
  background-color: ${({ theme }) => theme.text.code.backgroundColor};
  border: ${({ theme }) => theme.text.code.borderColor};
  border-radius: 2px;
  font-family: ${({ theme }) => theme.fonts.code};
`;
