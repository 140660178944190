import { FunctionComponent } from 'react';
import { SeverityLevel } from '@logz-pkg/enums';
import { Tag } from '../../../../Tag';
import { Select } from '../../Select.component';
import { SelectSizes } from '../../select-sizes.enum';
import { SelectDecoratedOption } from '../../components/DecoratedOption.component';
import type { ISelectOption, ISelectProps } from '../../Select.types';

export const severitySelectOptions: ISelectOption<SeverityLevel>[] = [
  { label: 'Severe', value: SeverityLevel.Severe },
  { label: 'High', value: SeverityLevel.High },
  { label: 'Medium', value: SeverityLevel.Medium },
  { label: 'Low', value: SeverityLevel.Low },
  { label: 'Info', value: SeverityLevel.Info },
];

interface ISeverityLevelSelectProps extends ISelectProps {
  borderless?: boolean;
  label?: string;
  value: SeverityLevel;
  onChange(choice: { label: string; value: any }): void;
  onBlur?: any;
  subject?: string;
  attachToBody?: ISelectProps['attachToBody'];
}

export const SelectSeverityLevel: FunctionComponent<ISeverityLevelSelectProps> = ({
  label,
  value,
  borderless,
  onChange,
  ...props
}) => {
  const OptionElement: FunctionComponent<ISelectOption<SeverityLevel>> = ({ label, value }) => (
    <SelectDecoratedOption label={<Tag.Severity severity={value}>{label}</Tag.Severity>} />
  );

  return (
    <Select
      options={severitySelectOptions}
      value={severitySelectOptions.find(option => option.value === value)}
      subject="severity-level-select"
      borderless={borderless}
      size={SelectSizes.Tiny}
      fitMenuToContent
      placeholder={'Severity'}
      OptionElement={OptionElement}
      SingleValueElement={OptionElement}
      onChange={onChange}
      {...props}
    />
  );
};
