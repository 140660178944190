import styled from 'styled-components';
import { position } from 'styled-system';

interface ISpinnerStyleProps {
  size?: 'xs' | 's' | 'm' | 'l';
  role?: string;
}

const sizes = {
  xs: 12,
  s: 16,
  m: 22,
  l: 28,
  xl: 60,
};

export const SpinnerSvg = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="loading__indicator__graphic">
    <defs>
      <linearGradient x1="28.154%" y1="63.74%" x2="74.629%" y2="17.783%" id={`${color}LoadingIndicatorGradient`}>
        <stop stopColor={color} offset="0%" />
        <stop stopColor={color} stopOpacity="0" offset="100%" />
      </linearGradient>
    </defs>
    <g transform="translate(2)" fill="none" fillRule="evenodd">
      <circle stroke={`url(#${color}LoadingIndicatorGradient)`} strokeWidth="4" cx="10" cy="12" r="10" />
      <path d="M10 2C4.477 2 0 6.477 0 12" stroke={color} strokeWidth="4" />
      <rect fill={color} x="8" width="4" height="4" rx="8" />
    </g>
  </svg>
);

export const SpinnerContainer = styled.div<ISpinnerStyleProps>`
  width: ${({ size }) => sizes[size] || 30}px;
  height: ${({ size }) => sizes[size] || 30}px;
  font-size: 14px;
  line-height: 10px;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  animation-name: spin;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  ${position}

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
