import React from 'react';
import { Group } from '../../containers/Group/Group.component';
import { Text } from '../../typography/Text/Text.component';
import { Flex } from '../../containers/Flex/Flex.component';
import { Color, colors } from '../../themes';

interface IStepsProgressProps {
  completed: number;
  total: number;
  title?: string;
  description?: string | JSX.Element;
  width?: string;
  vertical?: boolean;
  color?: Color;
}

const getBackgroundColor = (isCompleted, color) => {
  if (isCompleted) return color;

  return colors.gray['400'];
};

const Step = ({ isCompleted, vertical, color }) => {
  return (
    <Flex
      width={vertical ? '4px' : '100%'}
      height={vertical ? '16px' : '6px'}
      backgroundColor={getBackgroundColor(isCompleted, color)}
      style={{ borderRadius: '3px' }}
    />
  );
};

const ProgressLines = ({ stepsTotal, stepsCompleted, width, vertical, color }) => {
  return (
    <Group gap={1} width={width}>
      {Array.from({ length: stepsTotal }).map((_, index) => {
        return <Step isCompleted={index < stepsCompleted} key={index} vertical={vertical} color={color} />;
      })}
    </Group>
  );
};

export const StepsProgress: React.FunctionComponent<IStepsProgressProps> = ({
  title,
  description,
  completed,
  total,
  width = '350px',
  vertical = false,
  color = colors.yellow['600'],
}) => {
  const showHeader = Boolean(title) || Boolean(description);

  return (
    <Group vertical>
      {showHeader && (
        <Group gap={2}>
          <Text color={'royalBlue.1000'} size={12} weight={700}>
            {title}
          </Text>
          <Text color={'gray.600'} size={10} weight={400}>
            {description}
          </Text>
        </Group>
      )}
      <ProgressLines stepsTotal={total} stepsCompleted={completed} width={width} vertical={vertical} color={color} />
    </Group>
  );
};

StepsProgress.displayName = 'StepsProgress';
