import { Field } from 'formik';
import React from 'react';
import { Flex } from '../../../containers/Flex/Flex.component';
import { SuperDateRangePicker, SuperDateRangePickerProps } from '@logz-ui/dates';

export const SuperDateRangePickerFilter: React.FC<{
  name: string;
  quickOptions: SuperDateRangePickerProps['quickOptions'];
  sections?: SuperDateRangePickerProps['sections'];
}> = ({ name, quickOptions, sections }) => {
  return (
    <Field name={name}>
      {({ form, field }) => (
        <Flex>
          <SuperDateRangePicker
            autoSize
            quickOptions={quickOptions}
            inputVariant={'search-filter'}
            value={field.value}
            sections={sections}
            subject={`${name}-filter-popup`}
            onConfirm={value => form.setFieldValue(field.name, value)}
          />
        </Flex>
      )}
    </Field>
  );
};
