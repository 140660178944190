import React from 'react';
import { DateRange, getTimeRange } from '@logz-pkg/utils';
import DatePicker from 'react-datepicker';
import { StyledCalendarContainer } from '../AbsoluteFilter.styles';
import { CalendarStylesWrapper } from '../Calendar.styles';
import { IAbsoluteFilterProps } from '../AbsoluteFilter';
import { DateSection } from '../../types';
import { extractValuesFromDateRange } from '../utils';

interface IAbsoluteDatePickerProps {
  activeSection: DateSection;
  onChange: (newRange: DateRange, shouldSwitchSection?: boolean) => void;
  datePickerAbsoluteDates: ReturnType<typeof extractValuesFromDateRange>['datePickerAbsoluteDates'];
  shownDates: ReturnType<typeof extractValuesFromDateRange>['shownDates'];
  dateCustomizations: IAbsoluteFilterProps['dateCustomizations'];
  minDate: IAbsoluteFilterProps['minDate'];
  maxDate: IAbsoluteFilterProps['maxDate'];
}

export const AbsoluteDatePicker: React.FC<IAbsoluteDatePickerProps> = ({
  dateCustomizations,
  datePickerAbsoluteDates,
  shownDates,
  minDate,
  maxDate,
  activeSection,
  onChange,
}) => {
  const [start, end] = datePickerAbsoluteDates;
  const selectsStart = activeSection === 'start';

  const handleChange = (newDate: Date) => {
    const [shownStart, shownEnd] = shownDates;
    const startDate = selectsStart ? newDate : shownStart;
    const endDate = selectsStart ? shownEnd : newDate;
    const newTimeRange = getTimeRange(startDate, endDate);

    onChange(newTimeRange);
  };

  return (
    <CalendarStylesWrapper width={'100%'} dateCustomizations={dateCustomizations}>
      <StyledCalendarContainer visible section={activeSection}>
        <DatePicker
          key={`${activeSection}-${start}-${end}`}
          inline
          selected={selectsStart ? start : end}
          onChange={handleChange}
          startDate={start}
          endDate={end}
          selectsStart={selectsStart}
          selectsEnd={!selectsStart}
          minDate={selectsStart ? minDate : start}
          maxDate={maxDate}
          showTimeSelect
          todayButton={'Today'}
          timeCaption="Start time"
          timeFormat="HH:mm"
          timeIntervals={15}
          disabledKeyboardNavigation={true}
          shouldCloseOnSelect={false}
        />
      </StyledCalendarContainer>
    </CalendarStylesWrapper>
  );
};
