import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  FlexDirectionProps,
  FlexGrowProps,
  FlexProps,
  FlexShrinkProps,
  HeightProps,
  MaxHeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  OverflowProps,
  SpaceProps,
} from 'styled-system';
import * as CSS from 'csstype';
import { sizeFromTheme } from '../../utils';
import { Flex } from '../Flex/Flex.component';

interface IBaseGroupProps {
  ref?: React.Ref<any>;
  vertical?: boolean;
  gap?: number | string;
  rowGap?: number | string;
  alignItems?: string;
  width?: string | number;
  context?: string;
  justifyContent?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  subject?: string;
  flexWrap?: CSS.Property.FlexWrap;
  className?: string;
  flex?: string;
  name?: string;
  sticky?: boolean;
  reverse?: boolean;
  onClick?(): void;
  children?: React.ReactNode | React.ReactNode[];
}

const StyledGroup = styled(Flex)<IBaseGroupProps>`
  flex-direction: ${({ vertical, flexDirection, reverse }) => {
    const rowDirection = !reverse ? 'row' : 'row-reverse';

    return flexDirection ?? (vertical ? 'column' : rowDirection);
  }};

  ${({ gap, theme }) =>
    gap &&
    css`
      gap: ${sizeFromTheme(theme, gap)};
    `};

  ${({ rowGap, theme }) =>
    rowGap &&
    css`
      row-gap: ${sizeFromTheme(theme, rowGap)};
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: initial;
    `};

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
`;

export type IGroupProps = IBaseGroupProps &
  HeightProps &
  MaxHeightProps &
  MinHeightProps &
  SpaceProps &
  MaxWidthProps &
  MinWidthProps &
  FlexProps &
  FlexGrowProps &
  FlexDirectionProps &
  OverflowProps &
  FlexShrinkProps;

export const Group = forwardRef<HTMLDivElement, IGroupProps>(({ children, ...props }, ref) => {
  const alignItems = props.alignItems || (props.fullWidth ? 'stretch' : 'baseline');

  return (
    <StyledGroup alignItems={alignItems} ref={ref} {...props}>
      {children}
    </StyledGroup>
  );
});

Group.displayName = 'Group';
Group.defaultProps = {
  gap: 2,
  width: 'fit-content',
  context: 'group',
};
