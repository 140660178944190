import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { BaseInput, IInputProps } from './Input.component';

export const DebouncedInput: FunctionComponent<IInputProps> = ({
  value: externalValue,
  onChange,
  debounceTime,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState((defaultValue || externalValue) ?? '');

  const debouncedOnChange = useCallback(
    debounce(e => {
      onChange(e);
    }, debounceTime),
    [],
  );

  useEffect(() => {
    setValue(externalValue);
  }, [externalValue]);

  const handleChange = event => {
    setValue(event.target.value);
    debouncedOnChange(event);
  };

  return <BaseInput {...props} value={value} onChange={handleChange} onBlur={onChange} />;
};
