import React, { FunctionComponent } from 'react';
import { StyledBadge, StyledBadgeContainer } from './Badge.styles';
import { IBadgeProps } from './Badge.types';

export const Badge: FunctionComponent<IBadgeProps & React.HTMLAttributes<HTMLDivElement>> = ({
  value,
  onClick = () => {},
  size = 's',
  children,
  subject,
  color,
  borderColor,
  variant,
  ...props
}: IBadgeProps) => {
  return (
    <StyledBadgeContainer>
      {children}
      <StyledBadge
        wrapper={Boolean(children)}
        onClick={() => onClick(value)}
        size={size}
        justifyContent={'center'}
        alignItems={'center'}
        context={'badge'}
        subject={subject}
        {...(variant ? { variant } : { color, borderColor, backgroundColor: color })}
        {...props}
      >
        {value}
      </StyledBadge>
    </StyledBadgeContainer>
  );
};

Badge.displayName = 'Badge';
