export enum ManageUsersTab {
  Users,
  Groups,
}

export enum ManageUsersSubjects {
  UserRoleSelect = 'select-role',
  CreateUser = 'create-user',
  FullNameInput = 'full-name-input',
  UsernameInput = 'username-input',
}

export enum ManageAuthGroupsSubjects {
  GroupNameInput = 'group-name-input',
}
