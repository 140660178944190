import type { Property } from 'csstype';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import styled from 'styled-components';
import { MarginProps, SizeProps, WidthProps } from 'styled-system';
import { Box } from '../../containers/Container/Box.component';
import { StatusIcon } from '../../Input/Input.component';
import { inputCss } from '../../Input/Input.styles';
import { Spinner } from '../../progress/Spinner/Spinner.component';
import { TooltipPlacement } from '../../tooltip/Tooltip.component';
import { PopoverError } from '../../popover/PopoverError.component';

export interface ITextareaProps extends TextareaAutosizeProps, SizeProps, MarginProps, WidthProps {
  maxRows?: number;
  minRows?: number;
  spellCheck?: boolean;
  error?: boolean | string | string[] | JSX.Element | JSX.Element[];
  errorPlacement?: TooltipPlacement;
  errorOverflow?: boolean;
  isLoading?: boolean;
  code?: boolean;
  variant?: string;
  subject?: string;
  size?: any;
  borderless?: boolean;
  resize?: Property.Resize;
  onEnterPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}

const StyledBox = styled(Box)`
  display: inline;
  vertical-align: top;
  position: relative;
  width: 100%;
`;

const StyledStatusIcon = styled(StatusIcon)`
  top: 8px;
  justify-content: flex-start;
`;

const defaultProps = {
  p: '7px 27px 7px 7px',
  variant: 'primary',
};

const WrappedTextarea: FunctionComponent<ITextareaProps> = ({
  value,
  name,
  disabled,
  placeholder,
  maxRows = 5,
  minRows = 2,
  onBlur,
  onChange,
  className,
  spellCheck = true,
  error,
  errorPlacement = 'top-start',
  isLoading = false,
  subject,
  code,
  errorOverflow,
  resize = 'vertical',
  onKeyDown,
  onEnterPress,
  ...props
}) => {
  const handleChange = event => {
    onChange?.(event);
  };

  const [, updateState] = useState<unknown>();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    // we want the fonts to load and then to recalculate the height
    // https://github.com/Andarist/react-textarea-autosize/issues/93
    // https://stackoverflow.com/questions/5680013/how-to-be-notified-once-a-web-font-has-loaded
    (window as any).document.fonts?.ready?.then(() => {
      forceUpdate();
    });
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      onEnterPress?.(e);
    }

    onKeyDown?.(e);
  };

  return (
    <StyledBox context="textarea" subject={subject}>
      <PopoverError error={error} placement={errorPlacement} overflow={errorOverflow} subject={`${subject}-error`}>
        <TextareaAutosize
          {...{
            onKeyDown: handleKeyDown,
            value,
            className,
            name,
            disabled,
            placeholder,
            maxRows,
            minRows,
            onBlur,
            onChange: handleChange,
            spellCheck: spellCheck ?? (code && false),
            style: { resize },
            ...props,
            ...(props.borderless ? { borderless: 'true' } : {}),
          }}
        />
      </PopoverError>
      <StyledStatusIcon>{isLoading && <Spinner size="s" />}</StyledStatusIcon>
    </StyledBox>
  );
};

const StyledTextarea = styled(WrappedTextarea)`
  font-size: ${({ theme }) => theme.sizes.text.medium};
  font-family: ${({ theme, code }) => (code ? theme.fonts.code : theme.fonts.base)};
  ${inputCss};
`;

StyledTextarea.displayName = 'Textarea';
(StyledTextarea as any).defaultProps = defaultProps;

export const Textarea = StyledTextarea;
