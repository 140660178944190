import { FieldContainer as PrimaryFieldContainer } from './FieldContainer.component';
import { FieldDescription, FieldError, FieldHelper, FieldLabel } from './Components';

type FieldContainerProps = typeof PrimaryFieldContainer & {
  Description: React.ElementType;
  Helper: React.ElementType;
  Label: React.ElementType;
  Error: React.ElementType;
};

const FieldContainer = PrimaryFieldContainer as FieldContainerProps;

FieldContainer.Description = FieldDescription;
FieldContainer.Helper = FieldHelper;
FieldContainer.Error = FieldError;
FieldContainer.Label = FieldLabel;

export { FieldContainer };
