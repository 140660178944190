import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { PopoverPlacement } from '../popover/types';
import { Tooltip } from '../tooltip/Tooltip.component';

const CopyWrapper = styled.span<{ text: string }>`
  &:hover {
    ${({ text }) =>
      text &&
      css`
        cursor: copy;
      `};
  }
`;

interface ICopyToClipboardProps {
  text: string;
  onCopy?: (value?: string) => void;
  className?: string | undefined;
  subject?: string;
  children: React.ReactNode;
  tooltipPlacement?: PopoverPlacement;
}

export const CopyToClipboard = ({
  children,
  text = '',
  onCopy,
  className = undefined,
  tooltipPlacement = 'top',
  subject,
}: ICopyToClipboardProps) => {
  const [tooltipDisplay, handleTooltipDisplay] = useState(false);

  function handleClick() {
    if (!text) return;

    copy(text);

    if (onCopy) onCopy(text);

    handleTooltipDisplay(true);
    setTimeout(() => handleTooltipDisplay(false), 500);
  }

  const testId = subject ? generateLogzTestAttributes({ subject }) : '';

  return (
    <Tooltip title={'Copied'} placement={tooltipPlacement} disableEvents={true} open={tooltipDisplay}>
      <CopyWrapper className={className} onClick={handleClick} text={text.toString()} {...testId}>
        {children}
      </CopyWrapper>
    </Tooltip>
  );
};

CopyToClipboard.displayName = 'CopyToClipboard';
