import { cloneDeep } from 'lodash';
import { OptionResult, SelectedOption } from '../types';
import { CheckboxListSingle } from '@logz-ui/styleguide';

const areTheyEqual = (item1: SelectedOption, item2: SelectedOption) =>
  item1.type === item2.type && item1.id === item2.id;

const isOptionSelected = (option: CheckboxListSingle, value: SelectedOption[]) => {
  const foundValue = value.find(({ type, id }) => type === option.value.type && id === option.value.id);

  if (!foundValue) return null;

  if (foundValue.state === 'ENABLED') return true;

  return false;
};

const isOptionDisabled = (option: CheckboxListSingle, value: SelectedOption[]) => {
  const foundValue = value.find(({ type, id }) => type === option.value.type && id === option.value.id);

  if (!foundValue) return null;

  return !!foundValue?.isDisabled;
};

const mergeValuesAndOptions = (options: OptionResult, value: SelectedOption[]) => {
  if (value.length === 0) return options;

  const existingIds = new Set(options.results.map(result => result.id));

  const allValuesExist = value.every(val => existingIds.has(val.id));

  if (allValuesExist) return options;

  const copiedOptions = cloneDeep(options);

  value.forEach(val => {
    if (!existingIds.has(val.id)) {
      copiedOptions.results.unshift({
        id: val.id,
        title: val.id,
        type: val.type,
      });
      existingIds.add(val.id);
    }
  });

  copiedOptions.total = Math.max(copiedOptions.total, copiedOptions.results.length);

  return copiedOptions;
};

export const filterUtils = {
  areTheyEqual,
  isOptionSelected,
  isOptionDisabled,
  mergeValuesAndOptions,
};
