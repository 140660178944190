const colorScheme = [
  '#e3ba46',
  '#b763de',
  '#54c7c6',
  '#3b448b',
  '#219dd4',
  '#589026',
  '#eb8490',
  '#f19300', // EOF -> Colors from Adi
  '#3b73ac',
  '#479dc6',
  '#32a7c2',
  '#c5d558',
  '#f7be4f',
  '#f4a755',
  '#f29656',
  '#f08656',
  '#ec6856',
  '#eb5357',
  '#e04d70',
  '#c2458e',
  '#9e5297',
  '#6c478f',
  '#4f478f',
  '#444c92',
  '#42599b',
  '#2d5b89',
  '#287296',
  '#2e889e',
  '#cbc952',
  '#e1d76e',
  '#caaa52',
  '#c89951',
  '#ca8a52',
  '#c97b50',
  '#cb6f50',
  '#cd5451',
  '#c94a4f',
  '#b14458',
  '#9f4076',
  '#8a428a',
  '#5b3f7c',
  '#3d4078',
  '#3c4b7f',
  '#679ac9',
  '#67aed1',
  '#6cc2d5',
  '#82c2a3',
  '#b6d06f',
  '#d5de77',
  '#f6ef84',
  '#fff88e',
  '#fbe27d',
  '#ffd480',
  '#f9c57f',
  '#f6b87e',
  '#f4aa80',
  '#f0957e',
  '#ee7c7d',
  '#e8759a',
  '#cc6ea7',
  '#ab6aa2',
  '#89639e',
  '#6f65a0',
  '#636ca7',
  '#647fb3',
];

const getColorByString = name => {
  // This converts the string to a 32bit integer hash
  // taken from here :http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
  let hash = 0;

  if (name.length === 0) {
    return hash;
  }

  for (let i = 0; i < name.length; i += 1) {
    const char = name.charCodeAt(i);

    hash = (hash << 1) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  const normalized = Math.abs(hash) % colorScheme.length;

  return colorScheme[normalized];
};

const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) } : null;
};

const rgbToHex = ({ r, g, b }): string => {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const hashCode = str => {
  // java String#hashCode
  let hash = 0;

  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
};
const intToRGB = i => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

const convertHexToRgba = (hex, opacity = 1) => {
  opacity = opacity >= 0 && opacity <= 1 ? opacity : 1;

  const rgba = hexToRgb(hex);

  return ['rgba(', rgba.r, ',', rgba.g, ',', rgba.b, ',', opacity, ')'].join('');
};

const convertStringToRGBA = (string, opacity) => convertHexToRgba(getColorByString(string), opacity);

const calculateColor16 = (colorStrength: number, percent: number): string => {
  colorStrength = parseInt(String((colorStrength * (100 + percent)) / 100), 10);
  colorStrength = colorStrength < 255 ? colorStrength : 255;

  const color16 = colorStrength.toString(16);

  return String(color16).padStart(2, '0');
};

const shadeColor = (color, percent) => {
  const R = parseInt(color.substring(1, 3), 16);
  const G = parseInt(color.substring(3, 5), 16);
  const B = parseInt(color.substring(5, 7), 16);

  return ['#', calculateColor16(R, percent), calculateColor16(G, percent), calculateColor16(B, percent)].join('');
};

const getRandomColor = () => {
  const letters = '0123456789ABCDEF'.split('');
  let color = '#';

  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export const colorService = {
  getColorByString,
  hexToRgb,
  rgbToHex,
  hashCode,
  intToRGB,
  convertStringToRGBA,
  convertHexToRgba,
  calculateColor16,
  shadeColor,
  colorScheme,
  getRandomColor,
};
