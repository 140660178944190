import { useListContext } from '../context';
import { Flex } from '../../containers/Flex/Flex.component';
import { Text } from '../../typography';
import { mapSizeToFontSize } from '../config';

export const OrderNumber = ({ num }: { num: number }) => {
  const { size } = useListContext();
  const fontSize = mapSizeToFontSize[size];

  return (
    <Flex width={fontSize} height={fontSize} alignItems={'center'} justifyContent={'right'}>
      <Text size={fontSize} weight={500} textAlign={'right'}>
        {num}.
      </Text>
    </Flex>
  );
};
