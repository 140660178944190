import React from 'react';
import { SortDirection } from '@logz-pkg/enums';
import { Table } from '../Table';
import { Group } from '../../containers/Group/Group.component';
import { Info } from '../../Info/Info.component';
import { CrudTableTypes } from './Crud.types';

interface ITableHeaderCellProps {
  column: CrudTableTypes.IColumn;
  onSort(): void;
  sortDirection?: SortDirection;
  defaultSortingDirection: SortDirection;
  children: React.ReactNode;
}

export const TableHeaderCell: React.FunctionComponent<ITableHeaderCellProps> = ({
  column,
  onSort,
  sortDirection,
  defaultSortingDirection,
  ...props
}) => (
  <Table.HeaderCell
    {...props}
    minWidth={column.minWidth}
    highlight={column.isHighlighted}
    onSort={column.isSortable ? onSort : null}
    sortDirection={column.isSortable && sortDirection}
    defaultSortingDirection={defaultSortingDirection}
    context={`header-${column.key}`}
  >
    {(column.tooltip && (
      <Group alignItems="center">
        <span>{column.title}</span>
        <Info tooltip={column.tooltip} />
      </Group>
    )) ?? <span>{column.title}</span>}
  </Table.HeaderCell>
);
