// The string values are so we could send these values (e.g - for analytics)
export enum WelcomeCenterUILocations {
  Bar = 'bar',
  Modal = 'modal',
}

export enum WelcomeFlowStep {
  First,
  Second,
  Third,
  End,
}
