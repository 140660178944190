import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { BreakpointProvider } from '@logz-pkg/react-hooks';
import { LogzioTheme } from '@logz-pkg/enums';
import { darkV2Theme } from './dark-v2.theme';
import { lightV2Theme } from './light-v2.theme';

export { colors, logLevelsColors } from './colors';
export { fonts } from './base/fonts';
export { colorsV2 } from './colors-v2';
export type { ColorPalette, ColorV2 } from './colors-v2';
export type { Color } from './colors';
export { DarkTheme } from './dark';
export { MarketingTheme } from './marketing';
export { lightV2Theme } from './light-v2.theme';
export { darkV2Theme } from './dark-v2.theme';
export { iconSize, semanticColor, textColor, textSize, textWeight, severityColor } from './selectors';

const withTry =
  method =>
  (...args) => {
    try {
      return method(...args);
    } catch (e) {
      return undefined;
    }
  };

const is = n => n !== undefined && n !== null;
const value = (n, props) => (typeof n === 'function' ? withTry(n)(props) : n);
const get = (obj, path, fallback) => path.reduce((a, b) => (a && a[b] ? a[b] : null), obj) || fallback;

export const withThemesByProps = (...byProps) => {
  const styleValues = byProps.pop();

  return props =>
    get(
      value(styleValues, props),
      byProps.map(prop => props[prop]),
      null,
    );
};

export const getThemeObject = (theme: LogzioTheme) =>
  ({
    [LogzioTheme.Light]: lightV2Theme,
    [LogzioTheme.Dark]: darkV2Theme,
  }[theme] || lightV2Theme);

export const withThemesFallback =
  (...fbChain) =>
  props =>
    fbChain.find(fb => is(value(fb, props)));

export const LogzioThemeProvider: FunctionComponent<{ theme?: any; children: React.ReactNode }> = ({
  children,
  theme,
}) => (
  <BreakpointProvider>
    <ThemeProvider theme={lightV2Theme}>
      {theme ? <ThemeProvider theme={theme}>{children}</ThemeProvider> : children}
    </ThemeProvider>
  </BreakpointProvider>
);
