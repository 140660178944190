import { colors } from '../colors';

export const marketingBase = {
  colors: {
    background: colors.blue[800],
    text: {
      base: colors.yellow[200],
      title: colors.gray[0],
      Modal: colors.yellow[200],
    },
  },
};
