export enum NotificationEndpointType {
  Slack = 'Slack',
  BigPanda = 'BigPanda',
  Datadog = 'Datadog',
  PagerDuty = 'PagerDuty',
  ServiceNow = 'ServiceNow',
  VictorOps = 'VictorOps',
  Opsgenie = 'Opsgenie',
  Custom = 'Custom',
  MicrosoftTeams = 'Microsoft Teams',
  MicrosoftTeamsWorkflow = 'Microsoft Teams Workflow',
}

export enum NotificationEndpointNotificationSubject {
  CreateNotificationEndpoint = 'new-notification-endpoint-added',
}

export enum NotificationEndpointsSubjects {
  NotificationEndpointsPage = 'notification-endpoints-page',
  Table = 'notification-endpoints-table',
  TableEmptyState = 'table-empty-state',
  Modal = 'notification-endpoints-modal',
  EndpointTypeSelect = 'endpointType',
  EndpointTitleInput = 'endpoint-title',
  EndpointDescriptionInput = 'endpoint-description',
  EndpointUrlInput = 'endpoint-url',
}
