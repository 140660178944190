import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Popover, IPopoverProps } from '@logz-ui/styleguide/components/popover/Popover.component';
import { ITooltipProps, Tooltip } from '@logz-ui/styleguide/components/tooltip/Tooltip.component';
import { Flex } from '@logz-ui/styleguide/components/containers/Flex/Flex.component';
import { DateRangePickerSubject } from '@logz-pkg/enums';
import { DateRange } from '@logz-pkg/utils';
import {
  DatePickerInputProps,
  DatePickerSection,
  InputVariant,
  QuickOption,
  FooterNotificationProps,
  PopupVariant,
  DateCustomization,
} from './types';
import { calculateWidth, getInternalValue, getReadableValue } from './utils';
import { SuperDateRangePickerPopup } from './Popup/Popup';
import { defaultQuickOptions } from './Popup/default-quick-options';
import { SearchFilterInput } from './Input/SearchFilterInput';
import { StandardInput } from './Input/StandardInput';
import { WarningInput } from './Input/WarningInput';
import { PLACEHOLDER_TEXT } from './constants';

export type SuperDateRangePickerProps = {
  inputVariant?: InputVariant;
  popupVariant?: PopupVariant;
  autoSize?: boolean;
  inline?: boolean;
  label?: string;
  value?: DateRange;
  helper?: string;
  subject?: string;
  error?: string | JSX.Element;
  fullWidth?: boolean;
  minWidth?: number;
  onChange?: (range: DateRange) => void;
  onConfirm?: (range: DateRange) => void;
  onCancel?: () => void;
  quickOptions?: QuickOption[];
  popoverProps?: Partial<Omit<IPopoverProps, 'Content'>>;
  sections?: DatePickerSection[];
  showEndDateNowButton?: boolean;
  timezone?: string;
  showQuickFilterActionsBar?: boolean;
  minDate?: Date;
  maxDate?: Date;
  dateCustomizations?: DateCustomization[];
  renderFooterNotification?: (props: FooterNotificationProps) => React.ReactNode;
  renderActionsBarNotification?: () => React.ReactNode;
  tooltip?: ITooltipProps;
  loading?: boolean;
};

export const StyledContainer = styled.div`
  display: flex;
  position: relative;

  .font-awesome-icon-container {
    position: absolute;
    top: calc(50% - 6px);
    left: 8px;
  }
`;

const inputs: Record<InputVariant, React.FC<DatePickerInputProps>> = {
  standard: StandardInput,
  'search-filter': SearchFilterInput,
  warning: WarningInput,
};

const Component: React.FC<SuperDateRangePickerProps & { closePopover: () => void }> = ({
  value,
  popupVariant,
  quickOptions,
  onChange,
  closePopover,
  onCancel,
  onConfirm,
  subject,
  sections,
  showEndDateNowButton,
  timezone,
  minDate,
  maxDate,
  dateCustomizations,
  showQuickFilterActionsBar,
  renderFooterNotification,
  renderActionsBarNotification,
  loading,
}) => {
  return (
    <SuperDateRangePickerPopup
      value={value}
      variant={popupVariant}
      quickOptions={quickOptions}
      onChange={onChange}
      onCancel={() => {
        closePopover();
        onCancel?.();
      }}
      onConfirm={range => {
        closePopover();
        onConfirm?.(range);
      }}
      subject={subject}
      sections={sections}
      showEndDateNowButton={showEndDateNowButton}
      timezone={timezone}
      minDate={minDate}
      maxDate={maxDate}
      dateCustomizations={dateCustomizations}
      showQuickFilterActionsBar={showQuickFilterActionsBar}
      renderFooterNotification={renderFooterNotification}
      renderActionsBarNotification={renderActionsBarNotification}
      closePopover={closePopover}
      loading={loading}
    />
  );
};

export const SuperDateRangePicker: React.FC<SuperDateRangePickerProps> = ({
  inputVariant = 'standard',
  popupVariant = 'standard',
  fullWidth: fullWidthInput = false,
  inline = false,
  value: valueProp,
  autoSize = false,
  minWidth = 135,
  helper,
  subject,
  error,
  onChange,
  onConfirm,
  onCancel,
  quickOptions = defaultQuickOptions,
  popoverProps,
  sections,
  showEndDateNowButton = false,
  timezone,
  minDate,
  maxDate,
  dateCustomizations,
  showQuickFilterActionsBar,
  renderFooterNotification,
  renderActionsBarNotification,
  tooltip,
  loading,
}) => {
  const value = getInternalValue(valueProp, timezone);

  const readableValue = useMemo(
    () => !inline && getReadableValue(value, quickOptions),
    [value?.start.value, value?.end.value, inline, quickOptions],
  );

  const { width, fullWidth } = useMemo(() => {
    if (autoSize) return { width: calculateWidth(readableValue, minWidth, PLACEHOLDER_TEXT), fullWidth: false };

    return { width: null, fullWidth: fullWidthInput };
  }, [value, fullWidthInput, readableValue, autoSize]);

  const input = useMemo(() => {
    const Input = inputs[inputVariant];

    return (
      <Input
        readOnly
        width={width}
        height={`32px`}
        fullWidth={fullWidth}
        helper={helper}
        error={error as string}
        value={readableValue}
        subject={DateRangePickerSubject.FormInput}
        placeholder={PLACEHOLDER_TEXT}
      />
    );
  }, [width, fullWidth, helper, error, readableValue, inputVariant]);

  return inline ? (
    <SuperDateRangePickerPopup
      value={value}
      variant={popupVariant}
      quickOptions={quickOptions}
      onCancel={onCancel}
      onChange={onChange}
      onConfirm={onConfirm}
      subject={subject}
      sections={sections}
      showEndDateNowButton={showEndDateNowButton}
      timezone={timezone}
      minDate={minDate}
      maxDate={maxDate}
      dateCustomizations={dateCustomizations}
      showQuickFilterActionsBar={showQuickFilterActionsBar}
      renderFooterNotification={renderFooterNotification}
      renderActionsBarNotification={renderActionsBarNotification}
      loading={loading}
    />
  ) : (
    <Popover
      overflow
      trigger={'click'}
      placement={'bottom-start'}
      wrapChildWithRef={false}
      zIndex={999}
      offset={10}
      overflowable
      {...(popoverProps ?? {})}
      Content={{
        Component,
        props: {
          value,
          popupVariant,
          quickOptions,
          onChange,
          onCancel,
          onConfirm,
          subject,
          sections,
          showEndDateNowButton,
          timezone,
          minDate,
          maxDate,
          dateCustomizations,
          showQuickFilterActionsBar,
          renderFooterNotification,
          renderActionsBarNotification,
          loading,
        },
      }}
    >
      <StyledContainer>
        {tooltip ? (
          <Tooltip {...tooltip}>
            <Flex>{input}</Flex>
          </Tooltip>
        ) : (
          input
        )}
      </StyledContainer>
    </Popover>
  );
};
