import { colors } from '../colors';

const header = {
  backgroundColor: colors.gray[900],
  logo_background_hover: colors.blue[800],
  item_color: colors.gray[500],
  item_background: 'transparent',
  item_active_color: colors.blue[300],
  item_active_background: colors.blue[800],
  item_hover_color: colors.gray[500],
  item_hover_background: colors.blue[800],
};

export default header;
