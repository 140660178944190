import { Field } from 'formik';
import React from 'react';
import { ButtonGroup } from '../../../ButtonGroup/ButtonGroup.component';
import { Option } from '../../types';
import { IFilterField } from '../../interfaces';

export interface IToggleFilter extends IFilterField {
  options: Option[];
}

export const ToggleFilter: React.FC<IToggleFilter> = ({ name, options }) => {
  return (
    <Field name={name}>
      {({ form, field }) => (
        <ButtonGroup
          subject={`toggleFilter-${name}`}
          options={options}
          value={field.value}
          onChange={value => form.setFieldValue(field.name, value)}
        />
      )}
    </Field>
  );
};
