import React, { FunctionComponent, Ref } from 'react';
import styled, { css } from 'styled-components';
import { Group } from '../containers/Group/Group.component';

interface IStyledGroup {
  vertical?: boolean;
  snapAlignment?: string;
  ref?: Ref<HTMLDivElement>;
}

const StyledGroup = styled(Group)<IStyledGroup>`
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  ::-webkit-scrollbar {
    display: none;
  }
  ${({
    theme: {
      scrollSnapping: { padding },
    },
  }) => css`
    padding-bottom: ${padding};

    & > * {
      scroll-snap-align: start;
      scroll-margin-inline-start: ${padding};
    }

    & > :nth-child(1) {
      scroll-margin-inline-start: ${padding};
      margin-left: ${padding};
    }

    & > :last-child {
      scroll-margin-inline-end: ${padding};
      margin-right: ${padding};
    }
  `}
`;

interface IScrollGroupProps {
  gap?: number;
  groupRef?: Ref<HTMLDivElement>;
  children: React.ReactNode;
}

export const ScrollGroup: FunctionComponent<IScrollGroupProps> = ({ children, gap, groupRef }) => {
  return (
    <StyledGroup context={'scroll-snapping'} ref={groupRef} width={'auto'} gap={gap} alignItems={'center'}>
      {children}
    </StyledGroup>
  );
};
