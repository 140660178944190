import React, { ReactElement, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Flex } from '../../../containers/Flex/Flex.component';
import { Group } from '../../../containers/Group/Group.component';
import { Text } from '../../../typography';
import { Icon, IIconProps } from '../../../icons/Icon.component';
import { SelectedValueText } from './MultiSelectFilterLabel';

interface ISelectFilterLabelProps {
  label: string | ReactElement;
  value?: string;
  onLabelClick(): void;
  clearable?: boolean;
  minButtonWidth?: number;
  showLabel?: boolean;
  showLabelColon?: boolean;
}

const ActionIcon = ({ value, clearable, onClick }) => {
  const props: IIconProps = {
    icon: 'chevron-down-regular',
    size: 12,
  };

  if (value && clearable) {
    props.icon = 'xmark-regular';
    props.onClick = e => {
      e.stopPropagation();
      onClick();
    };
  }

  return <Icon {...props} />;
};

export const SelectFilterLabel: React.FC<ISelectFilterLabelProps> = ({
  label,
  value,
  onLabelClick,
  minButtonWidth,
  clearable,
  showLabel = true,
  showLabelColon = true,
}) => {
  const { searchFilters } = useContext(ThemeContext);

  return (
    <Group
      justifyContent={'space-between'}
      alignItems={'center'}
      minWidth={minButtonWidth}
      onClick={value ? null : onLabelClick}
    >
      <Text
        weight={400}
        textAlign={'left'}
        color={value ? searchFilters.button.active.label.color : searchFilters.button.inactive.label.color}
      >
        <Group gap={0} alignItems={'center'}>
          {!value && (
            <Text noTextWrap inheritColor>
              {label}
            </Text>
          )}
          <Flex>
            {value && (
              <>
                {showLabel && (
                  <Text noTextWrap inheritColor>
                    {label}
                  </Text>
                )}
                {showLabelColon ? ':' : ''}
                <SelectedValueText ellipsis>&nbsp;{value}</SelectedValueText>
              </>
            )}
          </Flex>
        </Group>
      </Text>
      <ActionIcon value={value} clearable={clearable} onClick={onLabelClick} />
    </Group>
  );
};
