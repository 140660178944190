export enum Permissions {
  ACCOUNT_ANALYZER_SETTINGS = 'ACCOUNT_ANALYZER_SETTINGS',
  ACCOUNT_FIELD = 'ACCOUNT_FIELD',
  ACCOUNT_OPEN_SESSION = 'ACCOUNT_OPEN_SESSION',
  ACCOUNT_TOPIC = 'ACCOUNT_TOPIC',
  ADMIN_ACCOUNT_SAWMILL_NOTE = 'ADMIN_ACCOUNT_SAWMILL_NOTE',
  ADMIN_ACCOUNT_SAWMILL_PIPELINE = 'ADMIN_ACCOUNT_SAWMILL_PIPELINE',
  ADMIN_ACCOUNT_TOKEN_RESET_PERMISSION = 'ADMIN_ACCOUNT_TOKEN_RESET_PERMISSION',
  ADMIN_AUDIT_TRAIL = 'ADMIN_AUDIT_TRAIL',
  ADMIN_MANAGE_SECURITY_ACCOUNT = 'ADMIN_MANAGE_SECURITY_ACCOUNT',
  ADMIN_SUPPORT_ACCESS = 'ADMIN_SUPPORT_ACCESS',
  ADMIN_TIMELESS_INDICES = 'ADMIN_TIMELESS_INDICES',
  ADMIN_UPGRADE_KIBANA_VERSION = 'ADMIN_UPGRADE_KIBANA_VERSION',
  ALERTS = 'ALERTS',
  API_TOKENS = 'API_TOKENS',
  APP_STATE = 'APP_STATE',
  ARCHIVE = 'ARCHIVE',
  AUDIT_TRAIL = 'AUDIT_TRAIL',
  AUTHENTICATION_GROUPS = 'AUTHENTICATION_GROUPS',
  AUTHORIZATION_EDIT = 'AUTHORIZATION_EDIT',
  AWS_MARKETPLACE_ACCOUNT_OWNER = 'AWS_MARKETPLACE_ACCOUNT_OWNER',
  AWS_MARKETPLACE_ADMIN = 'AWS_MARKETPLACE_ADMIN',
  BUSINESS_ANALYTICS = 'BUSINESS_ANALYTICS',
  CLOUDTRAIL = 'CLOUDTRAIL',
  DATA_PARSING = 'DATA_PARSING',
  DOC_SIZE_SETTING = 'DOC_SIZE_SETTING',
  DRILLDOWNS_PERMISSION = 'DRILLDOWNS_PERMISSION',
  DROP_LOGS_FILTERS = 'DROP_LOGS_FILTERS',
  EDIT_OWNER_ACCOUNT = 'EDIT_OWNER_ACCOUNT',
  ENDPOINT_PERMISSION = 'ENDPOINT_PERMISSION',
  FIELD_MAPPINGS = 'FIELD_MAPPINGS',
  FORCE_IMPERSONATION = 'FORCE_IMPERSONATION',
  GET_ACCOUNT_USED_FIELDS_NUM = 'GET_ACCOUNT_USED_FIELDS_NUM',
  GET_ALL_ACCOUNTS_USED_FIELDS_NUM = 'GET_ALL_ACCOUNTS_USED_FIELDS_NUM',
  GET_PERMISSIONS = 'GET_PERMISSIONS',
  GET_SEARCHABLE_ACCOUNTS = 'GET_SEARCHABLE_ACCOUNTS',
  GLOBAL_NOTIFICATIONS = 'GLOBAL_NOTIFICATIONS',
  IMPERSONATION = 'IMPERSONATION',
  INSIGHTS = 'INSIGHTS',
  LIVE_TAIL_SESSIONS = 'LIVE_TAIL_SESSIONS',
  LOGS_PATTERNS = 'LOGS_PATTERNS',
  LOOKUP_LISTS = 'LOOKUP_LISTS',
  MANAGE_SECURITY_ACCOUNT = 'MANAGE_SECURITY_ACCOUNT',
  MARKERS = 'MARKERS',
  METRICS_ACCOUNT = 'METRICS_ACCOUNT',
  METRICS_ACCOUNT_MANAGEMENT = 'METRICS_ACCOUNT_MANAGEMENT', // TODO: remove after DEV-19584 - here for backward compatibility
  METRICS_ACCOUNT_READ = 'METRICS_ACCOUNT_READ',
  METRICS_ACCOUNT_ROLLUP_CONFIG_READ = 'METRICS_ACCOUNT_ROLLUP_CONFIG_READ',
  METRICS_ACCOUNT_ROLLUP_CONFIG_WRITE = 'METRICS_ACCOUNT_ROLLUP_CONFIG_WRITE',
  METRICS_ACCOUNT_WRITE = 'METRICS_ACCOUNT_WRITE',
  METRICS_BUDGET_READ = 'METRICS_BUDGET_READ',
  METRICS_BUDGET_WRITE = 'METRICS_BUDGET_WRITE',
  METRICS_COMMON_ROLLUP_CONFIG_READ = 'METRICS_COMMON_ROLLUP_CONFIG_READ',
  METRICS_ROLLUP_CONFIG_MANAGEMENT = 'METRICS_ROLLUP_CONFIG_MANAGEMENT', // TODO: remove after DEV-19584 - here for backward compatibility
  METRICS_COMMON_ROLLUP_CONFIG_WRITE = 'METRICS_COMMON_ROLLUP_CONFIG_WRITE',
  READ_ALERTS = 'READ_ALERTS',
  READ_SECURITY_RULES_PERMISSION = 'READ_SECURITY_RULES_PERMISSION',
  READ_THREATS_FEEDS_PERMISSION = 'READ_THREATS_FEEDS_PERMISSION',
  REFRESH_KIBANA_MAPPINGS = 'REFRESH_KIBANA_MAPPINGS',
  SAVE_KIBANA_OBJECT = 'SAVE_KIBANA_OBJECT',
  SCHEDULED_REPORTS = 'SCHEDULED_REPORTS',
  SEARCH_ADMIN_PERMISSION = 'SEARCH_ADMIN_PERMISSION',
  SECURITY_RULES_PERMISSION = 'SECURITY_RULES_PERMISSION',
  SEND_YOUR_DATA_PAGE = 'SEND_YOUR_DATA_PAGE',
  SHARED_OBJECTS = 'SHARED_OBJECTS',
  SHARED_TOKENS = 'SHARED_TOKENS',
  SNAPSHOT = 'SNAPSHOT',
  SUB_ACCOUNT = 'SUB_ACCOUNT',
  SUPPORT_ACCESS = 'SUPPORT_ACCESS',
  THREATS_FEEDS_PERMISSION = 'THREATS_FEEDS_PERMISSION',
  TIMELESS_INDICES = 'TIMELESS_INDICES',
  TRIGGER = 'TRIGGER',
  TRIGGER_ADMIN = 'TRIGGER_ADMIN',
  USER_MANAGEMENT_ADMIN_PERMISSION = 'USER_MANAGEMENT_ADMIN_PERMISSION',
  USER_MANAGEMENT_PERMISSION = 'USER_MANAGEMENT_PERMISSION',
  USER_SESSION_PERMISSION = 'USER_SESSION_PERMISSION',
  UTILIZATION_SETTINGS = 'UTILIZATION_SETTINGS',
  WEEKLY_REPORT_SETTINGS = 'WEEKLY_REPORT_SETTINGS',
  ADD_SUB_ACCOUNT = 'ADD_SUB_ACCOUNT',
  DELETE_SUB_ACCOUNT = 'DELETE_SUB_ACCOUNT',
  ACTIVATE_FLEXIBLE_USAGE = 'ACTIVATE_FLEXIBLE_USAGE',
  FINANCE_CONSOLE_PERMISSION = 'FINANCE_CONSOLE_PERMISSION',
  LOG_METRIC_CREATE = 'LOG_METRIC_CREATE',
  LOG_METRIC_READ = 'LOG_METRIC_READ',
  LOG_METRIC_DELETE = 'LOG_METRIC_DELETE',
  LOG_METRIC_UPDATE = 'LOG_METRIC_UPDATE',
  LOG_METRIC_SET_ENABLED = 'LOG_METRIC_SET_ENABLED',
}
