import { Button } from '../../../Button/Button.component';

interface IPinProps {
  onPin?: (e) => void;
  isPinned?: boolean;
}

export const Pin = ({ onPin, isPinned }: IPinProps) => {
  const icon = isPinned ? 'thumbtack-solid' : 'thumbtack-regular';

  return <Button onClick={onPin} variant="tertiary" round icon={icon} size={'xs'} subject={'pin-icon-data-table'} />;
};
