import { colors } from '../../../themes/colors';
import { colorsV2 } from '../../../themes/colors-v2';

export const linearProgressTheme = {
  primary: {
    color: colors.blue[400],
    background: colors.blue[200],
  },
  radiant: {
    background: colors.blue[200],
    color: colorsV2.gradient.radiant,
  },
};
