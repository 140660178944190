import React from 'react';
import styled, { css } from 'styled-components';
import { Tag } from '../../Tag';

const activeStyle = css`
  background-color: ${({ theme }) => theme.colors.blue[200]};
  outline: 1px solid ${({ theme }) => theme.colors.blue[600]};
`;
const hoverStyle = css`
  background-color: ${({ theme }) => theme.colors.blue[300]};
`;

const StyledTag = styled(Tag)<{ active?: boolean }>`
  ${({ onClick, active }) =>
    onClick &&
    css`
      ${!active &&
      css`
        &:hover {
          ${hoverStyle}
        }
      `}
      &:active {
        ${activeStyle}
      }
    `}

  ${({ active }) => active && activeStyle}
`;

interface IPropertyProps {
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  property: string;
  active?: boolean;
}

export const Property: React.FC<IPropertyProps> = ({ onClick, property, active }) => {
  return (
    <StyledTag ellipsis={false} variant="code" onClick={onClick} active={active}>
      {property}
    </StyledTag>
  );
};
