import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ITextProps } from '../../typography/Text/Text.component';
import { ITagProps } from '../Tag.component';
import { Text } from '../../typography';

const fontSizes: Record<ITagProps['size'], ITextProps['size']> = {
  xs: '8px',
  s: 10,
  m: 12,
};

const lineHeightBySize: Record<ITagProps['size'], number> = {
  xs: 12,
  s: 16,
  m: 20,
};

export const StyledText = styled(Text)<{ customLineHeight?: number }>`
  line-height: ${({ customLineHeight }) => customLineHeight}px;
`;

export interface ITagTextProps {
  variant: ITagProps['variant'];
  ellipsis?: ITextProps['ellipsis'];
  size?: ITagProps['size'];
}

export const TagText: React.FC<PropsWithChildren<ITagTextProps>> = ({ variant, children, ellipsis, size = 'm' }) => {
  return (
    <StyledText
      variant={variant === 'code' ? 'code' : 'primary'}
      ellipsis={ellipsis}
      inheritColor
      weight={400}
      size={fontSizes[size]}
      customLineHeight={lineHeightBySize[size]}
      whiteSpace={'nowrap'}
    >
      {children}
    </StyledText>
  );
};
