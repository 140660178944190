import { opacity } from '../../utils';
import { colors } from '../../themes/colors';

export const buttonGroupTheme = {
  backgroundColor: colors.gray[0],
  color: colors.blue[500],
  borderColor: colors.gray[600],
  edgeButtonRadius: '18px',
  selected: {
    backgroundColor: opacity(colors.blue[500], 10),
    borderTopColor: colors.gray[600],
  },
  disabled: {
    backgroundColor: colors.gray[200],
    color: opacity(colors.gray[800], 70),
    borderColor: opacity(colors.gray[800], 40),
  },
  hover: {
    backgroundColor: opacity(colors.blue[500], 20),
  },
};
