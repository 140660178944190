export const intervalNames = [
  '1ms',
  '10ms',
  '100ms',
  '500ms',
  '1s',
  '5s',
  '10s',
  '30s',
  '1m',
  '10m',
  '30m',
  '1h',
  '6h',
  '12h',
  '1d',
  '7d',
  '1w',
  '1M',
  '1y',
] as const;

export type BucketIntervalType = 'ms' | 's' | 'm' | 'h' | 'd' | 'w' | 'M' | 'y';

export type BucketMap = {
  name: typeof intervalNames[number];
  ms: number;
  type: BucketIntervalType;
};

export const secondImMs = 1000;
export const minuteInMs = 60 * secondImMs;
export const hourInMs = 60 * minuteInMs;
export const dayInMs = 24 * hourInMs;

export const bucketMap: BucketMap[] = [
  { name: '1ms', ms: 1, type: 'ms' },
  { name: '10ms', ms: 10, type: 'ms' },
  { name: '100ms', ms: 100, type: 'ms' },
  { name: '500ms', ms: 500, type: 'ms' },
  { name: '1s', ms: secondImMs, type: 's' },
  { name: '5s', ms: 5 * secondImMs, type: 's' },
  { name: '10s', ms: 10 * secondImMs, type: 's' },
  { name: '30s', ms: 30 * secondImMs, type: 's' },
  { name: '1m', ms: minuteInMs, type: 'm' },
  { name: '10m', ms: 10 * minuteInMs, type: 'm' },
  { name: '30m', ms: 30 * minuteInMs, type: 'm' },
  { name: '1h', ms: hourInMs, type: 'h' },
  { name: '6h', ms: 6 * hourInMs, type: 'h' },
  { name: '12h', ms: 12 * hourInMs, type: 'h' },
  { name: '1d', ms: 24 * hourInMs, type: 'd' },
  { name: '1w', ms: 7 * dayInMs, type: 'w' },
  { name: '1M', ms: 30 * dayInMs, type: 'M' },
  { name: '1y', ms: 365 * dayInMs, type: 'y' },
];
