import { grayPalette } from '../../themes/colors';

export const modalLightTheme = {
  footer: {
    backgroundColor: grayPalette[100],
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
};
