import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '../../../../containers/Flex/Flex.component';
import { IDataTableProps } from '../../types';
import { Button } from '../../../../Button/Button.component';
import { dataTableUtils } from '../../utils';

const IndicatorWrapper = styled(Flex)<{ isExpanded: boolean }>`
  cursor: pointer;

  button svg {
    :hover {
      scale: 1.1;
      transition: all 0.2s ease-in-out;
    }
    ${({ isExpanded }) =>
      isExpanded &&
      css`
        transform: rotate(90deg);
      `}
  }
`;

export const SubComponentIndicatorComponent: IDataTableProps<any>['IndicatorComponent'] = ({
  row,
  density,
  onClick,
}) => {
  if (!row.getCanExpand()) return null;

  const paddingDensity = dataTableUtils.getDensityStyle(density).padding;
  const buttonYPadding = parseInt(paddingDensity.split(' ')[0], 10) - 2;

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    row.getToggleExpandedHandler()();
    onClick?.(row);
  };

  return (
    <IndicatorWrapper
      height="100%"
      width="100%"
      onClick={handleClick}
      subject="expand-log"
      px={'2px'}
      pt={`${buttonYPadding}px`}
      isExpanded={row.getIsExpanded()}
    >
      <Button size="xs" icon={'chevron-right-regular'} variant="tertiary" disabled={!row.getCanExpand()} />
    </IndicatorWrapper>
  );
};
