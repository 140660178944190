export enum SendYourDataTestSubject {
  CollectionPage = 'send-your-data-page',
  ShippingProductsTabs = 'shipping-products',
  ContentGrid = 'content-grid',
  SearchShippers = 'search-shippers',
  MarkdownContent = 'markdown-content',
  ContentCard = 'content-card',
  InstallGrafanaDashboards = 'install-grafana-dashboards',
  InfoCard = 'info-card',
  InfoCardTracingToken = 'info-card-tracing-token',
  InfoCardAdditionalToken = 'info-card-additional-token',
  AccountSelect = 'account-select',
  GuidedTours = 'guided-tours',
  NeedHelp = 'need-help',
  SeePlans = 'see-plans',
  Logout = 'logout',
  StartCollecting = 'start-collecting',
  AddBucketButton = 'add-bucket-button',
  SaveBucketButton = 'save-bucket-button',
  FilebeatSelector = 'filebeat-conf-wiz',
}

export enum AwsS3BucketSubject {
  DeleteBucketButton = 'aws-bucket-delete-button',
  BucketSettingsCard = 'aws-bucket-settings-card',
  ChooseSecretAccessTypeButton = 'aws-bucket-choose-secret-type',
  ChooseIAMRoleTypeButton = 'aws-bucket-choose-iam-type',
  BucketNameInput = 'aws-bucket-name-input',
  BucketPrefixInput = 'aws-bucket-prefix-input',
  BucketArnInput = 'aws-bucket-arn-input',
  BucketRegionSelect = 'aws-bucket-region-select',
  BucketSourcePathCheckbox = 'aws-bucket-include-source-path',
  BucketActiveToggle = 'aws-bucket-is-active-toggle',
  DeleteBucketModal = 'aws-delete-bucket-modal',
}
