import { Observable } from '@logz-pkg/observable';
import { NotificationAPI } from 'rc-notification';
import { NotificationCloseIcon } from './components/NotificationCloseIcon';
import { INotificationContentProps, notificationDefaults } from './Notification.types';
import { NotificationContent } from './components/NotificationContent';

class NotificationApiService {
  notificationApi = new Observable<NotificationAPI>();

  destroy = () => {
    const api = this.notificationApi.get();

    if (!api) {
      return;
    }

    api.destroy();
  };

  closeByKey = (key: string) => {
    const api = this.notificationApi.get();

    if (!api) {
      return;
    }

    api.close(key);
  };

  open = (args: INotificationContentProps) => {
    const api = this.notificationApi.get();

    if (!api) {
      return;
    }

    const subject = args.subject ?? '';
    const closable = args.closable ?? true;
    const autoHide = args.autoHide ?? notificationDefaults.autoHide;
    let duration = args.duration !== undefined ? args.duration : notificationDefaults.duration; // If duration is null the notification won't disappear until dismissed

    duration = autoHide ? duration : null;

    api.open({
      styles: { wrapper: { width: '480px' } },
      placement: 'bottomRight',
      closable,
      duration,
      closeIcon: <NotificationCloseIcon />,
      content: (
        <NotificationContent subject={subject} title={args.title} message={args.message} type={args.type}>
          {args.children}
        </NotificationContent>
      ),
      onClose: args.onClose,
      onClick: args.onClick,
      key: args.key,
      style: args.style || {},
      className: `notification-type-${args.type} ${subject ? `subject-${subject}` : ''}`,
    });
  };
}

export const notificationApiService = new NotificationApiService();
