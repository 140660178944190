import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FlexboxProps, PaddingProps, shadow, ShadowProps } from 'styled-system';
import { Flex } from '../containers/Flex/Flex.component';

const StyledContainer = styled(Flex)<ShadowProps>`
  background-color: ${({ theme }) => theme.header.backgroundColor};
  min-height: 50px;
  position: relative;
  ${shadow}
  box-shadow: ${({ boxShadow }) => (boxShadow === 'line' ? `0 -1px 0px 0 rgb(231 231 231) inset` : undefined)};
`;

interface IHeaderProps {
  shadow?: 'none' | 'small' | 'large' | 'line';
  subject?: string;
  children: React.ReactNode;
}

export const Header: FunctionComponent<IHeaderProps & FlexboxProps & PaddingProps> = ({
  children,
  shadow = 'line',
  ...props
}) => (
  <StyledContainer boxShadow={shadow} flexShrink={0} {...props}>
    {children}
  </StyledContainer>
);

Header.displayName = 'Header';
