export enum AverageUnit {
  Bytes,
  GigaBytes,
}

export enum PlanUpdateModals {
  AutoUpgrade,
  ManualUpgrade,
  Downgrade,
  Customize,
  AutoUpgradeSuccess,
}

export enum PlanChangeType {
  Upgrade = 'UPGRADE',
  Downgrade = 'DOWNGRADE',
}
