/* eslint-disable security/detect-object-injection */
import React from 'react';
import { useTheme } from 'styled-components';
import { lightV2Theme } from '../../themes';
import { IIconProps, Icon } from '../../icons/Icon.component';
import { INotificationContentProps } from '../Notification.types';
import { Spinner } from '../../progress/Spinner/Spinner.component';

const iconTypeMap: Record<INotificationContentProps['type'], IIconProps['icon'] | React.ReactNode> = {
  info: 'circle-info-solid',
  danger: 'diamond-exclamation-solid',
  success: 'circle-check-solid',
  warning: 'triangle-exclamation-solid',
  progress: <Spinner size={'s'} />,
};

interface INotificationIconProps {
  type: INotificationContentProps['type'];
}

export const NotificationIcon: React.FC<INotificationIconProps> = ({ type }) => {
  const theme = useTheme() as typeof lightV2Theme;

  if (React.isValidElement(iconTypeMap[type])) {
    return iconTypeMap[type];
  }

  return <Icon color={theme.notification[type].iconColor} size={16} icon={iconTypeMap[type] as IIconProps['icon']} />;
};
