import { css } from 'styled-components';
import { borderColor, shadow } from 'styled-system';

export const disabledCardStyles = props =>
  props.disabled &&
  css`
    pointer-events: none;
    opacity: 0.3;
  `;

export const highlightCardStyle = ({ theme, highlight }) =>
  highlight &&
  css`
    outline: 2px solid ${theme.card.highlight.borderColor};
    outline-offset: -2px;
    background-color: ${theme.card.highlight.backgroundColor};
    transition: outline 0.2s ease-in-out, background-color 0.2s ease-in-out;
  `;

export const onClickStyle = props =>
  props.onClick &&
  css`
    cursor: pointer;
    transition: box-shadow 0.2s ease-in-out;

    &:hover {
      ${shadow({ ...props, boxShadow: 'small' })}
    }
  `;

export const accentCardStyles = ({ theme, accentColor, accentLocation, accentWidth }) =>
  accentColor &&
  css`
    border-radius: 0;
    ${`border-${accentLocation}: ${accentWidth}px solid ${
      borderColor({ theme, borderColor: accentColor }).borderColor
    }`};
  `;

export const borderlessCardStyle = props =>
  props.borderless &&
  css`
    border-width: 0;
  `;
