import React, { useEffect, useMemo, useState } from 'react';
import { xor, isNil } from 'lodash';
import { FieldProps } from 'formik';
import { SelectSizes } from '../../../inputs/Select/select-sizes.enum';
import { Option } from '../../types';
import { IFilterField } from '../../interfaces';
import { StyledSelect } from './select-styles';
import { ListFooter, ListHeader, CheckboxOption, MenuList } from './MultiSelectList';
import { DropdownIndicator } from './DropdownIndicator';

interface IMultiSelectProps extends IFilterField {
  size?: SelectSizes;
  options: Option[];
  closePopover(): void;
  form: FieldProps['form'];
  field: FieldProps['field'];
  onSave?: (value: any[]) => void;
  loadOptions?: (searchTerm: string) => Option[];
  minimumSelectedOptions?: number;
}

export const MultiSelect: React.FC<IMultiSelectProps> = ({
  field,
  form,
  options = [],
  size = SelectSizes.Medium,
  name,
  closePopover,
  onSave,
  loadOptions,
  minimumSelectedOptions,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(null);
  const fieldValue = selectedOptions?.length > 0 ? selectedOptions.map(({ value }) => value) : undefined;

  const isSaveDisabled = useMemo(() => {
    if (isNil(selectedOptions)) return true;

    if (!isNil(minimumSelectedOptions) && selectedOptions.length < minimumSelectedOptions) return true;

    const mappedSelected = selectedOptions.map(({ value }) => value);

    // Check if there is any difference between chosen options and last saved state
    return xor(mappedSelected, field.value).length === 0;
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedOptions(options.filter(({ value }) => field.value?.includes(value)));
  }, []);

  const handleSave = () => {
    form.setFieldValue(field.name, fieldValue, false);
    onSave?.(fieldValue);
    closePopover();
  };

  const MenuListHeader = (
    <ListHeader
      onClear={() => setSelectedOptions([])}
      onSelectAll={() => setSelectedOptions(options)}
      numberOfOptions={options.length}
      numberOfSelectedOptions={selectedOptions?.length}
    />
  );

  const MenuListFooter = <ListFooter isSaveDisabled={isSaveDisabled} onSave={handleSave} onCancel={closePopover} />;

  return (
    <StyledSelect
      isMulti
      menuIsOpen
      size={size}
      options={options}
      isClearable={false}
      placeholder={`Search`}
      value={selectedOptions}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      hideSearch={options.length < 10}
      subject={`filter-${name}`}
      onChange={setSelectedOptions}
      controlShouldRenderValue={false}
      components={{ Option: CheckboxOption, MenuList, MenuListHeader, MenuListFooter, DropdownIndicator }}
      loadOptions={loadOptions}
    />
  );
};
