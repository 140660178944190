import styled, { css } from 'styled-components';
import { Icon } from '@logz-ui/styleguide/components/icons/Icon.component';
import { Group } from '@logz-ui/styleguide/components/containers/Group/Group.component';

export const StyledInputsContainer = styled(Group)`
  gap: 8px;
`;

export const StyledInputContainer = styled.div<{ active?: boolean; section?: string }>`
  display: flex;
  position: relative;
  width: auto;
  padding: 6px 8px;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.datePicker.absolute.input.backgroundColor};
  border: 1px solid ${({ theme }) => theme.datePicker.border};
  border-radius: 3px;

  &:not(:last-child) {
    margin: 0;

    &:after {
      position: absolute;
      content: '';
      top: 8px;
      bottom: 8px;
      right: 0;
      width: 0;
      box-sizing: border-box;
    }
  }

  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${({ theme }) => theme.datePicker.absolute.input.activeBorderColor};
      box-shadow: ${({ theme }) =>
        `-3px 3px 0px 0px ${theme.colors.blue[300]}, 3px -3px 0px 0px ${theme.colors.blue[300]}, -3px -3px 0px 0px ${theme.colors.blue[300]}, 3px 3px 0px 0px ${theme.colors.blue[300]}`};
    `}
`;

export const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.datePicker.absolute.input.color};
`;

export const StyledCalendarContainer = styled.div<{ visible?: boolean; section?: 'start' | 'end' }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  margin: 0;
  width: 100%;

  &:not(:last-child) {
    margin: 0;
  }

  .react-datepicker__navigation {
    .react-datepicker__navigation-icon::before {
      border-color: ${({ theme }) => theme.datePicker.absolute.input.color};
    }
  }

  ${({ section }) =>
    section === 'end' &&
    css`
      .react-datepicker {
        .react-datepicker__week:has(~ .react-datepicker__week .react-datepicker__day--range-start)
          .react-datepicker__day:not(.react-datepicker__day--range-start),
        .react-datepicker__day:has(~ .react-datepicker__day--range-start):not(.react-datepicker__day--range-start),
        .react-datepicker__day:has(~ .react-datepicker__day--range-start):not(.react-datepicker__day--range-start):hover {
          background-color: transparent;
          cursor: not-allowed;
        }
      }
    `}
`;
