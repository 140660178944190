import { bluePalette, grayPalette, greenPalette, redPalette, yellowPalette } from '../../themes/colors';

export const badgeLightTheme = {
  success: { color: greenPalette[600] },
  warning: { color: yellowPalette[600] },
  danger: { color: redPalette[600] },
  announcement: { color: bluePalette[600] },
  info: { color: grayPalette[600] },
  fontColor: 'white',
};
