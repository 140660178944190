export enum TracingSamplingRuleActionTypes {
  KeepPercentageOfTraces = 'keepPercentageOfTraces',
  KeepLatencyThreshold = 'keepLatencyThreshold',
  KeepFailed = 'keepFailed',
}

export enum TracingSamplingRulesSubject {
  Page = 'tracing-sampling-rules-page',
  EmptyState = 'empty-state',
  Modal = 'tracing-sampling-rule-modals',
  Services = 'services',
  AllServices = 'all-services',
  SpecificServices = 'specific-services',
  SelectedServices = 'selected-services',
  Actions = 'actions',
  ProbabilityAction = 'probability-action',
  TraceLatencyAction = 'trace-latency-action',
  SpanErrorAction = 'span-error-action',
  PercentValue = 'percent-value',
  MsValue = 'ms-value',
  UpdateConfigNotification = 'update-config-notification',
  YamlConfigurationPage = 'yaml-configuration-page',
}
