import styled from 'styled-components';
import { Input } from '@logz-ui/styleguide/components/Input';
import { DatePickerInputProps } from '../types';

export const BasicInput = styled(Input)<DatePickerInputProps>`
  margin-bottom: 0;
  width: 100%;
  input {
    min-width: 100px;
    padding-left: 24px;
    cursor: pointer;
  }
`;
