import { Field } from 'formik';
import React from 'react';
import { DateRangePicker } from '../../../lazy-components';

export const DateRangePickerFilter: React.FC<{ name: string; quickSelections: [] }> = ({ name, quickSelections }) => {
  return (
    <Field name={name}>
      {({ form, field }) => {
        return (
          <DateRangePicker
            subject={`${name}-filter-button`}
            value={field.value}
            width={'310px'}
            mb={0}
            onChange={value => form.setFieldValue(field.name, value)}
            quickSelections={quickSelections}
          />
        );
      }}
    </Field>
  );
};
