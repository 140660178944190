export enum IntegrationsSubject {
  Page = 'integrations-hub-page',
  IntegrationCard = 'integration-card',
  IntegrationCollapse = 'integration-collapse',
  IntegrationCardLink = 'integration-card',
  AgentWizardLink = 'agent-wizard-link',
  HubEmptyState = 'hub-empty-state',
  IntegrationPage = 'integration-page',
  CustomIntegrationPage = 'custom-integration-page',
  InfoCardLogsToken = 'info-card-logs-token',
  InfoCardMetricsToken = 'info-card-metrics-token',
  InfoCardTracingToken = 'info-card-tracing-token',
  MarkdownRender = 'markdown-render',
  SearchInput = 'search-input',
  IntegrationDescription = 'integration-description',
  GiveFeedback = 'integration-give-feedback',
  ThanksForFeedback = 'integration-thanks-for-feedback',
  Tabs = 'integrations-tabs',
  SelectTagButton = 'select-tag-button',
}

export enum ISidebarTagsSubject {
  Checkbox = 'sidebar-tag-checkbox',
}

export enum BundleTypes {
  OsdDashboards = 'OSD_DASHBOARD',
  OsdAlerts = 'LOG_ALERT',
  MetricsDashboards = 'GRAFANA_DASHBOARD',
  MetricsAlerts = 'GRAFANA_ALERT',
}

export enum IntegrationType {
  COLLECTOR = 'COLLECTOR',
  AGENT = 'AGENT',
}

export enum IntegrationsIQ {
  UserPromptTooLong = 'user-prompt-too-long',
}

export enum CustomIntegrationPages {
  Curl = 'cURL-data',
  Kubernetes = 'Kubernetes',
  NodeJs = 'Node-js',
  Java = 'Java',
  HTTP = 'HTTP',
  Python = 'Python',
  dotnet = 'dotnet',
  AwsS3Bucket = 'AWS-S3-Bucket',
  Filebeat = 'Filebeat-data',
  Rsyslog = 'Rsyslog-data',
}

export enum CustomIntegrationPagesProducts {
  Logs = 'logs',
  Metrics = 'metrics',
  Traces = 'traces',
  Content = 'content',
}
