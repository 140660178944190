export enum ServicesFieldTypes {
  ServiceName = 'service_name',
  DB = 'db_system',
  MESSAGING = 'messaging_system',
}

export enum OperationTypes {
  Operation = 'operation',
}

export enum EnvIdType {
  EnvId = 'env_id',
}

export type GroupByFieldTypes =
  | ServicesFieldTypes
  | OperationTypes
  | `${EnvIdType},${ServicesFieldTypes}`
  | `${EnvIdType},${OperationTypes}`
  | `${ServicesFieldTypes},${EnvIdType}`
  | `${OperationTypes},${EnvIdType}`;
