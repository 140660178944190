export enum LogzioCacheKeys {
  HEROKU = '_heroku_',
  SHARED_TOKENS = 'shared-tokens',
  SHARED_TOKENS_FILTERS = 'shared-tokens-filters',
  SHARE_MODE_FEATURES_ENABLED = 'share-mode-feature-flags',
  PLAN_PRICES = 'planPrices',
  SESSION_CONTEXT = 'session-context',
  KIBANA_MULTI_INDEX = 'kibana-multi-index',
  KIBANA_SEARCHED_ACCOUNTS_STATE = 'kibana-searched-accounts-state',
  SEARCHABLE_ACCOUNTS = 'searchable-accounts',
  SUB_ACCOUNTS = 'sub-accounts',
  ACCOUNTS_INDEX_MAP = 'accounts-index-map',
  KIBANA_CONFIG = 'api/kibana/settings',
  OSD_CONFIG = 'api/opensearch-dashboards/settings',
  ACCOUNTS_LIST = 'accounts-list',
  WHATS_NEW_ANNOUNCEMENTS = 'whats-new-announcements',
  HTTP_RESPONSE = 'http_response',
  LOGZIO_REGIONS = 'logzio-regions',
  SYSTEM_REGIONS = 'system-regions',
  SESSION_TOUR_PARAMS = 'session-tour-params',
  AUTHENTICATION_GROUPS = 'authentication-groups',
  CURRENT_ACCOUNT_ID = 'current-account-id',
  ADMIN_ACCOUNT_ID = 'admin-account-id',
}
