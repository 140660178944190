import React, { PropsWithChildren, createContext, useContext } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { lightV2Theme } from '../../themes';
import type { IMultiTagProps } from '../MultiTag.component';
import { multiTagUtils } from '../utils';
import { StyledMultiTag } from '../types';

const StyledTagContainer = styled.div<StyledMultiTag>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: 3px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ borderColor, invalid, theme }) => `1px solid ${invalid ? theme.multiTag.invalid : borderColor}`};
  cursor: ${({ state }) => (state === 'preview' ? 'default' : 'pointer')};
  flex: none;
  overflow-x: hidden;
  scroll-snap-align: start;
  max-width: 100%;

  button {
    color: ${({ theme, state }) => state === 'hovered' && theme.multiTag.closeButton.color.selected};
  }

  &:hover {
    ${({ theme, state }) =>
      state !== 'selected' &&
      state !== 'preview' &&
      css`
        border: 1px solid ${theme.multiTag.container.borderColor.hover};
        background-color: ${theme.multiTag.container.backgroundColor.hover};
      `};

    button {
      color: ${({ theme }) => theme.multiTag.closeButton.color.selected};
    }
  }
`;

type MultiTagContextProps = Partial<IMultiTagProps & { editMode: boolean }>;

const MultiTagContext = createContext<MultiTagContextProps>({});

export const useMultiTag = () => useContext(MultiTagContext);

export const TagContainer: React.FC<PropsWithChildren<Partial<IMultiTagProps> & { editMode?: boolean }>> = ({
  editMode,
  onSelected,
  state,
  invalid,
  onChange,
  tag,
  highlight,
  noTooltip,
  children,
  isEditable,
  ...props
}) => {
  const {
    multiTag: { container },
  } = useTheme() as typeof lightV2Theme;

  const backgroundColor = multiTagUtils.getBackgroundColor({
    container,
    editMode,
    state,
    defaultColor: tag?.style?.backgroundColor,
  });
  const borderColor = multiTagUtils.getBorderColor(container, state);

  return (
    <MultiTagContext.Provider
      value={{ editMode, onSelected, state, invalid, onChange, tag, highlight, noTooltip, isEditable }}
    >
      <StyledTagContainer
        {...{ state, backgroundColor, borderColor, invalid, ...props }}
        {...(state !== 'preview' && { onClick: onSelected })}
        data-state={state}
      >
        {children}
      </StyledTagContainer>
    </MultiTagContext.Provider>
  );
};
