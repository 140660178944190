export enum BillingInterval {
  Monthly = 'MONTHLY',
  Annually = 'ANNUALLY',
}

export enum PaymentStatus {
  FillingForm,
  UnexpectedError,
  Success,
  Cancellation,
}

export enum InvoicePeriod {
  CurrentMonth = 'CURRENT_MONTH',
  LastMonth = 'LAST_MONTH',
  Last6Months = 'LAST_6_MONTHS',
  Last12Months = 'LAST_12_MONTHS',
  AllInvoices = 'ALL_INVOICES',
}
