import React, { ReactNode, forwardRef, useState } from 'react';
import { CSSProperties } from 'styled-components';
import { PopoverPlacement } from '../../popover/types';
import { ITooltipProps, Tooltip } from '../../tooltip/Tooltip.component';

export type EllipsisPlacement = PopoverPlacement;

interface IEllipsisProps {
  children: any;
  noTooltip?: boolean;
  maxLines?: number;
  whiteSpace?: CSSProperties['whiteSpace'];
  textAlign?: CSSProperties['textAlign'];
  wordBreak?: 'normal' | 'break-all' | 'keep-all';
  overrideTooltip?: ReactNode;
  openDelayInMs?: ITooltipProps['openDelayInMs'];
  placement?: EllipsisPlacement;
}

const ellipsisDefaultStyle = {
  overflow: 'hidden',
  overflowWrap: 'break-word',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  display: 'block',
  textAlign: 'start',
};
const maxLinesStyle = numberOfLines => ({
  display: '-webkit-box',
  whiteSpace: 'initial',
  WebkitLineClamp: `${numberOfLines}`,
  WebkitBoxOrient: 'vertical',
});

const SCROLL_SIZE_THRESHOLD = 3;

export const Ellipsis = forwardRef<HTMLSpanElement, IEllipsisProps>(
  (
    { children, noTooltip, maxLines, wordBreak, overrideTooltip, openDelayInMs, textAlign, placement, whiteSpace },
    ref,
  ) => {
    const [tooltipText, setTooltipText] = useState('');

    const ellipsisStyle = {
      ...ellipsisDefaultStyle,
      ...(maxLines ? maxLinesStyle(maxLines) : {}),
      ...(wordBreak ? { wordBreak } : {}),
      ...(textAlign ? { textAlign } : {}),
      ...(whiteSpace ? { whiteSpace } : {}),
    };

    const showTooltip = ({ target }) => {
      if (
        target.scrollWidth > target.clientWidth + SCROLL_SIZE_THRESHOLD ||
        target.scrollHeight > target.clientHeight + SCROLL_SIZE_THRESHOLD
      ) {
        setTooltipText(target.textContent || target.value);
      }
    };

    const hideTooltip = () => {
      setTooltipText('');
    };

    const tooltipEvents = (!noTooltip && { onMouseEnter: showTooltip, onMouseLeave: hideTooltip }) || {};

    return (
      <Tooltip
        placement={placement}
        title={overrideTooltip || tooltipText}
        openDelayInMs={openDelayInMs}
        disableEvents={noTooltip}
      >
        <span ref={ref}>
          {React.Children.map(children, child => React.cloneElement(child, { style: ellipsisStyle, ...tooltipEvents }))}
        </span>
      </Tooltip>
    );
  },
);

Ellipsis.displayName = 'Ellipsis';
