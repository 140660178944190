import { Field, getIn } from 'formik';
import React from 'react';
import { Input } from '../../../Input';
import { IInputProps } from '../../../Input/Input.component';

export const InputFilter: React.FC<IInputProps & { shouldSubmitOnEnter?: boolean }> = ({
  name,
  subject,
  onEnterPress,
  shouldSubmitOnEnter = false,
  error,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <Input
            value={field.value ?? ''}
            onChange={e => {
              form.setFieldValue(field.name, e?.target.value);

              field.onChange?.(e?.target.value);
            }}
            onEnterPress={value => {
              if (!shouldSubmitOnEnter) return;

              form.setFieldValue(field.name, value);

              field.onChange?.(value);
              form.submitForm();
              onEnterPress?.(form.values);
            }}
            onBlur={() => form.setFieldTouched(field.name, true)}
            error={error ?? (getIn(form.touched, field.name) && getIn(form.errors, field.name))}
            subject={subject}
            {...props}
          />
        );
      }}
    </Field>
  );
};
