import React, { forwardRef } from 'react';
import {
  MaxWidthProps,
  grid,
  maxWidth,
  minWidth,
  WidthProps,
  GridProps,
  FlexboxProps,
  MinWidthProps,
  HeightProps,
  SpaceProps,
  MinHeightProps,
  OverflowProps,
} from 'styled-system';
import styled from 'styled-components';
import { Flex } from '../Flex/Flex.component';

interface IGridProps
  extends MaxWidthProps,
    MinWidthProps,
    WidthProps,
    GridProps,
    FlexboxProps,
    HeightProps,
    SpaceProps,
    OverflowProps,
    MinHeightProps {
  columns?: number; // TODO: Remove all explicit 12 columns
  rowGap?: number;
  cellGap?: number;
  subject?: string;
  children: React.ReactNode;
}

const StyledGrid = styled(Flex)`
  display: grid;
  ${grid}
  ${maxWidth}
  ${minWidth}
`;

// In case we want to go bigger - https://styled-css-grid.js.org/
export const Grid = forwardRef<HTMLDivElement, IGridProps>(
  ({ children, columns = 1, rowGap = 4, cellGap = 4, ...props }, ref) => (
    <StyledGrid
      gridRowGap={rowGap}
      gridColumnGap={cellGap}
      gridTemplateColumns={`repeat(${columns}, 1fr)`}
      context={'grid'}
      ref={ref}
      {...props}
    >
      {children}
    </StyledGrid>
  ),
);

Grid.displayName = 'Grid';
