import styled from 'styled-components';
import { TableCell } from './Cell.component';

const ActionsContainerStyled = styled(TableCell)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  word-break: initial;
`;

export const TableActionCell = ActionsContainerStyled;

TableActionCell.displayName = 'TableActionCell';
