export enum Regions {
  UsEast = 'us-east-1',
  Europe = 'eu-central-1',
  UnitedKingdom = 'eu-west-2',
  AsiaPacific = 'ap-southeast-2',
  Canada = 'ca-central-1',
  Japan = 'ap-northeast-1',
}

interface IRegion {
  name: string;
  code: string;
}

export const regions: Record<Regions, IRegion> = {
  [Regions.UsEast]: { name: 'US East (N. Virginia)', code: 'us' },
  [Regions.AsiaPacific]: { name: 'Asia Pacific (Sydney)', code: 'au' },
  [Regions.Canada]: { name: 'Canada (Central)', code: 'ca' },
  [Regions.Europe]: { name: 'Europe (Frankfurt)', code: 'eu' },
  [Regions.UnitedKingdom]: { name: 'Europe (London)', code: 'uk' },
  [Regions.Japan]: { name: 'Asia Pacific (Tokyo)', code: 'jp' },
};

export const getRegionCode = (region: string): string => {
  if (Object.keys(regions).find(currentRegion => region === currentRegion)) {
    return regions[region].code;
  }

  // Support future additions of new regions
  // DEV-25066 should send a log for unmapped regions
  return null;
};
