import { useEffect, useRef } from 'react';

export const usePopoverOnResize = ({ referenceElement, isOpen, update }) => {
  const prevReferenceElementHeight = useRef<number | undefined>(referenceElement?.clientHeight);

  useEffect(() => {
    if (!isOpen) return;

    if (!referenceElement) return;

    if (referenceElement && !prevReferenceElementHeight.current) {
      prevReferenceElementHeight.current = referenceElement.clientHeight;
    }

    const handleResize = () => {
      const currentHeight = referenceElement.clientHeight;

      if (isOpen && currentHeight !== prevReferenceElementHeight.current) {
        update();
        prevReferenceElementHeight.current = currentHeight;
      }
    };

    const resizeObserver = new ResizeObserver(() => requestAnimationFrame(handleResize));

    resizeObserver.observe(referenceElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [referenceElement, isOpen, update]);
};

export const usePopoverOnHover = ({
  trigger,
  referenceElement,
  popperElement,
  closePopover,
  openPopover,
  disabled,
  closeDelayInMs,
  openDelayInMs,
}) => {
  const hoverCloseTimeout = useRef(null);
  const hoverOpenTimeout = useRef(null);

  useEffect(() => {
    if (trigger !== 'hover' || disabled) return;

    const handleMouseLeave = () => {
      clearTimeout(hoverOpenTimeout.current);

      hoverCloseTimeout.current = setTimeout(closePopover, closeDelayInMs);
    };

    const handleMouseEnter = () => {
      clearTimeout(hoverCloseTimeout.current);

      hoverOpenTimeout.current = setTimeout(openPopover, openDelayInMs);
    };

    referenceElement?.addEventListener('mouseenter', handleMouseEnter);
    popperElement?.addEventListener('mouseenter', handleMouseEnter);
    referenceElement?.addEventListener('mouseleave', handleMouseLeave);
    popperElement?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      referenceElement?.removeEventListener('mouseenter', handleMouseEnter);
      popperElement?.removeEventListener('mouseenter', handleMouseEnter);
      referenceElement?.removeEventListener('mouseleave', handleMouseLeave);
      popperElement?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [popperElement, referenceElement, trigger, disabled]);
};

export const usePopoverOnClick = ({
  trigger,
  referenceElement,
  popperElement,
  closePopover,
  togglePopover,
  disabled,
}) => {
  // Register an onclick event to toggle the popover
  useEffect(() => {
    if (!referenceElement || trigger !== 'click' || disabled) return;

    referenceElement.addEventListener('click', togglePopover);

    return () => {
      referenceElement.removeEventListener('click', togglePopover);
    };
  }, [referenceElement, trigger, disabled, togglePopover]);

  // Register an event to close the popover when clicking outside of the popover
  useEffect(() => {
    if (!popperElement || !referenceElement || trigger !== 'click' || disabled) return;

    // listen for clicks and close dropdown on body
    document.addEventListener('mousedown', handleDocumentClick);
    // Handle clicks on the iframe as well.
    Array.from(document.getElementsByTagName('iframe')).forEach(node => {
      try {
        node.contentWindow.addEventListener('click', handleDocumentClick);
      } catch {
        /* not all iframes allow us to listen to their events */
      }
    });

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
      Array.from(document.getElementsByTagName('iframe')).forEach(node => {
        try {
          node.contentWindow.removeEventListener('click', handleDocumentClick);
        } catch {
          /* not all iframes allow us to listen to their events */
        }
      });
    };
  }, [popperElement, referenceElement, trigger, disabled]);

  const handleDocumentClick = event => {
    // Will not close if clicking inside the initiating component or the content component
    if (popperElement.contains(event.target) || referenceElement.contains(event.target)) {
      return;
    }

    closePopover();
  };
};
