export enum ElasticsearchSubjects {
  MultiFilter = 'elasticsearch-multi-filter',
}
export enum ElasticsearchFieldType {
  Number = 'number',
  Boolean = 'boolean',
  Date = 'date',
  IP = 'ip',
  Object = 'object',
  String = 'string',
  Lucene = 'lucene',
}
