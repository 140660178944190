import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import styled, { css, ThemeProps } from 'styled-components';
import { Group } from '../containers/Group/Group.component';
import { IInternalTabProps, IInternalTabsListProps, IStyledIndicatorProps, ITabHelperProps } from './interfaces';

export const StyledTabHelper = styled.span<ITabHelperProps>`
  ${({ theme: { tabs }, size }) => css`
    font-weight: ${tabs.helper.fontWeight[size]};
    margin-left: ${tabs.helper.marginLeft};
    font-size: ${size === 's' ? tabs.helper.fontSize.small : tabs.helper.fontSize.default};
  `}
  color: ${({ disabled, selected, theme: { tabs } }) => {
    if (disabled) return tabs.helper.color.disabled;

    if (selected) return tabs.helper.color.selected;

    return tabs.helper.color.default;
  }}
`;

export const StyledTabsList = styled.div.attrs<IInternalTabsListProps>(({ subject }) =>
  generateLogzTestAttributes({
    subject,
    context: 'tabs',
  }),
)<IInternalTabsListProps>`
  box-sizing: border-box;
  overflow: visible;
  position: relative;
  padding: ${({ tabOffset, vertical }) => (vertical ? `${tabOffset}px 0 ` : `0 ${tabOffset}px `)};
  ${({ theme: { tabs }, borderless, vertical }) => css`
    ${!borderless && `border-${vertical ? 'right' : 'bottom'}: ${tabs.tabList.borderBottom};`}
    ${vertical && 'display: inline-block'}
  `}
`;

export const StyledTab = styled.span<IInternalTabProps>`
  display: flex;
  align-items: baseline;

  ${({ theme: { tabs }, disabled, selected, size, noPadding }) => css`
    padding: ${noPadding ? 0 : tabs.tab.padding};
    margin-bottom: 0;
    font-size: ${tabs.tab.fontSize[size]};
    font-weight: ${tabs.tab.fontWeight[size]};
    line-height: ${tabs.tab.lineHeight[size]};

    ${tabs.tab.hover &&
    css`
      &:hover {
        ${!disabled &&
        !selected &&
        css`
          color: ${tabs.tab.hover.color};
        `};
      }
    `}
  `};
  color: ${({ disabled, selected, theme: { tabs } }) => {
    if (disabled) return tabs.tab.color.disabled;

    if (selected) return tabs.tab.color.selected;

    return tabs.tab.color.default;
  }};
`;

const verticalIndictorStyle = ({ theme: { tabs }, vertical, top, height }: IStyledIndicatorProps & ThemeProps<any>) =>
  vertical &&
  css`
    top: ${top}px;
    height: ${height}px;
    width: ${tabs.indicator.size}px;
    right: 0;
  `;

const horizontalIndictorStyle = ({
  theme: { tabs },
  vertical,
  borderless,
  width,
  left,
}: IStyledIndicatorProps & ThemeProps<any>) =>
  !vertical &&
  css`
    left: ${left}px;
    width: ${width}px;
    height: ${tabs.indicator.size}px;
    bottom: 0;
  `;

export const StyledIndicator = styled.span<IStyledIndicatorProps>`
  position: absolute;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${verticalIndictorStyle}
  ${horizontalIndictorStyle}
  ${({ theme: { tabs }, width, vertical, indicatorGradiant }) => css`
    background-color: ${tabs.indicator.backgroundColor};

    /* gradiant style for vertical indicator */
    ${vertical &&
    indicatorGradiant &&
    css`
      &:after {
        left: -${width}px;
        content: '';
        width: ${width}px;
        position: absolute;
        height: 100%;
        background-image: linear-gradient(
          to right,
          ${tabs.indicator.backgroundColor}00,
          ${tabs.indicator.backgroundColor}25
        );
      }
    `}
  `}
`;

export const StyledTabContainer = styled.div<{ disabled: boolean; width?: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  width: ${({ width }) => width || 'auto'};
  ${({ theme: { tabs }, disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    min-width: ${tabs.container.minWidth};
  `}
`;

export const StyledTabsGroup = styled(Group)`
  position: relative;
`;
