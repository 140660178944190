import React, { FC } from 'react';
import { Box } from '../containers/Container/Box.component';
import { Text } from '../typography';
import { useListContext } from './context';
import { Circle } from './Component/Circle';
import { OrderNumber } from './Component/OrderNumber';
import { mapSizeToFontSize } from './config';
import { Li, StyledIcon } from './ListItem.style';

export interface IListItemProps {
  children: React.ReactNode;
  index?: number;
}

export const ListItem: FC<IListItemProps> = ({ children, index }) => {
  const { variant, size } = useListContext();
  const fontSize = mapSizeToFontSize[size];

  const icon =
    variant === 'check' ? (
      <StyledIcon icon={'check-solid'} color={'royalBlue.600'} size={fontSize} />
    ) : (
      <Circle size={fontSize} />
    );

  return (
    <Li>
      {variant === 'order' ? <OrderNumber num={index + 1} /> : icon}
      <Box width={'fit-content'}>
        <Text color={variant === 'check' && 'gray.800'} size={fontSize}>
          {children}
        </Text>
      </Box>
    </Li>
  );
};
