// =======================================
// DON'T BE BARBARIC! MAINTAIN SORT ORDER!
// =======================================
export enum FeatureFlags {
  aiMetricsDashboard = 'ai-metrics-dashboard',
  aiHistory = 'ai-history',
  aiHistoryRca = 'ai-history-rca',
  aiAllAgent = 'ai-all-agent',
  aiAgentSearchPro = 'ai-agent-search-pro',
  aiAgentSearchSseConclusion = 'ai-agent-search-sse-conclusion',
  AIDynamicQuestions = 'ai-dynamic-questions',
  AICopilot = 'ai-copilot',
  AICopilotExploreLogTable = 'ai-copilot-explore-log-table',
  AICopilotExplore = 'ai-copilot-explore',
  AICopilotKube360 = 'ai-copilot-kube360',
  AICopilotKube360MainView = 'ai-copilot-kube360-main-view',
  AlertsRca = 'alerts-rca',
  AlertsSendToAll = 'alerts-send-to-all',
  AllowLeadingWildcard = 'allow-leading-wildcard',
  AskNicely = 'ask-nicely',
  AthenaArchiveSearch = 'athena-archive-search',
  BulkActionsInAlerts = 'bulk-actions-in-alerts',
  CentralizedDashboards = 'centralized-dashboards',
  CloudFetcher = 'cloud-fetcher',
  CloudFetcherAzureMonitorLogs = 'cloud-fetcher-azure-monitor-logs',
  ColdTierSearch = 'cold-tier-search',
  ColdTierV2 = 'cold-tier-v2',
  ConsumptionPlanAndBilling = 'consumption-plan-and-billing',
  Coupons = 'coupons',
  CorrelatedEventsUi = 'correlated-events-ui',
  EventManagementRCA = 'event-management-rca',
  ConsumptionBilling = 'consumption-billing',
  DeprecatedTriggeredAlert = 'hide-deprecated-triggered-alert',
  DeploymentEvents = 'deployment-events',
  disableWildCardQueryV2 = 'disable-wildcard-query-v2',
  DiscoverToExploreBanner = 'discover-to-explore-banner',
  DiyDataParsing = 'diy-data-parsing',
  editQueryDsl = 'edit-query-dsl',
  EventCorrelation = 'event-correlation',
  exceptionsShowDeployments = 'exceptions-show-deployments',
  experimentalApiGateway = 'experimental-api-gateway',
  ExploreAlerts = 'explore-alerts',
  ExploreAsDefault = 'explore-as-default',
  ExploreGroupBy = 'explore-group-by',
  ExplorePreferencesPage = 'explore-preferences-page',
  ExploreQuickVisualizations = 'explore-quick-visualizations',
  ExploreExpandedTraceView = 'explore-expanded-trace-view',
  ExploreSavedObjects = 'explore-saved-objects',
  ExploreSurroundingLogs = 'explore-surrounding-logs',
  grafanaNext = 'grafana-next',
  grafanaX = 'grafana-x',
  GroupByDropFilter = 'group-by-drop-filter',
  GuidedFlowListenToData = 'guided-flow-listen-for-data',
  GuidedWalkthrough = 'guided-walkthrough',
  HighlightedLogs = 'highlighted-logs',
  HighlightQueryContainsQuery = 'highlight-query-contains-query',
  HomeDashboardAiEnabled = 'home-dashboard-ai-enabled',
  insightsV2Milestone1 = 'insights-v2-milestone-1',
  IgnoreMetricsDropFiltersLimit = 'ignore-metrics-drop-filters-limit',
  IntegrationHubCustomPages = 'integration-hub-custom-pages',
  IntegrationsHubPlayground = 'integrations-hub-playground',
  IntegrationsPage = 'integrations-page',
  IntegrationsPageGuidanceChat = 'integrations-hub-guidance-chat',
  IntegrationsPageGuidanceExperiment = 'integrations-hub-guidance-experiment',
  InvestigateTriggeredAlerts = 'investigate-triggered-alerts',
  kibanaAdvisor = 'kibana-advisor',
  Kube360Livetail = 'kube360-livetail',
  logPatternsStorageSizeUi = 'log-patterns-storage-size-ui',
  logPatternsUi = 'log-patterns-ui',
  LogsEventManagment = 'logs-event-managment',
  LogsToMetrics = 'logs-to-metrics',
  LogsToMetricsHistogram = 'logs-to-metrics-histogram-buckets',
  M3dbMetricsAccount = 'm3db-metrics-account',
  ManageAccountsRedesign = 'manage-accounts-redesign',
  ManageConsumptionAccounts = 'manage-consumption-accounts',
  metricsProduct = 'metrics-product',
  MetricsRollups = 'metrics-rollups',
  NewDiscover = 'new-discover',
  NewOptimizersPage = 'new-optimizers-page',
  ObservabilityAIAgent = 'observability-ai-agent',
  ObservabilityHomeDashboard = 'observability-home-dashboard',
  Pendo = 'pendo',
  PercentileAlerts = 'percentile-alerts',
  PlanAndBillingMetricsUi = 'plan-and-billing-metrics-ui',
  Playground = 'playground',
  RealUserMonitoring = 'real-user-monitoring',
  SendYourDataQuickSetup = 'send-your-data-quick-setup',
  ServiceListMap = 'service-list-map',
  ServiceMapFromMetrics = 'service-map-from-metrics',
  SiemEventManagement = 'siem-event-management',
  SiemRole = 'siem-role',
  SigmaRuleConvertor = 'sigma-rule-convertor',
  styleguide = 'styleguide',
  SubAccountsSsoGroups = 'sub-accounts-sso-groups',
  TelemetryAgent = 'telemetry-agent',
  TelemetryAgentEdit = 'telemetry-agent-edit',
  TelemetryAgentFleet = 'telemetry-agent-fleet',
  TextToLucene = 'text-to-lucene',
  UpToSubAccountBackend = 'flexible-volume-management-allowed',
  UpToSubAccountUI = 'upto-sub-account',
  WelcomePageBar = 'welcome-page-bar',
  WarmTier = 'warm-tier',
  ExploreLuceneEditor = 'explore-lucene-editor',
  UnifiedDashboards = 'unified-dashboards',
  SPMCustomFilters = 'spm-custom-filters',
}

// =======================================
// DON'T BE BARBARIC! MAINTAIN SORT ORDER!
// =======================================
