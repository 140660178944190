import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Group } from '../containers/Group/Group.component';
import { darkModeService } from '../themes/dark-mode.service';
import { StyledIndicator, StyledTabsGroup, StyledTabsList } from './Tabs.styles';
import { IStyledIndicatorProps, ITabsProps } from './interfaces';

export const Tabs: React.FunctionComponent<ITabsProps> = ({
  size = 's',
  selectedTab,
  children: childrenProp,
  onChange,
  borderless,
  subject,
  vertical = false,
  showIndicator = true,
  indicatorGradiant = false,
  tabOffset,
  fullWidth,
}) => {
  const [{ top, left, width, height }, setIndicatorProps] = useState({ height: 0, width: 0, top: 0, left: 0 });
  const tabListRef = useRef(null);
  const selectedTabRef = useRef(null);

  const calculateIndicatorProps = (): IStyledIndicatorProps => {
    let selectedTabMetadata, tabsMetadata;

    if (tabListRef) {
      const containerRect = tabListRef.current.getBoundingClientRect();

      tabsMetadata = {
        left: containerRect.left,
        top: containerRect.top,
      };

      selectedTabMetadata = selectedTabRef.current && selectedTabRef.current.getBoundingClientRect();
    }

    let calculatedLeft = 0;
    let calculateTop = 0;

    if (selectedTabMetadata && tabsMetadata) {
      calculatedLeft = Math.round(selectedTabMetadata.left - tabsMetadata.left);
      calculateTop = Math.round(selectedTabMetadata.top - tabsMetadata.top);
    }

    return {
      top: calculateTop,
      left: calculatedLeft,
      height: selectedTabMetadata ? Math.round(selectedTabMetadata.height) : 0,
      width: selectedTabMetadata ? Math.round(selectedTabMetadata.width) : 0,
    };
  };

  const handleHelperChange = () => setIndicatorProps(calculateIndicatorProps());

  const children = React.Children.map(childrenProp, (tab, index) => {
    if (!React.isValidElement(tab)) return null;

    const tabCount = React.Children.count(childrenProp);
    const tabValue = isNil(tab.props.value) ? index : tab.props.value;

    const selected = selectedTab === tabValue;

    const childRef = selected ? { ref: selectedTabRef } : {};

    return React.cloneElement(tab, {
      size,
      selected,
      width: fullWidth ? `${100 / tabCount}%` : undefined,
      onClick: onChange,
      value: tabValue,
      onHelperChange: handleHelperChange,
      ...childRef,
    } as any);
  });

  useEffect(() => {
    setIndicatorProps(calculateIndicatorProps());
  }, [selectedTab]);

  return (
    <StyledTabsGroup
      className={darkModeService.manipulatorClass.ignore}
      gap={0}
      vertical={!vertical}
      fullWidth={!vertical}
      fullHeight={vertical}
      {...(vertical ? { overflow: 'scroll', alignItems: 'flex-start' } : {})}
    >
      <StyledTabsList
        tabOffset={tabOffset}
        ref={tabListRef}
        borderless={borderless}
        subject={subject}
        vertical={vertical}
      >
        <Group width={'100%'} gap={indicatorGradiant ? 0 : 2} vertical={vertical}>
          {children}
        </Group>
      </StyledTabsList>
      {showIndicator && <StyledIndicator {...{ top, left, width, height, borderless, vertical, indicatorGradiant }} />}
    </StyledTabsGroup>
  );
};

Tabs.displayName = 'Tabs';
