import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const debounceDuration = 200;

export const useScrollPosition = (containerElement, numberOfChildren) => {
  const [position, setPosition] = useState<'in-between' | 'end' | 'start'>('start');

  const handleScroll = useCallback(() => {
    if (containerElement.current.scrollLeft === 0) {
      setPosition('start');
    } else if (
      containerElement.current.scrollLeft ===
      containerElement.current.scrollWidth - containerElement.current.clientWidth
    ) {
      setPosition('end');
    } else {
      setPosition('in-between');
    }
  }, []);

  useEffect(() => {
    if (containerElement) {
      const debouncedHandleScroll = debounce(handleScroll, debounceDuration);

      containerElement.current?.addEventListener('scroll', debouncedHandleScroll);
    }

    return () => containerElement?.current?.removeEventListener('scroll', handleScroll);
  }, [containerElement]);

  const back = () => {
    containerElement.current.scrollBy({
      left: -(containerElement.current.scrollWidth - containerElement.current.clientWidth) / numberOfChildren,
      behavior: 'smooth',
    });
  };

  const next = () => {
    containerElement.current.scrollBy({
      left: (containerElement.current.scrollWidth - containerElement.current.clientWidth) / numberOfChildren,
      behavior: 'smooth',
    });
  };

  return { position, next, back };
};
