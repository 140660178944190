import React, { forwardRef, ReactNode } from 'react';
import styled from 'styled-components';
import { StyledSelect } from '../../../SearchFilters/Filters/Select/select-styles';
import { DropdownIndicator } from '../../../SearchFilters/Filters/Select/DropdownIndicator';
import { Popover } from '../../../popover/Popover.component';
import { ISelectProps } from '../Select.types';
import { Box } from '../../../containers/Container/Box.component';
import { Icon } from '../../../icons/Icon.component';
import { Group } from '../../../containers/Group/Group.component';
import { Text } from '../../../typography/Text/Text.component';
import { PopoverError } from '../../../popover/PopoverError.component';

interface IPopoveredSelect extends ISelectProps {
  buttonLabel: string | ((selectedOption) => ReactNode);
  open?: boolean;
}

const StyledDropdownButtonContainer = styled(Box)<{ variant: ISelectProps['variant'] }>`
  background-color: ${({ theme, variant }) => theme.select.variants[variant].popoveredSelect.button.backgroundColor};
  border: 1px solid ${({ theme, variant }) => theme.select.variants[variant].popoveredSelect.button.borderColor};
  border-radius: 3px;
  width: 300px;
  transition: border-color 0.1s ease-in-out;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme, variant }) => theme.select.variants[variant].popoveredSelect.button.hover.borderColor};
  }
`;

interface IDropDownButton {
  label: ReactNode;
  subject?: string;
  variant: 'primary' | 'secondary';
  error?: ISelectProps['error'];
  errorPlacement?: ISelectProps['errorPlacement'];
}

const DropdownButton = forwardRef<HTMLDivElement, IDropDownButton>(
  ({ label, variant, subject, error, errorPlacement }, ref) => {
    return (
      <PopoverError error={error} placement={errorPlacement}>
        <StyledDropdownButtonContainer ref={ref} variant={variant} px={2} py={1} subject={subject}>
          <Group alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            {label}
            <Icon color={'gray.800'} icon={'angle-down-solid'} />
          </Group>
        </StyledDropdownButtonContainer>
      </PopoverError>
    );
  },
);

interface IComponentProps extends IPopoveredSelect {
  closePopover: () => {};
}

const Component: React.FC<IComponentProps> = ({ closePopover, onChange, variant, subject, props }) => {
  const handleChange = (value, action) => {
    if (onChange) onChange(value, action);

    closePopover();
  };

  return (
    <StyledSelect
      size={'medium'}
      menuIsOpen
      autoFocus
      components={{ DropdownIndicator }}
      onChange={handleChange}
      controlShouldRenderValue={false}
      subject={subject}
      {...{ variant, ...props }}
    />
  );
};

export const PopoveredSelect: React.FC<IPopoveredSelect> = ({
  buttonLabel,
  onChange,
  open,
  variant = 'primary',
  subject,
  error = null,
  errorPlacement = 'right',
  ...props
}) => {
  const dropDownButtonLabel = typeof buttonLabel === 'string' ? <Text>{buttonLabel}</Text> : buttonLabel(props.value);

  return (
    <Popover
      Content={{ Component, props: { onChange, variant, subject, props } }}
      placement="bottom-start"
      wrapChildWithRef
      open={open}
    >
      <DropdownButton
        label={dropDownButtonLabel}
        variant={variant}
        subject={subject}
        error={error}
        errorPlacement={errorPlacement}
      />
    </Popover>
  );
};
