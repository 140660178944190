import React from 'react';
import { Field } from 'formik';
import { SelectSizes } from '../../../inputs/Select/select-sizes.enum';
import { Option } from '../../types';
import { IFilterField } from '../../interfaces';
import { FilterValue } from '../FilterValue/FilterValue';
import { MultiSelectFilterLabel } from './MultiSelectFilterLabel';
import { MultiSelect } from './MultiSelect';

export interface IMultiSelectFilter extends IFilterField {
  label: string;
  options: Option[];
  size?: SelectSizes;
  open?: boolean;
  onSave?: (value: string[]) => void;
  loadOptions?: (searchTerm: string) => Option[];
  fullWidth?: boolean;
  minimumSelectedOptions?: number;
  disabled?: boolean;
  attachToBody?: boolean;
  loading?: boolean;
}

export const MultiSelectFilter: React.FC<IMultiSelectFilter> = ({
  label,
  maxLabelWidth,
  open,
  onSave,
  attachToBody,
  fullWidth,
  minimumSelectedOptions,
  disabled,
  loading,
  minLabelWidth,
  ...props
}) => {
  const getTooltip = selectValue =>
    selectValue?.map(value => props.options?.find(option => option.value === value)?.label)?.join(', ');

  return (
    <Field name={props.name}>
      {({ form, field }) => {
        return (
          <FilterValue
            attachToBody={attachToBody}
            disabled={disabled}
            field={field.name}
            maxLabelWidth={maxLabelWidth}
            minLabelWidth={minLabelWidth}
            label={<MultiSelectFilterLabel label={label} value={field.value} tooltip={getTooltip(field.value)} />}
            isActive={field.value}
            open={open}
            loading={loading}
            ContentComponent={{
              Component: MultiSelect,
              props: {
                field,
                form,
                onSave,
                minimumSelectedOptions,
                ...props,
              } as Omit<React.ComponentProps<typeof MultiSelect>, 'closePopover'>,
            }}
            fullWidth={fullWidth}
          />
        );
      }}
    </Field>
  );
};
