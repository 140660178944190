import React from 'react';
import styled from 'styled-components';
import { StyledMultiTag } from '../types';
import { Step } from './Step';
import { useMultiTag } from './TagContainer';

const TagContentWrapper = styled.div<StyledMultiTag>`
  display: flex;
  gap: 5px;
  padding: 0 5px;
  position: relative;
  overflow: hidden;

  span {
    flex-shrink: 0;
  }

  ::after {
    ${({ state, editMode }) => state === 'disabled' && !editMode && `content: ''`};
    position: absolute;
    background-color: ${({ theme }) => theme.multiTag.strikeThroughColor};
    height: 1px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
`;

export const StepList: React.FC = () => {
  const { state, editMode, tag } = useMultiTag();

  return (
    <TagContentWrapper {...{ state, editMode }}>
      {tag.steps.map((step, index) => (
        <Step key={`${step.value}${index}`} {...{ step, index }} />
      ))}
    </TagContentWrapper>
  );
};
