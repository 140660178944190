import React from 'react';
import { Icon } from '../../../../../icons/Icon.component';
import { Text } from '../../../../../typography';
import { IRowAction } from '../../../types';
import { ButtonContainer } from './Actions.styles';

interface IActionButtonProps<T> {
  action: IRowAction<T>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, action: IRowAction<T>) => void;
}

export const ActionButton = React.memo(
  React.forwardRef<HTMLButtonElement, IActionButtonProps<any>>(({ action, onClick }, ref) => (
    <ButtonContainer
      ref={ref}
      background={action.background ?? 'transparent'}
      icon={<Icon icon={action.icon} size={14} color={action.color ?? 'blue.500'} />}
      subject={action.label}
      onClick={event => onClick(event, action)}
      size="xs"
      variant="tertiary"
      px={3}
    >
      {!action.showTooltip && <Text size={10}>{action.label}</Text>}
    </ButtonContainer>
  )),
);

ActionButton.displayName = 'ActionButton';
