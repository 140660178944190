export enum RootDashboardFolder {
  NAME = 'ROOT_FOLDER',
  DISPLAY_NAME = 'Root Folder',
}

export enum DashboardsHubPageSubjects {
  Page = 'dashboards-hub-page',
  Table = 'dashboards-hub-table',
  TableEmptyState = 'dashboards-hub-table-empty-state',
  CreateNewDashboardButton = 'dashboards-hub-create-new-dashboard-button',
  CreateNewDashboardModal = 'dashboards-hub-create-new-dashboard-modal',
  DeleteDashboardButton = 'dashboards-hub-delete-dashboard-button',
}

export enum ViewUnifiedDashboardPageSubjects {
  Page = 'view-unified-dashboard-page',
}
