import { SelectSizes } from '../../inputs/Select/select-sizes.enum';

export const sizes = {
  select: {
    [SelectSizes.Icon]: '90px',
    [SelectSizes.Tiny]: '120px',
    [SelectSizes.Small]: '200px',
    [SelectSizes.Medium]: '300px',
    [SelectSizes.Large]: '400px',
  },
  text: {
    mini: '11px',
    tiny: '12px',
    small: '13px',
    medium: '14px',
    large: '15px',
    big: '16px',
    huge: '18px',
    heading: '23px',
  },
  fontWeight: {
    light: 300,
    normal: 400,
    bold: 500,
  },
  icon: {
    tiny: '10px',
    small: '14px',
    medium: '16px',
    large: '18px',
    big: '24px',
    huge: '72px',
  },
  borderRadius: {
    none: '0px',
    sm: '2px',
    md: '4px',
    lg: '8px',
    xl: '12px',
    xxl: '16px',
    circle: '50%',
  },
};

export type BorderRadiusKeys = keyof typeof sizes.borderRadius;
export const borderRadiusOptions: BorderRadiusKeys[] = Object.keys(sizes.borderRadius) as BorderRadiusKeys[];
