import { PropsWithChildren, useContext, useState, createContext } from 'react';

type popoverState = {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
};

const PopoverStateContext = createContext<popoverState>({
  isOpen: false,
  setIsOpen: val => {},
});

export const PopoverStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isOpen, setIsOpen] = useState(false);

  return <PopoverStateContext.Provider value={{ isOpen, setIsOpen }}>{children}</PopoverStateContext.Provider>;
};

export const usePopoverState = () => {
  const context = useContext<popoverState>(PopoverStateContext);

  return context;
};
