import { isNil } from 'lodash';
import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import { useDebounce } from 'react-debounce-hook';
import ReactSelect, { components as reactSelectComponents, createFilter } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import styled, { ThemeContext } from 'styled-components';
import { Flex } from '../../containers/Flex/Flex.component';
import { PopoverError } from '../../popover/PopoverError.component';
import { Text } from '../../typography';
import { Tooltip } from '../../tooltip/Tooltip.component';
import { Box } from '../../containers/Container/Box.component';
import { SelectClearIndicator } from './components/ClearIndicator.component';
import { CreatableMenuList } from './components/CreatableMenuList.component';
import { SelectDropdownIndicator } from './components/DropdownIndicator.component';
import { SelectGroupHeading } from './components/GroupHeading.component';
import { SelectLoadingIndicator } from './components/LoadingIndicator.component';
import { SelectMenu } from './components/Menu.component';
import { MultiValueRemove } from './components/MultiValue.component';
import { classNamePrefix, reactSelectCssOverrides } from './Select.styles';
import { ISelectProps } from './Select.types';

const customComponents: Partial<typeof reactSelectComponents> = {
  Menu: SelectMenu,
  LoadingIndicator: SelectLoadingIndicator,
  ClearIndicator: SelectClearIndicator,
  GroupHeading: SelectGroupHeading,
  IndicatorSeparator: null,
  MultiValueRemove,
  DropdownIndicator: SelectDropdownIndicator,
};

const useSelectDebounce = loadOptions => {
  const [callback, setCallback] = useState(null);
  const [searchTerm, setSearchTerm] = useState();
  const handleSearch = async term => callback?.(await loadOptions(term));

  useDebounce(searchTerm, handleSearch);

  return { setCallback, setSearchTerm };
};

const handleIsValidNewOption = (inputValue, selectValue, selectOptions) => {
  const exactValueExists = selectOptions.find(el => el.value === inputValue);
  const valueIsNotEmpty = inputValue.trim().length;

  return !exactValueExists && valueIsNotEmpty;
};

/**
 * Decide on whether to get <Select />/<CreatableSelect />/<AsyncSelect />/<AsyncCreatableSelect />
 */
const getSelect = (props): JSX.Element => {
  props.ref = props.innerRef;

  if (props.loadOptions) {
    if (props.creatable) {
      return <AsyncCreatableSelect {...props} cacheOptions />;
    }

    return <AsyncSelect {...props} cacheOptions />;
  }

  if (props.creatable) {
    return <CreatableSelect {...props} />;
  }

  return <ReactSelect {...props} />;
};

// We need this in order to put the data-logz-test-context on this.
const SelectContainer = styled(Flex)<ISelectProps>`
  flex-direction: column;
  ${({ theme, isMulti, size }) => {
    if (isNil(size)) return { flex: 1 };

    if (isMulti) return { minWidth: theme.sizes.select[size] };

    return { width: theme.sizes.select[size] };
  }}
  ${reactSelectCssOverrides};
`;

const createOption =
  OptionElement =>
  ({ data, ...props }: React.ComponentProps<typeof reactSelectComponents.Option>) =>
    (
      <reactSelectComponents.Option {...props} data={data}>
        <OptionElement value={data.value} label={data.label} />
      </reactSelectComponents.Option>
    );

const createSingleValue =
  SingleValueElement =>
  ({ data, ...props }: React.ComponentProps<typeof reactSelectComponents.SingleValue>) =>
    (
      <reactSelectComponents.SingleValue {...props} data={data}>
        <SingleValueElement value={data.value} label={data.label} />
      </reactSelectComponents.SingleValue>
    );

const formatCreateLabel = inputValue => (
  <Text size={12} weight={400}>
    {inputValue}
  </Text>
);

export const OptionWithTooltip = ({ data, ...props }: React.ComponentProps<typeof reactSelectComponents.Option>) => {
  const tooltipProps = data.isDisabled && data.disabledTooltip ? data.disabledTooltip : { title: null };

  return (
    <Tooltip {...tooltipProps}>
      <Box>
        <reactSelectComponents.Option {...props} data={data} />
      </Box>
    </Tooltip>
  );
};

export const Select: FunctionComponent<ISelectProps> = ({
  loadOptions,
  children,
  OptionElement,
  SingleValueElement,
  error,
  errorPlacement,
  context = 'select',
  subject,
  components = {},
  defaultOptions = true,
  isClearable,
  autoFocus = false,
  creatable = false,
  searchable = true,
  isLoading = false,
  disabled = false,
  isMulti = false,
  attachToBody,
  size,
  value,
  onChange,
  name,
  helper,
  errorOverflow,
  variant = 'primary',
  borderless,
  noUnderline,
  caseSensitive = false,
  menuWidth,
  menuPositionOverride,
  maxLines,
  ...props
}) => {
  const { setSearchTerm, setCallback } = useSelectDebounce(loadOptions);
  // https://github.com/JedWatson/react-select/issues/614#issuecomment-244006496
  const handleLoadOptions = (term, callback) => {
    setCallback(() => callback);
    setSearchTerm(term);
  };
  const theme = useContext(ThemeContext);

  // https://react-select.com/components
  // Customise the options displayed in the select
  customComponents.Option = OptionElement ? createOption(OptionElement) : OptionWithTooltip;
  // Customise the selected value for uni-selection mode
  customComponents.SingleValue = SingleValueElement
    ? createSingleValue(SingleValueElement)
    : reactSelectComponents.SingleValue;

  if (creatable) customComponents.MenuList = CreatableMenuList();

  const handleChange = (value, action) => {
    if (isMulti) {
      isNil(value) ? onChange([], action) : onChange(value, action);

      return;
    }

    onChange(value, action);
  };
  const menuStyle = useMemo(() => {
    if (menuWidth || menuPositionOverride) {
      return {
        menu: base => ({
          ...base,
          ...(menuWidth ? { width: menuWidth } : {}),
          ...(menuPositionOverride ? menuPositionOverride : {}),
        }),
      };
    }

    return null;
  }, [menuWidth, menuPositionOverride]);

  return (
    <PopoverError error={error} placement={errorPlacement} overflow={errorOverflow}>
      <SelectContainer
        isMulti={isMulti}
        size={size}
        context={context}
        subject={subject}
        name={name}
        variant={variant}
        noUnderline={noUnderline}
        borderless={borderless}
        error={error}
        disabled={disabled}
        maxLines={maxLines}
      >
        {getSelect({
          ...props,
          onChange: handleChange,
          styles: {
            ...menuStyle,
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          },
          menuPlacement: 'auto',
          isDisabled: disabled || props.isDisabled,
          backspaceRemovesValue: isMulti,
          classNamePrefix,
          theme,
          variant,
          // loadOptions is probably checking that we pass something other than undefined to determine async,
          // So we pass the handler only if we have the `handleLoadOptions`
          loadOptions: loadOptions && handleLoadOptions,
          defaultOptions,
          isClearable: isNil(isClearable) ? isMulti : isClearable,
          creatable,
          autoFocus,
          formatCreateLabel,
          isSearchable: searchable,
          isLoading,
          isMulti,
          children,
          value,
          menuPortalTarget: attachToBody && document.body,
          components: { ...customComponents, ...components },
          selectProps: { variant },
          ...(caseSensitive
            ? {
                filterOption: createFilter({ ignoreCase: false }),
                isValidNewOption: handleIsValidNewOption,
              }
            : {}),
        })}
      </SelectContainer>
    </PopoverError>
  );
};

Select.displayName = 'Select';
