import styled from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { height } from 'styled-system';

export interface IFrameProps {
  subject?: string;
}

export const IFrame = styled.iframe.attrs<IFrameProps>(({ subject = 'iframe' }) => ({
  ...generateLogzTestAttributes({ context: 'iframe', subject }),
}))<IFrameProps>`
  width: 100%;
  height: ${({ height }) => height ?? '100%'};
  border: 0;
  overflow: auto;
  ${height}
`;
