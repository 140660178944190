import { useEffect, useMemo } from 'react';

export const useAnchorElement = (position: { x: number; y: number }) => {
  const referanceElement = useMemo(() => {
    const element = document.createElement('div');

    element.id = 'action-item-popover-referance';

    element.style.position = 'absolute';
    element.style.left = `${position?.x ?? 0}px`;
    element.style.top = `${position?.y ?? 0}px`;
    element.style.zIndex = '9999';

    return element;
  }, [position]);

  useEffect(() => {
    document.body.appendChild(referanceElement);

    return () => {
      document.body.removeChild(referanceElement);
    };
  }, [referanceElement]);

  return referanceElement;
};
