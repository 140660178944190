import { useHotkeys } from 'react-hotkeys-hook';

export const useHotkeysNavigation = (handleApply: () => void, closePopover: () => void) => {
  useHotkeys(
    ['esc'],
    (event: KeyboardEvent) => {
      event.preventDefault();

      closePopover();
    },
    { enableOnFormTags: true },
    [],
  );

  useHotkeys('enter', handleApply, { enableOnFormTags: true }, [handleApply]);
};
