import { colors } from '../../../themes/colors';

export const selectLightTheme = {
  variants: {
    primary: {
      valueContainer: {
        textColor: colors.royalBlue[1000],
      },
      placeholder: {
        textColor: colors.gray[700],
      },
      focused: {
        borderColor: colors.blue[300],
        shadowColor: colors.blue[300],
        shadowSpread: 10,
      },
      hover: {
        borderColor: colors.blue[200],
      },
      menu: {
        textColor: colors.royalBlue[1000],
        borderColor: colors.gray[300],
        backgroundColor: 'white',
        focusedOption: {
          backgroundColor: colors.gray[300],
        },
        selectedOption: {
          backgroundColor: colors.blue[200],
        },
        group: {
          borderColor: colors.gray[300],
          textColor: colors.royalBlue[1000],
        },
        empty: {
          textColor: colors.gray[700],
        },
        disabledOption: {
          textColor: colors.gray[500],
        },
      },
      indicator: {
        iconColor: colors.gray[600],
        hover: {
          iconColor: colors.gray[800],
        },
        clear: {
          borderColor: colors.gray[500],
        },
      },
      multiValue: {
        container: {
          borderColor: colors.gray[400],
          backgroundColor: colors.gray[300],
        },
      },
    },
    secondary: {
      valueContainer: {
        textColor: colors.royalBlue[1000],
      },
      placeholder: {
        textColor: colors.gray[700],
      },
      focused: {
        borderColor: colors.blue[300],
        shadowColor: colors.blue[200],
        shadowSpread: 10,
      },
      hover: {
        borderColor: colors.blue[200],
      },
      menu: {
        textColor: colors.royalBlue[1000],
        borderColor: colors.gray[300],
        backgroundColor: 'white',
        focusedOption: {
          backgroundColor: colors.gray[300],
        },
        selectedOption: {
          backgroundColor: colors.blue[200],
        },
        group: {
          borderColor: colors.gray[300],
          textColor: colors.royalBlue[1000],
        },
        empty: {
          textColor: colors.gray[700],
        },
        disabledOption: {
          textColor: colors.gray[500],
        },
      },
      indicator: {
        iconColor: colors.gray[600],
        hover: {
          iconColor: colors.gray[800],
        },
        clear: {
          borderColor: colors.gray[500],
        },
      },
      multiValue: {
        container: {
          borderColor: colors.gray[400],
          backgroundColor: colors.gray[300],
        },
      },
    },
  },
};
