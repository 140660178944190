import { bluePalette, grayPalette, greenPalette, redPalette, yellowPalette } from '../../themes/colors';

export const highlightLightTheme = {
  variants: {
    success: {
      backgroundColor: greenPalette[200],
      iconColor: greenPalette[500],
    },
    warning: {
      backgroundColor: yellowPalette[300],
      iconColor: yellowPalette[800],
    },
    danger: {
      backgroundColor: redPalette[200],
      iconColor: redPalette[600],
    },
    announcement: {
      backgroundColor: bluePalette[200],
      iconColor: bluePalette[800],
    },
    info: {
      backgroundColor: grayPalette[300],
      iconColor: grayPalette[800],
    },
  },
};
