export enum ManageAccountsSubjects {
  SaveAccount = 'save-changes',
  EnableFlexibility = 'activate-flexibility',
  DisableFlexibility = 'deactivate-flexibility',
  DeleteSubAccount = 'delete',
  ManageAccountsPage = 'manage-accounts-page',
  ManageConsumptionAccountPage = 'manage-consumption-accounts-page',
  LogsProductCard = 'logs-product-card',
  MetricsProductCard = 'metrics-product-card',
  TracingProductCard = 'tracing-product-card',
  SecurityProductCard = 'security-product-card',
  TimelessProductCard = 'timeless-product-card',
}

export enum ManageAccountsCardNames {
  LogsList = 'manage-accounts-accounts-card',
  LogsEdit = 'manage-accounts-edit-account-section',
  SiemList = 'manage-accounts-security-accounts-card',
  SiemEdit = 'manage-accounts-edit-security-account-section',
  TimelessList = 'manage-accounts-timeless-accounts-card',
  TracingList = 'manage-accounts-tracing-accounts-card',
  MetricsList = 'manage-accounts-metrics-accounts-card',
}
export enum SecurityRepositoryTestSubject {
  CantDeleteDialog = 'cannot-delete-repository-dialog',
  ChangeRepositoryNotification = 'change-repository-notification',
  ResetLink = 'reset-repository-account',
  InfobarSubject = 'siem-repo-infobar',
  ModalSelectRepository = 'list-repository-accounts',
  ModalSubject = 'manage-repository',
  PopupNotificationSubject = 'siem-repo-notification',
}
