import { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { useBreakpoint } from '@logz-pkg/react-hooks';
import { BaseButton } from '../buttons/BaseButton/BaseButton.component';
import { StyledButtonIcon } from '../buttons/BaseButton/BaseButton.styles';
import headerButtons from '../themes/base/components/HeaderButton.variants';
import { IsHoveredContext } from './HeaderDropdown.component';

const propTypes = {
  ...BaseButton.propTypes,
  label: PropTypes.string,
  iconSize: PropTypes.string,
};

const defaultProps = {
  ...BaseButton.defaultProps,
  ...{ p: 3, m: 0 },
  iconSize: 'large',
};

const HeaderButtonStyled = styled(BaseButton)`
  align-self: stretch;
  align-items: center;
  font-size: 14px;
  ${({ label }) => label && 'position: relative;'};

  font-family: ${({ theme }) => theme.fonts.base};
  ${({ theme, active }) => variant(headerButtons(theme, active))}

  ${StyledButtonIcon} {
    font-size: ${({ theme, iconSize }) => theme.sizes.icon[iconSize] || iconSize};
    margin-right: ${({ text, children }) => (text || children ? '0.3em' : '0')};
  }
`;

const HeaderButtonLabel = styled.div`
  position: absolute;
  top: 37px;
  left: 41px;
  font-size: ${({ theme }) => theme.sizes.text.tiny};
  color: ${({ theme }) => theme.colors.actions.accent};
  text-align: left;
`;

/**
 * @deprecated
 */
export const HeaderButton = forwardRef((props, ref) => {
  const breakpoints = useBreakpoint();
  const isHovered = useContext(IsHoveredContext);

  return (
    <HeaderButtonStyled {...props} ref={ref}>
      {(isHovered || !breakpoints.sm) && (
        <>
          {props.children}
          {props.label && <HeaderButtonLabel>{props.label}</HeaderButtonLabel>}
        </>
      )}
    </HeaderButtonStyled>
  );
});

HeaderButton.displayName = 'Header Button';
HeaderButton.propTypes = propTypes;
HeaderButton.defaultProps = defaultProps;
