import { HeightProps, WidthProps, OpacityProps, width, height, opacity } from 'styled-system';
import styled, { css } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import React from 'react';

type SpecificSkeletonProps = {
  round?: boolean;
  subject?: string;
};

export type SkeletonProps = SpecificSkeletonProps & WidthProps & HeightProps & OpacityProps;

const StyledSkeleton = styled.div<SkeletonProps>`
  ${width}
  ${height}
  ${opacity}
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  transition: opacity 300ms ease;
  background-size: 200% 100%;
  border-radius: 5px;
  ${({ round }) =>
    round
      ? css`
          border-radius: 50%;
        `
      : null}

  animation: 1s shine linear infinite;
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;

export const Skeleton: React.FC<SkeletonProps> = ({ round, subject, opacity = 1, ...props }) => {
  return (
    <StyledSkeleton
      round={round}
      opacity={opacity}
      {...generateLogzTestAttributes({ context: 'skeleton', subject })}
      {...props}
    />
  );
};
