import React from 'react';
import styled, { css } from 'styled-components';
import { position, PositionProps, opacity, OpacityProps } from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';

type LinearProgressProps = PositionProps &
  OpacityProps & {
    subject?: string;
    overlay?: boolean;
    variant?: 'primary' | 'radiant';
  };

const LinearOverlayContainer = styled.div<LinearProgressProps>`
  position: ${({ overlay }) => (overlay ? 'absolute' : 'relative')};
  width: ${({ overlay }) => (overlay ? '100%' : 'auto')};
  overflow: hidden;
  height: 4px;
  z-index: 10;
  transition: opacity 0.3s ease;
  background: ${({ theme, variant }) => theme.linearProgress[variant].background};
  ${position}
  ${opacity}
`;

const linearProgressStyling = ({ theme, variant }) =>
  css`
    position: absolute;
    height: 4px;
    top: 0;
    left: 0;
    bottom: 0;
    background: ${theme.linearProgress[variant].color};
    transition: transform 0.2s linear;
    transform-origin: left;
  `;

const ProgressBar1 = styled.div`
  ${linearProgressStyling};
  animation: progress1Keyframe 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  @keyframes progress1Keyframe {
    0% {
      left: -35%;
      right: 100%;
    }
    60% {
      left: 100%;
      right: -90%;
    }
    100% {
      left: 100%;
      right: -90%;
    }
  }
`;

const ProgressBar2 = styled.div`
  ${linearProgressStyling};
  animation: progress2Keyframe 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
  @keyframes progress2Keyframe {
    0% {
      left: -200%;
      right: 100%;
    }
    60% {
      left: 107%;
      right: -8%;
    }
    100% {
      left: 107%;
      right: -8%;
    }
  }
`;

export const LinearProgress: React.FC<LinearProgressProps> = ({
  subject,
  overlay = false,
  opacity = 1,
  variant = 'primary',
  ...props
}) => {
  return (
    <LinearOverlayContainer
      {...generateLogzTestAttributes({ context: 'linear-progress', subject })}
      className={'progressbar-container'}
      role={'progressbar'}
      overlay={overlay}
      opacity={opacity}
      variant={variant}
      {...props}
    >
      <ProgressBar1 className={'progressbar-1'} variant={variant} />
      <ProgressBar2 className={'progressbar-2'} variant={variant} />
    </LinearOverlayContainer>
  );
};

LinearProgress.displayName = 'Linear Progressbar';
