import styled from 'styled-components';
import { Flex } from '../containers/Flex/Flex.component';

export const drawerDirections = {
  left: 'left',
  right: 'right',
};

interface IStyledDrawerProps {
  open?: boolean;
  width?: string;
  direction?: keyof typeof drawerDirections;
  name?: string;
  height?: string;
}

const directionalShadows = {
  [drawerDirections.right]: `-4px 0px 4px 1px`,
  [drawerDirections.left]: `4px 0px 4px 1px`,
};

const sidenavWidth = `64px`;

export const DrawerContainer = styled(Flex)<IStyledDrawerProps>`
  max-width: calc(100vw - ${sidenavWidth});
  position: fixed;
  overflow: hidden;
  transform: translateX(${({ direction, open }) => (open ? '0%' : `${direction === 'left' ? '-100' : '100'}%`)});
  box-shadow: ${({ direction, theme, open }) =>
    open ? `${directionalShadows[direction]} ${theme.drawer.shadow};` : 'none'};
  background-color: ${({ theme }) => theme.drawer.backgroundColor};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  right: ${({ direction }) => (direction === 'right' ? '0px' : 'unset')};
  transition: transform 0.2s ease-in-out;
`;

export const StyledDrawerContent = styled.div<IStyledDrawerProps>`
  display: flex;
  overflow: hidden;
  max-width: calc(100vw - ${sidenavWidth});
  width: 100%;
`;
