import { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '../containers/Flex/Flex.component';
import { Icon } from '../icons/Icon.component';
import { IsHoveredContext } from './HeaderDropdown.component';

const propTypes = {
  ...Flex.propTypes,
};

const defaultProps = {
  ...Flex.defaultProps,
};

const IconContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.header.chevron_background};
  min-width: 20px;
  min-height: 20px;
  border-radius: 10px;
  justify-content: center;
  align-content: center;
  align-self: center;
  ${({ open }) =>
    open &&
    css`
      background-color: ${({ theme }) => theme.header.chevron_background_hover};
    `}
`;

const StyledIcon = styled(Icon)`
  margin-top: ${({ icon }) => (icon === 'chevron-down-regular' ? '3px' : 0)};
  font-size: 10px;
  line-height: 10px;
  color: ${({ theme }) => theme.colors.gray[1000]};
  align-self: center;
`;

/**
 * @deprecated
 */
export const HeaderDropdownChevron = () => {
  const isHovered = useContext(IsHoveredContext);

  return (
    <IconContainer open={isHovered}>
      <StyledIcon icon={isHovered ? 'chevron-up-regular' : 'chevron-down-regular'} />
    </IconContainer>
  );
};

HeaderDropdownChevron.displayName = 'Header Dropdown Chevron';
HeaderDropdownChevron.propTypes = propTypes;
HeaderDropdownChevron.defaultProps = defaultProps;
