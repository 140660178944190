import React from 'react';
import styled, { css } from 'styled-components';
import { alignSelf, AlignSelfProps } from 'styled-system';
import { get } from 'lodash';
import { Color, colors } from '../themes';
import { Text } from '../typography';
import { Flex } from '../containers/Flex/Flex.component';
import { Icon } from '../icons/Icon.component';
import { FieldHelper } from '../containers/FieldContainer/Components';

interface IStyledCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  checkedColor?: Color;
  value?: boolean;
  name?: string;
  onChange?(): void;
  role?: string;
  id?: string;
  fullWidth?: boolean;
}

const checkboxBackgroundColor = css<IStyledCheckboxProps>`
  background-color: ${({ disabled, theme, checked, checkedColor }) => {
    if (disabled) {
      return theme.checkbox.disabled.backgroundColor;
    }

    if (checked) {
      if (checkedColor) {
        return get(colors, checkedColor);
      }

      return theme.checkbox.checked.backgroundColor;
    }

    return theme.checkbox.backgroundColor;
  }};
`;

const checkboxBorderColor = ({ disabled, theme, checked, checkedColor }) => {
  if (disabled) {
    return theme.checkbox.borderColor;
  }

  if (checked) {
    if (checkedColor) {
      return get(colors, checkedColor);
    }

    return theme.checkbox.checked.borderColor;
  }

  return theme.checkbox.borderColor;
};

export const CheckboxLabel = styled(Text)<IStyledCheckboxProps>`
  vertical-align: middle;
  margin-left: 7px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'inline')};
  line-height: normal;
`;

export const CheckboxHelper: React.FC<{ children: React.ReactNode }> = styled(FieldHelper)`
  margin-left: 7px;
`;

export const StyledCheckbox = styled.div<IStyledCheckboxProps & AlignSelfProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1px solid ${checkboxBorderColor};
  border-radius: 2px;
  transition: all 150ms;
  ${checkboxBackgroundColor};
  outline: none;
  ${alignSelf};
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 100%;
  width: 100%;
  margin: -1px;
  overflow: hidden;
  z-index: 3;
  padding: 0;
  position: absolute;
  white-space: nowrap;

  &:focus-within + ${StyledCheckbox} {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blue[300]};
  }
`;

export const StyledCheckboxIcon = styled(Icon)`
  line-height: 1.2;
`;

export const StyledCheckBoxContainer = styled(Flex)<IStyledCheckboxProps>`
  display: inline-flex;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  height: fit-content;
  position: relative;
  ${({ disabled }) =>
    css`
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    `}
`;
