import { bluePalette, royalBluePalette } from '../../themes/colors';

export const breadcrumbsLightTheme = {
  link: {
    size: 12,
    fontWeight: 400,
    color: bluePalette[600],
  },
  text: {
    size: 12,
    fontWeight: 600,
    color: royalBluePalette[1000],
  },
};
