export enum ColdTierSearchSubjects {
  EmptyState = 'cold-search-empty-state',
  FirstQueryState = 'cold-search-first-query-state',
  PageContainer = 'cold-search-container',
  ReingestButton = 'reingest-button',
  ReingestConfirmButton = 'reingest-confirm-button',
  PermissionsNotification = 'cold-search-permissions-notification',
  AdminReingestModal = 'admin-reingest-modal',
}

export enum ColdTierStates {
  None = 'NONE',
  LogzioInternal = 'LOGZIO_INTERNAL',
  ExternalAWS = 'EXTERNAL_AWS',
}
