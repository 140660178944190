import styled from 'styled-components';
import { SortDirection } from '@logz-pkg/enums';
import { Link } from '../../typography';
import { Icon } from '../../icons/Icon.component';
import { If } from '../../utils';
import { Tooltip } from '../../tooltip/Tooltip.component';
import { colors } from '../../themes';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${colors.gray[800]};
`;

interface ISortProps {
  onClick(): void;
  sortDirection: SortDirection;
  defaultSortingDirection?: SortDirection;
  className?: string;
}

const sortTooltips = {
  [SortDirection.Descending]: 'Descending',
  [SortDirection.Ascending]: 'Ascending',
};

const sortIcons: { [key: string]: 'sort-down-solid' | 'sort-up-solid' } = {
  [SortDirection.Descending]: 'sort-down-solid',
  [SortDirection.Ascending]: 'sort-up-solid',
};

export const Sort = styled(({ onClick, sortDirection, defaultSortingDirection, className }: ISortProps) => {
  const icon = sortIcons[sortDirection || defaultSortingDirection];
  const tooltip = sortTooltips[sortDirection || defaultSortingDirection];

  return (
    <>
      <If condition={onClick}>
        <Tooltip title={tooltip}>
          <StyledLink subject="sort" onClick={onClick} className={className}>
            <Icon icon={icon} />
          </StyledLink>
        </Tooltip>
      </If>
      <If condition={!onClick && sortDirection}>
        <Tooltip title={tooltip}>
          <Icon icon={icon} />
        </Tooltip>
      </If>
    </>
  );
})``;
