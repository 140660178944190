import React from 'react';
import styled from 'styled-components';
import { Group } from '../containers/Group/Group.component';
import { Link, Text, Title } from '../typography';
import { Info } from '../Info/Info.component';
import { Icon } from '../icons/Icon.component';

interface IScrollTitleProps {
  title?: string;
  description?: string;
  showContent: boolean;
  hideCollapse?: boolean;
  onCollapse: () => void;
}

const StyleGroup = styled(Group)`
  padding-left: ${({ theme }) => theme.scrollSnapping.padding};
  align-items: center;
  margin-bottom: 14px;
`;

export const ScrollTitle: React.FC<IScrollTitleProps> = ({
  title,
  description,
  showContent,
  onCollapse,
  hideCollapse,
}) => {
  return (
    <StyleGroup>
      <Title size={16}>{title}</Title>
      <Group ml={4} alignItems={'center'} justifyContent={'center'}>
        {description && <Info tooltip={description} color={'royalBlue.1000'} />}
        {!hideCollapse && (
          <Link onClick={onCollapse}>
            <Group alignItems={'center'} gap={1}>
              <Text inheritColor>{showContent ? 'Hide' : 'Show'}</Text>
              <Icon size={12} icon={showContent ? 'chevron-up-regular' : 'chevron-down-regular'} />
            </Group>
          </Link>
        )}
      </Group>
    </StyleGroup>
  );
};
