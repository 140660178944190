import { bluePalette, grayPalette, redPalette, yellowPalette } from '../../themes/colors';
import { colorsV2 } from '../../themes/colors-v2';

export const buttonDarkTheme = {
  variants: {
    primary: {
      textColor: grayPalette[1000],
      background: yellowPalette[600],
      hover: {
        background: yellowPalette[500],
      },
      active: {
        background: yellowPalette[400],
      },
      disabled: {
        textColor: grayPalette[600],
        background: `${yellowPalette[800]}75`,
      },
    },
    secondary: {
      borderColor: colorsV2.gray[700],
      textColor: colorsV2.darkBlue[100],
      background: colorsV2.gray[900],
      hover: {
        background: colorsV2.gray[800],
      },
      active: {
        background: colorsV2.gray[700],
      },
      disabled: {
        borderColor: colorsV2.gray[800],
        background: colorsV2.gray[900],
        textColor: colorsV2.gray[500],
      },
    },
    tertiary: {
      textColor: bluePalette[600],
      background: 'transparent',
      hover: {
        textColor: bluePalette[700],
        background: grayPalette[200],
      },
      active: {
        textColor: bluePalette[700],
        background: grayPalette[400],
      },
      disabled: {
        textColor: bluePalette[800],
        background: 'transparent',
      },
    },
    danger: {
      textColor: grayPalette[0],
      background: redPalette[600],
      hover: {
        background: redPalette[700],
      },
      active: {
        background: redPalette[800],
      },
      disabled: {
        textColor: grayPalette[600],
        background: redPalette[900],
      },
    },
    radiant: {
      textColor: grayPalette[0],
      background: colorsV2.gradient.radiant,
      boxShadow: 'none',
      before: {
        background: colorsV2.gray[900],
      },
      hover: {
        background: colorsV2.gray[800],
      },
      active: {
        background: colorsV2.gray[700],
      },
      disabled: {
        background: colorsV2.gradient.radiant,
        opacity: 0.3,
        textColor: grayPalette[0],
        before: {
          background: grayPalette[1000],
        },
      },
    },
    contrast: {
      textColor: grayPalette[100],
      background: bluePalette[900],
      borderColor: grayPalette[900],
      hover: {
        background: bluePalette[800],
      },
      active: {
        background: bluePalette[800],
      },
      focus: {
        borderColor: bluePalette[500],
      },
      disabled: {
        textColor: bluePalette[500],
        background: bluePalette[200],
      },
    },
  },
};
