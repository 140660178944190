import React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { get } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import styled from 'styled-components';
import { type Color, colors } from '../themes/colors';
import { Tooltip, type ITooltipProps } from '../tooltip/Tooltip.component';
import { Flex } from '../containers/Flex/Flex.component';
import icons from './font-awesome/metadata/icons.json';

type FAProps = Pick<FontAwesomeIconProps, 'spin' | 'flip'>;

type Size = 8 | 10 | 12 | 14 | 16 | 18 | 20 | 24 | 28 | 32 | number;

export interface IIconProps extends FAProps {
  icon: keyof typeof icons;
  tooltip?: ITooltipProps;
  subject?: string;
  size?: Size;
  color?: Color | (string & { fromColor?: any });
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseEnter?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

/**
 * DO NOT add anything with inlinestyle
 * I will kill you
 * DEV-45051
 */
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: inherit !important;
  height: inherit !important;
  display: inherit !important;
`;

export const Icon: React.FC<IIconProps> = ({
  icon,
  tooltip,
  color,
  subject,
  size = 14,
  flip,
  onClick,
  spin,
  ...props
}) => {
  if (!icons[icon]) {
    console.warn('Missing icon, icon name:', icon);
  }

  const iconName: IconProp = ((icons[icon] as unknown) ?? 'party-horn-regular') as IconProp;

  return tooltip ? (
    <Tooltip {...tooltip}>
      <Flex
        height={`${size}px`}
        width={`${size}px`}
        justifyContent={'center'}
        alignItems={'center'}
        className={'font-awesome-icon-container'}
        display={'inherit'}
        onClick={onClick}
        {...props}
      >
        <StyledFontAwesomeIcon
          {...generateLogzTestAttributes({ context: 'icon', subject: subject ? subject : icon })}
          color={get(colors, color) ?? color}
          icon={iconName}
          flip={flip}
          spin={spin}
        />
      </Flex>
    </Tooltip>
  ) : (
    <Flex
      height={`${size}px`}
      width={`${size}px`}
      justifyContent={'center'}
      alignItems={'center'}
      className={'font-awesome-icon-container'}
      display={'inherit'}
      onClick={onClick}
      {...props}
    >
      <StyledFontAwesomeIcon
        {...generateLogzTestAttributes({ context: 'icon', subject: subject ? subject : icon })}
        color={get(colors, color) ?? color}
        icon={iconName}
        flip={flip}
        spin={spin}
      />
    </Flex>
  );
};

export const iconsNames = Object.keys(icons) as Array<keyof typeof icons>;
