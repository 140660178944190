import { useState } from 'react';
import { useUpdateEffect } from '@logz-pkg/react-hooks';
import { ICrudTableProps, RowId } from './Crud.types';

interface IUseBulkActionsHook {
  dataSource: ICrudTableProps['dataSource'];
  isBulkFeatureEnabled: boolean;
}

export interface IBulkActionsState {
  ids: RowId[];
  isAllPagesSelected: boolean;
  isPageSelected: boolean;
}

export const useBulkActions = ({ dataSource, isBulkFeatureEnabled }: IUseBulkActionsHook) => {
  const [selected, setSelected] = useState<IBulkActionsState>({
    ids: [],
    isAllPagesSelected: false,
    isPageSelected: false,
  });

  const handleHeaderSelected = (isChecked: boolean, ids: RowId[]) => {
    setSelected({ ids: isChecked ? ids : [], isAllPagesSelected: false, isPageSelected: isChecked });
  };

  const handleRecordSelected = (isChecked: boolean, id: RowId) => {
    const ids = isChecked ? [...selected.ids, id] : selected.ids.filter(checkedId => checkedId !== id);

    setSelected({ ids, isAllPagesSelected: false, isPageSelected: false });
  };

  const toggleSelectAllData = () => {
    setSelected({
      ids: [],
      isAllPagesSelected: !selected.isAllPagesSelected,
      isPageSelected: !selected.isAllPagesSelected,
    });
  };

  const resetState = () => {
    setSelected({ ids: [], isAllPagesSelected: false, isPageSelected: false });
  };

  useUpdateEffect(() => {
    if (dataSource && isBulkFeatureEnabled) {
      resetState();
    }
  }, [dataSource]);

  return { selected, toggleSelectAllData, handleRecordSelected, handleHeaderSelected };
};
