import { opacity } from '../../utils';
import { fonts } from '../../themes/base/fonts';
import { colors } from '../../themes/colors';

const statusDefaults = {
  borderRadius: '2px',
  fontSize: '13px',
  fontStyle: 'italic',
  lineHeight: '15px',
  backgroundColor: colors.gray[0],
};

const primary = {
  color: colors.gray[900],
  background: colors.gray[400],
  borderRadius: '2px',
  height: '20px',
  lineHeight: '20px',
};

const secondary = {
  color: colors.gray[800],
  border: `1px solid ${colors.gray[800]}`,
  ...statusDefaults,
  fontStyle: 'regular',
};

const code = {
  color: colors.royalBlue[1000],
  background: colors.gray[300],
  borderRadius: '2px',
  height: '16px',
  fontFamily: fonts.code,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};

const statusTypes = theme => ({
  success: {
    color: theme.colors.semantic.success,
    backgroundColor: `1px solid ${theme.colors.semantic.success}`,
  },
  danger: {
    color: theme.colors.semantic.danger,
    backgroundColor: `1px solid ${theme.colors.semantic.danger}`,
  },
  warning: {
    color: theme.colors.semantic.warning,
    backgroundColor: `1px solid ${theme.colors.semantic.warning}`,
  },
  info: {
    color: theme.colors.semantic.info,
    backgroundColor: `1px solid ${opacity(colors.gray[800], 40)}`,
  },
});

const status = ({ theme, status }) => {
  const typeParams = statusTypes(theme);

  return {
    color: typeParams[status].color,
    border: typeParams[status].backgroundColor,
    ...statusDefaults,
  };
};

const beta = ({ theme }) => ({
  background: opacity(colors.orange[500], 20),
  borderRadius: '2px',
  margin: '10px',
  fontFamily: theme.fonts.base,
  fontSize: theme.sizes.text.small,
  fontStyle: 'italic',
  fontWeight: 'bold',
  lineHeight: '13px',
  padding: '0 7px 0 7px',
});

interface ITagVariants {
  primary: any;
  secondary: any;
  success: any;
  danger: any;
  info: any;
  warning: any;
  beta: any;
  code: any;
}

export const tagVariants: ITagVariants = {
  primary,
  secondary,
  success: ({ theme }) => status({ theme, status: 'success' }),
  danger: ({ theme }) => status({ theme, status: 'danger' }),
  info: ({ theme }) => status({ theme, status: 'info' }),
  warning: ({ theme }) => status({ theme, status: 'warning' }),
  beta,
  code,
};
