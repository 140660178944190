import { colors } from '../colors';
import { opacity } from '../../utils';

export const baseColors = {
  text: {
    base: colors.gray[900],
    title: colors.gray[900],
    label: opacity(colors.gray[900], 70),
    primary: colors.blue[500],
    secondary: opacity(colors.gray[900], 70),
    accent: colors.orange[600],
    danger: colors.red[500],
    cardTitle: colors.blue[700],
    placeholder: colors.gray[600],
    helper: opacity(colors.gray[800], 70),
    readonly: colors.gray[800],
    marketing: colors.yellow[600],
  },
};
