import styled from 'styled-components';

const SectionContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.actions.secondary};
  position: relative;
`;

const SectionIndicator = styled.div`
  position: absolute;
  height: 100%;
  width: ${({ theme }) => theme.space[1] / 2}px;
  z-index: 1;
  background: ${({ theme }) => theme.colors.actions.primary};
`;

export const TableSection = ({ children, ...props }) => (
  <SectionContainer {...props}>
    <SectionIndicator />
    {children}
  </SectionContainer>
);

TableSection.displayName = 'Table Section';
