import { isNil } from 'lodash';
import React, { useEffect, type FunctionComponent, useRef, useState } from 'react';
import { Menu as SGMenu } from '../../menu/index';
import { ContextMenu, Item } from '../types';

interface IMenuProps {
  contextMenu: ContextMenu;
  item: Item;
  closePopover: () => void;
}

const scrollDistance = 100;

export const Menu: FunctionComponent<IMenuProps> = ({ item, contextMenu, closePopover }) => {
  const [initialHeight, setInitialHeight] = useState(null);
  const myRef = useRef(null);

  useEffect(() => {
    const rect = myRef.current.getBoundingClientRect();

    if (!initialHeight) {
      setInitialHeight(rect.top);
    } else if (Math.abs(initialHeight - rect.top) > scrollDistance) {
      closePopover();
    }
  });

  return (
    <SGMenu ref={myRef}>
      {!isNil(contextMenu.menuName) && <SGMenu.Title>{contextMenu.menuName}</SGMenu.Title>}
      {contextMenu.menuItems.map((menu, i) => {
        return (
          <SGMenu.Item
            key={i}
            icon={menu.icon}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
              menu.onClick(item);
              closePopover();
            }}
          >
            {menu.label}
          </SGMenu.Item>
        );
      })}
    </SGMenu>
  );
};
