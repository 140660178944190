import { opacity } from '../../utils';
import { colors } from '../colors';

export const notification = {
  info: {
    textColor: colors.gray[900],
    background: opacity(colors.gray[300], 90),
    icon: colors.gray[600],
    line: colors.gray[600],
  },
  announcement: {
    textColor: colors.gray[900],
    background: opacity(colors.blue[300], 10),
    icon: colors.gray[600],
    line: colors.blue[300],
  },
  danger: {
    textColor: colors.gray[900],
    background: opacity(colors.red[500], 10),
    icon: colors.red[500],
    line: colors.red[500],
  },
  accent: {
    textColor: colors.gray[900],
    background: opacity(colors.orange[500], 10),
    icon: colors.orange[500],
    line: opacity(colors.orange[500], 70),
  },
  warning: {
    textColor: colors.gray[900],
    background: opacity(colors.orange[500], 10),
    icon: colors.orange[500],
    line: opacity(colors.orange[500], 70),
  },
  notice: {
    textColor: colors.gray[900],
    background: opacity(colors.gray[300], 90),
    icon: colors.gray[600],
    line: colors.gray[600],
  },
  success: {
    textColor: colors.gray[900],
    background: opacity(colors.green[500], 10),
    icon: colors.green[500],
    line: colors.green[500],
  },
  critical: {
    textColor: colors.gray[0],
    background: colors.red[600],
    icon: colors.gray[0],
    line: colors.red[500],
  },
  progress: {
    textColor: colors.royalBlue[1000],
    background: colors.gray[100],
    icon: colors.royalBlue[1000],
    line: colors.royalBlue[1000],
  },
};
