export enum WelcomePageTestSubject {
  WelcomePage = 'welcome-page',
  ExitWelcomeFlow = 'exit-welcome-flow',
  PlaygroundModal = 'playground-modal',
  DataSentModal = 'data-sent-modal',
  GoBackButton = 'go-back-button',
  BarDataStatusIndicator = 'bar-data-status-indicator',
  CompleteFlowButton = 'complete-welcome-flow-button',
  ContinueFlowButton = 'continue-welcome-flow-button',
  UserRole = 'user-welcome-page-role',
  UserDesiredProduct = 'welcome-page-product',
}

export enum WelcomePageUserRole {
  DevOps = 'DevOps Engineer',
  Developer = 'Software Developer',
  Admin = 'System Administrator',
  Executive = 'Executive',
  TeamLead = 'Team Lead',
  MarketingSales = 'Marketing/Sales',
  Security = 'Security Analyst/Engineer',
  Other = 'Other',
}

export enum WelcomePageDesiredProducts {
  LogsAnalytics = 'Logs Analytics',
  InfraMonitoring = 'Infrastructure Monitoring',
  AppMonitoring = 'Application Monitoring',
  Traces = 'Traces',
  SIEM = 'SIEM',
}
