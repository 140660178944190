import iconsV2 from './font-awesome/metadata/icons.json';

interface IIconInformation {
  iconName: string;
  prefix: string;
}

export const getIconNameAndPrefix = (icon: string): IIconInformation => {
  const iconMapObject = JSON.parse(JSON.stringify(iconsV2));

  return iconMapObject[icon];
};
