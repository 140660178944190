import styled, { css } from 'styled-components';
import { isNil } from 'lodash';
import { space, get } from 'styled-system';
import { Flex, InlineFlex } from '../containers/Flex/Flex.component';
import { IBadgeProps } from './Badge.types';

export const sizes = {
  xxs: 10,
  xs: 18,
  s: 20,
  m: 30,
  l: 40,
};

const fontSize = {
  xs: 12,
  s: 14,
  m: 16,
  l: 18,
};

export const StyledBadgeContainer = styled(InlineFlex)`
  position: relative;
  vertical-align: middle;
`;

export const StyledBadge = styled(Flex)<IBadgeProps>`
  ${({ theme, variant, color, borderColor, outline, dashed, borderSize = 1 }) => {
    const styledColor = variant ? theme.badge[variant].color : get(theme.colors, `${color}`);
    const styledBorderColor = get(theme.colors, borderColor) ?? borderColor;

    return css`
      ${!borderColor ? `outline: ${dashed ? '1px dashed' : '1px solid'} ${styledColor}` : ''};
      outline-offset: -1px;
      background-color: ${outline ? `transparent` : styledColor};
      color: ${outline ? styledColor : theme.badge.fontColor};
      ${borderColor ? `border: ${borderSize}px solid ${styledBorderColor}` : ''};
    `;
  }}

  height: ${({ size }) => sizes[size]}px;
  min-width: ${({ size, square }) => (square ? `${sizes[size] - 4}px` : `${sizes[size]}px`)};

  border-radius: ${({ size, square }) => (!square ? `${sizes[size] / 2}px` : '3px')};

  font-weight: 500;
  font-size: ${({ size }) => fontSize[size]}px;

  ${({ children }) =>
    !isNil(children) &&
    css`
      padding: 1px 4px;
    `};
  box-sizing: border-box;

  ${({ wrapper, wrapperOffset }) =>
    wrapper &&
    css`
      position: absolute;
      right: ${wrapperOffset ?? 0};
      top: 0;
      transform: translate(50%, -50%);
    `};

  ${space}
`;
