export enum ArchiveStatus {
  NONE = 'none',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum ArchiveAndRestoreTabs {
  Archive = 'Archive',
  Restore = 'Restore',
  RestoredAccounts = 'Restored Accounts',
}

export enum ArchiveAndRestoreStorageType {
  AWS = 'S3',
  AZURE = 'BLOB',
}

export enum S3AuthenticationTypes {
  IAM = 'IAM',
  SECRET = 'KEYS',
}

export enum ArchiveAndRestoreSubject {
  SwitchStorage = 'switch-storage',
  PageTabs = 'archive-and-restore-tabs',
  SettingsStorageTabs = 'archive-settings-tabs-storage',
  ArchivingWarningSwitchToAzure = 'inline-warn-aws-archiving',
  ArchiveSettingsTabContent = 'archive-settings-tab-content',
}

export enum ArchiveAzureInputSubjects {
  TenantId = 'azure-tenant-id',
  ClientId = 'azure-client-id',
  ClientSecret = 'azure-client-secret',
  AccountName = 'azure-account-name',
  ContainerName = 'azure-container-name',
  StoragePathPrefix = 'azure-storage-path-prefix',
}

export enum AzureFormFieldsError {
  AccountNameRegex = 'Storage account can include only lowercase letters and numbers',
  AccountNameMinLength = 'Length must be at least 3 characters',
  AccountNameMaxLength = 'Length can be up to 24 characters',

  ContainerNameRegex = 'Container can include only lowercase letters, numbers, and hyphens (`–`)',
  ContainerHyphensPattern = 'Must start and end with a letter or a number and cannot include consecutive hyphens',
  ContainerNameMinLength = 'Length must be at least 3 characters',
  ContainerNameMaxLength = 'Length can be up to 63 characters',

  PathSlashesValidations = 'Virtual sub-folder cannot start or end with a slash (`/`)',
  PathMaxSlashes = 'Sub-folder can include up to 254 forward slashes (‘/’)',
  PathMaxLength = 'Length can be up to 974 URL characters',
}

export enum ArchiveNotificationSubject {
  TestSettings = 'test-archive-settings',
  CreateArchiveSettings = 'create-archive-settings',
  UpdateArchiveSettings = 'update-archive-settings',
  StopArchiving = 'stop-archiving',
  RestartArchiving = 'restart-archiving',
  DeleteArchiveSettings = 'delete-archive-settings',
  AssumeRoleDetails = 'get-assume-role-details',
  CopyRolePolicy = 'copy-role-policy',
}

export enum ArchiveButtonSubjects {
  ChangeToAccessKey = 'change-to-access-key',
  ChangeToIAMRole = 'change-to-iam',
  StartArchiving = 'start-archiving-button',
  PauseArchiving = 'pause-archiving-button',
  RestartArchiving = 'restart-archiving-button',
  TestConnection = 'test-connection-button',
  MigrateToIAM = 'migrate-to-iam',
  RevertToAccessKey = 'revert-to-access-key',
}

export enum ArchiveSettingsStatusSubject {
  notConfigured = 'not-configured',
  archiving = 'archiving',
  paused = 'paused',
}

export enum ArchiveInputSubjects {
  BucketName = 'bucket-name',
  ARN = 'role-arn',
  AccessKey = 'access-key',
  SecretKey = 'secret-key',
}

export enum RestoreNotificationSubject {
  CreateRestore = 'create-restore',
  DeleteRestore = 'delete-restore',
  EstimationCostRecieved = 'estimation-cost-recieved',
}

export enum RestoreSubjects {
  ProceedRestoreButton = 'proceed-restore-button',
  SubmitRestoreButton = 'submit-restore',
  AccountNameInput = 'account-name-input',
  PowerSearchInput = 'power-search-input',
  EstimateScanCostButton = 'estimate-scan-cost-button',
  RestoreDateRangePicker = 'restore-date-range',
  RestoreContainer = 'restore-container',
  RestoreConfigCard = 'restore-config-card',
  FiltersCard = 'filters-card',
  PowerSearchCard = 'power-search-card',
}

export enum RestoredAccountsSubjects {
  RestoredAccountsTable = 'restored-accounts-table',
  DeleteRestore = 'delete-restore',
}

export enum RestoreDataSourceType {
  ColdStorage = 'COLD_STORAGE',
  Archive = 'ARCHIVE',
}
