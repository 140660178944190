import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../Button/Button.component';
import { Group } from '../../../../containers/Group/Group.component';
import { Table } from '../../../Table';
import { actionsCellStyle } from '../ActionsCell';
import { ICrudOptions, ICrudTableEditRecordProps } from '../../Crud.types';
import { Text } from '../../../../typography';

interface ICrudTableEditRecordActionsProps {
  onCancel: ICrudTableEditRecordProps['onCancel'];
  isSubmitting: boolean;
  isNew?: boolean;
  className?: string;
  options?: ICrudOptions['actions']['edit'];
  dirty: boolean;
}

const StyledActionCell = styled(Table.ActionCell)`
  ${actionsCellStyle};
`;

export const CrudTableEditRecordActions: FunctionComponent<ICrudTableEditRecordActionsProps> = ({
  className,
  onCancel,
  isSubmitting,
  isNew = false,
  dirty,
  options,
}) => {
  const addButtonSubject = isNew ? 'add' : 'save';
  const addButtonText = options?.save?.text ?? (isNew ? 'Add' : 'Save');
  const addButtonVariant = options?.save?.variant ?? 'tertiary';
  const disableSaveButton = options?.save?.disableIfPristine && !dirty;

  return (
    <StyledActionCell className={className}>
      <Group vertical width={'100%'}>
        <Group>
          <Button
            variant={addButtonVariant}
            disabled={disableSaveButton}
            type={'submit'}
            loading={isSubmitting}
            subject={addButtonSubject}
          >
            {addButtonText}
          </Button>
          <Button variant={'tertiary'} onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
        </Group>
        {options?.note ? <Text size={10}>{options?.note}</Text> : null}
      </Group>
    </StyledActionCell>
  );
};
