import { textLightTheme } from '../../../typography/Text/themes/light-v2.theme';
import { colors } from '../../../themes/colors';

export const containerLightV2Theme = {
  variants: {
    neutral: {
      background: colors.gray[0],
      borderColor: colors.gray[300],
      textColor: textLightTheme.color.primary,
    },
    pLight: {
      background: colors.gray[100],
      borderColor: colors.gray[400],
      textColor: textLightTheme.color.faded,
    },
    primary: {
      background: colors.gray[200],
      borderColor: colors.gray[500],
      textColor: textLightTheme.color.primary,
    },
    pMedium: {
      background: colors.gray[300],
      borderColor: colors.gray[600],
      textColor: textLightTheme.color.primary,
    },
    pDark: {
      background: colors.gray[700],
      borderColor: colors.gray[1000],
      textColor: textLightTheme.color.contrast,
    },
    pDeep: {
      background: colors.gray[800],
      borderColor: colors.gray[1000],
      textColor: textLightTheme.color.contrast,
    },
    sLight: {
      background: colors.blue[100],
      borderColor: colors.blue[400],
      textColor: textLightTheme.color.faded,
    },
    secondary: {
      background: colors.blue[200],
      borderColor: colors.blue[500],
      textColor: textLightTheme.color.primary,
    },
    sMedium: {
      background: colors.blue[800],
      borderColor: colors.royalBlue[1000],
      textColor: textLightTheme.color.contrast,
    },
    sDark: {
      background: colors.blue[900],
      borderColor: colors.gray[1000],
      textColor: textLightTheme.color.contrast,
    },
    sDeep: {
      background: colors.blue[1000],
      borderColor: colors.gray[1000],
      textColor: textLightTheme.color.contrast,
    },
    tSoft: {
      background: colors.yellow[200],
      borderColor: colors.yellow[500],
      textColor: textLightTheme.color.primary,
    },
    tLight: {
      background: colors.yellow[300],
      borderColor: colors.yellow[600],
      textColor: textLightTheme.color.primary,
    },
    tertiary: {
      background: colors.yellow[400],
      borderColor: colors.yellow[700],
      textColor: textLightTheme.color.primary,
    },
    tMedium: {
      background: colors.yellow[600],
      borderColor: colors.yellow[900],
      textColor: textLightTheme.color.primary,
    },
    tDark: {
      background: colors.yellow[700],
      borderColor: colors.yellow[1000],
      textColor: textLightTheme.color.contrast,
    },
    lightError: {
      background: colors.red[200],
      borderColor: colors.red[500],
      textColor: textLightTheme.color.primary,
    },
    error: {
      background: colors.red[500],
      borderColor: colors.red[800],
      textColor: textLightTheme.color.contrast,
    },
    lightSuccess: {
      background: colors.green[200],
      borderColor: colors.green[500],
      textColor: textLightTheme.color.primary,
    },
    success: {
      background: colors.green[500],
      borderColor: colors.green[800],
      textColor: textLightTheme.color.primary,
    },
    darkSuccess: {
      background: colors.green[700],
      borderColor: colors.green[1000],
      textColor: textLightTheme.color.contrast,
    },
    lightRoyal: {
      background: colors.royalBlue[200],
      borderColor: colors.royalBlue[500],
      textColor: textLightTheme.color.primary,
    },
    royal: {
      background: colors.royalBlue[1000],
      borderColor: colors.royalBlue[700],
      textColor: textLightTheme.color.contrast,
    },
  },
};
