import React from 'react';
import { ITagFilterProps, TagFilter } from './Filter.component';
import { TagGroup } from './Group.component';
import { ITagSeverity, TagSeverity } from './Severity.component';
import { ITagProps, Tag as PrimaryTag } from './Tag.component';
import { tagVariants } from './themes/Tag.variants';

export type TagVariants = keyof typeof tagVariants;

type TagProps = typeof PrimaryTag & {
  Filter: React.FC<ITagFilterProps>;
  Group: React.ElementType;
  Severity: React.FC<ITagSeverity>;
} & Partial<Record<TagVariants, React.FunctionComponent<ITagProps>>>;

Object.defineProperty(PrimaryTag, 'Filter', { value: TagFilter });
Object.defineProperty(PrimaryTag, 'Group', { value: TagGroup });
Object.defineProperty(PrimaryTag, 'Severity', { value: TagSeverity });

export const Tag = PrimaryTag as TagProps;
