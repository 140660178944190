import { Row as RTrow } from '@tanstack/react-table';
import { VirtualItem } from '@tanstack/react-virtual';
import { isNil } from 'lodash';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../../themes/colors-v2';
import { INDICATOR_COMPONENT_COLUMN_ID, STATIC_DATA_ROW_STYLES } from '../constants';
import { IDataTableProps, TableVirtualizer } from '../types';
import { dataTableUtils } from '../utils';
import { BodyCell } from './BodyCell';
import { CellContent } from './CellContent';
import { PinShadow } from './PinShadow';
import { Row } from './Row';
import { SubComponentRow } from './SubComponent/SubComponentRow';

const StyledRow = styled(Row)<{ rowIndex: number }>`
  align-items: stretch;
  :hover {
    z-index: 1;
  }

  @keyframes flash {
    0% {
      background-color: ${colorsV2.yellow[200]};
    }
    100% {
      background-color: inherit;
    }
  }
`;

interface IBodyRowProps<T> {
  actionsOffsetTop?: number;
  virtualRow: VirtualItem<Element>;
  row: RTrow<T>;
  virtualizer: TableVirtualizer;
  rowColor: string;
  border: string;
  tableRef: React.RefObject<HTMLDivElement>;
  renderedRowIndex: number;
  ExpandableIconComponent: IDataTableProps<T>['subRows']['ExpandableIconComponent'];
  indentByDepth: IDataTableProps<T>['subRows']['indentByDepth'];
  ExpandedRowComponent: IDataTableProps<T>['ExpandedRowComponent'];
  onRowClick?: IDataTableProps<T>['onRowClick'];
  name: IDataTableProps<T>['name'];
  getActions: IDataTableProps<T>['getActions'];
  highlightColumns: IDataTableProps<T>['highlightColumns'];
  state: IDataTableProps<T>['state'];
  popoverSettings: IDataTableProps<T>['popoverSettings'];
  subject: IDataTableProps<T>['subject'];
  estimateRowHeight: IDataTableProps<T>['estimateRowHeight'];
  HoveredRowComponent: IDataTableProps<T>['HoveredRowComponent'];
  density: IDataTableProps<T>['density'];
  IndicatorComponent: IDataTableProps<T>['IndicatorComponent'];
  isRowMarked: boolean;
  isScrolledHorizontally: boolean;
  pinEndPosition: number;
  flash?: boolean;
}

export const BodyRow = <T extends object>({
  virtualRow,
  row,
  isRowMarked,
  virtualizer,
  rowColor,
  flash,
  border,
  tableRef,
  renderedRowIndex,
  ExpandableIconComponent,
  indentByDepth,
  ExpandedRowComponent,
  onRowClick,
  name,
  getActions,
  highlightColumns,
  state,
  popoverSettings,
  subject,
  estimateRowHeight,
  HoveredRowComponent,
  density,
  IndicatorComponent,
  isScrolledHorizontally,
  pinEndPosition,
  actionsOffsetTop,
}: IBodyRowProps<T>) => {
  const rowRef = useRef<HTMLTableRowElement>(null);
  const densityStyle = dataTableUtils.getDensityStyle(density);

  return (
    <>
      <StyledRow
        data-index={renderedRowIndex}
        data-marked={isRowMarked}
        backgroundColor={rowColor}
        data-expanded={row.getIsExpanded()}
        data-selected={row.getIsSelected()}
        rowIndex={renderedRowIndex}
        ref={element => {
          rowRef.current = element;

          if ((!isNil(ExpandedRowComponent) && !row.getIsExpanded()) || isNil(ExpandedRowComponent)) {
            virtualizer.measureElement(element);
          }
        }}
        {...(!isNil(onRowClick) && { onClick: () => onRowClick(row) })}
        style={{
          ...(virtualRow && { ...STATIC_DATA_ROW_STYLES, transform: `translateY(${virtualRow.start}px)` }),
          borderBottom: border,
          borderTop: border,
          ...(flash && {
            animation: `flash 1s ease-in-out`,
          }),
        }}
      >
        {row.getVisibleCells().map((cell, index) => {
          const isIndicatorCell = cell.column.id === INDICATOR_COMPONENT_COLUMN_ID;
          const { column } = cell;
          const isPinned = column.getIsPinned();
          const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
          const isLastRightPinnedColumn = isPinned === 'right' && column.getIsLastColumn('right');

          return (
            <BodyCell
              style={{
                display: 'flex',
                width: cell.column.getSize(),
                padding: 0,
                height: 'auto',
                ...dataTableUtils.getIndentStyle<T>({ ExpandableIconComponent, indentByDepth, index, row }),
                ...dataTableUtils.getHighlightStyle<T>({ cell, highlightColumns, state }),
                ...dataTableUtils.getCommonPinningStyles(column),
              }}
              key={cell.id}
              cell={cell}
              hasActions={!isNil(getActions)}
            >
              {isLastRightPinnedColumn && <PinShadow isVisible={isScrolledHorizontally} pinDirection="right" />}
              <CellContent
                {...{
                  getActions,
                  actionsOffsetTop,
                  cell,
                  ExpandableIconComponent,
                  isFirst: isNil(IndicatorComponent) ? index === 0 : index === 1,
                  padding: isIndicatorCell ? '0' : densityStyle.padding,
                  popoverSettings,
                  row,
                  name,
                  subject,
                  density,
                  flipLeftPadding: pinEndPosition,
                  isExpanded: row.getIsExpanded(), // this is to trigger the memo in CellContent
                }}
              />
              {isLastLeftPinnedColumn && <PinShadow isVisible={isScrolledHorizontally} pinDirection="left" />}
            </BodyCell>
          );
        })}
        {!isNil(HoveredRowComponent) && (
          <td className={'hovered-component'} style={{ zIndex: 2 }}>
            <HoveredRowComponent row={row} />
          </td>
        )}
      </StyledRow>
      {row.getIsExpanded() && !isNil(ExpandedRowComponent) && (
        <SubComponentRow<T>
          {...{
            parentRowHeight: rowRef.current?.getBoundingClientRect()?.height ?? estimateRowHeight,
            ExpandedRowComponent,
            row,
            virtualizer,
            virtualRow,
            tableRef,
          }}
        />
      )}
    </>
  );
};
