import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { lightV2Theme } from '@logz-ui/styleguide/components/themes/light-v2.theme';
import { DatePickerInputProps } from '../types';
import { BasicInput } from './BasicInput';
import { InputIcon } from './InputIcon';

const StyledInput = styled(BasicInput)`
  input {
    color: ${({ theme }) => theme.searchFilters.button.inactive.label.color};
    border-color: ${({ theme }) => theme.searchFilters.button.inactive.border};
    background-color: ${({ theme }) => theme.searchFilters.button.inactive.backgroundColor};

    &:focus {
      box-shadow: none;
      border-color: ${({ theme }) => theme.searchFilters.button.inactive.border};
    }

    &:active {
      border-color: ${({ theme }) => theme.searchFilters.button.inactive.active.borderColor};
      background-color: ${({ theme }) => theme.searchFilters.button.inactive.active.backgroundColor};
    }

    &:hover {
      color: ${({ theme }) => theme.searchFilters.button.inactive.label.color};
      border-color: ${({ theme }) => theme.searchFilters.button.inactive.hover.borderColor};
      background-color: ${({ theme }) => theme.searchFilters.button.inactive.hover.backgroundColor};
    }
  }

  ${({ value }) =>
    value &&
    css`
      input {
        font-weight: 500;
        color: ${({ theme }) => theme.searchFilters.button.active.label.color};
        border-color: ${({ theme }) => theme.searchFilters.button.active.border};
        background-color: ${({ theme }) => theme.searchFilters.button.active.backgroundColor};

        &:focus {
          color: ${({ theme }) => theme.searchFilters.button.active.label.color};
          border-color: ${({ theme }) => theme.searchFilters.button.active.border};
          background-color: ${({ theme }) => theme.searchFilters.button.active.backgroundColor};
        }

        &:active {
          border-color: ${({ theme }) => theme.searchFilters.button.active.active.borderColor};
          background-color: ${({ theme }) => theme.searchFilters.button.active.active.backgroundColor};
        }

        &:hover {
          color: ${({ theme }) => theme.searchFilters.button.active.label.color};
          border-color: ${({ theme }) => theme.searchFilters.button.active.hover.borderColor};
          background-color: ${({ theme }) => theme.searchFilters.button.active.hover.backgroundColor};
        }
      }
    `}
`;

export const SearchFilterInput: React.FC<DatePickerInputProps> = props => {
  const {
    searchFilters: { button },
  } = useTheme() as typeof lightV2Theme;
  const { value } = props;

  const iconColor = value ? button.active.icon.color : button.inactive.icon.color;

  return (
    <>
      <StyledInput {...props} />
      <InputIcon color={iconColor} />
    </>
  );
};
