import { ElementType } from 'react';
import { Grid as PrimaryGrid } from './Grid.component';
import { GridCell } from './Cell.component';

type GridProps = typeof PrimaryGrid & {
  Cell: ElementType;
};

Object.defineProperty(PrimaryGrid, 'Cell', { value: GridCell });

export const Grid = PrimaryGrid as GridProps;
