import { ElementType } from 'react';
import { Header as PrimaryHeader } from './Header.component';
import { HeaderSection } from './HeaderSection.component';
import { HeaderButton } from './HeaderButton.component';
import { HeaderDropdown } from './HeaderDropdown.component';
import { HeaderDropdownContent } from './HeaderDropdownContent.component';
import { HeaderDropdownChevron } from './HeaderDropdownChevron.component';

export const Header: typeof PrimaryHeader & {
  Section?: ElementType;
  Button?: ElementType;
  Dropdown?: ElementType;
  DropdownContent?: ElementType;
  Chevron?: ElementType;
} = PrimaryHeader;

Header.Section = HeaderSection;
Header.Button = HeaderButton;
Header.Dropdown = HeaderDropdown;
Header.DropdownContent = HeaderDropdownContent;
Header.Chevron = HeaderDropdownChevron;
