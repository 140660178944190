import { get } from 'lodash';

const generalError = (code, message, type = 'general') => ({ code, message, type });
export const authError = (code, message, type = 'auth') => ({ code, message, type });

export const ERRORS = {
  UNKNOWN_ERROR: generalError('unknown_error', 'An unknown error has occurred'),
  UNEXPECTED: generalError('unexpected_error', 'Unexpected failure. Please try again.'),
  A0_INVALID_CODE: authError('a0.mfa_invalid_code', 'The MFA entered is not valid'),
  A0_REGISTRATION_REQUIRED: authError('a0.mfa_registration_required', 'MFA Registration required'),
  SSO_NOT_CONFIGURED: authError('SSO/NOT_FOUND', "Couldn't find a matching provider"),
  SSO_CONNECTION_DISABLED: authError('the connection was disabled', 'SSO connection was disabled for the user'),
  A0_REQUIRED: authError('a0.mfa_required', 'MFA required'),
  UNAUTHORIZED: authError(
    'unauthorized',
    'You are trying to log in from a restricted IP address ' +
      'Please consult your account admin should you need additional help.',
  ),
  INVALID_LOGIN: authError('invalid_login', 'Invalid username/password or Google sign in is required'),
  FAILED_TO_LOGIN: authError('failed_to_login', 'Failed to login'),
  ACCESS_DENIED: authError('access_denied', 'Access denied'),
  TOO_MANY_ATTEMPTS: authError('too_many_attempts', 'Too many attempts'),
  MISSING_URL: authError('missing_url', 'Missing Sign-Up page URL'),
  EXTERNAL_REGISTRATION_FAILED: authError('external_registration_failed', 'External registration failed'),
  GET_EMAIL: authError('get_email', 'Error getting email'),
  INVALID_REGISTER: authError('invalid_register', 'Registration failed'),
  DOMAIN_NOT_AUTHORIZED: authError('domain_not_authorized', 'Domain is not authorized'),
  INVALID_OTP: authError('invalid_grant', 'Entered OTP code is not valid'),
  EMAIL_VERIFICATION_REQUIRED: authError(
    'email_verification_required',
    'Please check your email & click the link to activate your account',
  ),
  NO_IFRAME_LOGIN: authError('no_iframe_login', 'Unable to login in iframe or popup'),
};

const errorObjectReducer = (acc: any, [_, { code, message }]: any) => {
  acc[code] = message;

  return acc;
};

const errorCodeToMessage = Object.entries(ERRORS).reduce(errorObjectReducer, {});

export const getErrorMessageOrDefault = (errorCode: string, defaultError: string = ERRORS.UNKNOWN_ERROR.message) =>
  get(errorCodeToMessage, errorCode, defaultError);

export const safelyParseError = err => {
  const errCode = typeof err === 'string' ? err : err.code;

  return getErrorMessageOrDefault(errCode);
};
