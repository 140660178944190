// See: https://github.com/jxnblk/styled-system/blob/master/docs/api.md#customize
// for more information about `styled-system`

import { style, space } from 'styled-system';

export { space };

export const boxShadow = style({
  prop: 'boxShadow',
  cssProperty: 'box-shadow',
  key: 'shadow',
  numberToPx: false,
  getter: n => n,
});
