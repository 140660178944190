export enum ServiceOverviewSubjects {
  GroupByButtonGroup = `group-by-button-group`,
  ServiceOverviewTable = 'service-overview-table',
  SpmGraphThroughput = 'throughput-spm-graph',
  SpmGraphErrorRate = 'error-rate-spm-graph',
  SpmGraphLatency = 'latency-spm-graph',
  SpmGraphHttpStatusCode = 'http-status-code-spm-graph',
  InfraGraphCpu = 'cpu-infra-graph',
  InfraGraphMemory = 'memory-infra-graph',
}
