export enum AccountDataType {
  LOGS = 'LOGS',
  METRICS = 'METRICS',
  TRACES = 'TRACES',
  SIEM = 'SIEM',
}

export enum AccountTag {
  Searchable = 'Searchable',
  Accessible = 'Accessible',
  RepositoryAccount = 'Repository account',
  HasRepository = 'Has repository',
}

export enum AccountType {
  Owner = 'OWNER_ACCOUNT',
  Sub = 'SUB_ACCOUNT',
  Security = 'SECURITY_ACCOUNT',
  Timeless = 'TIMELESS_INDEX',
  Metrics = 'METRICS_ACCOUNT',
  Restore = 'RESTORE_ACCOUNT',
  Tracing = 'TRACING_ACCOUNT',
}

export enum PLAN_TYPE {
  FREE = 'FREE',
  TRIAL = 'TRIAL',
  LIGHT = 'LIGHT',
  STANDARD = 'STANDARD',
  PRO = 'PRO',
  ENTERPRISE = 'ENTERPRISE',
}

export enum ACCOUNT_STATES {
  BLOCKED,
  ACTIVE,
}

export enum UserRole {
  ReadOnly = 'USER_ROLE_READONLY',
  Siem = 'USER_ROLE_SIEM',
  User = 'USER_ROLE_REGULAR',
  Admin = 'USER_ROLE_ACCOUNT_ADMIN',
  Expert = 'USER_ROLE_RESTORE_ALLOWED',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Pending = 'PENDING',
  Expired = 'EXPIRED',
}

export enum ACCOUNT_CREATION_STATES {
  CREATED = 'AS_CREATED',
  READY = 'AS_READY',
  BASE = 'AS_BASE',
}

export enum LogsSuspensionState {
  NotSuspended = 'NOT_SUSPENDED',
  IndexingSuspended = 'INDEXING_SUSPENDED',
  ArchivingSuspended = 'ARCHIVING_SUSPENDED',
}

export enum SiemOwnerAccountState {
  NoSiemAccount = 'NO_SIEM_ACCOUNT',
  InactiveSiemAccount = 'INACTIVE_SIEM_ACCOUNT',
  SiemAccountExists = 'SIEM_ACCOUNT_EXISTS',
}
