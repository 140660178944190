import { FunctionComponent, useState } from 'react';
import { Button } from '../Button/Button.component';
import { CopyToClipboard } from './CopyToClipboard.component';

interface IContactSupportProps {
  text: string;
  beforeCopyText: string;
  afterCopyText: string;
  onCopy?(): void;
}

export const CopyToClipboardButton: FunctionComponent<IContactSupportProps> = ({
  text,
  beforeCopyText,
  afterCopyText,
  onCopy,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        setIsCopied(true);
        onCopy && onCopy();
      }}
    >
      {isCopied ? (
        <Button variant={'secondary'} icon={'circle-check-solid'}>
          {afterCopyText}
        </Button>
      ) : (
        <Button icon={'copy-regular'}>{beforeCopyText}</Button>
      )}
    </CopyToClipboard>
  );
};
