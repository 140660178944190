import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { IRadioProps } from './Radio.types';

export const RadioContainer = styled.div`
  display: inline-flex;
  ${space}
  ${css<IRadioProps>`
    align-items: ${({ alignItems }) => alignItems ?? 'center'};
  `}
`;

const borderColorStyle = css<IRadioProps>`
  border-color: ${({ theme, disabled, checked }) => {
    if (disabled) {
      return theme.radio.border.disabled;
    }

    return checked ? theme.radio.border.checked : theme.radio.border.default;
  }};
`;

const outerBackgroundStyle = css<IRadioProps>`
  background: ${({ disabled, theme, checked }) => {
    if (disabled) {
      return theme.radio.background.disabled;
    }

    return checked ? theme.radio.background.checked : theme.radio.background.default;
  }};
`;

const innerBackgroundStyle = css<IRadioProps>`
  background: ${({ disabled, theme, checked }) => {
    if (checked) {
      return theme.radio.innerCircle.checked;
    }

    return disabled ? theme.radio.innerCircle.disabled : theme.radio.innerCircle.default;
  }};
`;

export const RadioOuterCircle = styled.div<IRadioProps>`
  display: flex;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  border: 1px solid;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  :focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.radio.focusColor};
  }
  transition: all 0.1s ease-in-out;
  ${props => props.disabled && 'cursor: not-allowed;'}
  ${space}
  ${outerBackgroundStyle}
  ${borderColorStyle}
`;

export const RadioInnerCircle = styled.div<IRadioProps>`
  width: 6px;
  height: 6px;
  box-sizing: border-box;
  border-radius: 50%;
  ${innerBackgroundStyle}
`;

export const RadioButtonLabel = styled.label<IRadioProps>`
  display: inline-block;
  cursor: pointer;
  & span:not(:last-child) {
    margin-right: 2px;
  }
  ${({ theme, disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      color: ${theme.colors.gray[400]};
    `};
  ${space}
`;

export const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`;
