import { bluePalette, grayPalette } from '../../themes/colors';

export const radioLightTheme = {
  focusColor: bluePalette[300],
  border: {
    checked: bluePalette[700],
    default: grayPalette[400],
    disabled: grayPalette[400],
  },
  background: {
    checked: bluePalette[700],
    default: 'white',
    disabled: grayPalette[300],
  },
  innerCircle: {
    checked: 'white',
    default: 'white',
    disabled: grayPalette[300],
  },
};
