import styled, { css } from 'styled-components';
import { FunctionComponent } from 'react';
import { Link } from '../../typography';
import { Icon } from '../../icons/Icon.component';
import { StyledInput } from '../Input.styles';
import { Flex } from '../../containers/Flex/Flex.component';
import { Group } from '../../containers/Group/Group.component';

const borderRadius = 'border-radius: 3px 15px 15px 3px;';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.search.clearIndicatorColor};
  pointer-events: visible;
`;

export const CompositeInputActionContainer = styled(Group)`
  pointer-events: visible;
`;

export const ClearIndicator: FunctionComponent<{ onClick(): void; subject?: string }> = ({
  onClick,
  subject = 'clear-indicator',
}) => (
  <StyledLink onClick={onClick} subject={subject}>
    <Icon icon={'xmark-regular'} size={12} />
  </StyledLink>
);

export const CompositeInput = styled(({ pr, ...props }) => <StyledInput {...props} />)`
  background-color: ${({ theme, disabled }) => !disabled && theme.search.backgroundColor};
  ${({ pr }) =>
    css`
      padding-right: ${pr};
    `}
  font-size: 14px;
  ${borderRadius}
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

export const CompositeInputContainer = styled(Flex)`
  height: fit-content;
  position: relative;
  ${borderRadius};
`;
