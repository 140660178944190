import { ContainerBorderWidth, ContainerSize } from '../../containers/Container/container.enum';
import { containerLightV2Theme } from '../../containers/Container/themes/light-v2.theme';

export const container = {
  sizes: {
    [ContainerSize.Small]: '800px',
    [ContainerSize.Medium]: '1024px',
  },
  borderWidths: {
    [ContainerBorderWidth.Small]: '1px',
    [ContainerBorderWidth.Medium]: '2px',
    [ContainerBorderWidth.Large]: '3px',
  },
  ...containerLightV2Theme,
};
