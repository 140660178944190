import {
  Table,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { IDataTableProps } from '../types';

type UseDataTableProps<T> = {
  enableFilters: IDataTableProps<T>['enableFilters'];
  initialState: IDataTableProps<T>['initialState'];
  tableData: IDataTableProps<T>['data'];
  tableColumns: IDataTableProps<T>['columns'];
  state: IDataTableProps<T>['state'];
  onSortingChange: IDataTableProps<T>['onSortingChange'];
  onGlobalFilterChange: IDataTableProps<T>['onGlobalFilterChange'];
  onColumnVisibilityChange: IDataTableProps<T>['onColumnVisibilityChange'];
  onExpandedChange: IDataTableProps<T>['subRows']['onExpandedChange'];
  getRowCanExpand: IDataTableProps<T>['getRowCanExpand'];
};

type UseDataTable = <T extends object>(props: UseDataTableProps<T>) => Table<T>;

export const useDataTable: UseDataTable = ({
  enableFilters,
  initialState,
  tableData,
  tableColumns,
  state,
  onSortingChange,
  onGlobalFilterChange,
  onColumnVisibilityChange,
  onExpandedChange,
  getRowCanExpand,
}) => {
  const table = useReactTable({
    enableFilters,
    autoResetExpanded: false,
    initialState,
    data: tableData,
    columns: tableColumns,
    state,
    defaultColumn: { minSize: 1 },
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: row => (row as any).subRows,
    ...(onSortingChange && { onSortingChange }),
    ...(onGlobalFilterChange && { onGlobalFilterChange }),
    ...(onColumnVisibilityChange && { onColumnVisibilityChange }),
    ...(onExpandedChange && { onExpandedChange }),
    columnResizeMode: 'onChange',
    debugTable: window.location.hostname.includes('local'),
  });

  return table;
};
