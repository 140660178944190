import { merge } from 'lodash';
import { filterDarkThemeV2 } from '../Filter/themes/dark-v2.theme';
import { badgeDarkTheme } from '../Badge/themes/dark-v2.theme';
import { buttonDarkTheme } from '../Button/themes/dark-v2.theme';
import { cardDarkTheme } from '../Card/themes/dark.theme';
import { containerDarkV2Theme } from '../containers/Container/themes/dark-v2.theme';
import { drawerDarkV2Theme } from '../Drawer/themes/dark-v2.theme';
import { menuDarkV2Theme } from '../menu/themes/dark-V2.theme';
import { modalDarkV2Theme } from '../Modal/themes/dark.theme';
import { secondarySidenavDarkTheme } from '../Sidenav/Secondary/themes/dark-v2.theme';
import { textDarkV2Theme } from '../typography/Text/themes/dark-v2.theme';
import { tabsDarkTheme } from '../Tabs/themes/dark-v2.theme';
import { lightV2Theme } from './light-v2.theme';

// Supporting base V1 theme + V2 overrides.
export const darkV2Theme = merge({}, lightV2Theme, {
  name: 'dark',
  text: textDarkV2Theme,
  container: containerDarkV2Theme,
  badge: badgeDarkTheme,
  button: buttonDarkTheme,
  drawer: drawerDarkV2Theme,
  modal: modalDarkV2Theme,
  card: cardDarkTheme,
  shadows: {
    none: 'none',
    line: '0 1px 0px 0 rgba(0, 0, 0)',
    small: '0 2px 6px 0 rgba(0, 0, 0, 0.5)',
    large: '0 10px 10px rgba(0, 0, 0, 0.3), 0 5px 25px rgba(0, 0, 0, 0.3)',
  },
  backdrop_background_color: 'rgba(0, 0, 0, 0.5)',
  secondarySidenav: secondarySidenavDarkTheme,
  menu: menuDarkV2Theme,
  tabs: tabsDarkTheme,
  multiFilter: filterDarkThemeV2,
});
