import { bluePalette, grayPalette, royalBluePalette } from '../../../themes/colors';
import { ISelectTheme } from './theme';

export const selectLightV2Theme: ISelectTheme = {
  variants: {
    primary: {
      valueContainer: {
        textColor: royalBluePalette[1000],
      },
      placeholder: {
        textColor: grayPalette[700],
      },
      focused: {
        borderColor: royalBluePalette[1000],
      },
      hover: {
        borderColor: bluePalette[700],
      },
      menu: {
        textColor: royalBluePalette[1000],
        borderColor: grayPalette[300],
        backgroundColor: grayPalette[100],
        focusedOption: {
          backgroundColor: grayPalette[300],
        },
        selectedOption: {
          backgroundColor: bluePalette[300],
        },
        group: {
          borderColor: grayPalette[300],
          textColor: royalBluePalette[1000],
        },
        empty: {
          textColor: grayPalette[700],
        },
        disabledOption: {
          textColor: grayPalette[500],
        },
      },
      indicator: {
        iconColor: grayPalette[600],
        hover: {
          iconColor: grayPalette[800],
        },
        clear: {
          borderColor: grayPalette[500],
        },
      },
      multiValue: {
        container: {
          borderColor: grayPalette[400],
          backgroundColor: grayPalette[300],
        },
      },
      popoveredSelect: {
        button: {
          backgroundColor: grayPalette[100],
          borderColor: grayPalette[300],
          hover: { borderColor: bluePalette[700] },
        },
      },
    },
    secondary: {
      valueContainer: {
        textColor: royalBluePalette[800],
      },
      placeholder: {
        textColor: grayPalette[500],
      },
      focused: {
        borderColor: grayPalette[100],
      },
      hover: {
        borderColor: bluePalette[700],
      },
      menu: {
        textColor: grayPalette[100],
        borderColor: bluePalette[1000],
        backgroundColor: bluePalette[1000],
        focusedOption: {
          backgroundColor: bluePalette[800],
        },
        selectedOption: {
          backgroundColor: bluePalette[900],
        },
        group: {
          borderColor: bluePalette[900],
          textColor: grayPalette[100],
        },
        empty: {
          textColor: grayPalette[100],
        },
        disabledOption: {
          textColor: grayPalette[800],
        },
      },
      indicator: {
        iconColor: grayPalette[600],
        hover: {
          iconColor: grayPalette[800],
        },
        clear: {
          borderColor: grayPalette[300],
        },
      },
      multiValue: {
        container: {
          borderColor: grayPalette[400],
          backgroundColor: bluePalette[800],
        },
      },
      popoveredSelect: {
        button: {
          backgroundColor: grayPalette[100],
          borderColor: grayPalette[300],
          hover: { borderColor: bluePalette[700] },
        },
      },
    },
  },
};
