import React, { FunctionComponent, useContext, ReactNode } from 'react';
import { ThemeContext } from 'styled-components';
import { Text } from '../../typography';
import { Info } from '../../Info/Info.component';
import { IFieldContainerProps } from './interfaces';

const labelTextSize: Record<IFieldContainerProps['labelSize'], 10 | 14 | 18> = {
  s: 10,
  m: 14,
  l: 18,
};

const labelTextWeight: Record<IFieldContainerProps['labelSize'], 400 | 500> = {
  s: 400,
  m: 400,
  l: 500,
};

export const FieldLabel: FunctionComponent<
  Pick<IFieldContainerProps, 'labelSize' | 'required'> & { children: React.ReactNode }
> = ({ children, labelSize = 'm', required }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      {required && (
        <Text
          size={labelTextSize[labelSize]}
          weight={labelTextWeight[labelSize]}
          color={theme.fieldContainer?.requiredSignColor}
        >
          *
        </Text>
      )}
      <Text
        size={labelTextSize[labelSize]}
        weight={labelTextWeight[labelSize]}
        color={theme.fieldContainer?.label[labelSize]?.color}
      >
        {children}
      </Text>
    </>
  );
};

export const FieldDescription: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
  <Info tooltip={children} placement={'right'} />
);

export const FieldHelper: FunctionComponent<{ children: ReactNode }> = ({ children, ...props }) => {
  const theme = useContext(ThemeContext);

  return (
    <Text color={theme.fieldContainer?.helper?.color} size={12} {...props}>
      {children}
    </Text>
  );
};

export const FieldError: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const theme = useContext(ThemeContext);

  return (
    <Text color={theme.fieldContainer?.error?.color} size={12}>
      {children}
    </Text>
  );
};
