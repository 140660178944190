import { Title, Badge, Color, Icon } from '..';

export enum StepStatus {
  Done = 'Done',
  Current = 'Current',
  Next = 'Next',
}

interface IStepBadgeProps {
  stepNumber: number;
  stepStatus: StepStatus;
  size: 'm' | 'l';
}

interface IStepTitleProps {
  stepStatus: StepStatus;
  title: string;
  size?: IStepBadgeProps['size'];
}

type titleSizeType = 14 | 18;

const titleSize: Record<string, titleSizeType> = {
  m: 14,
  l: 18,
};

export const getStepStatus = ({ stepNumber, currentStep }: { stepNumber: number; currentStep: number }) => {
  if (stepNumber === currentStep) {
    return StepStatus.Current;
  }

  if (stepNumber < currentStep) {
    return StepStatus.Done;
  }

  return StepStatus.Next;
};

const statusToColor: Record<StepStatus, Color> = {
  [StepStatus.Done]: 'royalBlue.800',
  [StepStatus.Current]: 'royalBlue.1000',
  [StepStatus.Next]: 'gray.800',
};

export const StepBadge = ({ stepNumber, stepStatus, size }: IStepBadgeProps) => {
  const color = statusToColor[stepStatus] as Color;

  if (stepStatus === StepStatus.Done) {
    return <Badge value={<Icon icon={'check-solid'} size={titleSize[size]} />} color={color} outline size={size} />;
  }

  if (stepStatus === StepStatus.Current) {
    return <Badge value={stepNumber} color={color} size={size} />;
  }

  if (stepStatus === StepStatus.Next) {
    return <Badge value={stepNumber} color={color} outline size={size} />;
  }
};

export const StepTitle = ({ stepStatus, title, size }: IStepTitleProps) => (
  <Title size={titleSize[size]} color={statusToColor[stepStatus]}>
    {title}
  </Title>
);
