import React, { forwardRef, useMemo } from 'react';
import { Group } from '../containers/Group/Group.component';
import { Icon, IIconProps } from '../icons/Icon.component';
import { Spinner } from '../progress/Spinner/Spinner.component';
import { ISpinnerProps } from '../progress/Spinner/Spinner.types';
import { darkModeService } from '../themes/dark-mode.service';
import { Tooltip } from '../tooltip/Tooltip.component';
import { Text } from '../typography';

import { fontSize, StyledButton, TooltipContainer } from './Button.styles';
import { IButtonProps } from './Button.types';

const iconSize: Record<IButtonProps['size'], number> = {
  xs: 10,
  s: 12,
  m: 14,
  l: 14,
};

const spinnerSize: Record<IButtonProps['size'], ISpinnerProps['size']> = {
  xs: 'xs',
  s: 's',
  m: 'm',
  l: 'm',
};

function getIconComponent(icon: IIconProps['icon'] | JSX.Element, size: IButtonProps['size']): JSX.Element {
  return typeof icon === 'string' ? <Icon icon={icon} size={iconSize[size]} /> : icon;
}

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, IButtonProps>(
  (
    {
      disabled,
      children,
      variant = 'primary',
      size = 'm',
      icon,
      endIcon,
      loading,
      loadingText,
      disabledTooltip,
      width,
      tooltipPlacement,
      type = 'button',
      pulsate = false,
      href,
      subject,
      ...props
    },
    ref,
  ) => {
    const buttonElementType: 'a' | 'button' = href ? 'a' : 'button';

    const isIconOnly = !children && (Boolean(icon) || Boolean(endIcon));

    const accessibleLabel = useMemo(() => {
      if (typeof children === 'string') {
        return `${children}-button`;
      }

      if (subject) {
        return `${subject}-button`;
      }

      if (!children && typeof icon === 'string') {
        return `${icon}-button`;
      }

      return 'button';
    }, [children, subject, icon]);

    const content = useMemo(() => {
      if (children) {
        if (React.isValidElement(children)) return children;

        return (
          <Text noTextWrap weight={500} size={fontSize[size]} variant={null}>
            {children}
          </Text>
        );
      }

      return null;
    }, [children, size]);

    const buttonElement = (
      <StyledButton
        as={buttonElementType}
        ref={ref}
        disabled={disabled || loading}
        variant={variant}
        size={size}
        width={width}
        type={href ? undefined : type}
        pulsate={pulsate}
        className={variant === 'primary' ? darkModeService.manipulatorClass.invertStyles : null}
        iconOnly={isIconOnly}
        href={href}
        aria-disabled={disabled || loading ? 'true' : undefined}
        aria-label={accessibleLabel}
        role={href ? 'link' : 'button'}
        subject={subject}
        {...props}
      >
        <Group gap={1} alignItems="center" justifyContent="center" width="100%">
          {loading && loadingText && loadingText}
          {loading && <Spinner size={spinnerSize[size]} />}
          {!loading && icon && getIconComponent(icon, size)}
          {!loading && content}
          {!loading && endIcon && getIconComponent(endIcon, size)}
        </Group>
      </StyledButton>
    );

    if (disabled && disabledTooltip) {
      return (
        <Tooltip title={disabledTooltip} placement={tooltipPlacement}>
          <TooltipContainer>{buttonElement}</TooltipContainer>
        </Tooltip>
      );
    }

    return buttonElement;
  },
);

Button.displayName = 'Button';
