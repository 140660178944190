import { dateService } from '@logz-pkg/frontend-services/src/common/date.service';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { RelativeValue } from '@logz-pkg/utils';
import { InputMask } from '@react-input/mask';
import moment, { Moment } from 'moment-timezone';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatInputValue } from '../utils';

const StyledDateInput = styled.input.attrs(({ subject }: { subject: string }) =>
  generateLogzTestAttributes({ subject }),
)`
  width: 165px;
  padding: 0;
  margin-left: 6px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: ${({ theme }) => theme.datePicker.absolute.input.color};
`;

const inputMask = '__.__.____, __:__:__.___';
const maskReplacement = { _: /\d/ };
const dateFormat = 'DD.MM.YYYY, HH:mm:ss.SSS';

interface IAbsoluteFilterProps {
  placeholder: string;
  dateValue: Date | RelativeValue;
  onClick: () => void;
  onUpdate: (value: Date, switchSection: boolean) => void;
  subject: string;
  active: boolean;
  timezone?: string;
}

export const AbsoluteDateInput = React.memo<IAbsoluteFilterProps>(
  ({ placeholder, dateValue, onClick, onUpdate, subject, active, timezone }) => {
    const initialDateValue = formatInputValue(dateValue, timezone);
    const inputRef = useRef<HTMLInputElement>(null);
    const hasEditedRef = useRef(false);
    const enterKeyPressedRef = useRef(false);

    const [value, setValue] = useState<string>(initialDateValue);
    const isNowValue = value === 'Now';

    useEffect(() => {
      setValue(formatInputValue(dateValue, timezone));
    }, [dateValue, timezone]);

    useEffect(() => {
      if (inputRef.current && active) {
        inputRef.current.focus();

        if (isNowValue) {
          hasEditedRef.current = true;
        }
      }
    }, [active, isNowValue]);

    const handleUpdate = useCallback(
      (newDate: Moment, shouldSwitchSection: boolean) => {
        if (newDate.isValid() && hasEditedRef.current) {
          onUpdate(newDate.toDate(), shouldSwitchSection);
        } else if (!newDate.isValid()) {
          setValue(formatInputValue(dateValue, timezone));
        }

        hasEditedRef.current = false;
      },
      [dateValue, onUpdate, timezone],
    );

    const handleChange = useCallback((newValue: string) => {
      hasEditedRef.current = true;
      setValue(newValue);
    }, []);

    const onBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        if (enterKeyPressedRef.current) {
          enterKeyPressedRef.current = false;

          return;
        }

        handleUpdate(moment(e.target.value, dateFormat), false);
      },
      [handleUpdate],
    );

    const onKeyUpHandler = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          enterKeyPressedRef.current = true;
          handleUpdate(moment(e.currentTarget.value, dateFormat), true);
        }
      },
      [handleUpdate],
    );

    const handlePaste = useCallback(
      (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        const pastedText = event.clipboardData.getData('text');

        if (isNowValue) {
          handleChange('');
        }

        const isCorrectFormat = moment(pastedText, dateFormat, true).isValid();

        if (isCorrectFormat) {
          handleChange(pastedText);

          return;
        }

        const momentText = moment(pastedText, 'MMM DD HH:mm:ss.SSS');
        const formattedDate = dateService.format({
          date: momentText,
          format: dateFormat,
        });

        handleChange(formattedDate);
      },
      [handleChange, isNowValue],
    );

    return (
      <>
        {isNowValue ? (
          <StyledDateInput
            ref={inputRef}
            value="Now"
            readOnly
            onClick={() => {
              handleChange('');
              onClick();
            }}
            onPaste={handlePaste}
            onKeyUp={onKeyUpHandler}
            placeholder={placeholder}
            subject={subject}
          />
        ) : (
          <InputMask
            ref={inputRef}
            separate
            showMask
            mask={inputMask}
            replacement={maskReplacement}
            component={StyledDateInput}
            value={value}
            onClick={onClick}
            onChange={e => handleChange(e.target.value)}
            onPaste={handlePaste}
            placeholder={placeholder}
            subject={subject}
            onKeyUp={onKeyUpHandler}
            onBlur={onBlur}
          />
        )}
      </>
    );
  },
);

AbsoluteDateInput.displayName = 'AbsoluteDateInput';
