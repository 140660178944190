const base = ({ theme }) => ({
  color: theme.colors.yellow[600],
  textDecoration: 'none',
  '&:hover': {
    color: theme.colors.yellow[400],
    cursor: 'pointer',
  },
  '&:active': {
    color: theme.colors.yellow[200],
    cursor: 'pointer',
  },
});

const light = ({ theme }) => ({
  color: theme.colors.gray[0],
  fontFamily: theme.fonts.base,
  '&:hover': {
    color: theme.colors.yellow[400],
    cursor: 'pointer',
  },
});

export const link = {
  base,
  light,
};
