import styled, { css } from 'styled-components';
import { Button } from '../../../Button/Button.component';

type FilterButtonProps = {
  isActive: boolean;
  maxLabelWidth: number;
  minLabelWidth?: number;
  fullWidth?: boolean;
};

export const FilterButton = styled(Button)<FilterButtonProps>`
  height: 30px;
  max-width: ${({ maxLabelWidth }) => `${maxLabelWidth}px`};

  border: 1px solid ${({ theme }) => theme.searchFilters.button.inactive.border};
  background-color: ${({ theme }) => theme.searchFilters.button.inactive.backgroundColor};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      max-width: none;
    `}
  ${({ minLabelWidth }) =>
    minLabelWidth &&
    css`
      min-width: ${minLabelWidth}px;
    `}
  &:hover {
    background-color: ${({ theme }) => theme.searchFilters.button.inactive.hover.backgroundColor};
    border-color: ${({ theme }) => theme.searchFilters.button.inactive.hover.borderColor};
  }
  &:active {
    background-color: ${({ theme }) => theme.searchFilters.button.inactive.active.backgroundColor};
    border-color: ${({ theme }) => theme.searchFilters.button.inactive.active.borderColor};
  }

  &:focus {
    box-shadow: none;
  }

  ${({ isActive, disabled, theme }) =>
    isActive &&
    css`
      border: 1px solid ${theme.searchFilters.button.active.border};
      background-color: ${theme.searchFilters.button.active.backgroundColor};
      &:hover {
        background-color: ${!disabled && theme.searchFilters.button.active.hover.backgroundColor};
        border-color: ${!disabled && theme.searchFilters.button.active.hover.borderColor};
      }
      &:active {
        background-color: ${theme.searchFilters.button.active.active.backgroundColor};
        border-color: ${theme.searchFilters.button.active.active.borderColor};
      }
      &:focus {
        box-shadow: none;
      }
    `}
`;
