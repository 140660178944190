import React from 'react';
import ClickAwayListener from 'react-click-away-listener';
import styled from 'styled-components';
import { CustomCrumb } from '../types';

const StyledInput = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[400]};
  padding: 4px;

  &:focus {
    outline: none;
  }
`;

interface IEditInputProps {
  label: CustomCrumb['label'];
  onEdit: CustomCrumb['onEdit'];
  onEscape: () => void;
}

export const EditInput: React.FC<IEditInputProps> = ({ label, onEdit, onEscape }) => {
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onEdit(e.target.value);
      onEscape();
    } else if (e.key === 'Escape') {
      onEscape();
    }
  };

  return (
    <ClickAwayListener onClickAway={onEscape}>
      <StyledInput autoFocus defaultValue={label} onKeyDown={handleKeyDown} />
    </ClickAwayListener>
  );
};
