import React, { forwardRef, ReactNode, Ref } from 'react';
import styled, { css } from 'styled-components';
import {
  BackgroundColorProps,
  borderRadius,
  BorderRadiusProps,
  color,
  FlexboxProps,
  height,
  HeightProps,
  LayoutProps,
  MarginProps,
  maxWidth,
  MaxWidthProps,
  PaddingProps,
  shadow,
  space,
  width,
  WidthProps,
  PositionProps,
} from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Color } from '../themes';
import { Flex } from '../containers/Flex/Flex.component';
import {
  accentCardStyles,
  borderlessCardStyle,
  disabledCardStyles,
  highlightCardStyle,
  onClickStyle,
} from './Card.styles';
import { StyledLoadingCard, StyledLoadingGraph, StyledLoadingSpinner } from './Loading.card';

export interface ICardProps
  extends MarginProps,
    PaddingProps,
    BackgroundColorProps,
    HeightProps,
    WidthProps,
    BorderRadiusProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
    MaxWidthProps {
  shadow?: 'none' | 'small' | 'large';
  accentColor?: Color | string;
  accentLocation?: 'top' | 'right' | 'bottom' | 'left';
  accentWidth?: number;
  backgroundColor?: Color | string;
  borderColor?: Color | string;
  borderSize?: number;
  borderless?: boolean;
  highlight?: boolean;
  disabled?: boolean;
  subject?: string;
  boxShadow?: 'none' | 'small' | 'large';
  className?: string;
  onClick?(e): void;
  ref?: Ref<ReactNode>;
  context?: string;
  sticky?: boolean;
  loading?: boolean;
  loadingType?: 'passport' | 'graph' | 'spinner';
  children?: ReactNode;
}

interface IStyleCardProps extends ICardProps {
  $loading?: boolean;
  highlight?: boolean;
}

const StyledCard = styled(Flex)<IStyleCardProps>`
  border-radius: 3px;
  border: ${({ borderSize }) => borderSize || 1}px solid
    ${({ theme, borderColor }) => borderColor || theme.card.borderColor};
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.card.backgroundColor};

  ${({ $loading }) =>
    `${
      $loading
        ? css`
            opacity: 0.6;
          `
        : ''
    }`};

  ${disabledCardStyles}
  ${highlightCardStyle}
  ${borderlessCardStyle}
  ${accentCardStyles}
  ${props => onClickStyle({ ...props })}

  ${borderRadius}
  ${space}
  ${height}
  ${width}
  ${color}
  ${shadow}
  ${height}
  ${maxWidth}
`;

const mapLoadingType: Record<ICardProps['loadingType'], React.ReactNode> = {
  graph: <StyledLoadingGraph />,
  passport: <StyledLoadingCard />,
  spinner: <StyledLoadingSpinner />,
};

export const Card = forwardRef<HTMLDivElement, ICardProps>(
  (
    {
      accentLocation = 'top',
      accentWidth = 4,
      shadow = 'none',
      subject,
      borderRadius = 4,
      context,
      loading = false,
      loadingType = 'passport',
      p,
      children,
      ...props
    },
    ref,
  ) => {
    const loadingElem = mapLoadingType[loadingType] ?? mapLoadingType.passport;

    return (
      <StyledCard
        ref={ref}
        {...generateLogzTestAttributes({ context: context ?? 'Card', subject })}
        flexWrap={'wrap'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        accentLocation={accentLocation}
        accentWidth={accentWidth}
        boxShadow={shadow}
        borderRadius={borderRadius}
        p={loading ? 0 : p}
        $loading={loading}
        {...props}
      >
        {loading ? loadingElem : children}
      </StyledCard>
    );
  },
);

Card.displayName = 'Card';
