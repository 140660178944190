import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { get } from 'lodash';
import { InlineFlex } from '../../containers/Flex/Flex.component';
import { ITagProps } from '../Tag.component';
import { colors } from '../../themes/colors';

export const Container = styled(InlineFlex)<ITagProps>`
  align-items: center;
  padding: 0 5px;
  max-width: fit-content;
  transition: background-color 0.1s ease-in-out;

  ${({ variant, theme: { tag } }) => tag.variants[variant]}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}


  ${({ background }) => {
    return (
      background &&
      css`
        background: ${get(colors, background)};
      `
    );
  }}
  ${space}
`;

export const TagContainer: React.FC<PropsWithChildren<ITagProps>> = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>;
};
