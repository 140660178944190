export enum LogsToMetricsSubject {
  Container = 'l2m-container',
  DefinitionsPage = 'l2m-definitions-page',
  EditPage = 'l2m-edit-page',
  Form = 'l2m-form',
  Table = 'l2m-table',
  EmptyState = 'l2m-empty-state',
  RuleNameInput = 'l2m-rule-name-input',
  DimensionFieldSelect = 'l2m-dimension-field-select',
  CountLogsCheckbox = 'l2m-count-logs-checkbox',
  AggregateFieldCheckbox = 'l2m-aggregate-field-checkbox',
  FieldHistogramCheckbox = 'l2m-field-histogram-checkbox',
  AggregationMethodSelect = 'l2m-aggregation-method-select',
  AggregationFieldSelect = 'l2m-aggregation-field-select',
  DescriptionInput = 'l2m-description-input',
  LabelKeyInput = 'l2m-label-key-input',
  LabelValueInput = 'l2m-label-value-input',
  MetricsAccountSelect = 'l2m-metrics-account-select',
  SaveRuleButton = 'l2m-save-rule-button',
  EnableRuleNotification = 'l2m-enable-rule-notification',
  DisableRuleNotification = 'l2m-disable-rule-notification',
  CreateBlockedInlineNotification = 'l2m-create-blocked-inline-notification',
}

export enum LogsToMetricsAggregationMethod {
  Avg = 'AVG',
  Max = 'MAX',
  Min = 'MIN',
  Sum = 'SUM',
}
