import { colorService } from '@logz-pkg/utils';
import { colors, colorsV2 } from '@logz-ui/styleguide/components/themes';

const datePickerBackground = '#282828'; // This color is not in the styleguide palette

export const superDatePickerDarkTheme = {
  background: datePickerBackground,
  border: colors.royalBlue[900],
  boxShadow: colorService.convertHexToRgba(colors.gray[400], 0.3),
  tabs: {
    color: colors.royalBlue[600],
    activeColor: colors.gray[0],
    activeBorder: colors.yellow[600],
  },
  quick: {
    titleColor: colors.gray[0],
    linkColor: colors.gray[0],
    linkHoverColor: colors.gray[0],
    linkHoverBackground: 'transparent',
    linkSelectedColor: colors.gray[1000],
    linkSelectedBackground: colors.yellow[600],
  },
  absolute: {
    input: {
      backgroundColor: colors.gray[900],
      color: colors.gray[0],
      borderColor: colors.gray[300],
      activeBorderColor: colors.royalBlue[0],
    },
    today: {
      color: colors.gray[0],
      backgroundColor: colors.royalBlue[1000],
      borderColor: colors.royalBlue[800],
    },
  },
  time: {
    background: datePickerBackground,
    color: colors.gray[0],
    selectedItemColor: colors.gray[0],
    selectedItemBackground: colors.blue[900],
  },
  day: {
    color: colors.gray[0],
    colorInRange: colors.royalBlue[100],
    colorOutsideMonth: colors.gray[600],
    selectedBackground: colors.blue[500],
    inSelectingRangeBackground: colors.blue[500],
    inRangeBackground: colors.blue[800],
    rangeStartEndBackground: colors.blue[500],
    todayBackground: colors.yellow[600],
  },
  navigation: {
    currentMonthColor: colors.gray[0],
    iconColor: colors.gray[0],
    borderColor: colors.gray[700],
  },
  variants: {
    explore: {
      time: {
        selectedItemColor: colors.gray[0],
        selectedItemBackground: colorsV2.blue[500],
      },
      day: {
        colorInRange: colors.royalBlue[1000],
        selectedBackground: colorsV2.blue[100],
        inSelectingRangeBackground: colorsV2.blue[100],
        inRangeBackground: colorsV2.blue[100],
        rangeStartEndBackground: colorsV2.blue[500],
        highlightColor: colorsV2.blue[500],
      },
      quick: {
        linkSelectedBackground: colorsV2.blue[500],
        linkSelectedColor: colors.gray[0],
        linkColor: colorsV2.blue[500],
        linkHoverColor: colorsV2.blue[500],
      },
    },
  },
};
