import React, { FunctionComponent } from 'react';
import { Flex, FlexColumn, Group, Note, Timeline, TimelineItem, Title } from '..';

export interface IStepProps {
  size?: 's' | 'l';
  currentStep?: string;
  title: string | JSX.Element;
  note?: string;
  hint?: JSX.Element;
  subject?: string;
  gap: number;
  children?: React.ReactNode;
}

export const StepContainer: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  let itemNumber = 0;

  return (
    <Timeline>
      {React.Children.map(children, child => {
        if (!child) return null;

        itemNumber += 1;

        return React.cloneElement(child as React.ReactElement, {
          currentStep: itemNumber,
          subject: `step-${itemNumber}`,
        });
      })}
    </Timeline>
  );
};

export const Step: FunctionComponent<IStepProps> = ({
  size = 'l',
  currentStep,
  title,
  note,
  children,
  subject,
  hint,
  gap,
}) => (
  <TimelineItem size={size} text={currentStep} backgroundColor={'blue.800'}>
    <Group gap={5} subject={subject} vertical width={'100%'} fullHeight>
      <Flex justifyContent={'space-between'} width={'100%'}>
        <Group gap={4}>
          {typeof title === 'string' ? <Title size={size === 'l' ? 20 : 14}>{title}</Title> : title}
          {hint}
          {note && (
            <Note type="status" variant="info">
              {note}
            </Note>
          )}
        </Group>
      </Flex>
      <FlexColumn width={'100%'} height={'100%'}>
        <Group width={'100%'} flexGrow={1} gap={gap}>
          {children}
        </Group>
      </FlexColumn>
    </Group>
  </TimelineItem>
);
