import styled, { css } from 'styled-components';
import { width } from 'styled-system';
import { textSize } from '../themes';
import { space } from '../utils';
import { IInputProps } from './Input.component';

// TODO: Copy the shared styling so we can overhaul this.

const inputStyles: any = {
  styles: {
    minHeight: '30px',
    borderRadius: '3px',
    boxShadow: 'none',
    width: '100%',
    boxSizing: 'border-box',
    outline: 0,
    shadowSpread: 0,
  },
  borderless: {
    boxShadow: 'none',
    borderRadius: 0,
    border: 0,
  },
};

export type InputVariants = 'primary' | 'secondary';

export const baseInputCss = css<{
  borderless: boolean;
  noUnderline: boolean;
  error: boolean | string | JSX.Element;
  isDisabled: boolean;
  disabled: boolean;
  size: any;
  variant: InputVariants;
  iconSide: IInputProps['iconSide'];
}>`
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${textSize};
  transition: all 0.1s ease-in-out;
  ${inputStyles.styles};
  ${({ iconSide, theme, variant }) =>
    css`
      padding: ${iconSide === 'left' ? '0px 8px 0px 28px' : '0 8px'};
      ${theme.input.variants[variant].styles};
      border: 1px solid ${theme.input.variants[variant].styles.borderColor};
    `}

  ${space}
  ${width}

  ${({ theme, variant, borderless }) =>
    borderless &&
    css`
      ${inputStyles.borderless};
      border-bottom: 1px solid ${theme.input.variants[variant].borderless.borderColor};
      ${theme.input.variants[variant].borderless};
      background-color: transparent;
    `};
  ${({ noUnderline, variant }) =>
    noUnderline &&
    css`
      ${inputStyles.borderless};
      ${inputStyles.noUnderline};
      ${({ theme }) => theme.input.variants[variant].noUnderline};
      background-color: transparent;
    `};

  &::placeholder {
    ${({ theme, variant }) => theme.input.variants[variant].placeholder};
    font-weight: normal;
  }

  ${({ theme, variant, error }) =>
    error &&
    css`
      ${theme.input.variants[variant].error};
    `};

  ${({ isDisabled, disabled, variant }) =>
    (disabled || isDisabled) &&
    css`
      cursor: not-allowed;
      ${({ theme }) => theme.input.variants[variant].disabled};
      &:hover,
      &:active,
      &:focus {
        ${({ theme, variant }) => theme.input.variants[variant].disabled};
        box-shadow: none;
      }
    `};
`;

export const baseInputFocusCss = css<{ borderless: boolean; variant: InputVariants }>`
  ${({ theme, variant }) => theme.input.variants[variant].focus}
  ${({ theme, variant, borderless }) =>
    !borderless &&
    css`
      box-shadow: 0 0 ${inputStyles.styles.shadowSpread} 3px ${theme.input.variants[variant].active.shadowColor};
    `}
`;

export const baseInputHoverCss = css<{ borderless: boolean; variant: InputVariants }>`
  ${({ theme, variant }) => theme.input.variants[variant].hover};
  ${({ theme, variant, borderless }) =>
    borderless && `border-color: ${theme.input.variants[variant].borderless.hover.borderColor}`}
`;

export const baseInputErrorFocusCss = css<{ borderless: boolean; variant: InputVariants }>`
  ${({ theme, variant }) => theme.input.variants[variant].error};
  ${({ theme, variant, borderless }) =>
    !borderless &&
    css`
      box-shadow: 0 0 ${inputStyles.styles.shadowSpread} 2px ${theme.input.variants[variant].error.active.shadowColor};
    `}
`;

export const baseInputErrorHoverCss = css<{ borderless: boolean; variant: InputVariants }>`
  ${({ theme, variant }) => theme.input.variants[variant].error};
`;

export const inputCss = css<{
  centerText?: boolean;
  shrink?: boolean;
  borderless?: boolean;
  error?: boolean | string | JSX.Element;
  variant?: InputVariants;
}>`
  &:hover {
    ${baseInputHoverCss}
  }

  &:active,
  &:focus {
    ${baseInputFocusCss}
  }

  &:not(:focus):not(:placeholder-shown) {
    border-color: ${({ theme }) => theme.colors.blue[500]};
    background-color: ${({ theme }) => theme.colors.gray[0]};
    box-shadow: ${({ theme }) => `0 0 0 1px ${theme.colors.gray[400]} inset`};
  }

  ${({ error }) =>
    error &&
    css`
      &:active,
      &:focus {
        ${baseInputErrorFocusCss}
      }

      &:hover {
        ${baseInputErrorHoverCss}
      }
    `}

  ${({ shrink }) =>
    !shrink &&
    css`
      min-width: 194px;
    `};

  ${({ centerText }) =>
    centerText &&
    css`
      text-align: center;
    `};

  ${baseInputCss}
`;
export const StyledInput = styled.input`
  ${inputCss};
  height: ${({ height }) => height || '30px'};
  ${width}
`;
