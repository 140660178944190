import React, { FunctionComponent, useContext } from 'react';
import { FontWeightProps, SpaceProps } from 'styled-system';
import { ThemeContext } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Color } from '../../themes';
import { StyledTitle } from './Title.styles';

export interface ITitleProps extends SpaceProps {
  id?: string;
  color?: Color | (string & { fromColor?: any });
  weight?: 300 | 400 | 500 | 700 | FontWeightProps['fontWeight'];
  size?: 12 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 35 | 48;
  uppercase?: boolean;
  underline?: boolean;
  inline?: boolean;
  subject?: string;
  className?: string;
  variant?: string;
  children: React.ReactNode;
}

export const Title: FunctionComponent<ITitleProps> = ({
  size = 18,
  children,
  weight,
  subject = 'title',
  variant,
  color,
  ...props
}) => {
  const {
    title: { variants, fontWeight },
  } = useContext(ThemeContext);

  return (
    <StyledTitle
      color={color || variants[variant]?.color}
      fontWeight={(weight || variants[variant]?.fontWeight) ?? fontWeight}
      fontSize={variant ? variants[variant]?.fontSize : size}
      {...generateLogzTestAttributes({ context: 'title', subject })}
      {...props}
    >
      {children}
    </StyledTitle>
  );
};

Title.displayName = 'Title';
