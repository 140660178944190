export enum SidenavSubjects {
  FixedSidenavContainer = 'fixed-sidenav-container',
  ExpandedSidenavContainer = 'expanded-sidenav-container',
  MainItemLinkPrefix = 'main-item-',
  ExpandedHeaderPlanType = 'expanded-sidenav-plan-type',
  ExpandedHeaderTitle = 'expanded-sidenav-title',
  ExpandedHeaderUsername = 'expanded-sidenav-username',
  ExpandedHeaderRole = 'expanded-sidenav-role',
  LogoutButton = 'logout-button',
  SubItemPrefix = 'side-nav-sub-item-',
  CloseMenu = 'close-sidenav-menu',
  ThemeSelector = 'theme-selector',
}

export enum HeaderSubjects {
  TurnOnNewNavigationButton = 'turn-on-new-navigation',
  PageTitle = 'header-title',
  Header = 'header-container',
  HelpMenu = 'help-menu',
  HelpButton = 'help-button',
  AnnouncementBadge = 'announcement-badge',
  WhatsNewButton = 'whats-new',
  WhatsNewContainer = 'whats-new-container',
  AccountSelectContainer = 'account-select-container',
  AccountSelect = 'account-select',
  AccountNameText = 'logged-in-account-name',
  WhatsNewEmptyState = '',
  NotificationBadge = 'notification-badge',
  LoadingAnnouncements = 'loading-announcements',
  AnnouncementList = 'announcements-list',
  WhatsNewSearch = 'whats-new-search',
  WhatNewEmptyState = 'whats-new-empty-state',
  CloseWhatsNew = 'close-whats-new',
  UnreadAnnouncementIndicator = 'unread-indicator',
  AnnouncementContainer = 'announcementsContainer',
  OpenChatDrawerButton = 'open-chat-drawer-button',
}

export enum NavigationSubjects {
  SwitchAccountToast = 'switch-account',
}

export enum ProductKeys {
  App360 = 'App360',
  Home = 'Home',
  Spm = 'Spm',
  Logs = 'logsProduct',
  Metrics = 'Metrics',
  Siem = 'siemProduct',
  Tracing = 'tracingProduct',
  Settings = 'settings',
  K8S360 = 'K8S360',
  Explore = 'Explore',
  DataHub = 'DataHub',
  Integrations = 'Integrations',
  DashboardsHub = 'DashboardsHub',
  None = '',
}
