import React, { useEffect, useRef } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';
import { Row as RTRow } from '@tanstack/react-table';
import { Row } from '../Row';
import { STATIC_DATA_ROW_STYLES } from '../../constants';
import { IDataTableProps, TableVirtualizer } from '../../types';
import { colorsV2 } from '../../../../themes';
import { SubComponentBodyCell } from './SubComponentBodyCell';

interface ISubComponentRowProps<T> {
  virtualRow: VirtualItem<Element>;
  parentRowHeight: number;
  virtualizer: TableVirtualizer;
  ExpandedRowComponent: IDataTableProps<T>['ExpandedRowComponent'];
  row: RTRow<T>;
  tableRef: React.MutableRefObject<HTMLDivElement>;
}

export const SubComponentRow = <T extends object>({
  virtualRow,
  parentRowHeight,
  virtualizer,
  ExpandedRowComponent,
  row,
  tableRef,
}: ISubComponentRowProps<T>) => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (!virtualRow) return;

    const observer = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        if (!rowRef.current) return;

        const elementHeight = rowRef.current.getBoundingClientRect().height;

        const calculatedSize = elementHeight + parentRowHeight;

        if (virtualRow.size === calculatedSize) return;

        virtualizer.resizeItem(virtualRow.index, calculatedSize);
      });
    });

    observer.observe(rowRef.current);

    return () => {
      observer.disconnect();
    };
  }, [virtualRow, parentRowHeight]);

  return (
    <Row
      style={{
        ...(virtualRow && {
          ...STATIC_DATA_ROW_STYLES,
          transform: `translateY(${virtualRow.start + parentRowHeight}px)`,
        }),
        backgroundColor: colorsV2.gray[200],
      }}
      disableHover
      ref={rowRef}
      key={row.id}
      subject={`sub-component-row`}
    >
      <SubComponentBodyCell tableRef={tableRef}>
        <ExpandedRowComponent row={row} />
      </SubComponentBodyCell>
    </Row>
  );
};
