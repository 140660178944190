import { opacity } from '../../utils';
import { colors } from '../colors';
import { sizes } from './sizes';

export const tabHeights = {
  s: '31px',
  m: '34px',
  l: '36px',
};

export const tabs = {
  helper: {
    color: {
      selected: colors.orange[600],
      disabled: opacity(colors.gray[800], 70),
      default: opacity(colors.gray[800], 70),
    },
    fontSize: {
      small: sizes.text.mini,
      default: sizes.text.tiny,
    },
    fontWeight: {
      s: sizes.fontWeight.normal,
      m: sizes.fontWeight.bold,
      l: sizes.fontWeight.bold,
    },
    marginLeft: '4px',
  },
  tab: {
    fontSize: {
      s: '16px',
      m: '18px',
      l: '20px',
    },
    fontWeight: {
      s: sizes.fontWeight.normal,
      m: sizes.fontWeight.bold,
      l: sizes.fontWeight.bold,
    },
    lineHeight: {
      small: '16px',
      medium: '21px',
      large: '24px',
    },
    color: {
      selected: colors.gray[900],
      default: colors.gray[800],
      disabled: colors.gray[800],
    },
    padding: '8px 24px',
  },
  tabList: {
    borderBottom: `4px solid ${colors.gray[500]}`,
    height: size => tabHeights[size],
  },
  indicator: {
    backgroundColor: colors.gray[900],
    size: 4,
  },
  container: {
    minWidth: '160px',
  },
};
