export enum MultiSearchControlsSubject {
  Cancel = 'cancel-filter-selection',
  Apply = 'apply-filter-selection',
  SelectAll = 'select-all-options',
  Clear = 'clear-selected-options',
}

export enum SearchFiltersClearButtonSubject {
  Clear = 'clear-filters',
}

export enum SearchFilterType {
  Boolean = 'Boolean',
  Toggle = 'Toggle',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  DateRangePicker = 'DateRangePicker',
  SuperDateRangePicker = 'SuperDateRangePicker',
}
export enum SearchQueryFilterSubjects {
  MultiFilterEdit = 'multi-filter-edit',
  MultiFilter = 'multi-filter-element',
}
