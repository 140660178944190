import React, { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { generateLogzTestAttributes, buildTestAttribute } from '@logz-pkg/test-selectors';
import { opacity } from '../../utils';

export const TableContext = React.createContext({});

interface ITableProps {
  children: ReactNode;
  loading?: boolean;
  columnWidths?: string[] | number[];
  columnIsFixedWidths?: boolean[];
  inheritSize?: boolean;
  subject?: string;
}

const TableContainer = styled.div.attrs<{ subject: string }>(props =>
  generateLogzTestAttributes({
    context: props[buildTestAttribute('context')] || 'table',
    subject: props.subject,
  }),
)<{ inheritSize?: boolean }>`
  background: ${({ theme }) => theme.colors.background};
  font-size: ${({ theme }) => theme.sizes.text.medium};
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-spacing: 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};

  ${({ inheritSize }) =>
    inheritSize &&
    css`
      flex: inherit;
      height: inherit;
      width: inherit;
    `}
`;

const TableBodyOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${({ theme }) => opacity(theme.colors.gray[200], 70)};
`;

export const Table: FunctionComponent<ITableProps> = ({
  children,
  loading = false,
  columnWidths,
  columnIsFixedWidths = [],
  ...props
}) => (
  <TableContainer {...props}>
    <TableContext.Provider
      value={{
        columnWidths,
        columnIsFixedWidths,
        loading,
      }}
    >
      {loading && <TableBodyOverlay />}
      {children}
    </TableContext.Provider>
  </TableContainer>
);

Table.displayName = 'Table';
