import { bluePalette, grayPalette, royalBluePalette } from '../../themes/colors';

export const searchFiltersLightThemeV2 = {
  clearFilter: {
    hover: {
      backgroundColor: royalBluePalette['100'],
    },
    active: {
      backgroundColor: royalBluePalette['200'],
    },
  },
  button: {
    inactive: {
      border: grayPalette[300],
      backgroundColor: grayPalette[100],
      icon: {
        color: grayPalette[500],
      },
      label: {
        color: grayPalette[700],
      },
      active: {
        backgroundColor: royalBluePalette[200],
        borderColor: royalBluePalette[300],
      },
      hover: {
        backgroundColor: royalBluePalette[100],
        borderColor: royalBluePalette[300],
      },
    },
    active: {
      border: bluePalette[500],
      backgroundColor: grayPalette[0],
      hover: {
        backgroundColor: royalBluePalette['100'],
        borderColor: bluePalette['500'],
      },
      active: {
        backgroundColor: royalBluePalette['200'],
        borderColor: bluePalette['500'],
      },
      icon: {
        color: bluePalette[700],
      },
      label: {
        color: bluePalette[700],
      },
    },
  },
  popover: {
    select: {
      backgroundColor: grayPalette[100],
      input: {
        backgroundColor: bluePalette[100],
        hover: {
          notFocus: {
            borderColor: bluePalette[700],
          },
        },
        placeholder: {
          color: royalBluePalette[1000],
        },
        icon: {
          color: royalBluePalette[1000],
        },
      },
    },
    menu: {
      borderTop: grayPalette[300],
      list: {
        backgroundColor: grayPalette[100],
      },
    },
  },
};
