import { colors } from '../../themes/colors';
import { tagVariants } from './Tag.variants';

export const tagLightV2Theme = {
  variants: tagVariants,
  fieldFilter: {
    borderColor: { active: colors.blue[600], negate: colors.red[600], invalid: colors.gray[600] },
    backgroundColor: {
      default: colors.gray[0],
      hover: {
        active: colors.blue[200],
        negate: colors.red[100],
      },
    },
    color: {
      default: colors.royalBlue[1000],
      invalid: colors.gray[600],
    },
  },
  severity: {
    color: colors.gray[0],
  },
};
