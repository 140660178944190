export enum DateFormat {
  DashedDateShortTime = 'YYYY-MM-DD HH:mm A',
  DashedDateTimeFull = 'MMM-DD-YYYY HH:mm:ss',
  DashedDate = 'MMM-DD-YYYY',
  DateTime = 'MMM DD, YYYY, h:mm A',
  Date = 'MMM DD, YYYY',
  MonthDay = 'MMM DD',
  MonthSingleDigitDay = 'MMM D',
  MonthYear = 'MMMM YYYY',
  LocalizedShortFull = 'llll', // Thu, Sep 4, 1986 8:30 PM
}
