import React from 'react';
import { FileInput, IFileUploadProps } from './FileInput';

export interface IImageFile {
  dataURL: string;
  file: File;
}

interface IImageUploadProps extends IFileUploadProps {
  onChange(file: IImageFile): void;
}

const getBase64 = (file: File): Promise<string> => {
  const reader = new FileReader();

  return new Promise(resolve => {
    reader.addEventListener('load', () => resolve(String(reader.result)));

    if (file) reader.readAsDataURL(file);
  });
};

const getImageFileFromList = async (files: FileList): Promise<IImageFile> => {
  const base64 = await getBase64(files[0]);

  return {
    dataURL: base64,
    file: files[0],
  };
};

export const ImageInput: React.FunctionComponent<IImageUploadProps> = ({ onChange, children, ...props }) => {
  const selectHandler = async event => {
    const file = await getImageFileFromList(event.target.files);

    onChange(file);
  };

  return (
    <FileInput {...props} onSelect={selectHandler} fileType="image/png">
      {children}
    </FileInput>
  );
};
