import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { color } from 'styled-system';
import { Flex } from '../containers/Flex/Flex.component';
import { Text } from '../typography';
import { Group } from '../containers/Group/Group.component';
import { IIconProps, Icon } from '../icons/Icon.component';
import { Color } from '../themes';

export const sizes = {
  s: { itemGap: 3, contentGap: 2, contentDiameter: 24, textSize: 14 },
  l: { itemGap: 6, contentGap: 4, contentDiameter: 34, textSize: 16 },
};

const Content = styled(({ size, icon, text, ...props }) => (
  <Flex {...props}>
    {icon && <Icon icon={icon} color={'white'} size={size === 'l' ? 18 : 14} />}
    {text && (
      <Text color={'white'} weight={500} size={sizes[size].textSize}>
        {text}
      </Text>
    )}
  </Flex>
))<{ background: string }>`
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${({ size }) => sizes[size].contentDiameter}px;
  height: ${({ size }) => sizes[size].contentDiameter}px;
  ${color}
`;

interface ITimelineItemProps {
  size?: 's' | 'l';
  icon?: IIconProps['icon'];
  text?: string;
  backgroundColor: Color | string;
  subject?: string;
  children: React.ReactNode;
}

export const TimelineItem: FunctionComponent<ITimelineItemProps> = ({
  size = 'l',
  icon,
  backgroundColor,
  text,
  children,
  subject,
}) => {
  return (
    <Group gap={sizes[size].contentGap} context={'timeline-item'} subject={subject} width={'100%'}>
      <Content size={size} icon={icon} text={text} backgroundColor={backgroundColor} />
      {children}
    </Group>
  );
};
