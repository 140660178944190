import { yellowPalette, royalBluePalette, grayPalette, bluePalette } from '../../themes/colors';

export const tabsDarkTheme = {
  indicator: {
    backgroundColor: yellowPalette[600],
    size: 3,
  },
  tab: {
    color: {
      selected: yellowPalette[600],
      disabled: royalBluePalette[700],
      default: grayPalette[500],
    },
    hover: {
      color: grayPalette[700],
    },
  },
  tabList: {
    borderBottom: `3px solid ${bluePalette[300]}`,
  },
  helper: {
    color: {
      selected: yellowPalette[600],
      disabled: royalBluePalette[700],
      default: grayPalette[500],
    },
  },
  container: {
    minWidth: 0,
  },
};
