/* eslint-disable max-lines */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */

import React, { forwardRef, useImperativeHandle } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isNil } from 'lodash';
import { ResizeContainer, ResizerHandle, FirstContainer, SecondContainer } from './SplitContainer.styles';

type ContentProps = {
  element: React.ReactNode;
  minSize?: number;
  maxSize?: number;
  onResize?: (height: number) => void;
};

interface ISplitContainerProps {
  vertical?: boolean;
  first?: ContentProps;
  second?: ContentProps;
  initialPosition?: string | number;
  subject?: string;
  resetToMaxSize?: boolean;
  resizeHandleColor?: React.CSSProperties['backgroundColor'];
  handleResetSizes?: () => void;
  emitWindowResizeEvent?: boolean;
}

export const SplitContainer = forwardRef<ISplitContainerProps, ISplitContainerProps>(
  (
    {
      vertical,
      first,
      second,
      subject,
      initialPosition,
      resetToMaxSize,
      resizeHandleColor,
      emitWindowResizeEvent = true,
    },
    ref,
  ) => {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const firstRef = React.useRef<HTMLDivElement | null>(null);
    const secondRef = React.useRef<HTMLDivElement | null>(null);
    const resizerRef = React.useRef<HTMLDivElement | null>(null);

    const updateWidth = (currentLeftWidth, dx) => {
      const container = containerRef.current;
      const firstEle = firstRef.current;

      if (!container || !firstEle) {
        return;
      }

      const containerWidth = container.getBoundingClientRect().width;
      const delta = currentLeftWidth + dx;
      const newFirstHalfWidth = (delta * 100) / containerWidth;

      firstEle.style.width = `${newFirstHalfWidth}%`;
    };

    const updateHeight = (currentFirstHeight, dy) => {
      const container = containerRef.current;
      const firstEle = firstRef.current;

      if (!container || !firstEle) {
        return;
      }

      const containerHeight = container.getBoundingClientRect().height;
      const delta = currentFirstHeight + dy;
      const newFirstHalfHeight = (delta * 100) / containerHeight;

      firstEle.style.height = `${newFirstHalfHeight}%`;
    };

    const updateCursor = () => {
      const container = containerRef.current;
      const firstEle = firstRef.current;
      const resizerEle = resizerRef.current;
      const secondEle = secondRef.current;

      if (!container || !firstEle || !resizerEle || !secondEle) {
        return;
      }

      if (emitWindowResizeEvent) {
        window.dispatchEvent(new Event('resize'));
      }

      if (vertical) {
        resizerEle.style.cursor = 'ns-resize';
        document.body.style.cursor = 'ns-resize';
      } else {
        resizerEle.style.cursor = 'ew-resize';
        document.body.style.cursor = 'ew-resize';
      }

      firstEle.style.userSelect = 'none';
      firstEle.style.pointerEvents = 'none';
      secondEle.style.userSelect = 'none';
      secondEle.style.pointerEvents = 'none';
    };

    const resetCursor = () => {
      const container = containerRef.current;
      const firstEle = firstRef.current;
      const resizerEle = resizerRef.current;
      const secondEle = secondRef.current;

      if (!container || !firstEle || !resizerEle || !secondEle) {
        return;
      }

      if (vertical) {
        first.onResize?.(firstRef.current.getBoundingClientRect().height);
        second.onResize?.(secondRef.current.getBoundingClientRect().height);
      } else {
        first.onResize?.(firstRef.current.getBoundingClientRect().width);
        second.onResize?.(secondRef.current.getBoundingClientRect().width);
      }

      resizerEle.style.removeProperty('cursor');
      document.body.style.removeProperty('cursor');
      firstEle.style.removeProperty('user-select');
      firstEle.style.removeProperty('pointer-events');
      secondEle.style.removeProperty('user-select');
      secondEle.style.removeProperty('pointer-events');
    };

    const handleMouseDown = React.useCallback((e: React.MouseEvent) => {
      const startPos = {
        x: e.clientX,
        y: e.clientY,
      };

      const currentFirstHeight = firstRef.current.getBoundingClientRect().height;
      const currentLeftWidth = firstRef.current.getBoundingClientRect().width;

      const handleMouseMove = (e: React.MouseEvent) => {
        if (vertical) {
          const dy = e.clientY - startPos.y;

          updateHeight(currentFirstHeight, dy);
        } else {
          const dx = e.clientX - startPos.x;

          updateWidth(currentLeftWidth, dx);
        }

        updateCursor();
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove as any);
        document.removeEventListener('mouseup', handleMouseUp);
        resetCursor();
      };

      document.addEventListener('mousemove', handleMouseMove as any);
      document.addEventListener('mouseup', handleMouseUp);
    }, []);

    const handleTouchStart = React.useCallback((e: React.TouchEvent) => {
      const touch = e.touches[0];
      const startPos = {
        x: touch.clientX,
        y: touch.clientY,
      };

      const currentFirstHeight = firstRef.current.getBoundingClientRect().height;
      const currentLeftWidth = firstRef.current.getBoundingClientRect().width;

      const handleTouchMove = (e: React.TouchEvent) => {
        const touch = e.touches[0];

        if (vertical) {
          const dy = touch.clientY - startPos.y;

          updateHeight(currentFirstHeight, dy);
        } else {
          const dx = touch.clientX - startPos.x;

          updateWidth(currentLeftWidth, dx);
        }

        updateCursor();
      };

      const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove as any);
        document.removeEventListener('touchend', handleTouchEnd);
        resetCursor();
      };

      document.addEventListener('touchmove', handleTouchMove as any);
      document.addEventListener('touchend', handleTouchEnd);
    }, []);

    const handleResetSizes = () => {
      const container = containerRef.current;
      const firstEle = firstRef.current;
      const secondEle = secondRef.current;

      if (!container || !firstEle || !secondEle) {
        return;
      }

      if (vertical) {
        const containerHeight = container.getBoundingClientRect().height;
        let newFirstHalfHeight;
        let newSecondHalfHeight;

        if (typeof initialPosition === 'string') {
          newFirstHalfHeight = (parseFloat(initialPosition) / 100) * containerHeight;
          newSecondHalfHeight = containerHeight - newFirstHalfHeight;
        } else if (typeof initialPosition === 'number') {
          newFirstHalfHeight = initialPosition;
          newSecondHalfHeight = containerHeight - newFirstHalfHeight;
        } else {
          if (resetToMaxSize) {
            const eleKids = firstEle.children[0].children;
            const targetHeight = Array.from(eleKids?.[0]?.children ?? eleKids).reduce((acc, cur) => {
              const totalHeight = cur.scrollHeight;

              return acc + totalHeight;
            }, 0);

            if (containerHeight - targetHeight - (second.minSize ?? 0) >= 0) {
              newFirstHalfHeight = targetHeight;
            } else {
              newFirstHalfHeight = containerHeight - (second.minSize ?? 0);
            }
          } else {
            newFirstHalfHeight = containerHeight / 2;
          }

          newSecondHalfHeight = containerHeight - newFirstHalfHeight;
        }

        if (isNil(initialPosition)) firstEle.style.height = ``;
        else firstEle.style.height = `${newFirstHalfHeight}px`;

        secondEle.style.height = `${newSecondHalfHeight}px`;

        first.onResize?.(newFirstHalfHeight);
        second.onResize?.(newSecondHalfHeight);
      } else {
        const containerWidth = container.getBoundingClientRect().width;
        let newFirstHalfWidth;
        let newSecondHalfWidth;

        if (typeof initialPosition === 'string') {
          newFirstHalfWidth = (parseFloat(initialPosition) / 100) * containerWidth;
          newSecondHalfWidth = containerWidth - newFirstHalfWidth;
        } else if (typeof initialPosition === 'number') {
          newFirstHalfWidth = initialPosition;
          newSecondHalfWidth = containerWidth - newFirstHalfWidth;
        } else {
          newFirstHalfWidth = containerWidth / 2;
          newSecondHalfWidth = containerWidth - newFirstHalfWidth;
        }

        firstEle.style.width = `${newFirstHalfWidth}px`;
        secondEle.style.width = `${newSecondHalfWidth}px`;

        first.onResize?.(newFirstHalfWidth);
        second.onResize?.(newSecondHalfWidth);
      }
    };

    useImperativeHandle(ref, () => ({
      handleResetSizes,
    }));

    React.useEffect(() => {
      if (initialPosition) {
        handleResetSizes();
      }
    }, [initialPosition]);

    return (
      <ResizeContainer
        vertical={vertical}
        ref={containerRef}
        {...generateLogzTestAttributes({ context: 'resizeable-container', subject })}
      >
        {first.element && (
          <FirstContainer maxSize={first.maxSize} minSize={first.minSize} vertical={vertical} ref={firstRef}>
            {first.element}
          </FirstContainer>
        )}
        {first.element && second.element && (
          <ResizerHandle
            resizerColor={resizeHandleColor}
            vertical={vertical}
            role="separator"
            ref={resizerRef}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onDoubleClick={handleResetSizes}
          />
        )}
        {second.element && (
          <SecondContainer maxSize={second.maxSize} minSize={second.minSize} vertical={vertical} ref={secondRef}>
            {second.element}
          </SecondContainer>
        )}
      </ResizeContainer>
    );
  },
);

SplitContainer.displayName = 'SplitContainer';
