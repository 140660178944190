import { useMemo } from 'react';
import { ColumnDef, Row } from '@tanstack/react-table';
import { IDataTableProps } from '../types';
import { Skeleton } from '../../../Skeleton/Skeleton.component';
import { INDICATOR_COMPONENT_COLUMN_ID } from '../constants';

type UseColumnsProps<T> = {
  isFetching: boolean;
  data: IDataTableProps<T>['data'];
  columns: IDataTableProps<T>['columns'];
  estimateRowHeight: number;
  density: IDataTableProps<T>['density'];
  IndicatorComponent: IDataTableProps<T>['IndicatorComponent'];
  meta: IDataTableProps<T>['meta'];
  subject: string;
  onIndicatorClick?: (row: Row<T>) => void;
};

export const useColumns = <T extends object>({
  isFetching,
  columns,
  data,
  estimateRowHeight,
  density,
  subject,
  meta,
  IndicatorComponent,
  onIndicatorClick,
}: UseColumnsProps<T>) => {
  const memoizedColumns = useMemo(() => {
    const isLoading = isFetching && data.length === 0;

    const cols = columns.map<ColumnDef<T>>(column => ({
      ...column,
      enablePinning: false,
      meta: { ...meta, ...column.meta },
      ...(isLoading && {
        cell: () => (
          <Skeleton
            subject={`${subject}-loading`}
            width={'100%'}
            height={`${column.estimateRowHeight ?? estimateRowHeight}px`}
          />
        ),
      }),
    }));

    if (IndicatorComponent) {
      const indicatorColumn: ColumnDef<T> = {
        id: INDICATOR_COMPONENT_COLUMN_ID,
        cell: isLoading
          ? null
          : ({ row }) => (
              <IndicatorComponent onClick={onIndicatorClick} row={row} density={density} item={data[row.index]} />
            ),
        header: null,
        enableSorting: false,
        enableResizing: false,
        enablePinning: false,
        size: 24,
      };

      cols.unshift(indicatorColumn);
    }

    return cols;
  }, [isFetching, columns, data, meta, estimateRowHeight, density, subject, IndicatorComponent]);

  return memoizedColumns;
};
