import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isNil } from 'lodash';
import styled from 'styled-components';
import { Flex } from '../../containers/Flex/Flex.component';
import { Group } from '../../containers/Group/Group.component';
import { Text, Title } from '../../typography';
import { INotificationContentProps } from '../Notification.types';
import { NotificationIcon } from './NotificationIcon';

const WordBreakText = styled(Text)`
  word-break: break-word;
`;

export const NotificationContent = ({ subject, title, message, type, children }: INotificationContentProps) => (
  <Flex alignItems="baseline" {...generateLogzTestAttributes({ context: 'notification', type, subject })}>
    {!isNil(type) && <NotificationIcon type={type} />}
    <Group ml={type ? 3 : 2} vertical gap={3}>
      {title && <Title>{title}</Title>}
      {message && (
        <WordBreakText color={'gray.1000'} size={16}>
          {message}
        </WordBreakText>
      )}
      {children}
    </Group>
  </Flex>
);
