import styled, { css } from 'styled-components';
import { Flex } from '../Flex/Flex.component';

export const ShadowScrollContainer = styled(Flex)<{ hideShadow?: boolean }>`
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;

  :before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; // This ensures the shadow doesn't interfere with clicks on the content
    transition: box-shadow 0.3s ease-in-out; // Add smooth transition to the box shadow
    ${({ hideShadow }) =>
      !hideShadow &&
      css`
        box-shadow: 0px -12px 8px -10px ${({ theme }) => theme.colors.gray[500]} inset;
      `}
  }
`;
