import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { MarginProps } from 'styled-system';
import { Box } from '../../containers/Container/Box.component';
import { FillBarPresets } from './fillBar.presets';

const ANIMATIONS_DURATION = '800ms';

interface IFillBarProps extends MarginProps {
  percent: number;
  preset?: FillBarPresets.Type;
  height?: string;
}

interface IFillerProps {
  width: number;
  height: string;
  frames: any;
}

const Filler = styled(Box)<IFillerProps>`
  height: ${props => props.height};
  width: ${props => props.width}%;
  transition: width ${ANIMATIONS_DURATION} ease-in-out;
  animation-name: ${props => props.frames};
  animation-duration: ${ANIMATIONS_DURATION};
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: ${props => (props.width === 100 ? '3px' : 0)};
  border-bottom-right-radius: ${props => (props.width === 100 ? '3px' : 0)};
`;

const FillBarContainer = styled(Box)`
  width: 100%;
  background-color: ${({ theme }) => theme.fillBar.containerBackground};
  border-radius: 3px;
`;

export const generateKeyFrames = (percent: number, fillBarPaintOptions) => {
  const colors = fillBarPaintOptions.filter(option => percent >= option.from).map(option => option.color);

  const framesArr = colors.map((color: string, colorIndex: number) => {
    // dynamically create key frames based on the preset and percent.
    // last key frame must have a 100% value
    const isLast = colorIndex === colors.length - 1;
    const frameStep = isLast ? 100 : fillBarPaintOptions[colorIndex].from;

    return `${frameStep}% { background-color: ${color}}`;
  });

  return framesArr.join(' ');
};

export const FillBar: React.FunctionComponent<IFillBarProps> = ({
  percent,
  height,
  preset = FillBarPresets.Type.Base,
  ...props
}) => {
  if (isNaN(percent) || percent < 0 || percent > 100) {
    throw new Error('Percent must be a number between 0 and 100');
  }

  const [fillerOptions, setFillerOptions] = useState<IFillerProps>({ width: 0, frames: null, height: '23px' });

  useEffect(() => {
    const frames = generateKeyFrames(percent, FillBarPresets.steps[preset]);

    setFillerOptions({ width: percent, frames: keyframes`${frames}`, height: height || '23px' });
  }, [percent, height]);

  return (
    <FillBarContainer {...props}>
      <Filler height={fillerOptions.height} width={fillerOptions.width} frames={fillerOptions.frames} />
    </FillBarContainer>
  );
};

FillBar.displayName = 'FillBar';
