import { css } from 'styled-components';
import { withThemesByProps } from '../../themes';

export const statusFontStyle = withThemesByProps('type', {
  status: css`
    color: ${({ theme }) => theme.colors.text.label};
    font-size: ${({ theme }) => theme.sizes.text.small};
    font-style: italic;
    line-height: 17px;
  `,
  text: css`
    line-height: 14px;
  `,
});
