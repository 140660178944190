import React, { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from '../icons/Icon.component';
import { Group } from '../containers/Group/Group.component';

const StyledResizeGroup = styled(Group).attrs({ draggable: false })`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 16px;
  padding: 0px;
  cursor: col-resize;
  opacity: 0;
  box-shadow: 1px 0 2px 0px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.gray[200]};
  transition: opacity 0.1s linear;

  :hover {
    opacity: 1;
  }
  :active {
    opacity: 1;
  }
`;

export const ResizeHandler: React.FC<{
  resizable: React.MutableRefObject<HTMLElement>;
  onUpdate?: (width: number, isResizing: boolean) => void;
}> = ({ resizable, onUpdate }) => {
  const resizerRef = useRef<HTMLDivElement>(null);
  const startX = useRef(0);
  const startWidth = useRef(0);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (resizable.current) {
        const newWidth = startWidth.current + (startX.current - event.clientX);

        if (newWidth > 0) {
          resizable.current.style.width = `${newWidth}px`;
          onUpdate?.(newWidth, true);
        }
      }
    },
    [onUpdate, resizable.current],
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    if (onUpdate && resizable.current) {
      onUpdate(resizable.current.clientWidth, false);
    }

    // Re-enable pointer events after dragging
    document.body.style.pointerEvents = 'auto';
  }, [handleMouseMove, onUpdate, resizable.current]);

  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      startX.current = event.clientX;
      startWidth.current = resizable.current?.clientWidth || 0;

      // Disable all pointer events when dragging
      document.body.style.pointerEvents = 'none';

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp],
  );

  useEffect(() => {
    const resizer = resizerRef.current;

    if (resizer) {
      resizer.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      if (resizer) {
        resizer.removeEventListener('mousedown', handleMouseDown);
      }

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  return (
    <StyledResizeGroup ref={resizerRef} vertical subject="resizer" justifyContent={'center'} alignItems={'center'}>
      <Icon icon={'ellipsis-vertical-regular'} />
    </StyledResizeGroup>
  );
};
