import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Title } from '../../typography';
import { ITitleProps } from '../../typography/Title/Title.component';

const StyledTitle = styled(Title).attrs<{ subject?: string }>(({ subject }) => ({
  'aria-label': subject ? `Menu Title for ${subject}` : 'Menu Title',
}))`
  height: 36px;
  align-items: center;
`;

type IMenuTitleProps = ITitleProps;

export const MenuTitle: React.FC<PropsWithChildren<IMenuTitleProps>> = ({ children, ...props }) => {
  return (
    <StyledTitle size={16} m={0} py={0} px={6} {...props}>
      {children}
    </StyledTitle>
  );
};
