export enum ComparisonFilter {
  Equal = 'EQ',
  GreaterThan = 'GT',
  GreaterOrEqual = 'GE',
  LowerThan = 'LT',
  LowerOrEqual = 'LE',
  Regex = 'REGEX',
}

export enum MetricsOperator {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
}

export enum MetricsDropFiltersSubjects {
  Table = 'metrics-drop-filters-table',
  MetricsDropFiltersPage = 'metrics-drop-filters',
  TableEmptyState = 'metrics-drop-filters-table-empty-state',
  SearchFilter = 'search-filter',
  NewFilterButton = 'new-filter-button',
  CreateFilter = 'create-filter',
  UpdateFilter = 'update-filter',
  DeleteFilter = 'delete-filter',
  ActivateFilter = 'activate-filter',
  DeactivateFilter = 'deactivate-filter',
  AccountIdSelect = 'account-id-select',
  MetricNameSelect = 'metric-mame-select',
  ModalCheckbox = 'modal-checkbox',
  Modal = 'metrics-drop-filter-modal',
  AddFilterButton = 'add-filter-button',
  MetricFieldNameSelect = 'metric-field-name-select',
  MetricFieldValueInput = 'metric-field-value-input',
  ActivationToggle = 'activation-toggle',
  BulkDeleteFilter = 'bulk-delete-filters',
  BulkCreateFilter = 'bulk-create-filters',
  DeleteAllFilter = 'delete-all-filters',
}
