import React, { useContext } from 'react';
import styled from 'styled-components';
import { ISearchRequestObject } from '@logz-build/typescript';
import { ICustomAction, IPreDefinedAction } from '../Crud.types';
import { Divider } from '../../../Divider/Divider.component';
import { grayPalette } from '../../../themes/colors';
import { BulkActionsStateContext } from '../Crud';
import { Group } from '../../../containers/Group/Group.component';
import { Flex } from '../../../containers/Flex/Flex.component';
import { IBulkActionsState } from '../bulkActions.hook';
import { SelectedLabel } from './SelectedLabel';
import { SelectedAllDataNotification } from './SelectAllDataNotification';
import { ActionsContainer } from './ActionsContainer';

const StyledGroup = styled(Group)`
  height: 48px;
`;

export interface IBulkActionsProps {
  selected: IBulkActionsState;
  modelName: string;
  totalDataLength: number;
  dataSourceLength: number;
  selectedLength: number;
  actions: Array<ICustomAction | IPreDefinedAction>;
  filter: ISearchRequestObject['filter'];
  onSearch: () => void;
}

interface IBulkActionsBar {
  onSelectAllData: () => void;
}

export const BulkActionsBar: React.FunctionComponent<IBulkActionsBar> = ({ onSelectAllData }) => {
  const { selected, dataSourceLength, selectedLength } = useContext<IBulkActionsProps>(BulkActionsStateContext);
  const hasDataOnTable = dataSourceLength > 0;
  const shouldDisplaySelectAllComponent = selected.isPageSelected && hasDataOnTable;

  return (
    <Flex mb={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
      <StyledGroup alignItems={'center'} gap={4}>
        <SelectedLabel />
        {Boolean(selectedLength) && (
          <>
            <Divider vertical color={grayPalette[400]} />
            <ActionsContainer />
          </>
        )}
      </StyledGroup>
      {shouldDisplaySelectAllComponent && <SelectedAllDataNotification onSelectAllData={onSelectAllData} />}
    </Flex>
  );
};
