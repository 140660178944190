import { uniqueId } from 'lodash';

class AbortControllerService {
  private abortControllers: Map<string, AbortController> = new Map();

  /**
   * Generates a unique key to use for the abort controllers.
   * @param prefix The prefix for the unique key
   * @returns The unique key
   */
  createUniqueKey(prefix?: string): string {
    return uniqueId(prefix);
  }

  /**
   * Creates a new AbortController with a unique key.
   * @param key The unique key for the AbortController
   * @returns The newly created AbortController
   */
  create(key: string): AbortController {
    const controller = new AbortController();

    this.abortControllers.set(key, controller);

    return controller;
  }
  /**
   * Creates or retrieves an AbortController for a given key.
   * If no controller exists for the key, it creates and returns a new one.
   * @param key The unique key for the AbortController
   * @returns The AbortController associated with the key
   */
  get(key: string): AbortController {
    if (!this.abortControllers.has(key)) {
      this.create(key);
    }

    return this.abortControllers.get(key)!;
  }

  /**
   * Checks if the AbortController for a given key exists.
   * @param key The unique key for the AbortController
   * @returns `true` if the controller exists, `false` otherwise
   */
  has(key: string): boolean {
    return this.abortControllers.has(key);
  }

  /**
   * Aborts the AbortController associated with the given key.
   * @param key The unique key for the AbortController
   */
  abort(key: string): void {
    const controller = this.abortControllers.get(key);

    if (controller && !controller.signal.aborted) {
      controller.abort();
    }
  }

  /**
   * Aborts all active AbortControllers in the service.
   */
  abortAll(): void {
    this.abortControllers.forEach((controller, key) => {
      if (!controller.signal.aborted) {
        controller.abort();
      }
    });
  }

  /**
   * Deletes the AbortController associated with the given key.
   * This also aborts the controller before deleting it, if necessary.
   * @param key The unique key for the AbortController
   */
  delete(key: string): void {
    if (this.has(key)) {
      this.abort(key);
      this.abortControllers.delete(key);
    }
  }

  /**
   * Deletes all AbortControllers from the service.
   * This also aborts them before deletion.
   */
  deleteAll(): void {
    this.abortAll();
    this.abortControllers.clear();
  }

  /**
   * Cleans up aborted controllers to free memory.
   */
  cleanUp(): void {
    this.abortControllers.forEach((controller, key) => {
      if (controller.signal.aborted) {
        this.abortControllers.delete(key);
      }
    });
  }

  /**
   * Checks if the AbortController for a given key has already been aborted.
   * @param keys The unique key/s for the AbortController
   * @returns `true` if the controller is aborted, `false` otherwise
   */
  isAborted(keys: string | string[]): boolean {
    if (Array.isArray(keys)) {
      return keys.every(key => this.isAborted(key));
    }

    const controller = this.abortControllers.get(keys);

    return controller?.signal.aborted ?? true;
  }
}

export const abortControllerService = new AbortControllerService();
