import styled, { css } from 'styled-components';

interface IPinShadow {
  isVisible: boolean;
  pinDirection: 'right' | 'left';
}

export const PinShadow = styled.div<IPinShadow>`
  position: absolute;
  top: 0;
  height: 100%;
  user-select: none;
  ${({ theme, pinDirection, isVisible }) =>
    pinDirection === 'left' &&
    css`
      background: linear-gradient(to left, transparent 0%, transparent 50%, ${theme.colors.gray[1000]}20 100%);
      width: ${isVisible ? '24px' : '0'};
      right: ${isVisible ? '-24px' : '0'};
    `}

  ${({ theme, pinDirection, isVisible }) =>
    pinDirection === 'right' &&
    css`
      background: linear-gradient(to right, transparent 0%, transparent 50%, ${theme.colors.gray[1000]}20 100%);
      width: ${isVisible ? '24px' : '0'};
      left: ${isVisible ? '-24px' : '0'};
    `}
  touch-action: none;

  z-index: 4;

  transition: all 0.2s ease-out;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;
