import React from 'react';
import { StyledIcon, StyledLink, StyledListItem } from '../Breadcrumbs.styles';
import { Crumb } from '../types';

interface ISingleCrumbProps {
  isDisabled: boolean;
  isFirstItem: boolean;
  crumb: Crumb;
  firstItemSeparator: boolean;
  separator: boolean;
}

export const SingleCrumb: React.FC<ISingleCrumbProps> = ({
  isDisabled,
  isFirstItem,
  crumb,
  firstItemSeparator,
  separator,
}) => {
  const { href, onClick, label, icon } = crumb;

  return (
    <StyledListItem aria-current={isDisabled ? 'page' : undefined}>
      {(isFirstItem && !firstItemSeparator) ||
        (separator && <StyledIcon link={href} icon={'caret-right-solid'} size={12} />)}

      <StyledLink href={href} onClick={onClick} disabled={isDisabled} subject={`${label ?? icon}`} currentTab>
        {icon && <StyledIcon icon={icon} size={12} link={href} />}
        {label}
      </StyledLink>
    </StyledListItem>
  );
};
