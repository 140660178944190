import { ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { FontSizeProps, FontWeightProps, TextAlignProps } from 'styled-system';
import { Icon } from '../icons/Icon.component';
import { Text } from '../typography/Text/Text.component';

interface IButtonGroupItemProps {
  selected: boolean;
  disabled: boolean;
  subject?: string;
  index: number;
  total: number;
  iconOnly?: boolean;
  hideSelectedIndicator?: boolean;
}

export const StyledIcon = styled(({ visible, ...props }) => <Icon icon={'check-solid'} size={12} {...props} />)`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const StyledText = styled.span<ComponentProps<typeof Text> & FontWeightProps & FontSizeProps & TextAlignProps>`
  -webkit-font-smoothing: antialiased;
  font-family: ${({ theme }) => theme.fonts.text};
`;

export const StyledButtonGroupItem = styled.div<IButtonGroupItemProps>`
  ${({ theme, selected, disabled, index, total, iconOnly, hideSelectedIndicator }) => css`
    display: flex;
    align-items: center;
    transition: all 0.1s ease-in-out;
    color: ${theme.buttonGroup.color};
    background-color: ${theme.buttonGroup.backgroundColor};
    padding: 5px 18px 5px 8px;
    border: 1px solid ${theme.buttonGroup.borderColor}};
    gap: 4px;

  ${hideSelectedIndicator && 'padding: 5px 18px 5px 18px'};
  ${iconOnly && 'padding: 8px'};

    ${selected && !disabled && theme.buttonGroup.selected};

    ${
      disabled &&
      css`
        cursor: not-allowed;
        ${theme.buttonGroup.disabled}
      `
    }

    &:hover {
      ${!disabled && !selected && theme.buttonGroup.hover}
      ${!disabled && `cursor: pointer`}
    }

    ${
      index === 0 &&
      css`
        ${selected && !disabled && `border-left-color:${theme.buttonGroup.selected.borderTopColor}`};
        border-bottom-left-radius: ${theme.buttonGroup.edgeButtonRadius};
        border-top-left-radius: ${theme.buttonGroup.edgeButtonRadius};
      `
    }
    ${
      index === total - 1 &&
      css`
        ${selected && !disabled && `border-right-color:${theme.buttonGroup.selected.borderTopColor}`};
        border-bottom-right-radius: ${theme.buttonGroup.edgeButtonRadius};
        border-top-right-radius: ${theme.buttonGroup.edgeButtonRadius};
      `
    }
    ${
      index > 0 &&
      css`
        ${!disabled && 'margin-left: -1px'};
      `
    } // what?! did they mean "even"? 2n? or 2nd and beyond?!
  `}
`;
