import React, { useState } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Tag } from '../../Tag/Tag.component';
import { EditStyledIcon, StyledIcon, StyledLink, StyledListItem } from '../Breadcrumbs.styles';
import { CustomCrumb } from '../types';
import { EditInput } from './EditInput';

interface ICustomCrumbProps {
  crumb: CustomCrumb;
}

export const CustomSingleCrumb: React.FC<ICustomCrumbProps> = ({ crumb }) => {
  const { onClick, label, icon, onEdit, tagValue } = crumb;
  const [isEditting, setIsEditting] = useState(false);

  return (
    <StyledListItem
      aria-current={'page'}
      isEditting={isEditting}
      {...generateLogzTestAttributes({ subject: `custom-crumb-${label ?? icon}` })}
    >
      <StyledIcon icon={'caret-right-solid'} size={12} />
      {isEditting ? (
        <EditInput onEscape={() => setIsEditting(false)} label={label} onEdit={onEdit} />
      ) : (
        <StyledLink onClick={onClick} disabled={true} currentTab>
          {icon && <StyledIcon icon={icon} size={12} />}
          {label}
        </StyledLink>
      )}
      {onEdit && (
        <EditStyledIcon
          icon={isEditting ? 'xmark-regular' : 'pen-regular'}
          size={12}
          onClick={() => setIsEditting(!isEditting)}
          subject={`${label ?? icon}`}
        />
      )}
      {tagValue && <Tag value={tagValue} ml={1} />}
    </StyledListItem>
  );
};
