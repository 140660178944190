import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { MultiSearchControlsSubject } from '@logz-pkg/enums';
import { Group } from '../../../containers/Group/Group.component';
import { Button } from '../../../Button/Button.component';
import { Flex } from '../../../containers/Flex/Flex.component';
import { Checkbox } from '../../../Checkbox/Checkbox.component';
import { Text } from '../../../typography/Text/Text.component';

interface IListHeaderProps {
  numberOfOptions?: number;
  numberOfSelectedOptions?: number;
  onClear(): void;
  onSelectAll(): void;
}

export const ListHeader: React.FC<IListHeaderProps> = ({
  numberOfOptions = 0,
  numberOfSelectedOptions = 0,
  onClear,
  onSelectAll,
}) => {
  return (
    <Flex mt={2} alignItems={'center'} justifyContent={'space-between'}>
      <Button
        subject={MultiSearchControlsSubject.SelectAll}
        variant={'tertiary'}
        onClick={onSelectAll}
        disabled={numberOfSelectedOptions === numberOfOptions}
      >
        Select All ({numberOfOptions})
      </Button>
      {numberOfSelectedOptions !== 0 && (
        <Button subject={MultiSearchControlsSubject.Clear} variant={'tertiary'} onClick={onClear}>
          Clear
        </Button>
      )}
    </Flex>
  );
};

interface IListFooterProps {
  onSave(): void;
  onCancel(): void;
  isSaveDisabled?: boolean;
}

const GroupWithBorder = styled(Group)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[300]};
`;

export const ListFooter: React.FC<IListFooterProps> = ({ onSave, onCancel, isSaveDisabled = true }) => {
  return (
    <GroupWithBorder fullWidth gap={4} pr={4} pb={4} pt={2} alignItems={'center'} justifyContent={'flex-end'}>
      <Button subject={MultiSearchControlsSubject.Cancel} onClick={onCancel} variant={'tertiary'}>
        Cancel
      </Button>
      <Button subject={MultiSearchControlsSubject.Apply} disabled={isSaveDisabled} onClick={onSave} variant={'primary'}>
        Apply
      </Button>
    </GroupWithBorder>
  );
};

export const MenuList = props => {
  const { MenuListHeader = null, MenuListFooter = null } = props.selectProps.components;

  return (
    <>
      {MenuListHeader}
      <components.MenuList {...props}>{props.children}</components.MenuList>
      {MenuListFooter}
    </>
  );
};

export const CheckboxOption = props => (
  <components.Option {...props}>
    <Group gap={2} alignItems={'center'}>
      <Checkbox subject={props.label} onChange={() => null} checked={props.isSelected} />
      <Text>{props.label}</Text>
    </Group>
  </components.Option>
);
