import { FC } from 'react';
import { InlineFlex } from '../containers/Flex/Flex.component';
import { Icon } from '../icons/Icon.component';
import { Button } from '../Button/Button.component';
import { Link } from '../typography';

interface ILearnMoreProps {
  about: string;
  onClick?: () => void;
  subject?: string;
  isButton?: boolean;
}

export const LearnMoreLink: FC<ILearnMoreProps> = ({ about, onClick, subject, isButton = false }) => {
  const text = isButton ? (
    <Button variant={'tertiary'} icon={'life-ring-regular'}>
      Learn more
    </Button>
  ) : (
    <InlineFlex alignItems={'center'}>
      <Icon icon={'life-ring-regular'} /> Learn more
    </InlineFlex>
  );

  return (
    <Link subject={subject} href={about} onClick={onClick}>
      {text}
    </Link>
  );
};
