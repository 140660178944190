import styled from 'styled-components';
import React from 'react';
import { If, calculateHalfGap } from '../utils';
import { Group } from '../containers/Group/Group.component';
import { Container } from './components/TagContainer';

const GroupComponent = ({ children, gap, rowGap = 1, ...props }) => (
  <Group alignItems="center" gap={gap} rowGap={rowGap} flexWrap="wrap" context={'tag-group'} {...props}>
    {children}
  </Group>
);

export const TagGroup = styled(({ maxItems, emptyState = <></>, children, gap = '6px', ...props }) => {
  const myChildren = React.Children.toArray(children);
  const childrenToRender = myChildren && maxItems ? myChildren.slice(0, maxItems) : myChildren;

  if (childrenToRender.length && childrenToRender.length < myChildren.length) {
    childrenToRender.push(<span>...</span>);
  }

  return (
    <>
      <If condition={childrenToRender.length > 0}>
        <GroupComponent gap={gap} {...props}>
          {childrenToRender}
        </GroupComponent>
      </If>
      {childrenToRender.length === 0 && emptyState}
    </>
  );
})`
  ${Container} {
    margin-top: ${calculateHalfGap};
    margin-bottom: ${calculateHalfGap};
  }
`;
