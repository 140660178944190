import React, { CSSProperties, useRef } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';

export interface IFileUploadProps {
  fileType?: string;
  onClick?(): void;
  subject?: string;
  children?: React.ReactNode;
}

export interface IFileSelectorProps {
  onSelect(e: React.ChangeEvent): any;
}

export const FileInput: React.FunctionComponent<IFileUploadProps & IFileSelectorProps> = ({
  onSelect,
  fileType,
  onClick,
  subject,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenInputStyle: CSSProperties = children ? { position: 'absolute', top: '-9999px' } : {};

  const handleClick = () => {
    onClick?.();
    inputRef.current?.click();
  };

  const resetChange = e => {
    e.target.value = null;
  };

  return (
    <>
      <input
        type={'file'}
        ref={inputRef}
        accept={fileType}
        onChange={onSelect}
        onClick={resetChange}
        style={hiddenInputStyle}
        {...generateLogzTestAttributes({
          context: 'file-input',
          subject,
        })}
      />
      <>
        {React.Children.map(children, child =>
          React.cloneElement(child as React.ReactElement, { onClick: handleClick }),
        )}
      </>
    </>
  );
};
