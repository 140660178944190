import { Menu } from './Menu/Menu.component';
import { MenuItem } from './Menu/MenuItem.component';
import { MenuItemDivider } from './Menu/MenuItemDivider.component';
import { SubMenu } from './Menu/SubMenu.component';
import { MenuTitle } from './Menu/MenuTitle.component';

Menu.Item = MenuItem;
Menu.Divider = MenuItemDivider;
Menu.SubMenu = SubMenu;
Menu.Title = MenuTitle;

export { Menu };
export { ThemedActiveCss, ThemedDisabledCss, ThemedHoverCss } from './Menu/MenuItem.component';
