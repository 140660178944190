import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '../containers/Flex/Flex.component';
import { ScrollNavigation } from './ScrollNavigation';
import { ScrollGroup } from './ScrollGroup';
import { useScrollPosition } from './scroll-position.hook';
import { ScrollTitle } from './ScrollTitle';

interface IScrollSnappingProps {
  title?: string;
  description?: string;
  gap?: number;
  hideNavigation?: boolean;
  hideCollapse?: boolean;
  width?: string;
  children: React.ReactNode;
}

const StyledFlex = styled(Flex)`
  flex-direction: column;
  background-color: ${({ theme }) => theme.scrollSnapping.backgroundColor};
  padding-top: ${({ theme }) => `${theme.scrollSnapping.padding};`};
  position: relative;
`;

export const ScrollSnapping: React.FC<IScrollSnappingProps> = ({
  title,
  description,
  hideNavigation,
  hideCollapse,
  width = '100%',
  gap,
  children,
}) => {
  const containerRef = useRef(null);
  const { next, back, position } = useScrollPosition(containerRef, React.Children.count(children));
  const [showContent, setShowContent] = useState<boolean>(true);

  return (
    <StyledFlex width={width} pb={!showContent && 4}>
      {title && (
        <ScrollTitle
          title={title}
          description={description}
          showContent={showContent}
          hideCollapse={hideCollapse}
          onCollapse={() => setShowContent(perv => !perv)}
        />
      )}
      {showContent && (
        <>
          <ScrollGroup gap={gap} groupRef={containerRef}>
            {children}
          </ScrollGroup>
          {!hideNavigation && <ScrollNavigation position={position} onNext={next} onBack={back} />}
        </>
      )}
    </StyledFlex>
  );
};

ScrollSnapping.displayName = 'ScrollSnapping';
