import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { Flex } from '../containers/Flex/Flex.component';
import { Icon } from '../icons/Icon.component';

interface IToggleContainerProps extends HTMLAttributes<HTMLDivElement> {
  value?: boolean;
  moveRight?: boolean;
  disabled?: boolean;
}

const transition = '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms';

export const moveSwitchCircleRight = css`
  transform: translateX(18px);
`;

interface IBaseToggleContainerProps extends HTMLAttributes<HTMLDivElement> {
  disabled: boolean;
  value?: boolean;
}

export const ToggleContainer = styled.div<IBaseToggleContainerProps>`
  display: inline-flex;
  align-items: center;
  width: 40px;
  height: 20px;
  border-radius: 41px;
  cursor: pointer;
  transition: background-color ${transition};
  background: ${({ theme, value }) => (value ? theme.toggle.backgroundColor.on : theme.toggle.backgroundColor.off)};

  &:focus {
    box-shadow: 0 0 0 3px ${({ theme }) => theme.toggle.focusColor};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.toggle.backgroundColor.disabled};
      cursor: not-allowed;
    `}

  ${({ disabled, theme, value }) =>
    !disabled &&
    css`
      :hover {
        background: ${value ? theme.toggle.backgroundColor.hover.on : theme.toggle.backgroundColor.hover.off};
      }
    `};
  ${space}
`;

export const ToggleCircle = styled.div<IToggleContainerProps>`
  width: 16px;
  height: 16px;
  margin: 2px 3px;
  background: ${({ theme }) => theme.toggle.circle.backgroundColor};
  border-radius: 50%;
  transition: transform ${transition}, background ${transition};
  ${({ moveRight }) =>
    moveRight &&
    css`
      ${moveSwitchCircleRight};
    `};
`;

export const HiddenToggleInput = styled.input`
  display: none;
`;

export const StyledCheckedIcon = styled(props => <Icon color={'white'} icon={'check-solid'} size={14} {...props} />)`
  animation: fadeIn ease 0.2s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  margin-left: 4px;
  position: absolute;
`;

const BALL_SPEED = 0.15;
const BALL_COUNT = 3;

export const LoadingBall = styled(props => <span {...props} />)`
  height: 4px;
  width: 4px;
  opacity: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.actions.primary};
`;

export const LoadingBallsContainer = styled(props => (
  <Flex justifyContent="space-evenly" alignItems="center" {...props} />
))`
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  ${({ children, reverseAnimation }) => css`
    ${LoadingBall} {
      animation: dot ${(BALL_COUNT + 1) * BALL_SPEED}s ease-in-out infinite;
    }
    ${children.map(
      (ball, index) => css`
        ${LoadingBall}:nth-child(${index + 1}) {
          animation-delay: ${((reverseAnimation ? BALL_COUNT - index : index) + 1) * BALL_SPEED}s;
        }
      `,
    )}
  `}
`;
