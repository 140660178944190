/* eslint-disable max-lines */
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { flexRender, Row as RTrow } from '@tanstack/react-table';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import { debounce, isNil, throttle } from 'lodash';
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { colors, colorsV2 } from '../../themes';
import { Body } from './Components/Body';
import { BodyRow } from './Components/BodyRow';
import { Head } from './Components/Head';
import { HeadCell } from './Components/HeadCell';
import { TableLinearProgress } from './Components/LinearProgress';
import { Row } from './Components/Row';
import { SubComponentIndicatorComponent } from './Components/SubComponent/SubComponentIndicatorComponent';
import { Table } from './Components/Table';
import { TableWrapper } from './Components/TableWrapper';
import { INITIAL_EMPTY_OBJECT, STATIC_TABLE_HEADER_STYLES, STATIC_TABLE_STYLES } from './constants';
import { useColumns } from './hooks/columns.hook';
import { useDataTable } from './hooks/data-table.hook';
import { useDragDrop } from './hooks/drag-drop.hook';
import { DataTableHandle, IDataTableProps } from './types';
import { dataTableUtils } from './utils';
import { ReturnToTop } from './Components/ReturnToTop';
import { ActionItemPopoverProvider } from './Components/Actions/components/hooks/action-item-popover.hook';
import { ActionItemPopover } from './Components/Actions/components/ActionItemPopover';
import { useHeaderElementsOffsets } from './hooks/header-elements-offsets.hook';

const DataTableInner = <T extends object>(
  {
    height = `inherit`,
    maxHeight = '100%',
    estimateRowHeight = 50,
    columns,
    data,
    state = INITIAL_EMPTY_OBJECT,
    initialState = INITIAL_EMPTY_OBJECT,
    isFetching,
    isFetchingNextPage,
    onSortingChange,
    subRows: { onExpandedChange, ExpandableIconComponent, indentByDepth = true } = INITIAL_EMPTY_OBJECT,
    onGlobalFilterChange,
    onColumnVisibilityChange,
    onTableBottomReached,
    onColumnResize,
    onIndicatorClick,
    ExpandedRowComponent,
    IndicatorComponent = !isNil(ExpandedRowComponent) ? SubComponentIndicatorComponent : null,
    getRowCanExpand = !isNil(ExpandedRowComponent) ? () => true : undefined,
    getActions: getActionsProp,
    subject,
    onDelete,
    onRowClick,
    overflow = 'auto',
    highlightColumns,
    draggableColumns,
    onColumnReorder,
    enableFilters,
    customSortDirection,
    popoverSettings = INITIAL_EMPTY_OBJECT,
    name,
    getIsMarkedRow,
    alternatingColors = false,
    HoveredRowComponent,
    enableRowVirtualization = true,
    meta,
    density,
    showScrollToTopButton = false,
    fixedHeaderElement,
    scrollableHeaderElement,
    emptyStateElement,
    onVisibleRowsChange,
  }: IDataTableProps<T>,
  ref: ForwardedRef<DataTableHandle>,
) => {
  const showSkeleton = isFetching && data.length === 0;
  const getActions = showSkeleton ? undefined : getActionsProp;
  const tableRef = useRef<HTMLDivElement | null>(null);

  const tableData = React.useMemo(() => (showSkeleton ? Array(30).fill({}) : data), [isFetching, data]);
  const tableColumns = useColumns({
    columns,
    data,
    meta,
    isFetching,
    estimateRowHeight,
    density,
    subject,
    IndicatorComponent,
    onIndicatorClick,
  });

  const table = useDataTable<T>({
    enableFilters,
    initialState,
    onColumnVisibilityChange,
    onExpandedChange,
    onGlobalFilterChange,
    onSortingChange,
    state,
    tableColumns,
    tableData,
    getRowCanExpand,
  });

  const { onDragStart, onDrop } = useDragDrop<T>(table, { onColumnReorder });

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length ?? 0,
    overscan: 20,
    getScrollElement: () => tableRef?.current,
    estimateSize: () => estimateRowHeight,
    measureElement:
      typeof window !== 'undefined'
        ? (element, entry, instance) =>
            dataTableUtils.customMeasureElement({ element, entry, instance, rows, ExpandedRowComponent })
        : undefined,
    enabled: enableRowVirtualization,
  });

  const virtualItems = enableRowVirtualization ? virtualizer.getVirtualItems() : undefined;

  const [flashRowIndex, setFlashRowIndex] = useState<number | null>(null);

  const scrollToRow = useCallback(
    debounce((index: number, flash: boolean = false) => {
      virtualizer.scrollToIndex(index, { align: 'start', behavior: 'smooth' });

      if (flash) {
        setFlashRowIndex(index);
        setTimeout(() => setFlashRowIndex(null), 1000);
      }
    }, 200),
    [virtualizer.scrollToIndex],
  );

  const imperativeHandleProps = useMemo(
    () => ({
      scrollToRow,
      toggleExpanded: table.toggleAllRowsExpanded,
      getIsSomeRowsExpaneded: table.getIsSomeRowsExpanded,
      ...tableRef,
    }),
    [scrollToRow, table.toggleAllRowsExpanded, table.getIsSomeRowsExpanded, tableRef.current],
  );

  const [isScrolledHorizontally, setIsScrolledHorizontally] = useState(false);

  useEffect(() => {
    const handleHorizontalScroll = (event: Event) => {
      const element = event.target as HTMLDivElement;

      setIsScrolledHorizontally(element.scrollLeft > 0);
    };
    const scrollElement = tableRef.current;

    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleHorizontalScroll);

      setIsScrolledHorizontally(scrollElement.scrollLeft > 0);

      return () => {
        scrollElement.removeEventListener('scroll', handleHorizontalScroll);
      };
    }
  }, [tableRef]);

  useImperativeHandle(ref, () => imperativeHandleProps);

  const { actionsOffsetTop, fixedHeaderElementRef, scrollableHeaderElementRef, setScrollHeight, tableWrapperRef } =
    useHeaderElementsOffsets();

  const throttledOnVisibleRowsChange = useCallback(
    debounce((firstVisibleRow, lastVisibleRow) => {
      onVisibleRowsChange(data[firstVisibleRow], data[lastVisibleRow]);
    }, 300),
    [onVisibleRowsChange, data],
  );

  const reportVisibleRows = useCallback(
    throttle(() => {
      if (
        enableRowVirtualization &&
        virtualItems &&
        onVisibleRowsChange &&
        virtualizer.range?.endIndex >= 0 &&
        virtualizer.range.startIndex >= 0
      ) {
        const visibleItems = virtualItems.filter(
          item => item.index >= virtualizer.range.startIndex && item.index <= virtualizer.range.endIndex,
        );
        const firstVisibleRow = visibleItems[0]?.index ?? 0;
        const lastVisibleRow = visibleItems[visibleItems.length - 1]?.index ?? 0;

        throttledOnVisibleRowsChange(firstVisibleRow, lastVisibleRow);
      }
    }, 1500),
    [enableRowVirtualization, virtualItems, throttledOnVisibleRowsChange, virtualizer.range],
  );

  const handleScroll = useCallback(
    e => {
      setScrollHeight(e.target.scrollTop);

      if (data.length === 0) return;

      if (Math.floor(e.target.scrollHeight - e.target.scrollTop) < e.target.clientHeight + 10) {
        onTableBottomReached?.();
      }

      reportVisibleRows();
    },
    [data.length === 0, onTableBottomReached, reportVisibleRows],
  );

  const pinEndPosition = useMemo(() => {
    const pinnedColumns = table.getAllColumns().filter(column => column.getIsPinned());

    if (pinnedColumns.length === 0) return 0;

    const lastLeftPinnedColumn = pinnedColumns[pinnedColumns.length - 1];

    // Extra pixel is removed for popper not calculating the position correctly in different zoom levels
    return lastLeftPinnedColumn.getSize() + lastLeftPinnedColumn.getStart() - 1;
  }, [initialState]);

  useEffect(() => {
    virtualizer.shouldAdjustScrollPositionOnItemSizeChange = () => false; // This is a workaround for a bug in tanstack/virtual htt ps://github.com/TanStack/virtual/issues/562
  }, [virtualizer]);

  const isEmpty = data.length === 0 && !isFetchingNextPage && !isFetching;

  return (
    <ActionItemPopoverProvider>
      <div
        ref={tableWrapperRef}
        id={name}
        style={{ position: 'relative', overflow, height: 'inherit', width: 'inherit' }}
        {...generateLogzTestAttributes({ context: 'data-table', subject })}
      >
        <TableWrapper style={{ maxHeight, overflow, height }} ref={tableRef} onScroll={handleScroll}>
          <Table style={{ ...STATIC_TABLE_STYLES, height: isEmpty ? '100%' : 'auto' }}>
            {!isNil(scrollableHeaderElement) && (
              <Head>
                <div
                  ref={scrollableHeaderElementRef}
                  style={{
                    zIndex: 3,
                    position: 'sticky',
                    left: 0,
                    width: 'var(--table-wrapper-width)',
                  }}
                >
                  {scrollableHeaderElement}
                </div>
              </Head>
            )}
            <>
              <Head style={STATIC_TABLE_HEADER_STYLES}>
                {!isNil(fixedHeaderElement) && (
                  <div
                    ref={fixedHeaderElementRef}
                    style={{
                      zIndex: 2,
                      position: 'sticky',
                      left: 0,
                      width: 'var(--table-wrapper-width)',
                    }}
                  >
                    {fixedHeaderElement}
                  </div>
                )}
                {table.getHeaderGroups().map(headerGroup => (
                  <Row key={headerGroup.id} sticky style={{ backgroundColor: colors.gray[0] }}>
                    {headerGroup.headers.map(header => {
                      const { column } = header;
                      const isDraggable = draggableColumns ? draggableColumns.includes(header.column.id) : false;

                      return (
                        <HeadCell
                          draggable={isDraggable}
                          data-column-index={header.index}
                          onDragStart={onDragStart}
                          onDragOver={(e): void => e.preventDefault()}
                          onDrop={(e: React.DragEvent<HTMLElement>) => onDrop(e, draggableColumns)}
                          onSort={e => {
                            virtualizer.scrollToIndex(0, { behavior: 'smooth' });
                            column.getToggleSortingHandler()(e);
                          }}
                          onPin={() => {
                            if (!column.getCanPin()) return;

                            if (column.getIsPinned() === 'left' || column.getIsPinned() === 'right') column.pin(false);
                            else column.pin('left');
                          }}
                          onColumnResize={onColumnResize}
                          key={header.id}
                          customSortDirection={customSortDirection}
                          colSpan={header.colSpan}
                          header={header}
                          isScrolledHorizontally={isScrolledHorizontally}
                          style={{
                            display: 'flex',
                            width: header.column.getSize(),
                            minWidth: `calc(${header?.column?.getSize()} * 1px)`,
                            ...dataTableUtils.getCommonPinningStyles(column),
                          }}
                          {...(tableColumns[header.index].removable ? { onDelete } : {})}
                        >
                          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        </HeadCell>
                      );
                    })}
                  </Row>
                ))}
              </Head>
              {isEmpty ? (
                emptyStateElement
              ) : (
                <Body
                  style={{
                    display: 'grid',
                    height: enableRowVirtualization ? `${virtualizer.getTotalSize()}px` : undefined,
                    position: 'relative',
                  }}
                >
                  {isFetching && <TableLinearProgress position={'top'} loading={isFetching} />}
                  {(virtualItems ?? rows).map(
                    (rowOrVirtualRow: VirtualItem<Element> | RTrow<T>, renderedRowIndex: number) => {
                      if (enableRowVirtualization) {
                        renderedRowIndex = rowOrVirtualRow.index;
                      }

                      const row = enableRowVirtualization ? rows[renderedRowIndex] : (rowOrVirtualRow as RTrow<T>);
                      const isRowMarked = getIsMarkedRow?.(row);

                      let rowColor = colors.gray[0];
                      let border = 'none';

                      if (alternatingColors && renderedRowIndex % 2 === 0) {
                        rowColor = colorsV2.gray[100];
                      }

                      if (!showSkeleton && isRowMarked) {
                        rowColor = colorsV2.gray[200];
                        border = `1px solid ${colorsV2.gray[600]}`;
                      }

                      if (!isNil(ExpandedRowComponent) && row.getIsExpanded()) {
                        rowColor = colorsV2.gray[200];
                      }

                      return (
                        <BodyRow<T>
                          key={`${row.id}-${renderedRowIndex}`}
                          {...{
                            border,
                            isRowMarked,
                            estimateRowHeight,
                            ExpandableIconComponent,
                            ExpandedRowComponent,
                            getActions,
                            highlightColumns,
                            indentByDepth,
                            item: data[renderedRowIndex] as T,
                            name,
                            onRowClick,
                            popoverSettings,
                            row,
                            rowColor,
                            actionsOffsetTop,
                            flash: flashRowIndex === renderedRowIndex,
                            showSkeleton,
                            state,
                            subject,
                            tableRef,
                            virtualizer,
                            virtualRow: enableRowVirtualization ? (rowOrVirtualRow as VirtualItem<Element>) : undefined,
                            HoveredRowComponent,
                            renderedRowIndex,
                            density,
                            IndicatorComponent,
                            isScrolledHorizontally,
                            pinEndPosition,
                          }}
                        />
                      );
                    },
                  )}
                </Body>
              )}
            </>
          </Table>
        </TableWrapper>
        {isFetchingNextPage && <TableLinearProgress loading={isFetchingNextPage} position={'bottom'} />}
        {showScrollToTopButton && (
          <ReturnToTop
            estimateRowHeight={estimateRowHeight}
            scrollContainerRef={tableRef}
            handleClick={() => scrollToRow(0)}
          />
        )}
      </div>
      <ActionItemPopover />
    </ActionItemPopoverProvider>
  );
};

export const DataTable = forwardRef(DataTableInner) as <T>(
  props: IDataTableProps<T> & { ref?: ForwardedRef<DataTableHandle> },
) => ReturnType<typeof DataTableInner>;
