/**
 * InjectorProxy provides two layers of proxy decoration,
 * first for the services invocation by name, by the provided injector.
 * second layer is for the provided service interface.
 *
 * Both layers are Proxy wrapped to avoid fatal exceptions on non existing misuse
 * or application out of sync with the container services version.
 */
import type { IInjectorProxyHandlerOptions, IServiceProxyHandlerOptions } from './injector-proxy.interface';
import { InjectorLoggingUtils } from './injector-logging-utils';

const { GetMissingServiceMsg, GetMissingServicePropertyMsg, SetMissingServicePropertyMsg, CallMissingServiceMethodMsg } =
  InjectorLoggingUtils;

function defaultLogger(msg: any, ...args: any[]) {
  console.error('[InjectorProxy] ', msg, ...args);
}

/**
 * Ignore irrelevant behavior, such as angular dynamic properties enrichment.
 */
export function supportedPropsFilter(subject: string): boolean {
  return !(subject && subject.startsWith('$'));
}

export class ServiceProxyHandler {
  readonly propsFilter: (name: string) => boolean;
  readonly onGetMissingServiceProperty: (msg: string, name: string) => void;
  readonly onSetMissingServiceProperty: (msg: string, name: string, value: any) => void;
  readonly onCallMissingServiceMethod: (msg: string, thisArg: any, args: any[]) => void;

  constructor({
    propsFilter = supportedPropsFilter,
    onGetMissingServiceProperty = defaultLogger,
    onSetMissingServiceProperty = defaultLogger,
    onCallMissingServiceMethod = defaultLogger,
  }: IServiceProxyHandlerOptions = {}) {
    this.propsFilter = propsFilter;
    this.onGetMissingServiceProperty = onGetMissingServiceProperty;
    this.onSetMissingServiceProperty = onSetMissingServiceProperty;
    this.onCallMissingServiceMethod = onCallMissingServiceMethod;
  }

  get(target: {}, name: string | number | symbol): any {
    if (name === Symbol.toStringTag) {
      return target.toString();
    }

    if (name in target) return target[name];

    if (this.propsFilter(name.toString())) {
      const msg = GetMissingServicePropertyMsg(name);

      this.onGetMissingServiceProperty(msg, name.toString());

      return new Proxy({}, this);
    }
  }

  set(target: {}, name: string | number | symbol, value: any): boolean {
    if (!(name in target) && this.propsFilter(name.toString())) {
      const msg = SetMissingServicePropertyMsg(name, value);

      this.onSetMissingServiceProperty(msg, name.toString(), value);
    }

    target[name] = value;

    return true;
  }

  apply(target: Function, thisArg: any, argsList: any[]): any {
    if (!target) {
      const thisArgStr = JSON.stringify(thisArg);
      const msg = CallMissingServiceMethodMsg(thisArgStr, argsList);

      this.onCallMissingServiceMethod(msg, thisArgStr, argsList);

      return new Proxy({}, this);
    }

    return target.apply(thisArg, argsList);
  }
}

export class InjectorProxyHandler {
  readonly injector: (name: string | number | symbol) => any;
  readonly onGetMissingService: (msg: string, name: string | number | symbol) => void;
  readonly serviceProxyHandler: ServiceProxyHandler;

  constructor({ injector, onGetMissingService = defaultLogger }: IInjectorProxyHandlerOptions) {
    this.injector = injector;
    this.onGetMissingService = onGetMissingService;
    this.serviceProxyHandler = new ServiceProxyHandler(arguments[0] as IServiceProxyHandlerOptions);
  }

  get(target: {}, name: string | number | symbol): any {
    if (name === Symbol.toStringTag) return target.toString();

    if (name in target) return target[name];

    let service = null;

    try {
      service = this.injector(name);
    } catch (err) {}

    if (!service) {
      const msg = GetMissingServiceMsg(name);

      this.onGetMissingService(msg, name);
    }

    target[name] = new Proxy(service || {}, this.serviceProxyHandler);

    return target[name];
  }
}

export function injectorProxy(options: IInjectorProxyHandlerOptions): {} {
  return new Proxy({}, new InjectorProxyHandler(options));
}
