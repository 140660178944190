import React, { FunctionComponent } from 'react';
import { IPagination, ISearchRequestObject } from '@logz-build/typescript';
import { SearchFilterType } from '@logz-pkg/enums';
import { IIconProps } from '../../icons/Icon.component';
import { TooltipPlacement } from '../../tooltip/Tooltip.component';
import type { IBooleanFilter, IMultiSelectFilter, ISelectFilter, IToggleFilter } from '../../SearchFilters';
import { IButtonProps } from '../../Button/Button.types';
import { IDeleteModalComponentProps } from './CrudTableRecordDeleteModal';
import { CustomActionName, PreDefinedActionNames } from './Bulk/bulk-actions.enum';
import { IActionModalProps } from './Bulk/interfaces';

export namespace CrudTableTypes {
  export interface IColumn {
    title: string;
    key: string;
    recordPropertyName?: string;
    width?: string;
    minWidth?: string;
    tooltip?: string;
    isFixedWidth?: boolean;
    isSortable?: boolean;
    isHighlighted?: boolean;

    editModeComponent?(isNew: boolean, record: object): JSX.Element;

    ReadModeComponent?: FunctionComponent<{ record: object }>;
  }

  export interface ICustomAction {
    label: string | ((record: Record<any, any>) => string);
    icon?: IIconProps['icon'];
    shouldSearchAfterAction?: boolean;

    handleClick(record: object, event?): void;

    shouldDisplay?(record: object): boolean;
  }
}

type BooleanFilter = { type: SearchFilterType.Boolean; props: IBooleanFilter; defaultValue?: any };
type SelectFilter = { type: SearchFilterType.Select; props: ISelectFilter; defaultValue?: any };
type MultiSelectFilter = { type: SearchFilterType.MultiSelect; props: IMultiSelectFilter; defaultValue?: any };
type ToggleFilter = { type: SearchFilterType.Toggle; props: IToggleFilter; defaultValue?: any };
type DateRangePickerFilter = { type: SearchFilterType.DateRangePicker; props: any; defaultValue?: any };
type SuperDateRangePickerFilter = { type: SearchFilterType.SuperDateRangePicker; props: any; defaultValue?: any };

export type CrudSearchFilter =
  | BooleanFilter
  | SelectFilter
  | MultiSelectFilter
  | ToggleFilter
  | DateRangePickerFilter
  | SuperDateRangePickerFilter;

export interface IOnConfrimAction {
  ids: RowId[];
  filter: ISearchRequestObject['filter'];
  fields?: Record<string, any>;
}

export interface IBasicAction {
  onConfirm: ({ ids, filter, fields }: IOnConfrimAction) => Promise<void>;
}

export interface ICustomAction extends IBasicAction {
  type: CustomActionName;
  label: string;
  icon: IIconProps['icon'];
  Modal: FunctionComponent<IActionModalProps>;
}

export interface IPreDefinedAction extends IBasicAction {
  type: PreDefinedActionNames;
  notificationTitle?: string;
  confirmationText?: string;
}

interface IBulkOptions {
  modelName: string;
  actions: Array<ICustomAction | IPreDefinedAction>;
}

export interface ICrudOptions {
  bulk?: IBulkOptions;
  actions?: {
    hide?: boolean;
    edit?: {
      note?: string;
      save?: {
        disableIfPristine?: boolean;
        text?: string;
        variant?: IButtonProps['variant'];
      };
    };
  };
  actionBar?: {
    LeftComponent?: React.ComponentType<any>;
    RightComponent?: React.ComponentType<any>;
    BottomComponent?: React.ComponentType<any>;
    showClearFiltersButton?: boolean;
    disableInputSearchEnterKey?: boolean;
    inputSearchDebounceTime?: number;
  };

  header?: {
    sticky?: boolean;
    background?: string;
  };
  row?: {
    onClick?: (record) => void;
  };
  tableStyles?: {
    cellPadding?: 15 | 10 | 5 | 0;
    hideRowBorder?: boolean;
    cellContentAlignment?: 'center' | 'flex-start';
  };
}

export interface ICrudTableProps {
  options?: ICrudOptions;
  ref?: unknown;
  columns: CrudTableTypes.IColumn[];
  dataSource: Record<any, any>[];
  showSearchInput?: boolean;
  searchFilters?: CrudSearchFilter[];
  total: number;
  pagination?: IPagination;
  hidePagination?: boolean;
  addButtonText?: string;
  DeleteModalComponent?: React.ComponentType<IDeleteModalComponentProps>;
  createInitialValues?: object; // An initial object to display its values on the edit-mode inputs
  recordIdField?: ((record: any) => string) | string; // Which field should be used as a key in a list. Default - id
  recordContext?: string; // appended to the context selector, default is recordIdField
  initialRequestObject?: ISearchRequestObject; // The object to be passed when the table initially calls the onSearch (on load)
  customActions?: CrudTableTypes.ICustomAction[];
  emptyState?: JSX.Element;
  filterObjectBuilder?: (filters: ISearchRequestObject['filter']) => ISearchRequestObject['filter'];

  onSearch(searchRequestObject?: ISearchRequestObject, isInitial?: boolean): Promise<void> | void;

  searchTooltip?: string;
  searchPlaceholder?: string;

  onDelete?(record: object): Promise<any>;

  onCreate?(values: object): Promise<any>;

  onUpdate?(record: object): Promise<any>;

  onDuplicate?(record: object): Promise<any>;

  onCancel?(): void;

  validationSchema?: object;

  validate?(record: object): any;

  customAddAction?(record: object, openCreateRecord: Function): Promise<void>;

  customDuplicateAction?(record: object): Promise<void>;

  customEditAction?(record: object): Promise<void>;

  canEdit?(record: object): boolean;

  canDelete?(record: object): boolean;

  canDuplicate?(record: object): boolean;

  indicatorComponent?(record: object): JSX.Element;

  recordSubject?(key?: object): string;

  fullWidth?: boolean;
  disableAddButton?: boolean;
  disabledAddButtonTooltip?: string;
  preventActionCondense?: boolean;
  DeleteButton?: React.ComponentType<{ onDelete: ICrudTableRecordProps['onDelete'] }>;
  EditButton?: React.ComponentType<{ onEdit: () => void; isDense: boolean; record: object }>;
  DuplicateButton?: React.ComponentType<{ onDuplicate: ICrudTableRecordProps['onDuplicate'] }>;
  error?: string | null;
  subject?: string;
  tooltipPlacement?: TooltipPlacement;
  renderAddButton?: (addButton) => JSX.Element;
  forceLoading?: boolean;
  attachedAddButton?: boolean;

  shouldDisableRow?(record: object): boolean;
  onSearchClick?: (value?: any) => void;
}

export const paginationSize = {
  options: [10, 25, 50, 100],
  default: 25,
};

export type RowId = number | string;

export interface IBulkRecordProps {
  checked: boolean;
  onChange: (isChecked: boolean, id: RowId) => void;
}

export interface IBulkHeaderProps {
  selectedLength: number;
  dataSourceLength: number;
  isAllPagesSelected: boolean;
  onChange: (isChecked: boolean) => void;
}

export interface ICrudTableRecordProps {
  bulk?: IBulkRecordProps;
  record: Record<string, any>;
  columns: CrudTableTypes.IColumn[];
  isDense: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  canDuplicate?: boolean;
  context?: string;
  subject?: string;
  indicatorComponent?: ICrudTableProps['indicatorComponent'];
  customActions?: ICrudTableProps['customActions'];
  onSearch: ICrudTableProps['onSearch'];
  onStartLoading: () => void;
  onFinishLoading: () => void;

  onDelete(record: object): void;

  onEdit(record: object): void;

  onDuplicate(record: object): void;

  DeleteButton?: React.ComponentType<{ onDelete: ICrudTableRecordProps['onDelete'] }>;
  EditButton?: ICrudTableProps['EditButton'];
  DuplicateButton?: React.ComponentType<{ onDuplicate: ICrudTableRecordProps['onDuplicate'] }>;
  disable?: boolean;
  tableOptions: ICrudOptions;
  onClick?: () => void;
}

export interface ICrudTableEditRecordProps {
  columns: CrudTableTypes.IColumn[];
  record: object;
  className?: string;
  isNew?: boolean;
  subject?: string;
  context?: string;
  validationSchema?: ICrudTableProps['validationSchema'];
  validate?: ICrudTableProps['validate'];
  options?: ICrudOptions;
  onCancel(): void;

  onCreate?(record: object): Promise<void>;

  onUpdate?(record: object): Promise<void>;
}

export interface IAddButtonProps {
  disabled?: boolean;
  tableSubject?: string;
  disabledTooltip?: string;
  tooltipPlacement?: TooltipPlacement;
  addButtonText?: string;
}

export const CrudTableEvents = {
  AddNewClicked: 'add-new-clicked',
  TableIsLoading: 'table-loading',
  TableIsReady: 'table-ready',
  TableInEdit: 'table-in-edit',
  TableNotInEdit: 'table-not-in-edit',
};

export type CrudTableRef = {
  search: (searchRequestObject: ISearchRequestObject, isInitial?: boolean) => Promise<void>;
  refresh: () => Promise<void>;
};
