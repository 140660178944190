import React, { useState, useEffect, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';

export interface IFadeProps {
  transitionDuration?: number;
  startTransition?: boolean;
  subject?: string;
  children: ReactNode;
}

const StyledWrapper = styled.div.attrs<IFadeProps>(({ subject }) => generateLogzTestAttributes({ subject }))<IFadeProps>`
  ${({ transitionDuration }) =>
    css`
      transition: opacity ${transitionDuration}ms, transform ${transitionDuration}ms;
    `}
  opacity: ${({ startTransition }) => (startTransition ? '1' : '0')};
`;

export const Fade: React.FunctionComponent<IFadeProps> = ({
  startTransition = true,
  transitionDuration = 225,
  children,
  subject,
}) => {
  const [internalStartTransition, setInternalStartTransition] = useState(false);

  useEffect(() => {
    if (startTransition) {
      setInternalStartTransition(true);
    }
  }, [startTransition]);

  return (
    <StyledWrapper startTransition={internalStartTransition} transitionDuration={transitionDuration} subject={subject}>
      {children}
    </StyledWrapper>
  );
};
