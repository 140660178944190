import { Cell, Column, Row } from '@tanstack/react-table';
import { measureElement, Virtualizer } from '@tanstack/react-virtual';
import { CSSProperties } from 'react';
import { colorsV2 } from '../../themes';
import { Density, IDataTableProps } from './types';

type GetHightlightStyleProps<T> = {
  cell: Cell<T, unknown>;
  state: IDataTableProps<T>['state'];
  highlightColumns: IDataTableProps<T>['highlightColumns'];
};

type GetHighlightStyle = <T extends object>(props: GetHightlightStyleProps<T>) => CSSProperties;

const getHighlightStyle: GetHighlightStyle = ({ cell, state, highlightColumns }) => ({
  backgroundColor:
    state?.globalFilter &&
    highlightColumns?.includes?.(cell?.column?.id) &&
    cell?.getValue()?.toString()?.toLowerCase()?.includes?.(state?.globalFilter?.toLowerCase())
      ? colorsV2.yellow[50]
      : 'transparent',
});

type GetIndentStyleProps<T> = {
  index: number;
  row: Row<T>;
  ExpandableIconComponent: IDataTableProps<T>['subRows']['ExpandableIconComponent'];
  indentByDepth: IDataTableProps<T>['subRows']['indentByDepth'];
};

type GetIndentStyle = <T extends object>(props: GetIndentStyleProps<T>) => CSSProperties;

const getIndentStyle: GetIndentStyle = ({ index, row, ExpandableIconComponent, indentByDepth }): CSSProperties => ({
  ...(index === 0 &&
    ExpandableIconComponent &&
    !!indentByDepth && {
      paddingLeft: `${typeof indentByDepth === 'boolean' ? 10 * row.depth : indentByDepth * row.depth}px`,
    }),
  ...(ExpandableIconComponent ? { paddingTop: 0, paddingBottom: 0 } : {}),
});

const getDensityStyle = (density: Density) => {
  if (density === 'compact') return { padding: '4px 8px' };

  if (density === 'comfortable') return { padding: '12px' };

  if (density === 'regular') return { padding: '8px' };

  return { padding: '0px' };
};

const customMeasureElement = <T>({
  element,
  entry,
  instance,
  rows,
  ExpandedRowComponent,
}: {
  element: Element;
  entry: ResizeObserverEntry;
  instance: Virtualizer<HTMLDivElement, Element>;
  rows: Row<T>[];
  ExpandedRowComponent: IDataTableProps<T>['ExpandedRowComponent'];
}) => {
  const elementHeight = measureElement(element, entry, instance);
  const index = instance.indexFromElement(element);
  const row = rows[index];

  if (row?.getIsExpanded() && ExpandedRowComponent) {
    const expandedRowComponent = element.nextElementSibling;
    const expandedRowComponentHeight = expandedRowComponent?.getBoundingClientRect().height || 0;

    return elementHeight + expandedRowComponentHeight;
  }

  return elementHeight;
};

const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();

  const getPositionOffset = (): Partial<{ left: string; right: string }> => {
    if (isPinned === 'left') {
      return { left: `${column.getStart('left')}px` };
    }

    if (isPinned === 'right') {
      return { right: `${column.getAfter('right')}px` };
    }

    return {};
  };

  return {
    ...getPositionOffset(),
    background: 'inherit',
    opacity: isPinned ? 0.97 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned && 2,
  };
};

export const dataTableUtils = {
  getHighlightStyle,
  getIndentStyle,
  getDensityStyle,
  customMeasureElement,
  getCommonPinningStyles,
};
