import { royalBluePalette } from '../../../themes/colors';

export const titleLightThemeV2 = {
  styles: {
    fontFamily: 'Ubuntu, sans-serif',
    // SG-251: Remove once all deprecatedVariants are gone
    marginBottom: 0,
  },
  fontWeight: 700,
  color: royalBluePalette[1000],
};
