export enum SecurityIOCFeedsFeedTypes {
  PRIVATE = 'PRIVATE',
  LOGZIO = 'LOGZIO',
  REPOSITORY = 'REPOSITORY',
}

export enum SecurityIOCFeedsFeedTypesTags {
  PRIVATE = 'Private feed',
  LOGZIO = 'Logz.io feed',
  REPOSITORY = 'Repository',
}

export enum SecurityIOCFeedsSyncStatus {
  PAUSED = 'PAUSED',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
}

export enum SecurityIOCFeedsErrorCode {
  FEED_SIZE_LIMIT_REACHED = 'FEED_SIZE_LIMIT_REACHED',
  PARSING_ERROR = 'PARSING_ERROR',
  CONNECTION_SERVER_ERROR = 'CONNECTION_SERVER_ERROR',
  CONNECTION_UNAUTHORIZED = 'CONNECTION_UNAUTHORIZED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export enum SecurityIOCFeedsSyncStatusText {
  Pending = 'Pending...',
  Processing = 'Processing...',
  Paused = 'Sync paused',
}

export enum SecurityIOCFeedsIOCType {
  IP = 'IP',
  DNS = 'DNS',
  URL = 'URL',
  MD5 = 'MD5',
  SHA1 = 'SHA1',
  SHA256 = 'SHA256',
  USER_AGENT = 'USER_AGENT',
  CUSTOM = 'CUSTOM',
}

export enum SecurityIOCFeedsIOCTypeLabel {
  IP = 'IP',
  DNS = 'DNS',
  URL = 'URL',
  MD5 = 'MD5',
  SHA1 = 'SHA1',
  SHA256 = 'SHA256',
  USER_AGENT = 'USER-AGENT',
  CUSTOM = 'CUSTOM',
}

export enum SecurityIOCFeedsFeedFormat {
  STIX = 'STIX',
  SAWMILL_PIPELINE = 'SAWMILL_PIPELINE',
}

export enum SecurityIOCFeedsFeedConfidences {
  LOW = 'LOW',
  LOW_MEDIUM = 'LOW_MEDIUM',
  MEDIUM = 'MEDIUM',
  MEDIUM_HIGH = 'MEDIUM_HIGH',
  HIGH = 'HIGH',
}

export enum SecurityIOCFeedTestSubjects {
  FeedTable = 'feed-table',
  TableEmptyState = 'table-empty-state',
  TableEmptyStateText = 'table-empty-state-text',
  IocSearchInput = 'ioc-search-input',
  IocSearchButton = 'ioc-search-button',
  ThreatsFeedsPage = 'threats-feeds-page',
  PrivateFeedIcon = 'PrivateFeedIcon',
  LogzioFeedIcon = 'LogzioFeedIcon',
  RepositoryFeedIcon = 'Repository',
  NewPrivateFeedButton = 'NewPrivateFeedButton',
  ReSyncPausedPrivateFeedButton = 'ReSyncPausedPrivateFeedButton',
  FeedSyncIndicationErrorIcon = 'FeedSyncIndicationErrorIcon',
  FeedsSearchErrorNotification = 'FeedsSearchError',
  IocSearchErrorNotification = 'IocSearchError',
  FeedReSyncSuccessNotification = 'FeedReSyncSuccessNotification',
  PrivateFeedDeleteSuccessNotification = 'privateFeedDeleteSuccessNotification',
  PrivateFeedFormNameInput = 'PrivateFeedFormNameInput',
  PrivateFeedFormDescriptionInput = 'PrivateFeedFormDescriptionInput',
  PrivateFeedFormIocTypeSelect = 'PrivateFeedFormIocTypeSelect',
  PrivateFeedFormPipelineSelect = 'PrivateFeedFormPipelineSelect',
  PrivateFeedFormCompressionSelect = 'PrivateFeedFormCompressionSelect',
  PrivateFeedFormSeveritySelect = 'PrivateFeedFormSeveritySelect',
  PrivateFeedFormFeedStixToggle = 'PrivateFeedFormFeedStixToggle',
  PrivateFeedFormFeedEnableToggle = 'PrivateFeedFormFeedEnableToggle',
  PrivateFeedFormReliabilitySelect = 'PrivateFeedFormReliabilitySelect',
  PrivateFeedFormUrlInput = 'PrivateFeedFormUrlInput',
  PrivateFeedFormSourceUrlInput = 'PrivateFeedFormSourceUrlInput',
  PrivateFeedFormMethodSelect = 'PrivateFeedFormMethodSelect',
  PrivateFeedFormHeadersInput = 'PrivateFeedFormHeadersInput',
  PrivateFeedFormBodyInput = 'PrivateFeedFormBodyInput',
  PrivateFeedFormPage = 'PrivateFeedFormPage',
  PrivateFeedFormUpdated = 'PrivateFeedFormUpdated',
  PrivateFeedFormCreated = 'PrivateFeedFormCreated',
}

export enum SecurityIOCFeedsRoutingStates {
  Table = 'dashboard.security.threats.feeds',
  New = 'dashboard.security.threats.feeds-new',
  Edit = 'dashboard.security.threats.feeds-edit',
}
