import { useHover } from '@logz-pkg/react-hooks';
import { isNil } from 'lodash';
import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';
import { BaseButton } from '../../buttons/BaseButton/BaseButton.component';
import { StyledButtonIcon } from '../../buttons/BaseButton/BaseButton.styles';
import { IBaseButtonProps } from '../../buttons/BaseButton/types';
import { Grid } from '../../containers/Grid';
import { Text } from '../../typography';

export interface IBaseMenuItemProps extends IBaseButtonProps {
  helper?: string;
  subject?: string;
  active?: boolean;
  context?: string;
  disabled?: boolean;
  onHover?: () => void;
}

export type IMenuItemProps = IBaseMenuItemProps & SpaceProps;

export const ThemedDisabledCss = css`
  background-color: ${({ theme }) => theme.menu.item_disabled_background};
  color: ${({ theme }) => theme.menu.item_disabled_color};

  :hover {
    background-color: ${({ theme }) => theme.menu.item_disabled_background};
    color: ${({ theme }) => theme.menu.item_disabled_color};
  }

  ${StyledButtonIcon} {
    color: ${({ theme }) => theme.menu.item_disabled_color};
  }
`;

export const ThemedHoverCss = css`
  background-color: ${({ theme }) => theme.menu.item_hover_background};
  color: ${({ theme }) => theme.menu.item_hover_color};

  :hover {
    background-color: ${({ theme }) => theme.menu.item_hover_background};
    color: ${({ theme }) => theme.menu.item_hover_color};
  }
`;

export const ThemedActiveCss = css`
  background-color: ${({ theme }) => theme.menu.item_active_background};
  color: ${({ theme }) => theme.menu.item_active_color};

  :hover {
    background-color: ${({ theme }) => theme.menu.item_hover_background};
    color: ${({ theme }) => theme.menu.item_active_color};
  }

  ${StyledButtonIcon} {
    color: ${({ theme }) => theme.menu.item_active_color};
  }
`;

const MenuItemStyled = styled(BaseButton)<{
  active?: boolean;
  disabled?: boolean;
  hover?: boolean;
  children?: ReactNode;
}>`
  display: flex;
  justify-content: flex-start;
  position: relative;
  height: 30px;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.base};
  background: ${({ theme }) => theme.menu.item_background};
  color: ${({ theme }) => theme.menu.item_color};

  ${StyledButtonIcon} {
    font-size: 12px;
    color: ${({ theme, iconColor }) => !iconColor && theme.menu.icon_color};
    align-self: center;
  }

  ${({ disabled, active, hover }) => {
    if (disabled) return ThemedDisabledCss;

    if (active) return ThemedActiveCss;

    if (hover) return ThemedHoverCss;

    return '';
  }};
`;
export const MenuItem: React.FC<PropsWithChildren<IMenuItemProps>> = ({
  helper,
  subject,
  children,
  active = false,
  disabled = false,
  onHover,
  ...props
}) => {
  const { ref, isHovering } = useHover<HTMLButtonElement>();

  useEffect(() => {
    if (!isNil(onHover) && isHovering) {
      onHover();
    }
  }, [isHovering, ref, onHover]);

  return (
    <MenuItemStyled
      aria-label={subject ? `Menu Item for ${subject}` : 'Menu Item'}
      context={'menu-item'}
      subject={subject}
      ref={ref}
      hover={isHovering}
      active={active}
      disabled={disabled}
      px={2}
      py={0}
      mx={1}
      my={0}
      {...props}
    >
      {typeof children === 'string' ? (
        <Grid gridTemplateColumns={isHovering ? '1fr auto' : '1fr 0'} flexGrow={1}>
          <Text
            color={'inherit'}
            subject={'menu-title'}
            size={12}
            textAlign={'initial'}
            ellipsis={{ openDelayInMs: 300 }}
          >
            {children}
          </Text>
          {helper && (
            <Text ellipsis subject={'helper'} size={12} variant={'description'} textAlign={'end'}>
              {helper}
            </Text>
          )}
        </Grid>
      ) : (
        children
      )}
    </MenuItemStyled>
  );
};
