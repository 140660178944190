import styled, { css } from 'styled-components';

const listCss = css`
  list-style: none;
  margin: 0;
  padding-left: 8px;
`;
export const ListComponent = styled.ul`
  ${listCss}
`;
export const OrderListComponent = styled.ol`
  ${listCss}
`;
