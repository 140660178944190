export enum ExplorePageSubjects {
  Page = 'explore-page',
  OpenFilterPanelButton = 'explore-open-filter-panel-button',
  FilterPanel = 'explore-filters-panel',
  FilterPanelSkeleton = 'explore-filter-panel-skeleton',
  FilterPanelField = 'explore-filter-panel-field',
  FilterPanelCloseButton = 'explore-filter-panel-close-button',
  FilterPanelSection = 'explore-filter-panel-section',
  FilterPanelSectionCount = 'explore-filter-panel-section-count',
  FilterPanelSectionLoading = 'explore-filter-panel-section-loading',
  FilterGraph = 'explore-filter-graph',
  Graph = 'explore-graph',
  SearchResultsTable = 'search-results-data-table',
  QuickView = 'quick-view',
  QuickViewJson = 'quick-view-json',
  ExpandedLogJson = 'expanded-log-json',
  ExpandedLogTable = 'expanded-log-table',
  ExpandedSingleLogButton = 'expanded-single-log-button',
  ExpandedCopyButton = 'expanded-copy-button',
  ExpandedSurroundingLogsButton = 'expanded-surrounding-logs-button',
  QuickViewModeButton = 'quick-view-mode-select-button',
  QuickViewCloseButton = 'quick-view-close-button',
  QuickViewCopyButton = 'quick-view-copy-button',
  QuickViewCopyNotification = 'quick-view-copy-notification',
  ExploreAsDefaultSuccessNotification = 'explore-as-default-success-notification',
  ExploreAsDefaultFailureNotification = 'explore-as-default-failure-notification',
  QuickViewNotFoundNotification = 'quick-view-not-found-notification',
  QuickViewSurroundingLogsButton = 'quick-view-surrounding-logs-button',
  AccountSelector = 'filter-panel-account-selector',
  FiltersSearchBar = 'filters-search-bar',
  FiltersContainer = 'filters-container',
  ToggleModeButton = 'smart-search-mode-toggle',
  ToggleModePopover = 'smart-search-mode-popover',
  AIButton = 'explore-page-ai-button',
  GraphGroupBy = 'graph-group-by',
  GraphScaleTypeSelectButton = 'graph-scale-type-select-button',
  Totals = 'total-logs',
  SmartSearch = 'smart-search-component',
  SmartSelect = 'smart-select',
  PreviewInExplore = 'preview-in-explore',
  LuceneTextArea = 'explore-lucene-text-area',
  VirtualizedFilterFieldsList = 'explore-filter-panel-virtualized-filter-fields-list',
  SearchButton = `explore-search-button`,
  DensityModeSelectButton = `density-mode-select-button`,
  DensityModeSelectMenu = `density-mode-select-menu`,
  QuickViewLogTable = `quick-view-log-table`,
  AlertsInExploreToggle = `alerts-in-explore-toggle`,
  CreateAlert = `create-alert`,
  CreateOptimizer = `create-optimizer`,
  CopyUrl = `copy-url`,
  CloseQuickview = `close-quickview`,
  TableJsonFormatter = `explore-table-json-formatter`,
  MultiTag = `explore-multi-tag`,
  ActionsMenu = `explore-actions-menu`,
  OpenPreferencesButton = `open-preferences-button`,
  ExportAllButton = 'export-all',
  SmartSelectActions = `explore-smart-select-actions`,
  SmartSelectPopover = `explore-smart-select-popover`,
  SmartSelectPopoverOption = `explore-smart-select-popover-option`,
  ViewExceptions = 'explore-view-exceptions',
  ExceptionsQuickViewTable = 'explore-exceptions-quick-view-table',
  RcaExceptionButton = 'explore-rca-exception-button',
  RcaExceptionExistsButton = 'explore-rca-exception-exists-button',
  RcaExceptionRegenerateButton = 'explore-rca-exception-regenerate-button',
  OpenSavedSearchButton = 'open-saved-search-button',
  SaveSavedSearchButton = 'save-saved-search-button',
  CancelSavedSearchButton = 'cancel-saved-search-button',
  FindSavedSearchInput = 'find-saved-search-input',
  SortSavedSearchButton = 'sort-saved-search-button',
  SavedSearchListItem = 'saved-search-list-item',
  SavedSearchTitleWithTotalValues = 'saved-search-title-with-total-values',
  SavedSearchEmptyStateImportButton = 'saved-search-empty-state-import-button',
  SavedSearchEmptySearchImportButton = 'saved-search-empty-search-import-button',
  SavedSearchItemName = 'saved-search-item-name',
  SavedSearchEditItemNameInput = 'saved-search-edit-item-name-input',
  SavedSearchDeleteItemButton = 'saved-search-delete-item-button',
  SavedSearchEditItemButton = 'saved-search-edit-item-button',
  SavedSearchConfirmItemButton = 'saved-search-confirm-item-button',
  SavedSearchIsFavoriteInputButton = 'saved-search-isFavorite-item-button',
  SavedSearchCreateInput = 'saved-search-create-input',
  SavedSearchCreateNotification = 'saved-search-create-notification',
  SavedSearchDeleteNotification = 'saved-search-delete-notification',
  SavedSearchUpdateNotification = 'saved-search-update-notification',
  SavedSearchItemActions = 'saved-search-item-actions',
  SavedSearchPopover = 'saved-search-popover',
  SinglLogAiInvestigateButton = 'investigate-this-log',
  SmartSelectValues = 'smart-select-values',
  OpenSingleLogButton = 'open-single-log-button',
  ToggleUtc = 'explore-toggle-utc',
  VisualizationMenu = 'explore-visualization-menu',
  ExpandedRowTabs = 'explore-expanded-row-tabs',
  LuceneEditor = 'explore-lucene-editor',
  ExploreDeploymentsSettingSelected = 'explore-deployments-setting-selected',
  ExploreDeploymentsSettingAll = 'explore-deployments-setting-all',
  ExploreDeploymentsSetting = 'explore-deployments-setting',
  OpenUnifiedDashboardModal = 'explore-open-unified-dashboard-modal',
}

export enum ExplorePageGraphYAxisScaleTypes {
  Linear = 'linear',
  Logarithmic = 'logarithmic',
  SquareRoot = 'squareRoot',
}

export enum ExplorePageDensityModes {
  OneLine = 'OneLine',
  TwoLines = 'TwoLines',
  Expanded = 'Expanded',
}

export enum ExplorePreferencesSubjects {
  Page = 'explore-preferences',
  DensityTitle = 'preferences-keybindings-section-title',
  Density = 'preferences-keybindings-section-subtitle',
}

export enum FilterFieldSettingsIds {
  EXISTS = 'exists',
  NOT_EXISTS = 'not-exists',
  REMOVE_EXISTS = 'remove-exists',
  TABLE = 'table',
  BOOKMARK = 'bookmark',
  CLEAR = 'clear',
  GRAPH = 'graph',
}

export enum ExploreSurroundingPage {
  LoadLogsBar = 'load-surrounding-logs-bar',
  LoadLogsBarInput = 'load-surrounding-logs-bar-input',
}

export enum ExploreSingleLogPage {
  Page = 'single-log-view-page',
  SingleLogViewTable = `single-log-view-table`,
  SingleLogViewJson = `single-log-view-json`,
  SingleLogViewCopyButton = `single-log-view-copy-button`,
  SingleLogViewSurroundingLogsButton = `single-log-view-surrounding-logs-button`,
}

export type InputMode = 'LUCENE' | 'SMART';
export enum ExpandedDocumentTabNames {
  LOG = 'Log',
  TRACE = 'Trace',
}

export enum ExploreTimeInterval {
  Auto = 'auto',
  Millisecond = 'ms',
  Second = 's',
  Minute = 'm',
  Hour = 'h',
  Day = 'd',
  Week = 'w',
  Month = 'M',
  Year = 'y',
}

export enum ExploreWarmTier {
  WarmDataSlowerResponseTimesWarning = 'warm-data-slower-response-times-warning',
  WarmDataSuccessfullyRetrieveNotification = 'warm-data-successfully-retrieve-notification',
}
