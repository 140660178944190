import { opacity } from '../../../utils';

const headerButtons = (theme, active) => ({
  variants: {
    primary: {
      background: active ? theme.header.item_active_background : theme.header.item_background,
      color: active ? theme.header.item_active_color : theme.header.item_color,
      '&:hover': {
        background: active ? theme.header.item_active_background : theme.header.item_hover_background,
        color: active ? theme.header.item_active_color : theme.header.item_hover_color,
      },
    },
    accent: {
      background: active ? opacity(theme.colors.orange[500], 20) : theme.header.item_background,
      color: active ? theme.colors.orange[400] : theme.colors.actions.accent,
      '&:hover': {
        background: opacity(theme.colors.orange[500], 20),
        color: theme.colors.orange[400],
      },
    },
  },
});

export default headerButtons;
