import { ElementType } from 'react';
import { Header } from '../../Header/Header.component';
import { Page as PrimaryPage } from './Page.component';
import { PageBody } from './Body.component';

type PageProps = typeof PrimaryPage & {
  Header: ElementType;
  Footer: ElementType;
  Body: ElementType;
};

Object.defineProperty(PrimaryPage, 'Header', { value: Header });
Object.defineProperty(PrimaryPage, 'Footer', { value: Header });
Object.defineProperty(PrimaryPage, 'Body', { value: PageBody });

export const Page = PrimaryPage as PageProps;
