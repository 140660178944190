import { css } from 'styled-components';
import { memoize } from 'lodash';
import moment from 'moment';
import { DateRange, isRelativeDate, relativeToMoment } from '@logz-pkg/utils';
import { DateRangePickerSubject } from '@logz-pkg/enums';
import { DateCustomization, DateCustomizationSingleDay, DateCustomizationRange, DefaultQuickOptionId } from '../types';
import { getReactDatePickerValues } from '../utils';

export const getDateTooltipCss = memoize(
  (tooltip: string) => css`
    &:after {
      content: '${tooltip}';
      display: none;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      padding: 3px 8px;
      background-color: #002e42;
      color: #ffffff;
      font-weight: 400;
      font-size: 12px;
      line-height: 2em;
      z-index: 1;
    }

    &:hover:after {
      display: block;
    }
  `,
);

const rangeToDateArray = (range: DateCustomizationRange) => {
  const { start, end, tooltip, highlight } = range;
  const startDate = moment(start);
  const endDate = moment(end);
  const dates: DateCustomizationSingleDay[] = [];

  while (startDate.isSameOrBefore(endDate)) {
    dates.push({ date: startDate.toDate(), tooltip, highlight });
    startDate.add(1, 'day');
  }

  return dates;
};

export const getCustomizedDatesCss = memoize((dateCustomizations: DateCustomization[]) => {
  const dateCustomizationsWithRanges = dateCustomizations.filter(
    ({ start, end }: DateCustomizationRange) => start && end,
  ) as DateCustomizationRange[];

  const unwrappedDates = dateCustomizations.filter(
    ({ date }: DateCustomizationSingleDay) => date,
  ) as DateCustomizationSingleDay[];

  dateCustomizationsWithRanges.forEach(range => {
    unwrappedDates.push(...rangeToDateArray(range));
  });

  return unwrappedDates.map(({ date, tooltip, highlight }) => {
    const momentDate = moment(date);

    const day = momentDate.format('D').padStart(3, '0');
    const monthName = momentDate.format('MMMM');
    const year = momentDate.format('YYYY');

    return css`
      // Build selector based on aria labels because there's no other way to target specific days in react-datepicker
      .react-datepicker__day--${day}[aria-label*="${monthName}"][aria-label*="${year}"] {
        &.react-datepicker__day--range-start,
        &.react-datepicker__day--range-end {
          color: ${({ theme }) => theme.datePicker.day.colorStartEndRange};
        }

        &.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range, :hover) {
          // This selector was copied as is from react-datepicker, we need to override it :/
          color: ${({ theme }) => (highlight ? theme.datePicker.day.highlightColor : theme.datePicker.day.colorInRange)};
        }

        ${highlight &&
        css`
          font-weight: 700;
          color: ${({ theme }) => theme.datePicker.day.highlightColor};
        `}

        ${tooltip && getDateTooltipCss(tooltip)}
      }
    `;
  });
});

export const getQuickOptionSubject = ({ id, label }: { id: DefaultQuickOptionId; label: string }) =>
  `${DateRangePickerSubject.QuickOptionPrefix}-${id ?? label.replace(' ', '-').toLowerCase()}`;

export const isEqualDateRange = (a: DateRange, b: DateRange) => {
  return a?.start?.value === b?.start?.value && a?.end?.value === b?.end?.value;
};

export const extractValuesFromDateRange = (value: DateRange) => {
  const [start, end] = getReactDatePickerValues(value);

  const datePickerAbsoluteDates = [start, end].map(date =>
    typeof date === 'string' ? relativeToMoment(date).toDate() : date,
  );

  const shownDates = [start, end].map(date => {
    if (isRelativeDate(date) && date !== 'now') {
      return relativeToMoment(date).toDate();
    }

    return date;
  });

  return {
    shownDates,
    datePickerAbsoluteDates,
  };
};
