import { DateRangePickerSubject } from '@logz-pkg/enums';
import React from 'react';
import { AbsoluteDateInput } from '../AbsoluteDateInput';
import { StyledInputContainer, StyledIcon } from '../AbsoluteFilter.styles';
import { IButtonProps } from '../../../../../../components/Button/Button.types';
import { DateSection } from '../../types';
import { NowButton } from './NowButton';
import { IAbsoluteInputsProps } from './AbsoluteInputs';

export interface IAbsoluteInputProps {
  isActive: boolean;
  section: DateSection;
  onClick: () => void;
  onUpdate: (value: Date, switchSection: boolean) => void;
  timezone: IAbsoluteInputsProps['timezone'];
  value: IAbsoluteInputsProps['shownDates'][number];
  onNowButtonClick?: IButtonProps['onClick'];
}

export const AbsoluteInput: React.FC<IAbsoluteInputProps> = ({
  isActive,
  value,
  section,
  onClick,
  onUpdate,
  timezone,
  onNowButtonClick,
}) => {
  const isStartSection = section === 'start';

  return (
    <StyledInputContainer active={isActive} section={section}>
      <StyledIcon icon={'calendar-regular'} size={12} />
      <AbsoluteDateInput
        active={isActive}
        placeholder={isStartSection ? 'Start date' : 'End date'}
        dateValue={value}
        onClick={onClick}
        onUpdate={onUpdate}
        subject={isStartSection ? DateRangePickerSubject.StartDateInput : DateRangePickerSubject.EndDateInput}
        timezone={timezone}
      />
      {!isStartSection && onNowButtonClick && <NowButton onClick={onNowButtonClick} />}
    </StyledInputContainer>
  );
};
