import { forwardRef, FunctionComponent, MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { fontSize, FontSizeProps, fontWeight, FontWeightProps } from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { underlineStyle } from '../Text/Text.styles';

interface ILinkProps {
  href?: string | null;
  data?: string;
  subject?: string;
  className?: string;
  currentTab?: boolean;
  underline?: boolean;
  disabled?: boolean;
  size?: FontSizeProps['fontSize'];
  whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'initial' | 'inherit';
  weight?: 300 | 400 | 500 | 700;
  onClick?(event: MouseEvent): void;
  children: ReactNode;
}

export const disabledLinkStyles = props =>
  props.disabled &&
  css`
    pointer-events: none;
    opacity: 0.3;
  `;

const StyledLink = styled.a<ILinkProps & FontSizeProps & FontWeightProps>`
  text-decoration: none;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  font-family: ${({ theme }) => theme.fonts.text};
  ${({ underline }) => underline && underlineStyle};
  ${({ theme }) => css`
    color: ${theme.link.color};

    :hover {
      color: ${theme.link.active.color};
    }

    :active {
      color: ${theme.link.active.color};
    }
  `};
  transition: all 0.05s ease-in-out;

  ${disabledLinkStyles}
  ${fontSize};
  ${fontWeight};
  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `}

  :hover {
    cursor: pointer;
  }
`;

export const Link: FunctionComponent<ILinkProps> = forwardRef<HTMLLinkElement, ILinkProps>(
  ({ children, currentTab = false, weight = 500, size = 14, subject, ...props }, ref) => (
    <StyledLink
      ref={ref}
      fontSize={size}
      fontWeight={weight}
      target={currentTab ? '_top' : '_blank'}
      rel={currentTab ? '' : 'noreferrer noopener'}
      {...generateLogzTestAttributes({ context: 'link', subject })}
      {...props}
    >
      {children}
    </StyledLink>
  ),
);

Link.displayName = 'Link';
