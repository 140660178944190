export enum K8s360Subjects {
  Footer = 'footerContainer',
  Card = 'cardContainer',
  App = 'kube360App',
  QuickView = 'quickView',
  QuickViewTitle = 'quickViewTitle',
  QuickViewType = 'fieldRowTypeWrapper',
  CloseQuickView = 'closeQuickView',
  LegendMetricTypePrefix = 'legendMetricType-',
  CirclePrefix = 'circle-',
  LegendNumberPrefix = 'legendNumber-',
  LoadingKubeMapCard = 'loadingKubeMapCard',
  GroupPodsByTypeSelector = 'groupPodsByTypeSelector',
  SwitchViewMode = 'switchViewMode',
  OverviewCardRow = 'overview-cart-row',
  OverviewCardRowLabel = 'overview-cart-row-label',
  OverviewCardRowValue = 'overview-cart-row-value',
  EmptyStateTitle = 'empty-state-title',
  EmptyStateText = 'empty-state-text',
}

export enum K8SQuickViewTabNames {
  PODS = 'Pods',
  LOGS = 'Logs',
  SECURITY = 'Security',
  METRICS = 'Metrics',
  TRACES = 'Traces',
  YAML = 'YAML',
}
