import styled, { css } from 'styled-components';

const StyledBackdrop = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: ${({ theme }) => theme.backdrop_background_color};
  z-index: 90;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    !props.show &&
    css`
      display: none;
    `};
`;

export default StyledBackdrop;
