export const iconSize = ({ theme, size }) => theme.sizes.icon[size] || size;

export const textSize = ({ theme, size }) => theme.sizes.text[size] || size;

export const textWeight = ({ theme, weight }) => theme.sizes.fontWeight[weight] || weight;

export const textColor = ({ theme, color }) => theme.colors.text[color] || color;

export const semanticColor = ({ theme, semantic }) => theme.colors.semantic[semantic];

export const severityColor = ({ theme, severity }) => theme.colors.severity[severity];
