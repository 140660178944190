import React, { ComponentProps, forwardRef, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { PopoverStateProvider } from '../../../popover/Popover.provider';
import { PopoverContainer } from '../../../popover/popover.styles';
import { IPopoverProps, Popover } from '../../../popover/Popover.component';
import { Text } from '../../../typography/Text/Text.component';
import { Option } from '../../types';
import { FilterButton } from './FilterButton';

const FilterContainer = styled.div<{ fullWidth: boolean }>`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${PopoverContainer} {
    margin-top: 14px;
    overflow: hidden;
  }
`;

const FilterButtonWithRef: React.FC<any> = forwardRef(({ ...props }, ref) => <FilterButton {...props} />);

interface IFilterValueProps {
  disabled?: boolean;
  onClick?: () => Promise<void>;
  label: string | ReactElement;
  ContentComponent?: IPopoverProps['Content'];
  defaultValue?: Option;
  maxLabelWidth?: number;
  minLabelWidth?: number;
  isActive?: boolean;
  open?: boolean;
  field: string;
  attachToBody?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}

export const FilterValue: React.FC<IFilterValueProps> = ({
  disabled = false,
  label,
  ContentComponent,
  isActive,
  onClick,
  attachToBody,
  maxLabelWidth = 200,
  minLabelWidth = 50,
  open,
  field,
  fullWidth = false,
  loading = false,
}) => {
  if (!ContentComponent) {
    return (
      <FilterButton
        subject={`${field}-filter-button`}
        variant={'secondary'}
        maxLabelWidth={maxLabelWidth}
        minLabelWidth={minLabelWidth}
        onClick={onClick}
        isActive={isActive}
        fullWidth={fullWidth}
        loading={loading}
      >
        <Text>{label}</Text>
      </FilterButton>
    );
  }

  const attachToBodyProps: Partial<ComponentProps<typeof Popover>> = attachToBody
    ? { appendToBody: true, topOffset: 10 }
    : {};

  return (
    <PopoverStateProvider>
      <FilterContainer fullWidth={fullWidth}>
        <Popover
          zIndex={3}
          open={open}
          placement={'bottom-start'}
          wrapChildWithRef
          Content={ContentComponent}
          {...attachToBodyProps}
        >
          <FilterButtonWithRef
            disabled={disabled}
            variant="secondary"
            subject={`${field}-filter-button`}
            maxLabelWidth={maxLabelWidth}
            minLabelWidth={minLabelWidth}
            isActive={isActive}
            fullWidth={fullWidth}
            loading={loading}
          >
            {label}
          </FilterButtonWithRef>
        </Popover>
      </FilterContainer>
    </PopoverStateProvider>
  );
};
