import { colorService } from '@logz-pkg/utils';
import { colors, colorsV2 } from '@logz-ui/styleguide/components/themes';

export const superDatePickerLightTheme = {
  background: colors.gray[0],
  border: colors.gray[300],
  boxShadow: colorService.convertHexToRgba(colors.gray[400], 0.3),
  tabs: {
    color: colors.royalBlue[800],
    activeColor: colors.royalBlue[1000],
    activeBorder: colors.yellow[600],
  },
  quick: {
    titleColor: colors.royalBlue[1000],
    linkColor: colors.blue[600],
    linkHoverColor: colors.blue[600],
    linkHoverBackground: colors.gray[100],
    linkSelectedColor: colors.royalBlue[1000],
    linkSelectedBackground: colors.yellow[600],
  },
  absolute: {
    input: {
      backgroundColor: colors.gray[100],
      color: colors.gray[1000],
      borderColor: colors.gray[300],
      activeBorderColor: colors.royalBlue[1000],
    },
    today: {
      color: colors.royalBlue[1000],
      backgroundColor: colors.gray[100],
      borderColor: colors.gray[400],
    },
  },
  time: {
    color: colors.blue[1000],
    background: colors.gray[0],
    selectedItemColor: colors.blue[1000],
    selectedItemBackground: colors.blue[300],
  },
  day: {
    color: colors.blue[1000],
    colorInRange: colors.royalBlue[800],
    colorOutsideMonth: colors.gray[600],
    colorStartEndRange: colors.gray[0],
    selectedBackground: colors.blue[300],
    inSelectingRangeBackground: colors.blue[300],
    inRangeBackground: colors.blue[200],
    rangeStartEndBackground: colors.blue[500],
    todayBackground: colors.yellow[600],
    highlightColor: colors.blue[1000],
  },
  navigation: {
    currentMonthColor: colors.blue[1000],
    iconColor: colors.blue[1000],
  },
  variants: {
    explore: {
      time: {
        selectedItemColor: colors.gray[0],
        selectedItemBackground: colorsV2.blue[500],
      },
      day: {
        selectedBackground: colorsV2.blue[100],
        inSelectingRangeBackground: colorsV2.blue[100],
        inRangeBackground: colorsV2.blue[100],
        rangeStartEndBackground: colorsV2.blue[500],
        highlightColor: colorsV2.blue[500],
      },
      quick: {
        linkSelectedBackground: colorsV2.blue[500],
        linkSelectedColor: colors.gray[0],
        linkColor: colorsV2.blue[500],
        linkHoverColor: colorsV2.blue[500],
      },
    },
  },
};
