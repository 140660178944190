import React, { useState, useCallback, useMemo } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { DateRangePickerSubject } from '@logz-pkg/enums';
import { DateRange, isRelativeRange } from '@logz-pkg/utils';
import { Flex } from '@logz-ui/styleguide/components/containers/Flex/Flex.component';
import { Spinner } from '@logz-ui/styleguide/components/progress/Spinner/Spinner.component';
import { useForceUpdate } from '@logz-pkg/react-hooks';
import { QuickOption, DatePickerSection, FooterNotificationProps, PopupVariant, DateCustomization } from '../types';
import { getExternalValue } from '../utils';
import { Box, Tab, Tabs } from '../../../../../components';
import { SuperDateRangePickerThemeProvider } from './ThemeProvider';
import { QuickFilter } from './QuickFilter';
import { AbsoluteFilter } from './AbsoluteFilter';
import { StyledGroup, StyledOverlay } from './Popup.styles';

export interface SuperDateRangePickerPopupProps {
  variant?: PopupVariant;
  value?: DateRange;
  subject?: string;
  quickOptions?: QuickOption[];
  sections?: DatePickerSection[];
  onChange?: (range: DateRange) => void;
  onConfirm?: (range: DateRange) => void;
  onCancel?: () => void;
  showEndDateNowButton?: boolean;
  timezone?: string;
  minDate?: Date;
  maxDate?: Date;
  dateCustomizations?: DateCustomization[];
  showQuickFilterActionsBar?: boolean;
  renderFooterNotification?: (props: FooterNotificationProps) => React.ReactNode;
  renderActionsBarNotification?: () => React.ReactNode;
  closePopover?: () => void;
  loading?: boolean;
}

export const SuperDateRangePickerPopup: React.FC<SuperDateRangePickerPopupProps> = ({
  subject = DateRangePickerSubject.Popup,
  variant = 'standard',
  value,
  quickOptions,
  sections,
  onChange,
  onConfirm,
  onCancel,
  showEndDateNowButton,
  timezone,
  minDate,
  maxDate,
  dateCustomizations,
  showQuickFilterActionsBar,
  renderFooterNotification,
  renderActionsBarNotification,
  closePopover,
  loading,
}) => {
  const allowedSections = sections ?? ['quick', 'absolute'];
  const initialSection: DatePickerSection =
    value?.end.type === 'absolute' || value?.start.type === 'absolute' ? 'absolute' : 'quick';

  const [visibleSection, setVisibleSection] = useState<DatePickerSection>(
    allowedSections.includes(initialSection) ? initialSection : allowedSections[0],
  );
  const [selectedRange, setSelectedRange] = useState<DateRange>(value);
  const [shouldUpdate, forceUpdate] = useForceUpdate();

  const lastRelativeValue = useMemo<DateRange | null>(
    () => (value && isRelativeRange(value) ? value : null),
    [value?.start?.value, value?.end?.value],
  );

  const updateSelectedRange = useCallback((range: DateRange) => {
    setSelectedRange(range);
    // Need to remount absolute filter because react-datepicker doesn't properly update the selected dates
    // when the value prop is changed from the outside while popup is open
    forceUpdate();
  }, []);

  const footerNotification = useMemo(() => {
    return (
      renderFooterNotification &&
      renderFooterNotification({ selectedRange, setSelectedRange: updateSelectedRange, closePopover, visibleSection })
    );
  }, [renderFooterNotification, selectedRange?.start?.value, selectedRange?.end?.value, closePopover, visibleSection]);

  const actionsBarNotification = useMemo(() => {
    return renderActionsBarNotification && renderActionsBarNotification();
  }, [renderActionsBarNotification]);

  const onChangeHandler = useCallback(
    (range: DateRange) => {
      setSelectedRange(range);
      onChange && onChange(getExternalValue(range, timezone));
    },
    [onChange],
  );

  const onConfirmHandler = useCallback(
    (range: DateRange) => {
      onConfirm && onConfirm(getExternalValue(range, timezone));
    },
    [onConfirm],
  );

  const onCancelHandler = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  return (
    <SuperDateRangePickerThemeProvider variant={variant}>
      {loading && (
        <StyledOverlay alignItems={'center'} justifyContent={'center'}>
          <Spinner />
        </StyledOverlay>
      )}
      <StyledGroup vertical {...generateLogzTestAttributes({ context: 'date-range-picker-popup', subject })} gap={0}>
        {allowedSections.length > 1 && (
          <Box width={'100%'}>
            <Tabs
              size={'m'}
              onChange={(event, value) => setVisibleSection(value)}
              selectedTab={visibleSection}
              fullWidth
            >
              <Tab label={'Quick'} value={'quick'} subject={DateRangePickerSubject.QuickTab} />
              <Tab label={'Absolute'} value={'absolute'} subject={DateRangePickerSubject.AbsoluteTab} />
            </Tabs>
          </Box>
        )}

        <Flex width={'100%'}>
          {visibleSection === 'quick' && (
            <QuickFilter
              value={selectedRange}
              onChange={onChangeHandler}
              onConfirm={onConfirmHandler}
              onCancel={onCancelHandler}
              quickOptions={quickOptions}
              showActionsBar={showQuickFilterActionsBar}
              footerNotification={footerNotification}
              actionsBarNotification={actionsBarNotification}
              lastRelativeValue={lastRelativeValue}
              updateSelectedRange={updateSelectedRange}
            />
          )}

          {visibleSection === 'absolute' && (
            <AbsoluteFilter
              key={`absolute-filter-${shouldUpdate}`}
              value={selectedRange}
              onChange={onChangeHandler}
              onConfirm={onConfirmHandler}
              onCancel={onCancelHandler}
              showEndDateNowButton={showEndDateNowButton}
              timezone={timezone}
              minDate={minDate}
              maxDate={maxDate}
              dateCustomizations={dateCustomizations}
              footerNotification={footerNotification}
              actionsBarNotification={actionsBarNotification}
              updateSelectedRange={updateSelectedRange}
            />
          )}
        </Flex>
      </StyledGroup>
    </SuperDateRangePickerThemeProvider>
  );
};
