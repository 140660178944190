import { bluePalette } from '../../../themes/colors';

export const linkLightTheme = {
  color: bluePalette[600],
  hover: {
    color: bluePalette[800],
  },
  active: {
    color: bluePalette[900],
  },
};
