import { ComponentProps, FunctionComponent, ReactNode, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { IIconProps, Icon } from '../icons/Icon.component';
import { Tooltip } from '../tooltip/Tooltip.component';
import { Text } from '../typography';
import type { PopoverPlacement } from '../popover/types';
import { Color } from '../themes';

interface IInfoProps {
  color?: Color | string;
  tooltip: string | ReactNode;
  text?: string;
  placement?: PopoverPlacement;
  iconSize?: number;
  textSize?: ComponentProps<typeof Text>['size'];
  variant?: 'danger';
  subject?: string;
}

const UnderlinedText = styled(Text)`
  border-bottom: 1px dashed ${({ theme }) => theme.info.textUnderlineColor};
  display: inline-block;
  :hover {
    cursor: help;
  }
`;

export const Info: FunctionComponent<IInfoProps> = ({
  text,
  tooltip,
  textSize,
  iconSize,
  placement,
  color,
  variant,
  subject,
}) => {
  const theme = useContext(ThemeContext);

  const icon: IIconProps['icon'] = variant === 'danger' ? 'triangle-exclamation-solid' : 'circle-info-regular';

  return text ? (
    <Tooltip title={tooltip} placement={placement}>
      <span>
        <UnderlinedText color={color || theme.info.color} size={textSize}>
          {text}
        </UnderlinedText>
      </span>
    </Tooltip>
  ) : (
    <Icon
      subject={subject}
      color={color || theme.info.color}
      icon={icon}
      size={iconSize}
      tooltip={{ title: tooltip, placement }}
    />
  );
};

Info.displayName = 'Info';
