import { cloneDeep } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { CheckboxList, type ICheckboxList } from '../../Checkbox/CheckboxList.component';
import { CheckboxListSingle } from '../../Checkbox/types';
import { Flex } from '../../containers/Flex/Flex.component';
import { Group } from '../../containers/Group/Group.component';
import { InputSearch } from '../../Input';
import { IInputProps } from '../../Input/Input.component';
import { lightV2Theme } from '../../themes';
import { Link, Text } from '../../typography';
import { FILTER_CUSTOM_VALUE_TYPE } from '../constants';
import type { ISearchAndOptions } from '../types';
import { filterUtils } from '../utils/utils';
import { SingleOption } from './SingleOption';

export const SearchAndOptions: React.FC<ISearchAndOptions> = ({
  onChange,
  handleSearch,
  value,
  options,
  loading,
  reportCb,
  enableExclude,
  onShowMore,
  emptyState,
  description,
  creatable,
  showActions,
  searchPlaceholder = 'Search for values',
  maxVisibleOptions = 10,
  minNumberOfCheckedItems,
  subject,
}) => {
  const [searchFilter, setSearchFilter] = useState<string | null>(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const appTheme = useTheme() as typeof lightV2Theme;

  const MAX_ALLOWED_OPTIONS = isScrollable ? maxVisibleOptions : 5;
  const exceedsMaxAllowedOptions = options.total > MAX_ALLOWED_OPTIONS;

  const shouldRenderShowMoreButton =
    exceedsMaxAllowedOptions && !isScrollable && options.results.length > MAX_ALLOWED_OPTIONS;

  const filterResults = useMemo<ICheckboxList['options']>(() => {
    const filteredResults = options.results.filter(option =>
      option.title
        .toString()
        .toLowerCase()
        .includes(searchFilter?.toLowerCase() ?? ''),
    );

    if (creatable && filteredResults.length === 0 && searchFilter) {
      filteredResults.push({
        id: searchFilter,
        title: searchFilter,
        type: FILTER_CUSTOM_VALUE_TYPE,
        desc: 'Custom value',
      });
    }

    return filteredResults.map(option => ({
      label: (
        <SingleOption
          enableExclude={enableExclude}
          option={option}
          searchFilter={searchFilter}
          appTheme={appTheme}
          value={value}
          onChange={onChange}
          reportCb={reportCb}
        />
      ),
      value: option,
    }));
  }, [searchFilter, value, enableExclude, appTheme, onChange, reportCb, creatable, options.results, isScrollable]);

  const shownOptions = isScrollable ? filterResults : filterResults.slice(0, MAX_ALLOWED_OPTIONS);

  const handleToggleOne = (item: CheckboxListSingle, checked: boolean) => {
    reportCb?.(checked ? 'Check option' : 'Uncheck option');
    onChange(
      checked
        ? value.concat({
            type: item.value.type,
            id: item.value.id,
            state: 'ENABLED',
            isDisabled: false,
          })
        : value.filter(selectedItem => !filterUtils.areTheyEqual(item.value, selectedItem)),
    );
  };

  const handleChange: IInputProps['onChange'] = async searchTerm => {
    await handleSearch?.({ filter: { searchTerm }, pagination: { pageNumber: 1, pageSize: options.total } });

    setSearchFilter(searchTerm.toString());
    setIsScrollable(prev => (prev ? prev : true));
  };

  const handleShowMore = async () => {
    await onShowMore?.();

    setIsScrollable(true);
  };

  const handleListActions = (selectedOptions: CheckboxListSingle[]) => {
    if (selectedOptions.length === 0) {
      reportCb?.('Clear all');
      onChange([]);
    } else {
      const newSelectedOptions: any = cloneDeep(selectedOptions);

      value.forEach(checkedItem => {
        if (!newSelectedOptions.find(option => option.id === checkedItem.id)) {
          newSelectedOptions.push(checkedItem);
        }
      });

      reportCb?.('Select all');

      onChange(newSelectedOptions);
    }
  };

  return (
    <Flex flexDirection={'column'} context="search-and-options" width={'100%'} subject={subject}>
      <InputSearch
        loading={loading}
        onChange={handleChange}
        placeholder={searchPlaceholder}
        mb={2}
        subject={subject ?? 'filter-search'}
      />
      {shownOptions.length === 0 ? (
        emptyState
      ) : (
        <>
          {showActions && (
            <Flex width={'100%'} mb={2}>
              <CheckboxList.Actions
                value={value}
                options={shownOptions}
                onChange={handleListActions}
                numberOfCheckedItems={shownOptions.filter(option => filterUtils.isOptionSelected(option, value)).length}
              />
            </Flex>
          )}
          <CheckboxList
            subject={subject}
            getItemKey={item => JSON.stringify(item.value)}
            isItemChecked={option => filterUtils.isOptionSelected(option, value)}
            isItemDisabled={option => filterUtils.isOptionDisabled(option, value)}
            options={shownOptions}
            maxVisibleItems={shownOptions.length < MAX_ALLOWED_OPTIONS ? shownOptions.length : MAX_ALLOWED_OPTIONS}
            value={[]}
            onChange={onChange}
            toggleOne={handleToggleOne}
            fullWidth
            numberOfCheckedItems={value.length}
            minNumberOfCheckedItems={minNumberOfCheckedItems}
          />
          {(shouldRenderShowMoreButton || description) && (
            <Group pt={2} fullWidth justifyContent="space-between" alignItems="center">
              {shouldRenderShowMoreButton && (
                <Link whiteSpace="nowrap" size={12} onClick={handleShowMore} subject="show-more-options">
                  Show more ({options.results.length - MAX_ALLOWED_OPTIONS})
                </Link>
              )}
              {description && (
                <span style={{ marginLeft: 'auto' }}>
                  <Text size={12} color={'royalBlue.700'} ellipsis={{ openDelayInMs: 300 }}>
                    {description}
                  </Text>
                </span>
              )}
            </Group>
          )}
        </>
      )}
    </Flex>
  );
};
