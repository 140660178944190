import { colorService } from '@logz-pkg/utils';
import { grayPalette, greenPalette, redPalette, yellowPalette, royalBluePalette } from '../../themes/colors';

export const NotificationLightTheme = {
  borderColor: grayPalette[300],
  backgroundColor: grayPalette[0],
  shadowFrom: colorService.convertStringToRGBA(grayPalette[1000], 0.2),
  shadowTo: colorService.convertStringToRGBA(grayPalette[1000], 0.12),
  success: {
    accentColor: greenPalette[600],
    iconColor: greenPalette[600],
  },
  info: {
    accentColor: grayPalette[500],
    iconColor: grayPalette[700],
  },
  warning: {
    accentColor: yellowPalette[600],
    iconColor: yellowPalette[600],
  },
  danger: {
    accentColor: redPalette[600],
    iconColor: redPalette[600],
  },
  progress: {
    accentColor: royalBluePalette[1000],
    iconColor: royalBluePalette[1000],
  },
};
