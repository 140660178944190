import { set, get, isEqual } from 'lodash';

export interface IMatchAllQuery {
  match_all?: {};
}

export interface IK5Query extends IMatchAllQuery {
  query_string?: {
    query: string;
    analyze_wildcard: true;
  };
}

export class K5QueryUtils {
  static get DefaultQuery(): IMatchAllQuery {
    return { match_all: {} };
  }

  static isK5Query(query: IK5Query): boolean {
    return Boolean(query && (query.query_string || query.match_all));
  }

  static isDefault(query: IK5Query): boolean {
    return isEqual(query, K5QueryUtils.DefaultQuery);
  }

  static compose(value = ''): IK5Query {
    if (Boolean(value)) {
      return { query_string: { query: value, analyze_wildcard: true } };
    }

    return K5QueryUtils.DefaultQuery;
  }

  static decorate(query: IK5Query = {}, value: string): void {
    set(query, 'query_string.query', value);
    set(query, 'query_string.analyze_wildcard', true);
  }

  static getQueryString(query: IK5Query): string {
    return get(query, 'query_string.query') || ''; // empty string for match_all case
  }

  /**
   * Must preserve query reference, modify don't override.
   */
  static update(query: IK5Query, value: string): IK5Query {
    if (K5QueryUtils.isK5Query(query)) {
      if (K5QueryUtils.isDefault(query)) delete query.match_all;

      K5QueryUtils.decorate(query, value);
    }

    return query;
  }
}
