import { FormikErrors } from 'formik';
import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
import { Flex } from '../containers/Flex/Flex.component';
import { IIconProps, Icon } from '../icons/Icon.component';
import { PopoverError } from '../popover/PopoverError.component';
import { PopoverPlacement } from '../popover/types';
import { Spinner } from '../progress/Spinner/Spinner.component';
import { Text } from '../typography';
import { extractContainerProps } from '../utils';
import { InputVariants, StyledInput } from './Input.styles';
import { DebouncedInput } from './DebouncedInput.component';

export interface IInputProps extends React.HTMLProps<HTMLInputElement>, SpaceProps {
  icon?: IIconProps['icon'];
  shrink?: boolean;
  centerText?: boolean;
  className?: string;
  loading?: boolean;
  debounceTime?: number;
  error?: any[] | string | boolean | string[] | FormikErrors<any> | FormikErrors<any>[];
  errorPlacement?: PopoverPlacement;
  label?: string;
  helper?: string | ReactNode;
  disableAutoComplete?: boolean;
  fontSize?: string;
  subject?: string;
  variant?: InputVariants;
  context?: string;
  errorOverflow?: boolean;
  onEnterPress?: (args?: any) => void;
  handleKeyPress?: (args?: any) => void;
  setValueAndDebounce?: (args?: any) => void;
  onChange?: (e?: any) => void;
  borderless?: boolean;
  disableEnter?: boolean;
  iconSide?: 'right' | 'left';
  flex?: number;
}

export const StatusIcon = styled.div<{ iconSide?: IInputProps['iconSide'] }>`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  ${props => (props.iconSide === 'left' ? 'left: 8px;' : 'right: 8px;')}
  pointer-events: none;
`;

// Modern browsers ignore 'off' value for autocomplete, using 'new-password' instead, which disables autocomplete
export const autoCompleteOffValue = 'new-password';

const StyledLabel = styled(props => <Text color={'gray.800'} {...props} />)`
  line-height: 1;
`;

export const BaseInput: FunctionComponent<IInputProps> = ({
  loading = false,
  label,
  helper,
  error,
  errorPlacement,
  disableAutoComplete = false,
  children,
  fontSize = 'medium',
  type = 'text',
  placeholder = '',
  debounceTime = 0,
  shrink = false,
  centerText = false,
  width,
  height,
  subject = 'input',
  variant = 'primary',
  errorOverflow,
  icon = null,
  onEnterPress,
  iconSide = 'right',
  flex = 1,
  value,
  ...props
}) => {
  const { containerProps, ...rest } = extractContainerProps(props);

  return (
    <Flex
      flexDirection={'column'}
      flex={flex}
      mb={3}
      {...containerProps}
      position={'relative'}
      width={width}
      maxWidth={shrink ? width : null}
      context="input"
      subject={subject}
    >
      {label && <StyledLabel mb={1}>{label}</StyledLabel>}
      <PopoverError error={error} placement={errorPlacement} subject={`${subject}-error`} overflow={errorOverflow}>
        <StyledInput
          {...{
            variant,
            error,
            size: fontSize,
            ...(disableAutoComplete && {
              autoComplete: autoCompleteOffValue,
            }),
            type,
            height,
            placeholder,
            debounceTime,
            shrink,
            centerText,
            width,
            iconSide,
            value,
            onKeyDown: (e: any) => e.key === 'Enter' && onEnterPress?.(e.target?.value),
            ...rest,
          }}
        />
      </PopoverError>
      {children}
      {loading && (
        <StatusIcon>
          <Spinner size="s" />
        </StatusIcon>
      )}
      {icon && (!loading || iconSide === 'left') && (
        <StatusIcon iconSide={iconSide}>
          <Icon icon={icon} size={16} color={value ? 'blue.1000' : 'gray.500'} />
        </StatusIcon>
      )}
      {helper && (
        <Text size={12} pt={1} color={'gray.500'} weight={500} variant={'faded'}>
          {helper}
        </Text>
      )}
    </Flex>
  );
};

export const Input: React.FC<IInputProps> = ({ debounceTime, ...props }) => {
  if (debounceTime) {
    return <DebouncedInput debounceTime={debounceTime} {...props} />;
  }

  return <BaseInput {...props} />;
};
