import React, { forwardRef, PropsWithChildren } from 'react';
import { IBaseButtonProps } from './types';
import { ComplexButton } from './ComplexButton';

export const PlainButton = forwardRef<HTMLButtonElement, PropsWithChildren<IBaseButtonProps>>(
  ({ onClick, ...props }, ref) => {
    const handleClick = async (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (!props.loading && onClick) {
        await onClick(event);
      }
    };

    return <ComplexButton ref={ref} onClick={handleClick} {...props} />;
  },
);

PlainButton.displayName = 'PlainButton';
