export enum DateRangePickerSubject {
  StartDateInput = 'date-range-picker-start-date-input',
  EndDateInput = 'date-range-picker-end-date-input',
  EndDateNowButton = 'date-range-picker-end-date-now-button',
  FormInput = 'date-range-picker-form-input',
  Popup = 'date-range-picker-popup',
  QuickTab = 'date-range-picker-quick-tab',
  QuickOptionPrefix = 'date-range-picker-quick-option',
  AbsoluteTab = 'date-range-picker-absolute-tab',
  AbsoluteTabApplyButton = 'date-range-picker-absolute-tab-apply-button',
  SecondaryLabel = 'date-range-picker-secondary-label',
}
