import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { borderColor, space, SpaceProps } from 'styled-system';
import { Color } from '../themes';

const sizes = {
  s: 1,
  m: 2,
  l: 4,
};

interface IDividerStyles {
  color?: Color | string;
  size?: 's' | 'm' | 'l';
  dashed?: boolean;
  borderColor?: Color | string;
}
interface IDividerProps extends IDividerStyles {
  vertical?: boolean;
}

type DividerProps = SpaceProps & IDividerProps;

const BaseDivider = styled.hr<IDividerStyles>`
  background: inherit;
  margin: 0;

  border-width: ${({ size }) => sizes[size] || sizes.s}px;
  border-style: ${({ dashed }) => (dashed ? 'dashed' : 'solid')};
  ${({ theme, color }) => (color ? borderColor : `border-color:${theme.divider.color}}`)}

  border-right: none;
  border-bottom: none;

  ${space};
`;

const StyledHorizontalDivider = styled(BaseDivider)`
  width: 100%;
  border-left: none;
`;

const StyledVerticalDivider = styled(BaseDivider)`
  min-height: 100%;
  max-height: 100vh;
  border-top: none;
`;

const verticalContainerStyle = { alignSelf: 'stretch' };
// This encapsulation is needed for auto-complete
export const Divider: FunctionComponent<DividerProps> = ({ vertical = false, ...props }) =>
  vertical ? (
    <div style={verticalContainerStyle}>
      <StyledVerticalDivider {...props} borderColor={props.color} />
    </div>
  ) : (
    <StyledHorizontalDivider {...props} borderColor={props.color} />
  );

Divider.displayName = 'Divider';
