import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { usePopoverState } from '../../../popover/Popover.provider';
import { Group } from '../../../containers/Group/Group.component';
import { Text } from '../../../typography/Text/Text.component';
import { Tooltip } from '../../../tooltip/Tooltip.component';
import { Icon } from '../../../icons/Icon.component';

interface IMultiSelectFilterLabelProps {
  label: string;
  value: string[];
  tooltip?: string;
}

const ActiveLabel = styled(Text)`
  font-weight: 400;
  color: ${({ theme }) => theme.searchFilters.button.active.label.color};
`;

export const SelectedValueText = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.searchFilters.button.active.label.color};
`;

export const MultiSelectFilterLabel: React.FC<IMultiSelectFilterLabelProps> = ({ label, value = [], tooltip = '' }) => {
  const { isOpen } = usePopoverState();
  const { searchFilters } = useContext(ThemeContext);

  return (
    <Group width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
      {value.length === 0 && (
        <Text noTextWrap weight={400} color={searchFilters.button.inactive.label.color}>
          {label}
        </Text>
      )}
      {value.length === 1 && (
        <ActiveLabel ellipsis>
          {label}
          {': '}
          <SelectedValueText>{tooltip}</SelectedValueText>
        </ActiveLabel>
      )}
      {value.length > 1 && (
        <Tooltip disableEvents={!value} title={isOpen ? '' : tooltip}>
          <ActiveLabel>
            {label} <SelectedValueText>({value.length})</SelectedValueText>
          </ActiveLabel>
        </Tooltip>
      )}
      <Icon
        size={12}
        color={value.length > 0 ? searchFilters.button.active.icon.color : searchFilters.button.inactive.icon.color}
        icon={'chevron-down-regular'}
      />
    </Group>
  );
};
