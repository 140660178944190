import { useEffect } from 'react';

const openingChars = ['"', "'", '(', '[', '{'] as const;
const closingChars = ['"', "'", ')', ']', '}'] as const;

type OpeningChars = typeof openingChars[number];
type ClosingChars = typeof closingChars[number];

const openCharsMap: { [key in OpeningChars]: ClosingChars } = {
  '"': '"',
  "'": "'",
  '(': ')',
  '[': ']',
  '{': '}',
};

const closingCharsMap: { [key in ClosingChars]: OpeningChars } = Object.fromEntries(
  Object.entries(openCharsMap).map(entry => entry.reverse()),
);

type UseMatchCharsProps = {
  inputElement: HTMLInputElement;
  onChange: (value: string) => void;
};

export const useMatchingChars = ({ inputElement, onChange }: UseMatchCharsProps) => {
  useEffect(() => {
    const handleBeforeInput = (e: InputEvent) => {
      let deletedChar;

      const target = e.target as HTMLInputElement;
      const { value, selectionStart, selectionEnd } = target;
      const char = e.data?.toString();

      if (e.inputType === 'deleteContentBackward') {
        deletedChar = target.value[selectionStart - 1];

        const nextChar = target.value[selectionStart];
        const isNothingSelected = selectionStart === selectionEnd;
        const inBetweenMatchingChars = Object.entries(openCharsMap).some(
          ([open, close]) => deletedChar === open && nextChar === close,
        );

        if (isNothingSelected && inBetweenMatchingChars) {
          e.preventDefault();

          const newValue = target.value.slice(0, selectionStart - 1) + target.value.slice(selectionStart + 1);

          target.value = newValue;
          target.setSelectionRange(selectionStart - 1, selectionStart - 1);
          onChange(newValue);
        } else {
          onChange(target.value);
        }
      } else if (e.inputType === 'insertText') {
        if (closingChars.includes(char as ClosingChars)) {
          const nextChar = value[selectionStart];

          if (nextChar === char) {
            e.preventDefault();
            target.setSelectionRange(selectionStart + 1, selectionStart + 1);
            onChange(value);

            return;
          }
        }

        if (openingChars.includes(char as OpeningChars)) {
          e.preventDefault();

          const closingChar = openCharsMap[char as OpeningChars] || '';
          const isRangeSelected = selectionStart !== selectionEnd;

          let newValue = value;

          if (isRangeSelected) {
            newValue =
              value.slice(0, selectionStart) +
              char +
              value.slice(selectionStart, selectionEnd) +
              closingChar +
              value.slice(selectionEnd);
          } else {
            newValue = value.slice(0, selectionStart) + char + closingChar + value.slice(selectionStart);
          }

          target.value = newValue;
          target.setSelectionRange(selectionStart + 1, selectionStart + 1);
          onChange(newValue);
        } else {
          onChange(target.value);
        }
      } else {
        onChange(target.value);
      }
    };

    if (inputElement) {
      inputElement.addEventListener('beforeinput', handleBeforeInput);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('beforeinput', handleBeforeInput);
      }
    };
  }, [inputElement, onChange]);
};
