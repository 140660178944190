import { FunctionComponent, ReactNode } from 'react';
import { isEqual, isNil } from 'lodash';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Group } from '../containers/Group/Group.component';
import { FieldContainer } from '../containers/FieldContainer';
import { Icon } from '../icons/Icon.component';
import { StyledButtonGroupItem, StyledIcon, StyledText } from './ButtonGroup.styles';

interface IButtonGroupsOption {
  value: any;
  label?: string | JSX.Element;
  hideSelectedIndicator?: boolean;
  disabled?: boolean;
  subject?: string;
}

interface IItemComponentProps {
  value: any;
  label?: string | JSX.Element;
  selected: boolean;
  hideSelectedIndicator?: boolean;
  onClick(value: any): void;
  disabled?: boolean;
  index?: number;
  total?: number;
  subject?: string;
}

export interface IButtonGroupProps {
  options: IButtonGroupsOption[];
  value?: IButtonGroupsOption['value'] | IButtonGroupsOption['value'][];
  onChange?(selected: IButtonGroupsOption['value'] | IButtonGroupsOption['value'][]): void;
  multi?: boolean;
  subject?: string;
  label?: string | JSX.Element;
  disabled?: boolean;
  ItemComponent?: FunctionComponent<IItemComponentProps>;
  gap?: number;
}

const ItemContainer: FunctionComponent<{ subject: string; isSelected: boolean; children: ReactNode }> = ({
  children,
  subject,
  isSelected,
}) => {
  return (
    <span
      style={{ display: 'flex' }}
      {...generateLogzTestAttributes({
        context: 'button-group-item',
        subject,
      })}
      {...(isSelected ? { 'data-selected': '' } : {})}
    >
      {children}
    </span>
  );
};

export const ButtonGroup: FunctionComponent<IButtonGroupProps> = ({
  options = [],
  value,
  onChange = () => {},
  multi = false,
  subject,
  label,
  disabled,
  ItemComponent = ButtonGroupItem,
  gap = 0,
}) => {
  value = value ?? []; // Take care of default value in case of passed null

  const removeValue = selected => value.filter(val => !isEqual(val, selected));

  const addValue = selected => {
    if (multi) {
      return [...(value || []), selected];
    }

    return selected;
  };

  const isValueSelected = val => {
    if (multi) {
      return value && value.some(selected => isEqual(val, selected));
    }

    return isEqual(value, val);
  };

  const onClick = clickedValue => {
    if (isValueSelected(clickedValue)) {
      if (multi) {
        onChange?.(removeValue(clickedValue));
      }
    } else {
      onChange?.(addValue(clickedValue));
    }
  };

  return (
    <Group vertical fullWidth gap={1}>
      {label && <FieldContainer.Label>{label}</FieldContainer.Label>}
      <Group gap={gap} alignItems={'stretch'} flexWrap={'wrap'} context={'button-group'} subject={subject}>
        {options.map(
          ({ value: val, label, disabled: buttonDisabled, subject: itemSubject, hideSelectedIndicator }, index) => {
            const getSubject = () => {
              if (!isNil(itemSubject)) {
                return itemSubject;
              }

              return typeof label === 'string' ? label.toLowerCase() : `${index}`;
            };

            const isSelected = isValueSelected(val);

            return (
              <ItemContainer
                key={typeof label === 'string' ? label.toLowerCase() : `button-group-item-${index}`}
                subject={getSubject()}
                isSelected={isSelected}
              >
                <ItemComponent
                  value={val}
                  label={label ?? value}
                  index={index}
                  total={options?.length}
                  selected={isSelected}
                  onClick={onClick}
                  disabled={buttonDisabled || disabled}
                  hideSelectedIndicator={hideSelectedIndicator}
                />
              </ItemContainer>
            );
          },
        )}
      </Group>
    </Group>
  );
};

const ButtonGroupItem = ({
  value,
  label,
  selected,
  onClick,
  disabled,
  index,
  total,
  hideSelectedIndicator,
}: IItemComponentProps) => {
  const isIconOnly = (label as JSX.Element).type === Icon;

  return (
    <StyledButtonGroupItem
      selected={selected}
      onClick={() => !disabled && onClick(value)}
      disabled={disabled}
      index={index}
      total={total}
      iconOnly={isIconOnly}
      hideSelectedIndicator={hideSelectedIndicator}
    >
      {isIconOnly ? (
        <>{label}</>
      ) : (
        <>
          {!hideSelectedIndicator && <StyledIcon visible={selected} />}
          <StyledText>{label}</StyledText>
        </>
      )}
    </StyledButtonGroupItem>
  );
};
