import { FunctionComponent } from 'react';
import { Text } from '../../../../typography/Text/Text.component';
import { ConfirmModal } from '../../../../Modal/ConfirmModal';
import { InlineNotification } from '../../../../InlineNotification/InlineNotification.component';
import { IActionModalProps } from '../interfaces';

const defaultConfirmationText = "Other users may be using it. This action can't be undone.";

export const DeleteActionModal: FunctionComponent<IActionModalProps> = ({
  open,
  onClose,
  onConfirm,
  modelName,
  count,
  confirmationText = defaultConfirmationText,
  notificationTitle,
  ...props
}) => (
  <ConfirmModal
    isOpen={open}
    variant={'danger'}
    title={`Delete ${count} ${count > 1 ? `${modelName}s` : modelName}`}
    confirmText={'Delete'}
    onConfirm={onConfirm}
    onClose={onClose}
    {...props}
  >
    {notificationTitle && <InlineNotification variant={'warning'} title={notificationTitle} noClose mb={3} />}
    <Text>{confirmationText || defaultConfirmationText}</Text>
  </ConfirmModal>
);
