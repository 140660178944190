import React, { ReactNode, forwardRef, useCallback, useContext, useImperativeHandle, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { ModalContext } from '../Modal';
import { PopoverArrow, PopoverContainer } from './popover.styles';
import { usePopoverOnClick, usePopoverOnHover, usePopoverOnResize } from './utilities.hook';
import { popoverConfig } from './popover.config';
import { usePopoverState } from './Popover.provider';
import type { FlipOptions, PopoverContentComponentProps, PopoverPlacement } from './types';

export interface IPopoverProps {
  placement?: PopoverPlacement;
  open?: boolean;
  setOpen?(value: boolean | ((value: boolean) => boolean)): void;
  showArrow?: boolean;
  flip?: Partial<FlipOptions>;
  variant?: 'info' | 'tooltip' | 'danger' | 'none';
  // This is to handle cases where the child element doesn't forward the ref given to him to the actual DOM, so we will wrap it with a span.
  wrapChildWithRef?: boolean;
  overflow?: boolean;
  onClose?(): void;
  stopPropagation?: boolean;
  trigger?: 'hover' | 'click';
  disabled?: boolean;
  closeDelayInMs?: number;
  openDelayInMs?: number;
  appendToBody?: boolean;
  topOffset?: number;
  children: ReactNode;
  offset?: number;
  zIndex?: number;
  appendToSelector?: string;
  onOpen?: () => void;
  subject?: string;
  overflowable?: boolean;
  transitionDurationMs?: number;
  Content?: {
    Component: React.FC<PopoverContentComponentProps>;
    props?: Record<string, any>;
  };
}

export type PopoverHandle = { update: () => void; setPopverOpenState: (value: boolean) => void };

export const Popover = forwardRef<PopoverHandle, IPopoverProps>(
  (
    {
      children,
      placement,
      open,
      setOpen,
      showArrow = false,
      flip = {},
      variant = 'info',
      wrapChildWithRef,
      overflow,
      onClose,
      stopPropagation = false,
      trigger = 'click',
      disabled,
      closeDelayInMs = 1000,
      openDelayInMs = 0,
      appendToBody = false,
      appendToSelector,
      topOffset,
      offset = 0,
      zIndex = 1,
      onOpen,
      Content,
      subject,
      overflowable,
      transitionDurationMs,
    },
    ref,
  ) => {
    const modalContext = useContext(ModalContext);
    // A ref for the original child
    const [referenceElement, setReferenceElement] = useState(null);
    // A ref for a span-wrapper so we could always have ref to hold on to.
    const [popperElement, setPopperElement] = useState(null);
    const { styles, attributes, update, state } = usePopper(
      referenceElement,
      popperElement,
      popoverConfig({
        placement,
        overflow,
        showArrow,
        flip,
        offset,
        overflowable,
      }),
    );

    const stylesWithTopOffset = { ...styles, popper: { ...styles.popper, top: topOffset } };

    const { setIsOpen } = usePopoverState();
    const [isOpenInternal, setIsOpenInternal] = useState<boolean>(false);

    const setPopverOpenState = (newValue: boolean) => {
      const currentValue = open ?? isOpenInternal;

      if (currentValue === newValue) return;

      setIsOpen(newValue);
      setIsOpenInternal(newValue);

      (newValue ? onOpen : onClose)?.();
    };

    const closePopover = () => {
      if (setOpen) {
        setOpen(false);
        onClose?.();
      } else {
        setPopverOpenState(false);
      }
    };

    useImperativeHandle(ref, () => ({ update, setPopverOpenState }));

    const openPopover = () => {
      if (setOpen) {
        setOpen(true);
        onOpen?.();
      } else {
        setPopverOpenState(true);
      }
    };

    const togglePopover = useCallback(
      (e: Event) => {
        if (stopPropagation) {
          e.stopPropagation();
        }

        const isOpen = open ?? isOpenInternal;

        if (isOpen) {
          closePopover();
        } else {
          openPopover();
        }
      },
      [open, isOpenInternal],
    );

    usePopoverOnResize({ referenceElement, isOpen: open ?? isOpenInternal, update });

    usePopoverOnHover({
      trigger,
      referenceElement,
      popperElement,
      closePopover,
      openPopover,
      disabled,
      closeDelayInMs,
      openDelayInMs,
    });

    usePopoverOnClick({ trigger, referenceElement, popperElement, closePopover, togglePopover, disabled });

    const child = React.Children.only(children) as any;

    const clonedChild = React.cloneElement(child, {
      ref: setReferenceElement,
    });

    const PopoverElement = (
      <PopoverContainer
        ref={setPopperElement}
        subject={subject}
        style={topOffset ? stylesWithTopOffset.popper : styles.popper}
        {...attributes.popper}
        variant={variant}
        isTooltip={appendToBody}
        zIndex={zIndex}
        transitionDurationMs={transitionDurationMs}
      >
        <Content.Component closePopover={closePopover} popoverState={state} {...Content.props} />
        {showArrow && <PopoverArrow style={styles.arrow} data-popper-arrow />}
      </PopoverContainer>
    );

    const getPopoverElement = () => {
      const selector = appendToBody ? 'body' : appendToSelector;

      const targetPortalElement = appendToBody || appendToSelector ? document.querySelector(selector) : modalContext;

      if (appendToBody || appendToSelector || modalContext) {
        return createPortal(PopoverElement, targetPortalElement);
      }

      return PopoverElement;
    };

    return (
      <>
        {/* We don't care that the ref is assigned both in the clonedChild and the span since React won't invoke that function */}
        {wrapChildWithRef ? <span ref={setReferenceElement}>{clonedChild}</span> : clonedChild}
        {/* If open, check for the need to put the popover on a modal (for overflowing it) or append it to document body (to support tooltip usages) */}
        {(open ?? isOpenInternal) && getPopoverElement()}
      </>
    );
  },
);

Popover.displayName = 'Popover';
