import { opacity } from '../../utils';
import { colors } from '../colors';

const header = {
  backgroundColor: 'white',
  chevron_background: opacity(colors.gray[500], 70),
  chevron_background_hover: opacity(colors.gray[500], 40),
  logo_background_hover: colors.gray[0],
  item_color: colors.gray[900],
  item_background: 'transparent',
  item_active_color: colors.blue[500],
  item_active_background: opacity(colors.blue[500], 10),
  item_hover_color: colors.gray[900],
  item_hover_background: opacity(colors.gray[500], 10),
};

export default header;
