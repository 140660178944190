import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';

interface ISizeCallbackWrapperProps extends React.PropsWithChildren {
  callback: (rect: DOMRect) => void;
  debounceTime?: number;
  isDisabled?: boolean;
  trackChildren?: boolean;
  disableHeight?: boolean;
  disableWidth?: boolean;
}

export const ResizeCallbackWrapper: React.FC<ISizeCallbackWrapperProps> = ({
  children,
  callback,
  debounceTime = 100,
  trackChildren = true,
  disableHeight,
  disableWidth,
  isDisabled = disableHeight && disableWidth,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const updateSize = useCallback(
    debounce(() => {
      if (ref.current) {
        callback(ref.current.getBoundingClientRect());
      }
    }, debounceTime),
    [callback, debounceTime],
  );

  useEffect(() => {
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [updateSize]);

  useEffect(() => {
    const updater = () => {
      requestAnimationFrame(updateSize);
    };

    const resizeObserver = new ResizeObserver(updater);
    const mutationObserver = new MutationObserver(updater);

    if (ref.current) {
      resizeObserver.observe(ref.current, { box: 'border-box' });

      const attributeFilter = [...(disableHeight ? [] : ['height']), ...(disableWidth ? [] : ['width'])];

      mutationObserver.observe(ref.current, { childList: trackChildren, subtree: trackChildren, attributeFilter });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [ref.current, updateSize]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={!isDisabled ? ref : null}>
      {children}
    </div>
  );
};
