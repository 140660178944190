import { parse, format } from 'url';
import { parse as parseQuery } from 'query-string';

export const removeHash = (s: string) => (s ? s.replace('#', '') : '');

export const parseUrlQuery = (url: string) => parse(url, true).query;

export const urlParser = (url: string, encodeHash = true) => {
  const parsedUrl = parse(url);
  let hashUrl;

  if (encodeHash) {
    hashUrl = parse(removeHash(parsedUrl.hash || ''));
  } else {
    [, hashUrl = ''] = url.split('#');
    hashUrl = parse(hashUrl);
  }

  return {
    parsedUrl,
    hashPathname: hashUrl.pathname || '',
    hashQuery: parseQuery(hashUrl.query || ''),
    parsedQuery: parseQuery(parsedUrl.query || ''),
  };
};

export const urlHashParams = (url = '') => urlParser(url).hashQuery || {};

export const replaceHost = (url, { hostname = null, host = null }) => {
  const { pathname, ...parsedUrl } = parse(url);

  return format({
    ...parsedUrl,
    host,
    hostname,
    pathname: pathname === '' || pathname === '/' ? null : pathname,
  });
};
