import { bluePalette, grayPalette, redPalette } from '../../themes/colors';

export const checkboxLightTheme = {
  iconColor: 'white',
  backgroundColor: 'white',
  borderColor: grayPalette[300],
  checked: {
    backgroundColor: bluePalette[700],
    borderColor: bluePalette[700],
  },
  disabled: {
    backgroundColor: grayPalette[300],
    borderColor: grayPalette[300],
  },
  list: {
    backgroundColor: {
      inactive: redPalette[100],
      hover: {
        default: grayPalette[100],
        inactive: redPalette[200],
      },
    },
  },
};
