import React from 'react';
import { DateRange, getTimeRange } from '@logz-pkg/utils';
import { StyledInputsContainer } from '../AbsoluteFilter.styles';
import { DateSection } from '../../types';
import { IAbsoluteFilterProps } from '../AbsoluteFilter';
import { extractValuesFromDateRange } from '../utils';
import { AbsoluteInput } from './AbsoluteInput';

export interface IAbsoluteInputsProps {
  activeSection: DateSection;
  onSectionChange: (section: DateSection) => void;
  shownDates: ReturnType<typeof extractValuesFromDateRange>['shownDates'];
  onChange: (newRange: DateRange, shouldSwitchSection?: boolean) => void;
  timezone: IAbsoluteFilterProps['timezone'];
  showEndDateNowButton: IAbsoluteFilterProps['showEndDateNowButton'];
}

export const AbsoluteInputs: React.FC<IAbsoluteInputsProps> = ({
  activeSection,
  onSectionChange,
  timezone,
  showEndDateNowButton,
  shownDates,
  onChange,
}) => {
  const [shownStart, shownEnd] = shownDates;

  const isEditingStart = activeSection === 'start';

  const handleUpdate = (newDate: Date, shouldSwitchSection: boolean) => {
    const startDate = isEditingStart ? newDate : shownStart;
    const endDate = isEditingStart ? shownEnd : newDate;
    const newTimeRange = getTimeRange(startDate, endDate);

    onChange(newTimeRange, shouldSwitchSection);
  };

  const handleNowButtonClick = () => {
    const newTimeRange = getTimeRange(shownStart, 'now');

    onChange(newTimeRange, false);
  };

  return (
    <StyledInputsContainer alignItems={'strech'} justifyContent="center" width={'100%'}>
      <AbsoluteInput
        isActive={activeSection === 'start'}
        onClick={() => onSectionChange('start')}
        onUpdate={handleUpdate}
        timezone={timezone}
        value={shownStart}
        section={'start'}
      />
      <AbsoluteInput
        isActive={activeSection === 'end'}
        onClick={() => onSectionChange('end')}
        onUpdate={handleUpdate}
        timezone={timezone}
        value={shownEnd}
        section={'end'}
        onNowButtonClick={showEndDateNowButton ? handleNowButtonClick : undefined}
      />
    </StyledInputsContainer>
  );
};
