import { forwardRef } from 'react';
import { Tooltip } from '../../tooltip/Tooltip.component';
import { IBaseButtonProps } from './types';
import { PlainButton } from './PlainButton';

export const BaseButton = forwardRef<HTMLButtonElement, IBaseButtonProps>(
  ({ tooltip, tooltipPlacement, ...props }, ref) =>
    tooltip ? (
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        <span>
          <PlainButton {...props} ref={ref} />
        </span>
      </Tooltip>
    ) : (
      <PlainButton {...props} ref={ref} />
    ),
);

BaseButton.displayName = 'BaseButton';
