import React from 'react';
import styled, { css } from 'styled-components';
import type { IMultiTagProps } from '../MultiTag.component';
import { Button } from '../../Button/Button.component';
import type { StyledMultiTag } from '../types';

const resetStyles = css`
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
`;

const StyleButton = styled(Button)<StyledMultiTag>`
  min-width: fit-content;
  padding: 4px;
  margin: 0px 0px;
  height: auto;
  min-height: auto;

  color: ${({ theme, state }) =>
    state === 'selected' ? theme.multiTag.closeButton.color.selected : theme.multiTag.closeButton.color.default};

  &:hover {
    ${resetStyles}
  }

  &:focus {
    ${resetStyles}
  }

  ${({ theme, state }) =>
    state === 'selected' &&
    css`
      &:hover {
        border-radius: 2px;
        background-color: ${theme.multiTag.closeButton.color.hover};
      }
    `}

  ${({ editMode }) =>
    editMode &&
    css`
      visibility: hidden;
    `};

  transition: all 0.2s ease-in-out;
`;

interface IDeleteButton {
  state: IMultiTagProps['state'];
  onDelete: IMultiTagProps['onDelete'];
  editMode: boolean;
}

export const DeleteButton: React.FC<IDeleteButton> = ({ state, onDelete, editMode }) => {
  const handleOnDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <StyleButton
      size="xs"
      icon={'xmark-regular'}
      variant={'tertiary'}
      onClick={handleOnDelete}
      {...{ state, editMode }}
    />
  );
};
