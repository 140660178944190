import React, { forwardRef, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { FlexDirectionProps, FlexProps, SpaceProps } from 'styled-system';
import { Flex } from '../Flex/Flex.component';

const Container = styled(Flex)<{ noOverflow?: boolean }>`
  position: relative;
  ${({ noOverflow }) =>
    !noOverflow &&
    css`
      overflow: auto;
    `}

  flex-grow: 1;
`;

interface IPageBodyProps {
  subject?: string;
  // Tp support the migration of the new PageHeader templates
  noOverflow?: boolean;
  children: React.ReactNode;
}

export const PageBody: FunctionComponent<IPageBodyProps & SpaceProps & FlexProps & FlexDirectionProps> = forwardRef<
  HTMLDivElement,
  IPageBodyProps & SpaceProps & FlexProps & FlexDirectionProps
>(({ children, subject, flexDirection = 'column', ...props }, ref) => (
  <Container ref={ref} subject={subject} flexDirection={flexDirection} {...props}>
    {children}
  </Container>
));
