import styled from 'styled-components';
import { Icon } from '../icons/Icon.component';

export const Li = styled.li`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
`;

export const StyledIcon = styled(Icon)`
  transform: translateY(3px);
`;
