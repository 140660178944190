import styled, { css } from 'styled-components';
import { Ellipsis } from '../../typography/Ellipsis/Ellipsis.component';
import { TableCell } from './Cell.component';
import { Sort } from './Sort';

const TableHeaderCellStyled = styled(({ ...props }) => <TableCell {...props} />)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  min-height: 45px;
  width: 100%;

  ${({ numeric }) =>
    numeric &&
    css`
      align-items: flex-end;
    `};

  &:not(:nth-child(2)) {
    border-left: 1px solid ${({ theme }) => theme.colors.border};
  }

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `};

  ${({ theme, highlight }) =>
    highlight &&
    css`
      background-color: ${theme.table.header.cellHighlight};
    `};
`;

export const TableHeaderCell = styled(({ onSort, sortDirection, defaultSortingDirection, ...props }) => (
  <TableHeaderCellStyled {...props}>
    <Ellipsis maxLines={1} wordBreak={'break-all'}>
      <span>{props.children}</span>
    </Ellipsis>
    <Sort onClick={onSort} sortDirection={sortDirection} defaultSortingDirection={defaultSortingDirection} />
  </TableHeaderCellStyled>
))`
  ${Sort} {
    ${({ sortDirection }) =>
      !sortDirection &&
      css`
        opacity: 0;
        transition: opacity 0.1s ease-in-out;
      `}
  }
  :hover {
    ${Sort} {
      opacity: 1;
    }
  }
`;

TableHeaderCell.displayName = 'Table Header Cell';
TableHeaderCell.defaultProps = {
  py: 0,
  isTitle: true,
};
