import React from 'react';
import { CheckboxListSubjects } from '@logz-pkg/enums';
import { Flex } from '../containers/Flex/Flex.component';
import { Link } from '../typography';
import { BaseTheme } from '../themes/base';
import type { CheckBoxListActionsProps } from './types';

export const CheckboxListActions: React.FC<CheckBoxListActionsProps> = ({
  onChange,
  options,
  value,
  numberOfCheckedItems,
}) => {
  function selectAll() {
    onChange(options.map(option => option.value));
  }

  function cleanSelection() {
    onChange([]);
  }

  const checkedItems = numberOfCheckedItems ?? value.length;

  return (
    <Flex px={1} width={'100%'} justifyContent="space-between">
      <Link
        size={BaseTheme.sizes.text.medium}
        weight={BaseTheme.sizes.fontWeight.bold as 300 | 400 | 500}
        subject={CheckboxListSubjects.selectAll}
        onClick={selectAll}
        disabled={checkedItems === options.length}
      >
        Select all ({options.length})
      </Link>
      <Link
        size={BaseTheme.sizes.text.medium}
        weight={BaseTheme.sizes.fontWeight.bold as 300 | 400 | 500}
        subject={CheckboxListSubjects.unSelectAll}
        onClick={cleanSelection}
        disabled={value?.length === 0}
      >
        Clear
      </Link>
    </Flex>
  );
};
