import { colors } from '../../../themes/colors';

export const selectDarkTheme = {
  variants: {
    primary: {
      valueContainer: {
        textColor: colors.gray[100],
      },
      placeholder: {
        textColor: colors.gray[300],
      },
      focused: {
        borderColor: colors.blue[800],
        shadowColor: colors.blue[800],
        shadowSpread: 10,
      },
      hover: {
        borderColor: colors.blue[200],
      },
      menu: {
        textColor: colors.gray[300],
        borderColor: colors.blue[800],
        backgroundColor: colors.blue[800],
        focusedOption: {
          backgroundColor: colors.blue[700],
        },
        selectedOption: {
          backgroundColor: colors.blue[800],
          textColor: colors.blue[300],
        },
        group: {
          borderColor: colors.gray[300],
          textColor: colors.royalBlue[1000],
        },
        empty: {
          textColor: colors.gray[700],
        },
        disabledOption: {
          textColor: colors.gray[500],
        },
      },
      indicator: {
        iconColor: 'white',
        hover: {
          iconColor: 'white',
        },
        clear: {
          borderColor: colors.gray[500],
        },
      },
      multiValue: {
        container: {
          borderColor: colors.gray[400],
          backgroundColor: colors.gray[300],
        },
      },
    },
    secondary: {
      valueContainer: {
        textColor: colors.gray[100],
      },
      placeholder: {
        textColor: colors.gray[300],
      },
      focused: {
        borderColor: colors.blue[800],
        shadowColor: colors.blue[800],
        shadowSpread: 10,
      },
      hover: {
        borderColor: colors.blue[200],
      },
      menu: {
        textColor: colors.gray[300],
        borderColor: colors.blue[800],
        backgroundColor: colors.blue[800],
        focusedOption: {
          backgroundColor: colors.blue[700],
        },
        selectedOption: {
          backgroundColor: colors.blue[800],
          textColor: colors.blue[300],
        },
        group: {
          borderColor: colors.gray[300],
          textColor: colors.royalBlue[1000],
        },
        empty: {
          textColor: colors.gray[700],
        },
        disabledOption: {
          textColor: colors.gray[500],
        },
      },
      indicator: {
        iconColor: 'white',
        hover: {
          iconColor: 'white',
        },
        clear: {
          borderColor: colors.gray[500],
        },
      },
      multiValue: {
        container: {
          borderColor: colors.gray[400],
          backgroundColor: colors.gray[300],
        },
      },
    },
  },
};
