import { components } from 'react-select';
import { Icon } from '../../../icons/Icon.component';
import { SelectIconButton } from '../Select.styles';

export const SelectDropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <SelectIconButton variant={props.selectProps.variant}>
      <Icon icon={'chevron-down-regular'} size={10} />
    </SelectIconButton>
  </components.DropdownIndicator>
);

SelectDropdownIndicator.displayName = 'SelectDropdownIndicator';
