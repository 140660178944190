import { isNil, isNumber } from 'lodash';
import moment, { Moment } from 'moment-timezone';

export type DateInput = [Moment, Moment] | [Date, Date] | [number, number];
export type RelativeUnit = 's' | 'm' | 'h' | 'd' | 'w';

type PlusOrMinus = '+' | '-';

type BaseRelativeValue = `now` | `now-${number}${RelativeUnit}`;

export type RelativeValue = BaseRelativeValue | `-${number}${RelativeUnit}`;
export type ElasticValue =
  | BaseRelativeValue
  | `${BaseRelativeValue}/${RelativeUnit}`
  | `${BaseRelativeValue}/${RelativeUnit}${PlusOrMinus}${number}${RelativeUnit}`
  | `${BaseRelativeValue}/${RelativeUnit}${PlusOrMinus}${number}${RelativeUnit}${number}${RelativeUnit}`
  | `${BaseRelativeValue}/${RelativeUnit}${PlusOrMinus}${number}${RelativeUnit}${number}${RelativeUnit}${number}${RelativeUnit}`;

const DURATION_UNITS = ['ms', 's', 'm', 'h', 'd', 'w', 'M', 'y'] as const;

export type DurationUnit = typeof DURATION_UNITS[number];
export type DurationString = `${number}${DurationUnit}`;
export type DurationTuple = [number, DurationUnit];

export type DateRange = {
  start: { type: 'relative'; value: RelativeValue } | { type: 'absolute'; value: number };
  end: { type: 'relative'; value: RelativeValue } | { type: 'absolute'; value: number };
};

export const relativeDate = (value: RelativeValue): { type: 'relative'; value: RelativeValue } => ({
  type: 'relative',
  value,
});

export const absoluteDate = (value: number | Moment | Date): { type: 'absolute'; value: number } => ({
  type: 'absolute',
  value: value.valueOf(),
});

export const isAbsoluteDate = value => moment.isMoment(value) || isNumber(value) || moment.isDate(value);

export const isRelativeDate = (value): value is RelativeValue => {
  if (typeof value !== 'string') {
    return false;
  }

  if (value.includes('/')) {
    const relativeDatePattern = /^now([+-]\d+[smhdw])?(\/[smhdw])?((?:[+-]\d+[smhdw])*)$/;

    return relativeDatePattern.test(value);
  }

  const relativeDatePattern = /^now$|^-\d+[smhdwMy]$|^now-\d+[smhdwMy]$/;

  return relativeDatePattern.test(value);
};

export const getTimeRangeVal = (value: number | Moment | Date | RelativeValue) =>
  isRelativeDate(value) ? relativeDate(value) : absoluteDate(value);

export const getTimeRange = (
  start: number | Moment | Date | RelativeValue,
  end: number | Moment | Date | RelativeValue,
) => ({
  start: getTimeRangeVal(start),
  end: getTimeRangeVal(end),
});

export const elasticToMoment = (elasticTime: ElasticValue, timezone?: string): Moment => {
  const now = timezone ? moment.tz(timezone) : moment();

  if (elasticTime.startsWith('now')) {
    let result = now.clone();

    const regex = /now([+-]\d+[smhdw])?(\/[smhdw])?((?:[+-]\d+[smhdw])+)?/;
    const match = elasticTime.match(regex);

    if (match) {
      const [, offset, round, additionalOffsets] = match;

      if (offset) {
        const amount = parseInt(offset.slice(1), 10);
        const unit = offset.slice(-1) as RelativeUnit;

        result = offset.startsWith('+') ? result.add(amount, unit) : result.subtract(amount, unit);
      }

      if (round) {
        const unit = round.slice(1) as RelativeUnit;

        result = result.startOf(unit);
      }

      if (additionalOffsets) {
        const additionalMatches = additionalOffsets.matchAll(/([+-]\d+[smhdw])/g);
        const matchesAdditionalMatches = Array.from(additionalMatches); // Convert to an array

        for (const additionalOffset of matchesAdditionalMatches) {
          const offsetStr = additionalOffset[0];
          const amount = parseInt(offsetStr.slice(1), 10);
          const unit = offsetStr.slice(-1) as RelativeUnit;

          result = offsetStr.startsWith('+') ? result.add(amount, unit) : result.subtract(amount, unit);
        }
      }

      return result;
    }

    throw new Error('Invalid elastic time format');
  } else {
    throw new Error('Invalid elastic time format');
  }
};

export const relativeToMoment = (value: RelativeValue | ElasticValue, timezone?: string): Moment => {
  if (value === 'now') return moment();

  if (value.includes('/')) return elasticToMoment(value as ElasticValue, timezone);

  const [, amount, unit] = value.match(/-(\d+)(\w)$/);

  if (!isNil(timezone)) {
    return moment.tz(timezone).subtract(Number(amount), unit as RelativeUnit);
  }

  return moment().subtract(Number(amount), unit as RelativeUnit);
};

export const dateToUnix = (value: number) => {
  const isDateUix = new Date(value).getFullYear() === 1970;

  return !isDateUix ? moment(value).unix() : value;
};

export const elasticToAbsolute = (value: ElasticValue | number): number => {
  if (typeof value === 'number' || !isNaN(Number(value))) {
    const isDateUix = new Date(value).getFullYear() === 1970;

    return !isDateUix ? moment(value).unix() : (value as number);
  }

  const momentValue = elasticToMoment(value as ElasticValue);

  return momentValue.unix();
};

export const dateToAbsolute = (value: RelativeValue | ElasticValue | number): number => {
  if (typeof value === 'number' || !isNaN(Number(value))) {
    const isDateUix = new Date(value).getFullYear() === 1970;

    return !isDateUix ? moment(value).unix() : (value as number);
  }

  const momentValue = relativeToMoment(value as RelativeValue);

  return momentValue.unix();
};

export const dateToMoment = (value: RelativeValue | ElasticValue | number): Moment => {
  return moment(dateToAbsolute(value) * 1000);
};

export const dateRangeToMomentTuple = ({ start, end }: DateRange): [Moment, Moment] => {
  return [
    start.type === 'absolute' ? moment(start.value) : relativeToMoment(start.value),
    end.type === 'absolute' ? moment(end.value) : relativeToMoment(end.value),
  ];
};

export const dateTupleToAbsoluteRange = ([startDate, endDate]: DateInput): DateRange => ({
  start: { type: 'absolute', value: moment(startDate).valueOf() },
  end: { type: 'absolute', value: moment(endDate).valueOf() },
});

const isDurationUnit = (value: string): value is DurationUnit => DURATION_UNITS.includes(value as DurationUnit);

export const durationStringToTuple = (duration: DurationString): DurationTuple => {
  const [, amountStr, unit] = duration.match(/(\d*)(\w*)$/);
  const amount = Number(amountStr);

  if (!isNaN(amount) && isDurationUnit(unit)) {
    return [amount, unit];
  }

  return null;
};

export const isDurationString = (value: string): value is DurationString => {
  return value && Boolean(durationStringToTuple(value as DurationString));
};

export const durationToText = (duration: DurationString): string => {
  const text = {
    '1h': 'An hour ago',
    '1d': 'A day before',
    '1w': 'A week before',
    '1M': 'A month before',
  }[duration];

  if (text) return text;

  const [amount, unit] = durationStringToTuple(duration);

  const unitText = {
    ms: 'millisecond',
    s: 'second',
    m: 'minute',
    h: 'hour',
    d: 'day',
    w: 'week',
    M: 'month',
    y: 'year',
  }[unit];

  return `${amount} ${unitText}${amount > 1 ? 's' : ''} ago`;
};

export const timeAgo = (dateString: Date): string => {
  const now = new Date().getTime();
  const past = new Date(dateString).getTime();
  const diffInMs = now - past;

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds}s`;
  } else if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  }

  return `${days}d`;
};

export const isMoreThanTimeAgo = ({ date, timeInMinutes }: { date: Date; timeInMinutes: number }): boolean => {
  return moment().diff(moment(date), 'minutes') > timeInMinutes;
};

export const relativeToAbsoluteRange = ({ start, end }: DateRange): DateRange => {
  return {
    start: start.type === 'relative' ? { type: 'absolute', value: relativeToMoment(start.value).valueOf() } : start,
    end: end.type === 'relative' ? { type: 'absolute', value: relativeToMoment(end.value).valueOf() } : end,
  };
};

export const isRelativeRange = ({ start, end }: DateRange): boolean => {
  return start?.type === 'relative' && end?.type === 'relative';
};

export const hasAbsoluteInRange = ({ start, end }: DateRange): boolean => {
  return start?.type === 'absolute' || end?.type === 'absolute';
};
