import { ElementType } from 'react';
import { Card as PrimaryCard } from './Card.component';
import { CardFooter } from './Footer.component';

type CardType = typeof PrimaryCard & {
  Footer: ElementType;
};

const Card = PrimaryCard as CardType;

Card.Footer = CardFooter;

export { Card };
