import { colors } from '../../../themes/colors';
import { textDarkV2Theme } from '../../../typography/Text/themes/dark-v2.theme';

export const containerDarkV2Theme = {
  variants: {
    neutral: {
      background: colors.blue[1000],
      borderColor: colors.gray[1000],
      textColor: textDarkV2Theme.color.primary,
    },
    pLight: {
      background: colors.gray[100],
      borderColor: colors.gray[600],
      textColor: textDarkV2Theme.color.faded,
    },
    primary: {
      background: colors.gray[800],
      borderColor: colors.gray[500],
      textColor: textDarkV2Theme.color.primary,
    },
    pMedium: {
      background: colors.gray[700],
      borderColor: colors.gray[400],
      textColor: textDarkV2Theme.color.primary,
    },
    pDark: {
      background: colors.gray[500],
      borderColor: colors.gray[200],
      textColor: textDarkV2Theme.color.contrast,
    },
    pDeep: {
      background: colors.gray[400],
      borderColor: colors.gray[200],
      textColor: textDarkV2Theme.color.contrast,
    },
    sLight: {
      background: colors.blue[800],
      borderColor: colors.blue[500],
      textColor: textDarkV2Theme.color.primary,
    },
    secondary: {
      background: colors.blue[700],
      borderColor: colors.blue[400],
      textColor: textDarkV2Theme.color.primary,
    },
    sMedium: {
      background: colors.blue[500],
      borderColor: colors.royalBlue[300],
      textColor: textDarkV2Theme.color.contrast,
    },
    sDark: {
      background: colors.blue[400],
      borderColor: colors.gray[200],
      textColor: textDarkV2Theme.color.contrast,
    },
    sDeep: {
      background: colors.blue[300],
      borderColor: colors.gray[200],
      textColor: textDarkV2Theme.color.contrast,
    },
    tSoft: {
      background: colors.yellow[700],
      borderColor: colors.yellow[400],
      textColor: textDarkV2Theme.color.primary,
    },
    tLight: {
      background: colors.yellow[700],
      borderColor: colors.yellow[400],
      textColor: textDarkV2Theme.color.contrast,
    },
    tertiary: {
      background: colors.yellow[500],
      borderColor: colors.yellow[200],
      textColor: textDarkV2Theme.color.contrast,
    },
    tMedium: {
      background: colors.yellow[400],
      borderColor: colors.yellow[100],
      textColor: textDarkV2Theme.color.contrast,
    },
    tDark: {
      background: colors.yellow[300],
      borderColor: colors.yellow[0],
      textColor: textDarkV2Theme.color.contrast,
    },
    lightError: {
      background: colors.red[800],
      borderColor: colors.red[500],
      textColor: textDarkV2Theme.color.primary,
    },
    error: {
      background: colors.red[700],
      borderColor: colors.red[400],
      textColor: textDarkV2Theme.color.primary,
    },
    lightSuccess: {
      background: colors.green[800],
      borderColor: colors.green[500],
      textColor: textDarkV2Theme.color.primary,
    },
    success: {
      background: colors.green[600],
      borderColor: colors.green[300],
      textColor: textDarkV2Theme.color.primary,
    },
    darkSuccess: {
      background: colors.green[500],
      borderColor: colors.green[200],
      textColor: textDarkV2Theme.color.contrast,
    },
    lightRoyal: {
      background: colors.royalBlue[700],
      borderColor: colors.royalBlue[400],
      textColor: textDarkV2Theme.color.primary,
    },
    royal: {
      background: colors.royalBlue[500],
      borderColor: colors.royalBlue[200],
      textColor: textDarkV2Theme.color.primary,
    },
  },
};
