import { forwardRef, useEffect, useState } from 'react';
import { CrudTableTestSubject } from '@logz-pkg/enums';
import { Button } from '../../Button/Button.component';
import { CrudTableEvents, IAddButtonProps } from './Crud.types';

export const AddRecordButton = forwardRef<HTMLButtonElement, IAddButtonProps>(
  (
    { disabled = false, tableSubject, addButtonText = 'Add record', disabledTooltip, tooltipPlacement = 'bottom' },
    ref,
  ) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(disabled);

    const disableAddButton = () => setIsButtonDisabled(true);
    const enableAddButton = () => !disabled && setIsButtonDisabled(false);

    useEffect(() => {
      document.addEventListener(CrudTableEvents.TableIsLoading, disableAddButton);
      document.addEventListener(CrudTableEvents.TableIsReady, enableAddButton);

      return () => {
        document.removeEventListener(CrudTableEvents.TableIsLoading, disableAddButton);
        document.removeEventListener(CrudTableEvents.TableIsReady, enableAddButton);
      };
    }, []);

    const handleClick = () => {
      const event = new Event(CrudTableEvents.AddNewClicked);

      document.dispatchEvent(event);
    };

    return (
      <Button
        ref={ref}
        onClick={handleClick}
        icon={'plus-regular'}
        disabled={isButtonDisabled}
        subject={
          tableSubject ? `${tableSubject}-${CrudTableTestSubject.TableAddButton}` : CrudTableTestSubject.TableAddButton
        }
        disabledTooltip={disabledTooltip}
        tooltipPlacement={tooltipPlacement}
      >
        {addButtonText}
      </Button>
    );
  },
);
