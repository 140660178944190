import type { Table } from '@tanstack/react-table';
import { isNil } from 'lodash';
import { useRef } from 'react';

export const useDragDrop = <T extends object>(
  table: Table<T>,
  options?: { onColumnReorder?: (newOrder: string[]) => void },
) => {
  const columnBeingDragged = useRef<number>();

  const onDragStart = (e: React.DragEvent<HTMLElement>): void => {
    columnBeingDragged.current = Number(e.currentTarget.dataset.columnIndex);
  };

  const onDrop = (e: React.DragEvent<HTMLElement>, draggableColumns?: string[]): void => {
    e.preventDefault();

    if (isNil(columnBeingDragged.current)) return;

    const newPosition = Number(e.currentTarget.dataset.columnIndex);
    const currentCols = table.getVisibleLeafColumns().map(c => c.id);
    const colToBeMoved = currentCols.splice(columnBeingDragged.current, 1);
    const visibleColumn = table.getVisibleFlatColumns().map(c => c.id);

    if (!draggableColumns?.includes(visibleColumn[newPosition])) return;

    currentCols.splice(newPosition, 0, colToBeMoved[0]);
    table.setColumnOrder(currentCols);
    columnBeingDragged.current = undefined;

    if (options.onColumnReorder) {
      const columnsWithoutIndicator = currentCols.slice(1);

      options.onColumnReorder(columnsWithoutIndicator);
    }
  };

  return { onDragStart, onDrop };
};
