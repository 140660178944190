export enum LookupStrings {
  LookupNotFound = 'INVALID LOOKUP LIST',
  InvalidLookupError = 'The lookup is unavailable',
}
export enum LookupErrorCode {
  UNKNOWN_ERROR = 'LookupList/UNKNOWN_ERROR',
  DUPLICATE_VALUE = 'LookupList/DUPLICATE_VALUE',
  INSERTION_FAILED = 'LookupList/INSERTION_FAILED',
}
export enum LookupsTestSubject {
  LookupsListPage = 'lookup-list-page',
  LookupsListTable = 'lookup-list-table',
  LookupEditPage = 'lookup-edit-page',
  LookupEditNameInput = 'lookup-edit-name',
  LookupEditDescriptionInput = 'lookup-edit-description',
  LookupEditElementValueInput = 'lookup-element-value',
  LookupEditElementNoteInput = 'lookup-element-note',
  LookupEditElementTTLInput = 'lookup-element-ttl',
  LookupEditSubmit = 'lookup-edit-submit',
  DynamicLookupEditPage = 'dlist-edit-page',
  DynamicLookupCreateButton = 'dlist-create-button',
  DynamicEditFieldSelect = 'dlist-field-select',
  DynamicEditQuery = 'dlist-field-query',
  DynamicTimeframeField = 'dlist-field-timeframe',
  DynamicUpdateIntervalField = 'dlist-field-update-interval',
  DynamicElasticFilters = 'dlist-elastic-filters',
  LookupsUploadCSVButton = 'lookups-upload-csv-button',
  LookupUploadModal = 'lookup-upload-modal',
  LookupUploadModalSubmitButton = 'upload-csv-file',
  LookupUploadModalTTLInput = 'lookup-modal-ttl-input',
  LookupUploadModalErrorText = 'upload-error-text',
  LookupDefaultTTLValue = 'lookup-default-ttl-value',
  LookupDefaultTTLSelect = 'lookup-default-ttl-select',
  LookupDefaultTTLSaveButton = 'lookup-default-ttl-save',
  LookupDefaultTTLCancelButton = 'lookup-default-ttl-cancel',
  LookupDefaultTTLChangeButton = 'lookup-default-ttl-change',
  LookupEditElementTable = 'lookup-elements-table',
  AddLookupValue = 'add-lookup-value',
  DeleteLookupValue = 'delete-lookup-value',
  UpdateLookup = 'update-lookup',
  CreateLookup = 'create-lookup',
  CreateLookupMenu = 'create-lookup-menu',
  DeleteLookup = 'delete-lookup',
  UploadCSV = 'upload-csv',
  CronScheduleTestSubject = 'cron-schedule-input',
  DynamicLookupListLoadingHistorySubject = 'dynamic-lookup-list-loading-history',
}
export enum LookupListTypes {
  DYNAMIC = 'Dynamic',
  STATIC = 'Static',
}

export enum DynamicLookupListLoadingStatus {
  SUCCESS = 'Success',
  TRUNCATED = 'Truncated',
  ERROR = 'Error',
}
