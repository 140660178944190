export enum EventStatus {
  New = 'NEW',
  Assigned = 'ASSIGNED',
  InProgress = 'IN_PROGRESS',
  WaitingForResponse = 'WAITING_FOR_RESPONSE',
  FalsePositive = 'FALSE_POSITIVE',
  Resolved = 'RESOLVED',
}

export enum EventHistoryField {
  ASSIGNEE = 'ASSIGNEE',
  STATUS = 'STATUS',
  COMMENT = 'COMMENT',
}

export enum EventManagementTestSubjects {
  UpdateEvent = 'update-event',
  EventsManagementPage = 'em-events-management-page',

  FilterTimeframe = 'em-filter-timeframe',
  FilterAssignee = 'em-filter-assignee',
  FilterSeverity = 'em-filter-severity',
  FilterStatus = 'em-filter-status',
  ClearFilter = 'em-clear-filter',

  EventsTable = 'em-events-table',

  EventRecordGroup = 'em-group-event',
  EventRecordId = 'em-event-record-id',
  EventRecordName = 'em-event-record-name',
  EventRecordCount = 'em-event-record-count',
  EventRecordDescription = 'em-event-record-description',
  EventRecordStatus = 'em-event-record-status',
  EventRecordAssignee = 'em-event-record-assignee',
  EventRecordUpdatedAt = 'em-event-record-updated-at',
  EventRecordUpdatedBy = 'em-event-record-updated-by',
  EventRecordComment = 'em-event-record-comment',
  EventRecordCommentedBy = 'em-event-record-commented-by',
  EventRecordInvestigateButton = 'em-event-record-investigate',
  EventRecordRCAButton = 'em-event-record-rca',
  EventRecordEditButton = 'edit',

  EventDetailsModal = 'em-event-details-modal',
  EventDetailsDrawer = 'em-event-details-drawer',
  DrawerCloseButton = 'em-event-details-drawer-close-button',
  SubEvent = 'em-sub-event',
  EventRecordTitle = 'em-record-title',
  EventDetailsGroupId = 'em-event-details-group-id',
  EventDetailsDescription = 'em-event-details-description',
  EventDetailsRecordTriggeredAt = 'em-event-details-triggered-at',
  EventDetailsRecordId = 'em-event-details-id',
  EventDetailsGroupingType = 'em-event-details-grouping-type',
  EventDetailsRecordLink = 'em-event-details-link',

  EventHistoryModal = 'em-history-modal',
  EventHistoryId = 'em-event-history-id',
  EventHistoryDescription = 'em-event-history-description',
  EventHistoryRecordCreatedAt = 'em-event-history-record-created-at',
  EventHistoryRecordCell = 'em-event-history-record-cell',
  HistoryRecordChangedAssigneeBy = 'em-history-record-changed-assignee-by',
  HistoryRecordNewAssignee = 'em-history-record-new-assignee',
  HistoryRecordCommentedBy = 'em-history-record-commented-by',
  HistoryRecordNewComment = 'em-history-record-new-comment',
  HistoryRecordStatusChangedBy = 'em-history-record-status-changed-by',
  HistoryRecordStatusFrom = 'em-history-record-status-from',
  HistoryRecordStatusTo = 'em-history-record-status-to',
}

export enum SiemEventType {
  Group = 'GROUP',
  AlertEvent = 'ALERT_EVENT',
}

export enum EventModelGroupingType {
  AlertBased = 'ALERT_BASED',
  IpBased = 'IP_BASED',
}
export const eventManagementRCAPrompt = 'Help me investigate the root cause of a this security event.';
