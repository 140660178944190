import { grayPalette } from '../../../themes/colors';

export const textDarkV2Theme = {
  code: {
    backgroundColor: grayPalette[100],
    borderColor: `1px solid ${grayPalette[400]}`,
  },
  color: {
    primary: grayPalette[0],
    contrast: grayPalette[1000],
    faded: grayPalette[200],
    description: grayPalette[0],
    code: grayPalette[0],
  },
};
