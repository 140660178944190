import React, { FunctionComponent } from 'react';
import { Group } from '../Group/Group.component';
import { FieldDescription, FieldError, FieldHelper, FieldLabel } from './Components';
import { IFieldContainerProps } from './interfaces';

export const FieldContainer: FunctionComponent<IFieldContainerProps & { children: React.ReactNode }> = ({
  required,
  label,
  children,
  description,
  helper,
  fullWidth = true,
  labelSize = 'm',
  leftLabel,
  error,
}) => {
  const labelComponent =
    label && typeof label === 'string' ? (
      <FieldLabel required={required} labelSize={labelSize}>
        {label}
      </FieldLabel>
    ) : (
      label
    );

  const descriptionComponent =
    description && typeof description === 'string' ? <FieldDescription>{description}</FieldDescription> : description;

  const leftLabelComponent =
    leftLabel && typeof leftLabel === 'string' ? (
      <FieldLabel required={required} labelSize={labelSize}>
        {leftLabel}
      </FieldLabel>
    ) : (
      leftLabel
    );

  const helperComponent = typeof helper === 'string' ? <FieldHelper>{helper}</FieldHelper> : helper;
  const errorComponent = typeof error === 'string' ? <FieldError>{error}</FieldError> : error;

  return (
    <Group vertical gap={1} width={fullWidth ? '100%' : 'fit-content'} alignItems={'stretch'}>
      {label && (
        <Group alignItems={'center'} gap={1} width={'100%'}>
          {labelComponent}
          {descriptionComponent}
        </Group>
      )}
      <Group alignItems="center" width={'100%'}>
        {leftLabelComponent && (
          <Group gap={1}>
            {leftLabelComponent}
            {leftLabel && descriptionComponent}
          </Group>
        )}
        <Group vertical gap={1} width={'100%'} alignItems={'stretch'}>
          <>
            {children}
            {helper && !error && helperComponent}
            {error && errorComponent}
          </>
        </Group>
      </Group>
    </Group>
  );
};
