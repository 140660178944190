import { FC } from 'react';
import { CrudTableTestSubject } from '@logz-pkg/enums';
import { Button } from '../../Button/Button.component';
import { Group } from '../../containers/Group/Group.component';
import { Text } from '../../typography';
import { Select } from '../../inputs/Select/Select.component';
import { SelectSizes } from '../../inputs/Select/select-sizes.enum';

interface ITablePaginationProps {
  rowsPerPageOptions: number[];
  count: number;
  rowsPerPage: number;
  pageNumber: number;
  onChangePage(any): void;
  onChangeRowsPerPage(any): void;
}

export const TablePagination: FC<ITablePaginationProps> = ({
  rowsPerPageOptions,
  count,
  pageNumber,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
}: ITablePaginationProps) => {
  const paginationOptions = rowsPerPageOptions.map(o => ({ value: o, label: o }));
  const isLastPage = rowsPerPage * pageNumber >= count;
  const firstDisplayedRowNumber = rowsPerPage * (pageNumber - 1) + 1;
  const lastDisplayedRowNumber = isLastPage ? count : rowsPerPage * pageNumber;

  return (
    <Group fullWidth alignItems={'center'} justifyContent={'flex-end'} m={2} gap={8}>
      <Group gap={2}>
        <Text>Rows per page:</Text>
        <Select
          size={SelectSizes.Icon}
          subject={CrudTableTestSubject.PaginationPerPageSelector}
          borderless
          value={{ value: rowsPerPage, label: rowsPerPage }}
          onChange={({ value }) => onChangeRowsPerPage(value)}
          options={paginationOptions}
        />
      </Group>
      <Text>
        {firstDisplayedRowNumber}-{lastDisplayedRowNumber} of {count}
      </Text>
      <Group gap={3}>
        <Button
          subject={CrudTableTestSubject.PrevPageButton}
          size={'m'}
          variant={'tertiary'}
          round
          icon={'chevron-left-regular'}
          disabled={pageNumber === 1}
          onClick={() => {
            onChangePage(pageNumber - 1);
          }}
        />
        <Button
          size={'m'}
          variant={'tertiary'}
          round
          subject={CrudTableTestSubject.NextPageButton}
          icon={'chevron-right-regular'}
          disabled={isLastPage}
          onClick={() => {
            onChangePage(pageNumber + 1);
          }}
        />
      </Group>
    </Group>
  );
};
