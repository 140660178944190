import { grayPalette, redPalette, royalBluePalette } from '../../../themes/colors';

export const fieldContainerLightTheme = {
  requiredSignColor: royalBluePalette[1000],
  helper: {
    color: grayPalette[600],
  },
  error: {
    color: redPalette[800],
  },
  label: {
    // DEV-30432: handle different colors for different size
    s: {
      color: grayPalette[800],
    },
    m: {
      color: grayPalette[800],
    },
    l: {
      color: royalBluePalette[1000],
    },
  },
};
