import { useContext } from 'react';
import { components } from 'react-select';
import styled, { ThemeContext } from 'styled-components';
import { Text, Link } from '../../../typography';
import { Flex } from '../../../containers/Flex/Flex.component';
import { ISelectProps } from '../Select.types';

const StyledFlex = styled(Flex)<{ variant: ISelectProps['variant'] }>`
  border-bottom: 1px solid ${({ theme, variant }) => theme.select.variants[variant].menu.group.borderColor};
`;

export const SelectGroupHeading: typeof components.GroupHeading = ({
  children,
  action,
  actionName,
  selectProps: { variant },
}) => {
  const theme = useContext(ThemeContext);

  return (
    <StyledFlex justifyContent={'space-between'} alignItems={'center'} px={2} py={1} variant={variant}>
      <Text color={theme.select.variants[variant].menu.group.textColor} weight={700}>
        {children}
      </Text>
      {action && <Link onClick={action}>{actionName}</Link>}
    </StyledFlex>
  );
};

SelectGroupHeading.displayName = 'SelectGroupHeading';
