import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { useHover } from '@logz-pkg/react-hooks';
import { Text } from '../../typography';
import { Fade } from '../../Animations';
import { Icon } from '../../icons/Icon.component';
import { space } from '../../utils';
import { Flex } from '../../containers/Flex/Flex.component';
import { MenuItem, type IMenuItemProps } from './MenuItem.component';
import { Menu } from './Menu.component';

export interface IBaseSubMenuProps extends IMenuItemProps {
  label?: string;
  direction?: 'left' | 'right';
}

const SubMenuContent = styled(Menu)`
  z-index: 100;
  position: absolute;
  top: 0;

  ${({ direction, theme }) =>
    direction === 'left'
      ? css`
          right: calc(100% - ${theme.menu.inlineWhitespace});
        `
      : css`
          left: calc(100% - ${theme.menu.inlineWhitespace});
        `};
`;

const SubMenuIconStyled = styled(Icon)`
  position: absolute;
  right: 10px;
  color: ${({ theme }) => theme.menu.icon_color};

  :before {
    align-self: center;
  }
`;

const SubMenuContainer = styled(Flex).attrs<{ subject?: string }>(({ subject }) => ({
  flexDirection: 'column',
  'aria-label': subject ? `Sub Menu for ${subject}` : 'Sub Menu',
}))`
  position: relative;
  ${space};
`;

export const SubMenu: React.FC<PropsWithChildren<IBaseSubMenuProps>> = ({
  mx = 0,
  p = 0,
  direction = 'right',
  active,
  subject,
  label,
  href,
  onClick,
  icon,
  children,
  ...props
}) => {
  const { ref, isHovering } = useHover<HTMLDivElement>();

  return (
    <SubMenuContainer ref={ref} mx={mx} p={p} {...props}>
      <MenuItem active={active} subject={subject} href={href} onClick={onClick} icon={icon} pr={8}>
        <Text size={12} color={'inherit'}>
          {label}
        </Text>
        <SubMenuIconStyled icon={'chevron-right-regular'} size={12} />
      </MenuItem>

      {isHovering && (
        <Fade>
          <SubMenuContent direction={direction}>{children}</SubMenuContent>
        </Fade>
      )}
    </SubMenuContainer>
  );
};
