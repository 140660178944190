export enum PaymentSupportPanelSubject {
  Panel = 'payment-support-panel',

  InvoiceButton = 'payment-support-panel-invoice-button',
  InvoiceModal = 'payment-support-panel-invoice-modal',
  InvoiceSelect = 'payment-support-panel-invoice-select',
  InvoicePeriodRange = 'payment-support-panel-invoice-period-range',
  InvoiceNotification = 'payment-support-panel-invoice-notification',

  UpdateDetailsButton = 'payment-support-panel-update-details-button',
  UpdateDetailsModal = 'payment-support-panel-update-details-modal',
  UpdateDetailsNotification = 'payment-support-panel-update-details-notification',
}
