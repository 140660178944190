import React, { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';

const StyledTableBody = styled.tbody`
  background-color: ${({ theme }) => `${theme.colors.gray[0]}`};
  margin: 1px 1px 1px 0px;
`;

interface ITBodyProps extends PropsWithChildren {
  style: CSSProperties;
}

export const Body = React.forwardRef<HTMLTableSectionElement, ITBodyProps>(({ children, style }, ref) => (
  <StyledTableBody style={style} ref={ref}>
    {children}
  </StyledTableBody>
));

Body.displayName = 'TBody';
