import React, { FunctionComponent } from 'react';
import { IPopoverProps, Popover } from '../Popover.component';
import { HelperBody } from './HelperBody';

interface IComponentProps {
  closePopover: () => void;
  Content: IPopoverProps['Content'];
  hideCloseButton: boolean;
}

const Component: React.FC<IComponentProps> = ({ closePopover, Content, hideCloseButton }) => {
  return (
    <HelperBody
      hideCloseButton={hideCloseButton}
      body={<Content.Component closePopover={closePopover} {...(Content.props && { ...Content.props })} />}
      closeHelper={closePopover}
    />
  );
};
export interface IPopoverHelperProps extends Omit<IPopoverProps, 'content'> {
  hideCloseButton?: boolean;
}

export const PopoverHelper: FunctionComponent<IPopoverHelperProps> = ({
  children,
  placement = 'top',
  showArrow = false,
  Content,
  hideCloseButton = false,
  open,
  flip,
  onClose,
  trigger,
  disabled,
  closeDelayInMs,
  zIndex,
  offset,
  appendToBody,
}) => (
  <Popover
    closeDelayInMs={closeDelayInMs}
    showArrow={showArrow}
    placement={placement}
    open={open}
    flip={flip}
    onClose={onClose}
    trigger={trigger}
    disabled={disabled}
    zIndex={zIndex}
    offset={offset}
    appendToBody={appendToBody}
    Content={{ Component, props: { hideCloseButton, Content } }}
  >
    {children}
  </Popover>
);
