import { FunctionComponent, useContext, useState } from 'react';
import { useBoolean } from '@logz-pkg/react-hooks';
import { IBulkActionsProps } from '../BulkActionsBar';
import { BulkActionsStateContext } from '../../Crud';
import { Button } from '../../../../Button/Button.component';
import { ICustomBulkActionProps } from '../interfaces';

export const CustomBulkAction: FunctionComponent<ICustomBulkActionProps> = ({
  label,
  icon,
  onConfirm,
  Modal,
  confirmationText,
  notificationTitle,
}) => {
  const { modelName, selected, filter, onSearch, totalDataLength } =
    useContext<IBulkActionsProps>(BulkActionsStateContext);
  const { value: isModalOpened, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  return (
    <>
      <Button
        subject={`${label.toLowerCase()}-action-button`}
        variant={'secondary'}
        size={'s'}
        icon={icon}
        onClick={openModal}
      >
        {label}
      </Button>
      <Modal
        open={isModalOpened}
        onClose={closeModal}
        onConfirm={async fields => {
          try {
            setIsConfirming(true);
            await onConfirm({ ids: selected.isAllPagesSelected ? [] : selected.ids, filter, fields });
            onSearch();
          } finally {
            setIsConfirming(false);
            closeModal();
          }
        }}
        isConfirming={isConfirming}
        label={label}
        modelName={modelName}
        selected={selected}
        count={selected.ids.length || totalDataLength}
        confirmationText={confirmationText}
        notificationTitle={notificationTitle}
      />
    </>
  );
};
