export type Technology = typeof logzioTechnologies[number];
export const logzioTechnologies = [
  'python',
  'c++',
  'java',
  'c#',
  'dotnet',
  'nodejs',
  'node',
  'visualbasic',
  'php',
  'assembly',
  'sql',
  'fortran',
  'go',
  'matlab',
  'scratch',
  'delphiobjectpascal',
  'swift',
  'rust',
  'ruby',
  'kotlin',
  'cobol',
  'ada',
  'c',
  'mssql',
  'msql',
  'oracle',
  'db2',
  'postgresql',
  'redshift',
  'hive',
  'maxdb',
  'ingres',
  'redis',
  'kafka',
  'aws',
  'other',
] as const;
