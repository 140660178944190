import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isEqual, isNil } from 'lodash';
import React, { useCallback, useMemo, type FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import { Text } from '../typography/Text/Text.component';
import { plainToFlattenObject } from '../utils/flatten';
import { Menu } from './components/Menu';
import { Property } from './components/Property';
import { Value } from './components/Value';
import { useJsonFormatterState } from './json-formatter-state.hook';
import type { ContextMenu, Item, ParseError } from './types';

export interface IJsonFormatterProps {
  json: Record<string, any> | string;
  delimiter?: JSX.Element | string;
  flatten?: boolean;
  getPropertyContextMenu?: (item: Item) => ContextMenu;
  getValueContextMenu?: (item: Item) => ContextMenu;
  propertiesToExclude?: string[];
  onError?: (e: ParseError) => object;
  subject?: string;
  maxLines?: number;
  sortFn?: (itemA: any, itemB: any) => number;
  highlight?: Record<string, string | React.JSX.Element[]>;
}

export const JsonFormatter: FunctionComponent<IJsonFormatterProps> = React.memo(
  ({
    json,
    delimiter = ' ',
    maxLines,
    flatten,
    propertiesToExclude,
    getPropertyContextMenu,
    getValueContextMenu,
    onError,
    subject,
    highlight,
    sortFn,
  }) => {
    const { activeItem, activeMenu, popperStyles, popperAttributes, setPopperElement, clearActiveItem, handleClick } =
      useJsonFormatterState({
        getPropertyContextMenu,
        getValueContextMenu,
      });

    const safeSampleLog: object = useMemo(() => {
      let value;

      if (typeof json === 'string') {
        try {
          value = JSON.parse(json);
        } catch (error) {
          return (
            onError?.({
              message: 'Can not parse JSON in JsonFormatter',
              error,
              extra: {
                json,
              },
            }) ?? {}
          );
        }
      } else {
        value = json;
      }

      if (flatten === true) {
        value = plainToFlattenObject(value);
      }

      return value;
    }, [json, flatten]);

    const PopoverElement = useMemo(
      () => (
        <div ref={setPopperElement} style={popperStyles} {...popperAttributes}>
          {activeItem && <Menu item={activeItem.item} contextMenu={activeMenu} closePopover={clearActiveItem} />}
        </div>
      ),
      [activeItem, activeMenu, popperStyles, popperAttributes, clearActiveItem, setPopperElement],
    );

    const getPopoverElement = useCallback(() => {
      const targetPortalElement = document.querySelector('body');

      return createPortal(PopoverElement, targetPortalElement);
    }, [PopoverElement]);

    const entries = useMemo(() => {
      let items = Object.entries(safeSampleLog);

      if (!isNil(sortFn)) {
        items.sort(sortFn);
      }

      if (!isNil(maxLines)) {
        items = items.slice(0, maxLines * 10);
      }

      if (!isNil(propertiesToExclude)) {
        items = items.filter(([propName]) => !propertiesToExclude.includes(propName));
      }

      return items.map(([property, value], i, arr) => {
        const highlightedContent = highlight?.[property];

        let displayedValue: string = '';

        if (typeof value === 'object') {
          if (Array.isArray(value)) {
            displayedValue = value.join(', ');
          } else {
            displayedValue = JSON.stringify(value);
          }
        } else {
          displayedValue = value.toString();
        }

        return (
          <span key={i}>
            <Property
              active={activeItem?.type === 'property' && activeItem?.item.property === property}
              onClick={e => {
                if (isNil(getPropertyContextMenu)) return;

                e.stopPropagation();

                handleClick(e, 'property', { property, value });
              }}
              property={`${property}`}
            />
            <span style={{ padding: '0 2px' }}>:</span>
            <Value
              active={activeItem?.type === 'value' && activeItem?.item.value === value}
              value={highlightedContent ?? displayedValue}
              onClick={e => {
                if (isNil(getValueContextMenu)) return;

                e.stopPropagation();

                handleClick(e, 'value', { property, value });
              }}
            />
            {i < arr.length - 1 && (
              <Text mb={0} variant="code">
                {delimiter}
              </Text>
            )}
          </span>
        );
      });
    }, [safeSampleLog, sortFn, delimiter, activeItem, highlight]);

    return (
      <>
        <span
          style={
            maxLines
              ? {
                  paddingLeft: '1px',
                  overflow: 'hidden',
                  overflowWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  textAlign: 'start',
                  display: '-webkit-box',
                  whiteSpace: 'initial',
                  WebkitLineClamp: `${maxLines}`,
                  WebkitBoxOrient: 'vertical',
                }
              : {}
          }
          {...generateLogzTestAttributes({ context: 'json-formatter', subject })}
        >
          {entries}
          {activeItem && activeMenu && getPopoverElement()}
        </span>
      </>
    );
  },
  (oldProps, newProps) => {
    return isEqual(oldProps, newProps);
  },
);

JsonFormatter.displayName = 'JsonFormatter';
