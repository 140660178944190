import React from 'react';
import { isEqual, pickBy } from 'lodash';
import { FormikProps } from 'formik';

export const UpdateFormikOnFiltersChange = React.memo(
  ({ formik, filterValues }: { formik: FormikProps<any>; filterValues: Record<string, any> }) => {
    const [lastComponentFilterValues, updateComponentFilterValues] = React.useState(filterValues);

    React.useEffect(() => {
      if (!isEqual(lastComponentFilterValues, filterValues)) {
        updateComponentFilterValues(filterValues);
      }
    }, [filterValues]);

    React.useEffect(() => {
      if (!isEqual(pickBy(formik.values), lastComponentFilterValues)) {
        formik.setValues(lastComponentFilterValues);
      }
    }, [lastComponentFilterValues]);

    return null;
  },
);
