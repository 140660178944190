export enum TracingSubject {
  NonAdminInlineNotification = 'NON-ADMIN-INLINE-NOTIFICATION',
  StartTrialButton = 'START-TRIAL-BUTTON',
  SuspensionBar = 'TRACING-SUSPENSION-BAR',
  ApproveStartMetricsTrialButton = 'tracing-metrics-approve',
}

export enum TracingSuspensionStates {
  NotSuspended = 'NOT_SUSPENDED',
  MonthlySpansQuotaExceeded = 'MONTHLY_SPANS_QUOTA_EXCEEDED',
  DailyIndexingThresholdExceeded = 'DAILY_INDEXING_THRESHOLD_EXCEEDED',
}

export enum TracingProductActivationState {
  NotActivated,
  PendingTrial,
  ActivatedNotInUse,
  ActivatedInUse,
}

export enum TracingAccountCardState {
  NotActivated = 'NOT_ACTIVATED',
  Trial = 'TRIAL',
  Free = 'FREE',
  Pro = 'PRO',
}

export enum TracingServiceErrorCode {
  TracingNotActivated = 'tracing/TRACING_NOT_ACTIVATED',
}

export const MAX_TRACING_SAMPLING_RULES_AMOUNT = 10;
