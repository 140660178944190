import moment, { unitOfTime } from 'moment';
import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';
import { TimeUnit } from '@logz-pkg/enums';
import { useUpdateEffect } from '@logz-pkg/react-hooks';
import { InputNumber } from '../Input/Number/Number.component';
import { Group } from '../containers/Group/Group.component';
import type { PopoverPlacement } from '../popover/types';
import { TimeUnitSelect } from './TimeUnit.component';

export const timeUnitMap = {
  [TimeUnit.Milliseconds]: 'milliseconds',
  [TimeUnit.Seconds]: 'seconds',
  [TimeUnit.Minutes]: 'minutes',
  [TimeUnit.Hours]: 'hours',
  [TimeUnit.Days]: 'days',
};

interface IDurationProps {
  value?: number;
  valueTimeUnit?: TimeUnit;
  minTimeUnit?: TimeUnit;
  maxTimeUnit?: TimeUnit;
  error?: string;
  errorPlacement?: PopoverPlacement;
  onChange(value: number): void;
  onBlur?(value: number): void;
  name?: string;
  subject?: string;
  borderless?: boolean;
}

enum FieldComponent {
  Amount,
  TimeUnit,
}

const StyledInputNumber = styled(InputNumber)`
  width: 100px;
`;

const calcDuration = ({ amount, timeUnit, valueTimeUnit }) => moment.duration(amount, timeUnit).as(valueTimeUnit);

const getBiggestTimeUnitByDuration = (duration: moment.Duration) =>
  Object.values(TimeUnit)
    .reverse()
    .find(timeUnit => {
      const durationInTimeUnit = duration.as(timeUnitMap[timeUnit] as unitOfTime.Base);

      return durationInTimeUnit >= 1;
    });

export const Duration: FunctionComponent<IDurationProps> = ({
  value,
  valueTimeUnit = TimeUnit.Milliseconds,
  minTimeUnit,
  maxTimeUnit,
  onChange,
  error,
  errorPlacement,
  name,
  onBlur,
  subject,
  ...props
}) => {
  const [timeUnit, setTimeUnit] = useState<TimeUnit>(null);
  const [amount, setAmount] = useState<number>();
  const [lastUpdatedComponent, setLastUpdatedComponent] = useState<FieldComponent>(FieldComponent.Amount);
  const mappedValueTimeUnit = timeUnitMap[valueTimeUnit];

  const updateDuration = ({ updatedBy }) => {
    let duration = null;

    if (!isNil(amount) && timeUnit) {
      duration = calcDuration({
        amount,
        timeUnit: timeUnitMap[timeUnit],
        valueTimeUnit: mappedValueTimeUnit,
      });
    }

    if (!error) {
      setLastUpdatedComponent(updatedBy);
    }

    if (onChange) {
      onChange(duration);
    }
  };

  const handleBlur = event => {
    if (onBlur) {
      onBlur(event);
    }
  };

  useUpdateEffect(() => {
    updateDuration({ updatedBy: FieldComponent.TimeUnit });
  }, [timeUnit]);

  useUpdateEffect(() => {
    updateDuration({ updatedBy: FieldComponent.Amount });
  }, [amount]);

  useEffect(() => {
    if (!value) return;

    const duration = moment.duration(value, timeUnitMap[valueTimeUnit] as unitOfTime.Base);
    const biggestTimeUnit = getBiggestTimeUnitByDuration(duration);

    setTimeUnit(biggestTimeUnit);
    setAmount(duration.as(timeUnitMap[biggestTimeUnit] as unitOfTime.Base));
  }, []);

  return (
    <Group gap={3} alignItems={'center'}>
      <StyledInputNumber
        {...props}
        value={amount}
        onChange={setAmount}
        mb={0}
        placeholder={'how many'}
        error={lastUpdatedComponent === FieldComponent.Amount && error}
        errorPlacement={errorPlacement}
        onBlur={handleBlur}
        name={name}
        subject={subject}
      />
      <TimeUnitSelect
        {...props}
        value={timeUnit}
        minTimeUnit={minTimeUnit}
        maxTimeUnit={maxTimeUnit}
        onChange={setTimeUnit}
        error={lastUpdatedComponent === FieldComponent.TimeUnit && error}
        errorPlacement={errorPlacement}
        onBlur={handleBlur}
        subject={subject}
      />
    </Group>
  );
};
