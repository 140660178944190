import { opacity } from '../../utils';
import { colors } from '../colors';

export const input = {
  variants: {
    primary: {
      styles: {
        color: colors.gray[900],
        background: colors.gray[0],
        borderColor: colors.gray[500],
      },
      placeholder: {
        color: colors.gray[600],
      },
      active: {
        borderColor: colors.blue[300],
        shadowColor: 'rgba(101, 178, 212, 0.34)',
      },
      hover: {
        borderColor: colors.gray[900],
      },
      focus: {
        borderColor: colors.blue[300],
      },
      disabled: {
        color: colors.gray[800],
        background: colors.gray[300],
        borderColor: colors.gray[500],
      },
      borderless: {
        borderColor: colors.gray[500],
        hover: {
          borderColor: colors.gray[500],
        },
      },
      noUnderline: {
        borderBottom: 'none',
      },
      error: {
        borderColor: colors.red[500],
        active: {
          shadowColor: opacity(colors.red[500], 40),
        },
      },
    },
    secondary: {
      styles: {
        color: colors.gray[900],
        background: colors.gray[0],
        borderColor: colors.gray[500],
      },
      placeholder: {
        color: colors.gray[600],
      },
      active: {
        borderColor: colors.blue[300],
        shadowColor: 'rgba(101, 178, 212, 0.34)',
      },
      hover: {
        borderColor: colors.gray[900],
      },
      focus: {
        borderColor: colors.blue[300],
      },
      disabled: {
        color: colors.gray[800],
        background: colors.gray[300],
        borderColor: colors.gray[500],
      },
      borderless: {
        borderColor: colors.gray[500],
        hover: {
          borderColor: colors.gray[500],
        },
      },
      noUnderline: {
        borderBottom: 'none',
      },
      error: {
        borderColor: colors.red[500],
        active: {
          shadowColor: opacity(colors.red[500], 40),
        },
      },
    },
  },
};
