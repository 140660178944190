export enum Product {
  LogAnalytics = 'LOG_ANALYTICS',
  Metrics = 'METRICS',
  Siem = 'SIEM',
  Tracing = 'TRACING',
}

export enum ObservabilityProduct {
  K8S360 = 'K8S360',
  APP360 = 'APP360',
}
