import styled, { css } from 'styled-components';
import React from 'react';

interface IAnimatedDivProps {
  animation: keyof typeof animations;
  active: boolean;
  durationMs?: number;
  count?: number | 'infinite';
  easingFunction?: 'ease' | 'ease-in-out' | 'ease-in' | 'ease-out' | 'linear' | string; // cubic-brazier
}

interface IAnimationContainerProps extends IAnimatedDivProps {
  children: React.ReactNode;
}

const animations = {
  'enter-from-left': css`
    @keyframes enter-from-left {
      0% {
        opacity: 0;
        transform: translateX(-100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  `,
};

const AnimatedDiv = styled.div<IAnimatedDivProps>`
  width: 100%;
  ${({ active, animation, durationMs, count, easingFunction }) =>
    active &&
    css`
      animation: ${animation} ${durationMs}ms ${easingFunction} ${count};
      ${animations[animation]}
    `};
`;

export const AnimationContainer: React.FC<IAnimationContainerProps> = ({
  children,
  active = false,
  durationMs = 400,
  count,
  easingFunction = 'ease',
  animation,
}: IAnimationContainerProps) => {
  return (
    <AnimatedDiv
      active={active}
      animation={animation}
      durationMs={durationMs}
      count={count}
      easingFunction={easingFunction}
    >
      {children}
    </AnimatedDiv>
  );
};
