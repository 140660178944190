import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Header } from '../../Header/Header.component';
import { Group } from '../Group/Group.component';
import { Text } from '../../typography';
import { Box } from '../Container/Box.component';

interface ISubHeaderProps {
  description?: string | JSX.Element;
  StickyContent?: JSX.Element;
  CallToAction?: JSX.Element;
  subject?: string;
  isSticky?: boolean;
}

const descriptionComponent = (description: string | JSX.Element) => {
  if (typeof description !== 'string') return description;

  return <Text>{description}</Text>;
};

const StyledHeader = styled(Header)<{ isSticky: boolean }>`
  z-index: 2;
  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
    `}
`;

export const SubHeader: FunctionComponent<ISubHeaderProps> = ({
  description,
  StickyContent,
  CallToAction,
  isSticky,
  subject,
}) => {
  return (
    <StyledHeader subject={subject} isSticky={isSticky || Boolean(StickyContent)}>
      <Group px={8} vertical width={'100%'} justifyContent={'end'} gap={0}>
        {(description || CallToAction) && (
          <Group justifyContent={'space-between'} alignItems={'center'} width={'100%'} py={4}>
            <Group width={'700px'}>{descriptionComponent(description)}</Group>
            <Group>{CallToAction}</Group>
          </Group>
        )}
        {StickyContent && <Box>{StickyContent}</Box>}
      </Group>
    </StyledHeader>
  );
};

SubHeader.displayName = 'SubHeader';
