import styled, { css } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';

export const PopoverArrow = styled.div`
  position: absolute;
  width: ${({ theme: { popover } }) => popover.arrow.size};
  height: ${({ theme: { popover } }) => popover.arrow.size};
  pointer-events: none;
  visibility: visible;
  background: transparent;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    visibility: visible;
    display: block;
  }
`;

const getPopoverOffset = ({ theme: { popover }, variant }) => popover.position[variant] ?? popover.position.default;
const getPopoverArrowBackground = ({ theme: { popover }, variant }) =>
  popover.backgroundColor[variant] ?? popover.textColor.default;

export const PopoverContainer = styled.div.attrs(({ subject }: { subject: string }) =>
  generateLogzTestAttributes({ context: 'popover', subject }),
)<{
  variant: string;
  transitionDurationMs?: number;
  isTooltip?: boolean;
  zIndex?: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme: { popover }, variant }) => popover.backgroundColor[variant]};
  color: ${({ theme: { popover }, variant }) => popover.textColor[variant] ?? popover.textColor.default};
  box-shadow: ${({ theme: { shadow } }) => shadow[1]};
  z-index: ${({ zIndex }) => zIndex};
  border-radius: 3px;

  transform: translate3d(0, 0, 0);
  transition: none;

  &.ready-for-transitions {
    ${({ transitionDurationMs }) =>
      transitionDurationMs &&
      css`
        transition: transform ${transitionDurationMs}ms cubic-bezier(0.4, 0, 0.2, 1);
        will-change: transform;
      `}
  }

  ${({ variant }) =>
    variant === 'none' &&
    css`
      border-radius: unset;
      box-shadow: none;
      color: transparent;
      background: transparent;
    `}

  ${({ variant }) =>
    variant === 'tooltip' &&
    css`
      box-shadow: none;
      z-index: 1000000;
      background: transparent;
    `}
    
    &[data-popper-placement^='right'] {
    & > ${PopoverArrow} {
      left: ${getPopoverOffset};
      margin-left: -0.5em;
      height: 1em;
      width: 0.5em;

      &::before {
        filter: drop-shadow(-4px 1px 3px ${({ theme: { popover } }) => popover.arrow.shadowColor});
        border-width: 0.5em 0.5em 0.5em 0;
        border-color: transparent ${getPopoverArrowBackground} transparent transparent;
      }
    }
  }

  &[data-popper-placement^='left'] {
    & > ${PopoverArrow} {
      right: ${getPopoverOffset};
      margin-right: -0.5em;
      height: 1em;
      width: 0.5em;

      &::before {
        filter: drop-shadow(4px 1px 3px ${({ theme: { popover } }) => popover.arrow.shadowColor});
        border-width: 0.5em 0 0.5em 0.5em;
        border-color: transparent transparent transparent ${getPopoverArrowBackground};
      }
    }
  }

  &[data-popper-placement^='top'] {
    & > ${PopoverArrow} {
      bottom: ${getPopoverOffset};
      left: 0;
      margin-bottom: -0.5em;
      width: 1em;
      height: 0.5em;

      &::before {
        filter: drop-shadow(1px 4px 3px ${({ theme: { popover } }) => popover.arrow.shadowColor});
        border-width: 0.5em 0.5em 0 0.5em;
        border-color: ${getPopoverArrowBackground} transparent transparent transparent;
      }
    }
  }

  &[data-popper-placement^='bottom'] {
    & > ${PopoverArrow} {
      top: ${getPopoverOffset};
      margin-top: -0.5em;
      width: 1em;
      height: 0.5em;

      &::before {
        filter: drop-shadow(1px -4px 3px ${({ theme: { popover } }) => popover.arrow.shadowColor});
        border-width: 0 0.5em 0.5em 0.5em;
        border-color: transparent transparent ${getPopoverArrowBackground} transparent;
      }
    }
  }
`;
