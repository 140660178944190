import { FunctionComponent } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { useId } from '@logz-pkg/react-hooks';
import { Flex } from '../containers/Flex/Flex.component';
import { RadioOuterCircle, RadioContainer, RadioInnerCircle, HiddenInput, RadioButtonLabel } from './Radio.styles';
import { IRadioProps } from './Radio.types';

export const Radio: FunctionComponent<IRadioProps> = ({
  checked = false,
  disabled = false,
  value = null,
  onChange = () => {},
  label,
  subject,
  ...props
}) => {
  const onClick = e => {
    // The on click is called twice because it linked to the label by id, to prevent that we use preventDefault
    // https://stackoverflow.com/questions/28940955/double-clicks-when-label-of-radio-button-is-clickable
    e.preventDefault();
    !disabled && onChange(value);
  };
  const id = useId();

  return (
    <RadioContainer
      role={'radio'}
      {...generateLogzTestAttributes({
        context: 'radio',
        subject,
      })}
      onClick={onClick}
      aria-checked={checked}
      aria-labelledby={id}
      {...props}
    >
      <RadioOuterCircle mr={2} disabled={disabled} checked={checked}>
        <RadioInnerCircle checked={checked} disabled={disabled} />
        <HiddenInput aria-labelledby={id} type={'radio'} checked={checked} onChange={onClick} />
      </RadioOuterCircle>
      <Flex flexDirection={'column'}>
        <RadioButtonLabel id={id} disabled={disabled}>
          {label}
        </RadioButtonLabel>
      </Flex>
    </RadioContainer>
  );
};

Radio.displayName = 'Radio';
