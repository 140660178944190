import { colors } from '../../../themes/colors';

export const selectMarketingTheme = {
  variants: {
    primary: {
      valueContainer: {
        textColor: colors.yellow[900],
      },
      placeholder: {
        textColor: colors.yellow[600],
      },
      focused: {
        borderColor: colors.yellow[200],
      },
      hover: {
        borderColor: colors.yellow[400],
      },
      menu: {
        textColor: colors.royalBlue[1000],
        borderColor: colors.gray[300],
        backgroundColor: colors.yellow[100],
        focusedOption: {
          backgroundColor: colors.gray[300],
        },
        selectedOption: {
          backgroundColor: colors.yellow[300],
        },
        group: {
          borderColor: colors.gray[300],
          textColor: colors.royalBlue[1000],
        },
        empty: {
          textColor: colors.gray[700],
        },
        disabledOption: {
          textColor: colors.gray[500],
        },
      },
      indicator: {
        iconColor: colors.yellow[600],
        hover: {
          iconColor: colors.yellow[600],
        },
        clear: {
          borderColor: colors.gray[500],
        },
      },
      multiValue: {
        container: {
          borderColor: colors.gray[400],
          backgroundColor: colors.gray[300],
        },
      },
    },
    secondary: {},
  },
};
