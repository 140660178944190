import React from 'react';
import { LinearProgress } from '../../../progress/LinearProgress/LinearProgress.component';

type TableLinearProgressProps = { position: 'bottom' | 'top'; loading: boolean };

export const TableLinearProgress: React.FC<TableLinearProgressProps> = ({ position = 'top', loading }) => {
  return (
    <LinearProgress
      subject={`table-progress-bar-${position}`}
      top={position === 'top' ? 0 : null}
      bottom={position === 'bottom' ? 0 : null}
      opacity={loading ? 1 : 0}
      overlay
    />
  );
};
