import React, { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '../containers/Flex/Flex.component';
import { Grid } from '../containers/Grid';
import { Group } from '../containers/Group/Group.component';
import { Header } from '../Header/Header.component';
import { Text } from '../typography/Text/Text.component';
import { Title } from '../typography/Title/Title.component';
import { colors, lightV2Theme, LogzioThemeProvider } from '../themes';
import { BarToggleButton } from './ToggleButton';

type BarInterface = {
  Title: FunctionComponent<{ children: React.ReactNode }>;
  TrialDaysText: FunctionComponent<{ daysLeft }>;
  BarSubTitle: FunctionComponent<{ children: React.ReactNode }>;
  CallToAction: FunctionComponent<{ children: React.ReactNode }>;
  LeftActions: FunctionComponent<{ children?: React.ReactNode }>;
};

interface IBar {
  expanded?: boolean;
  onToggleBar?(isOpen): void;
  children: React.ReactNode;
}

const StyledHeader = styled<any>(Header)`
  background-color: ${colors.blue[1000]};
  min-height: 42px;
  z-index: 2;
  align-items: center;
  padding: 0 16px;
`;

const Bar: FunctionComponent<IBar> & Partial<BarInterface> = ({ children, expanded, onToggleBar }) => {
  const [isBarExpanded, setIsBarExpanded] = useState(expanded);

  useEffect(() => {
    setIsBarExpanded(expanded);
  }, [expanded]);

  const handleOnToggleClick = () => {
    const updatedValue = !isBarExpanded;

    setIsBarExpanded(updatedValue);
    onToggleBar?.(updatedValue);
  };

  return (
    <LogzioThemeProvider theme={lightV2Theme}>
      {isBarExpanded && (
        <StyledHeader subject={'trial-bar'}>
          <Grid width={'100%'} columns={12} alignItems={'center'}>
            {children}
          </Grid>
        </StyledHeader>
      )}
      {onToggleBar && <BarToggleButton onClick={handleOnToggleClick} isBarDisplayed={isBarExpanded} />}
    </LogzioThemeProvider>
  );
};

Bar.Title = ({ children }: { children: React.ReactNode }) => (
  <Grid.Cell span={4}>
    <Flex height={'100%'} alignItems={'center'} justifyContent={'center'}>
      <Title size={14} weight={700} color={'white'}>
        {children}
      </Title>
    </Flex>
  </Grid.Cell>
);
Bar.Title.displayName = 'BarTitle';

Bar.BarSubTitle = ({ children }: { children: React.ReactNode }) => (
  <Text color={'white'} weight={400} ml={2}>
    {children}
  </Text>
);
Bar.BarSubTitle.displayName = 'BarSubTitle';

Bar.TrialDaysText = ({ daysLeft }) => (
  <Text color={'white'} subject={'time-left'}>{`Trial ends in ${daysLeft} day${daysLeft <= 1 ? '' : 's'}`}</Text>
);
Bar.TrialDaysText.displayName = 'BarTrialDaysText';

Bar.CallToAction = ({ children }: { children: React.ReactNode }) => (
  <Grid.Cell span={4}>
    <Flex height={'100%'} alignItems={'center'} justifyContent={'flex-end'}>
      <Group alignItems={'center'}>{children}</Group>
    </Flex>
  </Grid.Cell>
);
Bar.CallToAction.displayName = 'BarCallToAction';

Bar.LeftActions = ({ children }: { children: React.ReactNode }) => (
  <Grid.Cell span={4}>
    <Flex alignItems={'center'} height={'100%'}>
      {children}
    </Flex>
  </Grid.Cell>
);
Bar.LeftActions.displayName = 'BarLeftActions';

export { Bar };
