import { Cell } from '@tanstack/react-table';
import React from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { INDICATOR_COMPONENT_COLUMN_ID } from '../constants';

export interface ITableCellStyledProps {
  numeric?: boolean;
  isTitle?: boolean;
  isFixedWidth?: boolean;
  highlight?: boolean;
  showOnHover?: boolean;
  hasContent?: boolean;
}

interface IBodyCell {
  children: React.ReactNode;
  style: React.CSSProperties;
  cell: Cell<any, unknown>;
  hasActions: boolean;
  hasValue?: boolean; // New prop to safely handle cell values
}

const StyledBodyCell = styled.td<IBodyCell>`
  ${({ cell, hasActions }) =>
    cell.column.id !== INDICATOR_COMPONENT_COLUMN_ID &&
    css`
      min-width: 0;
      padding: 8px;
      ${hasActions &&
      css`
        :hover {
          box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blue[500]};
        }
      `}
    `}
  ${({ theme }) =>
    css`
      background-color: ${theme.table.body.cellHighlight};
    `};
  ${space}
  height: 100%;
`;

export const BodyCell: React.FC<IBodyCell> = ({ children, cell, ...props }) => {
  return (
    <StyledBodyCell {...props} cell={cell}>
      {children}
    </StyledBodyCell>
  );
};
