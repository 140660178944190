import React from 'react';
import styled from 'styled-components';

interface IPrimaryTableProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  grid-template-rows: auto 1fr;
`;

export const Table = React.forwardRef<
  HTMLTableElement,
  IPrimaryTableProps & { onScroll?: React.MouseEventHandler<HTMLTableElement> }
>(({ ...props }, ref) => <StyledTable ref={ref} {...props} />);

Table.displayName = 'Table';
