import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import styled, { css, CSSProperties } from 'styled-components';
import { width } from 'styled-system';
import { Icon } from '../../icons/Icon.component';
import { space } from '../../utils';

export const opacityTransition = 'transition: opacity 0.2s linear;';

interface IButtonTextProps {
  isLoading?: boolean;
  overflow?: CSSProperties['overflow'];
}

export const ButtonText = styled.div<IButtonTextProps>`
  display: flex;
  gap: 6px;
  align-items: center;
  white-space: nowrap;
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ellipsis;
  ${opacityTransition};
  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `};
`;

interface IStyledButtonProps {
  disabled?: boolean;
  tooltip?: string;
  isLoading?: boolean;
  subject?: string;
  context?: string;
}

export const StyledButton = styled.button.attrs<IStyledButtonProps>(props =>
  generateLogzTestAttributes({ context: props.context || 'button', subject: props.subject }),
)<IStyledButtonProps>`
  font-size: 14px;
  border: 0;
  align-items: center;
  line-height: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;

  ${({ disabled, tooltip }) =>
    disabled && tooltip
      ? css`
          pointer-events: none;
        `
      : ''};

  ${space}
  ${width}

  ${({ disabled, isLoading }) => (disabled || isLoading ? 'cursor: not-allowed;' : 'cursor: pointer;')}
  transition: background 0.1s ease-in-out;
`;

export const StyledLink = styled(StyledButton.withComponent('a'))`
  vertical-align: baseline;
  text-decoration: none;
`;

export const StyledButtonIcon = styled(({ size, hasText, iconSize = 12, iconColor, ...props }) => (
  <Icon size={iconSize} color={iconColor} {...props} />
))``;
