import React, { PropsWithChildren, useEffect, useRef } from 'react';
import styled from 'styled-components';

const CELL_PADDING_X = 8;
const TBODY_MARGIN_RIGHT = 1;

const StyledDiv = styled.div`
  position: sticky;
  top: 0;
  left: 8px;
`;

interface ISubComponentBodyCellProps extends PropsWithChildren {
  tableRef: React.MutableRefObject<HTMLDivElement>;
}

export const SubComponentBodyCell: React.FC<ISubComponentBodyCellProps> = ({ children, tableRef }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        if (!tableRef?.current) return;

        ref.current.style.width = `${tableRef.current.offsetWidth - (CELL_PADDING_X * 2 + TBODY_MARGIN_RIGHT)}px`;
      });
    });

    resizeObserver.observe(tableRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, tableRef]);

  return (
    <td>
      <StyledDiv ref={ref}>{children}</StyledDiv>
    </td>
  );
};
