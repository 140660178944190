import type { Options } from '@popperjs/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import { usePopper } from 'react-popper';
import { useHotkeys } from 'react-hotkeys-hook';
import { useActionItemPopover } from './hooks/action-item-popover.hook';
import { useAnchorElement } from './hooks/anchor-element.hook';
import { useClickOutside } from './hooks/click-outside.hook';

const options: Options = {
  placement: 'auto',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'preventOverflow',
      options: {
        altAxis: true,
        padding: 100,
      },
    },
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
        adaptive: false,
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 0],
      },
    },
  ],
};

export const ActionItemPopover = () => {
  const [actionItem, setActionItem] = React.useState(null);
  const { popperContentElement, position, close } = useActionItemPopover();

  const referanceElement = useAnchorElement(position);

  useClickOutside(actionItem, close);

  const { styles, attributes, update } = usePopper(referanceElement, actionItem, options);

  const PopoverElement = useMemo(
    () => (
      <Draggable handle="[data-action-item-drag-handle]" bounds="body">
        <div ref={setActionItem} style={styles.popper} {...attributes.popper}>
          {popperContentElement}
        </div>
      </Draggable>
    ),
    [popperContentElement, styles, attributes],
  );

  const getPopoverElement = useCallback(() => {
    const targetPortalElement = document.querySelector('body');

    return createPortal(PopoverElement, targetPortalElement);
  }, [PopoverElement]);

  useHotkeys(
    ['esc'],
    () => {
      if (popperContentElement && referanceElement) close();
    },
    {},
    [popperContentElement, referanceElement],
  );

  useEffect(() => {
    update?.();
  }, [referanceElement]);

  return <>{popperContentElement && referanceElement && getPopoverElement()}</>;
};
