import { NotificationType } from '@logz-pkg/enums';
import * as React from 'react';

export interface IConfigProps {
  top?: string;
  bottom?: string;
  autoHide?: boolean;
  duration?: number;
  marginRight?: string;
  marginLeft?: string;
  getContainer?: () => HTMLElement;
}

type NotificationTypeValues = `${NotificationType}`;

export const notificationTypes: NotificationTypeValues[] = ['success', 'info', 'warning', 'danger', 'progress'];

export const notificationDefaults: IConfigProps = {
  top: '65px',
  bottom: '20px',
  autoHide: true,
  duration: 6,
  marginRight: '20px',
  marginLeft: '20px',
};

export interface INotificationOptions {
  key?: string;
  subject?: string;
  title?: React.ReactNode;
  message?: React.ReactNode;
  autoHide?: boolean;
  /**
   * Duration in seconds
   */
  duration?: number | null;
  closable?: boolean;
  children?: any;
  onClose?(): void;
}

export interface INotificationContentProps extends INotificationOptions {
  type?: NotificationTypeValues;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
}
