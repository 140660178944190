import React, { forwardRef, PropsWithChildren, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Spinner } from '../../progress/Spinner/Spinner.component';
import { ButtonText, StyledButton, StyledButtonIcon, StyledLink } from './BaseButton.styles';
import { IBaseButtonProps } from './types';

export const ComplexButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, PropsWithChildren<IBaseButtonProps>>(
  ({ href, loading, children, icon, iconSize, iconColor, text, variant = 'primary', ...props }, ref) => {
    const theme = useContext(ThemeContext);
    const buttonContent = (
      <>
        {loading && <Spinner size="s" color={theme.buttons[variant].styles.color} />}
        <ButtonText isLoading={loading} overflow={props.overflow}>
          {icon && (
            <StyledButtonIcon icon={icon} iconSize={iconSize} iconColor={iconColor} hasText={!!(children || text)} />
          )}
          {children || text}
        </ButtonText>
      </>
    );

    return href ? (
      <StyledLink {...props} href={href} ref={ref as React.Ref<HTMLAnchorElement>}>
        {buttonContent}
      </StyledLink>
    ) : (
      <StyledButton {...props} ref={ref as React.Ref<HTMLButtonElement>}>
        {buttonContent}
      </StyledButton>
    );
  },
);

ComplexButton.displayName = 'ComplexButton';
