import styled, { css } from 'styled-components';
import { Link } from '@logz-ui/styleguide/components/typography/Link/Link.component';
import { Title } from '@logz-ui/styleguide/components/typography/Title/Title.component';

export const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.datePicker.quick.titleColor};
`;

export const StyledLink = styled(Link)<{ selected: boolean }>`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  color: ${({ theme }) => theme.datePicker.quick.linkColor};
  line-height: 1.7;

  &:hover {
    color: ${({ theme }) => theme.datePicker.quick.linkHoverColor};
    background-color: ${({ theme }) => theme.datePicker.quick.linkHoverBackground};
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      color: ${theme.datePicker.quick.linkSelectedColor};
      background-color: ${theme.datePicker.quick.linkSelectedBackground};

      &:hover {
        color: ${theme.datePicker.quick.linkSelectedColor};
        background-color: ${theme.datePicker.quick.linkSelectedBackground};
      }
    `}
`;
