import styled, { css } from 'styled-components';

export const FirstContainer = styled.div<{ vertical?: boolean; minSize?: number; maxSize?: number }>`
  display: flex;
  align-items: start;
  justify-content: start;
  overflow: auto;

  ${({ vertical, minSize, maxSize }) =>
    vertical
      ? css`
          ${minSize ? `min-height: ${minSize}px;` : ''}
          ${maxSize ? `max-height: ${maxSize}px;` : ''}
          width: 100%;
        `
      : css`
          ${minSize ? `min-width: ${minSize}px;` : ''}
          ${maxSize ? `max-width: ${maxSize}px;` : ''}
          height: 100%;
        `}
`;

export const SecondContainer = styled.div<{ vertical?: boolean; minSize?: number; maxSize?: number }>`
  flex: 1;

  display: flex;
  align-items: start;
  justify-content: start;
  overflow: auto;

  ${({ vertical, minSize, maxSize }) =>
    vertical
      ? css`
          width: 100%;
          ${minSize ? `min-height: ${minSize}px;` : ''}
          ${maxSize ? `max-height: ${maxSize}px;` : ''}
        `
      : css`
          height: 100%;
          ${minSize ? `min-width: ${minSize}px;` : ''}
          ${maxSize ? `max-width: ${maxSize}px;` : ''}
        `}
`;

export const ResizerHandle = styled.div<{ vertical?: boolean; resizerColor?: React.CSSProperties['backgroundColor'] }>`
  position: relative;
  background: ${({ resizerColor }) => resizerColor || 'transparent'};
  transition: background 0.5s ease-out;
  &:hover {
    background: ${({ theme }) => theme.colors.blue[300]};
    transition: background 0s ease-out;
  }
  &:active {
    background: ${({ theme }) => theme.colors.blue[500]};
  }

  user-select: none;
  touch-action: none;

  ${({ vertical }) =>
    vertical
      ? css`
          cursor: ns-resize;
          height: 2px;
          width: 100%;

          // Increase the clickable area
          &::before {
            content: '';
            position: absolute;
            top: -5px;
            left: 0;
            right: 0;
            height: 10px;
          }
        `
      : css`
          cursor: ew-resize;
          height: 100%;
          width: 2px;

          // Increase the clickable area
          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -5px;
            width: 10px;
          }
        `}
`;

const dataSplitClose = css`
  :has(${FirstContainer}> [data-split-close='true']) {
    ${FirstContainer}:has([data-split-close='true']) {
      min-width: 0;
      max-width: 0;
      width: 0;
      min-height: 0;
      max-height: 0;
      height: 0;
    }
    > ${ResizerHandle} {
      display: none;
    }
  }
  :has(${SecondContainer}> [data-split-close='true']) {
    ${SecondContainer}:has([data-split-close='true']) {
      min-width: 0;
      max-width: 0;
      width: 0;
      min-height: 0;
      max-height: 0;
      height: 0;
    }
    > ${ResizerHandle} {
      display: none;
    }
  }
`;

export const ResizeContainer = styled.div<{ vertical?: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;
  overflow: auto;

  ${({ vertical }) =>
    css`
      flex-direction: ${vertical ? 'column' : 'row'};
    `}
  ${dataSplitClose}
`;
