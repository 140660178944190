import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Ellipsis } from '../typography';
import { ITagProps, Tag } from './Tag.component';
import { StyledText } from './components/TagText';

export interface ITagFilterProps extends PropsWithChildren {
  negate?: boolean;
  invalid?: boolean;
  onRemove?: ITagProps['onRemove'];
  onClick?: ITagProps['onClick'];
  disabled?: boolean;
  subject?: string;
}

const StyledTag = styled(Tag)<ITagFilterProps>`
  ${({
    negate,
    invalid,
    disabled,
    onClick,
    theme: {
      tag: { fieldFilter },
    },
  }) => css`
    color: ${invalid ? fieldFilter.color.invalid : fieldFilter.color.default};
    border-radius: 3px;
    max-width: 350px;
    border: 1px solid;
    background-color: ${fieldFilter.backgroundColor.default};
    text-decoration: ${disabled ? 'line-through' : 'default'};
    border-color: ${() => {
      if (invalid) {
        return fieldFilter.borderColor.invalid;
      } else if (negate) {
        return fieldFilter.borderColor.negate;
      }

      return fieldFilter.borderColor.active;
    }};

    ${onClick &&
    !disabled &&
    css`
      &:hover {
        background-color: ${negate
          ? fieldFilter.backgroundColor.hover.negate
          : fieldFilter.backgroundColor.hover.active};
      }
    `}
  `}

  ${StyledText} {
    color: inherit;
    margin: 0 2px;
  }
`;

export const TagFilter: React.FC<ITagFilterProps> = ({
  onRemove,
  onClick,
  children,
  negate = false,
  disabled = false,
  invalid = false,
  subject,
}) => (
  <StyledTag
    invalid={invalid}
    onRemove={onRemove}
    onClick={onClick}
    negate={negate}
    disabled={disabled}
    subject={subject}
  >
    <Ellipsis wordBreak={'break-all'} maxLines={1}>
      <span>{children}</span>
    </Ellipsis>
  </StyledTag>
);
