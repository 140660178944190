import React, { FunctionComponent } from 'react';
import { Button } from '../../../../Button/Button.component';
import { Menu } from '../../../../menu';
import { ICrudTableProps, CrudTableTypes } from '../../Crud.types';
import { Popover } from '../../../../popover/Popover.component';
import { isPromise } from '../../../../utils';

type ICustomAction = CrudTableTypes.ICustomAction;

interface IComponentProps {
  menuItemsList: (handleClose: any) => JSX.Element[] | null;
  closePopover: () => void;
}

const Component: React.FC<IComponentProps> = ({ closePopover, menuItemsList }) => {
  return <Menu>{menuItemsList(closePopover)}</Menu>;
};

interface ICrudTableRecordCustomActionsProps {
  actions: ICrudTableProps['customActions'];
  onSearch: ICrudTableProps['onSearch'];
  onStartLoading: () => void;
  onFinishLoading: () => void;
  record: object;
  appendToBody?: boolean;
}

export const CrudTableRecordCustomActions: FunctionComponent<ICrudTableRecordCustomActionsProps> = ({
  actions,
  record,
  onStartLoading,
  onFinishLoading,
  onSearch,
  appendToBody = false,
}) => {
  const menuItemsList = (handleClose): JSX.Element[] | null => {
    const handleClick = async (action: ICustomAction, event) => {
      try {
        const actionRes = action.handleClick(record, event);

        if (isPromise(actionRes)) {
          onStartLoading();
          await actionRes;
        }

        handleClose();

        if (action.shouldSearchAfterAction) {
          await onSearch();
        }
      } finally {
        onFinishLoading();
      }
    };

    return actions.map((action: ICustomAction): JSX.Element | null => {
      const label = typeof action.label === 'string' ? action.label : action.label?.(record);

      return (
        <Menu.Item key={label} icon={action.icon} onClick={event => handleClick(action, event)} subject={label}>
          {label}
        </Menu.Item>
      );
    });
  };

  return (
    <Popover appendToBody={appendToBody} Content={{ Component, props: { menuItemsList } }} placement={'bottom-start'}>
      <Button
        variant={'tertiary'}
        round
        subject={'menu'}
        icon={'ellipsis-vertical-regular'}
        onClick={e => {
          e.stopPropagation();
        }}
      />
    </Popover>
  );
};
