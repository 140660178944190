import styled, { css } from 'styled-components';
import { Flex } from '../../containers/Flex/Flex.component';

export const SIDE_NAV_MINIFIED_SIZE = 32;
export const SIDE_NAV_DEFAULT_SIZE = 64;

export const StyledPrimarySidenav = styled(props => <Flex {...props} />)`
  flex-direction: column;
  ${({ isMinified }) =>
    isMinified
      ? css`
          width: ${SIDE_NAV_MINIFIED_SIZE}px;
        `
      : css`
          width: ${SIDE_NAV_DEFAULT_SIZE}px;
        `}
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.blue[1000]};
  position: relative;
  align-items: center;
  z-index: 92;
  overflow-y: auto;
`;
