import { bluePalette, grayPalette, redPalette, royalBluePalette, yellowPalette } from '../../themes/colors';
import { colorsV2 } from '../../themes/colors-v2';

export const buttonLightTheme = {
  variants: {
    primary: {
      textColor: bluePalette[1000],
      background: yellowPalette[600],
      hover: {
        background: yellowPalette[700],
      },
      active: {
        background: yellowPalette[800],
      },
      disabled: {
        textColor: grayPalette[600],
        background: yellowPalette[200],
      },
    },
    secondary: {
      borderColor: grayPalette[400],
      textColor: bluePalette[1000],
      background: grayPalette[100],
      hover: {
        background: grayPalette[300],
      },
      active: {
        background: grayPalette[400],
      },
      disabled: {
        background: grayPalette[200],
        textColor: grayPalette[500],
      },
    },
    tertiary: {
      textColor: bluePalette[600],
      background: 'transparent',
      hover: {
        textColor: bluePalette[700],
        background: grayPalette[100],
      },
      active: {
        textColor: bluePalette[700],
        background: grayPalette[200],
      },
      disabled: {
        textColor: bluePalette[300],
        background: 'transparent',
      },
    },
    danger: {
      textColor: grayPalette[0],
      background: redPalette[600],
      hover: {
        background: redPalette[700],
      },
      active: {
        background: redPalette[800],
      },
      disabled: {
        textColor: grayPalette[500],
        background: grayPalette[200],
      },
    },
    contrast: {
      textColor: grayPalette[0],
      background: bluePalette[1000],

      hover: {
        background: bluePalette[700],
      },
      active: {
        background: bluePalette[800],
      },
      focus: {
        borderColor: bluePalette[500],
      },
      disabled: {
        textColor: bluePalette[500],
        background: bluePalette[200],
      },
    },
    radiant: {
      textColor: royalBluePalette[1000],
      boxShadow: `0px 0px 10px 0px ${grayPalette[1000]}30`,
      background: colorsV2.gradient.radiant,
      before: {
        background: grayPalette[0],
      },
      hover: {
        background: grayPalette[300],
      },
      focus: {
        textColor: royalBluePalette[1000],
      },
      active: {
        background: grayPalette[400],
        textColor: royalBluePalette[1000],
      },
      disabled: {
        background: colorsV2.gradient.radiant,
        opacity: 0.3,
        textColor: grayPalette[1000],
        before: {
          background: grayPalette[300],
        },
      },
    },
  },
};
