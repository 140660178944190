import styled, { css } from 'styled-components';
import { Link } from '../typography';
import { Icon } from '../icons/Icon.component';

export const StyledNav = styled.nav`
  display: flex;
`;

export const StyledOrderedList = styled.ol<{ separator?: boolean }>`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 2px;

  ${({ separator }) =>
    !separator &&
    css`
      gap: 4px;
    `}
`;

export const EditStyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.blue[500]};
  font-weight: 500;
  cursor: pointer;
  display: none;
  margin-left: 2px;
`;

export const StyledListItem = styled.li<{ link?: string; isEditting?: boolean }>`
  display: flex;
  align-items: center;
  gap: 2px;

  ${EditStyledIcon} {
    ${({ isEditting }) =>
      isEditting &&
      css`
        display: flex;
      `}
  }

  :hover {
    ${EditStyledIcon} {
      display: flex;
    }
  }
`;

export const StyledIcon = styled(Icon)<{ link?: string }>`
  color: ${({ theme, link }) => theme.breadcrumbs[link ? 'link' : 'text'].color};
  font-weight: 400;
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  line-height: 1;
  gap: 4px;

  size: ${({ theme }) => theme.breadcrumbs.link.size}px;
  font-weight: ${({ theme }) => theme.breadcrumbs.link.fontWeight};

  ${({ theme, disabled }) =>
    disabled &&
    css`
      opacity: 1;
      size: ${({ theme }) => theme.breadcrumbs.text.size}px;
      color: ${theme.breadcrumbs.text.color};
      font-weight: ${theme.breadcrumbs.text.fontWeight};
    `}
`;
