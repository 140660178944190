import { bluePalette, grayPalette, royalBluePalette } from '../../themes/colors';

export const buttonGroupLightTheme = {
  backgroundColor: 'white',
  color: grayPalette[800],
  borderColor: bluePalette[300],
  edgeButtonRadius: '3px',
  selected: {
    color: 'white',
    backgroundColor: bluePalette[700],
    borderColor: bluePalette[500],
    borderTopColor: bluePalette[700],
    borderBottomColor: bluePalette[700],
  },
  disabled: {
    backgroundColor: grayPalette[300],
    color: grayPalette[500],
    borderColor: grayPalette[400],
  },
  hover: {
    color: royalBluePalette[1000],
    backgroundColor: bluePalette[200],
  },
};
