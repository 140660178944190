import { FunctionComponent } from 'react';
import { components, MultiValueProps } from 'react-select';
import { SelectIconButton } from '../Select.styles';
import { Icon } from '../../../icons/Icon.component';

export const MultiValueRemove: FunctionComponent<MultiValueProps<any>> = props => (
  <components.MultiValueRemove {...props}>
    {/*
     A workaround for the `react-select-event` `clearFirst` option that is bound to search for an svg with aria-hidden
     https://github.com/romgain/react-select-event/issues/53
     */}
    <svg aria-hidden style={{ display: 'none' }} />
    <SelectIconButton variant={props.selectProps.variant}>
      <Icon icon={'xmark-regular'} size={10} />
    </SelectIconButton>
  </components.MultiValueRemove>
);
