import React from 'react';
import styled from 'styled-components';
import { Group } from '../containers/Group/Group.component';
import { Button } from '../Button/Button.component';

interface IScrollNavigationProps {
  position: 'start' | 'end' | 'in-between';
  onNext: () => void;
  onBack: () => void;
}

const StyledGroup = styled(Group)`
  align-self: flex-end;
  position: absolute;
  // this is half of m sized round button
  bottom: -16px;
`;

export const ScrollNavigation: React.FC<IScrollNavigationProps> = ({ position, onNext, onBack }) => {
  return (
    <StyledGroup pr={8}>
      <Button
        size={'m'}
        round
        variant={'secondary'}
        icon={'chevron-left-regular'}
        disabled={position === 'start'}
        onClick={onBack}
      />
      <Button
        size={'m'}
        round
        variant={'secondary'}
        icon={'chevron-right-regular'}
        disabled={position === 'end'}
        onClick={onNext}
      />
    </StyledGroup>
  );
};
