import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import type { PopoverPlacement } from '../popover/types';
import { Popover } from '../popover/Popover.component';
import { Text } from '../typography';

export type TooltipPlacement = PopoverPlacement;

export interface ITooltipProps {
  open?: boolean;
  title: string | ReactNode;
  placement?: TooltipPlacement;
  disableEvents?: boolean;
  showArrow?: boolean;
  appendToBody?: boolean;
  openDelayInMs?: number;
  background?: string;
  offset?: number;
}

const StyledTooltip = styled.div<{ placement: TooltipPlacement; background: string }>`
  background-color: ${({ theme, background }) => background || theme.tooltip.backgroundColor};
  color: ${({ theme }) => theme.tooltip.textColor};
  font-size: 12px;
  line-height: 1.4em;
  position: relative;
  padding: 4px 8px;
  max-width: 600px;
`;

const TooltipTextContainer = styled.span`
  display: inline-block;
  max-width: 300px;
  word-wrap: break-word;
`;

interface IComponentProps {
  title: ITooltipProps['title'];
  placement: ITooltipProps['placement'];
  background: ITooltipProps['background'];
}

const Component: React.FC<IComponentProps> = ({ title, placement, background }) => {
  return Boolean(title) ? (
    <StyledTooltip placement={placement} background={background} className="tooltip-content">
      {typeof title === 'string' ? (
        <TooltipTextContainer>
          <Text size={12} color={'white'}>
            {title}
          </Text>
        </TooltipTextContainer>
      ) : (
        title
      )}
    </StyledTooltip>
  ) : null;
};

export const Tooltip: FunctionComponent<ITooltipProps & { children: ReactNode }> = ({
  open,
  placement = 'bottom',
  showArrow = false,
  disableEvents = false,
  title,
  children,
  appendToBody = true,
  openDelayInMs = 0,
  background,
  offset = 10,
}) => {
  return (
    <Popover
      open={open}
      placement={placement}
      showArrow={showArrow}
      variant={'tooltip'}
      disabled={disableEvents}
      trigger={'hover'}
      closeDelayInMs={100}
      offset={offset}
      openDelayInMs={openDelayInMs}
      appendToBody={appendToBody}
      Content={{ Component, props: { placement, background, title } }}
    >
      {children}
    </Popover>
  );
};

Tooltip.displayName = 'Tooltip';
