import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { ICrudOptions } from '../Crud/Crud.types';

const cellPaddingX = 8;
const cellPaddingY = 15;

interface ITableCellProps {
  width?: string;
  isFixedWidth?: boolean;
  numeric?: boolean;
  minWidth?: string;
  isTitle?: boolean;
  highlight?: boolean;
  context?: string;
  subject?: string;
  tableOptions?: ICrudOptions;
}

const TableCellStyled = styled.div.attrs<ITableCellProps>(({ context, subject }) =>
  generateLogzTestAttributes({
    context: context ?? 'table-cell',
    subject,
  }),
)<ITableCellProps>`
  display: flex;
  min-height: 20px;
  flex: ${({ isFixedWidth }) => (isFixedWidth ? 0 : 1)} 1 ${({ width }) => width || 'auto'};
  flex-direction: column;
  line-height: 1.3;
  word-break: break-word;

  ${({ tableOptions }) =>
    tableOptions?.tableStyles?.cellContentAlignment &&
    css`
      justify-content: ${tableOptions?.tableStyles?.cellContentAlignment};
    `};

  ${({ numeric }) =>
    numeric &&
    css`
      align-items: flex-end;
    `};

  ${({ tableOptions }) =>
    css`
      padding: ${typeof tableOptions?.tableStyles?.cellPadding === 'number'
          ? tableOptions?.tableStyles?.cellPadding
          : cellPaddingY}px
        ${cellPaddingX}px;
    `};

  ${({ isTitle }) =>
    isTitle &&
    css`
      font-weight: 500;
      color: black;
    `};
  min-width: ${({ minWidth }) => minWidth || 0};

  ${({ theme, highlight }) =>
    highlight &&
    css`
      background-color: ${theme.table.body.cellHighlight};
    `};

  ${space}
`;

const TableCell = ({
  children,
  width = '75px',
  isFixedWidth = false,
  numeric = false,
  ...props
}: PropsWithChildren<ITableCellProps>) => (
  <TableCellStyled width={width} isFixedWidth={isFixedWidth} numeric={numeric} {...props}>
    {children}
  </TableCellStyled>
);

export { cellPaddingX, cellPaddingY, TableCell };
