import moment, { DurationInputArg1, DurationInputArg2 } from 'moment';
import { dateToAbsolute } from '../date-manipulator.service';
import {
  BucketMap,
  BucketIntervalType,
  bucketMap,
  hourInMs,
  minuteInMs,
  secondImMs,
} from './interval-calculator.config';

const second = 1;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

export const intervalData: [DurationInputArg1, DurationInputArg2][] = [
  [1, 'm'],
  [2, 'm'],
  [3, 'm'],
  [5, 'm'],
  [10, 'm'],
  [15, 'm'],
  [30, 'm'],
  [1, 'h'],
  [2, 'h'],
  [3, 'h'],
  [4, 'h'],
  [8, 'h'],
  [12, 'h'],
  [24, 'h'],
  [48, 'h'],
  [1, 'w'],
  [30, 'd'],
  [60, 'd'],
];

const stepAsDuration = ({
  start,
  end,
  size = 60,
}: {
  start: number;
  end: number;
  size?: number;
}): [DurationInputArg1, DurationInputArg2] | [] => {
  const step = (dateToAbsolute(end) - dateToAbsolute(start)) / size;

  return intervalData.find((data, index) => step < moment.duration(...intervalData[index + 1]).asSeconds()) || [];
};

const step = ({ start, end, size = 60 }: { start: number; end: number; size?: number }): string =>
  stepAsDuration({ start, end, size }).join('');

// Based on this calc
// Step\start time  |  <=7d |  <=30d |  >30d
// _________________________|________|_________
//                  |       |        |
// <=1m             |   1m  |   4m   |  40m
//                  |       |        |
// <=10m            |   4m  |   4m   |  40m
//                  |       |        |
// >10m             |   40m |   40m  |  40m

// Max (step=15s, min_int(15s,<=7d)=1m) =1m

const rateIntervalMapper: { step?: number; start?: number; rateInterval: number }[] = [
  { step: 1 * minute, start: 7 * day, rateInterval: 1 * minute },
  { step: 1 * minute, start: 30 * day, rateInterval: 4 * minute },
  { step: 1 * minute, rateInterval: 1 * minute }, // no start, i.e > 30

  { step: 10 * minute, start: 7 * day, rateInterval: 4 * minute },
  { step: 10 * minute, start: 30 * day, rateInterval: 4 * minute },
  { step: 10 * minute * day, rateInterval: 40 * minute },

  { start: 7 * minute * day, rateInterval: 40 * minute },
  { start: 30 * minute * day, rateInterval: 40 * minute },
  { rateInterval: 40 * minute },
];

const rate = ({ step, start, end }: { step: string; start: number; end: number }): string => {
  const timeFrame = dateToAbsolute(end) - dateToAbsolute(start);
  const stepInSec = moment.duration(...intervalData.find(item => step === `${item[0]}${item[1]}`)).asSeconds();

  const { rateInterval } = rateIntervalMapper.find(
    item =>
      (stepInSec <= item.step && timeFrame <= item.start) ||
      (stepInSec <= item.step && !item.start) ||
      (!item.step && timeFrame <= item.start) ||
      (!item.step && !item.start),
  );

  return `${moment.duration(Math.max(stepInSec, rateInterval)).asMinutes() * 1000}m`;
};

const getDurationFromStartEnd = ({ start, end }: { start: number; end: number }): string => {
  if (!start || !end) return null;

  const byHours = moment
    .duration(moment(dateToAbsolute(end) * 1000).diff(moment(dateToAbsolute(start) * 1000)))
    .asHours();

  return `${byHours >= 1 ? `${byHours}h` : `${byHours * 60}m`}`;
};

type RoundTime = (arg: { timestampInMs: number; bucketMapItem: BucketMap; toLowerBound?: boolean }) => string;

const roundTime: RoundTime = ({ timestampInMs, bucketMapItem, toLowerBound }) => {
  const date = new Date(timestampInMs);
  let divider;

  const { type }: BucketMap = bucketMapItem;

  switch (type) {
    case 'ms':
      date.setUTCMilliseconds(
        Math[toLowerBound ? 'floor' : 'round'](date.getUTCMilliseconds() / bucketMapItem.ms) * bucketMapItem.ms,
      );
      break;
    case 's':
      divider = bucketMapItem.ms / secondImMs;
      date.setUTCSeconds(Math[toLowerBound ? 'floor' : 'ceil'](date.getUTCSeconds() / divider) * divider);
      date.setUTCMilliseconds(0);
      break;
    case 'm':
      divider = bucketMapItem.ms / minuteInMs;
      date.setUTCMinutes(Math[toLowerBound ? 'floor' : 'ceil'](date.getUTCMinutes() / divider) * divider);
      date.setUTCSeconds(0, 0);
      break;
    case 'h':
      divider = bucketMapItem.ms / hourInMs;
      date.setUTCHours(Math[toLowerBound ? 'floor' : 'ceil'](date.getUTCHours() / divider) * divider);
      date.setUTCMinutes(0, 0, 0);
      break;
    default:
      const roundToDay: [number, number, number, number] = toLowerBound ? [0, 0, 0, 0] : [23, 59, 59, 999];

      date.setUTCHours(...roundToDay);
      break;
  }

  return date.toISOString();
};

const calculateAutoBucket = ({ start, end, maxBuckets }) => {
  const diff = end - start;
  const bucketSizeMs = diff / maxBuckets;
  let lowerBound,
    upperBound = bucketMap[0];

  bucketMap.forEach(b => {
    if (bucketSizeMs >= b.ms) {
      upperBound = b;
    }

    if (lowerBound === undefined && bucketSizeMs < b.ms) {
      lowerBound = b;
    }
  });

  if (lowerBound === undefined) {
    lowerBound = upperBound;
  }

  const upperBoundBuckets = Math.round(diff / upperBound.ms);

  const lowerBoundBuckets = Math.round(diff / lowerBound.ms);

  // here we decide which of 2 value(lowerBound, upperBound) have less distance to maxBuckets
  const desiredBucket: BucketMap =
    maxBuckets - lowerBoundBuckets <= upperBoundBuckets - maxBuckets ? lowerBound : upperBound;

  return desiredBucket;
};

const calculateIntervalBucket = ({ start, end, intervalType, maxBuckets }) => {
  const diff = end - start;
  const bucketSizeMs = diff / maxBuckets;

  const desiredBucket = bucketMap.find(b => b.type === intervalType);

  if (bucketSizeMs <= desiredBucket.ms) {
    return desiredBucket;
  }

  return calculateAutoBucket({ start, end, maxBuckets });
};

export type IntervalData = {
  start: string;
  end: string;
  interval: string;
  intervalMs: number;
  exceedsMaxBuckets: boolean;
};

type FindBucket = (arg: {
  start: number;
  end: number;
  maxBuckets?: number;
  intervalType?: 'auto' | BucketIntervalType;
}) => IntervalData;

/**
 * findBucket time interval for histogram
 * @param start timestamp in milliseconds
 * @param end timestamp in milliseconds
 * @param maxBuckets desired number of buckets
 * @param intervalType desired interval size (auto, ms, s, m, h, w, M, y)
 */
const findBucket: FindBucket = ({ start, end, intervalType, maxBuckets = 100 }) => {
  const isAutoInterval = intervalType === 'auto' || !intervalType;

  const desiredBucket = isAutoInterval
    ? calculateAutoBucket({ start, end, maxBuckets })
    : calculateIntervalBucket({ start, end, intervalType, maxBuckets });

  if (!isAutoInterval && desiredBucket.ms > end - start) {
    // If size of selected bucket is bigger than the time range, we don't round the boundaries
    // and use the time range as boundaries
    return {
      start: new Date(start).toISOString(),
      end: new Date(end).toISOString(),
      interval: desiredBucket.name,
      intervalMs: desiredBucket.ms,
      exceedsMaxBuckets: false,
    };
  }

  const exceedsMaxBuckets = Boolean(intervalType && desiredBucket.name !== `1${intervalType}`);

  return {
    start: roundTime({ timestampInMs: start, bucketMapItem: desiredBucket, toLowerBound: true }),
    end: roundTime({ timestampInMs: end, bucketMapItem: desiredBucket }),
    interval: desiredBucket.name,
    intervalMs: desiredBucket.ms,
    exceedsMaxBuckets,
  };
};

export const intervalCalculatorService = {
  stepAsDuration,
  step,
  rate,
  getDurationFromStartEnd,
  findBucket,
};
