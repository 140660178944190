import React, { FunctionComponent } from 'react';
import { Group } from '../containers/Group/Group.component';

export interface IRadioGroupProps {
  horizontal?: boolean;
  gap?: number;
  children: React.ReactNode;
}

export const RadioGroup: FunctionComponent<IRadioGroupProps> = ({ gap = 3, children, ...props }) => (
  <Group gap={gap} context={'radio-group'} vertical={!props.horizontal} {...props}>
    {children}
  </Group>
);

RadioGroup.displayName = 'Radio.Group';
