import { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { get } from 'lodash';
import { CrudTableTypes, IBulkRecordProps, ICrudOptions, ICrudTableRecordProps } from '../Crud.types';
import { Table } from '../../Table';
import { CrudTableRecordActions } from './ActionsCell';
import { CrudTableRecordCustomActions } from './Actions/Custom';

export interface IBaseCrudTableRow {
  children: ReactNode;
  disabled?: boolean;
  className?: string;
  indicatorComponent: JSX.Element;
  bulk?: IBulkRecordProps;
  actionsComponent: JSX.Element;
  tableOptions: ICrudOptions;
}

const BaseCrudTableRow: FunctionComponent<IBaseCrudTableRow> = ({ children, ...props }) => (
  <Table.Row {...props}>{children}</Table.Row>
);

const CrudTableRow = styled(BaseCrudTableRow)<{ disabled; bulk? }>`
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.table.body.row.hover_background};

    ${CrudTableRecordActions} {
      opacity: 1;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}

  ${({ bulk }) =>
    bulk?.checked &&
    css`
      background-color: ${({ theme }) => theme.table.body.row.selected.background};
      &:hover {
        background-color: ${({ theme }) => theme.table.body.row.selected.hover.background};
      }
    `}
`;

export const readModeCellContent = (column: CrudTableTypes.IColumn, record: object) =>
  (column.ReadModeComponent && <column.ReadModeComponent record={record} />) || get(record, column.recordPropertyName);

export const CrudTableRecord: FunctionComponent<ICrudTableRecordProps> = ({
  bulk,
  columns,
  record,
  onDuplicate,
  onDelete,
  onSearch,
  onStartLoading,
  onFinishLoading,
  onEdit,
  canEdit,
  canDelete,
  canDuplicate,
  indicatorComponent,
  customActions,
  isDense,
  DeleteButton,
  EditButton,
  DuplicateButton,
  disable,
  tableOptions,
  ...props
}) => {
  const recordCells = (record: object): JSX.Element[] =>
    columns.map(
      (column): JSX.Element => (
        <Table.Cell
          tableOptions={tableOptions}
          highlight={column.isHighlighted}
          key={column.key}
          minWidth={column.minWidth}
          context={`table-cell-${column.key}`}
        >
          {readModeCellContent(column, record)}
        </Table.Cell>
      ),
    );

  const actionsComponent = (record: object): JSX.Element | null => {
    const actions = customActions.filter((action): boolean =>
      action.shouldDisplay ? action.shouldDisplay(record) : true,
    );

    return actions.length > 0 ? (
      <CrudTableRecordCustomActions
        actions={actions}
        onSearch={onSearch}
        onStartLoading={onStartLoading}
        onFinishLoading={onFinishLoading}
        record={record}
      />
    ) : null;
  };

  return (
    <CrudTableRow
      disabled={disable}
      indicatorComponent={indicatorComponent(record)}
      {...(bulk ? { bulk: { checked: bulk.checked, onChange: isChecked => bulk.onChange(isChecked, record.id) } } : {})}
      actionsComponent={actionsComponent(record)}
      {...props}
      tableOptions={tableOptions}
    >
      {recordCells(record)}
      {tableOptions?.actions?.hide ? (
        <></>
      ) : (
        <CrudTableRecordActions
          tableOptions={tableOptions}
          onDelete={() => onDelete(record)}
          canEdit={canEdit}
          canDelete={canDelete}
          canDuplicate={canDuplicate}
          onEdit={() => onEdit(record)}
          onDuplicate={() => onDuplicate(record)}
          isDense={isDense}
          DeleteButton={DeleteButton}
          EditButton={EditButton}
          DuplicateButton={DuplicateButton}
          record={record}
        />
      )}
    </CrudTableRow>
  );
};
