import React from 'react';
import { DatePickerInputProps } from '../types';
import { BasicInput } from './BasicInput';
import { InputIcon } from './InputIcon';

export const StandardInput: React.FC<DatePickerInputProps> = props => {
  return (
    <>
      <BasicInput {...props} />
      <InputIcon />
    </>
  );
};
