import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TimeUnit } from '@logz-pkg/enums';
import { Select } from '../inputs/Select/Select.component';
import type { PopoverPlacement } from '../popover/types';

interface ITimeUnitProps {
  value?: TimeUnit;
  minTimeUnit?: TimeUnit;
  maxTimeUnit?: TimeUnit;
  onChange(timeUnit: TimeUnit): void;
  error?: string;
  errorPlacement?: PopoverPlacement;
  onBlur?: Function;
  subject?: string;
  borderless?: boolean;
}

const timeUnitsSelectOptions = [
  { value: TimeUnit.Milliseconds, label: 'milliseconds' },
  { value: TimeUnit.Seconds, label: 'seconds' },
  { value: TimeUnit.Minutes, label: 'minutes' },
  { value: TimeUnit.Hours, label: 'hours' },
  { value: TimeUnit.Days, label: 'days' },
];

const filterTimeUnitOptions = (minTimeUnit: TimeUnit, maxTimeUnit: TimeUnit) => {
  const minIndex = timeUnitsSelectOptions.findIndex(option => option.value === minTimeUnit);
  const maxIndex = timeUnitsSelectOptions.findIndex(option => option.value === maxTimeUnit);

  return timeUnitsSelectOptions.slice(minIndex, maxIndex + 1);
};

const StyledSelect = styled(Select)`
  width: 105px;
`;

export const TimeUnitSelect: FunctionComponent<ITimeUnitProps> = ({
  value,
  minTimeUnit = TimeUnit.Seconds,
  maxTimeUnit = TimeUnit.Days,
  onChange,
  error,
  errorPlacement,
  ...props
}) => {
  const filteredTimeUnitsSelectOptions = filterTimeUnitOptions(minTimeUnit, maxTimeUnit);

  return (
    <StyledSelect
      options={filteredTimeUnitsSelectOptions}
      value={filteredTimeUnitsSelectOptions.find(option => option.value === value)}
      isClearable={false}
      error={error}
      placeholder={'Time unit'}
      onChange={timeUnitOption => onChange(timeUnitOption.value)}
      errorPlacement={errorPlacement}
      subject={'time-unit-select'}
      {...props}
    />
  );
};
