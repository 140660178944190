import styled, { css } from 'styled-components';
import { Select } from '../../../inputs/Select/Select.component';
import { classNamePrefix } from '../../../inputs/Select/Select.styles';

const inputCss = css<{ hideSearch }>`
  .${classNamePrefix}__control {
    margin: ${({ theme }) => theme.space[2]}px;
    width: initial;
    background-color: ${({ theme }) => theme.searchFilters.popover.select.input.backgroundColor};

    &:hover:not(.${classNamePrefix}__control--is-focused) {
      border-color: ${({ theme }) => theme.searchFilters.popover.select.input.hover.notFocus.borderColor};
    }
    .${classNamePrefix}__placeholder {
      color: ${({ theme }) => theme.searchFilters.popover.select.input.placeholder.color};
    }
    &.${classNamePrefix}__control--menu-is-open {
      ${({ hideSearch }) =>
        hideSearch &&
        css`
          display: none;
        `}
    }
  }
`;

const menuCss = css<{ hideSearch }>`
  .${classNamePrefix}__menu {
    width: 100%;
    position: relative;
    box-shadow: none;
    border: none;
    border-top: 1px solid ${({ theme }) => theme.searchFilters.popover.menu.borderTop};
    ${({ hideSearch }) =>
      hideSearch &&
      css`
        margin-top: 0;
        border-top: 0;
      `}
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    .${classNamePrefix}__menu-list {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      background: ${({ theme }) => theme.searchFilters.popover.menu.list.backgroundColor};
      padding-bottom: ${({ theme }) => theme.space[2]}px;
    }
    .${classNamePrefix}__menu-list.${classNamePrefix}__menu-list--is-multi {
      .${classNamePrefix}__option--is-selected:not(:hover) {
        background-color: transparent;
      }
    }
  }
`;

export const StyledSelect = styled(Select)`
  background: ${({ theme }) => theme.searchFilters.popover.select.backgroundColor};
  border-radius: 5px;
  ${inputCss}
  ${menuCss}
`;
