import React, { createContext, FunctionComponent, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { height, HeightProps, space, SpaceProps } from 'styled-system';
import { colors } from '../themes';
import { Button } from '../Button/Button.component';

ReactModal.setAppElement('body');

export const ModalContext = createContext(null);

export const CloseModalButton = styled(props => <Button variant={'tertiary'} round {...props} />)`
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  margin: 0;
  color: ${colors.gray[800]};

  :hover {
    color: ${colors.gray[900]};
  }
`;

export const sizes = {
  auto: 'auto',
  s: '440px',
  m: '600px',
  ml: '950px',
  l: '90%',
};

// https://github.com/reactjs/react-modal/issues/627#issuecomment-578814799
const StyledModal = styled(({ className, ...props }) => (
  <ReactModal portalClassName={className} overlayClassName={'overlay'} className={className} {...props} />
))`
  .ReactModal__Content {
    background-color: ${({ theme }) => theme?.colors?.background};
    color: ${({ theme }) => theme?.colors?.text?.base};
    font-size: ${({ theme }) => theme?.sizes?.text?.medium};
    font-weight: ${({ theme }) => theme?.sizes?.fontWeight?.normal};
    position: relative;
    border-radius: 5px;
    border: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
    width: ${({ size }) => sizes[size] || size};
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 95%;
    outline: none;
    overflow: hidden;
    ${space}
    ${height}
  }

  .ReactModal__Overlay {
    z-index: 1300;
    padding: 40px;
    background-color: ${({ theme }) => theme?.modal?.overlay?.backgroundColor};
    display: flex;
    justify-content: center;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export interface IModalProps extends SpaceProps, HeightProps {
  size?: keyof typeof sizes | string;
  open: boolean;
  onClose?(): void;
  dontCloseOnOverlayClick?: boolean;
  subject?: string;
  noCloseButton?: boolean;
  onAfterOpen?(): void;
  alignItems?: string;
  children: React.ReactNode;
}

export const Modal: FunctionComponent<IModalProps> = ({
  open = false,
  size = 'm',
  subject,
  dontCloseOnOverlayClick = false,
  children,
  onClose,
  noCloseButton = false,
  alignItems,
  ...props
}) => {
  const [modalRef, setModalRef] = useState(null);

  return (
    <ModalContext.Provider value={modalRef}>
      <StyledModal
        data={{ 'logz-test-context': 'modal', ...(subject ? { 'logz-test-subject': subject } : {}) }}
        isOpen={open}
        shouldCloseOnOverlayClick={!dontCloseOnOverlayClick}
        size={size}
        onRequestClose={onClose}
        overlayRef={node => setModalRef(node)}
        alignItems={alignItems}
        {...props}
      >
        {!noCloseButton && <CloseModalButton icon={'xmark-regular'} onClick={onClose} subject="close-modal" />}
        {children}
      </StyledModal>
    </ModalContext.Provider>
  );
};

Modal.displayName = 'Modal';
