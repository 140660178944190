export enum GeneralSettingsSubject {
  GeneralSettingsPage = 'general-settings-page',
  AccountToken = 'account-token',
  AccountLogoUpload = 'account-logo-upload',
  AccountLogoImage = 'account-logo-image',
  AccountLogoDelete = 'account-logo-delete',
  AccountLogoImageSpinner = 'account-logo-image-spinner',
  UpdateTheme = 'update-theme',
  UserEmail = 'user-email',
  UserFullName = 'user-full-name',
  AccountName = 'account-name',
  Password = 'password',
  PciLevel = 'pci-level',
  RefreshKibanaMappingNotification = 'refresh-kibana-mapping-notification',
  RefreshKibanaMappingButton = 'refresh-kibana-mapping-button',
  SupportAccessToggle = 'support-success-toggle',
  EnabledSupportAccess = 'enabled-support-success',
  DisabledSupportAccess = 'disabled-support-success',
  UpdateSupportAccessButton = 'update-support-success-button',
  SelectSupportAccess = 'select-support-success',
  ChangeSupportAccess = 'change-support-access',
  LandingPageValue = 'landing-page-current-value',
  LandingPageSelector = 'landing-page-selector',
  LandingPageResetButton = 'landing-page-reset-button',
  ConfirmLandingPageModal = 'confirm-landing-page',
  LandingPageChangeNotification = 'landing-page-notification',
  LoadingAccountSettingsSection = 'loading-account-settings-section',
}

export enum GeneralSettingsPasswordSubject {
  New = 'new-password-input',
  Current = 'current-password-input',
  ChangeButton = 'change-password-button',
  UpdateButton = 'update-password-button',
  NotificationUpdateSuccess = 'update-success-notification',
  NotificationUpdateFailed = 'update-failure-notification',
  Cancel = 'cancel-button',
}

export enum GeneralSettingsSectionsSubject {
  User = 'user-section',
  Account = 'account-section',
}

export enum GeneralSettingsPciStatus {
  Initial = 'INITIAL',
  PendingActivation = 'PENDING_ACTIVATE',
  Activated = 'ACTIVATED',
  PendingDeactivation = 'PENDING_DEACTIVATE',
  Deactivated = 'DEACTIVATED',
}

export enum GeneralSettingsPciSubject {
  Button = 'activate-button',
  ConfirmModal = 'pci-confirm-modal',
  Checkbox = 'pci-checkbox',
  ActivationSent = 'pci-text',
  Row = 'pci-row',
  RequestSentNotification = 'request-sent-notification',
  OpensearchDashboards = 'opensearch-dashboards',
}
