import { bluePalette, grayPalette, redPalette, royalBluePalette } from '../../themes/colors';
import { IInputTheme } from './theme';

export const inputLightTheme: IInputTheme = {
  variants: {
    primary: {
      styles: {
        color: royalBluePalette[1000],
        background: grayPalette[100],
        borderColor: grayPalette[300],
      },
      placeholder: {
        color: grayPalette[700],
      },
      active: {
        color: royalBluePalette[1000],
        borderColor: royalBluePalette[1000],
        shadowColor: bluePalette[300],
        '&::placeholder': {
          color: grayPalette[700],
        },
      },
      hover: {
        color: royalBluePalette[1000],
        borderColor: bluePalette[700],
      },
      focus: {
        color: royalBluePalette[1000],
        borderColor: royalBluePalette[1000],
        '&::placeholder': {
          color: grayPalette[700],
        },
      },
      disabled: {
        color: grayPalette[700],
        background: grayPalette[300],
        borderColor: grayPalette[300],
        '&::placeholder': {
          color: grayPalette[500],
        },
      },
      borderless: {
        borderColor: grayPalette[300],
        hover: {
          borderColor: royalBluePalette[1000],
        },
      },
      error: {
        borderColor: redPalette[600],
        hover: {
          borderColor: redPalette[700],
        },
        active: {
          shadowColor: redPalette[200],
        },
      },
    },
    secondary: {
      styles: {
        color: grayPalette[100],
        background: grayPalette[100],
        borderColor: grayPalette[300],
      },
      placeholder: {
        color: grayPalette[700],
      },
      active: {
        color: grayPalette[100],
        borderColor: grayPalette[100],
        shadowColor: bluePalette[300],
        '&::placeholder': {
          color: grayPalette[700],
        },
      },
      hover: {
        color: grayPalette[100],
        borderColor: grayPalette[100],
      },
      focus: {
        color: grayPalette[100],
        borderColor: grayPalette[100],
        '&::placeholder': {
          color: grayPalette[700],
        },
      },
      disabled: {
        color: grayPalette[700],
        background: grayPalette[300],
        borderColor: grayPalette[300],
        '&::placeholder': {
          color: grayPalette[500],
        },
      },
      borderless: {
        borderColor: grayPalette[300],
        hover: {
          borderColor: grayPalette[100],
        },
      },
      error: {
        borderColor: redPalette[600],
        hover: {
          borderColor: redPalette[700],
        },
        active: {
          shadowColor: redPalette[200],
        },
      },
    },
  },
};
