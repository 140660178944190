import React, { useCallback } from 'react';
import { Button } from '../../Button/Button.component';
import { Card } from '../../Card/Card.component';
import { useHotkeysNavigation } from '../hooks/hotkeys-navigation.hook';
import { popMenuLightTheme } from '../themes/light.theme';
import { ButtonsConfig } from '../types';

interface IButtonBarProps {
  closePopover: () => void;
  buttonsConfig: ButtonsConfig;
}

export const ButtonBar: React.FC<IButtonBarProps> = ({ closePopover, buttonsConfig }) => {
  const { buttonBar: buttonBarTheme } = popMenuLightTheme;
  const { custom, cancel, apply } = buttonsConfig;
  const handleButtonClick = useCallback(
    buttonType => {
      const button = buttonsConfig[buttonType];

      if (button?.onClick) button.onClick();

      if (button?.shouldClosePopover !== false) closePopover();
    },
    [buttonsConfig, closePopover],
  );

  useHotkeysNavigation(() => handleButtonClick('apply'), closePopover);

  return (
    <Card
      backgroundColor={buttonBarTheme.backgroundColor}
      width={'100%'}
      px={4}
      minHeight={50}
      borderBottomRightRadius={4}
      borderBottomLeftRadius={4}
      borderless
      flexDirection={'row'}
      alignItems={'center'}
    >
      <Button
        variant="tertiary"
        size="m"
        onClick={() => handleButtonClick('custom')}
        mr={'auto'}
        disabled={custom.disabled}
      >
        {custom.text}
      </Button>
      <Button
        variant={'secondary'}
        size="m"
        onClick={() => handleButtonClick('cancel')}
        mr={2}
        disabled={cancel.disabled}
      >
        {cancel.text}
      </Button>
      <Button variant="primary" size="m" onClick={() => handleButtonClick('apply')} disabled={apply.disabled}>
        {apply.text}
      </Button>
    </Card>
  );
};
