import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from '../../../tooltip/Tooltip.component';
import { Button } from '../../../Button/Button.component';

const StyledDiv = styled.div<{ isVisible: boolean }>`
  position: absolute;
  left: 50%;
  bottom: 20px;
  z-index: 10;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translateY(50px);
  transition: all 0.5s ease-in-out allow-discrete;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 0.8;
      transform: translateY(0);
      height: auto;
    `}
`;

type TReturnToTopProps = {
  handleClick: () => void;
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  estimateRowHeight: number;
  loading?: boolean;
};

const ReturnToTop = ({ handleClick, scrollContainerRef, estimateRowHeight, loading }: TReturnToTopProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const checkScrollTop = () => {
    if (!isVisible && scrollContainerRef.current.scrollTop > estimateRowHeight) {
      setIsVisible(true);
    } else if (isVisible && scrollContainerRef.current.scrollTop <= estimateRowHeight) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', checkScrollTop);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', checkScrollTop);
      }
    };
  }, [isVisible, scrollContainerRef]);

  return (
    <StyledDiv isVisible={isVisible}>
      <Tooltip title="Back to top">
        <Button round variant="contrast" icon="arrow-up-regular" onClick={handleClick} loading={loading} />
      </Tooltip>
    </StyledDiv>
  );
};

ReturnToTop.displayName = 'ReturnToTop';

export { ReturnToTop };
