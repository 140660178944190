import { ISearchRequestObject } from '@logz-build/typescript';
import { SearchFilterType } from '@logz-pkg/enums';
import { useUpdateEffect } from '@logz-pkg/react-hooks';
import React, { forwardRef, useMemo, useState } from 'react';
import { PopoverError } from '../../popover/PopoverError.component';
import { Button } from '../../Button/Button.component';
import { Box } from '../../containers/Container/Box.component';
import { Flex } from '../../containers/Flex/Flex.component';
import { Group } from '../../containers/Group/Group.component';
import { Info } from '../../Info/Info.component';
import { InputSearch } from '../../Input';
import { BooleanFilter, FilterBar, MultiSelectFilter, SelectFilter, ToggleFilter } from '../../SearchFilters';
import { IFilterField } from '../../SearchFilters/interfaces';
import { TooltipPlacement } from '../../tooltip/Tooltip.component';
import { DateRangePickerFilter } from '../../SearchFilters/Filters/DateRangePicker/DateRangePickerFilter';
import { SuperDateRangePickerFilter } from '../../SearchFilters/Filters/SuperDateRangePicker/SuperDateRangePickerFilter';
import { CrudSearchFilter, ICrudTableProps } from './Crud.types';

export const FilterComponents: Record<SearchFilterType, React.FC<IFilterField>> = {
  [SearchFilterType.Boolean]: BooleanFilter,
  [SearchFilterType.MultiSelect]: MultiSelectFilter,
  [SearchFilterType.Select]: SelectFilter,
  [SearchFilterType.Toggle]: ToggleFilter,
  [SearchFilterType.DateRangePicker]: DateRangePickerFilter,
  [SearchFilterType.SuperDateRangePicker]: SuperDateRangePickerFilter,
};

interface IActionBarProps {
  onAddClick();

  fullWidth: boolean;
  subject?: string;
  disableAddButton: boolean;
  addButtonText: string;
  searchTooltip: string;
  searchPlaceholder?: string;
  showAddButton: boolean;
  showSearchInput: boolean;

  handleFiltersChange(filters: ISearchRequestObject['filter']): void;

  disabledAddButtonTooltip: string;
  error: string;
  tooltipPlacement?: TooltipPlacement;
  renderAddButton?: (addButton) => JSX.Element;
  inCreateMode: boolean;
  isTableLoading: boolean;
  actionBarOptions?: ICrudTableProps['options']['actionBar'];
  searchFilters?: CrudSearchFilter[];
  initialRequestObject: ISearchRequestObject;
  onSearchClick?: (value?: any) => void;
}

export const ActionBar: React.FunctionComponent<IActionBarProps> = ({
  fullWidth,
  onAddClick,
  onSearchClick,
  searchTooltip,
  searchPlaceholder = 'Search',
  addButtonText,
  showSearchInput,
  showAddButton,
  disableAddButton,
  handleFiltersChange,
  disabledAddButtonTooltip,
  tooltipPlacement,
  error,
  subject,
  renderAddButton = CustomButton => CustomButton && <CustomButton />,
  inCreateMode,
  isTableLoading,
  actionBarOptions = {},
  searchFilters,
  initialRequestObject,
}) => {
  const [filtersValue, setFiltersValue] = useState<ISearchRequestObject['filter']>(initialRequestObject?.filter || {});

  // Skips the first formik rerender
  useUpdateEffect(() => {
    handleFiltersChange(filtersValue);
  }, [filtersValue]);

  const { searchTerm = '', ...searchFiltersInitialValues } = initialRequestObject?.filter ?? {};
  const shouldDisableAddButton = disableAddButton || inCreateMode || isTableLoading;
  const shouldShowAddButtonTooltip = !inCreateMode && !isTableLoading;
  const searchFiltersDefaultValues = useMemo(
    () => searchFilters?.reduce((acc, cur) => ({ ...acc, [cur.props.name]: cur.defaultValue }), {}),
    [searchFilters],
  );

  const AddButton = showAddButton
    ? forwardRef<HTMLButtonElement>((_, ref) => (
        <Button
          ref={ref}
          onClick={onAddClick}
          icon={'plus-regular'}
          disabled={shouldDisableAddButton}
          subject={'table-add-button'}
          disabledTooltip={shouldShowAddButtonTooltip ? disabledAddButtonTooltip : null}
          tooltipPlacement={tooltipPlacement}
        >
          {addButtonText}
        </Button>
      ))
    : null;
  const { BottomComponent, LeftComponent, RightComponent, showClearFiltersButton = true } = actionBarOptions;

  const ActionBar = (
    <>
      <Group alignItems={'center'} mb={3} subject={`${subject || 'table'}-action-bar`} width={'100%'}>
        {LeftComponent ? <LeftComponent /> : null}
        <Group justifyContent={'space-between'} alignItems={'end'} flexGrow={1}>
          {showAddButton && (
            <PopoverError error={error} placement={'right'}>
              {renderAddButton(AddButton)}
            </PopoverError>
          )}
          {Boolean(searchFilters?.length) && (
            <FilterBar
              defaultValues={searchFiltersDefaultValues}
              initialValues={searchFiltersInitialValues}
              onChange={filters => setFiltersValue(({ searchTerm }) => ({ ...filters, searchTerm }))}
              showClearFiltersButton={showClearFiltersButton}
            >
              {searchFilters.map(filter => {
                const Component = FilterComponents[filter.type];

                return <Component key={filter.props.name} {...filter.props} />;
              })}
            </FilterBar>
          )}
          {showSearchInput && (
            <Flex alignItems="flex-end" ml={'auto'}>
              <InputSearch
                debounceTime={actionBarOptions.inputSearchDebounceTime}
                disableEnter={actionBarOptions.disableInputSearchEnterKey}
                defaultValue={searchTerm}
                mb={0}
                mr={1}
                name="search"
                width="300px"
                placeholder={searchPlaceholder}
                subject="crud-search"
                disableAutoComplete={true}
                onChange={searchTerm => setFiltersValue(prevState => ({ ...prevState, searchTerm }))}
                onClick={onSearchClick}
              />
              {searchTooltip && <Info tooltip={searchTooltip} iconSize={16} />}
            </Flex>
          )}
        </Group>
        {RightComponent ? <RightComponent /> : null}
      </Group>
      {BottomComponent ? <BottomComponent filtersValue={filtersValue} /> : null}
    </>
  );

  if (
    !showAddButton &&
    !Boolean(searchFilters?.length) &&
    !showSearchInput &&
    !actionBarOptions.LeftComponent &&
    !actionBarOptions.RightComponent &&
    !actionBarOptions.BottomComponent
  )
    return null;

  if (!fullWidth) return ActionBar;

  return <Box px={8}>{ActionBar}</Box>;
};
