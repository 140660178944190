import { FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { ICrudTableEditRecordProps, CrudTableEvents, ICrudOptions } from '../../Crud.types';
import { Table } from '../../../Table';
import { readModeCellContent } from '../Record';
import { CrudTableEditRecordActions } from './ActionsCell';

const StyledForm = styled.form`
  display: contents;
`;

const StyledTableRow = styled(Table.Row)`
  align-items: ${({ options }: { options: ICrudOptions }) => {
    return options?.tableStyles?.cellContentAlignment ?? 'baseline';
  }};
`;

const CrudTableRecordEdit: FunctionComponent<ICrudTableEditRecordProps> = ({
  columns,
  onCancel,
  onCreate,
  onUpdate,
  record,
  validationSchema,
  validate,
  className = '',
  isNew = false,
  options,
  ...props
}) => {
  const renderColumns = (record: object) =>
    columns.map(column => (
      <Table.Cell key={column.key} context={`table-cell-edit-${column.key}`}>
        {column.editModeComponent ? column.editModeComponent(isNew, record) : readModeCellContent(column, record)}
      </Table.Cell>
    ));

  useEffect(() => {
    document.dispatchEvent(new Event(CrudTableEvents.TableInEdit));
  }, []);

  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Formik
      initialValues={record}
      validationSchema={validationSchema}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          isNew ? await onCreate(values) : await onUpdate(values);
        } finally {
          mounted.current && setSubmitting(false);
        }
      }}
      innerRef={() => mounted}
    >
      {({ isSubmitting, isValid, handleSubmit, dirty }) => (
        <StyledForm onSubmit={handleSubmit}>
          <StyledTableRow className={className} options={options} {...props}>
            {renderColumns(record)}
            <CrudTableEditRecordActions
              {...{ onCancel, isSubmitting, isNew, isValid, options: options?.actions?.edit, dirty }}
            />
          </StyledTableRow>
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledCrudTableEditRecord = styled(CrudTableRecordEdit)`
  border: ${({ theme }) => theme.table.body.row.focusedRow.border};
  box-shadow: ${({ theme }) => theme.table.body.row.focusedRow.shadow};

  &:not(:last-of-type) {
    border-bottom: ${({ theme }) => theme.table.body.row.focusedRow.border};
  }
`;

export { StyledCrudTableEditRecord as CrudTableEditRecord };
