import React, { ComponentProps, FunctionComponent } from 'react';
import { isNil } from 'lodash';
import { ModalSubjects } from '@logz-pkg/enums';
import { Icon } from '../icons/Icon.component';
import { baseVariants } from '../core/index';
import { Button } from '../Button/Button.component';
import { Group } from '../containers/Group/Group.component';
import { Title } from '../typography/Title/Title.component';
import { IModalProps, Modal } from './index';

export interface IConfirmModalProps extends Omit<IModalProps, 'open' | 'children'> {
  isOpen?: boolean;
  onClose?(...args: any[]): any;
  onConfirm?(...args: any[]): any;
  confirmText?: string;
  confirmIcon?: ComponentProps<typeof Icon>['icon'];
  cancelText?: string;
  isConfirming?: boolean;
  isConfirmDisabled?: boolean;
  title?: string;
  subject?: string;
  variant?: keyof typeof baseVariants;
  children?: React.ReactNode;
}

const VariantButton = ({ variant, ...props }) =>
  Object.keys(baseVariants).includes(variant) ? (
    <Button variant={variant} {...props} /> // TODO: remove mx @DEV-15607
  ) : (
    <Button {...props} /> // TODO: remove mx @DEV-15607
  );

export const ConfirmModal: FunctionComponent<IConfirmModalProps> = ({
  isOpen = false,
  isConfirming = false,
  isConfirmDisabled = false,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  subject = 'confirm',
  confirmIcon,
  onClose,
  onConfirm,
  variant,
  title,
  children,
  ...props
}) => (
  <Modal open={isOpen} subject={subject} onClose={onClose} {...props}>
    {!isNil(title) && (
      <Modal.Header>
        <Title size={24}>{title}</Title>
      </Modal.Header>
    )}
    {children && (
      <Modal.Body flexDirection={'column'} color={'gray.800'}>
        {children}
      </Modal.Body>
    )}
    <Modal.Footer justifyContent={'flex-end'}>
      <Group gap={3}>
        {onClose && (
          <Button variant={'secondary'} onClick={onClose} disabled={isConfirming} subject="modal-confirm-close-button">
            {cancelText}
          </Button>
        )}
        {onConfirm && (
          <VariantButton
            variant={variant}
            onClick={onConfirm}
            loading={isConfirming}
            disabled={isConfirmDisabled}
            subject={ModalSubjects.ConfirmButton}
            icon={confirmIcon}
          >
            {confirmText}
          </VariantButton>
        )}
      </Group>
    </Modal.Footer>
  </Modal>
);
