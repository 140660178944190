export enum KibanaQuickTimeToday {
  Mode = 'quick',
  From = 'now/d',
  To = 'now/d',
}

export enum KibanaSubjects {
  FetchLogs = 'fetch-logs',
}

export enum KibanaAnalytics {
  KibanaSearchAnalyticsKey = 'kibana-search',
}

export enum KibanaSharedComponentsSubjects {
  previewQueryInKibana = 'preview-query-in-kibana',
}

export enum SearchTimeFrame {
  '48h' = '48h',
  '24h' = '24h',
  '12h' = '12h',
  '8h' = '8h',
  '4h' = '4h',
  '2h' = '2h',
  '1h' = '1h',
  '30m' = '30m',
  '15m' = '15m',
}

export const timeFrameInterval: Record<keyof typeof SearchTimeFrame, string> = {
  '48h': '30m',
  '24h': '15m',
  '12h': '10m',
  '8h': '5m',
  '4h': '3m',
  '2h': '2m',
  '1h': '2m',
  '30m': '1m',
  '15m': '1m',
};

export const timeFrameIntervalAsMinutes: Record<SearchTimeFrame, 30 | 15 | 10 | 5 | 2> = {
  '48h': 30,
  '24h': 30,
  '12h': 15,
  '8h': 10,
  '4h': 5,
  '2h': 2,
  '1h': 2,
  '30m': 30,
  '15m': 15,
};

export const timeFrameAsHours: Record<SearchTimeFrame, 48 | 24 | 12 | 8 | 4 | 2 | 1 | 0.5 | 0.25> = {
  '48h': 48,
  '24h': 24,
  '12h': 12,
  '8h': 8,
  '4h': 4,
  '2h': 2,
  '1h': 1,
  '30m': 0.5,
  '15m': 0.25,
};
