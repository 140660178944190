import { merge } from 'lodash';
import { NotificationLightTheme } from '../Notification/theme/light.theme';
import { multiTagLightV2Theme } from '../MultiTag/themes/light.theme';
import { breadcrumbsLightTheme } from '../Breadcrumbs/themes/light-v2.theme';
import { textLightTheme } from '../typography/Text/themes/light-v2.theme';
import { buttonLightTheme } from '../Button/themes/light-v2.theme';
import { cardLightTheme } from '../Card/themes/light.theme';
import { linkLightTheme } from '../typography/Link/themes/light-v2.theme';
import { titleLightThemeV2 } from '../typography/Title/themes/light-v2.theme';
import { pageLightTheme } from '../containers/Page/themes/light-v2.theme';
import { dividerLightTheme } from '../Divider/themes/light-v2.theme';
import { headerLightTheme } from '../Header/themes/light-v2.theme';
import { radioLightTheme } from '../Radio/themes/light-v2.theme';
import { badgeLightTheme } from '../Badge/themes/light-v2.theme';
import { inlineNotificationLightTheme } from '../InlineNotification/themes/light-v2.theme';
import { highlightLightTheme } from '../Notifications & Messages/themes/light-v2.theme';
import { buttonGroupLightTheme } from '../ButtonGroup/themes/light-v2.theme';
import { modalLightTheme } from '../Modal/themes/light.theme';
import { inputLightTheme } from '../Input/themes/light-v2.theme';
import { linearProgressTheme } from '../progress/LinearProgress/themes/light-v2.theme';
import { spinnerLightThemeV2 } from '../progress/Spinner/themes/lightThemeV2';
import { checkboxLightTheme } from '../Checkbox/themes/light-v2.theme';
import { tooltipLightTheme } from '../tooltip/themes/light-v2.theme';
import { tabsLightTheme } from '../Tabs/themes/light-v2.theme';
import { fieldContainerLightTheme } from '../containers/FieldContainer/themes/light-v2.theme';
import { infoLightV2Theme } from '../Info/themes/light-v2.theme';
import { selectLightV2Theme } from '../inputs/Select/themes/light-v2.theme';
import { searchLightTheme } from '../Input/Search/themes/light-v2.theme';
import { toggleLightV2Theme } from '../Toggle/themes/light-v2.theme';
import { primarySidenavLightTheme } from '../Sidenav/Primary/themes/light-v2.theme';
import { secondarySidenavLightTheme } from '../Sidenav/Secondary/themes/light-v2.theme';
import { searchFiltersLightThemeV2 } from '../SearchFilters/themes/lightThemeV2';
import { scrollSnappingLightThemeV2 } from '../ScrollSnapping/themes/light-v2.theme';
import { filterLightThemeV2 } from '../Filter/themes/light-v2.theme';
import { collapseLightV2Theme } from '../Collapse/themes/light-v2.theme';
import { tagLightV2Theme } from '../Tag/themes/light-v2.theme';
import { menuLightV2Theme } from '../menu/themes/light-V2.theme';
import { popMenuLightTheme } from '../popMenu/themes/light.theme';
import { containerLightV2Theme } from '../containers/Container/themes/light-v2.theme';
import { BaseTheme } from './base';
import { colors } from './colors';

// Supporting base V1 theme + V2 overrides.
export const lightV2Theme = merge({}, BaseTheme, {
  name: 'light',
  // For migration purposes only.
  version: 2,
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48],
  shadows: {
    none: 'none',
    line: '0 1px 0px 0 rgb(231 231 231)',
    small: '0 2px 6px 0 rgba(204, 213, 217, 0.8)',
    large: '0 10px 10px rgba(159, 171, 188, 0.3), 0 5px 25px rgba(159, 171, 188, 0.3)',
  },
  fonts: {
    text: 'Roboto, sans-serif',
    code: "'Roboto Mono', monospace",
    title: 'Ubuntu, sans-serif',
  },
  colors,
  text: textLightTheme,
  button: buttonLightTheme,
  link: linkLightTheme,
  card: cardLightTheme,
  container: containerLightV2Theme,
  page: pageLightTheme,
  divider: dividerLightTheme,
  header: headerLightTheme,
  radio: radioLightTheme,
  badge: badgeLightTheme,
  inlineNotification: inlineNotificationLightTheme,
  highlight: highlightLightTheme,
  buttonGroup: buttonGroupLightTheme,
  popMenu: popMenuLightTheme,
  modal: modalLightTheme,
  input: inputLightTheme,
  linearProgress: linearProgressTheme,
  searchFilters: searchFiltersLightThemeV2,
  scrollSnapping: scrollSnappingLightThemeV2,
  spinner: spinnerLightThemeV2,
  checkbox: checkboxLightTheme,
  tooltip: tooltipLightTheme,
  title: titleLightThemeV2,
  fieldContainer: fieldContainerLightTheme,
  tabs: tabsLightTheme,
  info: infoLightV2Theme,
  select: selectLightV2Theme,
  toggle: toggleLightV2Theme,
  search: searchLightTheme,
  primarySidenav: primarySidenavLightTheme,
  secondarySidenav: secondarySidenavLightTheme,
  breadcrumbs: breadcrumbsLightTheme,
  multiFilter: filterLightThemeV2,
  collapse: collapseLightV2Theme,
  multiTag: multiTagLightV2Theme,
  tag: tagLightV2Theme,
  menu: menuLightV2Theme,
  notification: NotificationLightTheme,
});
