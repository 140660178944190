import React, { useContext } from 'react';
import { BulkActionsStateContext } from '../Crud';
import { Group } from '../../../containers/Group/Group.component';
import { ICustomAction } from '../Crud.types';
import { IBulkActionsProps } from './BulkActionsBar';
import { CustomBulkAction } from './Actions/Custom';
import { actionToComponentsMapping } from './ActionsComponents';

function isCustom(action): action is ICustomAction {
  return action.type === 'custom';
}

export const ActionsContainer: React.FunctionComponent = () => {
  const { actions } = useContext<IBulkActionsProps>(BulkActionsStateContext);

  return (
    <Group flexWrap={'wrap'} gap={2}>
      {actions.map(action => {
        if (isCustom(action)) {
          return (
            <CustomBulkAction
              key={action.label}
              label={action.label}
              icon={action.icon}
              onConfirm={action.onConfirm}
              Modal={action.Modal}
            />
          );
        }

        return actionToComponentsMapping[action.type](action);
      })}
    </Group>
  );
};
