import { FunctionComponent } from 'react';
import { MathOperator } from '@logz-pkg/enums';
import { Select } from '../../Select.component';
import { SelectSizes } from '../../select-sizes.enum';
import { SelectDecoratedOption } from '../../components/DecoratedOption.component';
import type { ISelectOption, ISelectProps } from '../../Select.types';

// The reason for using char code for the signs
// https://shripadk.github.io/react/docs/jsx-gotchas.html
const humanReadableMathOperator: Record<MathOperator, { name: string; sign: number }> = {
  [MathOperator.GreaterThan]: { name: 'greater than', sign: 62 },
  [MathOperator.GreaterThanOrEquals]: { name: 'greater than or equal to', sign: 8805 },
  [MathOperator.LessThan]: { name: 'less than', sign: 60 },
  [MathOperator.LessThanOrEquals]: { name: 'less than or equal to', sign: 8804 },
  [MathOperator.Equals]: { name: 'equal to', sign: 61 },
  [MathOperator.NotEquals]: { name: 'not equal to', sign: 8800 },
};

export const mathOperatorSelectOptions: ISelectOption<MathOperator>[] = [
  { label: humanReadableMathOperator[MathOperator.GreaterThan].name, value: MathOperator.GreaterThan },
  { label: humanReadableMathOperator[MathOperator.GreaterThanOrEquals].name, value: MathOperator.GreaterThanOrEquals },
  { label: humanReadableMathOperator[MathOperator.LessThan].name, value: MathOperator.LessThan },
  { label: humanReadableMathOperator[MathOperator.LessThanOrEquals].name, value: MathOperator.LessThanOrEquals },
  { label: humanReadableMathOperator[MathOperator.Equals].name, value: MathOperator.Equals },
  { label: humanReadableMathOperator[MathOperator.NotEquals].name, value: MathOperator.NotEquals },
];

const getMathOperatorSign = (value: MathOperator) => String.fromCharCode(humanReadableMathOperator[value].sign);

interface IMathOperatorSelectProps extends ISelectProps {
  borderless?: boolean;
  label?: string;
  value: MathOperator;
  onChange(choice: { label: string; value: MathOperator }): void;
  onBlur?: any;
  isDisabled?: boolean;
  attachToBody?: ISelectProps['attachToBody'];
}

export const SelectMathOperator: FunctionComponent<IMathOperatorSelectProps> = ({
  label,
  value,
  borderless,
  onChange,
  ...props
}) => {
  const OptionElement: FunctionComponent<ISelectOption<MathOperator>> = ({ label, value }) => (
    <SelectDecoratedOption label={label} decoration={getMathOperatorSign(value)} />
  );

  const SingleValueElement: FunctionComponent<ISelectOption<MathOperator>> = ({ value }) => (
    <SelectDecoratedOption label={getMathOperatorSign(value)} />
  );

  return (
    <Select
      options={mathOperatorSelectOptions}
      value={mathOperatorSelectOptions.find(option => option.value === value)}
      subject={'math-operator-select'}
      aria-label={'math-operator-select'}
      borderless={borderless}
      size={SelectSizes.Tiny}
      fitMenuToContent
      placeholder={'Operator'}
      OptionElement={OptionElement}
      SingleValueElement={SingleValueElement}
      onChange={onChange}
      {...props}
    />
  );
};
