import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Group } from '../../../containers/Group/Group.component';
import { Table } from '../../Table';
import { ICrudOptions, ICrudTableProps, ICrudTableRecordProps } from '../Crud.types';
import { CrudTableRecordDeleteAction } from './Actions/Delete';
import { CrudTableRecordEditAction } from './Actions/Edit';
import { CrudTableRecordDuplicateAction } from './Actions/Duplicate';

export const actionsCellStyle = ({ tableOptions }) => css<{ tableOptions: ICrudOptions }>`
  flex: 0 1 150px; // Adjust size to the 2 buttons of the actions
  padding: ${typeof tableOptions?.tableStyles?.cellPadding === 'number' ? tableOptions?.tableStyles?.cellPadding : 15}px
    8px;
  align-items: ${({ tableOptions }: { tableOptions: ICrudOptions }) => {
    return tableOptions?.tableStyles?.cellContentAlignment ?? 'baseline';
  }};
`;

interface IBaseCrudTableRecordActionsProps {
  className?: string;
  onDelete(): void;
  onEdit(): void;
  onDuplicate(): void;
  canEdit: boolean;
  canDelete: boolean;
  canDuplicate: boolean;
  isDense: boolean;
  record: object;
  DeleteButton?: React.ComponentType<{ onDelete: ICrudTableRecordProps['onDelete'] }>;
  EditButton?: ICrudTableProps['EditButton'];
  DuplicateButton?: React.ComponentType<{ onDuplicate: ICrudTableRecordProps['onDuplicate'] }>;
}

interface IBaseCrudTableHeaderActions {
  className?: string;
}

const BaseCrudTableRecordActions: FunctionComponent<IBaseCrudTableRecordActionsProps> = ({
  className,
  onDelete,
  onEdit,
  onDuplicate,
  canEdit,
  canDelete,
  canDuplicate,
  isDense,
  DeleteButton,
  EditButton,
  DuplicateButton,
  record,
}) => {
  const DeleteButtonComponent = DeleteButton ? (
    <DeleteButton onDelete={onDelete} />
  ) : (
    <CrudTableRecordDeleteAction onDelete={onDelete} isDense={isDense} />
  );

  const EditButtonComponent = EditButton ? (
    <EditButton onEdit={onEdit} isDense={isDense} record={record} />
  ) : (
    <CrudTableRecordEditAction onEdit={onEdit} isDense={isDense} />
  );

  const DuplicateButtonComponent = DuplicateButton ? (
    <DuplicateButton onDuplicate={onDuplicate} />
  ) : (
    <CrudTableRecordDuplicateAction onDuplicate={onDuplicate} isDense={isDense} />
  );

  return (
    <Table.ActionCell className={className}>
      <Group gap={3}>
        {canEdit && EditButtonComponent}
        {canDuplicate && DuplicateButtonComponent}
        {canDelete && DeleteButtonComponent}
      </Group>
    </Table.ActionCell>
  );
};

export const CrudTableRecordActions = styled(BaseCrudTableRecordActions)`
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  ${actionsCellStyle}
`;

const BaseCrudTableHeaderActions: FunctionComponent<IBaseCrudTableHeaderActions> = ({ className }) => (
  <Table.ActionCell className={className} />
);

export const CrudTableHeaderActions = styled(BaseCrudTableHeaderActions)`
  ${actionsCellStyle}
`;
