const removeEmpty = obj =>
  Object.keys(obj).reduce(
    (cleanObject, key) => (obj[key] !== undefined ? { ...cleanObject, [key]: obj[key] } : cleanObject),
    {},
  );

export const extractContainerProps = ({
  m,
  mt,
  mr,
  mb,
  ml,
  mx,
  my,
  p,
  pt,
  pr,
  pb,
  pl,
  px,
  py,
  className,
  ...props
}) => ({
  containerProps: removeEmpty({
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    className,
  }),
  ...props,
});
