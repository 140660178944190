import { opacity } from '../../utils';
import { colors } from '../colors';

export const buttonsGroup = {
  background_checked: colors.blue[500],
  background_unchecked: colors.gray[200],
  border: `1px solid ${colors.gray[500]}`,
  border_disabled: `1px solid ${opacity(colors.gray[500], 40)}`,
  color_checked: colors.gray[0],
  color_disabled: colors.gray[800],
  color_enabled: colors.blue[500],
  background_hover_checked: opacity(colors.gray[500], 70),
  background_hover_disabled: colors.gray[200],
  background_hover_enabled: opacity(colors.gray[500], 10),
};
