import React from 'react';
import { Input } from '../Input';
import { LazyLoader } from './LazyLoader';

const LazyDateRangeTimePicker = React.lazy(() =>
  import('../datetime').then(({ DateRangePicker }) => ({ default: DateRangePicker })),
);

const LazyDateTimePicker = React.lazy(() =>
  import('../datetime').then(({ DateTimePicker }) => ({ default: DateTimePicker })),
);

const DateRangePicker = props => (
  <LazyLoader
    fallback={<Input placeholder="Select Date Range" {...props} loading readOnly />}
    Lazy={LazyDateRangeTimePicker}
    {...props}
  />
);

const DateTimePicker = props => (
  <LazyLoader
    fallback={<Input placeholder="Loading..." {...props} loading readOnly />}
    Lazy={LazyDateTimePicker}
    {...props}
  />
);

export { DateRangePicker, DateTimePicker };
