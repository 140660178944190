import { isArray, isPlainObject } from 'lodash';

export const copyInstance = (original: object) =>
  Object.assign(Object.create(Object.getPrototypeOf(original)), original);

export const convertBytesToGigaBytes = (size: number) => {
  const sizeInGigaBytes = size / 1024 / 1024 / 1024;
  const shortened = sizeInGigaBytes.toFixed(2);

  return parseFloat(shortened);
};

export const convertToArray = value => (isArray(value) ? value : [value]);

export const roundToFixedFractionDigits = (numberToRound: number, digitsToRoundBy: number) =>
  Number.parseFloat(numberToRound.toFixed(digitsToRoundBy));

export const hasDuplicates = array => new Set(array).size !== array.length; // Source: https://stackoverflow.com/a/7376645

/**
 * https://github.com/lodash/lodash/issues/2240
 *
 * This function converts a nested object to a `.` delimiter path array.
 * e.g - {
 *   key: {
 *     subKey: 2
 *   }
 * }
 *  Will output --> ['key.subKey']
 */
export const flattenKeys = function (obj, prefix = '') {
  const entries = Object.entries(obj);

  return entries.reduce((result, [key, value]) => {
    if (isPlainObject(value) || Array.isArray(value)) {
      return [...result, ...flattenKeys(value, prefix ? `${prefix}.${key}` : key)];
    }

    result.push(prefix ? `${prefix}.${key}` : key);

    return result;
  }, []);
};
