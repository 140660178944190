import moment from 'moment';
import { QuickOption } from '../types';

export const defaultQuickOptions: QuickOption[] = [
  {
    id: 'last-15m',
    label: 'Last 15m',
    extendedLabel: 'Last 15 minutes',
    start: 'now-15m',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-30m',
    label: 'Last 30m',
    extendedLabel: 'Last 30 minutes',
    start: 'now-30m',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-1h',
    label: 'Last 1h',
    extendedLabel: 'Last 1 hour',
    start: 'now-1h',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-3h',
    label: 'Last 3h',
    extendedLabel: 'Last 3 hours',
    start: 'now-3h',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-6h',
    label: 'Last 6h',
    extendedLabel: 'Last 6 hours',
    start: 'now-6h',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-12h',
    label: 'Last 12h',
    extendedLabel: 'Last 12 hours',
    start: 'now-12h',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-24h',
    label: 'Last 24h',
    extendedLabel: 'Last 24 hours',
    start: 'now-24h',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-48h',
    label: 'Last 48h',
    extendedLabel: 'Last 48 hours',
    start: 'now-48h',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'last-1w',
    label: 'Last 7 days',
    start: 'now-1w',
    end: 'now',
    type: 'relative',
  },
  {
    id: 'today',
    label: 'Today',
    type: 'absolute',
    start: () => moment().startOf('day').valueOf(),
    end: () => moment().endOf('day').valueOf(),
  },
  {
    id: 'yesterday',
    label: 'Yesterday',
    type: 'absolute',
    start: () => moment().subtract(1, 'day').startOf('day').valueOf(),
    end: () => moment().subtract(1, 'day').endOf('day').valueOf(),
  },
  {
    id: 'this-week',
    label: 'This week',
    type: 'absolute',
    start: () => moment().startOf('week').valueOf(),
    end: () => moment().endOf('week').valueOf(),
  },
  {
    id: 'last-week',
    label: 'Last week',
    type: 'absolute',
    start: () => moment().subtract(1, 'week').startOf('week').valueOf(),
    end: () => moment().subtract(1, 'week').endOf('week').valueOf(),
  },
  {
    id: 'this-month',
    label: 'This month',
    type: 'absolute',
    start: () => moment().startOf('month').valueOf(),
    end: () => moment().endOf('month').valueOf(),
  },
  {
    id: 'last-month',
    label: 'Last month',
    type: 'absolute',
    start: () => moment().subtract(1, 'month').startOf('month').valueOf(),
    end: () => moment().subtract(1, 'month').endOf('month').valueOf(),
  },
];

export const getQuickOptions = (values?: QuickOption['label'][]) => {
  if (!values?.length) return [...defaultQuickOptions.map(option => ({ ...option }))];

  return defaultQuickOptions.filter(option => values.includes(option.id));
};
