import { colors } from '../colors';
import { opacity } from '../../utils';

export const primary = {
  styles: {
    background: colors.blue[500],
    color: colors.gray[0],
  },
  active: {
    background: colors.blue[700],
  },
  hover: {
    background: opacity(colors.blue[500], 70),
  },
};

export const secondary = {
  styles: {
    background: opacity(colors.blue[500], 10),
    color: colors.blue[500],
  },
  active: {
    background: opacity(colors.blue[500], 40),
  },
  hover: {
    background: opacity(colors.blue[500], 20),
  },
};

export const text = {
  styles: {
    background: 'transparent',
    color: colors.blue[500],
  },
  active: {
    background: opacity(colors.blue[500], 40),
  },
  hover: {
    background: opacity(colors.blue[500], 10),
  },
};

export const accent = {
  styles: {
    background: colors.orange[500],
    color: colors.gray[0],
  },
  active: {
    background: colors.orange[600],
  },
  hover: {
    background: opacity(colors.orange[500], 70),
  },

  overrides: {
    textShadow: colors.orange[600],
  },
};

export const accentSecondary = {
  styles: {
    background: opacity(colors.orange[500], 10),
    color: colors.orange[500],
  },
  active: {
    background: opacity(colors.orange[500], 40),
  },
  hover: {
    background: opacity(colors.orange[500], 20),
    color: colors.orange[600],
  },
};

export const danger = {
  styles: {
    background: colors.red[500],
    color: colors.gray[0],
  },
  active: {
    background: colors.red[600],
    color: colors.gray[0],
  },
  hover: {
    background: opacity(colors.red[500], 70),
    color: colors.gray[0],
  },

  overrides: {
    textShadow: colors.red[500],
  },
};

export const dangerSecondary = {
  styles: {
    background: opacity(colors.red[500], 10),
    color: colors.red[600],
  },
  active: {
    background: opacity(colors.red[500], 40),
    color: colors.red[600],
  },
  hover: {
    background: opacity(colors.red[500], 20),
    color: colors.red[600],
  },
};

export const symbol = {
  styles: {
    background: 'transparent',
    color: colors.gray[800],
  },

  hover: {
    background: opacity(colors.blue[500], 10),
    color: colors.blue[500],
  },
};

export const symbolSecondary = {
  styles: {
    background: 'transparent',
    color: colors.gray[800],
  },
  active: {
    color: colors.blue[700],
  },
  hover: {
    background: 'transparent',
    color: colors.blue[500],
  },
};

export const symbolTernary = {
  styles: {
    background: 'transparent',
    color: opacity(colors.gray[900], 40),
  },

  hover: {
    background: 'transparent',
    color: opacity(colors.gray[900], 70),
  },
};

export const link = {
  styles: {
    padding: 0,
    background: 'transparent',
    color: colors.blue[500],
    marginLeft: 0,
  },

  hover: {
    background: 'transparent',
    color: colors.blue[400],
  },

  active: {
    background: 'transparent',
    color: colors.blue[800],
  },
};

export const disabled = {
  background: colors.gray[300],
  color: opacity(colors.gray[800], 40),
};

export const buttons = {
  padding: '0 12px',
  height: '30px',

  primary,
  secondary,
  text,
  accent,
  danger,
  dangerSecondary,
  symbol,
  symbolSecondary,
  symbolTernary,
  disabled,
  link,
};
