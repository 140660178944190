import React, { FunctionComponent, useEffect } from 'react';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { ITabProps } from './interfaces';
import { StyledTabHelper, StyledTab, StyledTabContainer } from './Tabs.styles';

export const Tab: FunctionComponent<ITabProps> = React.forwardRef<HTMLDivElement, ITabProps>(
  (
    {
      subject,
      width,
      disabled = false,
      selected = false,
      size,
      helper,
      onClick,
      value,
      label,
      onHelperChange,
      ...props
    },
    ref,
  ) => {
    const handleClick = event => {
      if (!disabled) {
        onClick(event, value);
      }
    };

    const tabHelper =
      typeof helper === 'string' ? (
        <StyledTabHelper disabled={disabled} selected={selected} size={size}>
          {helper}
        </StyledTabHelper>
      ) : (
        helper
      );

    useEffect(() => {
      onHelperChange?.();
    }, [helper]);

    const noPadding = React.isValidElement(label);

    return (
      <StyledTabContainer
        ref={ref}
        onClick={handleClick}
        width={width}
        disabled={disabled}
        {...props}
        {...generateLogzTestAttributes({
          context: `tab.${value}`,
          value,
          subject,
          label: label ?? value,
          selected,
          disabled,
        })}
      >
        <StyledTab
          noPadding={noPadding}
          size={size}
          selected={selected}
          disabled={disabled}
          aria-selected={selected}
          role={'tab'}
        >
          {label}
          {tabHelper}
        </StyledTab>
      </StyledTabContainer>
    );
  },
);

Tab.displayName = 'Tab';
