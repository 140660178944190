import { darkReaderConfig } from './dark-reader.config';

const manipulatorClass = {
  noImageAnalysis: 'ignore-dark-reader',
  ignore: 'ignore-dark-reader',
  invertStyles: 'invert-dark-reader',
};

const toCssClassSelector = className => `.${className}`;
const themeFixes: DarkReader.DynamicThemeFix = {
  ignoreImageAnalysis: [toCssClassSelector(manipulatorClass.noImageAnalysis)],
  invert: [toCssClassSelector(manipulatorClass.invertStyles)],
  css: `
    td div {
      color: white;
    }
  `,
  ignoreInlineStyle: [
    '.dark-reader-ignore .highcharts-grid-line',
    '.dark-reader-ignore .highcharts-axis-line',
    '.dark-reader-ignore .highcharts-point',
    '.ignore-dark-reader',
  ],
};

const enable = async () => {
  const { enable: enableDarkReader, setFetchMethod: setDarkReaderFetchMode } = await import('darkreader');

  setDarkReaderFetchMode(window.fetch);
  enableDarkReader(darkReaderConfig, themeFixes);
};

const disable = async () => {
  const { disable: disableDarkReader } = await import('darkreader');

  disableDarkReader();
};

export const darkModeService = {
  enable,
  disable,
  manipulatorClass,
};
