export enum Kube360Subjects {
  Kube360Page = 'kube360-page',
  SideFilter = 'kube360-side-filter',
  Kube360Footer = 'kube360-footer',
  CheckMapView = 'check-map-view',
  CheckTableView = 'check-table-view',
  SwitchViewMode = 'switch-view-mode',
  FilterContainer = 'filter-container',
  GroupPodsByTypeSelector = 'group-pods-by-type-selector',
  SwitchMetric = 'switch-metric',
  LegendMetricTypePrefix = 'legend-metric-type-',
  LegendNumberPrefix = 'legend-number-',
  LoadingKubeMapCard = 'loading-kube-map-card',
  EmptyStateTitle = 'empty-state-title',
  EmptyStateText = 'empty-state-text',
  KubeMapCard = 'kube-map-card',
  QuickView = 'kube360-quickview',
  CloseQuickView = 'kube360-closeQuickView',
  QuickViewTitle = 'kube360-quickview-title',
  QuickViewTitleType = 'kube360-quickview-title-type',
  OverviewCardRow = 'overview-card-row',
  OverviewCardRowLabel = 'overview-card-row-label',
  QuickViewLogsEmptyState = 'quick-view-logs-empty-state',
  QuickViewSpansEmptyState = 'quick-view-spans-empty-state',
  PodsTableEmptyState = 'pods-table-empty-state',
  PodsTabTable = 'pods-tab-table',
  PodsTable = 'pods-table',
  TracesTable = 'traces-table',
  IItemTable = 'item-table',
}
