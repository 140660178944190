import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Divider, Flex, Grid, Group, Info } from '..';
import { getStepStatus, StepBadge, StepTitle } from './stepComponents';

export interface IStepperProps {
  currentStep?: number;
  size?: 'm' | 'l';
  children: React.ReactNode;
}

export interface IStepProps {
  stepNumber?: number;
  title: string;
  hint?: JSX.Element | string;
  numberOfSteps?: number;
  currentStep?: number;
  size?: IStepperProps['size'];
  children?: React.ReactNode;
}

const StyledCell = styled(props => <Grid.Cell {...props} />)`
  display: flex;
  justify-content: center;
`;

export const StepperStep: FunctionComponent<IStepProps> = ({
  title,
  stepNumber,
  hint,
  numberOfSteps,
  children,
  currentStep,
  size,
}) => {
  const stepStatus = getStepStatus({ stepNumber, currentStep });
  const showDivider = stepNumber < numberOfSteps || children;

  return (
    <Flex subject={`step-${stepNumber}-${stepStatus}`}>
      <Grid columns={12} minWidth={'100%'} rowGap={0} cellGap={4}>
        <StyledCell span={2}>
          <StepBadge stepStatus={stepStatus} stepNumber={stepNumber} size={size} />
        </StyledCell>
        <StyledCell span={10}>
          <Group alignItems={'center'} width={'100%'}>
            <StepTitle stepStatus={stepStatus} title={title} size={size} />
            {hint && (typeof hint === 'string' ? <Info iconSize={14} placement={'right'} tooltip={hint} /> : hint)}
          </Group>
        </StyledCell>
        <StyledCell span={2}>{showDivider && <Divider vertical color={'royalBlue.800'} size={'m'} />}</StyledCell>
        {showDivider && (
          <Grid.Cell span={10} mb={4} mt={2}>
            {children}
          </Grid.Cell>
        )}
      </Grid>
    </Flex>
  );
};

export const Stepper = ({ currentStep, size = 'm', children }: IStepperProps) => {
  const numberOfSteps = React.Children.count(children);

  const numberedSteps = React.Children.map(children, (child, index) => {
    if (!child) return null;

    return React.cloneElement(child as React.ReactElement, {
      stepNumber: index + 1,
      currentStep,
      numberOfSteps,
      size,
    });
  });

  return (
    <Group vertical gap={0}>
      {numberedSteps}
    </Group>
  );
};
