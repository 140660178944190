export enum AdminThreatsTestSubjects {
  UpdatedFeedSuccess = 'UpdatedFeedSuccess',
  CreateFeedSuccess = 'CreateFeedSuccess',
  DeleteFeedSuccess = 'DeleteFeedSuccess',
  SyncFeedSuccess = 'SyncFeedSuccess',
}

export enum AdminPipelinesTestSubjects {
  PipelinesPage = 'feeds-pipelines-page',
  PipelinesTable = 'feeds-pipelines',
}

export enum SiemAdminTabs {
  Feeds = 'admin.siem.feeds',
  Pipelines = 'admin.siem.pipelines',
  Content = 'admin.siem.content',
}

export enum AdminPage {
  Users = 'USERS',
  Insights = 'INSIGHTS',
  Rates = 'RATES',
  Notifications = 'NOTIFICATIONS',
  Sawmill = 'SAWMILL',
  Metrics = 'METRICS',
  Siem = 'SIEM',
}
