import React from 'react';
import styled from 'styled-components';
import { Divider } from '../../Divider/Divider.component';
import { Flex } from '../../containers/Flex/Flex.component';
import { Icon } from '../../icons/Icon.component';
import { Text } from '../../typography';
import { popMenuLightTheme } from '../themes/light.theme';
import { IPopMenuProps } from '../PopMenu.component';
import { ButtonsConfig } from '../types';
import { ButtonBar } from './ButtonBar';

interface IPopMenuPanelProps {
  closePopover: () => void;
  title: IPopMenuProps['subTitle'];
  menuComponent: IPopMenuProps['menuComponent'];
  buttonsConfig: ButtonsConfig;
  width?: IPopMenuProps['width'];
  height?: IPopMenuProps['height'];
}

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export const PopMenuPanel: React.FC<IPopMenuPanelProps> = ({
  closePopover,
  title,
  menuComponent,
  buttonsConfig,
  width,
  height,
}) => {
  const { panel: panelTheme } = popMenuLightTheme;

  return (
    <Flex flexDirection={'column'} width={width || 440} height={height || 290}>
      <Flex pt={4} pb={2} px={4} width={'100%'} justifyContent="space-between" alignItems="center">
        <Text size={14} weight={600} color={'royalBlue.1000'}>
          {title}
        </Text>
        <StyledIcon icon={'xmark-regular'} onClick={closePopover} />
      </Flex>
      {menuComponent}
      <Divider color={panelTheme.dividerColor} size={'m'} />
      <ButtonBar buttonsConfig={buttonsConfig} closePopover={closePopover} />
    </Flex>
  );
};
