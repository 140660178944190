/* eslint-disable max-lines */
import styled from 'styled-components';
import { Group } from '@logz-ui/styleguide/components/containers/Group/Group.component';
import { DateCustomization } from '../types';
import { getDateTooltipCss, getCustomizedDatesCss } from './utils';

export const CalendarStylesWrapper = styled(Group)<{ dateCustomizations?: DateCustomization[] }>`
  .react-datepicker {
    display: flex;
    width: 100%;
    border: none;
    font-family: ${({ theme }) => theme.fonts.base};
    background-color: transparent;
    border-radius: 0;

    .react-datepicker__header {
      background-color: transparent;
      border: none;
    }

    .react-datepicker__time-container {
      margin: 0;
      width: 100px;
      border-left: 1px solid ${({ theme }) => theme.datePicker.border};
      background-color: ${({ theme }) => theme.datePicker.time.background};
    }

    .react-datepicker__month-container {
      float: none;
      border-right: 1px solid ${({ theme }) => theme.datePicker.border};
      padding-right: 16px;
      margin: 0;
    }

    .react-datepicker__day-names {
      display: none;
    }

    .react-datepicker__time,
    .react-datepicker__header--time {
      background-color: ${({ theme }) => theme.datePicker.time.background};
      border-radius: 0;
    }

    .react-datepicker-time__header {
      color: ${({ theme }) => theme.datePicker.time.color};
      font-family: ${({ theme }) => theme.fonts.title};
      font-size: 12px;
      font-weight: 600;
    }
    .react-datepicker__today-button {
      position: absolute;
      color: ${({ theme }) => theme.datePicker.absolute.today.color};
      border: 1px solid ${({ theme }) => theme.datePicker.absolute.today.borderColor};
      background-color: ${({ theme }) => theme.datePicker.absolute.today.backgroundColor};
      right: 120px;
      top: 3px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 3px;
      padding: 3px 8px;
    }
    .react-datepicker__time-container--with-today-button {
      display: inline;
      border-radius: 0.3rem;
      position: unset;
      border: none;
      right: -87px;
      top: 0;
    }
    .react-datepicker__header--time {
      padding: 8px 0;
      justify-content: center;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 100%;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__time-list {
      height: 192px !important; // React-datepicker sets this height via "style" prop in html
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item {
      margin: 0 8px;
      padding: 6px 8px;
      height: auto;
      text-align: center;
      font-size: 12px;
      line-height: 1;
      color: ${({ theme }) => theme.datePicker.time.color};
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item:hover,
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      border-radius: 3px;
      font-weight: 500;
      color: ${({ theme }) => theme.datePicker.time.selectedItemColor};
      background-color: ${({ theme }) => theme.datePicker.time.selectedItemBackground};
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      margin: 0;
      width: 48px;
      height: 36px;
      line-height: 36px;
    }

    .react-datepicker__day--keyboard-selected {
      background: none;
    }

    .react-datepicker__day {
      position: relative;
      font-size: 14px;
      color: ${({ theme }) => theme.datePicker.day.color};
      border-radius: 0;
    }

    .react-datepicker__day--today {
      font-weight: 400;
      background: linear-gradient(
          to bottom,
          ${({ theme }) => theme.datePicker.day.todayBackground} 0%,
          ${({ theme }) => theme.datePicker.day.todayBackground} 10%,
          transparent 10%,
          transparent 90%,
          ${({ theme }) => theme.datePicker.day.todayBackground} 90%,
          ${({ theme }) => theme.datePicker.day.todayBackground} 100%
        ),
        linear-gradient(
          to right,
          ${({ theme }) => theme.datePicker.day.todayBackground} 0%,
          ${({ theme }) => theme.datePicker.day.todayBackground} 7%,
          transparent 7%,
          transparent 93%,
          ${({ theme }) => theme.datePicker.day.todayBackground} 93%,
          ${({ theme }) => theme.datePicker.day.todayBackground} 100%
        );
      border-radius: 4px;
    }

    .react-datepicker__day:hover:not(.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range),
    .react-datepicker__day--selected {
      border-radius: 8px;
      background: ${({ theme }) => theme.datePicker.day.selectedBackground};
    }

    .react-datepicker__day--outside-month,
    .react-datepicker__day--disabled {
      color: ${({ theme }) => theme.datePicker.day.colorOutsideMonth};
    }

    .react-datepicker__day--in-selecting-range {
      background: ${({ theme }) => theme.datePicker.day.inSelectingRangeBackground};
      border-radius: 0;
    }

    .react-datepicker__day--in-range {
      color: ${({ theme }) => theme.datePicker.day.colorInRange};
      font-weight: 400;
      background: ${({ theme }) => theme.datePicker.day.inRangeBackground};
    }

    .react-datepicker__day--selecting-range-end {
      border-radius: 0;
    }

    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
      font-weight: 700;
      color: ${({ theme }) => theme.datePicker.day.colorStartEndRange};
      background: ${({ theme }) => theme.datePicker.day.rangeStartEndBackground};
    }

    .react-datepicker__day--selecting-range-end:hover,
    .react-datepicker__day--range-end {
      border-radius: 0 8px 8px 0;
    }

    .react-datepicker__day--selecting-range-start:hover,
    .react-datepicker__day--range-start {
      border-radius: 8px 0 0 8px;
    }

    .react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end,
    .react-datepicker__day--range-start.react-datepicker__day--range-end {
      border-radius: 8px;
    }

    .react-datepicker__month--selecting-range
      .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range, :hover) {
      // This selector was copied as is from react-datepicker, we need to override it :/
      background-color: transparent;
      color: ${({ theme }) => theme.datePicker.day.color};
    }

    .react-datepicker__current-month {
      padding: 0 62px;
      width: 120px;
      box-sizing: initial;
      font-weight: 400;
      text-align: center;
      font-size: 14px;
      color: ${({ theme }) => theme.datePicker.navigation.currentMonthColor};
    }

    .react-datepicker__navigation {
      margin: 0 31px;
      &:hover {
        background: none;
      }
    }

    // adjust color to work with dark reader
    .react-datepicker__navigation-icon::before {
      [data-logz-test-theme='dark'] & {
        border-color: #000000;
      }
    }

    .react-datepicker__navigation--next {
      top: 0;
      left: 152px;
      right: auto;
    }

    .react-datepicker__navigation--previous {
      top: 0;
      left: 0;
      right: auto;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border-color: ${({ theme }) => theme.datePicker.navigation.iconColor};
      border-width: 1px 1px 0 0;
      height: 6px;
      width: 6px;
      top: 9px;
    }

    .react-datepicker__day.react-datepicker__day--disabled,
    .react-datepicker__day.react-datepicker__day--disabled:hover {
      cursor: not-allowed;
      background-color: transparent;
      color: ${({ theme }) => theme.datePicker.day.colorOutsideMonth};
    }

    .react-datepicker__day--disabled {
      ${getDateTooltipCss('Unavailable')}
    }

    ${({ dateCustomizations }) => dateCustomizations && getCustomizedDatesCss(dateCustomizations)}
  }
`;
