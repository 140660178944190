import React from 'react';
import { ISort } from '@logz-build/typescript';
import { SortDirection } from '@logz-pkg/enums';
import { Table } from '../Table';
import { TableHeaderCell } from './HeaderCell';
import { CrudTableHeaderActions } from './Record/ActionsCell';
import { CrudTableTypes, IBulkHeaderProps, ICrudOptions } from './Crud.types';

interface ICrudTableHeaderProps {
  bulk?: IBulkHeaderProps;
  columns: CrudTableTypes.IColumn[];
  onSort(sort: ISort[]): void;
  sort: ISort[];
  maxSortedColumns?: number;
  defaultSortingDirection?: SortDirection;
  tableOptions: ICrudOptions;
}

export const CrudTableHeader: React.FunctionComponent<ICrudTableHeaderProps> = ({
  bulk,
  columns,
  onSort,
  sort,
  maxSortedColumns = 1,
  defaultSortingDirection = SortDirection.Descending,
  tableOptions,
}) => {
  let isChecked;

  if (bulk) {
    const isPageSelected = bulk.dataSourceLength && bulk.selectedLength === bulk.dataSourceLength;

    isChecked = bulk.isAllPagesSelected || isPageSelected;
  }

  const handleSort = (field: CrudTableTypes.IColumn['recordPropertyName']) => {
    const newSortArray = [...sort];
    const sortIndex = newSortArray.findIndex(item => item.field === field);

    if (sortIndex >= 0) {
      newSortArray[sortIndex].descending = !newSortArray[sortIndex].descending;
      newSortArray.push(...newSortArray.splice(sortIndex, 1));
    } else {
      newSortArray.length >= maxSortedColumns && newSortArray.shift();
      newSortArray.push({ field, descending: defaultSortingDirection === SortDirection.Descending });
    }

    onSort(newSortArray);
  };
  const headerCells = columns.map(column => {
    const columnSort = sort && sort.find(item => item.field === column.recordPropertyName);

    const sortDirection =
      (columnSort && (columnSort.descending ? SortDirection.Descending : SortDirection.Ascending)) || null;

    return (
      <TableHeaderCell
        column={column}
        sortDirection={sortDirection}
        defaultSortingDirection={defaultSortingDirection}
        onSort={() => handleSort(column.recordPropertyName)}
        key={column.key}
      >
        {column.title}
      </TableHeaderCell>
    );
  });

  return (
    <Table.Header
      {...(bulk
        ? {
            bulk: {
              indeterminate: bulk.selectedLength > 0,
              checked: isChecked,
              onChange: bulk.onChange,
            },
          }
        : {})}
      tableOptions={tableOptions}
    >
      {headerCells}
      {tableOptions?.actions?.hide ? <></> : <CrudTableHeaderActions tableOptions={tableOptions} />}
    </Table.Header>
  );
};
