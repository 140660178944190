import styled, { css } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { IFlexProps, InlineFlex } from '../../containers/Flex/Flex.component';
import { MenuItem } from './MenuItem.component';
import { MenuItemDivider } from './MenuItemDivider.component';
import { SubMenu } from './SubMenu.component';
import { MenuTitle } from './MenuTitle.component';

const PrimaryMenu = styled(InlineFlex).attrs<IFlexProps>(({ subject }) => ({
  p: 0,
  py: 1,
  'aria-label': subject ? `Menu for ${subject}` : 'Menu',
  ...generateLogzTestAttributes({ context: 'menu', subject }),
}))`
  min-width: 160px;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  border-radius: 5px;
  flex-direction: column;
  background: ${({ theme }) => theme.menu.background};
  box-shadow: ${({ theme }) => theme.shadows.large};
  border: 1px solid ${({ theme }) => theme.menu.borderColor};
`;

type MenuType = typeof PrimaryMenu & {
  Item: typeof MenuItem;
  Divider: typeof MenuItemDivider;
  SubMenu: typeof SubMenu;
  Title: typeof MenuTitle;
};

export const Menu = PrimaryMenu as MenuType;

Menu.displayName = 'Menu';
