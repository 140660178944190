export enum LogShippingTokenFields {
  CreatedAt = 'createdAt',
  Token = 'token',
  Enabled = 'enabled',
  Name = 'name',
}

export enum LogShippingTokensSubject {
  Table = 'log-shipping-tokens-table',
  Toggle = 'log-shipping-tokens-toggle-',
  Created = 'log-shipping-token-created',
  Removed = 'log-shipping-token-removed',
  Updated = 'log-shipping-token-updated',
}

export enum DataShippingTokensSubject {
  ProductSelector = 'products-selector',
  LoadingView = 'data-shipping-loading-view',
  RegionCode = 'region-code',
}

export enum MetricsShippingTokensSubject {
  Table = 'metrics-shipping-tokens-table',
}

export enum TracingShippingTokensSubject {
  Table = 'tracing-shipping-tokens-table',
}
