type Listener<T> = (val: T) => void;
type Unsubscriber = () => void;

// Based on https://blog.betomorrow.com/replacing-redux-with-observables-and-react-hooks-acdbbaf5ba80
export class Observable<T> {
  private _listeners: Listener<T>[] = [];
  private _value: T | undefined;

  constructor(initialValue?: T) {
    this._value = initialValue;
  }

  get = (): T | undefined => this._value;

  set = (value: T): void => {
    if (this._value !== value) {
      this._value = value;
      this._listeners.forEach(listener => listener(value));
    }
  };

  subscribe = (listener: Listener<T>): Unsubscriber => {
    this._listeners.push(listener);

    return () => {
      this._listeners = this._listeners.filter(_listener => _listener !== listener);
    };
  };
}
