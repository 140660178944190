import merge from 'deepmerge';
import { BaseTheme } from '../base';
import { colors } from '../colors';
import { selectDarkTheme } from '../../inputs/Select/themes/dark.theme';
import menu from './menu';
import header from './header';
import { input } from './input';

export const DarkTheme = merge(BaseTheme, {
  name: 'dark',

  colors: {
    ...colors,
    background: colors.gray[900],
    text: {
      base: colors.gray[500],
    },
    semantic: {
      accent: colors.yellow[600],
    },
  },
  menu,
  header,
  select: selectDarkTheme,
  input,
});
