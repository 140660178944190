import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { SingleTag } from '../types';

export const useEditMode = (tag: SingleTag) => {
  const [editMode, setEditMode] = useState(false);

  const debouncedEditMode = useMemo(
    () => debounce(steps => setEditMode(steps.some(step => step.selected)), 150),
    [setEditMode],
  );

  useEffect(() => {
    debouncedEditMode(tag.steps);
  }, [tag]);

  return { editMode };
};
