import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { isNil } from 'lodash';
import React, { useImperativeHandle, useRef } from 'react';
import styled, { css } from 'styled-components';
import { colors, colorsV2 } from '../../../themes';

const maintainFlexGrowForLastCell = css<{ hasActions?: boolean }>`
  ${({ hasActions }) =>
    hasActions
      ? css`
          td:nth-last-child(2) {
            flex-grow: 1;
            flex-shrink: 0;
          }
        `
      : css`
          td:last-child {
            flex-grow: 1;
            flex-shrink: 0;
          }
        `}
`;

const StyledRow = styled.tr<{
  sticky?: boolean;
  padLastChild?: number;
  hasActions?: boolean;
  onClick?: () => void;
  disableHover?: boolean;
  backgroundColor?: string;
}>`
  display: flex;
  width: 100%;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};

  ${maintainFlexGrowForLastCell}

  position: relative;
  --hovering-row: 0;

  & {
    .hovered-component {
      z-index: 1;
      opacity: 0;
      display: flex;
      align-items: center;
      position: sticky;
      inset-inline-end: 0px;
      padding: 0;
      justify-content: flex-end;
    }
    .hovered.hovered-component {
      opacity: 1;
    }
  }

  ${({ disableHover }) =>
    !disableHover &&
    css`
      :hover {
        --hovering-row: 1;
        background-color: ${colorsV2.indigo[100]};
      }
    `}

  ${({ sticky, backgroundColor }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 2;
      :hover {
        background-color: ${backgroundColor};
      }
    `}
  background-color:${({ backgroundColor }) => backgroundColor};
`;

interface IRowProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  sticky?: boolean;
  actions?: React.ReactElement;
  subject?: string;
  disableHover?: boolean;
  backgroundColor?: string;
  onClick?: () => void;
}

export const Row = React.forwardRef<HTMLTableRowElement, IRowProps>(
  ({ children, actions, subject, onClick, disableHover, backgroundColor = colors.gray[0], ...props }, ref) => {
    const innerRef = useRef<HTMLTableRowElement>(null);

    useImperativeHandle(ref, () => innerRef.current);

    return (
      <StyledRow
        hasActions={!isNil(actions)}
        backgroundColor={backgroundColor}
        ref={innerRef}
        {...generateLogzTestAttributes({
          context: 'table-row',
          subject,
        })}
        onClick={onClick}
        disableHover={disableHover}
        onMouseEnter={() => {
          const hoveredComponent = innerRef.current.querySelector('.hovered-component');

          if (hoveredComponent) {
            hoveredComponent.classList.add('hovered');
          }
        }}
        onMouseLeave={() => {
          const hoveredComponent = innerRef.current.querySelector('.hovered-component');

          if (hoveredComponent) {
            hoveredComponent.classList.remove('hovered');
          }
        }}
        {...props}
      >
        {children}
        {actions}
      </StyledRow>
    );
  },
);

Row.displayName = 'Row';
