import React, { createContext, PropsWithChildren, useCallback, useContext, useState } from 'react';

interface IActionItemPopoverContextProps {
  position: { x: number; y: number } | null;
  popperContentElement: React.ReactNode | null;
  open: (position: { x: number; y: number }, content: React.ReactNode) => void;
  close: () => void;
}

const ActionItemPopoverContext = createContext<IActionItemPopoverContextProps | undefined>(undefined);

export const ActionItemPopoverProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [popperContentElement, setPopperContentElement] = useState<React.ReactNode | null>(null);

  const open = useCallback(
    (pos: { x: number; y: number }, content: React.ReactNode) => {
      setPosition(pos);
      setPopperContentElement(content);
    },
    [setPosition, setPopperContentElement],
  );

  const close = useCallback(() => {
    setPosition({ x: 0, y: 0 });
    setPopperContentElement(null);
  }, [setPosition, setPopperContentElement]);

  return (
    <ActionItemPopoverContext.Provider value={{ position, popperContentElement, open, close }}>
      {children}
    </ActionItemPopoverContext.Provider>
  );
};

export const useActionItemPopover = () => {
  const context = useContext(ActionItemPopoverContext);

  if (!context) {
    throw new Error('useActionItemPopover must be used within an ActionItemPopoverProvider');
  }

  return context;
};
