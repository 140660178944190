import { notificationApiService } from './notification-api.service';
import { INotificationOptions } from './Notification.types';

const success = (options: INotificationOptions) => {
  notificationApiService.open({ ...options, type: 'success' });
};

const info = (options: INotificationOptions) => {
  notificationApiService.open({ ...options, type: 'info' });
};

const danger = (options: INotificationOptions) => {
  notificationApiService.open({ ...options, type: 'danger' });
};

const warning = (options: INotificationOptions) => {
  notificationApiService.open({ ...options, type: 'warning' });
};

const destroy = () => notificationApiService.destroy();

const unexpectedError = (title: string, options: INotificationOptions = {}) =>
  danger({
    title,
    message: (
      <>
        Please try one more time, and contact the <a href="mailto:help@logz.io">Support team</a> if this happens again.
      </>
    ),
    ...options,
  });

const inProgress = (options: INotificationOptions) => {
  notificationApiService.open({ ...options, type: 'progress' });
};

export const NotificationService = {
  success,
  info,
  danger,
  warning,
  inProgress,
  destroy,
  unexpectedError,
  closeByKey: notificationApiService.closeByKey,
};
