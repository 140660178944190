import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export interface IMultTagContainer {
  maxHeight?: React.CSSProperties['maxHeight'];
  maxWidth?: React.CSSProperties['maxWidth'];
  containerRef?: React.Ref<HTMLDivElement>;
}

const StyledDiv = styled.div<IMultTagContainer>`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  width: 100%;
`;

export const MultiTagContainer: React.FC<PropsWithChildren<IMultTagContainer>> = ({
  children,
  maxHeight,
  maxWidth,
  containerRef,
}) => {
  return (
    <StyledDiv ref={containerRef} style={{ maxHeight, maxWidth }}>
      {children}
    </StyledDiv>
  );
};
