import { isNil } from 'lodash';

function isPrimitive(test) {
  return test !== Object(test);
}

export const plainToFlattenObject = (object: Record<string, any>, ignoreArrays = false): Record<string, string> => {
  const result = {};

  function flatten(obj: Record<string, any>, prefix = '') {
    Object.entries(obj).forEach(([key, value]) => {
      if (!isNil(value) && Array.isArray(value)) {
        let isHomogeneousArray = true;

        value.forEach(item => {
          if (!isPrimitive(item)) {
            isHomogeneousArray = false;
          }
        });

        if (isHomogeneousArray) {
          result[`${prefix}${key}`] = value;
        } else {
          flatten(value, `${prefix}${key}.`);
        }
      } else if (!isNil(value) && typeof value === 'object') {
        flatten(value, `${prefix}${key}.`);
      } else {
        let formattedKey = `${prefix}${key}`;

        if (ignoreArrays) {
          const isArrayRegex = /\.\d+$/;

          formattedKey = formattedKey.replace(isArrayRegex, '');
        }

        result[formattedKey] = value;
      }
    });
  }

  flatten(object);

  return result;
};
