import React, { useMemo } from 'react';
import styled from 'styled-components';
import { HighlightedText } from '../../HighlightedText/HighlightedText.component';
import { lightV2Theme } from '../../themes';
import { Button } from '../../Button/Button.component';
import { Group } from '../../containers/Group/Group.component';
import { Text } from '../../typography';
import { filterUtils } from '../utils/utils';
import type { SelectedOption } from '../types';
import { IFilterComponentProps } from '../Filter.component';
import { Icon } from '../../icons/Icon.component';
import { FILTER_CUSTOM_VALUE_TYPE } from '../constants';

const StyledGroup = styled(Group)`
  height: 100%;
  .filter-button {
    display: none;
  }

  &:hover {
    .filter-button {
      display: block;
    }
    .filter-desc {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.multiFilter.excludeButtonColor};
`;

interface ISingleOption {
  option: IFilterComponentProps['options']['results'][number];
  value: IFilterComponentProps['value'];
  onChange: IFilterComponentProps['onChange'];
  enableExclude: IFilterComponentProps['enableExclude'];
  reportCb?: IFilterComponentProps['reportCb'];
  searchFilter: string;
  appTheme: typeof lightV2Theme;
}

export const SingleOption: React.FC<ISingleOption> = ({
  option,
  searchFilter,
  appTheme,
  value,
  onChange,
  reportCb,
  enableExclude,
}) => {
  const valueInOptions = useMemo(() => value.find(v => filterUtils.areTheyEqual(v, option)), [value]);
  const isCustomValue = option.type === FILTER_CUSTOM_VALUE_TYPE;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    const updatedValue = [...value];
    const existingValueIndex = updatedValue.findIndex(v => filterUtils.areTheyEqual(v, option));
    const newState = !valueInOptions || valueInOptions.state === 'ENABLED' ? 'DISABLED' : 'ENABLED';

    reportCb?.(newState === 'ENABLED' ? 'Check option' : 'Exclude option');

    if (existingValueIndex === -1) {
      const newValue: SelectedOption = {
        id: option.id,
        type: option.type,
        state: newState,
        isDisabled: false,
      };

      onChange([...updatedValue, newValue]);
    } else {
      updatedValue[existingValueIndex] = {
        ...updatedValue[existingValueIndex],
        state: newState,
      };
      onChange(updatedValue);
    }
  };

  return (
    <StyledGroup alignItems="center" justifyContent="space-between" width={'100%'}>
      <Group alignItems="center" justifyContent="center" gap={1}>
        {isCustomValue && <Icon icon="plus-regular" size={10} color={'blue.600'} />}
        <span style={{ wordBreak: 'break-word' }}>
          <HighlightedText
            size={12}
            textToHighlight={searchFilter}
            color={isCustomValue ? 'blue.600' : undefined}
            ellipsis={{ openDelayInMs: 500 }}
          >
            {`${option.title}`}
          </HighlightedText>
        </span>
      </Group>

      {option.desc && (
        <div className="filter-desc">
          <Text color={appTheme.multiFilter.countTextColor} pr={1} size={12} subject="filter-desc">
            {option.desc}
          </Text>
        </div>
      )}
      {enableExclude && (
        <div className="filter-button">
          <StyledButton
            variant="secondary"
            size="xs"
            icon={valueInOptions?.state === 'DISABLED' ? 'lightbulb-on-regular' : 'ban-regular'}
            onClick={handleClick}
          >
            <Text size={10} color={appTheme.multiFilter.excludeTextColor}>
              {valueInOptions?.state === 'DISABLED' ? 'Include' : 'Exclude'}
            </Text>
          </StyledButton>
        </div>
      )}
    </StyledGroup>
  );
};
