import { opacity } from '../../utils';
import { colors } from '../colors';

const menu = {
  background: colors.blue[800],
  item_divider_color: opacity(colors.gray[500], 20),
  item_color: colors.gray[300],
  item_background: 'transparent',
  item_active_color: colors.blue[300],
  item_active_background: colors.blue[800],
  item_hover_color: colors.gray[300],
  item_hover_background: colors.blue[700],
  item_disabled_color: opacity(colors.gray[500], 20),
  item_disabled_background: 'transparent',
  icon_color: opacity(colors.gray[500], 70),
};

export default menu;
