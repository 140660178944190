import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { ContextMenu, InlineType, Item } from './types';

type UseFormatterState = {
  getPropertyContextMenu?: (item: Item) => ContextMenu;
  getValueContextMenu?: (item: Item) => ContextMenu;
};

export const useJsonFormatterState = ({ getPropertyContextMenu, getValueContextMenu }: UseFormatterState) => {
  const [activeItem, setActiveItem] = useState<{ type: InlineType; item: Item }>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement>(null);
  const [referenceElement, setReferenceElement] = useState<HTMLElement>(null);
  const xPositionOffset = useRef<number>(0);
  const yPositionOffset = useRef<number>(0);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, type: InlineType, item: Item) => {
      const { x, width, y, height } = (event.target as HTMLElement).getBoundingClientRect();
      const { x: eventX, y: eventY } = event.nativeEvent;
      const offsetX = -(width / 2 - (eventX - x));
      const offsetY = -(height - Math.abs(eventY - y));

      xPositionOffset.current = offsetX;
      yPositionOffset.current = offsetY;
      setActiveItem({ type, item });
      setReferenceElement(event.target as HTMLElement);
    },
    [xPositionOffset.current, setActiveItem, setReferenceElement],
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        popperElement &&
        !popperElement.contains(event.target as HTMLElement) &&
        referenceElement &&
        !referenceElement.contains(event.target as HTMLElement)
      ) {
        setActiveItem(null);
      }
    },
    [popperElement, referenceElement],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const activeMenu: ContextMenu = useMemo(() => {
    if (!activeItem) return undefined;

    return activeItem.type === 'property'
      ? getPropertyContextMenu(activeItem.item)
      : getValueContextMenu(activeItem.item);
  }, [activeItem, getPropertyContextMenu, getValueContextMenu]);

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    strategy: 'absolute',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altAxis: true,
          padding: 20,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [xPositionOffset.current, yPositionOffset.current + 15],
        },
      },
    ],
  });

  useEffect(() => {
    update?.();
  }, [referenceElement]);

  return {
    activeItem,
    clearActiveItem: () => setActiveItem(null),
    activeMenu,
    handleClick,
    setPopperElement,
    popperStyles: styles.popper,
    popperAttributes: attributes.popper,
  };
};
