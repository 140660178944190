export const registerUniqueMethod = yup => {
  yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, function (list) {
      if (!list?.length) {
        return true;
      }

      if (list?.length === new Set(list?.map(mapper)).size) {
        return true;
      }

      return this.createError({ message });
    });
  });
  yup.addMethod(yup.array, 'oneOfSchemas', function oneOfSchemas(schemas, message?: string) {
    return this.test(
      'one-of-schemas',
      message || 'Not all items in ${path} match one of the allowed schemas',
      (items: unknown[]) => items.every(item => schemas.some(schema => schema.isValidSync(item, { strict: true }))),
    );
  });
};

export const registerJsonMethod = yup =>
  yup.addMethod(yup.string, 'validJson', function (errorMessage) {
    return this.test(`test-valid-json`, errorMessage, function (value) {
      const { path, createError } = this;

      try {
        JSON.parse(value);
      } catch (e) {
        return createError({ path, message: errorMessage });
      }

      return true;
    });
  });
