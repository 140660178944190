import React from 'react';
import { IListProps } from './List.component';

const defaultContext: Omit<IListProps, 'children'> = {
  variant: 'default',
  size: 'medium',
};

const Context = React.createContext<Omit<IListProps, 'children'>>(defaultContext);

export const useListContext = () => {
  return React.useContext(Context);
};

export const ListProvider = ({ children, value }) => {
  return <Context.Provider value={{ ...defaultContext, ...value }}>{children}</Context.Provider>;
};
