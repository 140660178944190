import React, { FunctionComponent } from 'react';
import { PaddingProps, MarginProps, FlexProps } from 'styled-system';
import { StyledPrimarySidenav } from './Primary-sidenav.styles';

interface IPrimarySidenavProps extends PaddingProps, MarginProps, FlexProps {
  subject?: string;
  isMinified?: boolean;
  children: React.ReactNode;
}

export const PrimarySidenav: FunctionComponent<IPrimarySidenavProps> = ({ children, isMinified, ...layoutProps }) => (
  <StyledPrimarySidenav isMinified={isMinified} {...layoutProps}>
    {children}
  </StyledPrimarySidenav>
);

PrimarySidenav.displayName = 'Sidenav.Primary';
