export const filterDarkThemeV2 = {
  badgeColorActive: 'royalBlue.500',
  badgeColorInactive: 'royalBlue.500',
  countTextColor: 'gray.800',
  titleIconColor: 'royalBlue.1000',
  disabledTitleIconColor: 'gray.700',
  quoteColor: 'gray.500',
  excludeTextColor: 'gray.500',
  excludeButtonColor: 'gray.500',
};
