import { useCallback, useEffect } from 'react';

export const useClickOutside = (referenceElement: HTMLElement, handler: () => void) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (referenceElement && !referenceElement.contains(event.target as HTMLElement)) {
        handler();
      }
    },
    [referenceElement, handler],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);
};
