import React, { PropsWithChildren } from 'react';
import { MarginProps, PaddingProps } from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Color } from '../themes';
import { TagContainer } from './components/TagContainer';
import { ITagTextProps, TagText } from './components/TagText';
import { IconContainer } from './components/IconContainer';
import { RemoveButton } from './components/RemoveButton';
import { TagVariants } from '.';

type ButtonEvents = Partial<Pick<React.DOMAttributes<HTMLDivElement>, 'onClick'>>;

export interface ITagProps extends ButtonEvents, MarginProps, PaddingProps, PropsWithChildren {
  variant?: TagVariants;
  iconElement?: React.ReactNode;
  background?: Color;
  onRemove?: (value: React.ReactNode) => void;
  value?: string | number;
  subject?: string;
  ellipsis?: ITagTextProps['ellipsis'];
  size?: 'xs' | 's' | 'm';
}

export const Tag: React.FC<ITagProps> = ({
  iconElement,
  children,
  onRemove,
  value,
  onClick,
  subject,
  variant = 'primary',
  ellipsis = true,
  size,
  ...props
}) => {
  return (
    <TagContainer {...{ variant, onClick }} {...generateLogzTestAttributes({ context: 'tag', subject })} {...props}>
      {iconElement && <IconContainer>{iconElement}</IconContainer>}
      <TagText ellipsis={ellipsis} variant={variant} size={size}>
        {children || value}
      </TagText>
      {onRemove && <RemoveButton onRemove={onRemove} value={value ?? children} />}
    </TagContainer>
  );
};
