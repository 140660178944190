import { SearchFilterType } from './searchFilters.enum';

export enum AlertOutputType {
  Json = 'JSON',
  Table = 'TABLE',
}

export enum AlertType {
  Alert,
  Rule,
}

export enum AlertAggregationType {
  AnyLogsFound = 'ANY_LOGS_FOUND',
  NoLogsFound = 'NO_LOGS_FOUND',
  NumberOfLogs = 'COUNT',
  Minimum = 'MIN',
  Maximum = 'MAX',
  Average = 'AVG',
  Sum = 'SUM',
  UniqueCount = 'UNIQUE_COUNT',
  Percentage = 'PERCENTAGE',
  Percentile = 'PERCENTILE',
}

export enum AlertNotificationSubject {
  CreateAlert = 'create-alert',
  UpdateAlert = 'update-alert',
  NoAccessToAlert = 'no-access-to-alert',
  MaxAlertsReachedLimit = 'max-alerts-reached-limit',
  EnableAlertState = 'enable-alert',
  DisableAlertState = 'disable-alert',
  EnableAlertRca = 'enable-alert-rca',
  DisableAlertRca = 'disable-alert-rca',
  EmptyState = 'empty-state',
}

export enum AlertCorrelationOperator {
  And = 'AND',
  AndThen = 'AND_THEN',
}

// In SG-139 we need to remove this enum
export enum AlertOutputAmountSubject {
  AllFields = 'all-fields',
  CustomFields = 'custom-fields',
}

export enum AlertQueryDefinitionSubject {
  QueryDefinition = 'query-definition',
  SearchString = 'elasticsearch-query',
}

export enum AlertOutputSubject {
  OutputTableEmptyState = 'output-table-empty-state',
}

export enum AlertJoinQueriesSubject {
  EmptyState = 'join-table-empty-state',
  Table = 'join-table',
  TableRow = 'join-queries-table-row',
  JoinedField = 'join-queries-joined-field',
}

export enum PreviewOutputSubject {
  JSON = 'output-preview-json',
  TABLE = 'output-preview-table',
}

export enum AlertDefinitionsSubject {
  EmptyState = 'alert-definitions-empty-state',
  AlertDefinitionsContainer = 'alert-definitions-container',
  AlertToggleElement = 'alert-toggle-element',
  HeaderNewButton = 'header-new-button',
}

export enum AlertSchedulingError {
  EmptyCron = 'EMPTY_CRON',
  LESS_THEN_MIN_GRANULARITY = 'LESS_THEN_MIN_GRANULARITY',
  LESS_THEN_MIN_INTERVAL = 'LESS_THEN_MIN_INTERVAL',
  INVALID_CRON = 'INVALID_CRON',
}

export enum SortableFields {
  name = 'NAME',
  severity = 'SEVERITY',
  createdAt = 'CREATED_AT',
  updatedAt = 'UPDATED_AT',
}

export enum FiltersIds {
  severities = 'severities',
  createdBy = 'createdBy',
  updatedBy = 'updatedBy',
  activeState = 'activeState',
  tags = 'tags',
  search = 'search',
}

export enum FiltersType {
  severities = SearchFilterType.MultiSelect,
  createdBy = SearchFilterType.MultiSelect,
  updatedBy = SearchFilterType.MultiSelect,
  activeState = SearchFilterType.MultiSelect,
  tags = SearchFilterType.MultiSelect,
}

export enum AlertScheduleSubject {
  ScheduleToggle = 'schedule-toggle',
  CheckAlways = 'always',
  CheckOnSchedule = 'on-schedule',
  CronSchedule = 'cron-schedule',
  TimezoneSchedule = 'timezone-schedule',
}

export enum AlertNotifySubject {
  SuppressNotifications = 'suppress-notifications',
  OutputFormat = 'output-format',
  OutputFieldsAmount = 'output-fields-amount',
  OutputLogFieldsTable = 'output-log-fields-table',
}

export enum MenuActions {
  Edit = 'Edit',
  Duplicate = 'Duplicate',
  ViewLastEvents = 'View last events',
  Delete = 'Delete',
  Contribute = 'Contribute',
}
