export function angularSafeDigest($rootScope, $timeout, Logger) {
  const MAX_ATTEMPTS = 10; // same as angular digest attempts count
  const scheduleMap = new Map();

  function reportDigestError() {
    const errMsg = `[logzioSafeDigestService] schedule digest failed after ${MAX_ATTEMPTS} atempts`;

    Logger.logError({
      origin: Logger.Origin.KIBANA,
      message: errMsg,
      error: new Error(errMsg), // new Error would provide the stack trace origin.
    });
  }

  function scheduleDigest($scope, attemptsCount) {
    if (attemptsCount === 0) {
      scheduleMap.delete($scope);

      return reportDigestError();
    }

    setTimeout(() => {
      if (['$apply', '$digest'].includes($rootScope.$$phase)) {
        attemptsCount -= 1;

        return scheduleDigest($scope, attemptsCount);
      }

      scheduleMap.delete($scope);
      $scope.$digest();
    }, 20);
  }

  return function digest($scope = null, rootDigest = false) {
    if (rootDigest) {
      return $timeout(() => {}, 0, true);
    }

    if ($scope && !scheduleMap.has($scope)) {
      scheduleMap.set($scope, null);
      scheduleDigest($scope, MAX_ATTEMPTS);
    }
  };
}

export const angularSafeDigestService = ['$rootScope', '$timeout', 'Logger', angularSafeDigest];
