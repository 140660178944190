import { FunctionComponent } from 'react';
import { Button } from '../../../../Button/Button.component';
import { Tooltip } from '../../../../tooltip/Tooltip.component';

interface ICrudTableRecordDuplicateActionProps {
  onDuplicate(): void;
  isDense: boolean;
}

export const CrudTableRecordDuplicateAction: FunctionComponent<ICrudTableRecordDuplicateActionProps> = ({
  onDuplicate,
  isDense,
}) => {
  const buttonSubject = 'duplicate';
  const duplicateText = 'Duplicate';

  return (
    <Tooltip title={duplicateText} disableEvents={!isDense}>
      <Button round={isDense} variant={'secondary'} icon={'copy-regular'} onClick={onDuplicate} subject={buttonSubject}>
        {!isDense && duplicateText}
      </Button>
    </Tooltip>
  );
};
