import React, { useState } from 'react';

export interface IDeleteModalComponentProps<T = object> {
  isOpen: boolean;
  onConfirm(): Promise<void>;
  isConfirming: boolean;
  onClose(): void;
  recordToDelete: T;
}

export interface ICrudTableRecordDeleteModalProps<T = object> {
  isOpen: boolean;
  recordToDelete: T;
  onDelete(record: object): Promise<void>;
  onClose(): void;
  ModalComponent: React.ComponentType<IDeleteModalComponentProps<T>>;
}

export const CrudTableRecordDeleteModal = ({
  isOpen,
  recordToDelete,
  ModalComponent,
  onDelete,
  onClose,
}: ICrudTableRecordDeleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteModalConfirm = async () => {
    if (isLoading) return;

    setIsLoading(true);

    try {
      await onDelete(recordToDelete);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };
  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onConfirm={handleDeleteModalConfirm}
      isConfirming={isLoading}
      onClose={handleClose}
      recordToDelete={recordToDelete}
    />
  );
};
