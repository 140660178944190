import React from 'react';
import styled, { css } from 'styled-components';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Checkbox } from '../../Checkbox/Checkbox.component';
import { TableCell, cellPaddingX } from './Cell.component';
import { TableContext } from './Table.component';

const rowPaddingX = 40;

const TableRowStyledContainer = styled.div.attrs<{ context?: string; subject?: string }>(({ context, subject }) =>
  generateLogzTestAttributes({
    subject,
    context: context || 'table-row',
  }),
)<{ isSelected?: boolean; hideRowBorder?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;

  ${({ theme, hideRowBorder }) =>
    !hideRowBorder &&
    css`
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.border};
      }
    `}

  ${({ theme, onClick, isSelected = false }) =>
    onClick &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.gray[100]};
        cursor: pointer;
      }
      ${
        isSelected &&
        css`
          background-color: ${theme.table.body.cellHighlight};
          &:hover {
            background-color: ${({ theme }) => theme.table.body.cellHighlight};
          }
        `
      }}
    `}
`;

const IndicatorContainerStyled = styled(TableCell)`
  flex-grow: 0;
  display: flex;
  align-items: baseline;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 0;
`;

const ActionMenuContainerStyled = styled(IndicatorContainerStyled)`
  display: flex;
  flex-direction: column;
  height: 100%;

  min-width: 45px;
`;

const childrenWithSize = (children, columnWidths, columnIsFixedWidths) => {
  if (!columnWidths) return children;

  return React.Children.map(children, (child, index) =>
    React.cloneElement(child, { width: columnWidths[index], isFixedWidth: columnIsFixedWidths[index] }),
  );
};

export const TableRow = ({ children, bulk, tableOptions, ...props }) => (
  <TableRowStyledContainer {...props} hideRowBorder={tableOptions?.tableStyles?.hideRowBorder}>
    {bulk && (
      <IndicatorContainerStyled width={`${rowPaddingX - cellPaddingX}px`}>
        <Checkbox
          subject={'select-record'}
          onChange={bulk.onChange}
          indeterminate={bulk.indeterminate}
          checked={bulk.checked}
        />
      </IndicatorContainerStyled>
    )}
    <IndicatorContainerStyled
      tableOptions={tableOptions}
      width={bulk && !props.indicatorComponent ? '1px' : `${rowPaddingX - cellPaddingX}px`}
    >
      {props.indicatorComponent}
    </IndicatorContainerStyled>
    <TableContext.Consumer>
      {({ columnWidths, columnIsFixedWidths }: any) => childrenWithSize(children, columnWidths, columnIsFixedWidths)}
    </TableContext.Consumer>
    <ActionMenuContainerStyled tableOptions={tableOptions} width={`${rowPaddingX}px`}>
      {props.actionsComponent}
    </ActionMenuContainerStyled>
  </TableRowStyledContainer>
);

TableRow.displayName = 'TableRow';
