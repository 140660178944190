import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Grid, Text, Group, Ellipsis } from '@logz-ui/styleguide';
import { DateRange, hasAbsoluteInRange } from '@logz-pkg/utils';
import { DateRangePickerSubject } from '@logz-pkg/enums';
import { QuickOption } from '../types';
import { getQuickOptionSubject, isEqualDateRange } from './utils';
import { defaultQuickOptions } from './default-quick-options';
import { StyledTitle, StyledLink } from './QuickFilter.styles';
import { ActionsBar } from './ActionsBar';

interface QuickFilterProps {
  onChange: (range: DateRange) => void;
  onConfirm: (range: DateRange) => void;
  onCancel: () => void;
  quickOptions?: QuickOption[];
  value?: DateRange;
  showActionsBar?: boolean;
  footerNotification?: React.ReactNode;
  actionsBarNotification?: React.ReactNode;
  updateSelectedRange: (range: DateRange) => void;
  lastRelativeValue: DateRange | null;
}

const isSelected = ({ id, start, end, value, selectedDate }) => {
  if (selectedDate?.id) return selectedDate.id === id;

  return start === value?.start.value && end === value?.end.value;
};

export const QuickFilter: React.FC<QuickFilterProps> = ({
  value,
  onChange,
  onConfirm,
  onCancel,
  quickOptions,
  showActionsBar,
  footerNotification,
  actionsBarNotification,
  updateSelectedRange,
  lastRelativeValue,
}) => {
  const options = quickOptions || defaultQuickOptions;
  const gridRowsNumber = Math.ceil(options.length / 2);

  const [selectedDate, setSelectedDate] = useState<DateRange & { id?: string; skipConfirm?: boolean }>(value);

  useEffect(() => {
    if (value && hasAbsoluteInRange(value) && lastRelativeValue) {
      updateSelectedRange(lastRelativeValue);
    }
  }, [lastRelativeValue, value, updateSelectedRange]);

  useEffect(() => {
    if (isEqualDateRange(value, selectedDate)) return;

    setSelectedDate(value);
  }, [value?.start?.value, value?.end?.value]);

  const onConfirmHandler = useCallback(() => {
    const { start, end } = selectedDate;

    onConfirm({ start, end });
  }, [onConfirm, selectedDate]);

  const onClickHandler = useCallback(
    ({ id, type, start, end, skipConfirm }) => {
      const payload: DateRange = {
        start: { type: type ?? 'relative', value: typeof start === 'function' ? start() : start },
        end: { type: type ?? 'relative', value: typeof end === 'function' ? end() : end },
      };

      onChange(payload);

      if (showActionsBar && !skipConfirm) {
        setSelectedDate({ id, skipConfirm, ...payload });
      } else {
        onConfirm(payload);
      }
    },
    [onConfirm, onChange, showActionsBar],
  );

  const quickOptionsList = useMemo(() => {
    return options.map(({ id, label, type, start, end, skipConfirm, secondaryLabel }, key) => {
      const selected = isSelected({ id, start, end, value, selectedDate });

      return (
        <Group key={key} gap={1}>
          <StyledLink
            selected={selected}
            size={12}
            weight={500}
            subject={getQuickOptionSubject({ id, label })}
            onClick={() => onClickHandler({ id, type, start, end, skipConfirm })}
          >
            {label}
          </StyledLink>
          {secondaryLabel && (
            <Ellipsis>
              <Text subject={DateRangePickerSubject.SecondaryLabel} color={'gray.600'} size={12}>
                {secondaryLabel}
              </Text>
            </Ellipsis>
          )}
        </Group>
      );
    });
  }, [value, quickOptions, selectedDate, showActionsBar, onChange, onConfirm]);

  return (
    <Group width={'100%'} vertical gap={0}>
      <Group width={'100%'} vertical p={4} gap={3}>
        <StyledTitle weight={700} size={14}>
          Frequently used
        </StyledTitle>
        <Grid
          columns={2}
          rowGap={1}
          width={'100%'}
          gridAutoFlow={'column'}
          gridTemplateRows={`repeat(${gridRowsNumber}, 1fr)`}
        >
          {quickOptionsList}
        </Grid>

        {footerNotification}
      </Group>

      {showActionsBar && (
        <ActionsBar
          disabled={!selectedDate}
          onConfirm={onConfirmHandler}
          onCancel={onCancel}
          notification={actionsBarNotification}
        />
      )}
    </Group>
  );
};
