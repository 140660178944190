import { CompareToTimeUnitType, SearchTimeFrame } from '@logz-pkg/enums';
import { Field } from 'formik';
import React, { ReactElement, useState } from 'react';
import { PopoverError } from '../../../popover/PopoverError.component';
import { SelectSizes } from '../../../inputs/Select/select-sizes.enum';
import { IFilterField } from '../../interfaces';
import { Option } from '../../types';
import { FilterValue } from '../FilterValue/FilterValue';
import { Container } from '../../../containers/Container/Container.component';
import { DropdownIndicator } from './DropdownIndicator';
import { SelectFilterLabel } from './SelectFilterLabel';
import { StyledSelect } from './select-styles';

export interface ISelectFilter extends IFilterField {
  options: Option[];
  label: string | ReactElement;
  showLabel?: boolean;
  showLabelColon?: boolean;
  placeholder?: string;
  size?: SelectSizes;
  open?: boolean;
  clearable?: boolean;
  autoFocus?: boolean;
  subject?: string;
  minButtonWidth?: number;
  findOptionByValue?: (fieldValue: any) => Option;
  onChange?: (value: SearchTimeFrame | CompareToTimeUnitType) => void;
  disabled?: boolean;
  error?: string;
}

const Component = ({
  placeholder,
  size,
  options,
  label,
  field,
  findOptionByValue,
  autoFocus,
  form,
  onChange,
  name,
  closePopover,
}) => {
  return (
    <StyledSelect
      menuIsOpen
      size={size}
      options={options}
      isClearable={false}
      placeholder={placeholder ? placeholder : typeof label === 'string' && `${label}: All`}
      subject={`filter-${name}`}
      hideSearch={options.length < 10}
      components={{ DropdownIndicator }}
      value={findOptionByValue(field.value)}
      autoFocus={autoFocus}
      controlShouldRenderValue={false}
      onChange={changeEvent => {
        if (changeEvent?.value === field.value) return;

        form.setFieldValue(field.name, changeEvent?.value);

        onChange?.(changeEvent?.value);

        closePopover();
      }}
    />
  );
};

export const SelectFilter: React.FC<ISelectFilter> = ({
  name,
  label,
  showLabel,
  showLabelColon,
  placeholder,
  options = [],
  autoFocus,
  size = SelectSizes.Medium,
  maxLabelWidth = 200,
  minLabelWidth = 50,
  minButtonWidth = 50,
  open = undefined,
  clearable = true,
  subject,
  onChange,
  disabled,
  error,
  ...props
}) => {
  const findOptionByValue = (fieldValue): Option => {
    return props.findOptionByValue?.(fieldValue) ?? options.find(({ value }) => value === fieldValue);
  };
  const [cleared, setCleared] = useState<boolean>(false);

  const handleLabelClick = (field, form) => {
    if (field.value && clearable)
      return () => {
        form.setFieldValue(field.name, undefined);
        setCleared(true);
      };

    return () => setCleared(false);
  };

  return (
    <Field name={name} subject={subject}>
      {({ form, field }) => {
        const optionByValue = findOptionByValue(field.value);

        return (
          <PopoverError error={error}>
            <Container width={'fit-content'}>
              <FilterValue
                field={field.name}
                open={cleared ? false : open}
                maxLabelWidth={maxLabelWidth}
                minLabelWidth={minLabelWidth}
                disabled={disabled}
                label={
                  <SelectFilterLabel
                    onLabelClick={handleLabelClick(field, form)}
                    label={label}
                    minButtonWidth={minButtonWidth}
                    showLabel={showLabel}
                    showLabelColon={showLabelColon}
                    value={optionByValue?.shouldHideSelection ? '' : optionByValue?.label}
                    clearable={clearable}
                  />
                }
                isActive={field.value}
                ContentComponent={{
                  Component,
                  props: {
                    placeholder,
                    size,
                    name,
                    options,
                    label,
                    field,
                    findOptionByValue,
                    autoFocus,
                    form,
                    onChange,
                  },
                }}
              />
            </Container>
          </PopoverError>
        );
      }}
    </Field>
  );
};
