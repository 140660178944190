import React from 'react';
import styled from 'styled-components';
import { DatePickerInputProps } from '../types';
import { BasicInput } from './BasicInput';
import { InputIcon } from './InputIcon';

const StyledInput = styled(BasicInput)`
  input {
    background-color: ${({ theme }) => theme.colors.yellow[300]};

    &:not(:focus):not(:placeholder-shown) {
      border-color: ${({ theme }) => theme.colors.gray[300]};
      background-color: ${({ theme }) => theme.colors.yellow[300]};
      box-shadow: none;
    }
  }
`;

export const WarningInput: React.FC<DatePickerInputProps> = props => {
  return (
    <>
      <StyledInput {...props} />
      <InputIcon icon={'circle-exclamation-solid'} color={'yellow.800'} />
    </>
  );
};
