import { FunctionComponent } from 'react';
import { Button } from '../../../../Button/Button.component';
import { Tooltip } from '../../../../tooltip/Tooltip.component';

interface ICrudTableRecordDeleteActionProps {
  onDelete(): void;
  isDense: boolean;
}

/**
 * According to the specs -
 * If there is only 1 action, we need to display an button with both icon and text
 * If there are 2 or more actions, we show only
 */
export const CrudTableRecordDeleteAction: FunctionComponent<ICrudTableRecordDeleteActionProps> = ({
  onDelete,
  isDense,
}) => {
  const buttonSubject = 'delete';
  const buttonText = 'Delete';

  return (
    <Tooltip title={isDense ? buttonText : ''}>
      <Button icon={'trash-can-regular'} variant="danger" round={isDense} onClick={onDelete} subject={buttonSubject}>
        {!isDense && buttonText}
      </Button>
    </Tooltip>
  );
};
