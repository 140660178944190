import React from 'react';
import { Flex } from '../../containers/Flex/Flex.component';
import { Text } from '../../typography';

export const DefaultEmptyState: React.FC = () => {
  return (
    <Flex width="100%" alignItems="center" justifyContent="center" p={2}>
      <Text>No results found...</Text>
    </Flex>
  );
};
