import { colors } from '../colors';

export const input = {
  variants: {
    primary: {
      styles: {
        color: colors.gray[300],
        background: 'transparent',
        borderColor: colors.gray[500],
      },
      borderless: {
        borderColor: colors.gray[300],
        hover: {
          borderColor: colors.gray[500],
        },
      },
    },
  },
};
