import React, { FC } from 'react';
import { ListProvider } from './context';
import { ListComponent, OrderListComponent } from './List.style';
import { IListItemProps } from './ListItem.component';

export interface IListProps {
  variant?: 'default' | 'check' | 'order';
  size?: 'small' | 'medium' | 'large';
  children: React.ReactElement<IListItemProps> | Array<React.ReactElement<IListItemProps>>;
}

export const List: FC<IListProps> = ({ variant, size, children }) => {
  if (variant === 'order') {
    const indexedChildren = React.Children.map(children, (child, index) => React.cloneElement(child, { index }));

    return (
      <ListProvider value={{ variant, size }}>
        <OrderListComponent>{indexedChildren}</OrderListComponent>
      </ListProvider>
    );
  }

  return (
    <ListProvider value={{ variant, size }}>
      <ListComponent>{children}</ListComponent>
    </ListProvider>
  );
};
