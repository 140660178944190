import React from 'react';
import styled from 'styled-components';
import { DateRangePickerSubject } from '@logz-pkg/enums';

import { Button } from '@logz-ui/styleguide/components/Button/Button.component';
import { IAbsoluteInputProps } from './AbsoluteInput';

const StyledButton = styled(Button)`
  border-radius: 2px;

  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
  }
`;

interface INowButtonProps {
  onClick: IAbsoluteInputProps['onNowButtonClick'];
}

export const NowButton: React.FC<INowButtonProps> = ({ onClick }) => {
  return (
    <StyledButton
      minWidth={50}
      width={60}
      size="xs"
      variant="secondary"
      icon={'clock-regular'}
      p={0}
      onClick={onClick}
      subject={DateRangePickerSubject.EndDateNowButton}
    >
      Now
    </StyledButton>
  );
};
