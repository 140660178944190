import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useMatchingChars } from '../../hooks/matching-chars.hook';
import type { IMultiTagProps } from '../MultiTag.component';
import { useInputWidth } from '../hooks/input-width.hook';
import { multiTagUtils } from '../utils';
import { useMultiTag } from './TagContainer';

const StyledInput = styled.input<{ dynamicWidth: number }>`
  width: ${({ dynamicWidth }) => `${dynamicWidth}px`};
  border: none;
  margin: 0;
  padding: 0;
  font-family: 'Roboto Mono';
  background-color: transparent;
  line-height: 1.3;
  font-size: 14px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  :focus {
    outline: none;
  }
`;

interface IStepForm {
  index: number;
  step: IMultiTagProps['tag']['steps'][number];
}

export const StepForm: React.FC<IStepForm> = ({ index, step }) => {
  const { onChange, tag } = useMultiTag();

  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldWidth, resetFieldWidth, calculateFieldWidth } = useInputWidth({
    inputRef,
    value: step.value,
  });

  const handleBlur = e => {
    const newSteps = multiTagUtils.createNewSteps(tag, index, { ...step, selected: false });

    step.onBlur?.(e);
    onChange?.({ ...tag, steps: newSteps });
  };

  const handleInputChange = useCallback(
    (value: string) => {
      step.onChange?.(value);

      if (value === '') {
        resetFieldWidth();
      } else {
        calculateFieldWidth();
      }
    },
    [step, resetFieldWidth, calculateFieldWidth],
  );

  useMatchingChars({ inputElement: inputRef.current, onChange: handleInputChange });

  return (
    <StyledInput
      data-hotkey
      autoFocus
      onFocus={e => e.target.select()}
      id="value"
      name="value"
      type={step.inputType ?? 'text'}
      onKeyDown={step.onKeyDown}
      onChange={e => handleInputChange(e.target.value)}
      onBlur={handleBlur}
      onClick={step.onClick}
      placeholder={step.label ?? step.value}
      defaultValue={step.value}
      dynamicWidth={fieldWidth}
      ref={inputRef}
      autoComplete="off"
      aria-label="multi-tag input"
    />
  );
};
