import { grayPalette, royalBluePalette } from '../../../themes/colors';
import { fonts } from '../../../themes/base/fonts';

export const textLightTheme = {
  font: {
    code: fonts.code,
  },
  color: {
    primary: grayPalette[900],
    dark: royalBluePalette[1000],
    contrast: grayPalette[0],
    faded: grayPalette[800],
    description: grayPalette[600],
  },
  code: {
    backgroundColor: grayPalette[100],
    borderColor: `1px solid ${grayPalette[400]}`,
  },
};
