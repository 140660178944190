import React, { useContext } from 'react';
import styled from 'styled-components';
import { BulkActionsSubjects } from '@logz-pkg/enums';
import { BulkActionsStateContext } from '../Crud';
import { Group } from '../../../containers/Group/Group.component';
import { bluePalette } from '../../../themes/colors';
import { Icon } from '../../../icons/Icon.component';
import { Text } from '../../../typography/Text/Text.component';
import { Button } from '../../../Button/Button.component';
import { IBulkActionsProps } from './BulkActionsBar';

const StyledGroup = styled(Group)`
  background-color: ${bluePalette[200]};
  height: 40px;
  border-radius: 4px;
`;

const StyledButton = styled(Button)`
  &:focus {
    box-shadow: none;
  }
`;

interface ISelectedAllDataNotification {
  onSelectAllData: () => void;
}

export const SelectedAllDataNotification: React.FunctionComponent<ISelectedAllDataNotification> = ({
  onSelectAllData,
}) => {
  const { selected, modelName, dataSourceLength } = useContext<IBulkActionsProps>(BulkActionsStateContext);
  const isPlural = selected.ids.length > 1 || (selected.isAllPagesSelected && dataSourceLength > 1);
  const name = isPlural ? `${modelName}s` : modelName;
  const clearAllButtonText = 'Clear selection';
  const selectAllButtonText = `Select all ${name} that match this search`;

  return (
    <StyledGroup pl={3} pr={1} gap={1} alignItems={'center'} justifyContent={'center'}>
      <Icon color={'blue.800'} icon={'bullhorn-solid'} />
      <Text whiteSpace={'nowrap'}>
        {selected.isAllPagesSelected
          ? `All ${name} in this search are selected.`
          : `All ${selected.ids.length} ${name} on this page are selected.`}
      </Text>
      <StyledButton
        subject={
          selected.isAllPagesSelected
            ? BulkActionsSubjects.ClearAllNotification
            : BulkActionsSubjects.SelectAllNotification
        }
        variant={'tertiary'}
        onClick={onSelectAllData}
      >
        <Text whiteSpace={'nowrap'} color={'blue.600'}>
          {selected.isAllPagesSelected ? clearAllButtonText : selectAllButtonText}
        </Text>
      </StyledButton>
    </StyledGroup>
  );
};
