import { SubmitFormikOnUpdate } from '@logz-pkg/react-hooks';
import { Formik, FormikProps } from 'formik';
import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { Group } from '../../containers/Group/Group.component';
import { FilterBarHandle } from '../types';
import { UpdateFormikOnFiltersChange } from '../utils/UpdateFormikOnFiltersChange';
import { ClearFiltersButton } from './ClearFiltersButton';

interface IFilterBarProps {
  onChange: (filters) => void;
  initialValues?: Record<string, any>;
  defaultValues?: Record<string, any>;
  showClearFiltersButton?: boolean;
  fullWidth?: boolean;
  subject?: string;
  children: React.ReactNode;
}

const ClearFiltersButtonMemo = memo(ClearFiltersButton);

export const FilterBar = forwardRef<FilterBarHandle, IFilterBarProps>(
  (
    { children, initialValues = {}, defaultValues = {}, onChange, showClearFiltersButton = true, fullWidth, subject },
    ref,
  ) => {
    const formikRef = useRef<FormikProps<any> | null>(null);

    const handleResetFilters = useCallback(() => {
      if (formikRef.current) {
        formikRef.current.setValues(defaultValues);
      }
    }, [defaultValues]);

    useImperativeHandle(
      ref,
      () => ({
        resetFilters: handleResetFilters,
        setFieldValue: formikRef.current.setFieldValue,
        resetValue: (name: string) => {
          formikRef.current.setFieldValue(name, undefined);
          formikRef.current.submitForm();
        },
      }),
      [handleResetFilters],
    );

    return (
      <Formik initialValues={initialValues} onSubmit={onChange}>
        {formik => {
          formikRef.current = formik;

          return (
            <Group
              rowGap={2}
              flexWrap={'wrap'}
              alignItems={'center'}
              gap={1}
              {...(fullWidth && { width: '100%' })}
              subject={subject}
            >
              {children}
              {showClearFiltersButton && (
                <ClearFiltersButtonMemo
                  onClick={() => formik.setValues(defaultValues)}
                  values={formik.values}
                  defaultValues={defaultValues}
                />
              )}
              <SubmitFormikOnUpdate formik={formik} />
              <UpdateFormikOnFiltersChange formik={formik} filterValues={initialValues} />
            </Group>
          );
        }}
      </Formik>
    );
  },
);

FilterBar.displayName = 'FilterBar';
