import { FunctionComponent, useState, forwardRef } from 'react';
import { MarginProps } from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { Group } from '../containers/Group/Group.component';
import { Flex } from '../containers/Flex/Flex.component';
import { Text } from '../typography';
import { Tooltip } from '../tooltip/Tooltip.component';
import { TooltipContainer } from '../Button/Button.styles';
import {
  ToggleContainer,
  ToggleCircle,
  HiddenToggleInput,
  StyledCheckedIcon,
  LoadingBall,
  LoadingBallsContainer,
} from './Toggle.styles';

export interface IToggleProps extends MarginProps {
  onChange(value: boolean): Promise<any> | any;
  value: boolean;
  disabled?: boolean;
  name?: string;
  subject?: string;
  label?: string | JSX.Element;
  className?: string;
  disabledTooltip?: string;
}

export const ToggleLoadingAnimation = ({ ...props }) => (
  <LoadingBallsContainer {...props}>
    <LoadingBall />
    <LoadingBall />
    <LoadingBall />
  </LoadingBallsContainer>
);

export const Toggle: FunctionComponent<IToggleProps> = forwardRef(
  ({ disabledTooltip, disabled, onChange, value = false, subject, label, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = async (value: boolean) => {
      try {
        setIsLoading(true);
        await onChange?.(value);
      } finally {
        setIsLoading(false);
      }
    };

    const toggle = (
      <Group alignItems={'center'} gap={1}>
        <Flex position={'relative'}>
          <ToggleContainer
            onClick={() => !disabled && handleChange(!value)}
            value={value}
            disabled={disabled || isLoading}
            ref={ref}
            {...generateLogzTestAttributes({ context: 'toggle', subject: subject || null, checked: value.toString() })}
            {...props}
            role="checkbox"
          >
            {value && <StyledCheckedIcon />}
            <ToggleCircle moveRight={value} disabled={disabled} />
            <HiddenToggleInput type={'checkbox'} checked={value} readOnly />
          </ToggleContainer>
          {<ToggleLoadingAnimation reverseAnimation={value} isLoading={isLoading} />}
        </Flex>
        {typeof label === 'string' ? <Text color={!disabled ? 'black' : 'gray.600'}>{label}</Text> : label}
      </Group>
    );

    return disabled && disabledTooltip ? (
      <Tooltip title={disabledTooltip}>
        <TooltipContainer>{toggle}</TooltipContainer>
      </Tooltip>
    ) : (
      toggle
    );
  },
);

Toggle.displayName = 'Toggle';
