import React, { useContext } from 'react';
import { upperFirst } from 'lodash';
import { Text } from '../../../typography';
import { BulkActionsStateContext } from '../Crud';
import { IBulkActionsProps } from './BulkActionsBar';

export const SelectedLabel: React.FunctionComponent = () => {
  const { modelName, totalDataLength, dataSourceLength, selectedLength } =
    useContext<IBulkActionsProps>(BulkActionsStateContext);
  const isPlural = selectedLength ? selectedLength > 1 : dataSourceLength > 1;
  const name = isPlural ? `${upperFirst(modelName)}s` : upperFirst(modelName);

  return (
    <Text whiteSpace={'nowrap'}>
      <Text weight={'bold'} mr={1}>
        {selectedLength ? `${selectedLength} ${name} Selected of` : `Showing ${dataSourceLength} ${name} of`}
      </Text>
      {totalDataLength}
    </Text>
  );
};
