import { CSSProperties } from 'react';

export const paginationSize = {
  options: [10, 25, 50, 100],
  default: 25,
};

export const defaultPagination = {
  pageNumber: 1,
  pageSize: paginationSize.default,
};

export const STATIC_TABLE_STYLES: CSSProperties = {
  width: 'auto',
  borderCollapse: 'collapse',
  tableLayout: 'auto',
  display: 'grid',
};

export const STATIC_TABLE_HEADER_STYLES: CSSProperties = {
  position: 'sticky',
  opacity: 0.95,
  width: '100%',
  top: 0,
  zIndex: 2,
};

export const STATIC_DATA_ROW_STYLES: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  border: 'none',
  minWidth: '100%',
  transition: 'none',
  width: 'auto',
};

export const INITIAL_EMPTY_OBJECT = {};
export const INDICATOR_COMPONENT_COLUMN_ID = 'indicator-component-column';

export const CELL_EXPAND_ACTION_LABEL = 'Expand';
