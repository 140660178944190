import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex } from '../containers/Flex/Flex.component';
import { Fade } from '../Animations';
import { IsHoveredContext } from './HeaderDropdown.component';

const propTypes = {
  ...Flex.propTypes,
  direction: PropTypes.oneOf(['left', 'right']),
};

const defaultProps = {
  ...Flex.defaultProps,
  direction: 'right',
};

const HeaderDropdownContentStyled = styled(Flex)`
  z-index: 100;
  position: absolute;
  top: ${({ theme }) => theme.header.height};
  ${({ direction }) =>
    direction === 'right'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
`;

/**
 * @deprecated
 */
export const HeaderDropdownContent = props => {
  const isHovered = useContext(IsHoveredContext);
  const [display, setDisplay] = useState(isHovered);
  const hoverCloseTimeout = useRef(null);

  // Put a debounce to not hide the menu when the user accidentally moving the mouse out - DEV-23283
  useEffect(() => {
    if (isHovered) {
      clearTimeout(hoverCloseTimeout.current);
      setDisplay(true);
    }

    if (!isHovered) {
      hoverCloseTimeout.current = setTimeout(() => setDisplay(false), 200);
    }
  }, [isHovered]);

  if (display) {
    return (
      <Fade>
        <HeaderDropdownContentStyled {...props} />
      </Fade>
    );
  }

  return '';
};

HeaderDropdownContent.displayName = 'HeaderDropdownContent';
HeaderDropdownContent.propTypes = propTypes;
HeaderDropdownContent.defaultProps = defaultProps;
