import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { MarginProps, PaddingProps } from 'styled-system';
import { SeverityLevel } from '@logz-pkg/enums';
import { severityColor } from '../themes';
import { Tag } from './Tag.component';

const StyledTag = styled(Tag)<{ severity: SeverityLevel }>`
  background: ${severityColor};
  color: ${({ theme }) => theme.tag.severity.color};
`;

export interface ITagSeverity extends PropsWithChildren, MarginProps, PaddingProps {
  severity?: SeverityLevel;
  subject?: string;
}

export const TagSeverity: React.FC<PropsWithChildren<ITagSeverity>> = ({
  severity = SeverityLevel.Info,
  subject,
  children,
  ...props
}) => {
  return (
    <StyledTag severity={severity} subject={subject} {...props}>
      {children}
    </StyledTag>
  );
};
