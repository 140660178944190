import { FunctionComponent, useCallback, useState } from 'react';
import { Color, Flex, Grid, Icon } from '@logz-ui/styleguide';

interface IStarsCollectionProps {
  onClick?: (index: number) => void;
  maxRate: number;
  rate: number;
}

export const StarsCollection: FunctionComponent<IStarsCollectionProps> = ({ onClick, maxRate, rate }) => {
  const [hoveredRate, setHoveredRate] = useState(null);

  const getIconProps = useCallback(
    (index: number): { icon: 'star-solid' | 'star-regular'; color: Color } => {
      const isHoveredOrRated = parseFloat(hoveredRate) >= index || index < rate;

      return {
        icon: isHoveredOrRated ? 'star-solid' : 'star-regular',
        color: isHoveredOrRated ? 'royalBlue.1000' : 'royalBlue.500',
      };
    },
    [hoveredRate, rate],
  );

  return (
    <Flex p={1} onMouseLeave={() => setHoveredRate(null)}>
      <Grid columns={5} alignItems="space-between" cellGap={6}>
        {Array(maxRate)
          .fill(0)
          .map((_, i) => (
            <Icon
              subject="feedback-star"
              size={18}
              key={`ai-chat-feedback-rate-${i}`}
              {...getIconProps(i)}
              onClick={() => {
                const newRate = i < rate ? i : i + 1;

                setHoveredRate(null);
                onClick?.(newRate);
              }}
              onMouseEnter={() => setHoveredRate(i)}
            />
          ))}
      </Grid>
    </Flex>
  );
};
