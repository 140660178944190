import * as PopperJS from '@popperjs/core';
import { isNil } from 'lodash';
import maxSize from 'popper-max-size-modifier';

const applyMaxSize: PopperJS.Modifier<'applyMaxSize', PopperJS.Options> = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({ state }) {
    const { height } = state.modifiersData.maxSize;

    if (state.modifiersData.arrow) return;

    if (state.styles.popper.maxHeight && state.styles.popper.maxHeight <= height) return;

    state.styles.popper = {
      ...state.styles.popper,
      maxHeight: `${height - 5}px`,
      overflow: 'auto',
    };
  },
};

const combinedStylesModifier: PopperJS.Modifier<'combinedStyles', PopperJS.Options> = {
  name: 'combinedStyles',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles', 'arrow'],
  fn({ state }) {
    const { popperOffsets } = state.modifiersData;

    if (!popperOffsets) return;

    state.styles.popper = {
      ...state.styles.popper,
      transform: `translate3d(${Math.round(popperOffsets.x)}px, ${Math.round(popperOffsets.y)}px, 0)`,
    };

    if (state.elements.arrow && state.modifiersData.arrow) {
      const { x: arrowX, y: arrowY } = state.modifiersData.arrow;

      if (!isNaN(arrowX) && !isNaN(arrowY)) {
        state.styles.arrow = {
          position: 'absolute',
          transform: `translate3d(${Math.round(arrowX)}px, ${Math.round(arrowY)}px, 0)`,
          width: state.elements.arrow.style.fontSize || '16px',
          height: state.elements.arrow.style.fontSize || '16px',
        };
      }
    }

    requestAnimationFrame(() => {
      if (state.elements.popper && !state.elements.popper.classList.contains('ready-for-transitions')) {
        state.elements.popper.classList.add('ready-for-transitions');
      }
    });
  },
};

export const popoverConfig = ({
  placement,
  overflow,
  showArrow,
  flip,
  offset = 0,
  overflowable,
}: {
  placement?: PopperJS.Placement;
  overflow?: boolean;
  showArrow?: boolean;
  flip?: any;
  offset?: number;
  overflowable?: boolean;
}): PopperJS.Options => ({
  placement,
  strategy: overflow ? 'fixed' : 'absolute',
  modifiers: [
    {
      name: 'computeStyles',
      options: {
        adaptive: false,
        gpuAcceleration: true,
      },
    },
    combinedStylesModifier,
    {
      name: 'arrow',
      enabled: showArrow,
      options: {
        element: '[data-popper-arrow]',
        padding: 8,
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, showArrow ? 10 + offset : offset],
      },
    },
    {
      name: 'flip',
      enabled: !isNil(flip),
      options: {
        padding: 5,
        ...flip,
        altBoundary: true,
        flipVariations: true,
      },
    },
    {
      name: 'preventOverflow',
      options: {
        altAxis: true,
        tether: false,
        padding: 5,
      },
    },
    ...(overflowable ? [maxSize, applyMaxSize] : []),
  ].filter(Boolean),
});
