export enum SharedTokensSubjects {
  TokensTable = 'shared-tokens-table',
  EmptyState = 'empty-state-shared-tokens',

  Modal = 'shared-tokens-modal',
  NameField = 'name',
  TokenFiltersSelect = 'select-token-filters',
  FiltersOperator = 'filters-operator',
  SubmitButton = 'save-shared-token',

  FiltersTable = 'token-filters-table',
  FiltersTableDescriptionInput = 'token-filter-description',
  FiltersTableFilterInput = 'token-filter-filter',
  FiltersTableValueInput = 'token-filter-value',

  SearchTokenNotification = 'search-token-notification',
  CreateTokenNotification = 'create-token-notification',
  UpdateTokenNotification = 'update-token-notification',
  DeleteTokenNotification = 'delete-token-notification',

  SearchFilterNotification = 'search-filter-notification',
  CreateFilterNotification = 'create-filter-notification',
  DeleteFilterNotification = 'delete-filter-notification',
}
