import { colors } from '../../../themes/colors';

export const titleLightTheme = {
  variants: {
    deprecatedPanel: {
      color: colors.orange[600],
      fontSize: '20px',
      fontWeight: 500,
    },
    deprecatedDialog: {
      color: colors.gray[900],
      fontSize: '23px',
      fontWeight: 400,
    },
    deprecatedDialogSmall: {
      color: colors.gray[900],
      fontSize: '18px',
      fontWeight: 500,
    },
    deprecatedPage: {
      color: colors.blue[500],
      fontSize: '30px',
      fontWeight: 300,
    },
    deprecatedPanelSecondary: {
      color: colors.gray[900],
      fontSize: '18px',
      fontWeight: 500,
    },
    deprecatedSubSection: {
      color: colors.gray[900],
      fontSize: '15px',
      fontWeight: 500,
    },
    deprecatedSection: {
      color: colors.orange[600],
      fontSize: '20px',
      fontWeight: 400,
    },
    deprecatedSectionSmall: {
      color: colors.gray[900],
      fontSize: '14px',
      fontWeight: 400,
    },
  },
  styles: {
    fontFamily: 'Roboto, sans-serif',
  },
  color: colors.gray[900],
};
