import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Backdrop } from '../backdrop';
import { DrawerLayout, DrawerMode } from './Drawer.configs';
import { DrawerContainer, StyledDrawerContent, drawerDirections } from './Drawer.styles';

interface IDrawerProps {
  open: boolean;
  onBackdropClick?(open: boolean): void;
  zIndex?: number;
  hideBackdrop?: boolean;
  layout?: keyof typeof DrawerLayout;
  mode?: keyof typeof DrawerMode;
  subject?: string;
  width?: string;
  direction?: keyof typeof drawerDirections;
  name?: string;
  children: React.ReactNode;
}

export const Drawer = forwardRef<HTMLDivElement, IDrawerProps>(
  (
    {
      onBackdropClick,
      hideBackdrop,
      mode = DrawerMode.Overlay,
      layout = DrawerLayout.Vertical,
      children,
      width = '450px',
      direction = 'left',
      zIndex = 91,
      open,
      name,
      ...props
    },
    ref,
  ) => {
    const drawerRef = useRef<HTMLDivElement>(null);

    const drawerHeight = drawerRef.current?.parentElement?.clientHeight
      ? `${drawerRef.current?.parentElement?.clientHeight}px`
      : '100%';

    useImperativeHandle(ref, () => drawerRef.current);

    return (
      <>
        <DrawerContainer
          direction={direction}
          name={name}
          width={width}
          open={open}
          context={'drawer'}
          height={drawerHeight}
          ref={drawerRef}
          zIndex={zIndex}
          {...props}
        >
          <StyledDrawerContent width={width}>{children}</StyledDrawerContent>
        </DrawerContainer>
        {!hideBackdrop && mode === DrawerMode.Overlay && (
          <Backdrop mode={mode} show={open} onClick={() => onBackdropClick(!open)} />
        )}
      </>
    );
  },
);

Drawer.displayName = 'Drawer';
