import { isNil } from 'lodash';
import styled, { css } from 'styled-components';
import {
  display,
  fontFamily,
  lineHeight,
  maxHeight,
  maxWidth,
  minWidth,
  position,
  shadow,
  space,
  textAlign,
} from 'styled-system';
import { Box } from './Box.component';
import { IContainerProps } from './Container.types';

type StyledContainerProps = Omit<IContainerProps, 'shadow'> & { boxShadow: IContainerProps['shadow'] };

export const StyledContainer = styled(Box)<StyledContainerProps>`
  font-size: ${({ theme }) => theme.sizes.text.medium};
  font-weight: ${({ theme }) => theme.sizes.fontWeight.normal};
  line-height: 17px;
  -webkit-font-smoothing: antialiased;
  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
  ${({ theme, size }) =>
    !isNil(size) &&
    css`
      width: ${theme.container.sizes[size]};
    `}
  ${display}
  ${position}
  ${space}
  ${shadow}
  box-shadow: ${({ boxShadow }) => (boxShadow === 'line' ? `0 -1px 0px 0 rgb(231 231 231) inset` : undefined)};
  ${maxWidth}
  ${maxHeight}
  ${lineHeight}
  ${fontFamily}
  ${textAlign}
  ${minWidth}
  
  height: ${({ height }) => height};
  color: ${({ theme }) => theme.colors.text.base};
  ${({ theme, variant }) =>
    variant
      ? css`
          color: ${theme.container.variants[variant].textColor};
          background: ${theme.container.variants[variant].background};
        `
      : css`
          color: inherit;
          background: inherit;
        `}
  ${({ theme, variant, border, borderBottom, borderTop, borderLeft, borderRight, borderWidth }) => {
    const varBorderColor = variant ? theme.container.variants[variant].borderColor : 'inherit';
    const varBorderWidth = variant ? theme.container.borderWidths[borderWidth] : 'inherit';

    if (border) {
      return css`
        border: ${varBorderWidth} solid ${varBorderColor};
      `;
    }

    if (borderBottom) {
      return css`
        border-bottom: ${varBorderWidth} solid ${varBorderColor};
      `;
    }

    if (borderTop) {
      return css`
        border-top: ${varBorderWidth} solid ${varBorderColor};
      `;
    }

    if (borderLeft) {
      return css`
        border-left: ${varBorderWidth} solid ${varBorderColor};
      `;
    }

    if (borderRight) {
      return css`
        border-right: ${varBorderWidth} solid ${varBorderColor};
      `;
    }
  }}
  ${({ borderRadius, theme }) =>
    borderRadius &&
    css`
      border-radius: ${theme.sizes.borderRadius[borderRadius]};
    `}
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 1;
    `}
    ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};

  ${({ resize }) =>
    resize &&
    css`
      resize: ${resize};
    `}
`;
