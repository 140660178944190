import merge from 'deepmerge';
import { BaseTheme } from '../base';
import { spinnerMarketingTheme } from '../../progress/Spinner/themes/marketingTheme';
import { selectMarketingTheme } from '../../inputs/Select/themes/marketing.theme';
import { marketingBase } from './marketingBase';
import { header } from './header';
import { input } from './input';
import { label } from './label';
import { tooltip } from './tooltip';
import { link } from './link';
import { drawer } from './drawer';

export const MarketingTheme = merge(BaseTheme, {
  name: 'marketing',
  ...marketingBase,
  drawer,
  spinnerMarketingTheme,
  input,
  label,
  select: selectMarketingTheme,
  tooltip,
  header,
  link,
});
