import { Cell, Row, flexRender } from '@tanstack/react-table';
import { isNil } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Popover } from '../../../popover/Popover.component';
import { PopoverContentComponentProps } from '../../../popover/types';
import { INDICATOR_COMPONENT_COLUMN_ID } from '../constants';
import { IDataTableProps, IRowAction } from '../types';
import { Actions } from './Actions/Actions';

const StyledDiv = styled.div<{ shouldShowOnRowHover: boolean }>`
  display: flex;
  width: 100%;
  ${({ shouldShowOnRowHover }) =>
    shouldShowOnRowHover &&
    css`
      opacity: var(--hovering-row);
    `}
`;

interface ICellContent<T> extends PopoverContentComponentProps {
  getActions: IDataTableProps<T>['getActions'];
  padding: string;
  row: Row<T>;
  cell: Cell<T, unknown>;
  popoverSettings: IDataTableProps<T>['popoverSettings'];
  isFirst: boolean;
  ExpandableIconComponent: IDataTableProps<T>['subRows']['ExpandableIconComponent'];
  name: IDataTableProps<T>['name'];
  subject: IDataTableProps<T>['subject'];
  flipLeftPadding: number;
  actionsOffsetTop?: number;
}

const Component: React.FC<ICellContent<any> & { actions: ReturnType<ICellContent<any>['getActions']> }> = ({
  actions,
  row,
  cell,
  subject,
  popoverState,
}) => {
  if (actions?.length === 0) return null;

  const isFlipped = popoverState?.placement === 'bottom-start' || popoverState?.placement === 'bottom-end';

  return <Actions actions={actions} row={row} cell={cell} subject={`${subject}-actions`} isFlipped={isFlipped} />;
};

export const CellContent = memo<ICellContent<any>>(
  ({
    getActions,
    row,
    cell,
    isFirst,
    padding,
    popoverSettings,
    ExpandableIconComponent,
    actionsOffsetTop,
    name,
    subject,
    flipLeftPadding,
  }) => {
    const [actions, setActions] = useState<IRowAction<any>[]>(null);

    const getParentElement = useCallback(() => document.querySelector(`[id="${name}"]`), [name]);
    const shouldShowOnRowHover = cell.column.columnDef.showCellOnRowHover;

    useEffect(() => {
      if (isNil(actions) || isNil(getActions)) return;

      setActions(getActions(row, cell));
    }, [cell, row, getActions, isNil(actions)]);

    const handleOpen = useCallback(() => {
      const newActions = getActions(row, cell);

      if (JSON.stringify(actions) !== JSON.stringify(newActions)) {
        setActions(newActions);
      }
    }, [actions, cell, getActions, row]);

    const leftFlipPadding = useMemo(() => {
      if (cell.column.getIsPinned()) return 0;

      return flipLeftPadding;
    }, [flipLeftPadding]);

    if (!isNil(getActions) && cell.column.id !== INDICATOR_COMPONENT_COLUMN_ID) {
      return (
        <Popover
          Content={{
            Component,
            props: {
              actions,
              row,
              cell,
              subject,
            },
          }}
          closeDelayInMs={0}
          placement={'top-start'}
          openDelayInMs={250}
          trigger="hover"
          zIndex={2}
          variant="none"
          transitionDurationMs={250}
          onOpen={handleOpen}
          flip={{
            padding: { top: (actionsOffsetTop ?? 0) + 35, left: leftFlipPadding },
            boundary: getParentElement(),
            fallbackPlacements: ['top-end', 'bottom-start', 'bottom-end'],
          }}
          {...popoverSettings}
        >
          <StyledDiv style={{ padding }} shouldShowOnRowHover={shouldShowOnRowHover}>
            {isFirst && ExpandableIconComponent && <ExpandableIconComponent row={cell.row} />}
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </StyledDiv>
        </Popover>
      );
    }

    return (
      <StyledDiv style={{ padding }} shouldShowOnRowHover={shouldShowOnRowHover}>
        {isFirst && ExpandableIconComponent && <ExpandableIconComponent row={cell.row} />}
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </StyledDiv>
    );
  },
);

CellContent.displayName = 'CellContent';
