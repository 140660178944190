import styled, { css } from 'styled-components';
import { SortDirection } from '@logz-pkg/enums';
import { Button } from '../../../Button/Button.component';
import { Tooltip } from '../../../tooltip/Tooltip.component';

const IconButton = styled(Button)<{ sortDirection: SortDirection }>`
  ${({ sortDirection }) =>
    sortDirection !== SortDirection.Initial &&
    css`
      &[type='button'] {
        display: block;
      }
    `};

  :active,
  :focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
`;

const sortTooltips = {
  [SortDirection.Descending]: 'Descending',
  [SortDirection.Ascending]: 'Ascending',
};

const sortIcons: { [key: string]: 'arrow-down-regular' | 'arrow-up-regular' | 'arrow-up-arrow-down-regular' } = {
  [SortDirection.Descending]: 'arrow-down-regular',
  [SortDirection.Ascending]: 'arrow-up-regular',
  [SortDirection.Initial]: 'arrow-up-arrow-down-regular',
};

interface ISortProps {
  sortDirection: SortDirection;
  onClick?: (e) => void;
  defaultSortingDirection?: SortDirection;
  className?: string;
  disabled?: boolean;
  disabledTooltip?: string;
}

export const Sort = styled(
  ({ sortDirection, defaultSortingDirection, onClick, disabled, disabledTooltip }: ISortProps) => {
    const icon = sortIcons[sortDirection || defaultSortingDirection];
    const tooltip =
      disabled && disabledTooltip ? disabledTooltip : sortTooltips[sortDirection || defaultSortingDirection];

    return (
      <Tooltip title={tooltip} placement={'top'}>
        <IconButton
          onClick={onClick}
          sortDirection={sortDirection}
          variant="tertiary"
          round
          disabled={disabled}
          icon={icon}
          size={'xs'}
          subject={'sort-icon-data-table'}
        />
      </Tooltip>
    );
  },
)``;
