export enum PlanAndBillingState {
  PlanAndBilling = 'dashboard.settings.plan-and-billing',
  Plan = 'dashboard.settings.plan-and-billing.plan',
  Billing = 'dashboard.settings.plan-and-billing.billing',
  Usage = 'dashboard.settings.plan-and-billing.usage',
}

export enum BillingCycle {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Annually = 'ANNUALLY',
}

export enum PaymentMethod {
  CreditCard = 'CREDIT_CARD',
  WireTransfer = 'WIRE_TRANSFER',
  MarketPlaceAWS = 'MARKET_PLACE_AWS',
  MarketPlaceAZURE = 'MARKET_PLACE_AZURE',
  Other = 'OTHER',
}

export enum ProductType {
  GbRetention = 'GB_RETENTION',
  UniqueMetrics = 'UM',
  SpansM = 'SPANS',
  Gb = 'GB',
  OnDemand = 'ON_DEMAND',
}

export enum PurchaseFlowStage {
  ConfigurePlan,
  PaymentDetails,
  UpgradeSummary,
}

export enum PlanAndBillingSubject {
  PlanPage = 'plan-page',
  UsagePage = 'usage-page',
  Promotions = 'promotions',
  VolumeAnalysis = 'volume-analysis',
  PlanSummary = 'plan-summary',
  ProductBrand = 'product-brand',
  ActiveProduct = 'active-product',
  InactiveProduct = 'inactive-product',
  OwnedProduct = 'owned-product',
  OwnedProductParam = 'owned-product-param-',
  PlanConfigurationPrice = 'plan-configuration-price',
  PlanConfigurationAddProduct = 'plan-configuration-add-product-',
  PlanConfigurationConfigureProduct = 'plan-configuration-configure-product',
  ProductConfiguration = 'product-configuration',
  PlanSelection = 'plan-selection',
  ParamSelect = 'param-select',
  CompanyDetailsFormSection = 'company-details-form-section',
  BillingAddressFormSection = 'billing-address-form-section',
  ShippingAddressFormSection = 'shipping-address-form-section',
  CreditCardFormSection = 'credit-card-form-section',
  ShoppingCartSubmit = 'shopping-cart-submit',
  PurchaseFlowModal = 'purchase-flow-modal',
  ShoppingCartTax = 'shopping-cart-tax',
  GeneralErrorModal = 'general-error-modal',
  UpgradeErrorModal = 'upgrade-error-modal',
  PageGeneralErrorModal = 'page-general-error-modal',
  PaymentStripeError = 'payment-stripe-error',
  ShoppingCartDisclaimer = 'shopping-cart-disclaimer',
  SubmitPayment = 'submit-payment',
  ShoppingCartRow = 'shopping-cart-row',
  ShoppingCart = 'shopping-cart',
  GoBack = 'go-back',
  StartVolumeAnalysis = 'start-volume-analysis',
  PaymentStage = 'payment-stage',
  Tax = 'tax',
  UpgradeStage = 'upgrade-stage',
  SuccessModal = 'success-modal',
  SuccessModalSubmit = 'success-modal-submit',
  SuccessModalTitle = 'success-modal-title',
  BillingCycle = 'billing-cycle',
  ShippingAddressCheckbox = 'shipping-address-checkbox',
  LogManagementUsageGraph = 'log-management-usage-graph',
  InfrastructureMonitoringUsageGraph = 'infrastructure-monitoring-usage-graph',
  UsageStatusLine = 'usage-status-line',
  ProductPlanNote = 'product-plan-note',
  MonthlyUsageGraph = 'monthly-usage-graph',
  ExpandUsageData = 'expand-usage-data',
}

export enum CreditCardStatus {
  Disabled,
  Enabled,
  Valid,
  Error,
  InProgress,
}

export enum AddressType {
  Billing = 'billingAddress',
  Shipping = 'shippingAddress',
}

export enum PurchaseFlow {
  Custom = 'custom',
  SemiAuto = 'semiAuto',
  Auto = 'auto',
  Downgrade = 'downgrade',
}

export enum ProductParamsChange {
  None,
  Upgrade,
  Downgrade,
}

export enum UsageResponseStatus {
  Success = 'SUCCESS',
  TimeOut = 'TIME_OUT',
  GeneralError = 'GENERAL_ERROR',
}

export enum UsageStatus {
  OverTheLimit = 'OverTheLimit',
  NearTheLimit = 'NearTheLimit',
  Ok = 'Ok',
}

export enum ProductUsageType {
  MonthlyTotalUsage,
  MonthlyOverageDaysCount,
}

export enum PlanChangeConfirmation {
  TrialAccountMigration,
  FinalizeBySupport,
  Downgrade,
}

export enum ProductPlanResponseStatus {
  SUCCESS = 'SUCCESS',
  TIME_OUT = 'TIME_OUT',
  GENERAL_ERROR = 'GENERAL_ERROR',
}

export enum LogMeasurementType {
  Indexed = 'INDEXED',
  Ingested = 'INGESTED',
}

export enum StripeRegion {
  US = 'US',
  NonUS = 'NON_US',
}

export enum OnDemandUsageType {
  USAGE = 'USAGE',
  OVERAGE = 'OVERAGE',
  DROP_LOGS = 'DROP_LOGS',
  API_CALLS = 'API_CALLS',
}

export enum PromotionType {
  CONSUMPTION_BASED_BILLING_PROMOTION = 'CONSUMPTION_BASED_BILLING_PROMOTION',
}
