import { colors } from '../../../themes/colors';

const link = {
  color: colors.blue[500],
  hover: {
    color: colors.blue[400],
  },
};

export default link;
