import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Spinner } from '../../progress/Spinner/Spinner.component';

export interface IAutoScrollingContainerProps {
  forceScroll?: boolean;
  animateScroll?: boolean;
  onScrollComplete?(): void;
  changeDetectionFilter?(
    previousProps: AutoScrollingContainerComponentProps,
    newProps: AutoScrollingContainerComponentProps,
  ): boolean;
  visibleDetectionEpsilon?: number;
  className?: string;
  loading?: boolean;
  onScrollAttachmentChanged?(attached: boolean): void;
  registerBottomScrollRef?(ref: any): void;
  children: React.ReactNode;
}

const Scrollable = styled.div<{ animateScroll: boolean }>`
  ${({ animateScroll }) => (animateScroll ? `scroll-behavior: smooth;` : '')}
  max-height: inherit;
  height: inherit;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ScrollTarget = styled.div`
  height: 1px;
`;

type AutoScrollingContainerComponentProps = PropsWithChildren<IAutoScrollingContainerProps>;

export class AutoScrollingContainer extends React.Component<IAutoScrollingContainerProps> {
  private readonly wrapperRef: React.RefObject<HTMLDivElement>;
  private readonly bottomRef: React.RefObject<HTMLDivElement>;

  constructor(props: IAutoScrollingContainerProps) {
    super(props);
    this.bottomRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.state = {
      attached: true,
    };
  }

  static defaultProps: IAutoScrollingContainerProps = {
    forceScroll: false,
    animateScroll: false,
    onScrollComplete: () => {},
    changeDetectionFilter: () => true,
    visibleDetectionEpsilon: 30,
    loading: false,
    onScrollAttachmentChanged: () => {},
    registerBottomScrollRef: () => {},
    children: null,
  };

  getSnapshotBeforeUpdate(): boolean {
    if (this.wrapperRef.current && this.bottomRef.current) {
      const { visibleDetectionEpsilon } = this.props;

      return AutoScrollingContainer.isVisible(this.wrapperRef.current, this.bottomRef.current, visibleDetectionEpsilon); // This argument is passed down to componentDidUpdate as 3rd parameter
    }

    return false;
  }

  componentDidUpdate(
    previousProps: AutoScrollingContainerComponentProps,
    previousState: any,
    visibleBeforeUpdate: boolean,
  ): void {
    const { forceScroll, changeDetectionFilter, onScrollAttachmentChanged } = this.props;
    const isValidChange = changeDetectionFilter!(previousProps, this.props);

    if (isValidChange && (forceScroll || visibleBeforeUpdate) && this.bottomRef.current && this.wrapperRef.current) {
      if (!previousState.attached) {
        onScrollAttachmentChanged(true);
        this.setState({ attached: true });
      }

      this.scrollParentToChild(this.wrapperRef.current, this.bottomRef.current);
    } else if (previousState.attached) {
      onScrollAttachmentChanged(false);
      this.setState({ attached: false });
    }
  }

  componentDidMount(): void {
    // Scroll to bottom from the start
    if (this.bottomRef.current && this.wrapperRef.current) {
      this.scrollParentToChild(this.wrapperRef.current, this.bottomRef.current);
    }

    if (this.props.registerBottomScrollRef && this.bottomRef.current) {
      this.props.registerBottomScrollRef(this.bottomRef);
    }
  }

  componentWillUnmount(): void {
    if (this.props.registerBottomScrollRef) {
      this.props.registerBottomScrollRef(null);
    }
  }

  /**
   * Scrolls a parent element such that the child element will be in view
   * @param parent
   * @param child
   */
  protected scrollParentToChild(parent: HTMLElement, child: HTMLElement): void {
    const { visibleDetectionEpsilon } = this.props;

    if (!AutoScrollingContainer.isVisible(parent, child, visibleDetectionEpsilon)) {
      child.scrollIntoView({ block: 'end' });
    }
  }

  /**
   * Returns whether a child element is visible within a parent element
   * @param parent
   * @param child
   * @param epsilon
   */
  private static isVisible(parent: HTMLElement, child: HTMLElement, epsilon: number): boolean {
    epsilon = epsilon || 0;

    // Source: https://stackoverflow.com/a/45411081/6316091
    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    const childTopIsVisible = childRect.top >= parentRect.top;
    const childBottomIsVisible = childRect.top > parentRect.top && childRect.top <= parentRect.top + parent.clientHeight;

    return childTopIsVisible && childBottomIsVisible;
  }

  render(): React.ReactNode {
    const { children, className, loading } = this.props;

    return (
      <Scrollable animateScroll={this.props.animateScroll} ref={this.wrapperRef} className={className}>
        {children}
        {loading && <Spinner size={'s'} />}
        <ScrollTarget ref={this.bottomRef} />
      </Scrollable>
    );
  }
}
