import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Box } from '../../containers/Container/Box.component';
import { colors } from '../../themes';
import { Link } from '../../typography';
import { Icon } from '../../icons/Icon.component';

interface IHelperBodyProps {
  body?: JSX.Element;
  hideCloseButton?: boolean;
  closeHelper?(): void;
}

const CloseButton = styled(Link)`
  color: ${colors.gray[800]};
  position: absolute;
  height: auto;
  min-width: 14px;
  top: 20px;
  right: 15px;

  :hover {
    color: ${colors.gray[900]};
  }
`;

const StyledBody = styled(Box)<{ hideCloseButton: boolean }>`
  padding: ${({ theme: { popover } }) => popover.helper.padding};
  ${({ theme: { popover }, hideCloseButton }) => !hideCloseButton && `padding-right: ${popover.helper.paddingRight}`}
`;

export const HelperBody: FunctionComponent<IHelperBodyProps> = ({ hideCloseButton, body, closeHelper }) => (
  <StyledBody hideCloseButton={hideCloseButton}>
    {!hideCloseButton && (
      <CloseButton onClick={closeHelper}>
        <Icon icon={'xmark-regular'} />
      </CloseButton>
    )}
    {body}
  </StyledBody>
);
