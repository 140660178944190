import { useContext } from 'react';
import { components } from 'react-select';
import { ThemeContext } from 'styled-components';
import { SelectIconButton } from '../../../inputs/Select/Select.styles';
import { Icon } from '../../../icons/Icon.component';

export const DropdownIndicator = props => {
  const { searchFilters } = useContext(ThemeContext);

  return (
    <components.DropdownIndicator {...props}>
      <SelectIconButton variant={props.selectProps.variant}>
        <Icon icon={'magnifying-glass-regular'} size={14} color={searchFilters.popover.select.input.icon.color} />
      </SelectIconButton>
    </components.DropdownIndicator>
  );
};
