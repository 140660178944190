import { colors } from '../../themes/colors';

export const multiTagLightV2Theme = {
  container: {
    backgroundColor: {
      disabled: colors.gray[0],
      selected: colors.blue[300],
      default: colors.blue[100],
      hover: colors.blue[200],
    },
    borderColor: {
      selected: colors.blue[600],
      default: colors.blue[300],
      hover: colors.blue[500],
    },
  },
  closeButton: {
    color: {
      default: colors.royalBlue[500],
      selected: colors.royalBlue[1000],
      hover: colors.blue[200],
    },
  },
  strikeThroughColor: colors.royalBlue[1000],
  stepTextBackgroundColor: colors.blue[200],
  invalid: colors.red[500],
};
