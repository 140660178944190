import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Link } from '../typography/Link/Link.component';
import { Icon } from '../icons/Icon.component';
import { Tooltip } from '../tooltip/Tooltip.component';

const height = 14;
const width = height * 2;

const ToggleButton = styled<any>(Link)`
  color: ${({ theme }) => theme.colors.gray[900]};
  height: ${height}px;
  width: ${width}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 ${width}px ${width}px;
  background-color: ${({ theme }) => theme.colors.yellow[600]};
  cursor: pointer;
  margin-right: 4px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.yellow[500]};
    color: ${({ theme }) => theme.colors.gray[900]};
  }
  position: absolute;
  z-index: 1001;
  top: ${({ top }) => top};
  right: 63px;
`;

interface IBarToggleButton {
  isBarDisplayed: boolean;
  onClick(): void;
}

export const BarToggleButton: FunctionComponent<IBarToggleButton> = ({ onClick, isBarDisplayed }) => {
  const toggleIcon = isBarDisplayed ? 'angle-up-solid' : 'angle-down-solid';
  const tooltipText = `${isBarDisplayed ? 'Hide' : 'Show'} trial details`;
  const topHeight = isBarDisplayed ? '42px' : '0px';

  return (
    <Tooltip title={tooltipText}>
      <ToggleButton subject={'bar-toggle-button'} onClick={onClick} top={topHeight}>
        <Icon icon={toggleIcon} size={10} />
      </ToggleButton>
    </Tooltip>
  );
};
