import styled, { keyframes } from 'styled-components';
import { Button } from '../../../../../Button/Button.component';

export const expandAnimation = keyframes`
  from { transform: scaleY(0); }
  to { transform: scaleY(1); }
`;

export const StyledActions = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ActionsContainer = styled.div<{ isFlipped: boolean }>`
  position: relative;
  display: flex;
  height: 100%;
  top: ${({ isFlipped }) => (isFlipped ? `-1px` : `1px`)};
  background-color: ${({ theme }) => theme.colors.gray[0]};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.blue[500]};
  border-radius: ${({ isFlipped }) => (isFlipped ? '0 0 2px 2px' : '2px 2px 0 0')};
  padding: 4px;
  transform-origin: ${({ isFlipped }) => (isFlipped ? 'top' : 'bottom')};
  animation: ${expandAnimation} 0.1s ease-in-out;

  ::after {
    content: '';
    position: absolute;
    width: calc(100% + 20px);
    height: 130%;
    top: ${({ isFlipped }) => (isFlipped ? `-3px` : `-5px`)};
    left: 0;
    z-index: -1;
  }
`;

export const ButtonContainer = styled(Button)<{ background: string }>`
  background: ${({ background }) => background};
  &:hover {
    background: ${({ background }) => background};
  }
`;
