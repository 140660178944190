import { get, isNil } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Box } from '../containers/Container/Box.component';
import { Flex, IFlexProps } from '../containers/Flex/Flex.component';
import { Grid } from '../containers/Grid/Grid.component';
import { Group, type IGroupProps } from '../containers/Group/Group.component';
import { Icon } from '../icons/Icon.component';
import { Color, colors } from '../themes';
import { Link } from '../typography';
import { Container } from '../containers/Container/Container.component';
import type { ITitleComponent } from './types';

const StyledContainer = styled(Container)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.collapse.styledLinkColor};
`;

const StyledGroup = styled(Group)<{ backgroundColor?: Color }>`
  background-color: ${({ backgroundColor }) => get(colors, backgroundColor) || 'inherit'};
  cursor: pointer;
  user-select: none;
`;

const StyledAnimatedChildrenContainer = styled(Grid)`
  transition: grid-template-rows 0.5s;
  width: 100%;
`;

const RotatingIcon = styled(Icon)<{ open: boolean }>`
  transition: transform 0.25s ease-in-out;
  transform: ${({ open }) => (open ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

interface IHandlerComponentProps
  extends Pick<ICollapseProps, 'backgroundColor' | 'TitleComponent' | 'disabled' | 'iconSize'> {
  isOpen: () => boolean;
  toggle: () => void;
}

export interface ICollapseProps extends IGroupProps {
  isAnimated?: boolean;
  initiallyOpen?: boolean;
  TitleComponent?: React.FC<ITitleComponent>;
  open?: boolean;
  disabled?: boolean;
  setOpen?(isOpen: boolean): void;
  onOpen?: (isOpen?: boolean) => Promise<void> | void;
  iconSize?: number;
  backgroundColor?: Color;
  HandlerComponent?: React.FC<IHandlerComponentProps>;
}

const DefaultHandlerComponent: FunctionComponent<IHandlerComponentProps> = ({
  TitleComponent,
  disabled,
  iconSize,
  backgroundColor,
  isOpen,
  toggle,
}) => {
  return (
    <StyledGroup
      alignItems="center"
      width={'100%'}
      gap={1}
      px={2}
      backgroundColor={backgroundColor}
      onClick={!disabled && toggle}
    >
      <StyledLink disabled={disabled} subject={'collapse'}>
        <RotatingIcon size={iconSize} open={isOpen()} icon={'chevron-right-regular'} />
      </StyledLink>
      <StyledContainer fullWidth disabled={disabled}>
        <Group fullWidth vertical gap={3} alignItems={'stretch'}>
          {TitleComponent && <TitleComponent open={isOpen()} disabled={disabled} />}
        </Group>
      </StyledContainer>
    </StyledGroup>
  );
};

export const Collapse: FunctionComponent<ICollapseProps> = ({
  isAnimated = false,
  children,
  initiallyOpen = false,
  TitleComponent,
  open: isOpenExternal,
  setOpen: setIsOpenExternal,
  disabled,
  onOpen,
  iconSize = 10,
  backgroundColor,
  HandlerComponent = DefaultHandlerComponent,
  ...groupProps
}) => {
  const [isOpenInternal, setIsOpenInternal] = useState<boolean>(initiallyOpen || isOpenExternal);

  const isOpen = () => (!isNil(isOpenExternal) ? isOpenExternal : isOpenInternal);

  const toggle = useCallback(async () => {
    const value = !isOpen();

    if (onOpen) await onOpen(value);

    (setIsOpenExternal ?? setIsOpenInternal)(value);
  }, [isOpenInternal, isOpenExternal, setIsOpenExternal, setIsOpenInternal, disabled, onOpen]);

  return (
    <Flex
      width={'100%'}
      height={'100%'}
      flexDirection={'column'}
      context={'collapse'}
      subject={groupProps.subject}
      aria-expanded={!isNil(isOpenExternal) ? isOpenExternal : isOpenInternal}
      {...(groupProps as IFlexProps)}
    >
      <HandlerComponent
        isOpen={isOpen}
        toggle={toggle}
        backgroundColor={backgroundColor}
        iconSize={iconSize}
        disabled={disabled}
        TitleComponent={TitleComponent}
      />
      {isAnimated ? (
        <StyledAnimatedChildrenContainer gridTemplateRows={isOpen() ? '1fr' : '0fr'}>
          <Box overflow={'hidden'}>{children}</Box>
        </StyledAnimatedChildrenContainer>
      ) : (
        isOpen() && children
      )}
    </Flex>
  );
};
