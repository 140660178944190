import { ComponentProps } from 'react';
import styled from 'styled-components';
import { PopoverHelper } from '../popover/Helper/PopoverHelper.component';
import { Text } from '../typography';

const StyledText = styled(Text)`
  border-bottom: 1px ${({ theme }) => theme.colors.orange[600]};
  border-bottom-style: dashed;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  text-align: start;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;

const StyledTextContainer = styled.span`
  width: auto;
  display: inline-block;
`;

interface ITextHintProps extends Omit<ComponentProps<typeof PopoverHelper>, 'children' | 'Content'> {
  text: string;
}

export const TextHint = ({
  text,
  ContentComponent,
  ...popperProps
}: ITextHintProps & {
  ContentComponent: ComponentProps<typeof PopoverHelper>['Content']['Component'];
}) => (
  <StyledTextContainer>
    <PopoverHelper Content={{ Component: ContentComponent }} {...popperProps}>
      <StyledText>{text}</StyledText>
    </PopoverHelper>
  </StyledTextContainer>
);

TextHint.displayName = 'TextHint';
