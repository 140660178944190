import styled, { css } from 'styled-components';

const increaseHandleArea = css`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    right: -5px;
    background: transparent;
    z-index: 2;
  }
`;

export const Resizer = styled.div<{ isResizing: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background: ${({ theme }) => theme.colors.blue[600]};
  cursor: ew-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;

  ${increaseHandleArea}

  ${props =>
    props.isResizing &&
    css`
      background: ${({ theme }) => theme.colors.blue[600]};
      opacity: 1;
      height: 9999px;
      z-index: 4;
    `}

  &:hover {
    opacity: 1;
  }
`;
