import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Box } from '../Container/Box.component';

interface IGridCellProps {
  span?: number;
  last?: boolean;
  offset?: number;
  sticky?: boolean;
  children: React.ReactNode;
}

const StyledBox = styled(Box)<IGridCellProps>`
  grid-column-end: ${({ last, offset, span }) => {
    if (offset) {
      return `span ${span}`;
    }

    return last ? -1 : 'auto';
  }};
  grid-column-start: ${({ span, offset }) => {
    if (offset) {
      return offset + 1;
    }

    return `span ${span}`;
  }};
`;

export const GridCell: FunctionComponent<IGridCellProps> = ({ last, span = 1, offset = 0, ...props }) => (
  <StyledBox last={last} span={span} offset={offset} context={'grid-cell'} {...props} />
);

GridCell.displayName = 'Grid.Cell';
