export enum AICopilotSubjects {
  OpenDrawerButton = 'ai-copilot-open-drawer-button',
  Drawer = 'ai-copilot-drawer',
  DrawerContent = 'ai-copilot-drawer-content',
  ChatContent = 'ai-copilot-chat-content',
  Message = 'ai-copilot-message',
  DrawerCloseButton = 'ai-copilot-drawer-close-button',
  DrawerClearButton = 'ai-copilot-drawer-clear-button',
  DrawerViewHistoryButton = 'ai-view-history-button',
  DrawerSubmitButton = 'ai-copilot-drawer-submit-button',
  DrawerInput = 'ai-copilot-drawer-Input',
  HelpQuestion = 'ai-copilot-help-question',
  QuickView = 'ai-copilot-quickview',
  SelectDataSource = 'ai-select-data-source',
  QuickViewTitle = 'ai-copilot-title',
}

export enum AICopilotChatMessageSubjects {
  CollapseMessage = 'ai-chat-collapse-message',
  ConclusionMessage = 'ai-chat-conclusion-message',
  InvocationMessage = 'ai-chat-invocation-message',
  InformationMessageManager = 'ai-chat-information-message-manager',
  InformationMessage = 'ai-chat-information-message',
}

export enum AICopilotInformationMessageSubjects {
  Graph = 'ai-chat-information-message-graph',
  Badge = 'ai-chat-information-message-badge',
}

export enum AICopilotInvocationMessageSubjects {
  InvocationTitle = 'ai-chat-invocation-title',
  ReadMore = 'ai-chat-invocation-read-more',
  EmptyGraph = 'ai-chat-invocation-graph-empty',
  Graph = 'ai-chat-invocation-graph',
  TimeRangeString = 'ai-chat-invocation-time-range-string',
  FieldString = 'ai-chat-invocation-field-string',
  QueryString = 'ai-chat-invocation-query-string',
  Badge = 'ai-chat-invocation-badge',
}

export enum AgentType {
  Pro = 'pro',
  Search = 'search',
  Rca = 'rca',
}

export enum AIDataSource {
  CurrentPageSearch = 'Search',
  CurrentPageAgent = 'Agent',
  AllAvailableData = 'AllAvailableData',
  RCA = 'RCA',
  KnowledgeBaseShipping = 'shipping',
  KnowledgeBaseUserGuide = 'userGuide',
  MetricsDashboard = 'MetricsDashboard',
}
