import { Group } from '@logz-ui/styleguide/components/containers/Group/Group.component';
import styled from 'styled-components';

export const StyledGroup = styled(Group)`
  background-color: ${({ theme }) => theme.datePicker.background};
  width: 485px;
  border-radius: 3px;
  box-shadow: 0px 10px 10px ${({ theme }) => theme.datePicker.boxShadow},
    0px 5px 25px ${({ theme }) => theme.datePicker.boxShadow};
`;

export const StyledOverlay = styled(Group)`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  z-index: 99;
`;
