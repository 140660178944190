export enum AuthPagesSubject {
  EmailField = 'email-field',
  PasswordField = 'password-field',
  FullNameField = 'full-name-field',
  CompanyField = 'company-field',
  PhoneField = 'phone-field',
  RegionField = 'region-field',
  Passcode = 'passcode',
  SignInButton = 'sign-in-button',
  SignUpButton = 'sign-up-button',
  ForgotPasswordButton = 'forgot-password-button',
  BackToLoginLink = 'back-to-login-link',
  LoginPage = 'login-page',
  ForgotPasswordPage = 'forgot-password-page',
  RegisterPage = 'register-page',
  AuthPage = 'auth-page',
  FormErrorMessage = 'form-error-message',
}

export enum AuthNotificationSubject {
  SsoRedirect = 'sso-redirect',
  PasswordChanged = 'password-changed',
}
