import { bluePalette, grayPalette } from '../../themes/colors';

export const toggleLightV2Theme = {
  backgroundColor: {
    on: bluePalette[700],
    off: grayPalette[500],
    disabled: grayPalette[300],
    hover: {
      on: bluePalette[800],
      off: grayPalette[600],
    },
  },
  focusColor: bluePalette[300],
  circle: {
    backgroundColor: 'white',
  },
};
