import { yellowPalette, royalBluePalette, grayPalette } from '../../themes/colors';

export const tabsLightTheme = {
  indicator: {
    backgroundColor: yellowPalette[600],
    size: 3,
  },
  tab: {
    color: {
      selected: royalBluePalette[1000],
      disabled: grayPalette[500],
      default: royalBluePalette[800],
    },
    hover: {
      color: royalBluePalette[1000],
    },
  },
  tabList: {
    borderBottom: `3px solid ${grayPalette[300]}`,
  },
  helper: {
    color: {
      selected: grayPalette[800],
      default: grayPalette[800],
      disabled: grayPalette[500],
    },
  },
  container: {
    minWidth: 0,
  },
};
