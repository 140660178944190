import styled, { css } from 'styled-components';
import {
  space,
  width,
  fontSize,
  color,
  flex,
  flexGrow,
  flexBasis,
  order,
  alignSelf,
  height,
  position,
  minHeight,
  maxWidth,
  overflow,
  SpaceProps,
  WidthProps,
  FontSizeProps,
  ColorProps,
  FlexProps,
  FlexGrowProps,
  FlexBasisProps,
  OrderProps,
  AlignSelfProps,
  HeightProps,
  MinHeightProps,
  MaxWidthProps,
  PositionProps,
  OverflowProps,
  MinWidthProps,
  minWidth,
} from 'styled-system';
import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { HTMLAttributes } from 'react';

type IBaseBoxProps = {
  sticky?: boolean;
  context?: string;
  subject?: string;
};

export type IBoxProps = IBaseBoxProps &
  HTMLAttributes<HTMLDivElement> &
  SpaceProps &
  WidthProps &
  FontSizeProps &
  ColorProps &
  FlexProps &
  FlexGrowProps &
  FlexBasisProps &
  OrderProps &
  AlignSelfProps &
  HeightProps &
  MinHeightProps &
  MaxWidthProps &
  PositionProps &
  OverflowProps &
  MinWidthProps;

export const Box = styled.div.attrs<IBoxProps>(({ context, subject }) =>
  generateLogzTestAttributes({
    context,
    subject,
  }),
)<IBoxProps>`
  box-sizing: border-box;
  ${space}
  ${width}
  ${fontSize}
  ${color}
  ${flex}
  ${flexGrow}
  ${flexBasis}
  ${order}
  ${alignSelf}
  ${height}
  ${minHeight}
  ${maxWidth}
  ${position}
  ${overflow}
  ${minWidth}
  
  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
    `}
`;

Box.displayName = 'Box';
