import React from 'react';
import styled, { css } from 'styled-components';
import { HighlightedText } from '../../HighlightedText/HighlightedText.component';
import { MAX_FIELD_WIDTH } from '../constants';
import { multiTagUtils } from '../utils';
import { StyledMultiTag } from '../types';
import { IStepProps } from './Step';
import { useMultiTag } from './TagContainer';

const StyledText = styled(HighlightedText)<StyledMultiTag>`
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  position: relative;
  overflow: visible;
  ${({ hover, theme }) =>
    hover &&
    css`
      background-color: ${theme.multiTag.stepTextBackgroundColor};
    `}
  &:hover {
    ${({ theme, state, isEditable }) =>
      state === 'selected' &&
      isEditable &&
      css`
        background-color: ${theme.multiTag.stepTextBackgroundColor};
      `};
  }
  ${({ theme, invalid }) =>
    invalid &&
    css`
      color: ${theme.multiTag.invalid};
    `}
`;

export const StepText: React.FC<IStepProps> = ({ step, index }) => {
  const { state, tag, onChange, highlight, noTooltip, invalid, isEditable } = useMultiTag();

  const startEditing = e => {
    if (state === 'selected') {
      e.stopPropagation();
    }

    if (step?.onClick) {
      step.onClick(e);
    } else {
      if (state === 'preview' || state !== 'selected') return;

      const newSteps = multiTagUtils.createNewSteps(tag, index, { ...step, selected: true });

      onChange?.({ ...tag, steps: newSteps });
    }
  };

  return (
    <StyledText
      textToHighlight={highlight}
      color={step.color}
      onClick={isEditable ? startEditing : undefined}
      ellipsis={{ noTooltip, maxLines: 1, wordBreak: 'break-all', openDelayInMs: 500 }}
      variant="code"
      hover={isEditable ? step.hover : undefined}
      {...{ state, invalid, isEditable, maxWidth: step.maxWidth ?? MAX_FIELD_WIDTH }}
    >
      {step.label ?? step.value}
    </StyledText>
  );
};
