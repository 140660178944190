import styled from 'styled-components';
import { Flex } from '../containers/Flex/Flex.component';

export const CardFooter = styled(({ ...props }) => <Flex py={4} px={4} {...props} width={'100%'} />)`
  background-color: ${({ theme }) => theme.card.footer.backgroundColor};
  color: ${({ theme }) => theme.card.footer.color};
  line-height: ${({ theme }) => theme.card.footer.lineHeight};
`;

CardFooter.displayName = 'Card.Footer';
