export enum ContentObjectType {
  Dashboard = 'DASHBOARD',
  FieldFormat = 'FIELD_FORMAT',
  SecurityRule = 'SECURITY_RULE',
}

export enum SavedObjectType {
  None = 'none',
  Search = 'search',
  Visualization = 'visualization',
  Dashboard = 'dashboard',
  FieldFormat = 'fieldFormat',
  Url = 'url',
  Query = 'query',
}

export enum ContentObjectStatus {
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}
