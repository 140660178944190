import React from 'react';
import styled, { css } from 'styled-components';
import { components } from 'react-select';
import { classNamePrefix } from '../Select.styles';

const reactSelectMenuCssOverrides = css`
  &.${classNamePrefix}__menu {
    ${({ selectProps: { fitMenuToContent } }: React.ComponentProps<typeof components.SelectContainer>) =>
      fitMenuToContent &&
      css`
        width: max-content;
      `};

    border-radius: 3px;
    margin: 0;
    margin-top: ${({ theme }) => theme.space[1]}px;
    border: 1px solid ${({ theme, selectProps: { variant } }) => theme.select.variants[variant].menu.borderColor};
    box-shadow: 0 2px 6px rgba(204, 213, 217, 0.8);
    color: ${({ theme, selectProps: { variant } }) => theme.select.variants[variant].menu.textColor};
    background-color: ${({ theme, selectProps: { variant } }) => theme.select.variants[variant].menu.backgroundColor};
    font-family: ${({ theme }) => theme.fonts.base};
    font-size: ${({ theme }) => theme.sizes.text.medium};
    -webkit-font-smoothing: antialiased;

    .${classNamePrefix}__menu-list {
      padding: 0;

      .${classNamePrefix}__menu-notice--no-options {
        color: ${({ theme, selectProps: { variant } }) => theme.select.variants[variant].menu.empty.textColor};
      }

      .${classNamePrefix}__group {
        padding-bottom: 0;
      }
      .${classNamePrefix}__option {
        padding: ${({ theme }) => theme.space[2]}px;

        &--is-selected {
          background-color: ${({ theme, selectProps: { variant } }) =>
            theme.select.variants[variant].menu.selectedOption.backgroundColor};
          color: ${({ theme, selectProps: { variant } }) =>
            theme.select.variants[variant].menu.selectedOption.textColor};
        }
        &--is-focused {
          background-color: ${({ theme, selectProps: { variant } }) =>
            theme.select.variants[variant].menu.focusedOption.backgroundColor};
        }
        &--is-disabled {
          color: ${({ theme, selectProps: { variant } }) =>
            theme.select.variants[variant].menu.disabledOption.textColor};
          cursor: not-allowed;
        }
        &:first-of-type {
          margin-top: ${({ theme }) => theme.space[2]}px;
        }
      }
    }
  }
`;

export const SelectMenu = styled(components.Menu)`
  ${reactSelectMenuCssOverrides}
`;

SelectMenu.displayName = 'SelectMenu';
