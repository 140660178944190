import moment, { Moment } from 'moment';
import { TimeUnit } from '@logz-pkg/enums';

interface IFormat {
  date?: Moment | number | Date | string;
  format?:
    | 'DD.MM.YYYY, HH:mm:ss.SSS'
    | 'MMM DD, YYYY @ HH:mm:ss.SSS'
    | 'MMM DD, YYYY @ HH:mm:ss'
    | 'MMM-DD-YYYY HH:mm:ss'
    | 'MMM-DD-YYYY'
    | 'MMM DD, YYYY, h:mm A'
    | 'MMM DD, YYYY'
    | 'MMM DD'
    | 'MMM D'
    | 'MMMM YYYY'
    | 'llll'
    | 'ddd, MMM DD YYYY'
    | 'HH:mm:ss';
}

const format = (
  { date = moment(), format = 'MMM DD, YYYY' }: IFormat = { date: moment(), format: 'MMM DD, YYYY' },
): string => moment(Number(date) ? +date : date).format(format);

const getBrowserTimeZone = (): string => {
  // If there is not support for Intl in the browser
  if (!Intl) {
    return 'GMT';
  }

  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const fromMinutesToTimeUnit = (minutes: number): TimeUnit => {
  if (minutes < 60) {
    return TimeUnit.Minutes;
  }

  if (minutes >= 60 && minutes < 60 * 24) {
    return TimeUnit.Hours;
  }

  return TimeUnit.Days;
};
/* There is a moment.duration(X, 'day').humanize() but it does not enable
the '1 year' format only 'a year'. That's why we wrote this one.
 */
const daysHumanize = (days: number, prefixForSingle = '1', fractionDigits = 0): string => {
  if (days < 30) {
    return `${days} days`;
  } else if (days >= 30 && days <= 45) {
    return `${prefixForSingle} month`;
  } else if (days < 365) {
    return `${(days / 30).toFixed(fractionDigits)} months`;
  } else if (days >= 365 && days < 365 * 1.5) {
    return `${prefixForSingle} year`;
  }

  return `${(days / 365).toFixed(fractionDigits)} years`;
};

const humanizeDuration = ({
  milliseconds,
  spaceBetween = true,
}: {
  milliseconds: number;
  spaceBetween?: boolean;
}): string => {
  const duration = moment.duration(milliseconds);

  const space = spaceBetween ? ' ' : '';

  if (duration.years() >= 1) {
    return `${Number(duration.asYears().toFixed(2))}${space}y`;
  }

  if (duration.months() >= 1) {
    return `${Number(duration.asMonths().toFixed(2))}${space}mo`;
  }

  if (duration.days() >= 1) {
    return `${Number(duration.asDays().toFixed(2))}${space}d`;
  }

  if (duration.hours() >= 1) {
    return `${Number(duration.asHours().toFixed(2))}${space}h`;
  }

  if (duration.minutes() >= 1) {
    return `${Number(duration.asMinutes().toFixed(2))}${space}m`;
  }

  if (duration.seconds() >= 1) {
    return `${Number(duration.asSeconds().toFixed(2))}${space}s`;
  }

  if (duration.milliseconds() >= 1) {
    return `${Number(duration.milliseconds().toFixed(2))}${space}ms`;
  }

  return `${milliseconds * 1000} μs`;
};

export const dateService = {
  format,
  getBrowserTimeZone,
  fromMinutesToTimeUnit,
  daysHumanize,
  humanizeDuration,
};
