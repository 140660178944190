import { Virtualizer, VirtualizerOptions, useVirtualizer } from '@tanstack/react-virtual';
import React, { PropsWithChildren, createContext, useContext, useRef } from 'react';
import { ShadowScroll } from '../containers/ShadowScroll/ShadowScroll.component';

const RowVirtualizerContext = createContext<{ rowVirtualizer: Virtualizer<HTMLDivElement, Element> } | undefined>(
  undefined,
);

export const useRowVirtualizer = () => {
  const context = useContext(RowVirtualizerContext);

  if (!context) {
    throw new Error('useRowVirtualizer must be used within a RowVirtualizerProvider');
  }

  return context;
};

type RowVirtualizer = VirtualizerOptions<HTMLDivElement, Element>;
interface IScrollContainerProps {
  count: RowVirtualizer['count'];
  estimateSize: RowVirtualizer['estimateSize'];
  overscan?: RowVirtualizer['overscan'];
  height?: React.CSSProperties['height'];
  maxHeight?: React.CSSProperties['maxHeight'];
  onBottomReached?: () => void;
}

export const ScrollContainer: React.FC<PropsWithChildren<IScrollContainerProps>> = ({
  height = '100%',
  count,
  children,
  estimateSize,
  onBottomReached,
  maxHeight,
  overscan,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count,
    getScrollElement: () => containerRef.current,
    estimateSize,
    overscan,
  });

  const items = rowVirtualizer.getVirtualItems();

  const handleScroll = () => {
    if (count === 0) return;

    if (items[items.length - 1]?.index === count - 1) {
      onBottomReached?.();
    }
  };

  return (
    <RowVirtualizerContext.Provider value={{ rowVirtualizer }}>
      <ShadowScroll parentRef={containerRef}>
        <div
          ref={containerRef}
          style={{ height, maxHeight, width: '100%', overflowY: 'auto', contain: 'strict' }}
          onScroll={handleScroll}
          {...props}
        >
          <div
            style={{
              height: rowVirtualizer.getTotalSize(),
              width: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${items[0]?.start ?? 0}px)`,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </ShadowScroll>
    </RowVirtualizerContext.Provider>
  );
};
