const cache: { [key: string]: string } = {};

const toHex = (value: number): string => {
  if (cache[`hex_${value}`]) {
    return cache[`hex_${value}`];
  }

  const hexValue = `0${Math.round(value * 2.55).toString(16)}`.slice(-2).toUpperCase();

  cache[`hex_${value}`] = hexValue;

  return hexValue;
};

export const opacity = (color: string, value: number): string => {
  if (cache[`${color}_${value}`]) {
    return cache[`${color}_${value}`];
  }

  const opacityValue = value < 101 && value >= 0 ? `${color}${toHex(value)}` : color;

  cache[`${color}_${value}`] = opacityValue;

  return opacityValue;
};
