import { opacity } from '../../utils';
import { colors } from '../colors';

export const popover = {
  arrow: {
    size: '16px',
    shadowColor: opacity(colors.gray[900], 40),
  },
  error: {
    padding: '8px 15px',
  },
  helper: {
    padding: '20px 25px',
    paddingRight: '50px',
  },
  backgroundColor: {
    info: colors.gray[0],
    primary: colors.blue[500],
    warn: colors.orange[500],
    danger: colors.red[700],
  },
  position: {
    default: 0,
    tooltip: '10px',
  },
  textColor: {
    info: colors.gray[900],
    default: colors.gray[0],
  },
};
