import styled from 'styled-components';
import { Group } from '../containers/Group/Group.component';
import { colors } from '../themes';

export const Timeline = styled(props => <Group vertical {...props} gap={6} width={'100%'} />)`
  margin: 2em 0;
  position: relative;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 16px;
    height: 100%;
    width: 2px;
    background: ${colors.gray[500]};
  }
`;
