export enum HomeDashboardSubjects {
  HomeDashboardPage = 'home-dashboard-page',
  TableEmptyState = 'table-empty-state',
  RecentEventsTable = 'recent-events-table',
  EventRecordInvestigateButton = 'event-record-investigate-button',
  EventRecordAiButton = 'event-record-ai-button',
  LogsCounter = 'logs-counter',
  MetricsCounter = 'metrics-counter',
  TracesCounter = 'traces-counter',
  AlertsCounter = 'alerts-counter',
  InsightsCounter = 'insights-counter',
  ExceptionsCounter = 'exceptions-counter',
  HomeDashboardFavItem = 'home-dashboard-fav-item',
  HomeDashboardFavorites = 'home-dashboard-favorites',
  FavoritesSearchResults = 'home-dashboard-fav-search-results',
  FavoritesBookmarks = 'home-dashboard-fav-bookmarks',
  FavoritesRecent = 'home-dashboard-fav-recent',
  FavoritesSuggestions = 'home-dashboard-fav-suggestions',
}

export enum HomeDashboardEventType {
  EXCEPTIONS = 'Exceptions',
  INSIGHTS = 'Insights',
  ALERTS = 'Alerts',
}
