/* eslint-disable @typescript-eslint/no-namespace */

import { colors } from '../../themes/colors';
import { colorsV2 } from '../../themes';
import { opacity } from '../../utils';

export namespace FillBarPresets {
  export enum Type {
    Base,
    Alternate,
  }

  export const steps: Record<string, { from: number; color: string }[]> = {
    [Type.Base]: [
      { from: 0, color: opacity(colors.green[500], 70) },
      { from: 80, color: opacity(colors.orange[500], 70) },
      { from: 100, color: colors.red[600] },
    ],
    [Type.Alternate]: [
      { from: 0, color: colorsV2.indigo[500] },
      { from: 100, color: colorsV2.error[400] },
    ],
  };
}
