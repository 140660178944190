import { FunctionComponent, ReactNode } from 'react';
import { AlignSelfProps } from 'styled-system';
import { useId } from '@logz-pkg/react-hooks';
import { IIconProps } from '../icons/Icon.component';
import { Color } from '../themes';
import { Group } from '../containers/Group/Group.component';
import { FieldDescription } from '../containers/FieldContainer/Components';
import {
  HiddenCheckbox,
  StyledCheckbox,
  StyledCheckboxIcon,
  CheckboxLabel,
  StyledCheckBoxContainer,
  CheckboxHelper,
} from './Checkbox.styles';

export interface ICheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  onChange?(value: boolean): void;
  checkedIcon?: IIconProps['icon'];
  checkedColor?: Color;
  disabled?: boolean;
  label?: string | JSX.Element;
  subject?: string;
  name?: string;
  description?: ReactNode;
  helper?: ReactNode;
  alignCheckbox?: AlignSelfProps['alignSelf'];
  fullWidth?: boolean;
  labelSize?: 's' | 'm' | 'l';
}

export const Checkbox: FunctionComponent<ICheckboxProps> = ({
  label,
  checked = false,
  indeterminate = false,
  onChange,
  subject,
  name,
  description,
  helper,
  alignCheckbox = 'start',
  checkedIcon = 'check-solid',
  checkedColor,
  disabled,
  fullWidth,
  labelSize = 's',
}) => {
  const onClick = () => {
    if (!disabled) onChange(!checked);
  };

  const id = useId();
  let size;

  if (labelSize === 's') {
    size = 12;
  } else if (labelSize === 'm') {
    size = 14;
  } else {
    size = 16;
  }

  return (
    <StyledCheckBoxContainer alignItems={'start'} context={'checkbox'} {...{ subject, fullWidth, disabled }}>
      <HiddenCheckbox
        aria-checked={checked}
        aria-labelledby={id}
        role={'checkbox'}
        onChange={onClick}
        {...{ checked, name }}
      />
      <StyledCheckbox
        aria-labelledby={id}
        onClick={onClick}
        checked={checked || indeterminate}
        alignSelf={alignCheckbox}
        {...{ disabled, checkedColor }}
      >
        {(checked || indeterminate) && (
          <StyledCheckboxIcon color={'white'} icon={checked ? checkedIcon : 'minus-regular'} />
        )}
      </StyledCheckbox>
      {label && (
        <Group vertical {...(fullWidth && { width: '100%' })}>
          <Group alignItems={'center'} gap={1} width={'100%'}>
            <CheckboxLabel id={id} size={size} disabled={disabled} onClick={onClick} fullWidth={fullWidth}>
              {label}
            </CheckboxLabel>
            {description && <FieldDescription>{description}</FieldDescription>}
          </Group>
          {helper && <CheckboxHelper>{helper}</CheckboxHelper>}
        </Group>
      )}
    </StyledCheckBoxContainer>
  );
};

Checkbox.displayName = 'Checkbox';
