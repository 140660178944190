import { generateLogzTestAttributes } from '@logz-pkg/test-selectors';
import { FormikErrors } from 'formik';
import { isArray, isBoolean, isNil } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Box } from '../containers/Container/Box.component';
import { Text } from '../typography';
import { Popover } from './Popover.component';
import type { PopoverPlacement } from './types';

export interface IPopoverErrorProps {
  error?: boolean | string | string[] | JSX.Element | JSX.Element[] | FormikErrors<any> | FormikErrors<any>[];
  placement?: PopoverPlacement;
  wrapChildWithRef?: boolean;
  className?: string;
  subject?: string;
  overflow?: boolean;
  children: React.ReactNode;
  offset?: number;
}

const ErrorPopoverContainer = styled(Box)`
  padding: ${({ theme: { popover } }) => popover.error.padding};
`;

const generateTextElements = text => (
  <ErrorPopoverContainer>
    {text.map((textItem, index) => (
      <Text mb={0} key={index} inheritColor={true}>
        {textItem}
      </Text>
    ))}
  </ErrorPopoverContainer>
);

interface IComponentProps {
  subject: string;
  text: string | React.JSX.Element;
}

const Component: React.FC<IComponentProps> = ({ subject, text }) => (
  <span {...generateLogzTestAttributes({ context: 'popover-error', subject })}>{text}</span>
);

export const PopoverError: React.FunctionComponent<IPopoverErrorProps> = ({
  error,
  placement = 'right',
  offset,
  subject,
  children,
  overflow,
  wrapChildWithRef,
}) => {
  const isOpen = !isNil(error) && !isBoolean(error);
  const text = generateTextElements(isArray(error) ? error : [error]) || '';

  return (
    <Popover
      wrapChildWithRef={wrapChildWithRef}
      placement={placement}
      Content={{ Component, props: { text, subject } }}
      variant="danger"
      open={isOpen}
      overflow={overflow}
      offset={offset}
      showArrow
    >
      {children}
    </Popover>
  );
};
