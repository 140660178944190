import styled, { css } from 'styled-components';
import { colors } from '..';

export type UpDownTrendsDirections = 'up' | 'down' | 'noChange';

type UpDownTrendsProps = {
  direction: UpDownTrendsDirections;
};

export const UpDownTrends = styled.div<UpDownTrendsProps>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  transform: translateY(-50%);
  ${({ direction }) => {
    if (direction === 'up') {
      return css`
        border-bottom: 6px solid ${colors.green['600']};
      `;
    }

    if (direction === 'down') {
      return css`
        border-top: 5px solid ${colors.red['600']};
      `;
    }

    return css`
      display: 'none';
    `;
  }}
`;
