import { colors } from '../colors';

export const menu = {
  inlineWhitespace: '8px',
  background: colors.gray[0],
  borderColor: colors.gray[300],
  item_divider_color: colors.gray[200],
  item_color: colors.royalBlue[1000],
  item_background: 'transparent',
  item_active_color: colors.royalBlue[1000],
  item_active_background: colors.blue[200],
  item_hover_color: colors.royalBlue[1000],
  item_hover_background: colors.gray[300],
  item_disabled_color: colors.gray[500],
  item_disabled_background: 'transparent',
  icon_color: colors.royalBlue[1000],
};
