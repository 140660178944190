export enum LogLevels {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  UNKNOWN = 'UNKNOWN',
}

export enum UxType {
  TOAST = 'TOAST',
  DIALOG = 'DIALOG',
  KIBANA_BAR = 'KIBANA_BAR',
  IN_PAGE = 'IN_PAGE',
  NONE = 'NONE',
}

export enum Origin {
  KIBANA = 'KIBANA',
  APP = 'APP',
  GRAFANA = 'GRAFANA',
  JAEGER = 'JAEGER',
}
