import PropTypes from 'prop-types';
import StyledBackdrop from './Backdrop.styles';

const drawerDefaultProps = {
  ...StyledBackdrop.defaultProps,
  show: false,
};

const drawerPropTypes = {
  ...StyledBackdrop.propTypes,
  show: PropTypes.bool,
};

export const Backdrop = StyledBackdrop;

Backdrop.displayName = 'Backdrop';
Backdrop.propTypes = drawerPropTypes;
Backdrop.defaultProps = drawerDefaultProps;
