import { useEffect, useMemo, useRef } from 'react';

/**
 * This hook calculates he offset required to display cell acctions correctly,
 * and also sets a CSS variable with the table width
 * this is used to calculate the width of the Header elements when the window is resized.
 * We porposly emit window resize event from other components to trigger the recalculation of the header elements width.
 */
export const useHeaderElementsOffsets = () => {
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const scrollableHeaderElementRef = useRef<HTMLDivElement>(null);
  const fixedHeaderElementRef = useRef<HTMLDivElement>(null);
  const fixedContentHeightRef = useRef<number>(0);
  const scrollableContentHeightRef = useRef<number>(0);
  const scrollHeightRef = useRef<number>(0);

  useEffect(() => {
    let fixedHeaderElementHeight = 0;
    let scrollableHeaderElementHeight = 0;

    if (fixedHeaderElementRef.current) {
      fixedHeaderElementHeight = fixedHeaderElementRef.current.getBoundingClientRect().height;
      fixedContentHeightRef.current = fixedHeaderElementHeight;
    }

    if (scrollableHeaderElementRef.current) {
      scrollableHeaderElementHeight = scrollableHeaderElementRef.current.getBoundingClientRect().height;
      scrollableContentHeightRef.current = scrollableHeaderElementHeight;
    }

    if (tableWrapperRef.current) {
      tableWrapperRef.current.style.setProperty(
        '--table-header-elements-height',
        `${fixedHeaderElementHeight + scrollableHeaderElementHeight}px`,
      );
    }
  }, [tableWrapperRef.current, scrollableHeaderElementRef.current, fixedHeaderElementRef.current]);

  useEffect(() => {
    const updateSizeVariables = () => {
      if (tableWrapperRef.current) {
        const { width } = tableWrapperRef.current.getBoundingClientRect();

        tableWrapperRef.current.style.setProperty('--table-wrapper-width', `${width}px`);
      }
    };

    updateSizeVariables();
    window.addEventListener('resize', updateSizeVariables);

    return () => {
      window.removeEventListener('resize', updateSizeVariables);
    };
  }, [tableWrapperRef.current]);

  const actionsOffsetTop = useMemo(() => {
    if (scrollableContentHeightRef.current) {
      const delta = scrollableContentHeightRef.current - (scrollHeightRef.current ?? 0);
      const padding = delta > 0 ? delta : 0;

      return padding + fixedContentHeightRef.current;
    }

    return 0;
  }, [scrollableContentHeightRef.current, scrollHeightRef.current, fixedContentHeightRef.current]);

  const setScrollHeight = (height: number) => {
    scrollHeightRef.current = height;
  };

  return { fixedHeaderElementRef, scrollableHeaderElementRef, tableWrapperRef, setScrollHeight, actionsOffsetTop };
};
