import { ISearchRequestObject } from '@logz-build/typescript';
import { FilterComponentTestSubjects } from '@logz-pkg/enums';
import React, { useMemo, useState } from 'react';
import { Collapse } from '../Collapse/Collapse.component';
import { IIconProps } from '../icons/Icon.component';
import { Color } from '../themes';
import { DefaultEmptyState } from './components/DefaultEmptyState';
import { SearchAndOptions } from './components/SearchAndOptions.component';
import { TitleComponent } from './components/TitleComponent';
import { deafultOptions, defaultRequestObject } from './constants';
import { OptionResult, SelectedOption, SettingOption } from './types';
import { filterUtils } from './utils/utils';

export interface IFilterComponentProps {
  title: string;
  value: SelectedOption[];
  onChange: (selected?: SelectedOption[]) => void;
  options: OptionResult;
  onSearch?: (searchObject: ISearchRequestObject) => Promise<void> | void;
  onShowMore?: () => Promise<void> | void;
  onOpen?: (isOpen?: boolean) => Promise<void> | void;
  loading?: boolean;
  initiallyOpen?: boolean;
  settings?: SettingOption[];
  initialRequestObject?: ISearchRequestObject;
  titleIcon?: IIconProps['icon'];
  subject?: string;
  highlight?: string;
  open?: boolean;
  isBookmark?: boolean;
  reportCb?: (eventName: string) => void;
  enableExclude?: boolean;
  hideBadge?: boolean;
  emptyState?: React.ReactElement;
  description?: string;
  creatable?: boolean;
  collapseBackgroundColor?: Color;
  disableExpandTooltip?: string;
}

export const Filter: React.FC<IFilterComponentProps> = ({
  open,
  initiallyOpen,
  loading,
  options: externalOptions = deafultOptions,
  value,
  title,
  titleIcon,
  onSearch,
  onChange,
  onShowMore,
  settings,
  initialRequestObject = defaultRequestObject,
  subject = FilterComponentTestSubjects.Container,
  onOpen,
  highlight,
  reportCb,
  enableExclude = true,
  hideBadge = false,
  emptyState = <DefaultEmptyState />,
  description,
  creatable = false,
  collapseBackgroundColor,
  disableExpandTooltip,
}) => {
  const [requestObject, setRequestObject] = useState(initialRequestObject);

  const options = useMemo(() => filterUtils.mergeValuesAndOptions(externalOptions, value), [externalOptions, value]);

  const handleSearch = async ({ filter, pagination = defaultRequestObject.pagination }: ISearchRequestObject) => {
    const searchObject: ISearchRequestObject = {
      ...defaultRequestObject,
      ...requestObject,
      filter,
      pagination,
    };

    await onSearch(searchObject);
    setRequestObject(searchObject);
  };

  return (
    <Collapse
      open={Boolean(disableExpandTooltip) ? false : open}
      initiallyOpen={initiallyOpen}
      disabled={Boolean(disableExpandTooltip) || loading}
      width={'100%'}
      alignItems={'baseline'}
      TitleComponent={() => (
        <TitleComponent
          {...{ options, value, title, loading, titleIcon, settings, highlight, hideBadge, disableExpandTooltip }}
        />
      )}
      subject={subject}
      onOpen={onOpen}
      iconSize={8}
      backgroundColor={collapseBackgroundColor}
    >
      <div style={{ minWidth: '100%', padding: '4px 8px' }}>
        <SearchAndOptions
          {...{
            options,
            value,
            loading,
            handleSearch: onSearch ? handleSearch : undefined,
            onChange,
            reportCb,
            enableExclude,
            onShowMore,
            emptyState,
            description,
            creatable,
          }}
        />
      </div>
    </Collapse>
  );
};
