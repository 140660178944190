import { createGlobalStyle } from 'styled-components';

// Font Awesome icons
import('./icons/font-awesome/js/all.min');

// if icon is missing will display 
createGlobalStyle`
  [class*="logzio-icons logzio-icon-"]:before {
    content:"";
  }
`;

function loadFont({ href, head }) {
  const font = document.createElement('link');

  font.setAttribute('rel', 'stylesheet');
  font.setAttribute('href', href);
  font.setAttribute('type', 'text/css');
  font.setAttribute('async', 'true');

  head.appendChild(font);
}

export function bootstrap({ shouldLoadFonts = true } = {}) {
  const [head] = document.getElementsByTagName('head');

  if (shouldLoadFonts) {
    loadFont({
      head,
      href: '//fonts.googleapis.com/css?family=Roboto+Mono:400,500|Roboto:300,400,400i,500,700&display=swap',
    });
    loadFont({
      head,
      href: '//fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap',
    });
  }
}
