import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { isEmpty, isNumber } from 'lodash';
import { IInputProps, Input, StatusIcon } from '../Input.component';
import { Icon } from '../../icons/Icon.component';
import { Group } from '../../containers/Group/Group.component';
import { Container } from '../../containers/Container/Container.component';

const StyledStatusIcon = styled(StatusIcon)`
  pointer-events: visible;
  cursor: pointer;
`;

const StyledInput: FunctionComponent<IInputProps> = styled(Input)`
  input {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    padding-right: ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledContainer = styled(Container)`
  background-color: transparent;

  &:not(:hover) ${StyledStatusIcon} {
    display: none;
  }
`;

export interface IInputNumberProps extends IInputProps {
  step?: number;
  max?: number;
  min?: number;
}

export const InputNumber: React.FunctionComponent<IInputNumberProps> = ({
  value,
  onChange,
  disabled,
  errorOverflow = true,
  step = 1,
  max,
  min,
  ...props
}) => {
  const handleChange = newValue => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleInputChange = newValue => {
    const convertedValue = isEmpty(newValue) ? newValue : Number(newValue);

    handleChange(convertedValue);
  };

  const handleIncrement = () => {
    const nextValue = Number(value ?? 0) + Number(step);

    if (isNumber(min) && nextValue < min) {
      handleInputChange(min);
    } else if (isNumber(max) && nextValue > max) {
      handleInputChange(max);
    } else {
      handleInputChange(nextValue);
    }
  };

  const handleDecrement = () => {
    const nextValue = Number(value ?? 0) - Number(step);

    if (isNumber(max) && nextValue > max) {
      handleInputChange(max);
    } else if (isNumber(min) && nextValue < min) {
      handleInputChange(min);
    } else {
      handleInputChange(nextValue);
    }
  };

  return (
    <StyledContainer position={'relative'}>
      <StyledInput
        type="number"
        value={value}
        onChange={e => handleInputChange(e.target.value)}
        shrink
        disabled={disabled}
        errorOverflow={errorOverflow}
        {...props}
      >
        {!disabled && (
          <StyledStatusIcon>
            <Group vertical gap={'2px'}>
              <Icon subject={'ChevronUp'} icon={'chevron-up-regular'} size={10} onClick={handleIncrement} />
              <Icon subject={'ChevronDown'} icon={'chevron-down-regular'} size={10} onClick={handleDecrement} />
            </Group>
          </StyledStatusIcon>
        )}
      </StyledInput>
    </StyledContainer>
  );
};
